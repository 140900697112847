<template>
  <aside :class="wrapClass" :style="wrapStyle" class="yxt-layout__aside">
    <yxt-sticky
      v-if="sticky"
      :bg="bg"
      :keep-top="mainNav"
      ref="sticky"
      :width="width"
      :allowance='allowance'
      @change="changeFixed"
      :offset="offset"
      :keep-left="keepLeft"
      :fix-height='fixHeight'
      keep-size
    >
      <div ref="view" class="yxt-layout__aside--inner" :style="style">
        <yxt-scrollbar ref='bar' :fit-height="true">
          <slot></slot>
        </yxt-scrollbar>
      </div>
    </yxt-sticky>
    <div ref="view" v-else class="yxt-layout__aside--inner" :style="style">
        <yxt-scrollbar ref='bar' :fit-height="true">
          <slot></slot>
        </yxt-scrollbar>
      </div>
      <span
        class="yxt-layout__resize-line"
        v-if="resizable"
        @mouseenter="resieEnter"
        @mousedown="resieStart"
      >
      <yxt-tooltip class="item" effect="dark" :content="showText ? this.t('pc_comp_sideBar_open') : this.t('pc_comp_sideBar_close')" placement="top">
        <span
          ref="toggle"
          :style="toggleStyle"
          class="yxt-layout__aside-toggle"
          v-if="showText"
          @mousedown.stop
          @click.stop="toggle"
        >
        <!-- <svg width="16" height="17" xmlns="http://www.w3.org/2000/svg"><defs><filter id="a"><feColorMatrix in="SourceGraphic" values="0 0 0 0 0.349020 0 0 0 0 0.349020 0 0 0 0 0.349020 0 0 0 1.000000 0"/></filter></defs><g transform="matrix(-1 0 0 1 18 -8)" filter="url(#a)" fill="none" fill-rule="evenodd"><path d="M2 8h16v16H2z"/><path d="M15.837 19.51a.85.85 0 0 1 0 1.7h-5.302a.85.85 0 0 1 0-1.7zM7.533 13.2c.086 0 .156.07.156.156v5.393a.156.156 0 0 1-.266.11l-3.31-2.532a.389.389 0 0 1 0-.55l3.31-2.531a.156.156 0 0 1 .11-.046zm8.304 1.91a.85.85 0 0 1 0 1.7h-5.302a.85.85 0 0 1 0-1.7zm0-4.4a.85.85 0 0 1 0 1.7h-5.302a.85.85 0 1 1 0-1.7z" fill="currentColor" fill-rule="nonzero"/></g></svg> -->
          <svg width="16" height="17" xmlns="http://www.w3.org/2000/svg"><g transform="matrix(-1 0 0 1 18 -8)" fill="none" fill-rule="evenodd"><path d="M2 8h16v16H2z"/><path d="M15.837 19.51a.85.85 0 0 1 0 1.7h-5.302a.85.85 0 0 1 0-1.7zM7.533 13.2c.086 0 .156.07.156.156v5.393a.156.156 0 0 1-.266.11l-3.31-2.532a.389.389 0 0 1 0-.55l3.31-2.531a.156.156 0 0 1 .11-.046zm8.304 1.91a.85.85 0 0 1 0 1.7h-5.302a.85.85 0 0 1 0-1.7zm0-4.4a.85.85 0 0 1 0 1.7h-5.302a.85.85 0 1 1 0-1.7z" fill="currentColor" fill-rule="nonzero"/></g></svg>
        </span>
        <span
          ref="toggle"
          :style="toggleStyle"
          class="yxt-layout__aside-toggle"
          v-else
          @mousedown.stop
          @click.stop="toggle"
        >
          <!-- <svg width="16" height="17" xmlns="http://www.w3.org/2000/svg"><defs><filter id="a"><feColorMatrix in="SourceGraphic" values="0 0 0 0 0.349020 0 0 0 0 0.349020 0 0 0 0 0.349020 0 0 0 1.000000 0"/></filter></defs><g transform="translate(-2 -7)" filter="url(#a)" fill="none" fill-rule="evenodd"><path d="M2 8h16v16H2z"/><path d="M15.837 19.51a.85.85 0 0 1 0 1.7h-5.302a.85.85 0 0 1 0-1.7zM7.533 13.2c.086 0 .156.07.156.156v5.393a.156.156 0 0 1-.266.11l-3.31-2.532a.389.389 0 0 1 0-.55l3.31-2.531a.156.156 0 0 1 .11-.046zm8.304 1.91a.85.85 0 0 1 0 1.7h-5.302a.85.85 0 0 1 0-1.7zm0-4.4a.85.85 0 0 1 0 1.7h-5.302a.85.85 0 1 1 0-1.7z" fill="currentColor" fill-rule="nonzero"/></g></svg> -->
          <svg width="16" height="17" xmlns="http://www.w3.org/2000/svg"><g transform="translate(-2 -7)"  fill="none" fill-rule="evenodd"><path d="M2 8h16v16H2z"/><path d="M15.837 19.51a.85.85 0 0 1 0 1.7h-5.302a.85.85 0 0 1 0-1.7zM7.533 13.2c.086 0 .156.07.156.156v5.393a.156.156 0 0 1-.266.11l-3.31-2.532a.389.389 0 0 1 0-.55l3.31-2.531a.156.156 0 0 1 .11-.046zm8.304 1.91a.85.85 0 0 1 0 1.7h-5.302a.85.85 0 0 1 0-1.7zm0-4.4a.85.85 0 0 1 0 1.7h-5.302a.85.85 0 1 1 0-1.7z" fill="currentColor" fill-rule="nonzero"/></g></svg>
        </span>
        </yxt-tooltip>
      </span>
  </aside>
</template>
<script>
import bus, { setAnimation } from './bus';
import {
  addResizeListener,
  removeResizeListener
} from '@utils/utils/resize-event';
// import scrollbarWidth from '@utils/utils/scrollbar-width';
import throttle from 'throttle-debounce/throttle';
import debounce from 'throttle-debounce/debounce';
import Locale from '@utils/mixins/locale';
const DEFAULT_DUTATION = 300;
const MIN_WIDTH = 200; // 可拖动区最小宽度
export default {
  name: 'YxtLayoutSide',
  mixins: [Locale],
  props: {
    sideHeight: String,
    bg: {
      default: '#fff',
      type: String
    },
    sticky: {
      type: Boolean,
      default: true
    },
    keepLeft: {
      default: true,
      type: Boolean
    },
    mainNav: {
      default: false,
      type: Boolean
    },
    offset: {
      default: 0,
      type: Number
    },
    width: {
      default: 0,
      type: [String, Number]
    },
    duration: {
      // 动画持续时间
      default: null,
      validator(v) {
        return v === null || v.match(/s$/i);
      },
      type: String
    },
    resizable: {
      // 是否允许拖动
      default: false,
      type: Boolean
    }
  },
  data() {
    return {
      showText: false,
      isFixed: false,
      viewWidth: '184px',
      allowance: 0,
      widthBackup: 184,
      isResize: false,
      borderRgba: false,
      toggleRectTop: 0,
      offsetBottom: 0,
      innerWidth: '184px',
      widthChanged: false,
      windowHeight: window.innerHeight,
      sideHidden: false // 是否隐藏目录区
    };
  },
  computed: {
    style() {
      let result = {
        'background-color': this.bg,
        'width': this.resizable ? `${Math.max(MIN_WIDTH, parseFloat(this.innerWidth))}px` : this.innerWidth
      };

      if (this.duration && this.mainNav) {
        result['transition-duration'] = this.duration;
      }

      return result;
    },
    fixHeight() {
      if (this.offset + this.offsetBottom > 0) {
        return (this.windowHeight - this.offset - this.offsetBottom) + 'px';
      } else {
        return `${this.windowHeight}px`;
      }
    },
    durationTime() {
      if (!this.duration) return DEFAULT_DUTATION;
      if (this.duration.endsWith('s')) {
        return parseFloat(this.duration) * 1000;
      } else {
        return parseFloat(this.duration);
      }
    },
    toggleStyle() {
      let result = {};
      if (this.sideHeight === 'top') {
        result['top'] = '28px';
      } else if (this.sideHeight === 'center') {
        result['top'] = 'calc(50% - 17px)';
      } else if (this.sideHeight === 'bottom') {
        result['top'] = 'calc(100% - 6%)';
      } else {
        result['top'] = `calc(28px + ${this.sideHeight}px)`;
      }
      return result;
    },
    wrapClass() {
      let result = [];
      if (this.mainNav) {
        result.push('yxt-layout__aside--main');
      }

      if (this.resizable) {
        result.push('yxt-layout__aside--resizable');
      }

      if (this.borderRgba) {
        // 拉伸中，线条变蓝
        result.push('is-active');
      }

      if (this.sideHidden) {
        result.push('yxt-layout__aside--hidden');
      }

      return result.join(' ');
    },
    wrapStyle() {
      let result = {
        'background-color': this.bg,
        width: this.viewWidth
      };

      if (this.duration) {
        result['transition-duration'] = this.duration;
      }

      if (this.isResize || (!this.widthChanged && !this.mainNav)) {
        result['transition'] = 'none';
      }
      return result;
    }
  },
  mounted() {
    bus.$on('changeSize', (time) => {

      setAnimation(() => {
        if (this.$refs.sticky) {
          this.$refs.sticky.place();
        }
      }, time + 100 || 400);
    });

    this.throttleUpdate = throttle(60, this.update);
    this.throttleResize = throttle(60, this.resize);
    this.throttleResizing = throttle(60, this.resizing);
    this.emitResizing = debounce(300, function() {
      this.$emit('side-resizing');
    });

    if (!this.resizable) {
      // 如果不是可拖动元素，则监听内部宽度
      this.update();
      addResizeListener(this.$refs.view, this.throttleUpdate);
    } else if (this.width) {
      this.viewWidth = /\d$/.test(String(this.width))
        ? `${this.width}px`
        : this.width;
    }

    this.debounceUpdateDom = debounce(100, this.updateDom);
    if (this.mainNav) {
      this.$refs.view.addEventListener('DOMSubtreeModified', this.debounceUpdateDom);
    }

    this.throttleCalPostion = throttle(1000 / 60, this.calPostion);
    if (this.resizable) {
      this.calPostion();
      window.addEventListener('resize', this.throttleResize);
      window.addEventListener('scroll', this.throttleCalPostion);
      document.body.addEventListener('mousemove', this.resizing);
      document.body.addEventListener('mouseup', this.resizEnd);
      document.body.addEventListener('mouseout', this.resizOut);
    }
  },
  destroyed() {
    removeResizeListener(this.$refs.view, this.throttleUpdate);
    window.removeEventListener('resize', this.throttleResize);
    window.removeEventListener('scroll', this.throttleCalPostion);
    document.body.removeEventListener('mousemove', this.resizing);
    document.body.removeEventListener('mouseup', this.resizEnd);
    document.body.removeEventListener('mouseout', this.resizOut);
    bus.$off();
  },
  methods: {
    updateDom() {
      if (this.$refs.bar) {
        window.setTimeout(() => {
          this.$refs.bar.update();
        }, 360);
      }
    },
    changeFixed(isFixed) {
      this.isFixed = isFixed;

      this.$emit('change', isFixed);
    },
    update() {
      if (this.width) {
        this.viewWidth = /\d$/.test(String(this.width))
          ? `${this.width}px`
          : this.width;
      } else if (this.$refs.view) {
        this.viewWidth = `${this.$refs.view.scrollWidth}px`;
      }
    },
    getValidWidth(width) {
      // 获取允许设置的最大宽度
      const maxWidth = this.$el.parentNode.clientWidth / 2;
      return width > maxWidth
        ? maxWidth
        : width < MIN_WIDTH
          ? MIN_WIDTH
          : width;
    },
    resize() {
      // 监听窗口变化，重新设置可拖动区域宽度（最大可设置宽度为内容区宽度的一半）
      const width = this.$el.offsetWidth;
      const maxWidth = this.$el.parentNode.clientWidth / 2;
      this.windowHeight = window.innerHeight;
      if (width > maxWidth) {
        this.viewWidth = `${maxWidth}px`;
        bus.$emit('content-change');
        this.$nextTick(() => {
          bus.$emit('content-change');
        });
      }

      this.calPostion();
    },
    calPostion() {
      // 计算收缩目录按钮的纵向位置
      if (this.$refs.toggle) {
        const scrollTop = document.documentElement.scrollTop;
        const offsetTop = this.$el.offsetTop;
        if (scrollTop >= offsetTop) {
          this.toggleRectTop = scrollTop - offsetTop;
        } else {
          this.toggleRectTop = 0;
        }
      }
    },
    toggle() {
      this.widthChanged = true;
      // 展开/收起目录区
      if (!this.sideHidden) {
        this.widthBackup = parseInt(this.viewWidth, 10);
        this.$el.parentNode.style.overflow = 'hidden';
      } else {
        setTimeout(() => {
          this.$el.parentNode.style.overflow = '';
        }, 200);
      }
      this.sideHidden = !this.sideHidden;
      setTimeout(() => {
        this.showText = !this.showText;
      }, 300);

      this.$nextTick(() => {
        this.viewWidth = this.sideHidden
          ? '0'
          : `${this.getValidWidth(this.widthBackup)}px`;
        this.animateToggle();
      });

      this.delayFun(() => {
        bus.$emit('changeSize');
      });

      // console.log('side-toggle', this.sideHidden);
      setTimeout(() => {
        this.$emit('side-toggle', this.sideHidden);
      }, 200);
    },
    animateToggle() {
      // 等待宽度变化完成后
      this.$refs.toggle.style.display = 'none';
      this.delayFun(() => {
        this.$refs.toggle.style.display = '';
      });
    },
    delayFun(handler, time) {
      // 延迟执行函数
      let duration = time ? time : (this.durationTime + 100);
      window.setTimeout(() => {
        handler();
      }, duration);
    },
    resieEnter() {
      this.borderRgba = true;
    },

    resieStart(e) {
      this.isResize = true;
      document.querySelector('html').style['user-select'] = 'none';
      this.pageX = e.pageX;
      this.oldWidth = this.$el.offsetWidth;
      // console.log('resizeStart ...');
      this.$emit('side-resize-start');
    },
    resizing(e) {
      if (!this.isResize) return;
      let pageX = e.pageX;
      let offset = pageX - this.pageX;
      let w = this.oldWidth + offset;
      this.viewWidth = `${this.getValidWidth(w)}px`;
      // 触发根节点宽度重新计算
      // bus.$emit('content-change');
      // console.log('resizing ...');
      // this.$emit('side-resizing');
      this.emitResizing();
    },
    resizEnd() {
      if (this.isResize) {
        // console.log('resizEnd ...');
        this.$emit('side-resize-end');
      }
      this.isResize = false;
      document.querySelector('html').style['user-select'] = '';
    },
    resizOut() {
      this.borderRgba = false;
    }
  },
  watch: {
    width() {
      this.update();
      this.delayFun(() => {
        bus.$emit('changeSize');
      });
    },
    isResize(v) {
      document.body.classList[v ? 'add' : 'remove']('yxt-body-noselect');
    },
    resizable: {
      immediate: true,
      handler(val) {
        if (val) {
          this.allowance = -3;
          this.offsetBottom = 20;
        }
      }
    },
    sideHidden(v) {
      if (!this.$refs.view) return;
      if (v) {
        setTimeout(() => {
          this.$refs.view.style.display = 'none';
        }, 300);
      } else {
        this.$refs.view.style.display = '';
      }
    },
    viewWidth(v) {
      // 触发容器大小修改事件，修正fixed元素位置
      if (!this.sideHidden) {
        this.innerWidth = v;
      }

      bus.$emit('changeSize');
    }
  }
};
</script>