var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "transition",
    {
      attrs: { name: "yxtf-zoom-in-top" },
      on: { "after-leave": _vm.doDestroy },
    },
    [
      _c(
        "ul",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.showPopper,
              expression: "showPopper",
            },
          ],
          staticClass: "yxtf-dropdown-menu yxtf-popper",
          class: [_vm.size && "yxtf-dropdown-menu--" + _vm.size],
        },
        [_vm._t("default")],
        2
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }