<template>
  <label
    class="yxt-radio"
    :class="[
      border && radioSize ? 'yxt-radio--' + radioSize : '',
      { 'is-disabled': isDisabled },
      { 'is-disabled-lock': isDisabled && isDisabled === 'lock' },
      { 'is-alone': !(!hideLabel && ($slots.default || label))},
      { 'is-focus': focus },
      { 'is-bordered': border },
      { 'is-checked': model === label && changeTextColor },
      { 'is-checked-alone': model === label && !changeTextColor }
    ]"
    role="radio"
    :aria-checked="model === label"
    :aria-disabled="isDisabled"
    :tabindex="tabIndex"
    @keydown.space.stop.prevent="model = isDisabled ? model : label"
  >
    <span class="yxt-radio__input"
      :class="{
        'is-disabled': isDisabled,
        'is-disabled-lock': isDisabled && isDisabled === 'lock' ,
        'is-checked': model === label && changeTextColor,
        'is-checked-alone': model === label && !changeTextColor
      }"
    >
      <span class="yxt-radio__inner"></span>
      <input
        ref="radio"
        class="yxt-radio__original"
        :value="label"
        type="radio"
        aria-hidden="true"
        v-model="model"
        @focus="focus = true"
        @blur="focus = false"
        @change="handleChange"
        :name="name"
        :disabled="isDisabled"
        tabindex="-1"
      >
    </span>
    <span class="yxt-radio__label" v-if="!hideLabel" @keydown.stop>
      <slot></slot>
      <template v-if="!$slots.default">{{label}}</template>
    </span>
  </label>
</template>
<script>
  import Emitter from '@utils/mixins/emitter';

  export default {
    name: 'YxtRadio',

    mixins: [Emitter],

    inject: {
      yxtform: {
        default: ''
      },

      yxtformItem: {
        default: ''
      }
    },

    componentName: 'YxtRadio',

    props: {
      value: {
        desc: '绑定值'
      },
      label: {
        desc: 'Radio的value'
      },
      disabled: {
        type: [Boolean, String],
        default: false,
        desc: '是否禁用'
      },
      name: {
        type: String,
        desc: '原生name属性'
      },
      border: {
        type: Boolean,
        desc: '是否显示边框'
      },
      hideLabel: {
        type: Boolean,
        default: false,
        desc: '是否隐藏右侧文本'
      },
      size: {
        type: String,
        desc: '尺寸，仅在 border 为 true 时有效'
      },
      changeTextColor: {
        type: [Number, Boolean],
        default: false,
        desc: 'label是否根据选中状态改变'
      }
    },

    data() {
      return {
        focus: false
      };
    },
    computed: {
      isGroup() {
        let parent = this.$parent;
        while (parent) {
          if (parent.$options.componentName !== 'YxtRadioGroup') {
            parent = parent.$parent;
          } else {
            this._radioGroup = parent;
            return true;
          }
        }
        return false;
      },
      model: {
        get() {
          return this.isGroup ? this._radioGroup.value : this.value;
        },
        set(val) {
          if (this.isGroup) {
            this.dispatch('YxtRadioGroup', 'input', [val]);
          } else {
            this.$emit('input', val);
          }
          this.$refs.radio && (this.$refs.radio.checked = this.model === this.label);
        }
      },
      _yxtformItemSize() {
        return (this.yxtformItem || {}).yxtformItemSize;
      },
      radioSize() {
        const temRadioSize = this.size || this._yxtformItemSize || (this.$ELEMENT || {}).size;
        return this.isGroup
          ? this._radioGroup.radioGroupSize || temRadioSize
          : temRadioSize;
      },
      isDisabled() {
        return this.isGroup
          ? this._radioGroup.disabled || this.disabled || (this.yxtform || {}).disabled
          : this.disabled || (this.yxtform || {}).disabled;
      },
      tabIndex() {
        return (this.isDisabled || (this.isGroup && this.model !== this.label)) ? -1 : 0;
      }
    },

    methods: {
      handleChange() {
        this.$nextTick(() => {
          this.$emit('change', this.model);
          this.isGroup && this.dispatch('YxtRadioGroup', 'handleChange', this.model);
        });
      }
    }
  };
</script>
