var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("transition", { attrs: { name: "viewer-fade" } }, [
    _c(
      "div",
      {
        staticClass: "yxt-image-viewer__wrapper",
        style: { "z-index": _vm.zIndex },
      },
      [
        _c("div", { staticClass: "yxt-image-viewer__mask" }),
        _c(
          "span",
          {
            staticClass: "yxt-image-viewer__btn yxt-image-viewer__close",
            on: { click: _vm.hide },
          },
          [_c("i", { staticClass: "yxt-icon-circle-close color-white" })]
        ),
        !_vm.isSingle
          ? [
              _c(
                "span",
                {
                  staticClass: "yxt-image-viewer__btn yxt-image-viewer__prev",
                  class: { "is-disabled": !_vm.infinite && _vm.isFirst },
                  on: { click: _vm.prev },
                },
                [_c("i", { staticClass: "yxt-icon-arrow-left" })]
              ),
              _c(
                "span",
                {
                  staticClass: "yxt-image-viewer__btn yxt-image-viewer__next",
                  class: { "is-disabled": !_vm.infinite && _vm.isLast },
                  on: { click: _vm.next },
                },
                [_c("i", { staticClass: "yxt-icon-arrow-right" })]
              ),
            ]
          : _vm._e(),
        _c(
          "div",
          { staticClass: "yxt-image-viewer__btn yxt-image-viewer__actions" },
          [
            _c("div", { staticClass: "yxt-image-viewer__actions__inner" }, [
              _c("i", {
                staticClass: "yxt-icon-zoom-out",
                on: {
                  click: function ($event) {
                    _vm.handleActions("zoomOut")
                  },
                },
              }),
              _c("i", {
                staticClass: "yxt-icon-zoom-in",
                on: {
                  click: function ($event) {
                    _vm.handleActions("zoomIn")
                  },
                },
              }),
              _c("i", { staticClass: "yxt-image-viewer__actions__divider" }),
              _c("i", { class: _vm.mode.icon, on: { click: _vm.toggleMode } }),
              _c("i", { staticClass: "yxt-image-viewer__actions__divider" }),
              _c("i", {
                staticClass: "yxt-icon-refresh-left",
                on: {
                  click: function ($event) {
                    _vm.handleActions("anticlocelise")
                  },
                },
              }),
              _c("i", {
                staticClass: "yxt-icon-refresh-right",
                on: {
                  click: function ($event) {
                    _vm.handleActions("clocelise")
                  },
                },
              }),
            ]),
          ]
        ),
        _c(
          "div",
          { staticClass: "yxt-image-viewer__canvas" },
          _vm._l(_vm.urlList, function (url, i) {
            return i === _vm.index
              ? _c("img", {
                  key: url,
                  ref: "img",
                  refInFor: true,
                  staticClass: "yxt-image-viewer__img",
                  style: _vm.imgStyle,
                  attrs: { src: _vm.currentImg },
                  on: {
                    load: _vm.handleImgLoad,
                    error: _vm.handleImgError,
                    mousedown: _vm.handleMouseDown,
                  },
                })
              : _vm._e()
          }),
          0
        ),
      ],
      2
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }