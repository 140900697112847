import { getPropByPath } from '@utils/utils/util';

export const cellStarts = {
  default: {
    order: ''
  },
  radio: {
    width: 48,
    minWidth: 48,
    realWidth: 48,
    order: '',
    classname: 'Yxt-table-column--radio'
  },
  selection: {
    width: 48,
    minWidth: 48,
    realWidth: 48,
    order: '',
    classname: 'Yxt-table-column--selection'
  },
  expand: {
    width: 48,
    minWidth: 48,
    realWidth: 48,
    order: ''
  },
  index: {
    width: 48,
    minWidth: 48,
    realWidth: 48,
    order: ''
  }
};

// 这些选项不应该被覆盖
export const cellForced = {
  selection: {
    renderHeader: function(h, { store }) {
      return (
        <yxt-checkbox
          disabled={store.states.data && store.states.data.length === 0}
          indeterminate={
            store.states.selection.length > 0 && !this.isAllSelected
          }
          nativeOn-click={this.toggleAllSelection}
          value={this.isAllSelected}
        />
      );
    },
    renderCell: function(h, { row, column, store, $index }) {
      return (
        <yxt-checkbox
          nativeOn-click={event => event.stopPropagation()}
          value={store.isSelected(row)}
          disabled={
            column.selectable
              ? !column.selectable.call(null, row, $index)
              : false
          }
          on-input={() => {
            store.commit('rowSelectedChanged', row);
          }}
        />
      );
    },
    sortable: false,
    resizable: false
  },
  radio: {
    renderHeader: function(h, { column, store }) {
      return column.label || '';
    },
    renderCell: function(h, { row, column, store, $index }) {
      return (
        <yxt-radio
          nativeOn-click={event => event.stopPropagation()}
          value={store.states.currentRadio === row }
          label={true}
          hide-label={true}
          disabled={
            column.radioable
              ? !column.radioable.call(null, row, $index)
              : false
          }
          on-input={() => {
            store.commit('rowRadioChanged', row);
          }}></yxt-radio>
      );
    },
    sortable: false,
    resizable: false
  },
  index: {
    renderHeader: function(h, { column }) {
      return column.label || '#';
    },
    renderCell: function(h, { $index, column }) {
      let i = $index + 1;
      const index = column.index;

      if (typeof index === 'number') {
        i = $index + index;
      } else if (typeof index === 'function') {
        i = index($index);
      }

      return <div>{i}</div>;
    },
    sortable: false
  },
  expand: {
    renderHeader: function(h, { column }) {
      return column.label || '';
    },
    renderCell: function(h, { row, store }) {
      const classes = ['yxt-table__expand-icon'];
      if (store.states.expandRows.indexOf(row) > -1) {
        classes.push('yxt-table__expand-icon--expanded');
      }
      const callback = function(e) {
        e.stopPropagation();
        store.toggleRowExpansion(row);
      };
      return (
        <div class={classes} on-click={callback}>
          <yxt-svg class="color-gray-3 hover-gray-6" width="16px" height="16px" icon-class={store.states.expandRows.indexOf(row) > -1 ? 'tree-close-1' : 'tree-open-1'}></yxt-svg>
          {/* <i class="yxt-icon yxt-icon-arrow-right" /> */}
        </div>
      );
    },
    sortable: false,
    resizable: false,
    classname: 'Yxt-table__expand-column'
  }
};

export function defaultRenderCell(h, { row, column, $index }) {
  const property = column.property;
  const value = property && getPropByPath(row, property).v;
  if (column && column.formatter) {
    return column.formatter(row, column, value, $index);
  }
  return value;
}

export function treeCellPrefix(h, { row, treeNode, store, treeDataFirstColumn }) {
  if (treeDataFirstColumn && !treeNode) return <span class="yxt-table__placeholder" />;
  if (!treeNode) return null;
  const ele = [];
  const callback = function(e) {
    e.stopPropagation();
    store.loadOrToggle(row);
  };
  if (treeNode.indent) {
    ele.push(
      <span
        class="yxt-table__indent"
        style={{ paddingLeft: treeNode.indent + 'px' }}
      />
    );
  }
  if (typeof treeNode.expanded === 'boolean' && !treeNode.noLazyChildren) {
    const expandClasses = [
      'yxt-table__expand-icon color-gray-7',
      treeNode.expanded ? 'yxt-table__expand-icon--expanded' : ''
    ];

    ele.push(
      <div class={expandClasses} on-click={callback}>
        {/* <i class={iconClasses} /> */}
        {treeNode.loading ? <div class="yxt-loading-spinner">
          <svg class="circular" viewBox="25 25 50 50">
            <circle class="path" cx="50" cy="50" r="20" fill="none"/>
          </svg>
        </div>
          : <yxt-svg width="16px" height="16px" icon-class={treeNode.expanded ? 'arrow_up' : 'arrow_right'}></yxt-svg>}
      </div>
    );
  } else {
    ele.push(<span class="yxt-table__placeholder" />);
  }
  return ele;
}
