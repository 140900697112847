var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "li",
    {
      staticClass: "yxt-menu-item",
      class: {
        "is-active": _vm.active,
        "is-disabled": _vm.disabled,
      },
      style: [
        _vm.paddingStyle,
        _vm.itemStyle,
        { backgroundColor: _vm.backgroundColor },
      ],
      attrs: { role: "menuitem", tabindex: "-1" },
      on: {
        click: _vm.handleClick,
        mouseenter: _vm.onMouseEnter,
        focus: _vm.onMouseEnter,
        blur: _vm.onMouseLeave,
        mouseleave: _vm.onMouseLeave,
      },
    },
    [
      _vm.parentMenu.$options.componentName === "YxtfMenu" &&
      _vm.rootMenu.collapse &&
      _vm.$slots.title
        ? _c(
            "yxtf-tooltip",
            { attrs: { effect: "dark", placement: "right" } },
            [
              _c(
                "div",
                { attrs: { slot: "content" }, slot: "content" },
                [_vm._t("title")],
                2
              ),
              _c(
                "div",
                {
                  staticStyle: {
                    position: "absolute",
                    left: "0",
                    top: "0",
                    height: "100%",
                    width: "100%",
                    display: "inline-block",
                    "box-sizing": "border-box",
                    padding: "0 20px",
                  },
                },
                [_vm._t("default")],
                2
              ),
            ]
          )
        : [_vm._t("default"), _vm._t("title")],
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }