var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "transition",
    {
      attrs: { name: "yxt-zoom-in-top" },
      on: { "after-leave": _vm.doDestroy },
    },
    [
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.showPopper,
              expression: "showPopper",
            },
          ],
          staticClass: "yxt-color-dropdown",
        },
        [
          _c(
            "div",
            {
              staticClass: "yxt-color-dropdown__main-wrapper",
              staticStyle: { overflow: "hidden" },
            },
            [_c("sv-panel", { ref: "sl", attrs: { color: _vm.color } })],
            1
          ),
          _c("div", { staticClass: "yxt-color-dropdown__slider" }, [
            _c(
              "div",
              { staticClass: "yxt-color-dropdown__slider-left" },
              [
                _c("hue-slider", { ref: "hue", attrs: { color: _vm.color } }),
                _vm.showAlpha
                  ? _c("alpha-slider", {
                      ref: "alpha",
                      attrs: { color: _vm.color },
                    })
                  : _vm._e(),
              ],
              1
            ),
            _vm.showAlpha
              ? _c("div", {
                  staticClass: "yxt-color-dropdown__slider-right",
                  style: { backgroundColor: _vm.color.value },
                })
              : _vm._e(),
          ]),
          _c(
            "div",
            {
              staticClass: "yxt-color-dropdown__btns",
              style: {
                "justify-content": "space-between",
                "border-bottom": _vm.predefine ? "solid 1px #E9E9E9" : "",
              },
            },
            [
              _c(
                "span",
                {
                  staticClass: "yxt-color-dropdown__hex-value",
                  style: _vm.showAlpha
                    ? "line-height: 24px;"
                    : "line-height: 24px;",
                },
                [
                  _c("yxt-input", {
                    attrs: { "validate-event": false, size: "mini" },
                    on: {
                      blur: function ($event) {
                        _vm.handleConfirm(_vm.customInput)
                      },
                    },
                    nativeOn: {
                      keyup: function ($event) {
                        if (
                          !("button" in $event) &&
                          _vm._k(
                            $event.keyCode,
                            "enter",
                            13,
                            $event.key,
                            "Enter"
                          )
                        ) {
                          return null
                        }
                        _vm.handleConfirm(_vm.customInput)
                      },
                    },
                    model: {
                      value: _vm.customInput,
                      callback: function ($$v) {
                        _vm.customInput = $$v
                      },
                      expression: "customInput",
                    },
                  }),
                  _vm._v("\n        Hex\n      "),
                ],
                1
              ),
              _c(
                "span",
                {
                  staticClass: "yxt-color-dropdown__rgba-value",
                  style: _vm.showAlpha
                    ? "width:30px;line-height: 24px;"
                    : "width:40px;line-height: 24px;",
                },
                [
                  _c("yxt-input-number", {
                    attrs: {
                      controls: false,
                      min: 0,
                      max: 255,
                      "validate-event": false,
                      size: "mini",
                    },
                    on: {
                      blur: function ($event) {
                        _vm.handleConfirm()
                      },
                    },
                    nativeOn: {
                      keyup: function ($event) {
                        if (
                          !("button" in $event) &&
                          _vm._k(
                            $event.keyCode,
                            "enter",
                            13,
                            $event.key,
                            "Enter"
                          )
                        ) {
                          return null
                        }
                        _vm.handleConfirm()
                      },
                    },
                    model: {
                      value: _vm.rgbaColor.r,
                      callback: function ($$v) {
                        _vm.$set(_vm.rgbaColor, "r", $$v)
                      },
                      expression: "rgbaColor.r",
                    },
                  }),
                  _vm._v("\n        R\n      "),
                ],
                1
              ),
              _c(
                "span",
                {
                  staticClass: "yxt-color-dropdown__rgba-value",
                  style: _vm.showAlpha
                    ? "width:30px;line-height: 24px;"
                    : "width:40px;line-height: 24px;",
                },
                [
                  _c("yxt-input-number", {
                    attrs: {
                      min: 0,
                      max: 255,
                      controls: false,
                      "validate-event": false,
                      size: "mini",
                    },
                    on: {
                      blur: function ($event) {
                        _vm.handleConfirm()
                      },
                    },
                    nativeOn: {
                      keyup: function ($event) {
                        if (
                          !("button" in $event) &&
                          _vm._k(
                            $event.keyCode,
                            "enter",
                            13,
                            $event.key,
                            "Enter"
                          )
                        ) {
                          return null
                        }
                        _vm.handleConfirm()
                      },
                    },
                    model: {
                      value: _vm.rgbaColor.g,
                      callback: function ($$v) {
                        _vm.$set(_vm.rgbaColor, "g", $$v)
                      },
                      expression: "rgbaColor.g",
                    },
                  }),
                  _vm._v("\n        G\n      "),
                ],
                1
              ),
              _c(
                "span",
                {
                  staticClass: "yxt-color-dropdown__rgba-value",
                  style: _vm.showAlpha
                    ? "width:30px;line-height: 24px;"
                    : "width:40px;line-height: 24px;",
                },
                [
                  _c("yxt-input-number", {
                    attrs: {
                      controls: false,
                      min: 0,
                      max: 255,
                      "validate-event": false,
                      size: "mini",
                    },
                    on: {
                      blur: function ($event) {
                        _vm.handleConfirm()
                      },
                    },
                    nativeOn: {
                      keyup: function ($event) {
                        if (
                          !("button" in $event) &&
                          _vm._k(
                            $event.keyCode,
                            "enter",
                            13,
                            $event.key,
                            "Enter"
                          )
                        ) {
                          return null
                        }
                        _vm.handleConfirm()
                      },
                    },
                    model: {
                      value: _vm.rgbaColor.b,
                      callback: function ($$v) {
                        _vm.$set(_vm.rgbaColor, "b", $$v)
                      },
                      expression: "rgbaColor.b",
                    },
                  }),
                  _vm._v("\n        B\n      "),
                ],
                1
              ),
              _vm.showAlpha
                ? _c(
                    "span",
                    {
                      staticClass: "yxt-color-dropdown__rgba-value",
                      style: _vm.showAlpha
                        ? "width:30px;line-height: 24px;"
                        : "width:40px;line-height: 24px;",
                    },
                    [
                      _c("yxt-input-number", {
                        attrs: {
                          min: 0,
                          max: 100,
                          controls: false,
                          "validate-event": false,
                          size: "mini",
                        },
                        on: {
                          blur: function ($event) {
                            _vm.handleConfirm()
                          },
                        },
                        nativeOn: {
                          keyup: function ($event) {
                            if (
                              !("button" in $event) &&
                              _vm._k(
                                $event.keyCode,
                                "enter",
                                13,
                                $event.key,
                                "Enter"
                              )
                            ) {
                              return null
                            }
                            _vm.handleConfirm()
                          },
                        },
                        model: {
                          value: _vm.rgbaColor.a,
                          callback: function ($$v) {
                            _vm.$set(_vm.rgbaColor, "a", $$v)
                          },
                          expression: "rgbaColor.a",
                        },
                      }),
                      _vm._v("\n        A\n      "),
                    ],
                    1
                  )
                : _vm._e(),
            ]
          ),
          _vm.predefine
            ? _c("predefine", {
                ref: "predefineRef",
                attrs: { color: _vm.color, colors: _vm.predefine },
                on: {
                  creatColor: _vm.handleParseColor,
                  removePredefineIndex: _vm.handleRemovePredefineIndex,
                },
              })
            : _vm._e(),
          _vm.footBtn
            ? _c(
                "div",
                { staticClass: "slot_btn" },
                [
                  _c(
                    "yxt-button",
                    {
                      on: {
                        click: function ($event) {
                          _vm.showPopper = false
                        },
                      },
                    },
                    [_vm._v(_vm._s(_vm.t("pc_comp_datepicker_cancel")))]
                  ),
                  _c(
                    "yxt-button",
                    {
                      staticStyle: { "margin-left": "8px" },
                      attrs: { type: "primary" },
                      on: { click: _vm.addBtnColor },
                    },
                    [_vm._v(_vm._s(_vm.t("pc_comp_colorpicker_confirm")))]
                  ),
                ],
                1
              )
            : _vm._e(),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }