var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    _vm.tag,
    { tag: "component", staticClass: "yxtf-infinite-list" },
    [
      _vm._t("default"),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.loading,
              expression: "loading",
            },
          ],
          staticClass: "yxtf-infinite-list-loading",
          class: { "is-page": _vm.isPage },
        },
        [
          _vm._t("loading", [
            _c("span", { staticClass: "yxt-infinite-loading-circle" }, [
              _c("svg", { attrs: { viewBox: "0 0 16 16" } }, [
                _c("circle", {
                  staticClass: "path",
                  attrs: { cx: "8", cy: "8", r: "7", fill: "none" },
                }),
              ]),
            ]),
            _c("span", { staticClass: "color-gray-7 font-size-14" }, [
              _vm._v(_vm._s(_vm.loadingText)),
            ]),
          ]),
        ],
        2
      ),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.showFinishText && _vm.finished,
              expression: "showFinishText && finished",
            },
          ],
          staticClass: "yxtf-infinite-list-finished",
          class: { "is-page": _vm.isPage },
        },
        [
          _vm._t("finished", [
            _c("div", { staticClass: "font-size-14 color-gray-7" }, [
              _vm._v(_vm._s(_vm.finishedText)),
            ]),
          ]),
        ],
        2
      ),
      _c("div", { ref: "placeholder" }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }