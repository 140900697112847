var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "yxtf-image", style: _vm.wrapStyle },
    [
      _vm.loading
        ? _vm._t("placeholder", [
            _c("div", { staticClass: "yxtf-image__placeholder" }),
          ])
        : _vm.error
        ? _vm._t("error", [
            _c("div", { staticClass: "yxtf-image__error" }, [
              _c(
                "div",
                { staticClass: "yxtf-image__error-content" },
                [
                  _c("yxt-svg", {
                    attrs: {
                      width: "48",
                      height: "48",
                      "remote-url": _vm.svgRemoteUrl,
                      "icon-class": "img-failed",
                    },
                  }),
                  _c("div", { staticClass: "mt12" }, [
                    _vm._v(
                      "\n            " +
                        _vm._s(_vm.t("pc_comp_image_error")) +
                        "\n          "
                    ),
                  ]),
                ],
                1
              ),
            ]),
          ])
        : _vm.currentRatio === 0
        ? _c(
            "img",
            _vm._g(
              _vm._b(
                {
                  staticClass: "yxtf-image__inner",
                  class: {
                    "yxtf-image__inner--center": _vm.alignCenter,
                    "yxtf-image__preview": _vm.preview,
                  },
                  style: _vm.imageStyle,
                  attrs: { src: _vm.webpSrc },
                  on: { click: _vm.clickHandler },
                },
                "img",
                _vm.$attrs,
                false
              ),
              _vm.$listeners
            )
          )
        : _c(
            "div",
            {
              staticClass: "yxtf-image__ratio--container",
              style: _vm.ratioStyle,
            },
            [
              _c("div", { staticClass: "yxtf-image__ratio--inner" }, [
                _c(
                  "img",
                  _vm._g(
                    _vm._b(
                      {
                        staticClass: "yxtf-image__inner",
                        class: {
                          "yxtf-image__inner--center": _vm.alignCenter,
                          "yxtf-image__preview": _vm.preview,
                        },
                        style: _vm.imageStyle,
                        attrs: { src: _vm.webpSrc },
                        on: { click: _vm.clickHandler },
                      },
                      "img",
                      _vm.$attrs,
                      false
                    ),
                    _vm.$listeners
                  )
                ),
              ]),
            ]
          ),
      _vm.preview && _vm.showViewer
        ? _c("image-viewer", {
            attrs: {
              "z-index": _vm.zIndex,
              "on-close": _vm.closeViewer,
              "url-list": _vm.previewSrcList,
            },
          })
        : _vm._e(),
      _vm.$slots.overlay
        ? _c(
            "div",
            { staticClass: "yxtf-image__overlay" },
            [_vm._t("overlay")],
            2
          )
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }