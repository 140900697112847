var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "label",
    {
      staticClass: "yxt-radio-button",
      class: [
        _vm.size ? "yxt-radio-button--" + _vm.size : "",
        _vm.type ? "yxt-radio-button--" + _vm.type : "",
        { "is-active": _vm.value === _vm.label },
        { "is-disabled": _vm.isDisabled },
        { "is-focus": _vm.focus },
      ],
      attrs: {
        role: "radio",
        "aria-checked": _vm.value === _vm.label,
        "aria-disabled": _vm.isDisabled,
        tabindex: _vm.tabIndex,
      },
      on: {
        keydown: function ($event) {
          if (
            !("button" in $event) &&
            _vm._k($event.keyCode, "space", 32, $event.key, [" ", "Spacebar"])
          ) {
            return null
          }
          $event.stopPropagation()
          $event.preventDefault()
          _vm.value = _vm.isDisabled ? _vm.value : _vm.label
        },
      },
    },
    [
      _c("input", {
        directives: [
          {
            name: "model",
            rawName: "v-model",
            value: _vm.value,
            expression: "value",
          },
        ],
        staticClass: "yxt-radio-button__orig-radio",
        attrs: {
          type: "radio",
          name: _vm.name,
          disabled: _vm.isDisabled,
          tabindex: "-1",
        },
        domProps: { value: _vm.label, checked: _vm._q(_vm.value, _vm.label) },
        on: {
          change: [
            function ($event) {
              _vm.value = _vm.label
            },
            _vm.handleChange,
          ],
          focus: function ($event) {
            _vm.focus = true
          },
          blur: function ($event) {
            _vm.focus = false
          },
        },
      }),
      _c(
        "span",
        {
          staticClass: "yxt-radio-button__inner",
          style: _vm.value === _vm.label ? _vm.activeStyle : null,
          on: {
            keydown: function ($event) {
              $event.stopPropagation()
            },
          },
        },
        [
          _vm._t("default"),
          !_vm.$slots.default ? [_vm._v(_vm._s(_vm.label))] : _vm._e(),
        ],
        2
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }