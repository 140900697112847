var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "transition",
    {
      attrs: { name: "yxt-zoom-in-top" },
      on: {
        "after-leave": function ($event) {
          _vm.$emit("dodestroy")
        },
      },
    },
    [
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.visible,
              expression: "visible",
            },
          ],
          staticClass: "yxt-time-range-picker yxt-picker-panel yxt-popper",
          class: _vm.popperClass,
        },
        [
          _c(
            "div",
            { staticClass: "yxt-time-range-picker__content" },
            [
              _c("time-spinner", {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.isStart,
                    expression: "isStart",
                  },
                ],
                ref: "minSpinner",
                attrs: {
                  "show-seconds": _vm.showSeconds,
                  "am-pm-mode": _vm.amPmMode,
                  "arrow-control": _vm.arrowControl,
                  date: _vm.minDate,
                },
                on: {
                  change: _vm.handleMinChange,
                  "select-range": _vm.setMinSelectionRange,
                  placeholder: _vm.handlePlaceholder,
                },
              }),
              _c("time-spinner", {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: !_vm.isStart,
                    expression: "!isStart",
                  },
                ],
                ref: "maxSpinner",
                attrs: {
                  "show-seconds": _vm.showSeconds,
                  "am-pm-mode": _vm.amPmMode,
                  "arrow-control": _vm.arrowControl,
                  date: _vm.maxDate,
                },
                on: {
                  change: _vm.handleMaxChange,
                  "select-range": _vm.setMaxSelectionRange,
                  placeholder: _vm.handlePlaceholder,
                },
              }),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "yxt-time-panel__footer" },
            [
              _c(
                "yxt-button",
                {
                  attrs: {
                    type: "text2",
                    size: "small",
                    disabled: _vm.nowDisabled,
                  },
                  on: {
                    click: function ($event) {
                      _vm.handleCurrent()
                    },
                  },
                },
                [_vm._v(_vm._s(_vm.t("pc_comp_datepicker_now")))]
              ),
              _c(
                "yxt-button",
                {
                  staticClass: "yxt-time-panel__btn confirm",
                  attrs: {
                    type: "primary",
                    size: "small",
                    disabled: _vm.btnDisabled,
                  },
                  on: {
                    click: function ($event) {
                      _vm.handleConfirm()
                    },
                  },
                },
                [_vm._v(_vm._s(_vm.t("pc_comp_datepicker_confirm")))]
              ),
            ],
            1
          ),
        ]
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }