<template>
  <ul class="yxt-select-group__wrap" v-show="visible">
    <li class="yxt-select-group__title">{{ label }}</li>
    <li>
      <ul class="yxt-select-group">
        <slot></slot>
      </ul>
    </li>
  </ul>
</template>

<script type="text/babel">
  import Emitter from '@utils/mixins/emitter';

  export default {
    mixins: [Emitter],

    name: 'YxtOptionGroup',

    componentName: 'YxtOptionGroup',

    props: {
      label: String,
      disabled: {
        type: Boolean,
        default: false
      }
    },

    data() {
      return {
        visible: true
      };
    },

    watch: {
      disabled(val) {
        this.broadcast('YxtOption', 'handleGroupDisabled', val);
      }
    },

    methods: {
      queryChange() {
        this.visible = this.$children &&
          Array.isArray(this.$children) &&
          this.$children.some(option => option.visible === true);
      }
    },

    created() {
      this.$on('queryChange', this.queryChange);
    },

    mounted() {
      if (this.disabled) {
        this.broadcast('YxtOption', 'handleGroupDisabled', this.disabled);
      }
    }
  };
</script>
