<template>
  <span class="lc-base-text">
    {{ content }}
  </span>
</template>
<script>
export default {
  name: 'YxtText',
  props: {
    content: {
      type: [String, Number],
      default: '文本区'
    }
  }
};
</script>
