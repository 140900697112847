var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return !_vm.lazy || _vm.loaded || _vm.active
    ? _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.active,
              expression: "active",
            },
          ],
          staticClass: "yxtf-tab-pane",
          attrs: {
            role: "tabpanel",
            "aria-hidden": !_vm.active,
            id: "pane-" + _vm.paneName,
            "aria-labelledby": "tab-" + _vm.paneName,
          },
        },
        [_vm._t("default")],
        2
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }