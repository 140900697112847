var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "transition",
    {
      attrs: { name: "dialog-fade" },
      on: { "after-enter": _vm.afterEnter, "after-leave": _vm.afterLeave },
    },
    [
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.visible,
              expression: "visible",
            },
          ],
          staticClass: "yxtf-dialog__wrapper",
          on: {
            click: function ($event) {
              if ($event.target !== $event.currentTarget) {
                return null
              }
              return _vm.handleWrapperClick($event)
            },
          },
        },
        [
          _c(
            "div",
            {
              key: _vm.key,
              ref: "dialog",
              class: [
                "yxtf-dialog",
                {
                  "is-fullscreen": _vm.fullscreen,
                  "yxtf-dialog--center": _vm.center,
                  "yxtf-dialog--cutline": _vm.cutline,
                  "yxtf-dialog--larger": _vm.paddingSize == "larger",
                  "yxtf-dialog--medium": _vm.paddingSize == "medium",
                  "yxtf-dialog--small": _vm.paddingSize == "small",
                  "yxtf-dialog--empty": _vm.paddingSize == "empty",
                },
                _vm.customClass,
              ],
              style: _vm.style,
              attrs: {
                role: "dialog",
                "aria-modal": "true",
                "aria-label": _vm.title || "dialog",
              },
            },
            [
              _vm.$slots.title || _vm.title
                ? _c(
                    "div",
                    { ref: "dialogHeader", staticClass: "yxtf-dialog__header" },
                    [
                      _vm._t("title", [
                        _c("span", { staticClass: "yxtf-dialog__title" }, [
                          _vm._v(_vm._s(_vm.title)),
                        ]),
                      ]),
                    ],
                    2
                  )
                : _vm._e(),
              _vm.showClose
                ? _c(
                    "button",
                    {
                      staticClass: "yxtf-dialog__headerbtn",
                      staticStyle: { "z-index": "999" },
                      attrs: { type: "button", "aria-label": "Close" },
                      on: { click: _vm.handleClose },
                    },
                    [
                      _c("i", {
                        staticClass:
                          "yxtf-dialog__close yxtf-icon yxtf-icon-close",
                      }),
                    ]
                  )
                : _vm._e(),
              _vm.rendered
                ? _c(
                    "div",
                    { ref: "dialogBody", staticClass: "yxtf-dialog__body" },
                    [_vm._t("default")],
                    2
                  )
                : _vm._e(),
              _vm.$slots.footer
                ? _c(
                    "div",
                    { staticClass: "yxtf-dialog__footer" },
                    [_vm._t("footer")],
                    2
                  )
                : _vm._e(),
            ]
          ),
        ]
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }