<template>
  <div
    class="yxtf-portrait"
    :style="style"
    :class="classes"
    @click="onClick"
  >
    <i class="yxtf-portrait__content" v-if="(!imgUrl || isLoadErr)">
      {{ last }}
    </i>
    <yxtf-image @error="onImgLoadError" :src="getFilteredImg(this.imgUrl)" fit="cover" v-else></yxtf-image>
  </div>
</template>
<script>
import YxtfImg from '../../image';
export default {
  name: 'YxtfPortrait',
  components: {
    YxtfImg
  },
  props: {
    size: {
      type: String,
      default: 'medium'
    },
    backgroundColor: {
      type: String,
      default: '#436dff'
    },
    color: {
      type: String,
      default: '#fff'
    },
    imgUrl: {
      type: String,
      default: ''
    },
    username: {
      type: String,
      default: ''
    },
    targetHref: {
      type: String,
      default: ''
    },
    fontSize: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      isLoadErr: false
    };
  },
  computed: {
    last() {
      let text = this.username.trim();
      if (!text || typeof text !== 'string') {
        return null;
      }
      return text.substr(text.length - 1, 1);
    },
    style() {
      let style = {};

      const hasCustomSize = !['large', 'medium', 'small'].includes(this.size);
      if (hasCustomSize) {
        style.width = this.size;
        style.height = this.size;
        style.lineHeight = this.size;
        style.fontSize = this.fontSize ? this.fontSize : `${parseFloat(this.size) * 0.4}px`;
      }
      return style;
    },
    classes() {
      const size = ['large', 'medium', 'small'].includes(this.size) ? this.size : 'medium';
      const classes = [`yxt-portrait--${size}`];
      return classes;
    }
  },
  methods: {
    getFilteredImg(url) {
      if (url && !!~url.indexOf('picobd') && !~url.indexOf('.gif')) {
        return `${url}?x-bce-process=image/resize,w_400/auto-orient,o_1`;
      } else {
        return url;
      }
    },
    onClick(e) {
      if (this.targetHref) {
        window.location.href = this.targetHref;
        return;
      }
      this.$emit('click', e);
    },
    onImgLoadError() {
      this.isLoadErr = true;
    }
  },
  mounted() {
  }
};
</script>
