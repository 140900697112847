var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "yxtf-page-header",
      class: {
        "yxtf-page-header--sub": _vm.subLevel,
        "yxtf-page-header--suback": _vm.subLevel && _vm.showBack,
      },
    },
    [
      _c("div", { staticClass: "yxtf-page-header__top" }, [
        _c(
          "div",
          {
            staticClass: "yxtf-page-header__left",
            class: _vm.showBack ? "hand" : "",
            on: {
              click: function ($event) {
                _vm.showBack && _vm.$emit("back")
              },
            },
          },
          [
            _vm.showBack
              ? _c(
                  "span",
                  { staticClass: "yxtf-page-header__back" },
                  [
                    _c("yxt-svg", {
                      staticClass: "color-gray-9",
                      attrs: {
                        width: "16px",
                        height: "16px",
                        "icon-class": "arrow-left",
                      },
                    }),
                  ],
                  1
                )
              : _vm._e(),
            _c(
              "div",
              { staticClass: "yxtf-page-header__title color-gray-10" },
              [_vm._t("title", [_vm._v(_vm._s(_vm.title))])],
              2
            ),
          ]
        ),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }