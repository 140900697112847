var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "clickoutside",
          rawName: "v-clickoutside",
          value: _vm.hide,
          expression: "hide",
        },
      ],
      class: [
        "yxt-color-picker",
        _vm.colorDisabled ? "is-disabled" : "",
        _vm.colorSize ? "yxt-color-picker--" + _vm.colorSize : "",
      ],
      style: _vm.type === "strip" ? "height: 33px !important;" : "",
    },
    [
      _vm.colorDisabled
        ? _c("div", { staticClass: "yxt-color-picker__mask" })
        : _vm._e(),
      _vm.type === "default"
        ? _c(
            "div",
            {
              staticClass: "yxt-color-picker__trigger",
              style: _vm.bg_border,
              on: {
                click: _vm.handleTrigger,
                mouseover: _vm.mouseover,
                mouseout: _vm.mouseout,
              },
            },
            [
              _c(
                "span",
                {
                  class: {
                    "yxt-color-picker__color": true,
                    "is-alpha": _vm.showAlpha,
                  },
                  staticStyle: {
                    height: "101%",
                    "border-radius": "2px",
                    border: "none",
                  },
                },
                [
                  _c("span", {
                    staticClass: "yxt-color-picker__color-inner",
                    style: {
                      backgroundColor: _vm.displayedColor,
                      border: "solid 1px rgba(0,0,0, 0.06)",
                      "border-radius": "2px",
                    },
                  }),
                  !_vm.value && !_vm.showPanelColor
                    ? _c("span", {
                        staticClass: "yxt-color-picker__empty yxt-icon-close",
                      })
                    : _vm._e(),
                ]
              ),
              _c("span", {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.value || _vm.showPanelColor,
                    expression: "value || showPanelColor",
                  },
                ],
                staticClass: "yxt-color-picker__icon",
              }),
            ]
          )
        : _vm._e(),
      _vm.type === "strip"
        ? _c(
            "div",
            {
              staticClass: "yxt-color-picker__input yxt-color-picker__trigger",
              style: _vm.bg_border,
              on: {
                click: _vm.handleTrigger,
                mouseover: _vm.mouseover,
                mouseout: _vm.mouseout,
              },
            },
            [
              _c(
                "span",
                {
                  staticClass: "yxt-color-picker__color",
                  class: { "is-alpha": _vm.showAlpha },
                  staticStyle: {
                    "background-size": "14px 14px",
                    "background-repeat": "no-repeat",
                    "border-radius": "4px",
                  },
                },
                [
                  _c("span", {
                    staticClass: "yxt-color-picker__color-inner",
                    style: {
                      backgroundColor: _vm.displayedColor,
                      borderRadius: "2px",
                      border: "solid 1px rgba(0,0,0, 0.06)",
                      margin: "-1px",
                    },
                  }),
                ]
              ),
              _c(
                "span",
                { staticClass: "yxt-color-picker__color-inner" },
                [
                  _c("yxt-input", {
                    attrs: { placeholder: "#000000" },
                    model: {
                      value: _vm.showColor,
                      callback: function ($$v) {
                        _vm.showColor = $$v
                      },
                      expression: "showColor",
                    },
                  }),
                ],
                1
              ),
            ]
          )
        : _vm._e(),
      _vm.type === "colour"
        ? _c("div", {
            staticClass: "yxt-color-picker__svg",
            on: {
              click: _vm.handleTrigger,
              mouseover: _vm.mouseover,
              mouseout: _vm.mouseout,
            },
          })
        : _vm._e(),
      _c("picker-dropdown", {
        ref: "dropdown",
        class: ["yxt-color-picker__panel", _vm.popperClass || ""],
        staticStyle: { border: "none" },
        attrs: {
          "foot-btn": _vm.footBtn,
          color: _vm.color,
          "show-alpha": _vm.showAlpha,
          predefine: _vm.predefine,
        },
        on: {
          pick: _vm.confirmValue,
          clear: _vm.clearValue,
          "predefine-change": _vm.predefineChange,
        },
        model: {
          value: _vm.showPicker,
          callback: function ($$v) {
            _vm.showPicker = $$v
          },
          expression: "showPicker",
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }