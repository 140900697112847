<template>
  <li
    @mouseenter="hoverItem"
    @mousemove="enableMouseEnter"
    @click.stop="selectOptionClick"
    class="yxtf-select-dropdown__item"
    v-show="visible"
    :class="{
      selected: itemSelected,
      hover: isHover,
      'is-disabled': disabled || groupDisabled || limitReached
    }"
  >
    <slot>
      <span>{{ currentLabel }}</span>
    </slot>
  </li>
</template>

<script type="text/babel">
import Emitter from '@utils/mixins/emitter';
import { getValueByPath, escapeRegexpString } from '@utils/utils/util';

export default {
  mixins: [Emitter],

  name: 'YxtfOption',

  componentName: 'YxtfOption',

  inject: ['select'],

  props: {
    value: {
      required: true
    },
    label: [String, Number],
    labelProp: {
      type: String,
      default: ''
    },
    created: Boolean,
    disabled: {
      type: Boolean,
      default: false
    }
  },

  data() {
    return {
      index: -1,
      groupDisabled: false,
      visible: true,
      hitState: false,
      hover: false
    };
  },

  computed: {
    isObject() {
      return (
        Object.prototype.toString.call(this.value).toLowerCase() ===
        '[object object]'
      );
    },

    currentLabel() {
      return this.label || (this.isObject ? '' : this.value);
    },

    isHover() {
      return this.select.options.indexOf(this) === this.select.hoverIndex;
    },

    currentValue() {
      return this.value || this.label || '';
    },

    itemSelected() {
      if (!this.select.multiple) {
        return this.isEqual(this.value, this.select.value);
      } else {
        return this.contains(this.select.value, this.value);
      }
    },

    limitReached() {
      if (this.select.multiple) {
        return (
          !this.itemSelected &&
          (this.select.value || []).length >= this.select.multipleLimit &&
          this.select.multipleLimit > 0
        );
      } else {
        return false;
      }
    }
  },

  watch: {
    currentLabel() {
      if (!this.created && !this.select.remote) {
        this.dispatch('YxtfSelect', 'setSelected');
      }
    },
    value(val, oldVal) {
      const { remote, valueKey } = this.select;
      if (!this.created && !remote) {
        if (
          valueKey &&
          typeof val === 'object' &&
          typeof oldVal === 'object' &&
          val[valueKey] === oldVal[valueKey]
        ) {
          return;
        }
        this.dispatch('YxtfSelect', 'setSelected');
      }
    }
  },

  methods: {
    isEqual(a, b) {
      if (!this.isObject) {
        return a === b;
      } else {
        const valueKey = this.select.valueKey;
        return getValueByPath(a, valueKey) === getValueByPath(b, valueKey);
      }
    },

    contains(arr = [], target) {
      if (!this.isObject) {
        return arr && arr.indexOf(target) > -1;
      } else {
        const valueKey = this.select.valueKey;
        return (
          arr &&
          arr.some(item => {
            return (
              getValueByPath(item, valueKey) ===
              getValueByPath(target, valueKey)
            );
          })
        );
      }
    },

    handleGroupDisabled(val) {
      this.groupDisabled = val;
    },

    hoverItem() {
      if (!this.disabled && !this.groupDisabled && this.select.mouseEnterEnable) {
        this.select.hoverIndex = this.select.options.indexOf(this);
      }
    },

    enableMouseEnter() {
      if (!this.select.mouseEnterEnable) {
        this.select.mouseEnterEnable = true;
        this.hoverItem();
      }
    },

    selectOptionClick() {
      if (this.disabled !== true && this.groupDisabled !== true) {
        this.dispatch('YxtfSelect', 'handleOptionClick', [this, true]);
      }
    },

    queryChange(query) {
      this.visible =
        new RegExp(escapeRegexpString(query), 'i').test(this.currentLabel) ||
        this.created;
      if (!this.visible) {
        this.select.filteredOptionsCount--;
      }
    }
  },

  created() {
    this.select.options.push(this);
    this.select.cachedOptions.push(this);
    this.select.optionsCount++;
    this.select.filteredOptionsCount++;

    this.$on('queryChange', this.queryChange);
    this.$on('handleGroupDisabled', this.handleGroupDisabled);
  },

  beforeDestroy() {
    const { selected, multiple } = this.select;
    let selectedOptions = multiple ? selected : [selected];
    let index = this.select.cachedOptions.indexOf(this);
    let selectedIndex = selectedOptions.indexOf(this);

    // 如果选项没有被选中, 将其从缓存移除
    if (index > -1 && selectedIndex < 0) {
      this.select.cachedOptions.splice(index, 1);
    }
    this.select.onOptionDestroy(this.select.options.indexOf(this));
  }
};
</script>
