var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    _vm._elTag,
    {
      tag: "component",
      class: [
        "yxtf-radio-group",
        { "yxtf-radio-group--row": this.direction === "row" },
      ],
      attrs: { role: "radiogroup" },
      on: { keydown: _vm.handleKeydown },
    },
    [_vm._t("default")],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }