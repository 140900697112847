var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "transition",
    {
      attrs: { name: "yxt-zoom-in-top" },
      on: {
        "before-enter": _vm.handleMenuEnter,
        "after-leave": function ($event) {
          _vm.$emit("dodestroy")
        },
      },
    },
    [
      _c("time-select", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.visible,
            expression: "visible",
          },
        ],
        ref: "select",
        attrs: {
          popperClass: _vm.popperClass,
          start: _vm.start,
          end: _vm.end,
          step: _vm.step,
          value: _vm.value,
          "default-value": _vm.defaultValue,
          "min-time": _vm.minTime,
          "max-time": _vm.maxTime,
        },
        on: { pick: _vm.handlePick, placeholder: _vm.handlePlaceHolder },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }