<template>
  <svg
    :style="style"
    class="yxt-svg-icon"
    aria-hidden="true"
  >
  </svg>
</template>

<script>
import icons from './icons';
import iconMap from './iconMap';
import { objToParam } from '@utils/utils/util';
export default {
  name: 'YxtSvg',
  props: {
    iconClass: {
      type: String,
      required: true
    },
    width: {
      default: ''
    },
    height: {
      default: ''
    },
    title: {
      default: ''
    },
    fill: {
      default: ''
    },
    remoteUrl: {
      default: '',
      type: String
    },
    query: {
      default: null,
      type: Object
    }
  },
  computed: {
    iconName() {
      return `#yxt-common-${this.iconClass}`;
    },
    style() {
      let style = { display: 'inline-block' };

      if (this.width) {
        const width = (typeof this.width) === 'number' ? `${this.width}px` : this.width;
        style.width = width;
      }
      if (this.height) {
        const height = (typeof this.height) === 'number' ? `${this.height}px` : this.height;
        style.height = height;
      }
      if (this.fill) {
        style.fill = this.fill;
      }

      return style;
    },
    iconPath() {
      // const imagesBaseUrl = (typeof window !== 'undefined' && window.feConfig && window.feConfig.common && window.feConfig.common.imagesBaseUrl) || 'https://images.yxt.com/';
      const staticBaseUrl = (typeof window !== 'undefined' && window.feConfig && window.feConfig.common && window.feConfig.common.staticBaseUrl) || 'https://stc.yxt.com/';
      const stcUrl = `${staticBaseUrl}ufd/3f5568/common/pc_backstage/svg`;
      const remoteUrl = this.remoteUrl || stcUrl;
      const fileName = this.remoteUrl ? this.iconClass : iconMap[this.iconClass];
      if (!fileName) {
        console.error(`不存在icon-class为‘${this.iconClass}’的图标`);
        return false;
      }
      let resUrl = `${this.formatUrl(remoteUrl)}${fileName}.svg`;
      const params = objToParam(this.query);
      if (params) {
        resUrl = `${resUrl}?${params}`;
      }
      return resUrl;
    }
  },
  mounted() {
    this.getIcon();
  },
  methods: {
    getIcon() {
      if (!this.iconPath) return; // 未匹配到svg路径，不执行请求
      const icon = icons[this.iconPath];
      if (icon === undefined || icon === '') {
        icons[this.iconPath] = this.loadSvg();
        icons[this.iconPath]
          .then(res => {
            this.setData(res);
          })
          .catch(e => {
            console.log(e);
          });
      } else if (typeof icon === 'string') {
        this.setData(icons[this.iconPath]);
      } else {
        icon
          .then(res => {
            this.setData(res);
          })
          .catch(e => {
            console.log(e);
          });
      }
    },
    loadSvg() {
      /* eslint-disable no-undef */
      return new Promise((resolve, reject) => {
        const request = new XMLHttpRequest();
        request.open('GET', this.iconPath, true);
        request.onload = () => {
          this.$el.innerHTML = '';
          if (request.status >= 200 && request.status < 400) {
            // Setup a DOM parser to convert the response to text/xml
            let res = request.responseText;
            icons[this.iconPath] = res;
            this.setDiv(res);
            resolve(res);
          } else {
            icons[this.iconPath] = '';
            reject('There was an error retrieving the source of the SVG.');
          }
        };

        request.onerror = function() {
          icons[this.iconPath] = '';
          reject('There was on XML Http Request');
        };

        request.send();
      });
    },
    setDiv(node) {
      let container = document.querySelector('#common-svg-preview');
      if (container) {
        container.innerHTML += node;
      } else {
        container = document.createElement('div');
        container.id = 'common-svg-preview';
        container.style.position = 'absolute';
        container.style.overflow = 'hidden';
        container.style.width = 0;
        container.style.height = 0;
        const first = document.body.firstChild;
        document.body.insertBefore(container, first);
        container.innerHTML += node;
      }
    },
    setData(data) {
      while (this.$el.firstChild) {
        this.$el.removeChild(this.$el.firstChild);
      }
      const domParser = new DOMParser();
      const result = domParser.parseFromString(data, 'text/xml');
      const loadedSVG = result.getElementsByTagName('svg')[0];
      if (!loadedSVG) {
        console.error(
          'No SVG element found in the given file: ' + this.iconPath
        );
        return;
      }

      // add attributes to the inline SVG
      const len = loadedSVG.attributes.length;
      for (let i = 0; i < len; i++) {
        let it = loadedSVG.attributes[i];
        // 属性名
        let name = it.localName;
        // 属性值
        let value = it.value;
        this.$el.setAttribute(name, value);
      }
      this.$el.style.width = this.width;
      this.$el.style.height = this.height;

      if (this.title) {
        let title = document.createElementNS('http://www.w3.org/2000/svg', 'title');
        title.textContent = this.title;
        this.$el.appendChild(title);
      }

      // add child nodes to the inline SVG
      const domN = loadedSVG.childNodes.length;
      for (let i = 0; i <= domN - 1; i++) {
        const originNode = loadedSVG.childNodes.item(i);
        const node = originNode.cloneNode(true);
        this.$el.appendChild(node);
      }
    },
    // 如传入的url末尾未加'/'，则自动添加'/'，
    formatUrl(url) {
      return url.charAt(url.length - 1) === '/' ? url : `${url}/`;
    }
  },
  watch: {
    iconClass() {
      this.getIcon();
    }
  }
};
</script>
