<script>
  import Emitter from '@utils/mixins/emitter';

  export default {
    name: 'YxtCheckboxGroup',

    componentName: 'YxtCheckboxGroup',

    mixins: [Emitter],

    inject: {
      yxtformItem: {
        default: ''
      }
    },

    props: {
      value: {
        desc: '绑定值'
      },
      disabled: {
        type: Boolean,
        desc: '是否禁用'
      },
      min: {
        type: Number,
        desc: '勾选的最小数量'
      },
      max: {
        type: Number,
        desc: '勾选的最大数量'
      },
      size: {
        type: String,
        desc: '尺寸'
      },
      fill: {
        type: String,
        desc: '激活时的填充色和边框色(按钮形式)'
      },
      textColor: {
        type: String,
        desc: '激活时的文本颜色(按钮形式)'
      },
      direction: {
        type: String,
        default: 'column',
        desc: '排布方式'
      }
    },

    computed: {
      _yxtformItemSize() {
        return (this.yxtformItem || {}).yxtformItemSize;
      },
      checkboxGroupSize() {
        return this.size || this._yxtformItemSize || (this.$ELEMENT || {}).size;
      }
    },

    watch: {
      value(value) {
        this.dispatch('YxtFormItem', 'yxt.form.change', [value]);
      }
    }
  };
</script>

<template>
  <div :class="['yxt-checkbox-group', { 'yxt-checkbox-group--row': this.direction === 'row' }]" role="group" aria-label="checkbox-group">
    <slot></slot>
  </div>
</template>
