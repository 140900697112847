// 特殊皮肤处理
const SepcialTheme = {
  '#000000': {
    '--color-primary': '#292929',
    '--color-primary-1': '#EBEBEB',
    '--color-primary-2': '#E0E0E0',
    '--color-primary-3': '#BFBFBF',
    '--color-primary-4': '#999999',
    '--color-primary-5': '#707070',
    '--color-primary-6': '#292929',
    '--color-primary-7': '#141414',
    '--color-primary-8': '#0A0A0A',
    '--color-primary-9': '#000000',
    '--color-primary-10': '#000000',
    '--color-primary-light-1': '#333333',
    '--color-primary-light-2': '#5C5C5C',
    '--color-primary-light-3': '#7A7A7A',
    '--color-primary-light-4': '#949494',
    '--color-primary-light-5': '#ADADAD',
    '--color-primary-light-6': '#C7C7C7',
    '--color-primary-light-7': '#DBDBDB',
    '--color-primary-light-8': '#E6E6E6',
    '--color-primary-light-9': '#F0F0F0',
    '--color-primary-white-5': '#ADADAD',
    '--color-primary-white-8': '#5C5C5C',
    '--color-primary-white-10': '#333333',
    '--slider-button-active-color': '#0A0A0A',
    '--slider-button-hover-color': '#333333'
  }
};
export {SepcialTheme};
