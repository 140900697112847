var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      ref: "anchorRef",
      staticClass: "yxt-anchor",
      class: [
        "yxt-anchor--" + _vm.direction,
        this.type === "underline" ? "yxt-anchor--underline" : "",
      ],
    },
    [
      _vm.marker
        ? _c("div", {
            ref: "markerRef",
            staticClass: "yxt-anchor__marker",
            style: _vm.markerStyle,
          })
        : _vm._e(),
      _c("div", { staticClass: "yxt-anchor__list" }, [_vm._t("default")], 2),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }