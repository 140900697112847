var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return !_vm.ranged
    ? _c(
        "yxtf-input",
        _vm._b(
          {
            directives: [
              {
                name: "clickoutside",
                rawName: "v-clickoutside",
                value: _vm.handleClose,
                expression: "handleClose",
              },
            ],
            ref: "reference",
            staticClass: "yxtf-date-editor",
            class: "yxtf-date-editor--" + _vm.type,
            attrs: {
              readonly:
                !_vm.editable ||
                _vm.readonly ||
                _vm.type === "dates" ||
                _vm.type === "week",
              disabled: _vm.pickerDisabled,
              size: _vm.pickerSize,
              name: _vm.name,
              placeholder: _vm.placeholder,
              value: _vm.displayValue,
              validateEvent: false,
            },
            on: {
              focus: _vm.handleFocus,
              input: function (value) {
                return (_vm.userInput = value)
              },
              change: _vm.handleChange,
            },
            nativeOn: {
              keydown: function ($event) {
                return _vm.handleKeydown($event)
              },
              mouseenter: function ($event) {
                return _vm.handleMouseEnter($event)
              },
              mouseleave: function ($event) {
                _vm.showClose = false
              },
            },
          },
          "yxtf-input",
          _vm.firstInputId,
          false
        ),
        [
          _c("i", {
            staticClass: "yxtf-input__icon",
            class: _vm.triggerClass,
            attrs: { slot: "prefix" },
            on: { click: _vm.handleFocus },
            slot: "prefix",
          }),
          _vm.haveTrigger
            ? _c("i", {
                staticClass: "yxtf-input__icon",
                class: [_vm.showClose ? "" + _vm.clearIcon : ""],
                attrs: { slot: "suffix" },
                on: { click: _vm.handleClickIcon },
                slot: "suffix",
              })
            : _vm._e(),
        ]
      )
    : _c(
        "div",
        {
          directives: [
            {
              name: "clickoutside",
              rawName: "v-clickoutside",
              value: _vm.handleClose,
              expression: "handleClose",
            },
          ],
          ref: "reference",
          staticClass: "yxtf-date-editor yxtf-range-editor yxtf-input__inner",
          class: [
            "yxtf-date-editor--" + _vm.type,
            _vm.pickerSize ? "yxtf-range-editor--" + _vm.pickerSize : "",
            _vm.pickerDisabled ? "is-disabled" : "",
            _vm.pickerVisible ? "is-active" : "",
            _vm.isFocus ? "is-focus" : "",
          ],
          on: {
            click: _vm.handleRangeClick,
            mouseenter: _vm.handleMouseEnter,
            mouseleave: function ($event) {
              _vm.showClose = false
            },
            keydown: _vm.handleKeydown,
          },
        },
        [
          _c("i", {
            class: ["yxtf-input__icon", "yxtf-range__icon", _vm.triggerClass],
          }),
          _c(
            "input",
            _vm._b(
              {
                staticClass: "yxtf-range-input",
                attrs: {
                  autocomplete: "off",
                  placeholder: _vm.startPlaceholder,
                  disabled: _vm.pickerDisabled,
                  readonly: !_vm.editable || _vm.readonly,
                  name: _vm.name && _vm.name[0],
                },
                domProps: { value: _vm.displayValue && _vm.displayValue[0] },
                on: {
                  input: _vm.handleStartInput,
                  change: _vm.handleStartChange,
                  focus: _vm.handleFocus,
                  blur: _vm.handleBlur,
                },
              },
              "input",
              _vm.firstInputId,
              false
            )
          ),
          _vm._t("range-separator", [
            _c("span", { staticClass: "yxtf-range-separator" }, [
              _vm._v(_vm._s(_vm.rangeSeparator)),
            ]),
          ]),
          _c(
            "input",
            _vm._b(
              {
                staticClass: "yxtf-range-input",
                attrs: {
                  autocomplete: "off",
                  placeholder: _vm.endPlaceholder,
                  disabled: _vm.pickerDisabled,
                  readonly: !_vm.editable || _vm.readonly,
                  name: _vm.name && _vm.name[1],
                },
                domProps: { value: _vm.displayValue && _vm.displayValue[1] },
                on: {
                  input: _vm.handleEndInput,
                  change: _vm.handleEndChange,
                  focus: _vm.handleFocus,
                  blur: _vm.handleBlur,
                },
              },
              "input",
              _vm.secondInputId,
              false
            )
          ),
          _vm.haveTrigger
            ? _c("i", {
                staticClass: "yxtf-input__icon yxtf-range__close-icon",
                class: [_vm.showClose ? "" + _vm.clearIcon : ""],
                on: { click: _vm.handleClickIcon },
              })
            : _vm._e(),
        ],
        2
      )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }