<template>
  <div :class="[
    isTextarea ? 'yxtf-textarea' : 'yxtf-input',
    inputSize ? 'yxtf-input--' + inputSize : '',
    {
      'is-ie': isIE,
      'is-ie-focus': isIE && focused,
      'is-search': searchable,
      'is-limit': isWordLimitVisible,
      'is-limit--big': isWordLimitVisible && upperLimit >= 100,
      'is-disabled': inputDisabled,
      'is-error': inputError,
      'is-exceed': inputExceed,
      'yxtf-input-group': $slots.prepend || $slots.append,
      'yxtf-input-group--append': $slots.append,
      'yxtf-input-group--prepend': $slots.prepend,
      'yxtf-input--prefix': $slots.prefix || prefixIcon,
      'yxtf-input--suffix': $slots.suffix || suffixIcon || clearable || showPassword
    }
    ]"
    @mouseenter="hovering = true"
    @mouseleave="hovering = false"
  >
    <template v-if="!isTextarea">
      <!-- 前置元素 -->
      <div class="yxtf-input-group__prepend" v-if="$slots.prepend">
        <slot name="prepend"></slot>
      </div>
      <input
        :tabindex="tabindex"
        class="yxtf-input__inner"
        :class="[`yxtf-input__inner--${styleType}`, {'yxtf-input__inner--primary': pendType === 'primary' }, {'yxtf-input__inner--radusfull': radusType === 'full'}]"
        v-bind="$attrs"
        :type="showPassword ? (passwordVisible ? 'text': 'password') : type"
        :disabled="inputDisabled"
        :readonly="readonly"
        :autocomplete="autoComplete || autocomplete"
        ref="input"
        @compositionstart="handleCompositionStart"
        @compositionupdate="handleCompositionUpdate"
        @compositionend="handleCompositionEnd"
        @input="handleInput"
        @focus="handleFocus"
        @blur="handleBlur"
        @change="handleChange"
        @keyup.enter="search"
        :aria-label="label"
      >
      <!-- 前置内容 -->
      <span class="yxtf-input__prefix" v-if="$slots.prefix || prefixIcon">
        <slot name="prefix"></slot>
        <i class="yxtf-input__icon"
           v-if="prefixIcon"
           :class="prefixIcon">
        </i>
      </span>
      <!-- 后置内容 -->
      <span
        :class="{'yxtf-input__suffix--inputed': this.value}"
        class="yxtf-input__suffix">
        <span class="yxtf-input__suffix-inner">
          <i v-if="showClear"
            class="yxtf-input__icon yxtf-icon-error yxtf-input__clear"
            @mousedown.prevent
            @click="clear"
          ></i>
          <template v-if="!showClear || !showPwdVisible || !isWordLimitVisible">
            <slot name="suffix"></slot>
            <i class="yxtf-input__icon"
              v-if="suffixIcon"
              :class="suffixIcon">
            </i>
            <div v-if="searchable" @click="search" class="yxtf-input__search hover-primary-6">
              <yxt-svg icon-class="search" slot="suffix" />
            </div>
          </template>
         <i class="yxt-input__icon yxt-input__pwd yxt-input__clear" @click="handlePasswordVisible" v-if="showPwdVisible">
            <yxt-svg width='16px' height='16px' :icon-class='viewIcon' />
          </i>
          <span v-if="isWordLimitVisible" class="yxtf-input__count">
            <span class="yxtf-input__count-inner">
              <slot name="textlimit" :textLength="textLength" :upperLimit="upperLimit">{{ textLength }}<span class="yxtf-input__count-limit">/{{ upperLimit }}</span></slot>
            </span>
          </span>
        </span>
        <i class="yxtf-input__icon"
          v-if="validateState"
          :class="['yxtf-input__validateIcon', validateIcon]">
        </i>
      </span>
      <!-- 后置元素 -->
      <div class="yxtf-input-group__append"
        :class="[`yxtf-input-group__append--${pendType}`, `yxtf-input-group__append--${inputSize}`]" v-if="$slots.append">
        <div class="yxtf-input-group__append--inner">
          <slot name="append"></slot>
        </div>
      </div>
    </template>
    <template v-else>
      <textarea
        spellcheck='false'
        :tabindex="tabindex"
        :class="isIE ? 'yxtf-textarea__inner-ie' : 'yxtf-textarea__inner'"
        @compositionstart="handleCompositionStart"
        @compositionupdate="handleCompositionUpdate"
        @compositionend="handleCompositionEnd"
        @input="handleInput"
        ref="textarea"
        v-bind="$attrs"
        :disabled="inputDisabled"
        :readonly="readonly"
        :autocomplete="autoComplete || autocomplete"
        :style="textareaStyle"
        @focus="handleFocus"
        @blur="handleBlur"
        @change="handleChange"
        @keydown="handleKeyDown"
        :aria-label="label"
      >
      </textarea>
      <div v-if="isWordLimitVisible" class="yxtf-input__count"><slot name="textlimit" :textLength="textLength" :upperLimit="upperLimit">{{ textLength }}<span class="yxtf-input__limit">/{{ upperLimit }}</span></slot></div>
    </template>
  </div>
</template>
<script>
  import emitter from '@utils/mixins/emitter';
  import Migrating from '@utils/mixins/migrating';
  import calcTextareaHeight from './calcTextareaHeight';
  import merge from '@utils/utils/merge';
  import {isKorean} from '@utils/utils/shared';

  export default {
    name: 'YxtfInput',

    componentName: 'YxtfInput',

    mixins: [emitter, Migrating],

    inheritAttrs: false,

    inject: {
      yxtForm: {
        default: ''
      },
      yxtFormItem: {
        default: ''
      }
    },

    data() {
      return {
        textareaCalcStyle: {},
        hovering: false,
        focused: false,
        isComposing: false,
        passwordVisible: false
      };
    },

    props: {
      value: [String, Number],
      searchable: {
        type: Boolean,
        default: false
      },
      size: String,
      resize: String,
      form: String,
      disabled: Boolean,
      error: Boolean,
      readonly: Boolean,
      radusType: {
        type: String, // common full
        default: 'common'
      },
      styleType: {
        type: String, // border bg
        default: 'border'
      },
      pendType: {
        type: String, // common primary
        default: 'common'
      },
      type: {
        type: String,
        default: 'text'
      },
      autosize: {
        type: [Boolean, Object],
        default: false
      },
      autocomplete: {
        type: String,
        default: 'off'
      },
      /** @Deprecated in next major version */
      autoComplete: {
        type: String,
        validator(val) {
          process.env.NODE_ENV !== 'production' &&
            console.warn('[Element Warn][Input]\'auto-complete\' property will be deprecated in next major version. please use \'autocomplete\' instead.');
          return true;
        }
      },
      validateEvent: {
        type: Boolean,
        default: true
      },
      suffixIcon: String,
      prefixIcon: String,
      label: String,
      clearable: {
        type: Boolean,
        default: false
      },
      showPassword: {
        type: Boolean,
        default: false
      },
      showWordLimit: {
        type: Boolean,
        default: false
      },
      tabindex: String
    },

    computed: {
      _yxtFormItemSize() {
        return (this.yxtFormItem || {}).yxtFormItemSize;
      },
      viewIcon() {
        return this.passwordVisible ? 'f_view' : 'f_hide';
      },
      validateState() {
        return this.yxtFormItem ? this.yxtFormItem.validateState : '';
      },
      needStatusIcon() {
        return this.yxtForm ? this.yxtForm.statusIcon : false;
      },
      validateIcon() {
        return {
          validating: 'yxtf-icon-loading',
          success: 'yxtf-icon-circle-check',
          error: 'yxtf-icon-circle-close'
        }[this.validateState];
      },
      isIE() {
        return (!!window.ActiveXObject || 'ActiveXObject' in window) || /Edge/i.test(navigator.userAgent);
      },
      isTextarea() {
        return this.type === 'textarea';
      },
      textareaStyle() {
        const tempStyle = { resize: this.resize };
        if (!(this.isWordLimitVisible && this.isTextarea)) {
          tempStyle.paddingBottom = '15px';
        }
        return merge({}, this.textareaCalcStyle, tempStyle);
      },
      inputSize() {
        return this.size || this._yxtFormItemSize || (this.$ELEMENT || {}).size;
      },
      inputDisabled() {
        return this.disabled || (this.yxtForm || {}).disabled;
      },
      inputError() {
        return this.error || (this.yxtForm || {}).error;
      },
      nativeInputValue() {
        return this.value === null || this.value === undefined ? '' : String(this.value);
      },
      showClear() {
        return this.clearable &&
          !this.inputDisabled &&
          !this.readonly &&
          this.nativeInputValue &&
          (this.focused || this.hovering);
      },
      showPwdVisible() {
        return this.showPassword &&
          !this.inputDisabled &&
          !this.readonly &&
          (!!this.nativeInputValue || this.focused);
      },
      isWordLimitVisible() {
        return this.showWordLimit &&
          this.$attrs.maxlength &&
          (this.type === 'text' || this.type === 'textarea') &&
          !this.readonly &&
          !this.showPassword;
      },
      upperLimit() {
        return this.$attrs.maxlength;
      },
      textLength() {
        if (typeof this.value === 'number') {
          return String(this.value).length;
        }

        return (this.value || '').length;
      },
      inputExceed() {
        // show exceed style if length of initial value greater then maxlength
        return this.isWordLimitVisible &&
          (this.textLength > this.upperLimit);
      }
    },

    watch: {
      value(val) {
        this.$nextTick(this.resizeTextarea);
        if (this.validateEvent) {
          this.dispatch('YxtfFormItem', 'yxtf.form.change', [val]);
        }
      },
      // native input value is set explicitly
      // do not use v-model / :value in template
      // see: https://github.com/ElemeFE/element/issues/14521
      nativeInputValue() {
        this.setNativeInputValue();
      },
      // when change between <input> and <textarea>,
      // update DOM dependent value and styles
      // https://github.com/ElemeFE/element/issues/14857
      type() {
        this.$nextTick(() => {
          this.setNativeInputValue();
          this.resizeTextarea();
          this.updateIconOffset();
        });
      }
    },

    methods: {
      focus() {
        this.getInput().focus();
      },
      blur() {
        this.getInput().blur();
      },
      search() {
        this.$emit('search', this.value);
      },
      getMigratingConfig() {
        return {
          props: {
            'icon': 'icon is removed, use suffix-icon / prefix-icon instead.',
            'on-icon-click': 'on-icon-click is removed.'
          },
          events: {
            'click': 'click is removed.'
          }
        };
      },
      handleBlur(event) {
        this.focused = false;
        this.$emit('blur', event);
        if (this.validateEvent) {
          this.dispatch('YxtfFormItem', 'yxtf.form.blur', [this.value]);
        }
      },
      select() {
        this.getInput().select();
      },
      resizeTextarea() {
        if (this.$isServer) return;
        const { autosize } = this;
        if (!this.isTextarea) return;
        if (!autosize) {
          this.textareaCalcStyle = {
            minHeight: calcTextareaHeight(this.$refs.textarea).minHeight
          };
          return;
        }
        const minRows = autosize.minRows;
        const maxRows = autosize.maxRows;

        this.textareaCalcStyle = calcTextareaHeight(this.$refs.textarea, minRows, maxRows);
      },
      setNativeInputValue() {
        const input = this.getInput();
        if (!input) return;
        if (input.value === this.nativeInputValue) return;
        input.value = this.nativeInputValue;
      },
      handleFocus(event) {
        this.focused = true;
        this.$emit('focus', event);
      },
      handleCompositionStart() {
        this.isComposing = true;
      },
      handleCompositionUpdate(event) {
        const text = event.target.value;
        const lastCharacter = text[text.length - 1] || '';
        this.isComposing = !isKorean(lastCharacter);
      },
      handleCompositionEnd(event) {
        if (this.isComposing) {
          this.isComposing = false;
          this.handleInput(event);
        }
      },
      handleInput(event) {
        // should not emit input during composition
        // see: https://github.com/ElemeFE/element/issues/10516
        if (this.isComposing) return;
        // hack for https://github.com/ElemeFE/element/issues/8548
        // should remove the following line when we don't support IE
        if (event.target.value === this.nativeInputValue) return;

        this.$emit('input', event.target.value);

        // ensure native input value is controlled
        // see: https://github.com/ElemeFE/element/issues/12850
        this.$nextTick(this.setNativeInputValue);
      },
      handleChange(event) {
        this.$emit('change', event.target.value);
      },
      handleKeyDown(event) {
        if (this.$attrs.maxlength && this.textLength >= this.upperLimit && event.key === 'Enter') {
          event.preventDefault();
          return false;
        }
      },
      calcIconOffset(place) {
        let elList = [].slice.call(this.$el.querySelectorAll(`.yxtf-input__${place}`) || []);
        if (!elList.length) return;
        let el = null;
        for (let i = 0; i < elList.length; i++) {
          if (elList[i].parentNode === this.$el) {
            el = elList[i];
            break;
          }
        }
        if (!el) return;
        const pendantMap = {
          suffix: 'append',
          prefix: 'prepend'
        };

        const pendant = pendantMap[place];
        if (this.$slots[pendant]) {
          el.style.transform = `translateX(${place === 'suffix' ? '-' : ''}${this.$el.querySelector(`.yxtf-input-group__${pendant}`).offsetWidth}px)`;
        } else {
          el.removeAttribute('style');
        }
      },
      updateIconOffset() {
        this.calcIconOffset('prefix');
        this.calcIconOffset('suffix');
      },
      clear() {
        this.$emit('input', '');
        this.$emit('change', '');
        this.$emit('clear');
      },
      handlePasswordVisible() {
        this.passwordVisible = !this.passwordVisible;
        this.focus();
      },
      getInput() {
        return this.$refs.input || this.$refs.textarea;
      },
      getSuffixVisible() {
        return this.$slots.suffix ||
          this.suffixIcon ||
          this.showClear ||
          this.showPassword ||
          this.searchable ||
          this.isWordLimitVisible ||
          (this.validateState && this.needStatusIcon);
      }
    },

    created() {
      this.$on('inputSelect', this.select);
    },

    mounted() {
      this.setNativeInputValue();
      this.resizeTextarea();
      this.updateIconOffset();
    },

    updated() {
      this.$nextTick(this.updateIconOffset);
    }
  };
</script>
