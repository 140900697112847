var render = function (_h, _vm) {
  var _c = _vm._c
  return _c(
    "div",
    _vm._g(
      _vm._b(
        {
          class: [
            _vm.data.staticClass,
            "yxt-divider",
            "yxt-divider--" + _vm.props.direction,
          ],
        },
        "div",
        _vm.data.attrs,
        false
      ),
      _vm.listeners
    ),
    [
      _vm.slots().default &&
      _vm.slots().default[0].text !== "" &&
      _vm.props.direction !== "vertical"
        ? _c(
            "div",
            { class: ["yxt-divider__text", "is-" + _vm.props.contentPosition] },
            [
              _c("div", { staticClass: "text-line-left" }),
              _c(
                "div",
                { staticClass: "text-line-center" },
                [_vm._t("default")],
                2
              ),
              _c("div", { staticClass: "text-line-right" }),
            ]
          )
        : _c("div", {
            class: [
              "yxt-divider__textnone",
              "yxt-divider__" + _vm.props.themeMode,
            ],
            style: _vm.props.lineColor
              ? { backgroundColor: _vm.props.lineColor }
              : {},
          }),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }