<template>
  <transition name="yxt-zoom-in-top">
    <div
      class="yxt-table-filter"
      v-if="multiple"
      v-clickoutside="handleOutsideClick"
      v-show="showPopper">
      <div class="yxt-table-filter__header">
        <yxt-checkbox
          :indeterminate="isIndeterminate"
          v-model="checkAll"
          @change="handleCheckAllChange"
          >{{t('pc_comp_table_clearFilter')}}</yxt-checkbox>
      </div>
      <div class="yxt-table-filter__content">
        <yxt-scrollbar wrap-class="yxt-table-filter__wrap">
          <yxt-checkbox-group class="yxt-table-filter__checkbox-group" v-model="filteredValue">
            <yxt-checkbox
              v-for="filter in filters"
              :key="filter.value"
              :label="filter.value">
              <yxt-tooltip effect="dark" :content="filter.text" placement="top" open-filter>
                <span v-text="filter.text"></span>
              </yxt-tooltip>
            </yxt-checkbox>
          </yxt-checkbox-group>
        </yxt-scrollbar>
      </div>
      <div class="yxt-table-filter__bottom">
        <yxt-button @click="handleCancel" size="small" plain>
          {{ t('pc_comp_table_cancel') }}
        </yxt-button>
        <yxt-button @click="handleConfirm" size="small" type="primary">
          {{ t('pc_comp_table_confirm') }}
        </yxt-button>
      </div>
    </div>
    <div
      class="yxt-table-filter"
      v-else
      v-clickoutside="handleOutsideClick"
      v-show="showPopper">
      <ul class="yxt-table-filter__list">
        <li class="yxt-table-filter__list-item"
            :class="{ 'is-active': filterValue === undefined || filterValue === null }"
            @click="handleSelect(null)">{{ t('pc_comp_table_clearFilter') }}</li>
        <li class="yxt-table-filter__list-item"
            v-for="filter in filters"
            :label="filter.value"
            :key="filter.value"
            :class="{ 'is-active': isActive(filter) }"
            @click="handleSelect(filter.value)" >{{ filter.text }}</li>
      </ul>
    </div>
  </transition>
</template>

<script type="text/babel">
  import Popper from '@utils/utils/vue-popper';
  import { PopupManager } from '@utils/utils/popup';
  import Locale from '@utils/mixins/locale';
  import Clickoutside from '@utils/utils/clickoutside';
  import Dropdown from './dropdown';
  import ElCheckbox from '@backstage/checkbox';
  import ElCheckboxGroup from '@backstage/checkbox-group';
  import ElScrollbar from '@backstage/scrollbar';

  export default {
    name: 'YxtTableFilterPanel',

    mixins: [Popper, Locale],

    directives: {
      Clickoutside
    },

    components: {
      ElCheckbox,
      ElCheckboxGroup,
      ElScrollbar
    },

    props: {
      placement: {
        type: String,
        default: 'bottom-end'
      }
    },

    methods: {
      // 处理全选状态改变
      handleCheckAllChange(val) {
        if (val) {
          this.filteredValue = this.filters.map((f) => f.value);
        } else {
          this.filteredValue = [];
        }
        // this.confirmFilter(this.filteredValue);
        // this.setCheckAll();
      },

      isActive(filter) {
        return filter.value === this.filterValue;
      },

      handleOutsideClick() {
        setTimeout(() => {
          this.showPopper = false;
        }, 16);
      },

      handleConfirm() {
        this.confirmFilter(this.filteredValue);
        this.handleOutsideClick();
      },

      handleReset() {
        this.filteredValue = [];
        this.confirmFilter(this.filteredValue);
        this.handleOutsideClick();
      },

      handleCancel() {
        this.filteredValue = this.oldFilters;
        this.handleOutsideClick();
      },

      handleSelect(filterValue) {
        this.filterValue = filterValue;

        if ((typeof filterValue !== 'undefined') && (filterValue !== null)) {
          this.confirmFilter(this.filteredValue);
        } else {
          this.confirmFilter([]);
        }

        this.handleOutsideClick();
        this.setCheckAll();
      },

      confirmFilter(filteredValue) {
        this.table.store.commit('filterChange', {
          column: this.column,
          values: filteredValue
        });
        this.oldFilters = filteredValue;
        this.table.store.updateAllSelected();
      },

      setCheckAll() {
        this.checkAll = this.filteredValue && this.filteredValue.length === this.filters.length;
        this.isIndeterminate = !this.checkAll && this.filteredValue && this.filteredValue.length !== 0;
      }
    },

    data() {
      return {
        // 全选状态
        checkAll: false,
        // 表示 checkbox 的不确定状态，用于实现全选的效果
        isIndeterminate: false,
        table: null,
        cell: null,
        column: null,
        oldFilters: []
      };
    },

    computed: {
      filters() {
        return this.column && this.column.filters;
      },

      filterValue: {
        get() {
          return (this.column.filteredValue || [])[0];
        },
        set(value) {
          if (this.filteredValue) {
            if ((typeof value !== 'undefined') && (value !== null)) {
              this.filteredValue.splice(0, 1, value);
            } else {
              this.filteredValue.splice(0, 1);
            }
          }
        }
      },

      filteredValue: {
        get() {
          if (this.column) {
            return this.column.filteredValue || [];
          }
          return [];
        },
        set(value) {
          if (this.column) {
            this.column.filteredValue = value;
          }
          this.setCheckAll();
        }
      },

      multiple() {
        if (this.column) {
          return this.column.filterMultiple;
        }
        return true;
      }
    },

    mounted() {
      this.popperElm = this.$el;
      this.referenceElm = this.cell;
      this.table.bodyWrapper.addEventListener('scroll', () => {
        this.updatePopper();
      });

      this.$watch('showPopper', (value) => {
        if (this.column) this.column.filterOpened = value;
        if (value) {
          Dropdown.open(this);
        } else {
          Dropdown.close(this);
        }
      });
    },
    watch: {
      showPopper(val) {
        if (val === true && parseInt(this.popperJS._popper.style.zIndex, 10) < PopupManager.zIndex) {
          this.popperJS._popper.style.zIndex = PopupManager.nextZIndex();
        }
      }
    }
  };
</script>
