<template>
  <main class="yxt-main">
    <slot></slot>
  </main>
</template>

<script>
  export default {
    name: 'YxtMain',
    componentName: 'YxtMain'
  };
</script>
