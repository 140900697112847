var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "yxt-anchor__item" }, [
    _c(
      "a",
      {
        ref: "linkRef",
        staticClass: "yxt-anchor__link",
        class: { "is-active": _vm.isActive },
        attrs: { href: _vm.href },
        on: { click: _vm.handleClick },
      },
      [_vm._t("default", [_vm._v(_vm._s(_vm.title))])],
      2
    ),
    _vm.$slots["sub-link"] && _vm.direction === "vertical"
      ? _c("div", { staticClass: "yxt-anchor__list" }, [_vm._t("sub-link")], 2)
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }