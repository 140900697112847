
// eslint-disable-next-line no-undef
const scrollIds = new Map();

export function scrollTo(element, to, duration) {
  if (scrollIds.get(element)) {
    cancelAnimationFrame(scrollIds.get(element));
  }
  if (duration <= 0) {
    element.isAnimated = false;
    scrollIds.set(
      element,
      requestAnimationFrame(() => {
        element.scrollTop = to;
      }),
    );

    return;
  }
  const difference = to - element.scrollTop;
  const perTick = (difference / duration) * 10;

  scrollIds.set(
    element,
    requestAnimationFrame(() => {
      element.scrollTop += perTick;
      if (element.scrollTop !== to) {
        element.isAnimated = true;
        scrollTo(element, to, duration - 10);
      } else {
        element.isAnimated = false;
      }
    }),
  );
}
