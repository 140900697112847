var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "yxtf-steps",
      class: [
        !_vm.simple && !_vm.basic && "yxtf-steps--" + _vm.direction,
        _vm.simple && !_vm.basic && "yxtf-steps--simple",
        _vm.basic && !_vm.simple && "yxtf-steps--basic",
      ],
    },
    [_vm._t("default")],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }