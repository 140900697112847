<template>
  <li
    class="yxt-dropdown-menu__item"
    :class="{
      'is-disabled': disabled,
      'yxt-dropdown-menu__item--divided': divided
    }"
    @click="handleClick"
    :aria-disabled="disabled"
    :tabindex="disabled ? null : -1"
  >
    <i :class="icon" v-if="icon"></i>
    <slot></slot>
    <yxt-tooltip :effect="tooltipEffect" :content="labelTooltip" placement="top-start" :offset="10" v-if="labelTooltip" :max-width="labelTooltipWidth">
      <yxt-svg remote-url='' class='color-gray-6 hover-primary-6 ml4' width='16px' height='16px' icon-class="question-cirlce-o" />
    </yxt-tooltip>
  </li>
</template>
<script>
  import Emitter from '@utils/mixins/emitter';

  export default {
    name: 'YxtDropdownItem',

    mixins: [Emitter],

    props: {
      command: {},
      disabled: Boolean,
      divided: Boolean,
      icon: String,
      labelTooltip: String,
      tooltipEffect: {
        type: String,
        default: 'dark'
      },
      labelTooltipWidth: {
        type: Number,
        default: 100
      }
    },

    methods: {
      handleClick(e) {
        if (this.disabled) return;
        this.dispatch('YxtDropdown', 'menu-item-click', [this.command, this]);
      }
    }
  };
</script>
