<template>
  <div
    :class="[
      isTextarea ? 'yxt-textarea' : 'yxt-input',
      inputSize ? 'yxt-input--' + inputSize : '',
      {
        'is-ie': isIE,
        'is-ie-focus': isIE && focused,
        'is-disabled': inputDisabled,
        'is-disabled-with-value': inputDisabled && textLength > 0,
        'is-error': inputError,
        'is-exceed': inputExceed,
        'yxt-textarea--world-limit': isWordLimitVisible,
        'yxt-input-group': $slots.prepend || $slots.append,
        'yxt-input-group--append': $slots.append,
        'yxt-input-group--prepend': $slots.prepend,
        'yxt-input--prefix': $slots.prefix || prefixIcon || searchable,
        'yxt-input--suffix':
          $slots.suffix || suffixIcon || clearable || showPassword
      }
    ]"
    :data-id="$attrs['data-id']"
    @mouseenter="hovering = true"
    @mouseleave="hovering = false"
  >
    <template v-if="!isTextarea">
      <!-- 前置元素 -->
      <div class="yxt-input-group__prepend" v-if="$slots.prepend">
        <slot name="prepend"></slot>
      </div>
      <input
        :tabindex="tabindex"
        class="yxt-input__inner"
        :style="isWordLimitVisible?'padding-right:'+paddingRight+'px':''"
        :class="[
          {'yxt-input__hover': isHover},
          {'yxt-input__inner--primary': pendType === 'primary' },
          // 'yxt-input__double-icon': showClear && searchable,
          {'yxt-input__wordlimit': isWordLimitVisible && upperLimit < 100},
          {'yxt-input__wordlimit-big': isWordLimitVisible && upperLimit >= 100}
         ]"
        v-bind="attrsNoDataId"
        :type="showPassword ? (passwordVisible ? 'text' : 'password') : type"
        :disabled="inputDisabled"
        :readonly="readonly"
        :autocomplete="autoComplete || autocomplete"
        ref="input"
        @compositionstart="handleCompositionStart"
        @compositionupdate="handleCompositionUpdate"
        @compositionend="handleCompositionEnd"
        @input="handleInput"
        @focus="handleFocus"
        @blur="handleBlur"
        @change="handleChange"
        @keyup.enter="search"
        :aria-label="label"
      />
      <!-- 前置内容 -->
      <span class="yxt-input__prefix" v-if="$slots.prefix || prefixIcon || searchable">
        <slot name="prefix"></slot>
        <i class="yxt-input__icon" v-if="prefixIcon" :class="prefixIcon"> </i>
        <i
          v-if="searchable"
          @click="search"
          @mouseenter.stop.prevent="isHover = true"
          @mouseleave="isHover = false"
          class="yxt-input__icon hand yxt-icon-search"
        ></i>
      </span>
      <!-- 后置内容 -->
      <span class="yxt-input__suffix" v-if="getSuffixVisible()">
        <span class="yxt-input__suffix-inner">
          <span v-if="isWordLimitVisible" class="yxt-input__count">
            <slot name="textlimit"></slot>
            <span v-if="!$slots.textlimit" class="yxt-input__count-inner">
              <span
                class="yxt-input__count-innerleft"
                :style="{ color: textLength > 0 ? '#757575' : '' }"
                >{{ textLength }}</span
              >/{{ upperLimit }}
            </span>
            <span
              v-if="isWordLimitVisible && this.$slots.suffix"
              class="yxt-input__wordLimitSplit"
              ><em></em
            ></span>
          </span>
          <span v-if="showClear"
            class="yxt-input__clear-wrap"
            @mousedown.prevent
            @mouseenter.stop.prevent="isHover = true;"
            @mouseleave="isHover = false"
            @click="clear">
            <i class="yxt-input__icon yxt-icon-close yxt-input__clear"></i>
          </span>
          <template v-if="!showClear || !showPwdVisible || !isWordLimitVisible">
            <slot name="suffix"></slot>
            <i class="yxt-input__icon" v-if="suffixIcon" :class="suffixIcon">
            </i>
          </template>

          <!-- <yxt-svg class="yxt-input__icon yxt-input__clear" icon-class="close-circle"  @mousedown.prevent @click="clear"/> -->
          <i
            class="yxt-input__icon yxt-input__pwd"
            @click="handlePasswordVisible"
            v-if="showPwdVisible"
          >
            <yxt-svg width="16px" height="16px" :icon-class="viewIcon" />
          </i>
          <!-- <i v-if="showPwdVisible"
            class="yxt-input__icon yxt-icon-view yxt-input__clear"
            @click="handlePasswordVisible"
          ></i> -->
        </span>
        <i
          class="yxt-input__icon"
          v-if="validateState"
          :class="['yxt-input__validateIcon', validateIcon]"
        >
        </i>
      </span>
      <!-- 后置元素 -->
      <div class="yxt-input-group__append"  :class="[`yxt-input-group__append--${pendType}`,]" v-if="$slots.append">
        <slot name="append"></slot>
      </div>
    </template>
    <template v-else>
      <textarea
        spellcheck='false'
        :tabindex="tabindex"
        :class="isIE ? 'yxt-textarea__inner-ie' : 'yxt-textarea__inner'"
        @compositionstart="handleCompositionStart"
        @compositionupdate="handleCompositionUpdate"
        @compositionend="handleCompositionEnd"
        @input="handleInput"
        ref="textarea"
        v-bind="attrsNoDataId"
        :disabled="inputDisabled"
        :readonly="readonly"
        :autocomplete="autoComplete || autocomplete"
        :style="textareaStyle"
        @focus="handleFocus"
        @blur="handleBlur"
        @change="handleChange"
        @keydown="handleKeyDown"
        :aria-label="label"
      >
      </textarea>
      <span v-if="isWordLimitVisible" class="yxt-input__count">
        <slot name="textlimit"></slot>
        <span v-if="!$slots.textlimit" class="yxt-input__count-inner">
          <span
            class="yxt-input__count-innerleft"
            :style="{ color: textLength > 0 ? '#757575' : '' }"
            >{{ textLength }}</span
          >/{{ upperLimit }}
        </span>
      </span>
    </template>
  </div>
</template>
<script>
import emitter from '@utils/mixins/emitter';
import Migrating from '@utils/mixins/migrating';
import calcTextareaHeight from './calcTextareaHeight';
import merge from '@utils/utils/merge';
import { isKorean } from '@utils/utils/shared';

export default {
  name: 'YxtInput',

  componentName: 'YxtInput',

  mixins: [emitter, Migrating],

  inheritAttrs: false,

  inject: {
    yxtForm: {
      default: ''
    },
    yxtFormItem: {
      default: ''
    }
  },

  data() {
    return {
      textareaCalcStyle: {},
      hovering: false,
      focused: false,
      isComposing: false,
      isHover: false,
      passwordVisible: false,
      paddingRight: 65,
      originTextLength: this.textLength
    };
  },

  props: {
    value: {
      type: [String, Number],
      desc: '绑定值'
    },
    searchable: {
      type: Boolean,
      default: false,
      desc: '显示搜索图标'
    },
    error: {
      type: Boolean,
      desc: '显示错误'
    },
    size: {
      type: String,
      desc: '尺寸'
    },
    resize: {
      type: String,
      desc: '是否能被用户缩放'
    },
    form: {
      type: String,
      desc: '原生form属性'
    },
    disabled: {
      type: Boolean,
      desc: '是否禁用'
    },
    readonly: {
      type: Boolean,
      desc: '是否只读'
    },
    type: {
      type: String,
      default: 'text',
      desc: '类型'
    },
    autosize: {
      type: [Boolean, Object],
      default: false,
      desc: '自适应内容高度，只对type="textarea"有效，可传入对象，如，{ minRows: 2, maxRows: 6 }'
    },
    autocomplete: {
      type: String,
      default: 'off',
      desc: '自动补全'
    },
    validateEvent: {
      type: Boolean,
      default: true,
      desc: '输入时是否触发表单的校验'
    },
    suffixIcon: {
      type: String,
      desc: '尾部图标'
    },
    prefixIcon: {
      type: String,
      desc: '头部图标'
    },
    label: {
      type: String,
      desc: '关联的label文字'
    },
    clearable: {
      type: Boolean,
      default: false,
      desc: '是否可清空'
    },
    showPassword: {
      type: Boolean,
      default: false,
      desc: '显示切换密码图标'
    },
    searchNoIcon: {
      type: Boolean,
      default: false,
      desc: '在非图标下支持search时间'
    },
    showWordLimit: {
      type: Boolean,
      default: false,
      desc: '是否显示输入字数统计，只在 type = "text" 或 type = "textarea" 时有效'
    },
    tabindex: {
      type: String,
      desc: '输入框的tabindex'
    },
    /** @Deprecated in next major version */
    autoComplete: {
      type: String,
      desc: '自动补全(废弃)',
      validator(val) {
        process.env.NODE_ENV !== 'production' &&
          console.warn(
            "[Element Warn][Input]'auto-complete' property will be deprecated in next major version. please use 'autocomplete' instead."
          );
        return true;
      }
    },
    pendType: {
      type: String, // common primary
      default: 'common',
      desc: '追加区域类型'
    }
  },

  computed: {
    _yxtFormItemSize() {
      return (this.yxtFormItem || {}).yxtFormItemSize;
    },
    viewIcon() {
      return this.passwordVisible ? 'view' : 'hide';
    },
    validateState() {
      return this.yxtFormItem ? this.yxtFormItem.validateState : '';
    },
    needStatusIcon() {
      return this.yxtForm ? this.yxtForm.statusIcon : false;
    },
    validateIcon() {
      return {
        validating: 'yxt-icon-loading',
        success: 'yxt-icon-circle-check',
        error: 'yxt-icon-circle-close'
      }[this.validateState];
    },
    isIE() {
      return (
        this.isWordLimitVisible &&
        (!!window.ActiveXObject ||
          'ActiveXObject' in window ||
          /Edge/i.test(navigator.userAgent))
      );
    },
    isTextarea() {
      return this.type === 'textarea';
    },
    textareaStyle() {
      return merge({}, this.textareaCalcStyle, { resize: this.resize });
    },
    inputSize() {
      return this.size || this._yxtFormItemSize || (this.$ELEMENT || {}).size;
    },
    inputError() {
      return this.error || (this.yxtForm || {}).error;
    },
    inputDisabled() {
      return this.disabled || (this.yxtForm || {}).disabled;
    },
    nativeInputValue() {
      return this.value === null || this.value === undefined
        ? ''
        : String(this.value);
    },
    showClear() {
      return (
        this.clearable &&
        !this.inputDisabled &&
        !this.readonly &&
        this.nativeInputValue &&
        (this.focused || this.hovering)
      );
    },
    showPwdVisible() {
      return (
        this.showPassword &&
        !this.inputDisabled &&
        !this.readonly
      );
    },
    isWordLimitVisible() {
      return (
        this.showWordLimit &&
        (this.$attrs.maxlength || this.$attrs.minlength) &&
        (this.type === 'text' || this.type === 'textarea') &&
        !this.inputDisabled &&
        !this.readonly &&
        !this.showPassword
      );
    },
    upperLimit() {
      return this.$attrs.maxlength;
    },
    lowerLimit() {
      return this.$attrs.minlength;
    },
    attrsNoDataId() {
      const attrsNoDataId = { ...this.$attrs }; // 创建一个新的对象包含所有属性
      delete attrsNoDataId['data-id']; // 然后删除 data-id 属性
      return attrsNoDataId;
    },
    textLength() {
      let textLength = 0;
      if (typeof this.value === 'number') {
        textLength = String(this.value).length;
      } else {
        textLength = (this.value || '').length;
      }
      // 解决字符限制数字较大时  遮挡 input 文案
      if (String(textLength).length !== this.originTextLength) {
        let offsetWidth = 55;
        this.$nextTick(()=>{
          const dom = this.$el && this.$el.getElementsByClassName('yxt-input__suffix')[0];
          if (dom) {
            offsetWidth = dom.offsetWidth;
            this.paddingRight = offsetWidth + 16;
          }

        });
        this.paddingRight = offsetWidth + 16;
      }
      this.originTextLength = String(textLength).length;
      return textLength;
    },
    inputExceed() {
      // show exceed style if length of initial value greater then maxlength
      return this.isWordLimitVisible && ~this.textLength === ~this.upperLimit;
    }
  },

  watch: {
    value(val) {
      this.$nextTick(this.resizeTextarea);
      if (this.validateEvent) {
        this.dispatch('YxtFormItem', 'yxt.form.change', [val]);
      }
    },
    // native input value is set explicitly
    // do not use v-model / :value in template
    // see: https://github.com/ElemeFE/element/issues/14521
    nativeInputValue() {
      this.setNativeInputValue();
    },
    // when change between <input> and <textarea>,
    // update DOM dependent value and styles
    // https://github.com/ElemeFE/element/issues/14857
    type() {
      this.$nextTick(() => {
        this.setNativeInputValue();
        this.resizeTextarea();
        this.updateIconOffset();
      });
    }
  },

  methods: {
    focus() {
      this.getInput().focus();
    },
    blur() {
      this.getInput().blur();
    },
    search() {
      if (this.searchable || this.searchNoIcon) {
        this.$emit('search', this.value);
      }
    },
    getMigratingConfig() {
      return {
        props: {
          icon: 'icon is removed, use suffix-icon / prefix-icon instead.',
          'on-icon-click': 'on-icon-click is removed.'
        },
        events: {
          click: 'click is removed.'
        }
      };
    },
    handleBlur(event) {
      this.focused = false;
      this.$emit('blur', event);
      if (this.validateEvent) {
        this.dispatch('YxtFormItem', 'yxt.form.blur', [this.value]);
      }
    },
    select() {
      this.getInput().select();
    },
    resizeTextarea() {
      if (this.$isServer) return;
      const { autosize } = this;
      if (!this.isTextarea) return;
      if (!autosize) {
        this.textareaCalcStyle = {
          minHeight: calcTextareaHeight(this.$refs.textarea).minHeight
        };
        return;
      }
      const minRows = autosize.minRows;
      const maxRows = autosize.maxRows;

      this.textareaCalcStyle = calcTextareaHeight(
        this.$refs.textarea,
        minRows,
        maxRows
      );
    },
    setNativeInputValue() {
      const input = this.getInput();
      if (!input) return;
      if (input.value === this.nativeInputValue) return;
      input.value = this.nativeInputValue;
    },
    handleFocus(event) {
      this.focused = true;
      this.$emit('focus', event);
    },
    handleCompositionStart() {
      this.isComposing = true;
    },
    handleCompositionUpdate(event) {
      const text = event.target.value;
      const lastCharacter = text[text.length - 1] || '';
      this.isComposing = !isKorean(lastCharacter);
    },
    handleCompositionEnd(event) {
      if (this.isComposing) {
        this.isComposing = false;
        this.handleInput(event);
      }
    },
    handleInput(event) {
      // should not emit input during composition
      // see: https://github.com/ElemeFE/element/issues/10516
      if (this.isComposing) return;

      // hack for https://github.com/ElemeFE/element/issues/8548
      // should remove the following line when we don't support IE
      if (event.target.value === this.nativeInputValue) return;

      this.$emit('input', event.target.value);

      // ensure native input value is controlled
      // see: https://github.com/ElemeFE/element/issues/12850
      this.$nextTick(this.setNativeInputValue);
    },
    handleKeyDown(event) {
      if (
        this.$attrs.maxlength &&
        this.textLength >= this.upperLimit &&
        event.key === 'Enter'
      ) {
        event.preventDefault();
        return false;
      }
    },
    handleChange(event) {
      this.$emit('change', event.target.value);
    },
    calcIconOffset(place) {
      let elList = [].slice.call(
        this.$el.querySelectorAll(`.yxt-input__${place}`) || []
      );
      if (!elList.length) return;
      let el = null;
      for (let i = 0; i < elList.length; i++) {
        if (elList[i].parentNode === this.$el) {
          el = elList[i];
          break;
        }
      }
      if (!el) return;
      const pendantMap = {
        suffix: 'append',
        prefix: 'prepend'
      };

      const pendant = pendantMap[place];
      if (this.$slots[pendant]) {
        el.style.transform = `translateX(${place === 'suffix' ? '-' : ''}${
          this.$el.querySelector(`.yxt-input-group__${pendant}`).offsetWidth
        }px)`;
      } else {
        el.removeAttribute('style');
      }
    },
    updateIconOffset() {
      this.calcIconOffset('prefix');
      this.calcIconOffset('suffix');
    },
    clear() {
      this.$emit('input', '');
      this.$emit('change', '');
      this.$emit('clear');
    },
    handlePasswordVisible() {
      this.passwordVisible = !this.passwordVisible;
      this.focus();
    },
    getInput() {
      return this.$refs.input || this.$refs.textarea;
    },
    getSuffixVisible() {
      return (
        this.$slots.suffix ||
        this.suffixIcon ||
        this.showClear ||
        this.showPassword ||
        this.isWordLimitVisible ||
        (this.validateState && this.needStatusIcon)
      );
    }
  },

  created() {
    this.$on('inputSelect', this.select);
  },

  mounted() {
    this.setNativeInputValue();
    this.resizeTextarea();
    this.updateIconOffset();
  },

  updated() {
    this.$nextTick(this.updateIconOffset);
  }
};
</script>
