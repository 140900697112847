import { isClient } from './types';

export const isInContainer = function(el, container) {
  if (!isClient || !el || !container) return false;

  const elRect = el.getBoundingClientRect();

  let containerRect;
  if (container instanceof Element) {
    containerRect = container.getBoundingClientRect();
  } else {
    containerRect = {
      top: 0,
      right: window.innerWidth,
      bottom: window.innerHeight,
      left: 0
    };
  }

  return (
    elRect.top < containerRect.bottom &&
    elRect.bottom > containerRect.top &&
    elRect.right > containerRect.left &&
    elRect.left < containerRect.right
  );
};
export const getOffsetTop = (el) => {
  let offset = 0;
  let parent = el;

  while (parent) {
    offset += parent.offsetTop;
    parent = parent.offsetParent; // 移除 TypeScript 的类型断言
  }

  return offset;
};

export const getOffsetTopDistance = (el, containerEl) => {
  return Math.abs(getOffsetTop(el) - getOffsetTop(containerEl));
};

export const getClientXY = (event) => {
  let clientX, clientY;
  if (event.type === 'touchend') {
    clientY = (event.changedTouches || event.touches)[0].clientY; // 合并两种情况
    clientX = (event.changedTouches || event.touches)[0].clientX;
  } else if (event.type.startsWith('touch')) {
    // 如果不是 'touchend'，则默认使用 'touches'
    clientY = event.touches[0].clientY;
    clientX = event.touches[0].clientX;
  } else {
    // 对于非触摸事件，直接使用 clientX 和 clientY
    clientY = event.clientY;
    clientX = event.clientX;
  }
  return {
    clientX,
    clientY
  };
};
