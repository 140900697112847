var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "yxt-table__wrapper" },
    [
      _vm.customColumnTop || _vm.$slots.top
        ? _c(
            "div",
            { staticClass: "yxt-table-top" },
            [
              _c(
                "div",
                { staticClass: "yxt-table-top__content" },
                [_vm._t("top")],
                2
              ),
              _vm.customColumnTop
                ? _c(
                    "yxt-popover",
                    {
                      attrs: {
                        placement: "bottom-end",
                        "max-width": "184",
                        "popper-class":
                          "yxt-table-top__options-pop  popover-top-bottom-4",
                        trigger: "click",
                      },
                      on: {
                        show: function ($event) {
                          _vm.customColumnShow = true
                        },
                        "after-leave": function ($event) {
                          _vm.customColumnShow = false
                        },
                      },
                    },
                    [
                      _c(
                        "div",
                        {
                          staticClass: "yxt-table-top__options",
                          class: {
                            "yxt-table-top__options--open":
                              _vm.customColumnShow,
                          },
                          attrs: { slot: "reference" },
                          slot: "reference",
                        },
                        [
                          _c("yxt-svg", {
                            staticClass: "color-gray-7",
                            attrs: {
                              width: "18px",
                              height: "18px",
                              "icon-class": "manage",
                            },
                          }),
                        ],
                        1
                      ),
                      _c("custom-column", {
                        attrs: { show: _vm.customColumnShow, popper: "" },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
            ],
            1
          )
        : _vm._e(),
      _vm.reWrite
        ? _c(
            "div",
            {
              key: _vm.tableId,
              staticClass: "yxt-table",
              class: [
                {
                  "yxt-table--fit": _vm.fit,
                  "yxt-table--title":
                    (_vm.customColumnProps && _vm.customColumnProps.enable) ||
                    _vm.$slots.title,
                  "yxt-table--striped": _vm.stripe,
                  "yxt-table--border": _vm.border || _vm.isGroup,
                  "yxt-table--hidden": _vm.isHidden,
                  "yxt-table--group": _vm.isGroup,
                  "yxt-table--fluid-height": _vm.maxHeight,
                  "yxt-table--scrollable-x": _vm.layout.scrollX,
                  "yxt-table--scrollable-y": _vm.layout.scrollY,
                  "yxt-table--enable-row-hover": !_vm.store.states.isComplex,
                  "yxt-table--enable-row-transition":
                    (_vm.store.states.data || []).length !== 0 &&
                    (_vm.store.states.data || []).length < 100,
                  "yxt-table--empty": !_vm.data || _vm.data.length === 0,
                },
                _vm.tableSize ? "yxt-table--" + _vm.tableSize : "",
              ],
              on: {
                mouseleave: function ($event) {
                  _vm.handleMouseLeave($event)
                },
              },
            },
            [
              _c(
                "div",
                { ref: "hiddenColumns", staticClass: "hidden-columns" },
                [_vm._t("default")],
                2
              ),
              _vm.showHeader
                ? _c(
                    "div",
                    {
                      directives: [
                        {
                          name: "mousewheel",
                          rawName: "v-mousewheel",
                          value: _vm.handleHeaderFooterMousewheel,
                          expression: "handleHeaderFooterMousewheel",
                        },
                      ],
                      ref: "headerWrapper",
                      staticClass: "yxt-table__header-wrapper",
                    },
                    [
                      _c("table-header", {
                        ref: "tableHeader",
                        style: {
                          width: _vm.layout.bodyWidth
                            ? _vm.layout.bodyWidth + "px"
                            : "",
                        },
                        attrs: {
                          store: _vm.store,
                          tooltipEffect: _vm.tooltipEffect,
                          border: _vm.border,
                          "default-sort": _vm.defaultSort,
                        },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
              _c(
                "div",
                {
                  ref: "bodyWrapper",
                  staticClass: "yxt-table__body-wrapper",
                  class: [
                    _vm.layout.scrollX
                      ? "is-scrolling-" + _vm.scrollPosition
                      : "is-scrolling-none",
                  ],
                  style: [_vm.bodyHeight],
                  on: { scroll: _vm.scrollHandler },
                },
                [
                  _c("table-body", {
                    style: [
                      {
                        width: _vm.bodyWidth,
                      },
                      _vm.tableStyle,
                    ],
                    attrs: {
                      context: _vm.context,
                      virtual: _vm.virtual,
                      tooltipEffect: _vm.tooltipEffect,
                      list: _vm.renderList,
                      range: _vm.range,
                      store: _vm.store,
                      stripe: _vm.stripe,
                      "row-class-name": _vm.rowClassName,
                      "row-style": _vm.rowStyle,
                      "row-height": _vm.rowHeight,
                      highlight: _vm.highlightCurrentRow,
                    },
                  }),
                  _vm.loading
                    ? _c("div", { staticClass: "yxt-table__loading-block" }, [
                        _c("div", { staticClass: "yxt-loading-spinner" }, [
                          _c(
                            "svg",
                            {
                              staticClass: "circular",
                              staticStyle: { width: "32px", height: "32px" },
                              attrs: { viewBox: "25 25 50 50" },
                            },
                            [
                              _c("circle", {
                                staticClass: "path",
                                attrs: {
                                  cx: "50",
                                  cy: "50",
                                  r: "20",
                                  fill: "none",
                                },
                              }),
                            ]
                          ),
                        ]),
                      ])
                    : _vm._e(),
                  !_vm.loading && (!_vm.data || _vm.data.length === 0)
                    ? _c(
                        "div",
                        {
                          ref: "emptyBlock",
                          staticClass: "yxt-table__empty-block",
                          style: _vm.emptyBlockStyle,
                        },
                        [_c("span", [_vm._t("empty", [_c("yxt-empty")])], 2)]
                      )
                    : _vm._e(),
                  _vm.$slots.append
                    ? _c(
                        "div",
                        {
                          ref: "appendWrapper",
                          staticClass: "yxt-table__append-wrapper",
                        },
                        [_vm._t("append")],
                        2
                      )
                    : _vm._e(),
                ],
                1
              ),
              !_vm.loading && (!_vm.data || _vm.data.length === 0)
                ? _c(
                    "div",
                    {
                      staticClass: "yxt-table__empty-mask",
                      style: _vm.emptyMaskStyle,
                    },
                    [_c("span", [_vm._t("empty", [_c("yxt-empty")])], 2)]
                  )
                : _vm._e(),
              _vm.showSummary
                ? _c(
                    "div",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.data && _vm.data.length > 0,
                          expression: "data && data.length > 0",
                        },
                        {
                          name: "mousewheel",
                          rawName: "v-mousewheel",
                          value: _vm.handleHeaderFooterMousewheel,
                          expression: "handleHeaderFooterMousewheel",
                        },
                      ],
                      ref: "footerWrapper",
                      staticClass: "yxt-table__footer-wrapper",
                    },
                    [
                      _c("table-footer", {
                        style: {
                          width: _vm.layout.bodyWidth
                            ? _vm.layout.bodyWidth + "px"
                            : "",
                        },
                        attrs: {
                          store: _vm.store,
                          border: _vm.border,
                          "sum-text":
                            _vm.sumText || _vm.t("pc_comp_table_sumText"),
                          "summary-method": _vm.summaryMethod,
                          "default-sort": _vm.defaultSort,
                        },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
              _vm.fixedColumns.length > 0
                ? _c(
                    "div",
                    {
                      directives: [
                        {
                          name: "mousewheel",
                          rawName: "v-mousewheel",
                          value: _vm.handleFixedMousewheel,
                          expression: "handleFixedMousewheel",
                        },
                      ],
                      ref: "fixedWrapper",
                      staticClass: "yxt-table__fixed",
                      style: [
                        {
                          width: _vm.layout.fixedWidth
                            ? _vm.layout.fixedWidth + "px"
                            : "",
                        },
                        !_vm.data || _vm.data.length === 0
                          ? { height: _vm.layout.headerHeight + "px" }
                          : _vm.fixedHeight,
                      ],
                    },
                    [
                      _vm.showHeader
                        ? _c(
                            "div",
                            {
                              ref: "fixedHeaderWrapper",
                              staticClass: "yxt-table__fixed-header-wrapper",
                            },
                            [
                              _c("table-header", {
                                ref: "fixedTableHeader",
                                style: {
                                  width: _vm.bodyWidth,
                                },
                                attrs: {
                                  fixed: "left",
                                  tooltipEffect: _vm.tooltipEffect,
                                  border: _vm.border,
                                  store: _vm.store,
                                },
                              }),
                            ],
                            1
                          )
                        : _vm._e(),
                      _c(
                        "div",
                        {
                          ref: "fixedBodyWrapper",
                          staticClass: "yxt-table__fixed-body-wrapper",
                          style: [
                            {
                              top: _vm.layout.headerHeight + "px",
                            },
                            !_vm.data || _vm.data.length === 0
                              ? { height: _vm.layout.headerHeight + "px" }
                              : _vm.fixedHeight,
                          ],
                        },
                        [
                          _c("custom-column"),
                          _c("table-body", {
                            style: [
                              {
                                width: _vm.bodyWidth,
                              },
                              _vm.tableStyle,
                            ],
                            attrs: {
                              fixed: "left",
                              store: _vm.store,
                              tooltipEffect: _vm.tooltipEffect,
                              virtual: _vm.virtual,
                              list: _vm.renderList,
                              stripe: _vm.stripe,
                              highlight: _vm.highlightCurrentRow,
                              "row-class-name": _vm.rowClassName,
                              "row-style": _vm.rowStyle,
                              "row-height": _vm.rowHeight,
                            },
                          }),
                          _vm.$slots.append
                            ? _c("div", {
                                staticClass: "yxt-table__append-gutter",
                                style: {
                                  height: _vm.layout.appendHeight + "px",
                                },
                              })
                            : _vm._e(),
                        ],
                        1
                      ),
                      _vm.showSummary
                        ? _c(
                            "div",
                            {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value: _vm.data && _vm.data.length > 0,
                                  expression: "data && data.length > 0",
                                },
                              ],
                              ref: "fixedFooterWrapper",
                              staticClass: "yxt-table__fixed-footer-wrapper",
                            },
                            [
                              _c("table-footer", {
                                style: {
                                  width: _vm.bodyWidth,
                                },
                                attrs: {
                                  fixed: "left",
                                  border: _vm.border,
                                  "sum-text":
                                    _vm.sumText ||
                                    _vm.t("pc_comp_table_sumText"),
                                  "summary-method": _vm.summaryMethod,
                                  store: _vm.store,
                                },
                              }),
                            ],
                            1
                          )
                        : _vm._e(),
                    ]
                  )
                : _vm._e(),
              _vm.rightFixedColumns.length > 0
                ? _c(
                    "div",
                    {
                      directives: [
                        {
                          name: "mousewheel",
                          rawName: "v-mousewheel",
                          value: _vm.handleFixedMousewheel,
                          expression: "handleFixedMousewheel",
                        },
                      ],
                      ref: "rightFixedWrapper",
                      staticClass: "yxt-table__fixed-right",
                      style: [
                        {
                          width: _vm.layout.rightFixedWidth
                            ? _vm.layout.rightFixedWidth + "px"
                            : "",
                          right: _vm.layout.scrollY
                            ? (_vm.border
                                ? _vm.layout.gutterWidth
                                : _vm.layout.gutterWidth || 0) + "px"
                            : "",
                        },
                        !_vm.data || _vm.data.length === 0
                          ? { height: _vm.layout.headerHeight + "px" }
                          : _vm.fixedHeight,
                      ],
                    },
                    [
                      _vm.showHeader
                        ? _c(
                            "div",
                            {
                              ref: "rightFixedHeaderWrapper",
                              staticClass: "yxt-table__fixed-header-wrapper",
                            },
                            [
                              _c("table-header", {
                                ref: "rightFixedTableHeader",
                                style: {
                                  width: _vm.bodyWidth,
                                },
                                attrs: {
                                  fixed: "right",
                                  tooltipEffect: _vm.tooltipEffect,
                                  border: _vm.border,
                                  store: _vm.store,
                                },
                              }),
                            ],
                            1
                          )
                        : _vm._e(),
                      _c(
                        "div",
                        {
                          ref: "rightFixedBodyWrapper",
                          staticClass: "yxt-table__fixed-body-wrapper",
                          style: [
                            {
                              top: _vm.layout.headerHeight + "px",
                            },
                            _vm.fixedBodyHeight,
                          ],
                        },
                        [
                          _c("table-body", {
                            style: [
                              {
                                width: _vm.bodyWidth,
                              },
                              _vm.tableStyle,
                            ],
                            attrs: {
                              fixed: "right",
                              store: _vm.store,
                              virtual: _vm.virtual,
                              tooltipEffect: _vm.tooltipEffect,
                              list: _vm.renderList,
                              stripe: _vm.stripe,
                              "row-class-name": _vm.rowClassName,
                              "row-style": _vm.rowStyle,
                              "row-height": _vm.rowHeight,
                              highlight: _vm.highlightCurrentRow,
                            },
                          }),
                          _vm.$slots.append
                            ? _c("div", {
                                staticClass: "yxt-table__append-gutter",
                                style: {
                                  height: _vm.layout.appendHeight + "px",
                                },
                              })
                            : _vm._e(),
                        ],
                        1
                      ),
                      _vm.showSummary
                        ? _c(
                            "div",
                            {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value: _vm.data && _vm.data.length > 0,
                                  expression: "data && data.length > 0",
                                },
                              ],
                              ref: "rightFixedFooterWrapper",
                              staticClass: "yxt-table__fixed-footer-wrapper",
                            },
                            [
                              _c("table-footer", {
                                style: {
                                  width: _vm.bodyWidth,
                                },
                                attrs: {
                                  fixed: "right",
                                  border: _vm.border,
                                  "sum-text":
                                    _vm.sumText ||
                                    _vm.t("pc_comp_table_sumText"),
                                  "summary-method": _vm.summaryMethod,
                                  store: _vm.store,
                                  "is-number": _vm.isNumber,
                                },
                              }),
                            ],
                            1
                          )
                        : _vm._e(),
                    ]
                  )
                : _vm._e(),
              _vm.rightFixedColumns.length > 0
                ? _c("div", {
                    ref: "rightFixedPatch",
                    staticClass: "yxt-table__fixed-right-patch",
                    style: {
                      width: _vm.layout.scrollY
                        ? _vm.layout.gutterWidth + "px"
                        : "0",
                      height: _vm.layout.headerHeight + "px",
                    },
                  })
                : _vm._e(),
              _c("div", {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.resizeProxyVisible,
                    expression: "resizeProxyVisible",
                  },
                ],
                ref: "resizeProxy",
                staticClass: "yxt-table__column-resize-proxy",
              }),
            ]
          )
        : _vm._e(),
      !_vm.customColumnTop ? _c("custom-column") : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }