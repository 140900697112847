var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "transition",
    {
      attrs: { name: "yxt-zoom-in-top" },
      on: { "after-leave": _vm.doDestroy },
    },
    [
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.showPopper,
              expression: "showPopper",
            },
          ],
          staticClass: "yxt-autocomplete-suggestion yxt-popper",
          class: {
            "is-loading": !_vm.parent.hideLoading && _vm.parent.loading,
          },
          style: { width: _vm.dropdownWidth },
          attrs: { role: "region" },
        },
        [
          _c(
            "yxt-scrollbar",
            {
              attrs: {
                tag: "ul",
                "wrap-class": "yxt-autocomplete-suggestion__wrap",
                "view-class": "yxt-autocomplete-suggestion__list",
              },
            },
            [
              !_vm.parent.hideLoading && _vm.parent.loading
                ? _c("li", [_c("i", { staticClass: "yxt-icon-loading" })])
                : _vm._t("default"),
            ],
            2
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }