import Pager from './pager.vue';
import yxtSelect from '@backstage/select';
import yxtOption from '@backstage/option';
import ElInput from '@backstage/input';
import Locale from '@utils/mixins/locale';
import { valueEquals } from '@utils/utils/util';

export default {
  name: 'YxtPagination',

  props: {
    pageSize: {
      type: Number,
      default: 10
    },
    simpleTotal: {
      type: Boolean,
      default: true
    },
    simpleTotalPage: {
      type: Boolean,
      default: false
    },
    jumperAutoWidth: {
      type: Boolean,
      default: false
    },
    small: Boolean,

    total: Number,

    pageCount: Number,

    pagerCount: {
      type: Number,
      validator(value) {
        return (
          (value | 0) === value && value > 4 && value < 22 && value % 2 === 1
        );
      },
      default: 7
    },

    currentPage: {
      type: Number,
      default: 1
    },

    layout: {
      default: 'total, prev, pager, next, sizes, jumper'
    },

    pageSizes: {
      type: Array,
      default() {
        return [10, 20, 50, 100];
      }
    },

    popperClass: String,

    prevText: String,

    nextText: String,

    jumperNoText: Boolean,

    prevNextNoBorder: {
      type: Boolean
    },

    background: Boolean,

    disabled: Boolean,

    showOnSinglePage: {
      type: Boolean,
      default: true
    }
  },

  data() {
    return {
      internalCurrentPage: 1,
      internalPageSize: 0,
      lastEmittedPage: -1,
      userChangePageSize: false
    };
  },

  render(h) {
    const layout = this.layout;
    if (!layout) return null;
    if (
      !this.showOnSinglePage &&
      (!this.internalPageCount || this.internalPageCount === 1)
    ) {
      return null;
    }

    let template = (
      <div
        class={[
          'yxt-pagination',
          {
            'is-background': this.background,
            'yxt-pagination--small': this.small
          }
        ]}
      ></div>
    );
    const TEMPLATE_MAP = {
      prev: <prev></prev>,
      jumper: <jumper></jumper>,
      pager: (
        <pager
          currentPage={this.internalCurrentPage}
          pageCount={this.internalPageCount}
          pagerCount={this.pagerCount}
          on-change={this.handleCurrentChange}
          disabled={this.disabled}
          small={this.small}
        ></pager>
      ),
      next: <next></next>,
      sizes: <sizes pageSizes={this.pageSizes}></sizes>,
      slot: <slot>{this.$slots.default ? this.$slots.default : ''}</slot>,
      total: <total></total>
    };
    const components = layout.split(',').map(item => item.trim());
    const rightWrapper = <div class="yxt-pagination__rightwrapper"></div>;
    let haveRightWrapper = false;

    template.children = template.children || [];
    rightWrapper.children = rightWrapper.children || [];
    components.forEach(compo => {
      if (compo === '->') {
        haveRightWrapper = true;
        return;
      }

      if (!haveRightWrapper) {
        template.children.push(TEMPLATE_MAP[compo]);
      } else {
        rightWrapper.children.push(TEMPLATE_MAP[compo]);
      }
    });

    if (haveRightWrapper) {
      template.children.unshift(rightWrapper);
    }

    return template;
  },

  components: {
    Prev: {
      render(h) {
        return (
          <button
            type="button"
            class={[
              'btn-prev',
              {
                'btn-prev-no-border': this.$parent.prevNextNoBorder
              }
            ]}
            disabled={
              this.$parent.disabled || this.$parent.internalCurrentPage <= 1
            }
            on-click={this.$parent.prev}
          >
            {this.$parent.prevText ? (
              <span>{this.$parent.prevText}</span>
            ) : (
              <i class="yxt-icon yxt-icon-arrow-left"></i>
            )}
          </button>
        );
      }
    },

    Next: {
      render(h) {
        return (
          <button
            type="button"
            class={[
              'btn-next',
              {
                'btn-next-no-border': this.$parent.prevNextNoBorder
              }
            ]}
            disabled={
              this.$parent.disabled ||
              this.$parent.internalCurrentPage ===
                this.$parent.internalPageCount ||
              this.$parent.internalPageCount === 0
            }
            on-click={this.$parent.next}
          >
            {this.$parent.nextText ? (
              <span>{this.$parent.nextText}</span>
            ) : (
              <i class="yxt-icon yxt-icon-arrow-right"></i>
            )}
          </button>
        );
      }
    },

    Sizes: {
      mixins: [Locale],

      data() {
        return {
          borderHover: false
        };
      },

      props: {
        pageSizes: Array
      },

      watch: {
        pageSizes: {
          immediate: true,
          handler(newVal, oldVal) {
            if (valueEquals(newVal, oldVal)) return;
            if (Array.isArray(newVal)) {
              this.$parent.internalPageSize =
                newVal.indexOf(this.$parent.pageSize) > -1
                  ? this.$parent.pageSize
                  : this.pageSizes[0];
            }
          }
        }
      },

      render(h) {
        return (
          <span
            class={[
              'yxt-pagination__sizes',
              {
                'yxt-pagination__sizes-toggle': this.borderHover
              }
            ]}
          >
            <yxt-select
              value={this.$parent.internalPageSize}
              popperClass={this.$parent.popperClass || ''}
              size={this.$parent.small ? 'mini' : 'normal'}
              on-input={this.handleChange}
              disabled={this.$parent.disabled}
              on-visible-change={this.handleSizesVisibleChange}
            >
              {this.pageSizes.map(item => (
                <yxt-option
                  value={item}
                  label={this.t('pc_comp_pagination_pagesize', { size: item})}
                ></yxt-option>
              ))}
            </yxt-select>
          </span>
        );
      },

      components: {
        yxtSelect,
        yxtOption
      },

      methods: {
        handleChange(val) {
          if (val !== this.$parent.internalPageSize) {
            this.$parent.internalPageSize = val = parseInt(val, 10);
            this.$parent.userChangePageSize = true;
            this.$parent.$emit('update:pageSize', val);
            this.$parent.$emit('size-change', val);
          }
        },
        handleSizesVisibleChange(val) {
          // console.log('handleSizesVisibleChange =======', val);
          this.borderHover = val;
        }
      }
    },

    Jumper: {
      mixins: [Locale],

      components: { ElInput },

      data() {
        return {
          userInput: null,
          spanText: '',
          spanWidth: '30px'
        };
      },

      watch: {
        '$parent.internalCurrentPage'() {
          this.userInput = null;
        }
      },

      mounted() {
        this.setJumperWidth();
        // this.spanText = this.userInput !== null ? this.userInput : this.$parent.simpleTotalPage ? this.$parent.internalCurrentPage : '';
      },

      methods: {
        handleKeyup({ keyCode, target }) {
          // Chrome, Safari, Firefox triggers change event on Enter
          // Hack for IE: https://github.com/ElemeFE/element/issues/11710
          // Drop this method when we no longer supports IE
          if (keyCode === 13) {
            console.log('handleKeyup --- 13');
            // this.handleChange(target.value);
          }
        },
        setJumperWidth() {
          if (!this.$parent.jumperAutoWidth) {
            this.spanWidth = '50px';
            return;
          }
          this.spanText = this.userInput !== null ? this.userInput : this.$parent.simpleTotalPage ? this.$parent.internalCurrentPage : '';
          this.$nextTick(() => {
            // 如果不用$nextTick的话页面并不会更新，它是在下次dom更新后再渲染到页面上
            let width = this.$el.querySelector('.yxt-pagination__jump-span-txt').getBoundingClientRect().width || '30';
            // console.log('++==', width);
            if (width < 30) {
              width = 30;
            }
            // console.log('====', width);
            this.spanWidth = `${width}px`;
          });
        },
        handleInput(value) {
          console.log('handleInput --- ', value);
          this.userInput = value;
          // this.spanText = this.userInput;
          this.setJumperWidth();
        },
        handleChange(value) {
          console.log('handleChange --- ', value);
          this.$parent.internalCurrentPage = this.$parent.getValidCurrentPage(
            value
          );
          this.$parent.emitChange();
          this.userInput = null;
          this.setJumperWidth();
        }
      },

      render(h) {
        let pc_comp_pagination_goto = this.t('pc_comp_pagination_goto');
        let pc_comp_pagination_pageClassifier = this.t('pc_comp_pagination_pageClassifier');
        if (this.$parent.jumperNoText) {
          pc_comp_pagination_goto = '';
          pc_comp_pagination_pageClassifier = '';
        }
        return (
          <span
            class={[
              'pagination__jump',
              {
                'pagination__jump-simple-total-page': this.$parent.simpleTotalPage
              }
            ]}
          >
            {pc_comp_pagination_goto}
            <yxt-input
              style={{ width: this.spanWidth }}
              class={[
                'yxt-pagination__editor',
                'is-in-pagination',
                {
                  'pagination__editor-auto-width': this.$parent.jumperAutoWidth
                }
              ]}
              min={1}
              max={this.$parent.internalPageCount}
              value={
                this.userInput !== null
                  ? this.userInput
                  : this.$parent.simpleTotalPage ? this.$parent.internalCurrentPage : ''
                  // : 'this.$parent.internalCurrentPage'
              }
              type="number"
              disabled={this.$parent.disabled}
              nativeOnKeyup={this.handleKeyup}
              onInput={this.handleInput}
              onChange={this.handleChange}
            />
            <div class="yxt-pagination__jump-span-txt">{ this.spanText }</div>
            {pc_comp_pagination_pageClassifier}
          </span>
        );
      }
    },

    Total: {
      mixins: [Locale],

      render(h) {
        const extra = () => {
          if (this.$parent.simpleTotal) {
            return '';
          }
          return (
            ' ' +
            this.t('pc_comp_pagination_currentPage', {
              current: `${this.$parent.currentPage} / ${this.$parent.internalPageCount}`
            })
          );
        };
        const extraTotal = () => {
          let result = '';
          if (this.$parent.simpleTotalPage) {
            result = <span class="yxt-pagination__total yxt-pagination__total-simple-total-page">
              {` / ${this.$parent.internalPageCount}`}
            </span>;
          } else {
            result = typeof this.$parent.total === 'number' ? (
              <span class="yxt-pagination__total">
                {this.t('pc_comp_pagination_total', { total: this.$parent.total }) + extra()}
              </span>
            ) : (
              ''
            );
          }
          return result;
        };
        return extraTotal();
      }
    },

    Pager
  },

  methods: {
    handleCurrentChange(val) {
      this.internalCurrentPage = this.getValidCurrentPage(val);
      this.userChangePageSize = true;
      this.emitChange();
    },
    prev() {
      if (this.disabled) return;
      const newVal = this.internalCurrentPage - 1;
      this.internalCurrentPage = this.getValidCurrentPage(newVal);
      this.$emit('prev-click', this.internalCurrentPage);
      this.emitChange();
    },

    next() {
      if (this.disabled) return;
      const newVal = this.internalCurrentPage + 1;
      this.internalCurrentPage = this.getValidCurrentPage(newVal);
      this.$emit('next-click', this.internalCurrentPage);
      this.emitChange();
    },

    getValidCurrentPage(value) {
      value = parseInt(value, 10);

      const havePageCount = typeof this.internalPageCount === 'number';

      let resetValue;
      if (!havePageCount) {
        if (isNaN(value) || value < 1) resetValue = 1;
      } else {
        if (value < 1) {
          resetValue = 1;
        } else if (value > this.internalPageCount) {
          resetValue = this.internalPageCount;
        }
      }

      if (resetValue === undefined && isNaN(value)) {
        resetValue = 1;
      } else if (resetValue === 0) {
        resetValue = 1;
      }

      return resetValue === undefined ? value : resetValue;
    },

    emitChange() {
      this.$nextTick(() => {
        if (
          this.internalCurrentPage !== this.lastEmittedPage ||
          this.userChangePageSize
        ) {
          this.$emit('current-change', this.internalCurrentPage);
          this.lastEmittedPage = this.internalCurrentPage;
          this.userChangePageSize = false;
        }
      });
    }
  },

  computed: {
    internalPageCount() {
      if (typeof this.total === 'number') {
        return Math.max(1, Math.ceil(this.total / this.internalPageSize));
      } else if (typeof this.pageCount === 'number') {
        return Math.max(1, this.pageCount);
      }
      return null;
    }
  },

  watch: {
    currentPage: {
      immediate: true,
      handler(val) {
        this.internalCurrentPage = this.getValidCurrentPage(val);
      }
    },

    pageSize: {
      immediate: true,
      handler(val) {
        this.internalPageSize = isNaN(val) ? 10 : val;
      }
    },

    internalCurrentPage: {
      immediate: true,
      handler(newVal) {
        this.$emit('update:currentPage', newVal);
        this.lastEmittedPage = -1;
      }
    },

    internalPageCount(newVal) {
      /* istanbul ignore if */
      const oldPage = this.internalCurrentPage;
      if (newVal > 0 && oldPage === 0) {
        this.internalCurrentPage = 1;
      } else if (oldPage > newVal) {
        this.internalCurrentPage = newVal === 0 ? 1 : newVal;
        this.userChangePageSize && this.emitChange();
      }
      this.userChangePageSize = false;
    }
  }
};
