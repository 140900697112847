<template>
  <div
    class="yxt-anchor"
    ref="anchorRef"
    :class="[
      'yxt-anchor--' + direction,
      this.type === 'underline' ? 'yxt-anchor--underline' : '',
    ]"
  >
    <div
      v-if="marker"
      ref="markerRef"
      class="yxt-anchor__marker"
      :style="markerStyle"
    />
    <div class="yxt-anchor__list">
      <slot></slot>
    </div>
  </div>
</template>

<script>
import { getChildByName, getElement, throttleByRaf } from '@utils/utils/util';
import { isWindow, isNewUndefined } from '@utils/utils/types';
import { getScrollElement, animateScrollTo, getScrollTop } from '@utils/utils/scroll-into-view';
import { getOffsetTopDistance } from '@utils/utils/position';
export default {
  name: 'YxtAnchor',
  props: {
    duration: {
      type: Number,
      default: 300
    },
    direction: {
      type: String,
      default: 'vertical'
    },
    marker: {
      type: Boolean,
      default: true
    },
    type: {
      type: String,
      default: 'default'
    },
    offset: {
      type: Number,
      default: 0
    },
    bound: {
      type: Number,
      default: 0
    },
    container: {
      type: [String, HTMLElement, Window, null],
      default: null
    }
  },
  computed: {},
  data() {
    return {
      items: [],
      currentAnchor: '',
      containerEl: null,
      clearAnimate: null,
      isScrolling: false,
      currentScrollTop: 0,
      handleScroll: null,
      markerStyle: {}
    };
  },
  methods: {
    getCurrentHref() {
      if (!this.containerEl) return;
      const scrollTop = getScrollTop(this.containerEl);
      const anchorTopList = [];
      for (const item of this.items) {
        const target = getElement(item.href);
        if (!target) continue;
        const scrollEle = getScrollElement(target, this.containerEl);
        const distance = getOffsetTopDistance(target, scrollEle);
        anchorTopList.push({
          top: distance - this.offset - this.bound,
          href: item.href
        });
      }
      anchorTopList.sort((prev, next) => prev.top - next.top);
      for (let i = 0; i < anchorTopList.length; i++) {
        const item = anchorTopList[i];
        const next = anchorTopList[i + 1];

        if (i === 0 && scrollTop === 0) {
          return '';
        }
        if (item.top <= scrollTop && (!next || next.top > scrollTop)) {
          return item.href;
        }
      }
    },
    setMarkerStyle() {
      if (!this.$refs.anchorRef || !this.$refs.markerRef || !this.currentAnchor) {
        return {};
      }

      const currentLinkEl = this.items.filter(
        (item) => item.href === this.currentAnchor
      )[0];
      if (!currentLinkEl) return {};
      const anchorRect = this.$refs.anchorRef.getBoundingClientRect();
      const markerRect = this.$refs.markerRef.getBoundingClientRect();
      const linkRect = currentLinkEl.$refs.linkRef.getBoundingClientRect();
      const left = linkRect.left - anchorRect.left;
      if (this.direction === 'horizontal') {
        this.markerStyle = {
          left: `${left}px`,
          width: `${linkRect.width}px`,
          opacity: 1
        };
      } else {
        const top =
          linkRect.top -
          anchorRect.top +
          (linkRect.height - markerRect.height) / 2;
        this.markerStyle = {
          top: `${top}px`,
          opacity: 1
        };
      }
    },
    updateItems() {
      const items = [];
      this.$children.forEach((child) => {
        items.push(
          getChildByName(child.$vnode, 'YxtAnchorLink').componentInstance
        );
      });
      this.items = items;
    },
    handleClick(e, href) {
      this.$emit('click', e, href);
      this.scrollTo(href);
    },
    setCurrentAnchor(href) {
      if (this.currentAnchor !== href) {
        this.currentAnchor = href;
        this.$emit('change', href);
        this.setMarkerStyle();
      }
    },
    scrollTo(href) {
      if (href) {
        this.setCurrentAnchor(href);
        this.scrollToAnchor(href);
      }
    },
    scrollToAnchor(href) {
      if (!this.containerEl) return;
      const target = getElement(href);
      if (!target) return;
      if (this.clearAnimate) this.clearAnimate();
      this.isScrolling = true;
      const scrollEle = getScrollElement(target, this.containerEl);
      const distance = getOffsetTopDistance(target, scrollEle);
      const max = scrollEle.scrollHeight - scrollEle.clientHeight;
      const to = Math.min(distance - this.offset, max);
      this.clearAnimate = animateScrollTo(
        this.containerEl,
        this.currentScrollTop,
        to,
        this.duration,
        () => {
          // make sure it is executed after throttleByRaf's handleScroll
          setTimeout(() => {
            this.isScrolling = false;
          }, 20);
        }
      );
    },
    getContainer() {
      const el = getElement(this.container);
      if (!el || isWindow(el)) {
        this.containerEl = window;
      } else {
        this.containerEl = el;
      }
    }

  },
  watch: {
    container() {
      this.getContainer();
    }
  },
  mounted() {
    // 获取容器
    this.getContainer();
    this.handleScroll = throttleByRaf(() => {
      if (this.containerEl) {
        this.currentScrollTop = getScrollTop(this.containerEl);
      }
      const currentHref = this.getCurrentHref();
      if (this.isScrolling || isNewUndefined(currentHref)) return;
      this.setCurrentAnchor(currentHref);
    });
    this.updateItems();
    const hash = decodeURIComponent(window.location.hash);
    const target = getElement(hash);
    if (target) {
      this.scrollTo(hash);
    } else {
      this.handleScroll();
    }
    this.containerEl.addEventListener('scroll', this.handleScroll);
  },
  unmounted() {
    this.containerEl.removeEventListener('scroll', this.handleScroll);
  }
};
</script>
