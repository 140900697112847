var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "yxt-card",
      class: _vm.shadow ? "is-" + _vm.shadow + "-shadow" : "is-always-shadow",
    },
    [
      _vm.$slots.header || _vm.header
        ? _c(
            "div",
            { staticClass: "yxt-card__header" },
            [_vm._t("header", [_vm._v(_vm._s(_vm.header))])],
            2
          )
        : _vm._e(),
      _c(
        "div",
        { staticClass: "yxt-card__body", style: _vm.bodyStyle },
        [_vm._t("default")],
        2
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }