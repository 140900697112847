var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "a",
    _vm._b(
      {
        class: [
          "yxtf-link",
          _vm.type ? "yxtf-link--" + _vm.type : "",
          _vm.disabled && "is-disabled",
          _vm.underline && !_vm.disabled && "is-underline",
        ],
        attrs: { href: _vm.disabled ? null : _vm.href },
        on: { click: _vm.handleClick },
      },
      "a",
      _vm.$attrs,
      false
    ),
    [
      _vm.icon ? _c("i", { class: _vm.icon }) : _vm._e(),
      _vm.$slots.default
        ? _c(
            "span",
            { staticClass: "yxtf-link--inner" },
            [_vm._t("default")],
            2
          )
        : _vm._e(),
      _vm.$slots.icon
        ? [_vm.$slots.icon ? _vm._t("icon") : _vm._e()]
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }