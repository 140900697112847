<template>
  <div class="yxt-step" :style="style" :class="[
    !isSimple && !isBasic && !isNavigation && `is-${$parent.direction}`,
    isSimple && !isBasic && !isNavigation && 'is-simple',
    isBasic && !isSimple && !isNavigation && 'is-basic',
    isNavigation && !isSimple && !isBasic && 'is-navigation',
    isLast && !space && !isCenter && 'is-flex',
    isCenter && !isVertical && !isSimple && 'is-center',
    size && $parent.direction === 'horizontal' && `is-${size}`
  ]">
    <!-- icon & line -->
    <div v-if="!isBasic" class="yxt-step__head"  @click="onStepClick" :class="[`is-${currentStatus}`,`yxt-step__${cursorType}`]">
      <div v-if="!isNavigation" class="yxt-step__line" :style="isLast ? '' : { marginRight: $parent.stepOffset + 'px' }">
        <i class="yxt-step__line-inner" :style="lineStyle"></i>
      </div>

      <div class="yxt-step__icon" :class="[`is-${icon ? 'icon' : 'text'}`,isNavigation&&currentStatus=='process'&&'is-text-process']">
        <slot v-if="currentStatus !== 'success' && currentStatus !== 'error'" name="icon">
          <i v-if="icon" class="yxt-step__icon-inner" :class="[icon]"></i>
          <div class="yxt-step__icon-inner" v-if="!icon && !isSimple">{{ index + 1 }}</div>
        </slot>
        <i v-else :class="['yxt-icon-' + (currentStatus === 'success' ? 'check' : 'close')]"
          class="yxt-step__icon-inner is-status">
        </i>
      </div>
    </div>
    <!-- title & description -->
    <div class="yxt-step__main">
      <div class="yxt-step__title"  @click="onStepClick" ref="title" :class="['is-' + currentStatus,`yxt-step__${cursorType}`,hoverClass]">
        <i v-if="currentStatus === 'finish' && isBasic" class="yxt-icon-circle-check"></i>
        <slot name="title">{{ title }}</slot>
      </div>
      <div v-if="isSimple" class="yxt-step__arrow"></div>
      <div v-if="isNavigation" class="yxt-step__navigation-arrow"></div>
      <div v-if="!isSimple && !isBasic && !isNavigation" class="yxt-step__description" :class="['is-' + currentStatus]">
        <slot name="description">{{ description }}</slot>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'YxtStep',

  props: {
    title: String,
    icon: String,
    description: String,
    status: String
  },

  data() {
    return {
      index: -1,
      lineStyle: {},
      internalStatus: ''
    };
  },

  beforeCreate() {
    this.$parent.steps.push(this);
  },

  beforeDestroy() {
    const steps = this.$parent.steps;
    const index = steps.indexOf(this);
    if (index >= 0) {
      steps.splice(index, 1);
    }
  },

  computed: {
    currentStatus() {
      return this.status || this.internalStatus;
    },
    prevStatus() {
      const prevStep = this.$parent.steps[this.index - 1];
      return prevStep ? prevStep.currentStatus : 'wait';
    },
    isCenter() {
      return this.$parent.alignCenter || this.$parent.basic;
    },
    isVertical() {
      return this.$parent.direction === 'vertical';
    },
    isNavigation() {
      return this.$parent.type === 'navigation';
    },
    isSimple() {
      return this.$parent.simple || this.$parent.type === 'simple';
    },
    isBasic() {
      return this.$parent.basic || this.$parent.type === 'basic';
    },
    isLast() {
      const parent = this.$parent;
      return parent.steps[parent.steps.length - 1] === this;
    },
    stepsCount() {
      return this.$parent.steps.length;
    },
    space() {
      const { isSimple, $parent: { space } } = this;
      return isSimple ? '' : space;
    },
    size() {
      return this.$parent.size || '';
    },
    style() {
      const style = {};
      const parent = this.$parent;
      const len = parent.steps.length;

      const space = (typeof this.space === 'number'
        ? this.space + 'px'
        : this.space
          ? this.space
          : 100 / (len - (this.isCenter ? 0 : 1)) + '%');
      // style.flexBasis = space;
      style.flex = '0 1 ' + space;
      if (this.isNavigation) {
        delete style.flex;
      }
      if (this.isVertical) return style;
      if (this.isLast) {
        style.maxWidth = 100 / this.stepsCount + '%';
      } else {
        style.marginRight = -this.$parent.stepOffset + 'px';
      }

      return style;
    },
    isOperate() {
      if (this.$parent.operate && this.$parent.internalActive > this.index) {
        return true;
      } else {
        return false;
      }
    },
    isAlloperate() {
      if (this.$parent.alloperate && this.$parent.internalActive !== this.index) {
        return true;
      } else {
        return false;
      }
    },
    /**
     * 鼠标手势类型
     * @return 0 : default,1:pointer,2:disabled
     */
    cursorType() {
      if (!this.isNavigation) {
        return 'default';
      }

      if (!this.$parent.operate && !this.$parent.alloperate) {
        if (this.currentStatus === 'process') {
          return 'default';
        } else {
          return 'notAllowed';
        }
      }
      switch (true) {
        case this.$parent.internalActive < this.index && !this.$parent.alloperate:
          return 'notAllowed';
        case this.$parent.internalActive < this.index && this.$parent.alloperate:
          return 'pointer';
        case this.$parent.internalActive === this.index:
          return 'default';
        case this.$parent.internalActive > this.index:
          return 'pointer';
        default:
          return 'default';
      }
    },
    hoverClass() {
      if (this.isOperate && ['success'].includes(this.currentStatus)) {
        return 'is-operate';
      } else if (this.isAlloperate && ['success', 'wait', 'process'].includes(this.currentStatus)) {
        return 'is-operate';
      }
    }
  },

  methods: {
    updateStatus(val) {
      const prevChild = this.$parent.$children[this.index - 1];

      if (val > this.index) {
        this.internalStatus = this.$parent.finishStatus;
      } else if (val === this.index && this.prevStatus !== 'error') {
        this.internalStatus = this.$parent.processStatus;
      } else {
        this.internalStatus = 'wait';
      }

      if (prevChild) prevChild.calcProgress(this.internalStatus);
    },

    calcProgress(status) {
      let step = 100;
      const style = {};

      style.transitionDelay = 150 * this.index + 'ms';
      if (status === this.$parent.processStatus) {
        step = this.currentStatus !== 'error' ? 0 : 0;
      } else if (status === 'wait') {
        step = 0;
        style.transitionDelay = (-150 * this.index) + 'ms';
      }

      style.borderWidth = step && !this.isSimple ? '1px' : 0;
      this.$parent.direction === 'vertical'
        ? style.height = step + '%'
        : style.width = step + '%';

      this.lineStyle = style;
    },

    /**
     * 点击当前步骤
     */
    onStepClick() {
      const cb = ()=>{
        this.$parent.internalActive = this.index;
      };
      if (this.isOperate || this.isAlloperate) {
        if (typeof this.$parent.beforeClickStep === 'function') {
          this.$parent.beforeClickStep(cb, this.index);
        } else {
          cb();
        }
      }

    }
  },

  mounted() {
    const unwatch = this.$watch('index', val => {
      this.$watch('$parent.active', this.updateStatus, { immediate: true });
      this.$watch('$parent.internalActive', this.updateStatus, { immediate: true });
      this.$watch('$parent.processStatus', () => {
        const activeIndex = this.$parent.active;
        this.updateStatus(activeIndex);
      }, { immediate: true });
      unwatch();
    });
  }
};
</script>
