<template>
  <div class='yxt-sticky' :class="wrapClass" :style='style'>
    <div ref='container' class='yxt-sticky__inner' :class="[stickyClass, {'is-fixed': fixed, 'yxt-sticky--animate': animated}]" :style="stickyStyle">
      <slot></slot>
    </div>
  </div>
</template>

<script>
import { getScrollParent } from './utils';
import ResizeObserver from 'resize-observer-polyfill';
export default {
  name: 'YxtSticky',
  props: {
    offset: {
      type: Number,
      default: 0
    },
    wrapClass: {
      type: String,
      default: ''
    },
    stickyClass: {
      type: String,
      default: ''
    },
    container: {
      default: null
    },
    allowance: {
      default: 0,
      type: Number
    },
    keepSize: {
      default: false,
      type: Boolean
    },
    keepLeft: {
      default: true, // 是否保持左侧和父容器对齐
      type: Boolean
    },
    width: { // 只做宽度改变的标识，不实际赋值
      default: 0,
      type: [Number, String]
    },
    animated: {
      default: false,
      type: Boolean
    },
    resizable: {
      default: false,
      type: Boolean
    },
    keepTop: {
      default: false,
      type: Boolean // 内部元素是否为fixed定位, 如果是，在触发悬浮前需要跟随父元素的位置
    },
    fixHeight: {
      default: '',
      type: String
    },
    autoSize: {
      default: false,
      type: Boolean
    },
    bg: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      fixed: false,
      offsetX: 0,
      offsetY: this.offset,
      scroller: null,
      eleHeight: 0,
      eleWidth: 0
    };
  },
  computed: {
    style() {
      let result = {};
      if (!this.keepSize) {
        result.height = `${this.eleHeight}px`;
      } else if (this.fixHeight && this.fixed) {
        result.height = this.fixHeight;
      }
      return result;
    },
    stickyStyle() {
      let result = {
        'background-color': this.bg
      };

      if (this.autoSize) {
        result.height = `${this.eleHeight}px`;
      }

      if (this.fixed) {
        result.top = `${this.offset}px`;
        result.width = `${this.eleWidth}px`;

        if (this.fixHeight) {
          result.height = this.fixHeight;
        }
      } else if (this.keepTop) {
        result.top = `${this.offsetY}px`;
      }

      if (this.fixed && this.keepLeft) {
        result.left = `${this.offsetX}px`;
      }

      return result;
    }
  },
  mounted() {
    this.requestAnim = null;
    this.offsetY = this.$el.getBoundingClientRect().top;
    this.calSize();
    this.scroller = this.container || getScrollParent(this.$el);
    this.scrollHandler();
    this.initEvent();

    if (this.autoSize) {
      const observer = new ResizeObserver(entries => {
        for (const entry of entries) {
          const {width, height} = entry.contentRect;
          if (!this.resizable) {
            this.eleWidth = width;
          }
          this.eleHeight = height;
        }
      });

      observer.observe(this.$slots.default[0].elm);
    }
  },
  destroyed() {
    this.destroyEvent();
  },
  methods: {
    initEvent() {
      this.scroller.addEventListener('scroll', this.scrollHandler);
      if (this.resizable) {
        window.addEventListener('resize', this.calSize);
      }
    },
    destroyEvent() {
      this.scroller.removeEventListener('scroll', this.scrollHandler);
      window.removeEventListener('resize', this.calSize);
    },
    calSize() {
      // 重新计算宽度
      this.eleWidth = this.$el.offsetWidth;
    },
    place() {
      this.$nextTick(() => {
        this.scrollHandler();
        this.calSize();
      });
    },
    scrollHandler() {
      // if (this.requestAnim) {
      //   window.cancelAnimationFrame(this.requestAnim);
      // }

      this.requestAnim = window.requestAnimationFrame(() => {
        this.eleHeight = this.$refs['container'].offsetHeight;
        const { top, left} = this.$el.getBoundingClientRect();
        this.offsetX = left;
        let oldFixed = this.fixed;
        this.fixed = top <= (this.offset + this.allowance);

        if (this.keepTop && !this.fixed) {
        // 如果元素是fixed，需要跟随父元素的顶部
          this.offsetY = top;
        } else {
          this.offsetY = this.offset;
        }

        if (oldFixed !== this.fixed) {
          this.calSize();
          // 吸附状态改变，触发外部change事件
          this.$emit('change', this.fixed);
        }
      });
    }
  },
  watch: {
    width() {
      this.calSize();
    }
  }
};
</script>
