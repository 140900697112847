<template>
  <transition name="yxt-zoom-in-top" @after-leave="doDestroy">
    <div class="yxt-color-dropdown" v-show="showPopper">
      <div class="yxt-color-dropdown__main-wrapper" style="overflow:hidden;">
        <sv-panel ref="sl" :color="color"></sv-panel>
      </div>
      <div class="yxt-color-dropdown__slider">
        <div class="yxt-color-dropdown__slider-left">
          <hue-slider ref="hue" :color="color"></hue-slider>
          <alpha-slider
            v-if="showAlpha"
            ref="alpha"
            :color="color"
          ></alpha-slider>
        </div>
        <div
          class="yxt-color-dropdown__slider-right"
          v-if="showAlpha"
          :style="{ backgroundColor: color.value }"
        ></div>
      </div>
      <div 
        class="yxt-color-dropdown__btns" 
        :style="{
          'justify-content': 'space-between',
          'border-bottom': predefine ? 'solid 1px #E9E9E9' : ''
        }">
        <span :style="showAlpha ? 'line-height: 24px;' : 'line-height: 24px;' " class="yxt-color-dropdown__hex-value">
          <yxt-input
            v-model="customInput"
            @keyup.native.enter="handleConfirm(customInput)"
            @blur="handleConfirm(customInput)"
            :validate-event="false"
            size="mini"
          >
          </yxt-input>
          Hex
        </span>
        <span :style="showAlpha ? 'width:30px;line-height: 24px;' : 'width:40px;line-height: 24px;' " class="yxt-color-dropdown__rgba-value">
          <yxt-input-number
            v-model="rgbaColor.r"
            :controls="false"
            :min="0"
            :max="255"
            @keyup.native.enter="handleConfirm()"
            @blur="handleConfirm()"
            :validate-event="false"
            size="mini"
          >
          </yxt-input-number>
          R
        </span>
        <span :style="showAlpha ? 'width:30px;line-height: 24px;' : 'width:40px;line-height: 24px;' " class="yxt-color-dropdown__rgba-value">
          <yxt-input-number
            v-model="rgbaColor.g"
            :min="0"
            :max="255"
            :controls="false"
            @keyup.native.enter="handleConfirm()"
            @blur="handleConfirm()"
            :validate-event="false"
            size="mini"
          >
          </yxt-input-number>
          G
        </span>
        <span :style="showAlpha ? 'width:30px;line-height: 24px;' : 'width:40px;line-height: 24px;' " class="yxt-color-dropdown__rgba-value">
          <yxt-input-number
            v-model="rgbaColor.b"
            :controls="false"
            :min="0"
            :max="255"
            @keyup.native.enter="handleConfirm()"
            @blur="handleConfirm()"
            :validate-event="false"
            size="mini"
          >
          </yxt-input-number>
          B
        </span>
        <span v-if="showAlpha" :style="showAlpha ? 'width:30px;line-height: 24px;' : 'width:40px;line-height: 24px;' " class="yxt-color-dropdown__rgba-value">
          <yxt-input-number
            :min="0"
            :max="100"
            v-model="rgbaColor.a"
            :controls="false"
            @keyup.native.enter="handleConfirm()"
            @blur="handleConfirm()"
            :validate-event="false"
            size="mini"
          >
          </yxt-input-number>
          A
        </span>
        <!-- <yxt-button
          size="mini"
          type="text"
          class="yxt-color-dropdown__link-btn"
          @click="$emit('clear')">
          {{ t('pc_comp_colorpicker_clear') }}
        </yxt-button>
        <yxt-button
          plain
          size="mini"
          class="yxt-color-dropdown__btn"
          @click="confirmValue">
          {{ t('pc_comp_colorpicker_confirm') }}
        </yxt-button> -->
      </div>

      <predefine
        ref="predefineRef"
        v-if="predefine"
        :color="color"
        :colors="predefine"
        @creatColor="handleParseColor"
        @removePredefineIndex="handleRemovePredefineIndex"
      ></predefine>
      <div class="slot_btn" v-if="footBtn">
        <yxt-button @click="showPopper = false">{{ t('pc_comp_datepicker_cancel')  }}</yxt-button>
        <yxt-button style="margin-left: 8px;" @click="addBtnColor" type="primary">{{ t('pc_comp_colorpicker_confirm') }}</yxt-button>
      </div>
    </div>
  </transition>
</template>

<script>
import SvPanel from './sv-panel';
import HueSlider from './hue-slider';
import AlphaSlider from './alpha-slider';
import Predefine from './predefine';
import Popper from '@utils/utils/vue-popper';
import Locale from '@utils/mixins/locale';
import ElInput from '@backstage/input';
import ElButton from '@backstage/button';
import Color from '../color';

export default {
  name: 'Yxt-color-picker-dropdown',

  mixins: [Popper, Locale],

  components: {
    SvPanel,
    HueSlider,
    AlphaSlider,
    ElInput,
    ElButton,
    Predefine
  },

  props: {
    color: {
      required: true
    },
    showAlpha: Boolean,
    predefine: Array,
    footBtn: Boolean
  },

  data() {
    return {
      customInput: '',
      rgbaColor: {
        r: 0,
        g: 0,
        b: 0,
        a: 100
      },
      showPopper: false,
      isFootBtnAdd: false
    };
  },

  computed: {
    currentColor() {
      const parent = this.$parent;
      // console.log('value:', parent.value, ' - showPanelColor', parent.showPanelColor, ' - color.value: ', parent.color.value);
      // console.log(parent.color);
      // console.log(parent.color.toHex());
      // console.log(parent.color.toHex({noAlpha: true}));
      const enableAlpha = parent.color.enableAlpha;
      if (!parent.value && !parent.showPanelColor) {
        return '';
      } else {
        let res = parent.color.value;
        if (!enableAlpha) {
          res = parent.color.toHex({noAlpha: true});
        }
        // console.log('res: ', res);
        return res;
      }
      // return !parent.value && !parent.showPanelColor ? '' : parent.color.value;
    }
  },

  methods: {
    addBtnColor() {
      this.showPopper = false;
      this.isFootBtnAdd = true;
    },
    handleParseColor(c) {
      if (this.predefine.some((i) => i === c.value)) return false;
      // let newColors = this.predefine.filter(function(value, index, self) {
      //   return self.indexOf(value) === index;
      // });
      // console.log(newColors, '123123');
      // console.log(this.predefine, 'this.predefine');
      const colExist = this.predefine.some((i) => {
        // console.log('handleParseColor ----- i: ', i, ' --- c.value: ', c.value);

        const ci = new Color();
        ci.enableAlpha = true;
        ci.format = 'rgba';
        ci.fromString(i);

        // console.log('handleParseColor ----- ci.value: ', ci.toRgba(), ' --- c.value: ', c.toRgba());
        // console.log('handleParseColor ----- ci.value: ', JSON.stringify(ci.toRgba()), ' --- c.value: ', JSON.stringify(c.toRgba()), '===', JSON.stringify(ci.toRgba) == JSON.stringify(c.toRgba()));
        // console.log(ci.r === c.r && ci.g === c.g && ci.b === c.b && ci.a === c.a);
        return JSON.stringify(ci.toRgba()) === JSON.stringify(c.toRgba());
        // return ci.r === c.r && ci.g === c.g && ci.b === c.b && ci.a === c.a;
      });
      console.log('handleParseColor, color exist', colExist);
      if (colExist) return false;
      // if (this.footBtn && this.isFootBtnAdd) {
      //   this.predefine.push(c.value);
      //   this.$emit('predefine-change', this.predefine);
      //   return false;
      // }
      if (this.footBtn) {
        if (this.isFootBtnAdd) {
          this.predefine.push(c.value);
          this.$emit('predefine-change', this.predefine);
        } else {
          return false;
        }
      } else {
        this.predefine.push(c.value);
        this.$emit('predefine-change', this.predefine);
      }
    },
    handleRemovePredefineIndex(index) {
      // console.log('handleRemovePredefineIndex.....', this.predefine);
      this.predefine.splice(index, 1);
      this.$emit('predefine-change', this.predefine);
      // console.log('handleRemovePredefineIndex-----', this.predefine);
    },
    confirmValue() {
      this.$emit('pick');
    },

    handleConfirm(val) {
      // console.log('handleConfirm --- ', val);
      if (val) {
        this.color.fromString(this.customInput);
      } else {
        const { r, g, b, a } = this.rgbaColor;
        const rgba = `rgba(${r}, ${g}, ${b}, ${a / 100})`;
        this.color.fromString(rgba);
      }
      // this.color.fromString(this.customInput);
    }
  },

  mounted() {
    this.$parent.popperElm = this.popperElm = this.$el;
    this.referenceElm = this.$parent.$el;
  },

  watch: {
    showPopper(val) {
      if (val === true) {
        this.$nextTick(() => {
          const { sl, hue, alpha } = this.$refs;
          sl && sl.update();
          hue && hue.update();
          alpha && alpha.update();
        });
      } else {
        // add color on close
        if (this.predefine) {
          this.$refs.predefineRef.creatColor();
        }
      }
    },

    currentColor: {
      immediate: true,
      handler(val) {
        // console.log('currentColor handler val --- ', val);

        // const cruColor = new Color({
        //   enableAlpha: this.showAlpha,
        //   format: this.colorFormat
        // });

        this.customInput = val;
        const parent = this.$parent;
        const enableAlpha = parent.color.enableAlpha;
        if (!enableAlpha) {
          this.customInput = this.color.toHex({noAlpha: true});
        }
        // console.log('currentColor handler  ---', 'customInput ---', this.customInput);
        // const rgbaColorObj = this.color.toRgba();
        // this.rgbaColor = Object.assign(this.rgbaColor, rgbaColorObj);

        this.rgbaColor = this.color.toRgba();
        // console.log('currentColor handler rgbaColor --- ', this.rgbaColor);
      }
    }
  }
};
</script>
