<template>
  <div
    :class="[
      'yxt-color-picker',
      colorDisabled ? 'is-disabled' : '',
      colorSize ? `yxt-color-picker--${colorSize}` : ''
    ]"
    :style="type === 'strip' ? 'height: 33px !important;' : ''"
    v-clickoutside="hide"
  >
    <div class="yxt-color-picker__mask" v-if="colorDisabled"></div>
    <!-- default -->
    <div v-if="type === 'default'" :style="bg_border" class="yxt-color-picker__trigger" @click="handleTrigger" @mouseover="mouseover" @mouseout="mouseout">
      <span 
        style="height: 101%;border-radius: 2px;border: none;"
        :class="{'yxt-color-picker__color': true, 'is-alpha': showAlpha }">
        <span
          class="yxt-color-picker__color-inner"
          :style="{
            backgroundColor: displayedColor,
            border: 'solid 1px rgba(0,0,0, 0.06)',
            'border-radius': '2px'
          }"
        ></span>
        <span
          class="yxt-color-picker__empty yxt-icon-close"
          v-if="!value && !showPanelColor"
        ></span>
      </span>
      <span
        class="yxt-color-picker__icon"
        v-show="value || showPanelColor"
      ></span>
    </div>
    <!-- strip -->
    <div v-if="type === 'strip'" :style="bg_border" class="yxt-color-picker__input yxt-color-picker__trigger" @click="handleTrigger" @mouseover="mouseover" @mouseout="mouseout">
      <span class="yxt-color-picker__color" 
        style="
          background-size: 14px 14px;
          background-repeat: no-repeat;
          border-radius: 4px;" 
        :class="{ 'is-alpha': showAlpha }">
        <span
          class="yxt-color-picker__color-inner"
          :style="{
            backgroundColor: displayedColor,
            borderRadius: '2px',
            border: 'solid 1px rgba(0,0,0, 0.06)',
            margin: '-1px'
          }"
        ></span>
      </span>
      <span class="yxt-color-picker__color-inner">
        <yxt-input placeholder="#000000" v-model="showColor" />
      </span>
      <!-- <span
        class="yxt-color-picker__icon"
        v-show="value || showPanelColor"
      ></span> -->
    </div>
    <!-- colour -->
    <div v-if="type === 'colour'" class="yxt-color-picker__svg" @click="handleTrigger" @mouseover="mouseover" @mouseout="mouseout">
    </div>
    <picker-dropdown
      ref="dropdown"
      :class="['yxt-color-picker__panel', popperClass || '']"
      style="border: none;"
      v-model="showPicker"
      :foot-btn="footBtn"
      @pick="confirmValue"
      @clear="clearValue"
      @predefine-change="predefineChange"
      :color="color"
      :show-alpha="showAlpha"
      :predefine="predefine"
    >
    </picker-dropdown>
  </div>
</template>

<script>
import Color from './color';
import PickerDropdown from './components/picker-dropdown.vue';
import Clickoutside from '@utils/utils/clickoutside';
import Emitter from '@utils/mixins/emitter';

export default {
  name: 'YxtColorPicker',

  mixins: [Emitter],

  props: {
    value: String,
    showAlpha: Boolean,
    colorFormat: String,
    disabled: Boolean,
    size: String,
    popperClass: String,
    predefine: Array,
    type: {
      type: String,
      default: 'default'
    },
    footBtn: {
      type: Boolean,
      default: false
    }
  },

  inject: {
    yxtform: {
      default: ''
    },
    yxtformItem: {
      default: ''
    }
  },

  directives: { Clickoutside },

  computed: {
    displayedColor() {
      if (!this.value && !this.showPanelColor) {
        return 'transparent';
      }

      return this.displayedRgb(this.color, this.showAlpha);
    },

    showColor: {
      get() {
        let getGba = this.displayedRgb(this.color, this.showAlpha);
        return this.rgb2Hex(getGba).toUpperCase();
      },
      set(val) {
        this.color.value = val;
        console.log(this.color.value, val, 123);
        // let getGba = this.displayedRgb(val, this.showAlpha);
        // return this.rgb2Hex(getGba);
      }
    },

    _yxtformItemSize() {
      return (this.yxtformItem || {}).yxtformItemSize;
    },

    colorSize() {
      return this.size || this._yxtformItemSize || (this.$ELEMENT || {}).size;
    },

    colorDisabled() {
      return this.disabled || (this.yxtform || {}).disabled;
    }
  },

  watch: {
    value(val) {
      if (!val) {
        this.showPanelColor = false;
      } else if (val && val !== this.color.value) {
        this.color.fromString(val);
      }
    },
    color: {
      deep: true,
      handler() {
        this.showPanelColor = true;
      }
    },
    displayedColor(val) {
      if (!this.showPicker) return;
      const currentValueColor = new Color({
        enableAlpha: this.showAlpha,
        format: this.colorFormat
      });
      currentValueColor.fromString(this.value);

      const currentValueColorRgb = this.displayedRgb(
        currentValueColor,
        this.showAlpha
      );
      if (val !== currentValueColorRgb) {
        this.$emit('active-change', val);
      }
    }
  },

  methods: {
    rgb2Hex(value) {
      if (/rgba/.test(value)) {
        const currentValueColor = new Color({
          enableAlpha: this.showAlpha,
          format: this.colorFormat
        });
        value = currentValueColor.colorRgbaToHex(value);
      } else {
        value = `#${value.match(/\d+/g).map(d => (Number(d).toString(16).toUpperCase()).padStart(2, '0')).join('')}`;
      }
      return value;
    },
    mouseover() {
      this.bg_border = 'border: solid 1px var(--color-primary-6);box-shadow: 0 0 0 2px var(--color-primary-2);';
    },
    mouseout() {
      this.bg_border = '';
    },
    predefineChange(val) {
      if (val) {
        this.$emit('predefine-change', val);
      }
    },
    handleTrigger() {
      if (this.colorDisabled) return;
      this.showPicker = !this.showPicker;
    },
    confirmValue() {
      const value = this.color.value;
      if (this.value !== value) {
        this.$emit('input', value);
        this.$emit('change', value);
        this.dispatch('YxtFormItem', 'yxt.form.change', value);
      }
      this.showPicker = false;
    },
    clearValue() {
      this.$emit('input', null);
      this.$emit('change', null);
      if (this.value !== null) {
        this.dispatch('YxtFormItem', 'yxt.form.change', null);
      }
      this.showPanelColor = false;
      this.showPicker = false;
      this.resetColor();
    },
    hide() {
      if (this.showPicker && this.showPanelColor) {
        this.confirmValue();
      } else {
        this.showPicker = false;
        this.showPanelColor = false;
      }
    },
    resetColor() {
      this.$nextTick((_) => {
        if (this.value) {
          this.color.fromString(this.value);
        } else {
          this.showPanelColor = false;
        }
      });
    },
    displayedRgb(color, showAlpha) {
      if (!(color instanceof Color)) {
        throw Error('color should be instance of Color Class');
      }

      const { r, g, b } = color.toRgb();
      return showAlpha
        ? `rgba(${r}, ${g}, ${b}, ${color.get('alpha') / 100})`
        : `rgb(${r}, ${g}, ${b})`;
    }
  },

  mounted() {
    const value = this.value;
    if (value) {
      this.color.fromString(value);
    }
    this.popperElm = this.$refs.dropdown.$el;
  },

  data() {
    const color = new Color({
      enableAlpha: this.showAlpha,
      format: this.colorFormat
    });

    return {
      color,
      showPicker: false,
      showPanelColor: false,
      bg_border: '' // hover border-color
    };
  },

  components: {
    PickerDropdown
  }
};
</script>
