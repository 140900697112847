<template>
  <transition name="yxt-zoom-in-top" @before-enter="handleMenuEnter" @after-leave="$emit('dodestroy')">
   <time-select ref="select" v-show="visible" 
     :popperClass="popperClass"
     :start="start"
     :end="end"
     :step="step"
     :value="currentValue"
     :default-value="defaultValue"
     :min-time="minTime"
     :max-time="maxTime"
     :current-min="minDate"
     :current-max="maxDate"
     @pick="handlePick"
     @placeholder="handlePlaceHolder"
     />
 </transition>
</template>

<script type="text/babel">
 import TimeSelect from '../basic/time-select';

 export default {
   components: { TimeSelect },

   data() {
     return {
       popperClass: '',
       start: '09:00',
       end: '18:00',
       step: '00:30',
       value: '',
       defaultValue: '',
       visible: false,
       minTime: '',
       maxTime: '',
       minDate: '',
       maxDate: '',
       isStart: true
     };
   },

   watch: {
     value: {
       immediate: true,
       handler: function(v) {
         if (!Array.isArray(v)) {
           this.minDate = '';
           this.maxDate = '';
         } else {
           this.minDate = v[0];
           this.maxDate = v[1];
         }
       }
     }
   },

   computed: {
     currentValue() {
       if (!Array.isArray(this.value)) return '';
       return this.isStart ? this.value[0] : this.value[1];
     }
   },
   methods: {
     handleMenuEnter() {
       this.$refs.select.handleMenuEnter();
     },
     handlePlaceHolder(...args) {
       this.$emit('placeholder', args[1]);
     },
     handlePick(value) {
       if (this.isStart) {
         this.$emit('pick', [value, this.maxDate]);
       } else {
         this.$emit('pick', [this.minDate, value]);
       }
     },
     // 获取距离最近的时间戳
     getNear(date) {
       if (this.isStart) {
         return [this.$refs.select.getNear(date[0]), this.maxDate];
       } else {
         return [this.minDate, this.$refs.select.getNear(date[1])];
       }
 
     }
   }
 };
</script>
