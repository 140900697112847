var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      class: _vm.carouselClasses,
      on: {
        mouseenter: function ($event) {
          $event.stopPropagation()
          return _vm.handleMouseEnter($event)
        },
        mouseleave: function ($event) {
          $event.stopPropagation()
          return _vm.handleMouseLeave($event)
        },
      },
    },
    [
      _c(
        "div",
        {
          staticClass: "yxt-carousel__container",
          style: { height: _vm.height },
        },
        [
          _vm.arrowDisplay
            ? _c("transition", { attrs: { name: "carousyxt-arrow-left" } }, [
                _c(
                  "button",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value:
                          (_vm.arrow === "always" || _vm.hover) &&
                          (_vm.loop || _vm.activeIndex > 0),
                        expression:
                          "(arrow === 'always' || hover) && (loop || activeIndex > 0)",
                      },
                    ],
                    staticClass:
                      "yxt-carousel__arrow yxt-carousel__arrow--left",
                    attrs: { type: "button" },
                    on: {
                      mouseenter: function ($event) {
                        _vm.handleButtonEnter("left")
                      },
                      mouseleave: _vm.handleButtonLeave,
                      click: function ($event) {
                        $event.stopPropagation()
                        _vm.throttledArrowClick(_vm.activeIndex - 1)
                      },
                    },
                  },
                  [_c("i", { staticClass: "yxt-icon-arrow-left" })]
                ),
              ])
            : _vm._e(),
          _vm.arrowDisplay
            ? _c("transition", { attrs: { name: "carousyxt-arrow-right" } }, [
                _c(
                  "button",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value:
                          (_vm.arrow === "always" || _vm.hover) &&
                          (_vm.loop || _vm.activeIndex < _vm.items.length - 1),
                        expression:
                          "(arrow === 'always' || hover) && (loop || activeIndex < items.length - 1)",
                      },
                    ],
                    staticClass:
                      "yxt-carousel__arrow yxt-carousel__arrow--right",
                    attrs: { type: "button" },
                    on: {
                      mouseenter: function ($event) {
                        _vm.handleButtonEnter("right")
                      },
                      mouseleave: _vm.handleButtonLeave,
                      click: function ($event) {
                        $event.stopPropagation()
                        _vm.throttledArrowClick(_vm.activeIndex + 1)
                      },
                    },
                  },
                  [_c("i", { staticClass: "yxt-icon-arrow-right" })]
                ),
              ])
            : _vm._e(),
          _vm._t("default"),
        ],
        2
      ),
      _vm.indicatorPosition !== "none"
        ? _c(
            "ul",
            { class: _vm.indicatorsClasses },
            _vm._l(_vm.items, function (item, index) {
              return _c(
                "li",
                {
                  key: index,
                  class: [
                    "yxt-carousel__indicator",
                    "yxt-carousel__indicator--" + _vm.direction,
                    { "is-active": index === _vm.activeIndex },
                  ],
                  on: {
                    mouseenter: function ($event) {
                      _vm.throttledIndicatorHover(index)
                    },
                    click: function ($event) {
                      $event.stopPropagation()
                      _vm.handleIndicatorClick(index)
                    },
                  },
                },
                [
                  _c("button", { staticClass: "yxt-carousel__button" }, [
                    _vm.hasLabel
                      ? _c("span", [_vm._v(_vm._s(item.label))])
                      : _vm._e(),
                  ]),
                ]
              )
            }),
            0
          )
        : _vm._e(),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }