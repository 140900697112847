var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "clickoutside",
          rawName: "v-clickoutside",
          value: _vm.close,
          expression: "close",
        },
      ],
      staticClass: "yxt-autocomplete",
      attrs: {
        "aria-haspopup": "listbox",
        role: "combobox",
        "aria-expanded": _vm.suggestionVisible,
        "aria-owns": _vm.id,
      },
    },
    [
      _c(
        "yxt-input",
        _vm._b(
          {
            ref: "input",
            on: {
              input: _vm.handleChange,
              focus: _vm.handleFocus,
              blur: _vm.handleBlur,
              clear: _vm.handleClear,
            },
            nativeOn: {
              keydown: [
                function ($event) {
                  if (
                    !("button" in $event) &&
                    _vm._k($event.keyCode, "up", 38, $event.key, [
                      "Up",
                      "ArrowUp",
                    ])
                  ) {
                    return null
                  }
                  $event.preventDefault()
                  _vm.highlight(_vm.highlightedIndex - 1)
                },
                function ($event) {
                  if (
                    !("button" in $event) &&
                    _vm._k($event.keyCode, "down", 40, $event.key, [
                      "Down",
                      "ArrowDown",
                    ])
                  ) {
                    return null
                  }
                  $event.preventDefault()
                  _vm.highlight(_vm.highlightedIndex + 1)
                },
                function ($event) {
                  if (
                    !("button" in $event) &&
                    _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                  ) {
                    return null
                  }
                  return _vm.handleKeyEnter($event)
                },
                function ($event) {
                  if (
                    !("button" in $event) &&
                    _vm._k($event.keyCode, "tab", 9, $event.key, "Tab")
                  ) {
                    return null
                  }
                  return _vm.close($event)
                },
              ],
            },
          },
          "yxt-input",
          [_vm.$props, _vm.$attrs],
          false
        ),
        [
          _vm.$slots.prepend
            ? _c("template", { slot: "prepend" }, [_vm._t("prepend")], 2)
            : _vm._e(),
          _vm.$slots.append
            ? _c("template", { slot: "append" }, [_vm._t("append")], 2)
            : _vm._e(),
          _vm.$slots.prefix
            ? _c("template", { slot: "prefix" }, [_vm._t("prefix")], 2)
            : _vm._e(),
          _vm.$slots.suffix
            ? _c("template", { slot: "suffix" }, [_vm._t("suffix")], 2)
            : _vm._e(),
        ],
        2
      ),
      _c(
        "yxt-autocomplete-suggestions",
        {
          ref: "suggestions",
          class: [_vm.popperClass ? _vm.popperClass : ""],
          attrs: {
            "popper-options": _vm.popperOptions,
            "append-to-body": _vm.popperAppendToBody,
            placement: _vm.placement,
            id: _vm.id,
          },
        },
        _vm._l(_vm.suggestions, function (item, index) {
          return _c(
            "li",
            {
              key: index,
              class: { highlighted: _vm.highlightedIndex === index },
              attrs: {
                id: _vm.id + "-item-" + index,
                role: "option",
                "aria-selected": _vm.highlightedIndex === index,
              },
              on: {
                click: function ($event) {
                  _vm.select(item)
                },
              },
            },
            [
              _vm._t(
                "default",
                [
                  _vm._v(
                    "\n        " + _vm._s(item[_vm.valueKey]) + "\n      "
                  ),
                ],
                { item: item }
              ),
            ],
            2
          )
        }),
        0
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }