var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "clickoutside",
          rawName: "v-clickoutside",
          value: function () {
            return _vm.toggleDropDownVisible(false)
          },
          expression: "() => toggleDropDownVisible(false)",
        },
      ],
      ref: "reference",
      class: [
        "yxt-cascader",
        _vm.realSize && "yxt-cascader--" + _vm.realSize,
        { "is-disabled": _vm.isDisabled },
      ],
      on: {
        mouseenter: function ($event) {
          _vm.inputHover = true
        },
        mouseleave: function ($event) {
          _vm.inputHover = false
        },
        click: function () {
          return _vm.toggleDropDownVisible(_vm.readonly ? undefined : true)
        },
        keydown: _vm.handleKeyDown,
      },
    },
    [
      _c(
        "yxt-input",
        {
          ref: "input",
          class: { "is-focus": _vm.dropDownVisible },
          attrs: {
            size: _vm.realSize,
            placeholder: _vm.placeholder,
            readonly: _vm.readonly,
            disabled: _vm.isDisabled,
            "validate-event": false,
          },
          on: {
            focus: _vm.handleFocus,
            blur: _vm.handleBlur,
            input: _vm.handleInput,
          },
          model: {
            value: _vm.multiple ? _vm.presentText : _vm.inputValue,
            callback: function ($$v) {
              _vm.multiple ? _vm.presentText : (_vm.inputValue = $$v)
            },
            expression: "multiple ? presentText : inputValue",
          },
        },
        [
          _c("template", { slot: "suffix" }, [
            _vm.clearBtnVisible
              ? _c("i", {
                  key: "clear",
                  staticClass: "yxt-input__icon yxt-icon-close",
                  on: {
                    click: function ($event) {
                      $event.stopPropagation()
                      return _vm.handleClear($event)
                    },
                  },
                })
              : _c("i", {
                  key: "arrow-down",
                  class: [
                    "yxt-input__icon",
                    "yxt-icon-arrow-down",
                    _vm.dropDownVisible && "is-reverse",
                  ],
                  on: {
                    click: function ($event) {
                      $event.stopPropagation()
                      _vm.toggleDropDownVisible()
                    },
                  },
                }),
          ]),
        ],
        2
      ),
      _vm.multiple
        ? _c(
            "div",
            { staticClass: "yxt-cascader__tags" },
            [
              _vm._l(_vm.presentTags, function (tag, index) {
                return _c(
                  "yxt-tag",
                  {
                    key: tag.key,
                    attrs: {
                      type: "info",
                      size: _vm.tagSize,
                      hit: tag.hitState,
                      closable: tag.closable,
                      "disable-transitions": "",
                    },
                    on: {
                      close: function ($event) {
                        _vm.deleteTag(index)
                      },
                    },
                  },
                  [_c("span", [_vm._v(_vm._s(tag.text))])]
                )
              }),
              _vm.filterable && !_vm.isDisabled
                ? _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model.trim",
                        value: _vm.inputValue,
                        expression: "inputValue",
                        modifiers: { trim: true },
                      },
                    ],
                    staticClass: "yxt-cascader__search-input",
                    attrs: {
                      type: "text",
                      placeholder: _vm.presentTags.length
                        ? ""
                        : _vm.placeholder,
                    },
                    domProps: { value: _vm.inputValue },
                    on: {
                      input: [
                        function ($event) {
                          if ($event.target.composing) {
                            return
                          }
                          _vm.inputValue = $event.target.value.trim()
                        },
                        function (e) {
                          return _vm.handleInput(_vm.inputValue, e)
                        },
                      ],
                      click: function ($event) {
                        $event.stopPropagation()
                        _vm.toggleDropDownVisible(true)
                      },
                      keydown: function ($event) {
                        if (
                          !("button" in $event) &&
                          _vm._k(
                            $event.keyCode,
                            "delete",
                            [8, 46],
                            $event.key,
                            ["Backspace", "Delete", "Del"]
                          )
                        ) {
                          return null
                        }
                        return _vm.handleDelete($event)
                      },
                      blur: function ($event) {
                        _vm.$forceUpdate()
                      },
                    },
                  })
                : _vm._e(),
            ],
            2
          )
        : _vm._e(),
      _c(
        "transition",
        {
          attrs: { name: "yxt-zoom-in-top" },
          on: { "after-leave": _vm.handleDropdownLeave },
        },
        [
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.dropDownVisible,
                  expression: "dropDownVisible",
                },
              ],
              ref: "popper",
              class: ["yxt-popper", "yxt-cascader__dropdown", _vm.popperClass],
            },
            [
              _c("yxt-cascader-panel", {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: !_vm.filtering,
                    expression: "!filtering",
                  },
                ],
                ref: "panel",
                attrs: {
                  options: _vm.options,
                  "panel-width": _vm.panelWidth,
                  "panel-height": _vm.panelHeight,
                  props: _vm.config,
                  border: false,
                  "render-label": _vm.$scopedSlots.default,
                },
                on: {
                  "expand-change": _vm.handleExpandChange,
                  close: function ($event) {
                    _vm.toggleDropDownVisible(false)
                  },
                },
                model: {
                  value: _vm.checkedValue,
                  callback: function ($$v) {
                    _vm.checkedValue = $$v
                  },
                  expression: "checkedValue",
                },
              }),
              _vm.filterable
                ? _c(
                    "yxt-scrollbar",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.filtering,
                          expression: "filtering",
                        },
                      ],
                      ref: "suggestionPanel",
                      staticClass: "yxt-cascader__suggestion-panel",
                      attrs: {
                        tag: "ul",
                        "view-class": "yxt-cascader__suggestion-list",
                      },
                      nativeOn: {
                        keydown: function ($event) {
                          return _vm.handleSuggestionKeyDown($event)
                        },
                      },
                    },
                    [
                      _vm.suggestions.length
                        ? _vm._l(_vm.suggestions, function (item, index) {
                            return _c(
                              "li",
                              {
                                key: item.uid,
                                class: [
                                  "yxt-cascader__suggestion-item",
                                  item.checked && "is-checked",
                                ],
                                attrs: { tabindex: -1 },
                                on: {
                                  click: function ($event) {
                                    _vm.handleSuggestionClick(index)
                                  },
                                },
                              },
                              [
                                _c("span", [_vm._v(_vm._s(item.text))]),
                                item.checked
                                  ? _c("i", { staticClass: "yxt-icon-check" })
                                  : _vm._e(),
                              ]
                            )
                          })
                        : _vm._t("empty", [
                            _c(
                              "li",
                              { staticClass: "yxt-cascader__empty-text" },
                              [
                                _vm._v(
                                  _vm._s(_vm.t("pc_comp_cascader_noMatch"))
                                ),
                              ]
                            ),
                          ]),
                    ],
                    2
                  )
                : _vm._e(),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }