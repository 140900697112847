<template>
  <transition name="yxt-zoom-in-top" @after-leave="doDestroy">
    <ul class="yxt-dropdown-menu"
      :class="[size && `yxt-dropdown-menu--${size}`] "
      v-show="showPopper"
      v-if="scrollable"
    >
      <div
        class="yxt-popper"
        :class="[$slots.custom && 'is-custom',loading && !Object.keys(this.$slots).length && 'is-loading', $slots['fixed-footer'] && 'is-suffix', $slots['fixed-header'] && 'is-prefix']"
        :style="wrapStyle"
        v-loading="loading"
        yxt-loading-size="small"
      >
        <div v-if="$slots['fixed-header']" class="yxt-dropdown-menu__prefix">
          <slot name="fixed-header"></slot>
        </div>
        <yxt-scrollbar ref='bar' :fit-height="true" class="yxt-dropdown-menu__wrap">
          <slot></slot>
          <div class="yxt-dropdown-menu__no_data" v-if="!Object.keys(this.$slots).length && !loading ">
            {{t('pc_comp_not_available')}}
          </div>
        </yxt-scrollbar>
        <div v-if="$slots.custom" class="yxt-dropdown-custom">
          <slot name="custom"></slot>
        </div>
        <div v-if="$slots['fixed-footer']" class="yxt-dropdown-menu__suffix">
          <slot name="fixed-footer"></slot>
        </div>
      </div>
    </ul>
    <ul class="yxt-dropdown-menu yxt-popper" v-else :class="[size && `yxt-dropdown-menu--${size}`]" v-show="showPopper">
      <slot></slot>
    </ul>
  </transition>
</template>
<script>
  import Popper from '@utils/utils/vue-popper';
  import Locale from '@utils/mixins/locale';
  // import {
  //   addResizeListener,
  //   removeResizeListener
  // } from '@utils/utils/resize-event';

  export default {
    name: 'YxtDropdownMenu',

    componentName: 'YxtDropdownMenu',

    mixins: [Popper, Locale],

    props: {
      visibleArrow: {
        type: Boolean,
        default: true
      },
      arrowOffset: {
        type: Number,
        default: 0
      },
      maxHeight: [String, Number],
      maxWidth: [String, Number],
      scrollable: {
        type: Boolean,
        default: true
      }
    },

    data() {
      return {
        size: this.dropdown.dropdownSize,
        loading: false
      };
    },

    computed: {
      wrapStyle() {
        return (this.maxHeight ? `max-height: ${this.maxHeight}px;` : '') + (this.maxWidth ? `max-width: ${this.maxWidth}px` : '');
      }
    },

    inject: ['dropdown'],

    created() {
      this.$on('updatePopper', () => {
        if (this.showPopper) this.updatePopper();
      });
      this.$on('visible', val => {
        this.showPopper = val;
      });
      this.$on('loading', val => {
        this.loading = val;
        if (!val) {
          this.$nextTick(this.updatePopper);
        }
      });
    },

    mounted() {
      this.dropdown.popperElm = this.popperElm = this.$el;
      this.referenceElm = this.dropdown.$el;
      if (this.updatePopper) {
        // addResizeListener(this.$el, this.updatePopper);
      }
      // compatible with 2.6 new v-slot syntax
      // issue link https://github.com/ElemeFE/element/issues/14345
      this.dropdown.initDomOperation();
    },

    beforeDestroy() {
      if (this.$el && this.updatePopper) {
        // removeResizeListener(this.$el, this.updatePopper);
      }
    },

    watch: {
      'dropdown.placement': {
        immediate: true,
        handler(val) {
          this.currentPlacement = val;
        }
      }
    }
  };
</script>
