var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      ref: "popper",
      staticClass: "yxt-time-select yxt-dropdown-menu yxt-popper",
      class: _vm.popperClass,
    },
    [
      _c(
        "yxt-scrollbar",
        {
          attrs: {
            "fit-height": true,
            "wrap-class": "yxt-picker-panel__content",
          },
        },
        _vm._l(_vm.items, function (item) {
          return _c(
            "div",
            {
              key: item.value,
              staticClass: "yxt-time-select-item",
              class: {
                selected: _vm.value === item.value,
                disabled: item.disabled,
                default: item.value === _vm.defaultValue,
              },
              attrs: { disabled: item.disabled },
              on: {
                mouseenter: function ($event) {
                  _vm.handleMouseEnter(item)
                },
                mouseleave: _vm.handleMouseLeave,
                click: function ($event) {
                  _vm.handleClick(item)
                },
              },
            },
            [_vm._v(_vm._s(item.value))]
          )
        }),
        0
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }