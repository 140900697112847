<template>
  <div v-if="iconList.length" class="yxt-side-bar-new" :style="{...containerStyle, height: (53 * iconList.length - 1) + 'px'}">
    <div class="yxt-side-bar-new__list">
      <div class="yxt-side-bar-new__list-item" v-for="(item, index) in iconList" :key="index">
        <yxt-popover placement="left" trigger="hover" :padding-small="false">
          <div class="yxt-side-bar-new__list-info" slot="reference" @click="changeDrawer(item, true)">
            <!-- <i :class="item.iconClassName"></i> -->
            <yxt-svg width="20px" height="20px" :icon-class="item.iconClassName"/>
          </div>
          <div :class="['yxt-side-bar-new__list-content', item.popoverCenter ? 'center' : '']" v-html="item.popoverSlot"></div>
        </yxt-popover>
        <div v-show="item.isDrawerShow" class="yxt-side-bar-new__drawer" :style="item.drawerStyle">
          <!-- <i :class="closeIconClassName" @click="changeDrawer(item, false)"></i> -->
          <div slot="drawer-container" v-html="item.drawerSlot"></div>
        </div>
      </div>
    </div>
    
  </div>
</template>

<script>
export default {
  name: 'YxtSideBarNew',
  data() {
    return {};
  },
  props: {
    closeIconClassName: {
      type: String,
      default: 'yxt-icon-close'
    },
    containerStyle: {
      type: Object,
      default: {}
    },
    iconList: {
      type: Array,
      default: []
    }
  },
  methods: {
    changeDrawer(item, show = false) {
      item.drawerSlot && (item.isDrawerShow = show);
      this.$emit('drawerShowChange', { ...item }, show);
    }
  }
};
</script>
