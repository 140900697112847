var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "yxt-tabs__nav-more",
      class: [_vm.havExtra && "yxt-tabs__nav-more-extra"],
      on: { mouseover: _vm.handleover, mouseout: _vm.handleout },
    },
    [
      _c("span", { staticClass: "yxt-tabs__nav-more-shadow" }),
      _c("yxt-svg", {
        attrs: { "icon-class": "three-dot", width: "16px", height: "16px" },
      }),
      _c("transition", { attrs: { name: "fade-in-linear" } }, [
        _c(
          "div",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.show,
                expression: "show",
              },
            ],
            staticClass: "yxt-tabs__nav-popover",
          },
          [
            _c("scrollbar", [
              _c(
                "ul",
                _vm._l(_vm.list, function (pane, index) {
                  return _c(
                    "li",
                    {
                      key: index,
                      on: {
                        click: function ($event) {
                          _vm.clickMoreLi(
                            pane,
                            pane.name || pane.index || index,
                            $event
                          )
                        },
                      },
                    },
                    [
                      pane.$slots.label
                        ? _c("template-slot", {
                            attrs: { data: pane.$slots.label },
                          })
                        : [
                            _vm._v(
                              "\n              " +
                                _vm._s(pane.label) +
                                "\n            "
                            ),
                          ],
                    ],
                    2
                  )
                }),
                0
              ),
            ]),
          ],
          1
        ),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }