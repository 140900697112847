<template functional>
  <div
    v-bind="data.attrs"
    v-on="listeners"
    :class="[data.staticClass, 'yxt-divider', `yxt-divider--${props.direction}`]"
  >
    <div
      v-if="slots().default && slots().default[0].text!=='' && props.direction !== 'vertical'"
      :class="['yxt-divider__text', `is-${props.contentPosition}`]"
     >
     <!-- {{JSON.stringify(slots().default[0])}} -->
      <div class="text-line-left"></div>
      <div class="text-line-center">
        <slot />
      </div>
      <div class="text-line-right"></div>
    </div>
    <div v-else :class="['yxt-divider__textnone',`yxt-divider__${props.themeMode}`]" :style="props.lineColor ? {backgroundColor:props.lineColor } :{}" ></div>
  </div>
</template>

<script>
export default {
  name: 'YxtDivider',
  props: {
    direction: {
      type: String,
      default: 'horizontal',
      validator(val) {
        return ['horizontal', 'vertical'].indexOf(val) !== -1;
      }
    },
    contentPosition: {
      type: String,
      default: 'center',
      validator(val) {
        return ['left', 'center', 'right'].indexOf(val) !== -1;
      }
    },
    lineColor: {
      type: String,
      default: '#E9E9E9'
    },
    themeMode: {
      type: String,
      default: 'light'
    }
  },
  mounted() {
    console.log('---------');
    console.log(this.slots());
  }
};

</script>
