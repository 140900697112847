var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("transition", { attrs: { name: "yxt-notification-fade" } }, [
    _c(
      "div",
      {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.visible,
            expression: "visible",
          },
        ],
        class: ["yxt-notification", _vm.customClass, _vm.horizontalClass],
        style: _vm.positionStyle,
        attrs: { role: "alert" },
        on: {
          mouseenter: function ($event) {
            _vm.clearTimer()
          },
          mouseleave: function ($event) {
            _vm.startTimer()
          },
          click: _vm.click,
        },
      },
      [
        _vm.type && _vm.iconClass
          ? _c("i", {
              staticClass: "yxt-notification__icon",
              class: [_vm.typeClass, _vm.iconClass],
            })
          : _vm.type
          ? _c("yxt-svg", {
              staticClass: "yxt-notification__icon",
              attrs: {
                width: "18px",
                height: "18px",
                "icon-class": _vm.iconName,
              },
            })
          : _vm._e(),
        _c(
          "div",
          {
            staticClass: "yxt-notification__group",
            class: { "is-with-icon": _vm.typeClass || _vm.iconClass },
          },
          [
            _c("h2", {
              staticClass: "yxt-notification__title",
              domProps: { textContent: _vm._s(_vm.title) },
            }),
            _c(
              "div",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.message,
                    expression: "message",
                  },
                ],
                staticClass: "yxt-notification__content",
              },
              [
                _vm._t("default", [
                  !_vm.dangerouslyUseHTMLString
                    ? _c("p", [_vm._v(_vm._s(_vm.message))])
                    : _c("p", { domProps: { innerHTML: _vm._s(_vm.message) } }),
                ]),
              ],
              2
            ),
            _vm.showClose
              ? _c("div", {
                  staticClass: "yxt-notification__closeBtn yxt-icon-close",
                  on: {
                    click: function ($event) {
                      $event.stopPropagation()
                      return _vm.close($event)
                    },
                  },
                })
              : _vm._e(),
          ]
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }