<template>
  <span class="yxt-guide">
    <span id="yxtGuide" @click.prevent.stop="guide">
      <slot />
    </span>
  </span>
</template>

<script>
/**
 * @description  : 新手引导组件
 * @date         : 2022-12-04 16:15:15
 * @author       : yokiizx
 */
import Driver from 'driver.js';
import 'driver.js/dist/driver.min.css';
import { hasClass } from '@utils/utils/dom';
import { deepCopy } from '@utils/utils/util';
import { t } from '@utils/locale';
const POSITION_MAP = {
  top: 'top-center',
  bottom: 'bottom-center',
  left: 'left-center',
  right: 'right-center',
  'top-start': 'top',
  'bottom-start': 'bottom',
  'left-start': 'left',
  'right-start': 'right',
  'top-end': 'top-right',
  'bottom-end': 'bottom-right',
  'left-end': 'left-bottom',
  'right-end': 'right-bottom'
};
const staticBaseUrl = (typeof window !== 'undefined' && window.feConfig && window.feConfig.common && window.feConfig.common.staticBaseUrl) || 'https://stc.yxt.com/';
const stcUrl = `${staticBaseUrl}ufd/3f5568/common/pc_backstage/svg`;

export default {
  name: 'YxtGuide',
  props: {
    id: {
      type: String,
      default: null,
      required: true
    },
    /**
     * type step = {
     *   title: string,
     *   content: string,
     *   placement: top|bottom|left|right|left-center|left-bottom
     *   ref: string
     * }
     * type steps = step[]
     */
    steps: {
      type: Array,
      default: () => [],
      required: true
    },
    needMask: {
      type: Boolean,
      default: true
    },
    allowClose: {
      type: Boolean,
      default: false
    },
    needClose: {
      type: Boolean,
      default: true
    },
    // auto start
    auto: {
      type: Boolean,
      default: true
    },
    animate: {
      type: Boolean,
      default: true
    },
    autoFinish: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      driver: null,
      _steps: null // main内操作 this._steps 替代 this.steps 否则报错
    };
  },
  created() {
    this.preload();
  },
  mounted() {
    this.init();
  },

  beforeDestroy() {
    if (this[`driver${this.id}`]) {
      this[`driver${this.id}`].reset();
      this[`driver${this.id}`] = null;
    }
  },
  methods: {
    /**
     * init, automatic pop for the first time
     */
    initGuide() {
      if (!this.hasId()) return;
      this.guide();
    },
    guide() {
      const steps = this._steps;
      if (!steps.length) {
        console.warn('Property steps is required and cannot be empty! Please check it');
        return;
      }
      const guided = JSON.parse(localStorage.getItem(`yxt-guide-${this.id}`));
      if (guided) return;
      const initTimer = setTimeout(() => {
        this[`driver${this.id}`] = new Driver({
          className: 'yxt-guide-popover',
          animate: this.animate, // 动画
          opacity: this.needMask ? 0.7 : 0,
          allowClose: this.allowClose, // 点击遮罩是否关闭
          keyboardControl: true, // 允许通过键盘进行控制（esc以关闭，箭头键移动）
          showButtons: true, // 不显示控制按钮（弹窗底部）
          doneBtnText: t('pc_comp_message_iknow'),
          nextBtnText: t('pc_next_step'),
          prevBtnText: t('pc_prev_btn'),
          closeBtnText: '',
          onHighlighted: this.onHighlighted, // 高亮时触发
          onReset: this.onReset, // 覆盖即将清除时调用
          onNext: this.onNext, // 下一步
          onPrevious: this.onPrevious // 上一步
        });
        this[`driver${this.id}`].defineSteps(steps);
        this[`driver${this.id}`].start();
        document.body.addEventListener('mousedown', this.stopDefaultMousedown);
        clearTimeout(initTimer);
      }, 100);
    },
    endGuide() {
      this[`driver${this.id}`].reset();
    },
    hasId() {
      if (this.id === null) {
        console.warn('please transfer property "id" to yxt-guide!');
        return false;
      }
      return true;
    },
    onNext() {},
    onPrevious() {},
    onHighlighted(element) {
      if (!this.hasSteps()) return;
      if (this.steps.length === 1) {
        const tmp = setTimeout(() => {
          const btn = document.getElementsByClassName('driver-next-btn')[0];
          const title = document.getElementsByClassName('driver-popover-title')[0];
          if (btn) btn.style.display = 'block';
          if (btn) title.style.setProperty('margin-top', '0', 'important');
          clearTimeout(tmp);
        });
      } else {
        if (!this.needClose) return;
        const tmp = setTimeout(() => {
          const btnIns = document.getElementById('yxtGuideCloseBtn');
          btnIns.addEventListener('click', () => {
            this.endGuide();
          });
          clearTimeout(tmp);
        }, 500);
      }
      this.setEleZIndex(element);
    },
    /**
     * guide finished and don't need it anymore unless trigger it manually
     */
    onReset() {
      if (this.autoFinish) {
        localStorage.setItem(`yxt-guide-${this.id}`, JSON.stringify(true));
      }
      document.body.removeEventListener('mousedown', this.stopDefaultMousedown);
      // 关闭引导要对 z-index 做重置
      this._steps.forEach(step=>{
        if (step.element) {
          const node = document.querySelector(step.element);
          this.restoreEleZindex({node});
        }
      });
      this.$emit('guideEnd');
    },
    stopDefaultMousedown(e) {
      e.stopPropagation();
    },

    /**
     * assemble primitive steps
     */
    assembleSteps() {
      this._steps.forEach((item, index) => {
        this.resetPosition(item.popover);
        if (this._steps.length > 1) {
          this.needClose && this.addCloseBtn(item.popover);
          this.addStepIndicator(item.popover, index + 1);
        }
        this.addImage(item.popover);
      });
    },
    resetPosition(popover) {
      popover.position = POSITION_MAP[popover.position] || POSITION_MAP['bottom'];
    },
    addCloseBtn(popover) {
      const btnCloseStr =
        `<img id="yxtGuideCloseBtn" class="yxt-guide-close" src="${stcUrl}/guide-close.svg">`;
      popover.title ? popover.title += btnCloseStr : popover.title = btnCloseStr;
    },
    addStepIndicator(popover, currentStep) {
      const stepStr = `<span class="yxt-guide-indicator">${currentStep}/${
        this.steps.length
      }</span>`;
      popover.title ? popover.title += stepStr : popover.title = stepStr ;
    },
    addImage(popover) {
      if (!popover.imgUrl) return;
      const imgStr = `<img class="yxt-guide-img" src="${popover.imgUrl}"><br/>`;
      popover.title ? popover.title = imgStr + popover.title : popover.title = imgStr;
    },
    preload() {
      let needPic = null;
      if (this.hasSteps()) {
        needPic = this.steps.map((item) => item.popover.imgUrl).filter(Boolean);
      } else {
        needPic = [];
      }
      needPic.push(`${stcUrl}/guide-close.svg`);
      this.images = [];
      for (let i = 0; i < needPic.length; i++) {
        this.images[i] = new Image();
        this.images[i].src = needPic[i];
      }
    },
    // 设置 z-index 代替 driver-highlighted-element设置 z-index 的值
    // 注： classList.add() 与 vue中 :class 不兼容，以此处理
    setEleZIndex(element) {
      const node = element.node;
      const fn = (e)=>{
        if (hasClass(node, 'driver-highlighted-element')) {
          // 保存已经存在的z-index
          if (node.style.zIndex) {
            node.setAttribute('old-zIndex', node.style.zIndex);
          }
          // 注入 driver-highlighted-element 中的z-index值
          node.style.zIndex = '100004';
        }
        node.removeEventListener('mouseenter', fn);
      };
      node.addEventListener('mouseenter', fn);
    },
    // 清空设置的z-index
    restoreEleZindex(element) {
      if (!element.node || !element.node instanceof HTMLElement) {
        console.log('this element is error!');
        return;
      }
      const node = element.node;
      node.getAttribute('old-zIndex');
      if (node.getAttribute('old-zIndex')) {
        // 如果old-zIndex存在
        node.style.zIndex = node.getAttribute('old-zIndex');
        node.removeAttribute('old-zIndex');
      } else {
        node.style.zIndex = node.style.zIndex === '100004' ? null : node.style.zIndex;
      }
    },
    cloneStep() {
      if (Array.isArray(this.steps)) {
        if (this.steps.length) {
          const steps = [];
          this.steps.forEach((step)=>{
            const popover = step.popover ? step.popover : null;
            steps.push({
              element: step.element ? step.element : '',
              popover: deepCopy(popover)
            }) ;
          });
          // driver.js 本身如果无title 不会显示弹窗，为兼容此问题，
          // 默认带有title 及时只写描述弹窗也显示
          steps.forEach((step)=>{
            if (!step.popover.title) {
              step.popover.title = ' ';
            }
          });
          this._steps = steps;
          return;
        }
        this._step = [];
      } else {
        console.log('this steps is error!');
      }
    },
    init() {
      if (this.hasSteps()) {
        this.cloneStep();
        this.assembleSteps();
        this.$nextTick(() => {
          if (!this.auto) return;
          this.initGuide();
        });
      }
    },
    hasSteps() {
      return Boolean(this.steps && this.steps.length);
    }
  },
  watch: {
    steps: {
      deep: true,
      handler() {
        if (this[`driver${this.id}`]) {
          this.endGuide();
          this[`driver${this.id}`] = null;
        }
        this.init();
      }
    }
  }
};
</script>
