var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "li",
    {
      staticClass: "yxt-dropdown-menu__item",
      class: {
        "is-disabled": _vm.disabled,
        "yxt-dropdown-menu__item--divided": _vm.divided,
      },
      attrs: {
        "aria-disabled": _vm.disabled,
        tabindex: _vm.disabled ? null : -1,
      },
      on: { click: _vm.handleClick },
    },
    [
      _vm.icon ? _c("i", { class: _vm.icon }) : _vm._e(),
      _vm._t("default"),
      _vm.labelTooltip
        ? _c(
            "yxt-tooltip",
            {
              attrs: {
                effect: _vm.tooltipEffect,
                content: _vm.labelTooltip,
                placement: "top-start",
                offset: 10,
                "max-width": _vm.labelTooltipWidth,
              },
            },
            [
              _c("yxt-svg", {
                staticClass: "color-gray-6 hover-primary-6 ml4",
                attrs: {
                  "remote-url": "",
                  width: "16px",
                  height: "16px",
                  "icon-class": "question-cirlce-o",
                },
              }),
            ],
            1
          )
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }