var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "yxt-page-header",
      class: [
        true
          ? { "yxt-page-header--pure yxt-page-header--pure_line": _vm.pure }
          : { "yxt-page-header--pure": _vm.pure },
        _vm.size ? "yxt-page-header--" + _vm.size : "",
      ],
    },
    [
      _c(
        "div",
        {
          staticClass: "yxt-page-header__top",
          class: [_vm.describe && "yxt-page-header__top_desc"],
        },
        [
          _c(
            "div",
            {
              staticClass: "yxt-page-header__left",
              on: {
                click: function ($event) {
                  _vm.iconHot || _vm.$emit("back")
                },
              },
            },
            [
              _c(
                "div",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.showIcon,
                      expression: "showIcon",
                    },
                  ],
                  staticClass: "yxt-page-header__icon",
                  on: {
                    click: function ($event) {
                      !_vm.iconHot || _vm.$emit("back")
                    },
                  },
                },
                [
                  _c("yxt-svg", {
                    staticClass: "color-gray-9 yxt-page-header__back",
                    attrs: {
                      width: "24px",
                      height: "24px",
                      "icon-class": "arrow-left",
                    },
                  }),
                ],
                1
              ),
              !_vm.describe
                ? _c(
                    "div",
                    {
                      staticClass:
                        "yxt-page-header__title yxt-weight-6 color-gray-10",
                      style: _vm.iconHot ? "cursor: default" : "",
                    },
                    [_vm._t("title", [_vm._v(_vm._s(_vm.title))])],
                    2
                  )
                : _c("div", [
                    _c(
                      "div",
                      {
                        staticClass:
                          "yxt-page-header__title yxt-weight-6 color-gray-10",
                        style: _vm.iconHot ? "cursor: default" : "",
                      },
                      [_vm._t("title", [_vm._v(_vm._s(_vm.title))])],
                      2
                    ),
                    _c("span", { staticClass: "yxt-page-header--describe" }, [
                      _vm._v(_vm._s(_vm.describe)),
                    ]),
                  ]),
            ]
          ),
          _c(
            "div",
            { staticClass: "yxt-page-header__subtitle" },
            [_vm._t("subtitle", [_vm._v(_vm._s(_vm.subtitle))])],
            2
          ),
          _c(
            "div",
            { staticClass: "yxt-page-right" },
            [_vm._t("title-right")],
            2
          ),
        ]
      ),
      !_vm.pure && _vm.$slots.content
        ? _c(
            "div",
            { staticClass: "yxt-page-header__content" },
            [_vm._t("content")],
            2
          )
        : _vm._e(),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }