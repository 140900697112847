var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("li", { staticClass: "yxt-dropdown-group" }, [
    _vm.title
      ? _c("div", { staticClass: "yxt-dropdown-group-title" }, [
          _vm._v(_vm._s(_vm.title)),
        ])
      : _vm._e(),
    _c("ul", [_vm._t("default")], 2),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }