import Picker from '../timePicker';
import TimePanel from '../panel/time';
import TimeRangePanel from '../panel/time-range';

export default {
  mixins: [Picker],

  name: 'YxtTimePicker',

  props: {
    isRange: {
      type: Boolean,
      desc: '是否为时间范围选择，仅对<yxt-time-picker>有效'
    },
    arrowControl: {
      type: Boolean,
      desc: '是否使用箭头进行时间选择，仅对<yxt-time-picker>有效'
    },
    rangeSeparator: {
      type: String,
      default: '~',
      desc: '选择范围时的分隔符'
    }
  },

  data() {
    return {
      type: ''
    };
  },

  watch: {
    isRange(isRange) {
      if (this.picker) {
        this.unmountPicker();
        this.type = isRange ? 'timerange' : 'time';
        this.panel = isRange ? TimeRangePanel : TimePanel;
        this.mountPicker();
      } else {
        this.type = isRange ? 'timerange' : 'time';
        this.panel = isRange ? TimeRangePanel : TimePanel;
      }
    }
  },

  created() {
    this.type = this.isRange ? 'timerange' : 'time';
    this.panel = this.isRange ? TimeRangePanel : TimePanel;
  }
};
