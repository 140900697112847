<template>
  <label
    class="yxt-radio-card"
    :class="[
      { 'is-active': value === label },
      { 'is-disabled': isDisabled },
      { 'is-adisabled': isDisabled &&  value === label }
    ]"
    role="radio"
    :aria-checked="value === label"
    :aria-disabled="isDisabled"
    :tabindex="tabIndex"
    @keydown.space.stop.prevent="value = isDisabled ? value : label"
  >
    <input
      class="yxt-radio-card__orig-radio"
      :value="label"
      type="radio"
      v-model="value"
      :name="name"
      @change="handleChange"
      :disabled="isDisabled"
      tabindex="-1"
      @focus="focus = true"
      @blur="focus = false"
    >
    <div class="yxt-radio-card__content">
      <div class="yxt-radio-card__avatar"  v-if="$slots.avatar || avatar">
        <slot name="avatar">{{ avatar }}</slot>
      </div>
      <div class="yxt-radio-card__detail">
        <div class="yxt-radio-card__header" v-if="title || $slots.extra || extra">
          <yxt-tooltip v-if="showTitleTooltip" :open-filter="true" :content="title" placement="top">
            <span  class="yxt-radio-card__title">{{title}}</span>
          </yxt-tooltip>
          <span v-else  class="yxt-radio-card__title">{{title}}</span>
          <template v-if="$slots.extra">
            <slot name="extra">
              <!-- ui强制要透明度 -->
              <span class="yxt-radio-card__extraIcon">{{extra}}</span>
            </slot>
          </template>
          <template v-else-if="extra">
            <span class="yxt-radio-card__extra">{{extra}}</span>
          </template>
        </div>
        <span  v-if="description" class="yxt-radio-card__description">{{description}}</span>
      </div>
    </div>

    <span class="yxt-radio-card__iconCorner" :class="[
        { 'is-active': value === label },
        { 'is-disabled': isDisabled },
        { 'is-adisabled': isDisabled &&  value === label }
      ]">
      <svg aria-hidden="true"  class="yxt-svg-icon ml4 hand" xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" style="display: inline-block; width: 16px; height: 16px;"><g fill="currentColor" fill-rule="evenodd"><path d="M16 0v13a3 3 0 0 1-3 3H0L16 0z" fill="currentColor" fill-rule="evenodd"/><path fill="#FFF" fill-rule="nonzero" d="M9.695 12.868l3.587-4.017.613.557-4.138 4.634-2.76-2.506.552-.618z"/></g></svg>
    </span>
  </label>
</template>
<script>
  import Emitter from '@utils/mixins/emitter';

  export default {
    name: 'YxtRadioCard',

    mixins: [Emitter],

    inject: {
      yxtform: {
        default: ''
      },
      yxtformItem: {
        default: ''
      }
    },

    props: {
      avatar: {
        desc: '选项元素的图片地址'
      },
      extra: {
        desc: 'icon图标或者文字'
      } || { type: String, desc: 'icon图标或者文字'},
      label: {
        desc: 'Radio 的 value'
      },
      disabled: {
        type: Boolean,
        default: false,
        desc: '是否禁用'
      },
      name: {
        type: String,
        desc: '原生name属性'
      },
      title: {
        type: String,
        desc: '标题'
      },
      showTitleTooltip: {
        type: Boolean,
        desc: '是否开启超出显示气泡功能'
      },
      description: {
        type: String,
        desc: '描述'
      }
    },
    data() {
      return {
        focus: false
      };
    },
    computed: {
      value: {
        get() {
          return this._radioGroup.value;
        },
        set(value) {
          this._radioGroup.$emit('input', value);
        }
      },
      _radioGroup() {
        let parent = this.$parent;
        while (parent) {
          if (parent.$options.componentName !== 'YxtRadioGroup') {
            parent = parent.$parent;
          } else {
            return parent;
          }
        }
        return false;
      },
      isDisabled() {
        return this.disabled || this._radioGroup.disabled || (this.yxtform || {}).disabled;
      },
      tabIndex() {
        return (this.isDisabled || (this._radioGroup && this.value !== this.label)) ? -1 : 0;
      }
    },
    methods: {
      handleChange() {
        this.$nextTick(() => {
          this.dispatch('YxtRadioGroup', 'handleChange', this.value);
        });
      }
    }
  };
</script>
