var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "yxt-popover",
    _vm._b(
      {
        attrs: { "popper-class": "yxt-popconfirm-box", trigger: "click" },
        model: {
          value: _vm.visible,
          callback: function ($$v) {
            _vm.visible = $$v
          },
          expression: "visible",
        },
      },
      "yxt-popover",
      _vm.$attrs,
      false
    ),
    [
      _c("div", { staticClass: "yxt-popconfirm" }, [
        _c(
          "div",
          { staticClass: "yxt-popconfirm__main" },
          [
            !_vm.hideIcon
              ? _c("yxt-svg", {
                  staticClass: "yxt-popconfirm__icon",
                  attrs: {
                    width: _vm.iconSize,
                    height: _vm.iconSize,
                    "icon-class": _vm.icon,
                  },
                })
              : _vm._e(),
            _vm.title
              ? _c("div", { class: { "yxt-popconfirm--title": _vm.content } }, [
                  _vm._v(_vm._s(_vm.title)),
                ])
              : _vm._e(),
          ],
          1
        ),
        _vm.content
          ? _c("div", { staticClass: "yxt-popconfirm__content" }, [
              _vm._v(_vm._s(_vm.content)),
            ])
          : _vm._e(),
        _c(
          "div",
          { staticClass: "yxt-popconfirm__action" },
          [
            _vm.showTip
              ? _c("yxt-checkbox", { on: { change: _vm.change } }, [
                  _vm._v("不再提示"),
                ])
              : _vm._e(),
            _c(
              "yxt-button",
              {
                attrs: { size: "small", type: _vm.cancelButtonType },
                on: { click: _vm.cancel },
              },
              [
                _vm._v(
                  "\n        " +
                    _vm._s(_vm.displayCancelButtonText) +
                    "\n      "
                ),
              ]
            ),
            _c(
              "yxt-button",
              {
                attrs: { size: "small", type: _vm.confirmButtonType },
                on: { click: _vm.confirm },
              },
              [
                _vm._v(
                  "\n        " +
                    _vm._s(_vm.displayConfirmButtonText) +
                    "\n      "
                ),
              ]
            ),
          ],
          1
        ),
      ]),
      _vm._t("reference", null, { slot: "reference" }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }