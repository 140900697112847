var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "yxt-badge" },
    [
      _vm._t("default"),
      _c("transition", { attrs: { name: "yxt-zoom-in-center" } }, [
        _c(
          "sup",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value:
                  !_vm.hidden &&
                  (_vm.content || _vm.content === 0 || _vm.isDot || _vm.isText),
                expression:
                  "!hidden && (content || content === 0 || isDot || isText)",
              },
            ],
            staticClass: "yxt-badge__content",
            class: [
              "yxt-badge__content--" + _vm.type,
              {
                "is-fixed": _vm.$slots.default,
                "is-dot": _vm.isDot,
                "is-text": _vm.isText,
              },
            ],
            style: _vm.positionStyle,
          },
          [
            _c("span", { staticClass: "scale-font" }, [
              _vm._v(_vm._s(_vm.content)),
            ]),
            _vm.isShowPlus
              ? _c("yxt-svg", {
                  staticClass: "plus-svg",
                  attrs: {
                    width: "5px",
                    "remote-url": _vm.url,
                    "icon-class": "badge-plus",
                  },
                })
              : _vm._e(),
          ],
          1
        ),
      ]),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }