<template>
  <div
    class="yxt-progress"
    :class="[
      'yxt-progress--' + type,
      status ? 'is-' + status : '',
      themeMode ? 'is-theme-' + themeMode : '',
      {
        'yxt-progress--without-text': !showText,
        'yxt-progress--text-inside': textInside,
      }
    ]"
    role="progressbar"
    :aria-valuenow="percentage"
    aria-valuemin="0"
    aria-valuemax="100"
  >
    <div class="yxt-progress-bar" v-if="type === 'line'">
      <div class="yxt-progress-bar__outer" :style="progressLineStyle">
        <div class="yxt-progress-bar__inner" :style="barStyle">
          <div class="yxt-progress-bar__innerText" v-if="showText && textInside">{{content}}</div>
        </div>
      </div>
    </div>
    <div class="yxt-progress-circle" :style="progressCircleStyle" v-else>
      <svg viewBox="0 0 100 100">
        <path
          class="yxt-progress-circle__track"
          :d="trackPath"
          :stroke="outColor || '#f0f0f0'"
          :stroke-width="relativeStrokeWidth"
          fill="none"
          :style="trailPathStyle"></path>
        <path
          class="yxt-progress-circle__path"
          :d="trackPath"
          fill="none"
          stroke-linecap="round"
          :stroke-width="percentage ? relativeStrokeWidth : 0"
          :style="circlePathStyle"></path>
      </svg>
    </div>
    <div
      class="yxt-progress__text"
      v-if="showText && !textInside"
      :style="{fontSize: progressTextSize + 'px'}"
    >
      <template v-if="!status || status === 'success'">{{content}}<span v-if="(typeof format !== 'function')" style="font-size:16px;">%</span></template>
      <div v-else>
        <span v-if="type !== 'line' && status === 'exception'">!</span>
        <i v-else :class="iconClass"></i>
      </div>
      <div class="yxt-progress__custom">
        <slot></slot>
      </div>
    </div>
  </div>
</template>
<script>
  const progressWidthMapping = {
    large: 8,
    middle: 6,
    large_160: 8,
    huge: 8,
    middle_120: 6,
    small: 4
  };
  const circleSizeMapping = {
    huge: 200,
    large_160: 160,
    large: 162,
    middle: 124,
    middle_120: 120,
    small: 80
  };
  export default {
    name: 'YxtProgress',
    props: {
      type: {
        type: String,
        default: 'line',
        validator: val => ['line', 'circle', 'dashboard'].indexOf(val) > -1
      },
      percentage: {
        type: Number,
        default: 0,
        required: true,
        validator: val => val >= 0 && val <= 100
      },
      status: {
        type: String,
        validator: val => ['success', 'exception', 'warning'].indexOf(val) > -1
      },
      strokeWidth: {
        type: Number
      },
      textInside: {
        type: Boolean,
        default: false
      },
      width: {
        type: Number
      },
      size: {
        type: String,
        default: 'middle',
        validator: val => ['large', 'middle', 'small', 'middle_120', 'large_160', 'huge'].indexOf(val) > -1
      },
      showText: {
        type: Boolean,
        default: true
      },
      color: {
        type: [String, Array, Function],
        default: ''
      },
      outColor: {
        type: String,
        default: '' // 外圈颜色
      },
      themeMode: String,
      format: Function
    },
    computed: {
      progressLineStyle() {
        const style = {height: (this.strokeWidth || progressWidthMapping[this.size]) + 'px'};
        if (this.outColor) {
          style.backgroundColor = this.outColor;
        }
        return style;
      },
      progressCircleStyle() {
        const width = this.width || circleSizeMapping[this.size];
        return {height: width + 'px', width: width + 'px'};
      },
      barStyle() {
        const style = {};
        style.width = this.percentage + '%';
        style.backgroundColor = this.getCurrentColor(this.percentage);
        return style;
      },
      relativeStrokeWidth() {
        const progressWidth = this.strokeWidth || progressWidthMapping[this.size];
        const width = this.width || circleSizeMapping[this.size];
        return (progressWidth / width * 100).toFixed(1);
      },
      radius() {
        if (this.type === 'circle' || this.type === 'dashboard') {
          return parseInt(50 - parseFloat(this.relativeStrokeWidth) / 2, 10);
        } else {
          return 0;
        }
      },
      trackPath() {
        const radius = this.radius;
        const isDashboard = this.type === 'dashboard';
        return `
          M 50 50
          m 0 ${isDashboard ? '' : '-'}${radius}
          a ${radius} ${radius} 0 1 1 0 ${isDashboard ? '-' : ''}${radius * 2}
          a ${radius} ${radius} 0 1 1 0 ${isDashboard ? '' : '-'}${radius * 2}
          `;
      },
      perimeter() {
        return 2 * Math.PI * this.radius;
      },
      rate() {
        return this.type === 'dashboard' ? 0.75 : 1;
      },
      strokeDashoffset() {
        const offset = -1 * this.perimeter * (1 - this.rate) / 2;
        return `${offset}px`;
      },
      trailPathStyle() {
        return {
          strokeDasharray: `${(this.perimeter * this.rate)}px, ${this.perimeter}px`,
          strokeDashoffset: this.strokeDashoffset
        };
      },
      circlePathStyle() {
        const style = {
          strokeDasharray: `${this.perimeter * this.rate * (this.percentage / 100) }px, ${this.perimeter}px`,
          strokeDashoffset: this.strokeDashoffset,
          transition: 'stroke-dasharray 0.6s ease 0s, stroke 0.6s ease'
        };
        if (this.stroke) style.stroke = this.stroke;
        return style;
      },
      stroke() {
        let ret;
        if (this.color) {
          ret = this.getCurrentColor(this.percentage);
        }
        return ret;
      },
      iconClass() {
        if (this.status === 'warning') {
          return 'yxt-icon-warning';
        }
        if (this.type === 'line') {
          return this.status === 'success' ? '' : 'yxt-icon-warning';
        } else {
          return this.status === 'success' ? 'yxt-icon-check' : 'yxt-icon-warning';
        }
      },
      progressTextSize() {
        return this.type === 'line' ? 14 : this.size === 'small' ? 18 : 24;
        // return this.type === 'line'
        //   ? 12 + this.strokeWidth * 0.4
        //   : this.width * 0.111111 + 2 ;
      },
      content() {
        if (typeof this.format === 'function') {
          // bug: 修复当format 文本较多出现的文本换行问题
          this.updateBarWidth();
          return this.format(this.percentage) || '';
        } else {
          return `${this.percentage}`;
        }
      }
    },
    methods: {
      getCurrentColor(percentage) {
        if (typeof this.color === 'function') {
          return this.color(percentage);
        } else if (typeof this.color === 'string') {
          return this.color;
        } else {
          return this.getLevelColor(percentage);
        }
      },
      getLevelColor(percentage) {
        const colorArray = this.getColorArray().sort((a, b) => a.percentage - b.percentage);

        for (let i = 0; i < colorArray.length; i++) {
          if (colorArray[i].percentage > percentage) {
            return colorArray[i].color;
          }
        }
        return colorArray[colorArray.length - 1].color;
      },
      getColorArray() {
        const color = this.color;
        const span = 100 / color.length;
        return color.map((seriesColor, index) => {
          if (typeof seriesColor === 'string') {
            return {
              color: seriesColor,
              progress: (index + 1) * span
            };
          }
          return seriesColor;
        });
      },
      updateBarWidth() {
        if (this.type === 'line' && (typeof this.format === 'function')) {
          this.$nextTick(_ => {
            const dom_progress = this.$el && this.$el.querySelector('.yxt-progress-bar');
            const dom_text_width = this.$el && this.$el.querySelector('.yxt-progress__text') && this.$el.querySelector('.yxt-progress__text').offsetWidth + 9;
            if (dom_text_width > 53) {
              dom_progress.style.marginRight = '-' + dom_text_width + 'px';
              dom_progress.style.paddingRight = dom_text_width + 'px';
            }
          });
        }
      }
    }
  };
</script>
