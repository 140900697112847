<template>
   <transition name="yxt-zoom-in-top" @before-enter="handleMenuEnter" @after-leave="$emit('dodestroy')">
    <time-select ref="select" v-show="visible" 
      :popperClass="popperClass"
      :start="start"
      :end="end"
      :step="step"
      :value="value"
      :default-value="defaultValue"
      :min-time="minTime"
      :max-time="maxTime"
      @pick="handlePick"
      @placeholder="handlePlaceHolder"
      />
  </transition>
</template>

<script type="text/babel">
  import TimeSelect from '../basic/time-select';

  export default {
    components: { TimeSelect },

    data() {
      return {
        popperClass: '',
        start: '09:00',
        end: '18:00',
        step: '00:30',
        value: '',
        defaultValue: '',
        visible: false,
        minTime: '',
        maxTime: '',
        isRange: false,
        isStart: true
      };
    },
    methods: {
      handleMenuEnter() {
        this.$refs.select.handleMenuEnter();
      },
      handlePlaceHolder(...args) {
        this.$emit('placeholder', args[1]);
      },
      handlePick(...args) {
        this.$emit('pick', ...args);
      },
      // 获取距离最近的时间戳
      getNear(date) {
        return this.$refs.select.getNear(date);
      }
    }
  };
</script>
