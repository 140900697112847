var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "label",
    {
      staticClass: "yxtf-radio",
      class: [
        _vm.border && _vm.radioSize ? "yxtf-radio--" + _vm.radioSize : "",
        { "is-disabled": _vm.isDisabled },
        { "is-disabled-lock": _vm.isDisabled && _vm.isDisabled === "lock" },
        { "is-focus": _vm.focus },
        { "is-bordered": _vm.border },
        { "is-checked": _vm.model === _vm.label && _vm.changeTextColor },
        { "is-checked-alone": _vm.model === _vm.label && !_vm.changeTextColor },
      ],
      attrs: {
        role: "radio",
        "aria-checked": _vm.model === _vm.label,
        "aria-disabled": _vm.isDisabled,
        tabindex: _vm.tabIndex,
      },
      on: {
        keydown: function ($event) {
          if (
            !("button" in $event) &&
            _vm._k($event.keyCode, "space", 32, $event.key, [" ", "Spacebar"])
          ) {
            return null
          }
          $event.stopPropagation()
          $event.preventDefault()
          _vm.model = _vm.isDisabled ? _vm.model : _vm.label
        },
      },
    },
    [
      _c(
        "span",
        {
          staticClass: "yxtf-radio__input",
          class: {
            "is-disabled": _vm.isDisabled,
            "is-disabled-lock": _vm.isDisabled && _vm.isDisabled === "lock",
            "is-checked": _vm.model === _vm.label && _vm.changeTextColor,
            "is-checked-alone": _vm.model === _vm.label && !_vm.changeTextColor,
          },
        },
        [
          _c("span", { staticClass: "yxtf-radio__inner" }),
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.model,
                expression: "model",
              },
            ],
            ref: "radio",
            staticClass: "yxtf-radio__original",
            attrs: {
              type: "radio",
              "aria-hidden": "true",
              name: _vm.name,
              disabled: _vm.isDisabled,
              tabindex: "-1",
            },
            domProps: {
              value: _vm.label,
              checked: _vm._q(_vm.model, _vm.label),
            },
            on: {
              focus: function ($event) {
                _vm.focus = true
              },
              blur: function ($event) {
                _vm.focus = false
              },
              change: [
                function ($event) {
                  _vm.model = _vm.label
                },
                _vm.handleChange,
              ],
            },
          }),
        ]
      ),
      _c(
        "span",
        {
          staticClass: "yxtf-radio__label",
          on: {
            keydown: function ($event) {
              $event.stopPropagation()
            },
          },
        },
        [
          _vm._t("default"),
          !_vm.$slots.default ? [_vm._v(_vm._s(_vm.label))] : _vm._e(),
        ],
        2
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }