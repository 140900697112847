
export default {
  pc_comp_colorpicker_confirm: '确定',
  pc_comp_colorpicker_clear: '清空',
  pc_comp_datepicker_now: '此刻',
  pc_comp_datepicker_today: '今天',
  pc_comp_datepicker_cancel: '取消',
  pc_comp_datepicker_clear: '清空',
  pc_comp_datepicker_confirm: '确定',
  pc_comp_datepicker_selectDate: '选择日期',
  pc_comp_datepicker_selectTime: '选择时间',
  pc_comp_datepicker_startDate: '开始日期',
  pc_comp_datepicker_startTime: '开始时间',
  pc_comp_datepicker_endDate: '结束日期',
  pc_comp_datepicker_endTime: '结束时间',
  pc_comp_datepicker_prevYear: '前一年',
  pc_comp_datepicker_nextYear: '后一年',
  pc_comp_datepicker_prevMonth: '上个月',
  pc_comp_datepicker_nextMonth: '下个月',
  pc_comp_datepicker_year: '年',
  pc_comp_datepicker_month1: '1 月',
  pc_comp_datepicker_month2: '2 月',
  pc_comp_datepicker_month3: '3 月',
  pc_comp_datepicker_month4: '4 月',
  pc_comp_datepicker_month5: '5 月',
  pc_comp_datepicker_month6: '6 月',
  pc_comp_datepicker_month7: '7 月',
  pc_comp_datepicker_month8: '8 月',
  pc_comp_datepicker_month9: '9 月',
  pc_comp_datepicker_month10: '10 月',
  pc_comp_datepicker_month11: '11 月',
  pc_comp_datepicker_month12: '12 月',
  pc_comp_datepicker_weeksSun: '日',
  pc_comp_datepicker_weeksMon: '一',
  pc_comp_datepicker_weeksTue: '二',
  pc_comp_datepicker_weeksWed: '三',
  pc_comp_datepicker_weeksThu: '四',
  pc_comp_datepicker_weeksFri: '五',
  pc_comp_datepicker_weeksSat: '六',
  pc_comp_datepicker_monthJan: '一月',
  pc_comp_datepicker_monthFeb: '二月',
  pc_comp_datepicker_monthMar: '三月',
  pc_comp_datepicker_monthApr: '四月',
  pc_comp_datepicker_monthMay: '五月',
  pc_comp_datepicker_monthJun: '六月',
  pc_comp_datepicker_monthJul: '七月',
  pc_comp_datepicker_monthAug: '八月',
  pc_comp_datepicker_monthSep: '九月',
  pc_comp_datepicker_monthOct: '十月',
  pc_comp_datepicker_monthNov: '十一月',
  pc_comp_datepicker_monthDec: '十二月',
  pc_comp_datepicker_quarterQ1: 'Q1',
  pc_comp_datepicker_quarterQ2: 'Q2',
  pc_comp_datepicker_quarterQ3: 'Q3',
  pc_comp_datepicker_quarterQ4: 'Q4',
  pc_comp_select_loading: '加载中',
  pc_comp_select_noMatch: '无匹配数据',
  pc_comp_select_noData: '无数据',
  pc_comp_select_placeholder: '请选择',
  pc_comp_cascader_noMatch: '无匹配数据',
  pc_comp_cascader_loading: '加载中',
  pc_comp_cascader_placeholder: '请选择',
  pc_comp_cascader_noData: '暂无数据',
  pc_comp_pagination_goto: '前往',
  pc_comp_pagination_pagesize: '{size} 条/页',
  pc_comp_pagination_total: '共 {total} 条',
  pc_comp_pagination_pageClassifier: '页',
  pc_comp_pagination_currentPage: '第 {current} 页',
  pc_comp_messagebox_title: '提示',
  pc_comp_messagebox_confirm: '确定',
  pc_comp_messagebox_cancel: '取消',
  pc_comp_messagebox_error: '输入的数据不合法!',
  pc_comp_upload_deleteTip: '按 delete 键可删除',
  pc_comp_upload_delete: '刪除',
  pc_comp_upload_preview: '查看图片',
  pc_comp_upload_continue: '继续上传',
  pc_comp_table_emptyText: '暂无数据',
  pc_comp_table_confirmFilter: '筛选',
  pc_comp_table_resetFilter: '重置',
  pc_comp_table_clearFilter: '全部',
  pc_comp_table_sumText: '合计',
  pc_comp_table_customColumnText: '自定义列',
  pc_comp_table_confirm: '确认',
  pc_comp_table_cancel: '取消',
  pc_comp_tree_emptyText: '暂无数据',
  pc_comp_transfer_noMatch: '无匹配数据',
  pc_comp_transfer_noData: '无数据',
  pc_comp_transfer_titles: ['列表 1', '列表 2'],
  pc_comp_transfer_filterPlaceholder: '请输入搜索内容',
  pc_comp_transfer_noCheckedFormat: '共 {total} 项',
  pc_comp_transfer_hasCheckedFormat: '已选 {checked}/{total} 项',
  pc_comp_image_error: '加载失败',
  pc_comp_pageHeader_title: '返回',
  pc_comp_popconfirm_confirmButtonText: '确定',
  pc_comp_popconfirm_cancelButtonText: '取消',

  pc_comp_datepicker_week: '周次',
  pc_comp_infinite_content: '已经到底了', // 已經到底了  Already reached the bottom
  pc_comp_drawer_confirmCancel: '当前操作尚未保存，是否确认取消？', // 當前操作尚未保存，是否確認取消？The current operation has not been saved. Are you sure to cancel?
  pc_comp_drawer_cancel_describe: '当前操作尚未保存',
  pc_comp_drawer_msgCancel: '确认取消？', // 確認取消？Confirm cancel?
  pc_comp_drawer_waive: '确定放弃此次操作吗？',
  pc_comp_drawer_confirm: '确定',
  pc_comp_drawer_cancel: '取消',
  pc_comp_table_manager: '暂无权限，请联系管理员', // 暫無權限，請聯繫管理員 No permission yet
  pc_comp_no_auth: '暂无权限，请联系管理员',
  pc_comp_in_browser: '已经为您在浏览器中打开本系统，请去浏览器中使用。',
  pc_comp_sideBar_open: '展开',
  pc_comp_sideBar_close: '收起',
  pc_comp_message_iknow: '我知道了',
  pc_comp_table_columnDragTip: '长按左右调整列宽',
  pc_comp_table_columnCustomTip: '请选择需要展示的列',
  pc_comp_table_columnCustomDragTip: '长按拖拽排序',
  pc_comp_message_repeal: '撤回',
  pc_comp_not_available: '暂无选项',
  pc_prev_btn: '上一步',
  pc_next_step: '下一步'
};

// export default {
//   el: {
//     colorpicker: {
//       confirm: '确定',
//       clear: '清空'
//     },
//     datepicker: {
//       now: '此刻',
//       today: '今天',
//       cancel: '取消',
//       clear: '清空',
//       confirm: '确定',
//       selectDate: '选择日期',
//       selectTime: '选择时间',
//       startDate: '开始日期',
//       startTime: '开始时间',
//       endDate: '结束日期',
//       endTime: '结束时间',
//       prevYear: '前一年',
//       nextYear: '后一年',
//       prevMonth: '上个月',
//       nextMonth: '下个月',
//       year: '年',
//       month1: '1 月',
//       month2: '2 月',
//       month3: '3 月',
//       month4: '4 月',
//       month5: '5 月',
//       month6: '6 月',
//       month7: '7 月',
//       month8: '8 月',
//       month9: '9 月',
//       month10: '10 月',
//       month11: '11 月',
//       month12: '12 月',
//       // week: '周次',
//       weeks: {
//         sun: '日',
//         mon: '一',
//         tue: '二',
//         wed: '三',
//         thu: '四',
//         fri: '五',
//         sat: '六'
//       },
//       months: {
//         jan: '一月',
//         feb: '二月',
//         mar: '三月',
//         apr: '四月',
//         may: '五月',
//         jun: '六月',
//         jul: '七月',
//         aug: '八月',
//         sep: '九月',
//         oct: '十月',
//         nov: '十一月',
//         dec: '十二月'
//       }
//     },
//     select: {
//       loading: '加载中',
//       noMatch: '无匹配数据',
//       noData: '无数据',
//       placeholder: '请选择'
//     },
//     cascader: {
//       noMatch: '无匹配数据',
//       loading: '加载中',
//       placeholder: '请选择',
//       noData: '暂无数据'
//     },
//     pagination: {
//       goto: '前往',
//       pagesize: '每页 {size} 条',
//       total: '共 {total} 条',
//       pageClassifier: '页',
//       currentPage: '第 {current} 页'
//     },
//     messagebox: {
//       title: '提示',
//       confirm: '确定',
//       cancel: '取消',
//       error: '输入的数据不合法!'
//     },
//     upload: {
//       deleteTip: '按 delete 键可删除',
//       delete: '删除',
//       preview: '查看图片',
//       continue: '继续上传'
//     },
//     table: {
//       emptyText: '暂无数据',
//       confirmFilter: '筛选',
//       resetFilter: '重置',
//       clearFilter: '全部',
//       sumText: '合计',
//       customColumnText: '自定义列',
//       confirm: '确定',
//       cancel: '取消'
//     },
//     tree: {
//       emptyText: '暂无数据'
//     },
//     transfer: {
//       noMatch: '无匹配数据',
//       noData: '无数据',
//       titles: ['列表 1', '列表 2'],
//       filterPlaceholder: '请输入搜索内容',
//       noCheckedFormat: '共 {total} 项',
//       hasCheckedFormat: '已选 {checked}/{total} 项'
//     },
//     image: {
//       error: '加载失败'
//     },
//     pageHeader: {
//       title: '返回'
//     },
//     popconfirm: {
//       confirmButtonText: '确定',
//       cancelButtonText: '取消'
//     }
//   }
// };
