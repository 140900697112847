<template>
  <div class="yxt-button-group">
    <slot></slot>
  </div>
</template>
<script>
  export default {
    name: 'YxtfButtonGroup'
  };
</script>
