var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "transition",
    {
      attrs: { name: "yxt-drawer-fade" },
      on: { "after-enter": _vm.afterEnter, "after-leave": _vm.afterLeave },
    },
    [
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.visible,
              expression: "visible",
            },
          ],
          staticClass: "yxt-dialog__wrapper",
          attrs: { role: "presentation" },
        },
        [
          _c(
            "div",
            {
              staticClass: "yxt-drawer__container",
              class: _vm.visible && "yxt-drawer__open",
              attrs: { role: "document", tabindex: "-1" },
              on: {
                mousedown: function ($event) {
                  if ($event.target !== $event.currentTarget) {
                    return null
                  }
                  return _vm.handleWrapperClick($event)
                },
              },
            },
            [
              _c(
                "div",
                {
                  ref: "drawer",
                  staticClass: "yxt-drawer",
                  class: [_vm.direction, _vm.customClass],
                  style: _vm.isHorizontal
                    ? "width: " + _vm.size
                    : "height: " + _vm.size,
                  attrs: {
                    "aria-modal": "true",
                    "aria-labelledby": "yxt-drawer__title",
                    role: "presentation",
                  },
                },
                [
                  _vm.title || _vm.$slots.title
                    ? _c(
                        "header",
                        {
                          staticClass: "yxt-drawer__header",
                          class: { "yxt-drawer__noborder": !_vm.headerBorder },
                          attrs: { id: "yxt-drawer__title" },
                        },
                        [
                          _vm._t("title", [
                            _vm.isBack
                              ? _c(
                                  "button",
                                  {
                                    staticClass: "yxt-drawer__back-btn",
                                    attrs: { type: "button" },
                                    on: { click: _vm.closeDrawer },
                                  },
                                  [
                                    _c("yxt-svg", {
                                      staticClass:
                                        "color-gray-8 hover-bg-gay-3 hand",
                                      attrs: {
                                        "icon-class": "arrow_left",
                                        width: "20px",
                                        height: "20px",
                                      },
                                    }),
                                  ],
                                  1
                                )
                              : _vm._e(),
                            _c(
                              "span",
                              {
                                staticClass: "yxt-drawer--title",
                                attrs: { role: "heading" },
                              },
                              [_vm._v(_vm._s(_vm.title))]
                            ),
                            _vm.labelTooltip
                              ? _c(
                                  "yxt-tooltip",
                                  {
                                    attrs: {
                                      effect: _vm.tooltipEffect,
                                      content: _vm.labelTooltip,
                                      placement: "bottom",
                                      "max-width": _vm.labelTooltipWidth,
                                    },
                                  },
                                  [
                                    _c("yxt-svg", {
                                      staticClass:
                                        "color-gray-6 hover-primary-6",
                                      staticStyle: {
                                        "margin-left": "-4px",
                                        "margin-right": "4px",
                                        cursor: "pointer",
                                      },
                                      attrs: {
                                        "remote-url": "",
                                        width: "20px",
                                        height: "20px",
                                        "icon-class": "question-cirlce-o",
                                      },
                                    }),
                                  ],
                                  1
                                )
                              : _vm._e(),
                            _vm.describe
                              ? _c(
                                  "span",
                                  { staticClass: "yxt-drawer--describe" },
                                  [_vm._v(_vm._s(_vm.describe))]
                                )
                              : _vm._e(),
                          ]),
                        ],
                        2
                      )
                    : _vm._e(),
                  _vm.showClose
                    ? _c(
                        "button",
                        {
                          staticClass: "yxt-drawer__close-btn",
                          attrs: {
                            "aria-label": "close " + (_vm.title || "drawer"),
                            type: "button",
                          },
                          on: { click: _vm.closeDrawer },
                        },
                        [
                          _c("yxt-svg", {
                            staticClass: "color-gray-8 hover-bg-gay-3 hand",
                            attrs: {
                              "icon-class": "common_close",
                              width: "24px",
                              height: "24px",
                            },
                          }),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.hasScroll
                    ? _c("scrollbar", { attrs: { "fit-height": true } }, [
                        _vm.rendered
                          ? _c(
                              "section",
                              { staticClass: "yxt-drawer__body" },
                              [_vm._t("default")],
                              2
                            )
                          : _vm._e(),
                      ])
                    : _vm._e(),
                  _vm.rendered && !_vm.hasScroll
                    ? _c(
                        "section",
                        { staticClass: "yxt-drawer__body" },
                        [_vm._t("default")],
                        2
                      )
                    : _vm._e(),
                  _vm.$slots.footer
                    ? _c(
                        "footer",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: _vm.rendered,
                              expression: "rendered",
                            },
                          ],
                          staticClass: "yxt-drawer_footer",
                        },
                        [_vm._t("footer")],
                        2
                      )
                    : _vm._e(),
                ],
                1
              ),
            ]
          ),
        ]
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }