var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "yxtf-progress",
      class: [
        "yxtf-progress--" + _vm.type,
        _vm.fitWidth ? "yxtf-progress--fit-width" : "",
        _vm.status ? "is-" + _vm.status : "",
        {
          "yxtf-progress--without-text": !_vm.showText,
          "yxtf-progress--text-inside": _vm.textInside,
        },
      ],
      attrs: {
        role: "progressbar",
        "aria-valuenow": _vm.percentage,
        "aria-valuemin": "0",
        "aria-valuemax": "100",
      },
    },
    [
      _vm.$slots.title
        ? _c(
            "div",
            { staticClass: "yxtf-progress__title" },
            [_vm._t("title")],
            2
          )
        : _vm._e(),
      _vm.type === "line"
        ? _c("div", { staticClass: "yxtf-progress-bar" }, [
            _c(
              "div",
              {
                staticClass: "yxtf-progress-bar__outer",
                style: _vm.outerStyle,
              },
              [
                _c(
                  "div",
                  {
                    staticClass: "yxtf-progress-bar__inner",
                    style: _vm.barStyle,
                  },
                  [
                    _vm.showText && _vm.textInside
                      ? _c(
                          "div",
                          { staticClass: "yxtf-progress-bar__innerText" },
                          [_vm._v(_vm._s(_vm.content))]
                        )
                      : _vm._e(),
                  ]
                ),
              ]
            ),
          ])
        : _c(
            "div",
            {
              staticClass: "yxtf-progress-circle",
              style: { height: _vm.width + "px", width: _vm.width + "px" },
            },
            [
              _c("svg", { attrs: { viewBox: "0 0 100 100" } }, [
                _c("path", {
                  staticClass: "yxt-progress-circle__track",
                  style: _vm.trailPathStyle,
                  attrs: {
                    d: _vm.trackPath,
                    stroke: _vm.outColor || _vm.currentColor,
                    "stroke-opacity": "0.1",
                    "stroke-width": _vm.relativeStrokeWidth,
                    fill: "none",
                  },
                }),
                _c("path", {
                  staticClass: "yxt-progress-circle__path",
                  style: _vm.circlePathStyle,
                  attrs: {
                    d: _vm.trackPath,
                    stroke: _vm.stroke,
                    fill: "none",
                    "stroke-linecap": "round",
                    "stroke-width": _vm.percentage
                      ? _vm.relativeStrokeWidth
                      : 0,
                  },
                }),
              ]),
            ]
          ),
      (_vm.showText && !_vm.textInside) || _vm.$slots.content
        ? _c(
            "div",
            { staticClass: "yxtf-progress__text" },
            [
              _vm._t("content"),
              !_vm.$slots.content
                ? _c("div", [_c("div", [_vm._v(_vm._s(_vm.content))])])
                : _vm._e(),
            ],
            2
          )
        : _vm._e(),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }