const rootNode = window;

const overflowScrollReg = /scroll|auto/i;
export function getScrollParent(element) {
  let node = element;
  while (node &&
      node.nodeType === document.ELEMENT_NODE &&
      node.tagName !== 'HTML' &&
      node.tagName !== 'BODY' &&
      node !== rootNode
  ) {
    let { overflowY } = window.getComputedStyle(node);

    if (overflowScrollReg.test(overflowY)) {
      return node;
    }

    node = node.parentNode;
  }

  return rootNode;
}

export function getRootScrollTop() {
  return window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0;
}

export function getElementTop(element) {
  return (
    (element === window ? 0 : element.getBoundingClientRect().top) +
    getRootScrollTop()
  );
}
