<template>
  <div
    ref="popper"
    :class="popperClass"
    class="yxt-time-select yxt-dropdown-menu yxt-popper">
    <yxt-scrollbar :fit-height="true" wrap-class="yxt-picker-panel__content">
      <div class="yxt-time-select-item"
        v-for="item in items"
        @mouseenter="handleMouseEnter(item)"
        @mouseleave="handleMouseLeave"
        :class="{ selected: value === item.value, disabled: item.disabled, default: item.value === defaultValue }"
        :disabled="item.disabled"
        :key="item.value"
        @click="handleClick(item)">{{ item.value }}</div>
    </yxt-scrollbar>
  </div>
</template>

<script type="text/babel">
 import ElScrollbar from '@backstage/scrollbar';
 import scrollIntoView from '@utils/utils/scroll-into-view';

 const parseTime = function(time) {
   const values = (time || '').split(':');
   if (values.length >= 2) {
     const hours = parseInt(values[0], 10);
     const minutes = parseInt(values[1], 10);

     return {
       hours,
       minutes
     };
   }
   /* istanbul ignore next */
   return null;
};

const compareTime = function(time1, time2) {
   const value1 = parseTime(time1);
   const value2 = parseTime(time2);

   const minutes1 = value1.minutes + value1.hours * 60;
   const minutes2 = value2.minutes + value2.hours * 60;

   if (minutes1 === minutes2) {
     return 0;
   }

   return minutes1 > minutes2 ? 1 : -1;
};

const formatTime = function(time) {
   return (time.hours < 10 ? '0' + time.hours : time.hours) + ':' + (time.minutes < 10 ? '0' + time.minutes : time.minutes);
};

const nextTime = function(time, step) {
   const timeValue = parseTime(time);
   const stepValue = parseTime(step);

   const next = {
     hours: timeValue.hours,
     minutes: timeValue.minutes
   };

   next.minutes += stepValue.minutes;
   next.hours += stepValue.hours;

   next.hours += Math.floor(next.minutes / 60);
   next.minutes = next.minutes % 60;

   return formatTime(next);
};

 export default {
   components: { ElScrollbar },

   watch: {
     value(val) {
       if (!val) return;
       this.$nextTick(() => this.scrollToOption());
     }
   },
   computed: {
     items() {
       const start = this.start;
       const end = this.end;
       const step = this.step;

       const result = [];

       if (start && end && step) {
         let current = start;
         while (compareTime(current, end) <= 0) {
           result.push({
             value: current,
             disabled: compareTime(current, this.minTime || '-1:-1') <= 0 ||
               compareTime(current, this.maxTime || '100:100') >= 0
           });
           current = nextTime(current, step);
         }
       }

       return result;
     }
   },

   methods: {
     handleMenuEnter() {
       const selected = this.items.map(item => item.value).indexOf(this.value) !== -1;
       const hasDefault = this.items.map(item => item.value).indexOf(this.defaultValue) !== -1;
       const option = (selected && '.selected') || (hasDefault && '.default') || '.time-select-item:not(.disabled)';
       this.$nextTick(() => this.$refs.select && this.$refs.select.scrollToOption(option));
     },
     handleMouseEnter(item) {
       this.$emit('placeholder', 'time-select', item.value);
     },
     handleMouseLeave() {
       this.$emit('placeholder', 'time-select', null);
     },
     handleClick(item) {
       if (!item.disabled) {
         this.$emit('pick', item.value);
       }
     },

     handleClear() {
       this.$emit('pick', null);
     },

     scrollToOption(selector = '.selected') {
       const menu = this.$refs.popper.querySelector('.yxt-picker-panel__content');
       scrollIntoView(menu, menu.querySelector(selector));
     },
 
     scrollDown(step) {
       const items = this.items;
       const length = items.length;
       let total = items.length;
       let index = items.map(item => item.value).indexOf(this.value);
       while (total--) {
         index = (index + step + length) % length;
         if (!items[index].disabled) {
           this.$emit('pick', items[index].value, true);
           return;
         }
       }
     },
     // 获取距离最近的时间戳
     getNear(date) {
       if (!date) return null;
       const arr = date.split(':');
       if (!arr || arr.length < 2) return null;
       const hour = Number(arr[0]);
       const minute = Number(arr[1]);
       const dateMinutes = hour * 60 + minute;
       const stepValue = parseTime(this.step);
       const stepMinutes = stepValue.hours * 60 + stepValue.minutes;
       let res = this.items.length > 0 ? this.items[0].value : null;
       let min = stepMinutes;
 
       this.items.forEach((item) => {
         const values = item.value.split(':').map(Number);
         const valueMinutes = values[0] * 60 + values[1];
         let offsetMinutes = Math.abs(valueMinutes - dateMinutes);
         if (offsetMinutes <= min) {
           min = offsetMinutes;
           res = item.value;
         }
       });
       return res;
     },

     isValidValue(date) {
       return this.items.filter(item => !item.disabled).map(item => item.value).indexOf(date) !== -1;
     },

     handleKeydown(event) {
       const keyCode = event.keyCode;
       if (keyCode === 38 || keyCode === 40) {
         const mapping = { 40: 1, 38: -1 };
         const offset = mapping[keyCode.toString()];
         this.scrollDown(offset);
         event.stopPropagation();
         return;
       }
     }
   },

   props: {
     popperClass: String,
     start: [String, Object],
     end: [String, Object],
     step: [String, Object],
     value: '',
     defaultValue: '',
     minTime: '',
     maxTime: ''
   }
 };
</script>
