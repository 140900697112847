var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "transition",
    {
      attrs: { name: "yxtf-loading-fade" },
      on: { "after-leave": _vm.handleAfterLeave },
    },
    [
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.visible,
              expression: "visible",
            },
          ],
          staticClass: "yxtf-loading-mask",
          class: [_vm.customClass, { "is-fullscreen": _vm.fullscreen }],
          style: { backgroundColor: _vm.background || "" },
        },
        [
          _c("div", { staticClass: "yxtf-loading-spinner" }, [
            _c(
              "svg",
              {
                class: !_vm.spinner ? "circular" : "customCircular",
                attrs: { viewBox: "25 25 50 50" },
              },
              [
                _c("circle", {
                  staticClass: "path",
                  attrs: { cx: "50", cy: "50", r: "20", fill: "none" },
                }),
              ]
            ),
            _vm.text
              ? _c("p", { staticClass: "yxtf-loading-text" }, [
                  _vm._v(_vm._s(_vm.text)),
                ])
              : _vm._e(),
          ]),
        ]
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }