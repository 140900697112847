<template>
  <div
   @dragstart="handleDragStart"
    @mouseover="controlsFocus = true"
    @mouseleave="controlsFocus = false"
    :class="[
      'yxt-input-number',
      inputNumberSize ? 'yxt-input-number--' + inputNumberSize : '',
      { 'is-disabled': inputNumberDisabled },
      { 'is-without-controls': !controls },
      { 'is-controls-right': controlsAtRight },
      currency && currency.length > 0 ? 'currency-input-style' : ''
    ]"
    :style="widthCustom ? `width:${widthCustom}` : ''"
  >
  <!-- {{displayValue}} - {{currentValue}} - {{userInput}} -->
    <span
      class="yxt-input-number__decrease"
      role="button"
      v-show="controlsFocus && controls"
      v-repeat-click="decrease"
      :class="{ 'is-disabled': minDisabled || inputNumberDisabled }"
      @keydown.enter="decrease"
      @click="handleStopPropagation"
    >
      <i :class="`yxt-icon-${controlsAtRight ? 'arrow-down' : 'minus'}`"></i>
    </span>
    <span
      class="yxt-input-number__increase"
      role="button"
      v-show="controlsFocus && controls"
      v-repeat-click="increase"
      :class="{ 'is-disabled': maxDisabled || inputNumberDisabled }"
      @keydown.enter="increase"
      @click="handleStopPropagation"
    >
      <i :class="`yxt-icon-${controlsAtRight ? 'arrow-up' : 'plus'}`"></i>
    </span>
    <yxt-input
      ref="input"
      :class="currency && currency.length > 0 ? 'currencyIcon' : ''"
      :value="displayValue"
      :placeholder="placeholder"
      :disabled="inputNumberDisabled"
      :size="inputNumberSize"
      :max="max"
      :min="min"
      :name="name"
      :label="label"
      @keydown.up.native.prevent="increase"
      @keydown.down.native.prevent="decrease"
      @blur="handleBlur"
      @focus="handleFocus"
      @input="handleInput"
      @change="handleInputChange"
    >
      <div v-if="currency && currency.length > 0" slot="prefix" class="currency-num-style">{{currency}}</div>
    </yxt-input>
  </div>
</template>
<script>
import ElInput from '@backstage/input';
import Focus from '@utils/mixins/focus';
import RepeatClick from '@utils/directives/repeat-click';

export default {
  name: 'YxtInputNumber',
  mixins: [Focus('input')],
  inject: {
    yxtForm: {
      default: ''
    },
    yxtFormItem: {
      default: ''
    }
  },
  directives: {
    repeatClick: RepeatClick
  },
  components: {
    ElInput
  },
  model: {
    prop: 'value',
    event: 'valueInput'
  },
  props: {
    step: {
      type: Number,
      default: 1,
      desc: '计数器步长'
    },
    stepStrictly: {
      type: Boolean,
      default: false,
      desc: '只能输入step的倍数'
    },
    max: {
      type: Number,
      default: Infinity,
      desc: '最大值'
    },
    min: {
      type: Number,
      default: -Infinity,
      desc: '最小值'
    },
    value: {
      desc: '绑定值'
    },
    disabled: {
      type: Boolean,
      desc: '是否禁用'
    },
    size: {
      type: String,
      desc: '尺寸'
    },
    controls: {
      type: Boolean,
      default: true,
      desc: '使用控制按钮'
    },
    controlsPosition: {
      type: String,
      default: 'right',
      desc: '控制按钮位置，值为right时按钮在右侧，其他任何值按钮在左右两侧'
    },
    name: {
      type: String,
      desc: '原生name属性'
    },
    label: {
      type: String,
      desc: '关联的label文字'
    },
    placeholder: {
      type: String,
      desc: '占位文本'
    },
    precision: {
      type: Number,
      validator(val) {
        return val >= 0 && val === parseInt(val, 10);
      },
      desc: '数值精度'
    },
    formatter: {
      type: Function,
      default: null,
      desc: '格式化函数，参数为修改后的值'
    },
    width: {
      type: String,
      default: null,
      desc: '数字输入框的宽'
    },
    currency: {
      type: String,
      default: '',
      desc: '国际货币/货币输入框'
    },
    parser: {
      type: Function,
      default: null,
      desc: '解析函数'
    },
    shouldPreventDrag: {
      type: Boolean,
      default: true,
      desc: '是否阻止拖拽(appmaker内置使用)'
    }
  },
  data() {
    return {
      currentValue: 0,
      userInput: null,
      controlsFocus: false
    };
  },
  watch: {
    value: {
      immediate: true,
      handler(value) {
        let newVal = value === undefined ? value : Number(value);
        if (newVal !== undefined) {
          if (isNaN(newVal)) {
            return;
          }

          if (this.stepStrictly) {
            const stepPrecision = this.getPrecision(this.step);
            const precisionFactor = Math.pow(10, stepPrecision);
            newVal =
              (Math.round(newVal / this.step) * precisionFactor * this.step) /
              precisionFactor;
          }

          if (this.precision !== undefined) {
            newVal = this.toPrecision(newVal, this.precision);
          }
        }
        if (newVal >= this.max) newVal = this.max;
        if (newVal <= this.min) newVal = this.min;
        this.currentValue = this.formatter ? this.formatter(newVal) : newVal;
        this.userInput = null;
        this.$emit('valueInput', newVal);
      }
    }
  },
  computed: {
    widthCustom() {
      return this.width ? this.width + 'px' : false;
    },
    minDisabled() {
      return this._decrease(this.value, this.step) < this.min;
    },
    maxDisabled() {
      return this._increase(this.value, this.step) > this.max;
    },
    numPrecision() {
      const { value, step, getPrecision, precision } = this;
      const stepPrecision = getPrecision(step);
      if (precision !== undefined) {
        if (stepPrecision > precision) {
          console.warn(
            '[Element Warn][InputNumber]precision should not be less than the decimal places of step'
          );
        }
        return precision;
      } else {
        return Math.max(getPrecision(value), stepPrecision);
      }
    },
    controlsAtRight() {
      return this.controls && this.controlsPosition === 'right';
    },
    _yxtFormItemSize() {
      return (this.yxtFormItem || {}).yxtFormItemSize;
    },
    inputNumberSize() {
      return this.size || this._yxtFormItemSize || (this.$ELEMENT || {}).size;
    },
    inputNumberDisabled() {
      return this.disabled || (this.yxtform || {}).disabled;
    },
    displayValue() {
      if (this.userInput !== null) {
        return this.userInput;
      }

      let currentValue = this.currentValue;

      if (typeof currentValue === 'number') {
        if (this.stepStrictly) {
          const stepPrecision = this.getPrecision(this.step);
          const precisionFactor = Math.pow(10, stepPrecision);
          currentValue =
            (Math.round(currentValue / this.step) *
              precisionFactor *
              this.step) /
            precisionFactor;
        }

        if (this.precision !== undefined) {
          currentValue = currentValue.toFixed(this.precision);
        }
      }

      return currentValue;
    }
  },
  methods: {
    handleStopPropagation(e) {
      e.preventDefault();
      e.stopPropagation();
    },
    handleDragStart(event) {
      if (this.shouldPreventDrag) {
        event.preventDefault(); // 如果应该阻止，则调用 preventDefault;
      }
      // 其他逻辑...
    },
    toPrecision(num, precision) {
      if (precision === undefined) precision = this.numPrecision;
      return parseFloat(
        Math.round(num * Math.pow(10, precision)) / Math.pow(10, precision)
      );
    },
    getPrecision(value) {
      if (value === undefined) return 0;
      const valueString = value.toString();
      const dotPosition = valueString.indexOf('.');
      let precision = 0;
      if (dotPosition !== -1) {
        precision = valueString.length - dotPosition - 1;
      }
      return precision;
    },
    _increase(val, step) {
      // console.log('increase val-step: ', val, step);
      // console.log('typeof val', typeof val, typeof val !== 'number');
      // console.log('val !=== undefined: ', val !== undefined);
      // console.log('this.currentValue: ', this.currentValue);
      if (typeof val !== 'number' && val !== undefined) {
        return this.currentValue;
      }

      if (val === undefined || val === null || val === '') {
        // this.min 为 Infinity 时，val 为 0
        if (!Number.isFinite(this.min)) {
          val = 0;
        } else {
          val = this.min;
        }
      }

      const precisionFactor = Math.pow(10, this.numPrecision);
      // Solve the accuracy problem of JS decimal calculation by converting the value to integer.

      // if (val === undefined) {
      //   val = 0;
      // }
      return this.toPrecision(
        (precisionFactor * val + precisionFactor * step) / precisionFactor
      );
    },
    _decrease(val, step) {
      if (typeof val !== 'number' && val !== undefined) {
        return this.currentValue;
      };

      if (val === undefined || val === null || val === '') {
        // this.min 为 Infinity 时，val 为 0
        if (!Number.isFinite(this.min)) {
          val = 0;
        } else {
          val = this.min;
        }
      }

      const precisionFactor = Math.pow(10, this.numPrecision);

      // if (val === undefined) {
      //   val = 0;
      // }

      return this.toPrecision(
        (precisionFactor * val - precisionFactor * step) / precisionFactor
      );
    },
    increase(e) {
      // console.log('increase ......', e);
      if (this.inputNumberDisabled || this.maxDisabled) return;
      // const value = this.value || 0;

      // console.log('displayValue---', this.displayValue);
      // console.log('value---this.value---', this.value);
      const value = this.getnewValue(this.displayValue);
      // console.log('value---', value);
      const newVal = this._increase(value, this.step);
      // console.log('newVal---', newVal);
      this.userInput = this.currentValue;
      this.setCurrentValue(newVal);
    },
    decrease() {
      if (this.inputNumberDisabled || this.minDisabled) return;
      // const value = this.value || 0;
      const value = this.getnewValue(this.displayValue);
      // console.log('value---', value);
      const newVal = this._decrease(value, this.step);
      // console.log('newVal---', newVal);
      this.userInput = this.currentValue;
      this.setCurrentValue(newVal);
    },
    handleBlur(event) {
      this.$emit('blur', event);
    },
    handleFocus(event) {
      this.controlsFocus = true;
      this.$emit('focus', event);
    },
    setCurrentValue(newVal) {
      // console.log('setCurrentValue: ', newVal);
      const oldVal = this.currentValue;
      if (typeof newVal === 'number' && this.precision !== undefined) {
        newVal = this.toPrecision(newVal, this.precision);
      }
      if (newVal >= this.max) newVal = this.max;
      if (newVal <= this.min) newVal = this.min;
      if (oldVal === newVal) return;
      this.userInput = null;
      this.$emit('valueInput', newVal);
      this.$emit('change', newVal, oldVal);
      // this.currentValue = newVal;
      this.currentValue = this.formatter ? this.formatter(newVal) : newVal;
    },
    getnewValue(value) {
      // /^(\-|\+)?\d+(\.\d+)?$/
      const noNumberReg = /[^0-9\.\-\+]/g;
      value = `${value}`;
      // let newVal = value;
      if (value === '' || value === undefined || value === 'undefined') {
        return undefined;
      } else {
        let newVal = value.replace(noNumberReg, '');
        // console.log(newVal);
        if (isNaN(newVal)) {
          newVal = this.currentValue;
          return newVal;
        };
        return Number(newVal);
      }
    },
    handleInput(value) {
      // const newVal = value === '' ? undefined : Number(value);
      // console.log('handleInput --- value: ', value, ' - newVal: ', newVal);
      // if (!isNaN(newVal) || value === '') {
      //   this.setCurrentValue(newVal);
      // }
      this.userInput = value;
      this.$emit('input', value);
    },
    handleInputChange(value) {
      // const newVal = value === '' ? undefined : Number(value);
      const newVal = this.getnewValue(value);
      // console.log('handleInputChange --- value: ', value, ' - newVal: ', newVal);
      if (!isNaN(newVal) || value === '') {
        this.setCurrentValue(newVal);
      }
      this.userInput = null;
    },
    select() {
      this.$refs.input.select();
    }
  },
  mounted() {
    let innerInput = this.$refs.input.$refs.input;
    innerInput.setAttribute('role', 'spinbutton');
    innerInput.setAttribute('aria-valuemax', this.max);
    innerInput.setAttribute('aria-valuemin', this.min);
    innerInput.setAttribute('aria-valuenow', this.currentValue);
    innerInput.setAttribute('aria-disabled', this.inputNumberDisabled);
  },
  updated() {
    if (!this.$refs || !this.$refs.input) return;
    const innerInput = this.$refs.input.$refs.input;
    innerInput.setAttribute('aria-valuenow', this.currentValue);
  }
};
</script>
