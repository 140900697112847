<template>
  <div class="yxt-badge-text">
    <slot></slot>
    <div
      v-show="!hidden"
      :class="['yxt-badge-text__content', 'yxt-badge-text__content--' + type]"
    >
      <span class="scale-font">{{ content }}</span>
      <yxt-svg v-if="isShowPlus" width="5px" :remote-url="url" icon-class="badge-plus" class="plus-svg" />
    </div>
  </div>
</template>

<script>
export default {
  name: 'YxtBadgeText',
  props: {
    hidden: Boolean,
    value: [String, Number],
    max: Number,
    type: {
      type: String,
      validator(val) {
        return ['primary', 'success', 'warning', 'info', 'danger'].indexOf(val) > -1;
      }
    }
  },
  computed: {
    content() {
      const value = this.value;
      const max = this.max;
      if (typeof value === 'number' && typeof max === 'number') {
        return max < value ? max : value;
      }
      return value;
    },
    isShowPlus() {
      const value = Number(this.value);
      const max = this.max;
      if (typeof value === 'number' && typeof max === 'number') {
        return max < value;
      } else {
        return false;
      }
    },
    url() {
      const staticBaseUrl = (typeof window !== 'undefined' && window.feConfig && window.feConfig.common && window.feConfig.common.staticBaseUrl) || 'https://stc.yxt.com/';
      return `${staticBaseUrl}ufd/3f5568/common/pc_backstage/font`;
    }
  }
};
</script>
