var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "aside",
    {
      staticClass: "yxt-layout__aside",
      class: _vm.wrapClass,
      style: _vm.wrapStyle,
    },
    [
      _vm.sticky
        ? _c(
            "yxt-sticky",
            {
              ref: "sticky",
              attrs: {
                bg: _vm.bg,
                "keep-top": _vm.mainNav,
                width: _vm.width,
                allowance: _vm.allowance,
                offset: _vm.offset,
                "keep-left": _vm.keepLeft,
                "fix-height": _vm.fixHeight,
                "keep-size": "",
              },
              on: { change: _vm.changeFixed },
            },
            [
              _c(
                "div",
                {
                  ref: "view",
                  staticClass: "yxt-layout__aside--inner",
                  style: _vm.style,
                },
                [
                  _c(
                    "yxt-scrollbar",
                    { ref: "bar", attrs: { "fit-height": true } },
                    [_vm._t("default")],
                    2
                  ),
                ],
                1
              ),
            ]
          )
        : _c(
            "div",
            {
              ref: "view",
              staticClass: "yxt-layout__aside--inner",
              style: _vm.style,
            },
            [
              _c(
                "yxt-scrollbar",
                { ref: "bar", attrs: { "fit-height": true } },
                [_vm._t("default")],
                2
              ),
            ],
            1
          ),
      _vm.resizable
        ? _c(
            "span",
            {
              staticClass: "yxt-layout__resize-line",
              on: { mouseenter: _vm.resieEnter, mousedown: _vm.resieStart },
            },
            [
              _c(
                "yxt-tooltip",
                {
                  staticClass: "item",
                  attrs: {
                    effect: "dark",
                    content: _vm.showText
                      ? this.t("pc_comp_sideBar_open")
                      : this.t("pc_comp_sideBar_close"),
                    placement: "top",
                  },
                },
                [
                  _vm.showText
                    ? _c(
                        "span",
                        {
                          ref: "toggle",
                          staticClass: "yxt-layout__aside-toggle",
                          style: _vm.toggleStyle,
                          on: {
                            mousedown: function ($event) {
                              $event.stopPropagation()
                            },
                            click: function ($event) {
                              $event.stopPropagation()
                              return _vm.toggle($event)
                            },
                          },
                        },
                        [
                          _c(
                            "svg",
                            {
                              attrs: {
                                width: "16",
                                height: "17",
                                xmlns: "http://www.w3.org/2000/svg",
                              },
                            },
                            [
                              _c(
                                "g",
                                {
                                  attrs: {
                                    transform: "matrix(-1 0 0 1 18 -8)",
                                    fill: "none",
                                    "fill-rule": "evenodd",
                                  },
                                },
                                [
                                  _c("path", { attrs: { d: "M2 8h16v16H2z" } }),
                                  _c("path", {
                                    attrs: {
                                      d: "M15.837 19.51a.85.85 0 0 1 0 1.7h-5.302a.85.85 0 0 1 0-1.7zM7.533 13.2c.086 0 .156.07.156.156v5.393a.156.156 0 0 1-.266.11l-3.31-2.532a.389.389 0 0 1 0-.55l3.31-2.531a.156.156 0 0 1 .11-.046zm8.304 1.91a.85.85 0 0 1 0 1.7h-5.302a.85.85 0 0 1 0-1.7zm0-4.4a.85.85 0 0 1 0 1.7h-5.302a.85.85 0 1 1 0-1.7z",
                                      fill: "currentColor",
                                      "fill-rule": "nonzero",
                                    },
                                  }),
                                ]
                              ),
                            ]
                          ),
                        ]
                      )
                    : _c(
                        "span",
                        {
                          ref: "toggle",
                          staticClass: "yxt-layout__aside-toggle",
                          style: _vm.toggleStyle,
                          on: {
                            mousedown: function ($event) {
                              $event.stopPropagation()
                            },
                            click: function ($event) {
                              $event.stopPropagation()
                              return _vm.toggle($event)
                            },
                          },
                        },
                        [
                          _c(
                            "svg",
                            {
                              attrs: {
                                width: "16",
                                height: "17",
                                xmlns: "http://www.w3.org/2000/svg",
                              },
                            },
                            [
                              _c(
                                "g",
                                {
                                  attrs: {
                                    transform: "translate(-2 -7)",
                                    fill: "none",
                                    "fill-rule": "evenodd",
                                  },
                                },
                                [
                                  _c("path", { attrs: { d: "M2 8h16v16H2z" } }),
                                  _c("path", {
                                    attrs: {
                                      d: "M15.837 19.51a.85.85 0 0 1 0 1.7h-5.302a.85.85 0 0 1 0-1.7zM7.533 13.2c.086 0 .156.07.156.156v5.393a.156.156 0 0 1-.266.11l-3.31-2.532a.389.389 0 0 1 0-.55l3.31-2.531a.156.156 0 0 1 .11-.046zm8.304 1.91a.85.85 0 0 1 0 1.7h-5.302a.85.85 0 0 1 0-1.7zm0-4.4a.85.85 0 0 1 0 1.7h-5.302a.85.85 0 1 1 0-1.7z",
                                      fill: "currentColor",
                                      "fill-rule": "nonzero",
                                    },
                                  }),
                                ]
                              ),
                            ]
                          ),
                        ]
                      ),
                ]
              ),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }