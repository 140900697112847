<template>
  <div class="yxtf-page-header" :class="{'yxtf-page-header--sub': subLevel, 'yxtf-page-header--suback': subLevel && showBack}">
    <div class="yxtf-page-header__top">
      <div class="yxtf-page-header__left" :class="showBack?'hand':''" @click="showBack && $emit('back')">
        <span v-if="showBack" class="yxtf-page-header__back"><yxt-svg width="16px" height="16px" icon-class="arrow-left" class="color-gray-9" /></span>
        <div class="yxtf-page-header__title color-gray-10">
          <slot name="title">{{ title }}</slot>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import YxtSvg from '../../svg-icon';
export default {
  name: 'YxtfPageHeader',
  components: {
    YxtSvg
  },
  props: {
    title: {
      type: String,
      default: ''
    },
    showBack: Boolean,
    subLevel: Boolean,
    threeLevel: Boolean
  }
};
</script>
