var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "yxt-progress",
      class: [
        "yxt-progress--" + _vm.type,
        _vm.status ? "is-" + _vm.status : "",
        _vm.themeMode ? "is-theme-" + _vm.themeMode : "",
        {
          "yxt-progress--without-text": !_vm.showText,
          "yxt-progress--text-inside": _vm.textInside,
        },
      ],
      attrs: {
        role: "progressbar",
        "aria-valuenow": _vm.percentage,
        "aria-valuemin": "0",
        "aria-valuemax": "100",
      },
    },
    [
      _vm.type === "line"
        ? _c("div", { staticClass: "yxt-progress-bar" }, [
            _c(
              "div",
              {
                staticClass: "yxt-progress-bar__outer",
                style: _vm.progressLineStyle,
              },
              [
                _c(
                  "div",
                  {
                    staticClass: "yxt-progress-bar__inner",
                    style: _vm.barStyle,
                  },
                  [
                    _vm.showText && _vm.textInside
                      ? _c(
                          "div",
                          { staticClass: "yxt-progress-bar__innerText" },
                          [_vm._v(_vm._s(_vm.content))]
                        )
                      : _vm._e(),
                  ]
                ),
              ]
            ),
          ])
        : _c(
            "div",
            {
              staticClass: "yxt-progress-circle",
              style: _vm.progressCircleStyle,
            },
            [
              _c("svg", { attrs: { viewBox: "0 0 100 100" } }, [
                _c("path", {
                  staticClass: "yxt-progress-circle__track",
                  style: _vm.trailPathStyle,
                  attrs: {
                    d: _vm.trackPath,
                    stroke: _vm.outColor || "#f0f0f0",
                    "stroke-width": _vm.relativeStrokeWidth,
                    fill: "none",
                  },
                }),
                _c("path", {
                  staticClass: "yxt-progress-circle__path",
                  style: _vm.circlePathStyle,
                  attrs: {
                    d: _vm.trackPath,
                    fill: "none",
                    "stroke-linecap": "round",
                    "stroke-width": _vm.percentage
                      ? _vm.relativeStrokeWidth
                      : 0,
                  },
                }),
              ]),
            ]
          ),
      _vm.showText && !_vm.textInside
        ? _c(
            "div",
            {
              staticClass: "yxt-progress__text",
              style: { fontSize: _vm.progressTextSize + "px" },
            },
            [
              !_vm.status || _vm.status === "success"
                ? [
                    _vm._v(_vm._s(_vm.content)),
                    typeof _vm.format !== "function"
                      ? _c("span", { staticStyle: { "font-size": "16px" } }, [
                          _vm._v("%"),
                        ])
                      : _vm._e(),
                  ]
                : _c("div", [
                    _vm.type !== "line" && _vm.status === "exception"
                      ? _c("span", [_vm._v("!")])
                      : _c("i", { class: _vm.iconClass }),
                  ]),
              _c(
                "div",
                { staticClass: "yxt-progress__custom" },
                [_vm._t("default")],
                2
              ),
            ],
            2
          )
        : _vm._e(),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }