var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "span",
    {
      staticClass: "yxtf-action-icon",
      class: _vm.light
        ? "yxtf-action-icon__" + _vm.type + "-light"
        : "yxtf-action-icon__" + _vm.type,
      style: _vm.fitStyle,
    },
    [
      _c("i", {
        ref: "icon",
        style: _vm.style,
        on: { click: _vm.clickHandler },
      }),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }