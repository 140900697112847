<template>
  <div
    class="yxt-select-dropdown yxt-popper"
    :class="[{ 'is-multiple': $parent.multiple }, popperClass]"
    @mouseleave="handleMouseLeave"
    :style="{ minWidth: minWidth }">
    <slot></slot>
  </div>
</template>

<script type="text/babel">
  import Popper from '@utils/utils/vue-popper';

  export default {
    name: 'YxtSelectDropdown',

    componentName: 'YxtSelectDropdown',

    mixins: [Popper],

    props: {
      placement: {
        default: 'bottom-start'
      },

      boundariesPadding: {
        default: 0
      },
      // offset: [0, 0],
      popperOptions: {
        default() {
          return {
            gpuAcceleration: false
          };
        }
      },

      visibleArrow: {
        default: true
      },

      appendToBody: {
        type: Boolean,
        default: true
      }
    },

    data() {
      return {
        minWidth: ''
      };
    },

    computed: {
      popperClass() {
        return this.$parent.popperClass;
      }
    },

    watch: {
      '$parent.inputWidth'() {
        this.minWidth = this.$parent.$el.getBoundingClientRect().width + 'px';
      }
    },

    mounted() {
      if (!this.appendToBody) {
        // this.offset = [0, 0];
        this.offset = 0;
      }
      this.referenceElm = this.$parent.$refs.reference.$el;
      this.$parent.popperElm = this.popperElm = this.$el;
      this.$on('updatePopper', () => {
        if (this.$parent.visible) this.updatePopper();
      });
      this.$on('destroyPopper', this.destroyPopper);
      this.$on('loadingChange', function() {
        this.$nextTick(() => {
          if (this.$parent.visible) this.updatePopper();
        });
      });
    },

    methods: {
      handleMouseLeave() {
        this.$emit('leaveDropdown');
      }
    }
  };
</script>
