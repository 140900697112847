var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("transition", { attrs: { name: "yxt-alert-fade" } }, [
    _c(
      "div",
      {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.visible,
            expression: "visible",
          },
        ],
        staticClass: "yxt-alert",
        class: [
          _vm.typeClass,
          _vm.center ? "is-center" : "",
          "is-" + _vm.effect,
          _vm.showIcon ? "yxt-alert-svg" : "",
        ],
        style: { backgroundColor: _vm.bgColor },
        attrs: { role: "alert" },
      },
      [
        _c(
          "div",
          {
            class: ["yxt-alert__content", _vm.showIcon ? "yxt-alert__svg" : ""],
          },
          [
            _vm.showIcon
              ? _c("yxt-svg", {
                  staticClass: "color-gray-6 hover-primary-6",
                  attrs: {
                    width: "16px",
                    height: "16px",
                    "icon-class": _vm.iconName,
                  },
                })
              : _vm._e(),
            _vm.title || _vm.$slots.title
              ? _c(
                  "span",
                  {
                    ref: "title",
                    staticClass: "yxt-alert__title",
                    class: [_vm.isBoldTitle],
                  },
                  [_vm._t("title", [_vm._v(_vm._s(_vm.title))])],
                  2
                )
              : _vm._e(),
            _vm.$slots.default && !_vm.description
              ? _c(
                  "p",
                  { staticClass: "yxt-alert__description" },
                  [_vm._t("default")],
                  2
                )
              : _vm._e(),
            _vm.description && !_vm.$slots.default
              ? _c("p", { staticClass: "yxt-alert__description" }, [
                  _vm._v(_vm._s(_vm.description)),
                ])
              : _vm._e(),
          ],
          1
        ),
        _c(
          "i",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.closable,
                expression: "closable",
              },
            ],
            staticClass: "yxt-alert__closebtn",
            class: {
              "is-customed": _vm.closeText !== "",
              "yxt-icon-close": _vm.closeText === "",
            },
            on: {
              click: function ($event) {
                _vm.close()
              },
            },
          },
          [_vm._v(_vm._s(_vm.closeText))]
        ),
      ]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }