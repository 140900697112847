/* eslint-disable */
import { arrayFindIndex } from '@utils/utils/util';
import { on, off } from '@utils/utils/dom';
import { getCell, getColumnByCell, getRowIdentity } from './util';
import { getStyle, hasClass, removeClass, addClass } from '@utils/utils/dom';
import ElCheckbox from '@backstage/checkbox';
import YxtPopover from '@backstage/popover';
import YxtScrollbar from '@backstage/scrollbar';
import debounce from 'throttle-debounce/debounce';
import LayoutObserver from './layout-observer';
import { mapStates } from './store/helper';

export default {
  name: 'YxtTableBody',

  mixins: [LayoutObserver],

  components: {
    ElCheckbox,
    YxtPopover,
    YxtScrollbar
  },

  props: {
    store: {
      required: true
    },
    rowHeight: Number,
    list: {
      type: Array,
      default() {
        return [];
      }
    },
    tooltipEffect: {
      type: String,
      default: 'dark'
    },
    virtual: {
      type: Boolean,
      default: false
    },
    range: {
      type: Object,
      default: () => ({ start: 0, end: 0 })
    },
    stripe: Boolean,
    context: {},
    rowClassName: [String, Function],
    rowStyle: [Object, Function],
    fixed: String,
    highlight: Boolean
  },

  render(h) {
    return (
      <table class="yxt-table__body" cellspacing="0" cellpadding="0" border="0">
        <colgroup>
          {this.columns.map(column => (
            <col name={column.id} key={column.id} />
          ))}
        </colgroup>
        <tbody>
          {this.renderList.reduce((acc, row) => {
            return acc.concat(this.wrappedRowRender(row, this.virtual ? row._index : acc.length));
          }, [])}
        </tbody>
        <yxt-popover
          show-scroll
          max-width={300}
          max-height={200}
          placement="top"
          effect={this.tooltipEffect}
          ref="tooltip"
          popper-class="yxt-table__popper"
          // content={this.tooltipContent}
          // after-enter={this.popoverUpdateScrollbar}
        >
          {!this.popoverHtml && this.popoverCustom ? (
            <pre className="popper_pre">{this.tooltipContent}</pre>
          ) : (
            this.tooltipContent
          )}
        </yxt-popover>
      </table>
    );
  },

  computed: {
    table() {
      return this.$parent;
    },

    renderList() {
      return this.virtual ? this.list : this.data || [];
    },

    treeTable() {
      return !!(this.data || []).find((row) => {
        return row[this.store.states.lazyColumnIdentifier] || row[this.store.states.childrenColumnName];
      });
    },

    tableRowHeight() {
      return this.rowHeight ? { height: `${this.rowHeight}px` } : {};
    },

    ...mapStates({
      data: 'data',
      columns: 'columns',
      treeIndent: 'indent',
      leftFixedLeafCount: 'fixedLeafColumnsLength',
      rightFixedLeafCount: 'rightFixedLeafColumnsLength',
      hoverRow: states => states.hoverRow,
      columnsCount: states => states.columns.length,
      leftFixedCount: states => states.fixedColumns.length,
      rightFixedCount: states => states.rightFixedColumns.length,
      hasExpandColumn: states =>
        states.columns.some(({ type }) => type === 'expand'),
      expandContentNoPadding: states =>
        states.columns.some((c) => c.expandContentNoPadding)
    }),

    firstDefaultColumnIndex() {
      return arrayFindIndex(this.columns, ({ type }) => type === 'default');
    }
  },

  watch: {
    // don't trigger getter of currentRow in getCellClass. see https://jsfiddle.net/oe2b4hqt/
    // update DOM manually. see https://github.com/ElemeFE/element/pull/13954/files#diff-9b450c00d0a9dec0ffad5a3176972e40
    'store.states.hoverRow'(newVal, oldVal) {
      if (!this.store.states.isComplex || this.$isServer) return;
      let raf = window.requestAnimationFrame;
      if (!raf) {
        raf = fn => setTimeout(fn, 16);
      }
      raf(() => {
        const rows = this.$el.querySelectorAll('.yxt-table__row');
        const oldRow = rows[oldVal];
        const newRow = rows[newVal];
        if (oldRow) {
          removeClass(oldRow, 'hover-row');
        }
        if (newRow) {
          addClass(newRow, 'hover-row');
        }
      });
    },
    'store.states.data'(newVal, oldVal) {
      this.renderCellList = [];
      const tooltip = this.$refs.tooltip;
      if (tooltip) {
        tooltip.doClose();
        tooltip.doDestroy();
      }
    },
    list() {
      this.$forceUpdate();
    }
  },

  data() {
    this.renderCellList = [];
    return {
      tooltipContent: '',
      popoverCustom: '',
      popoverHtml: ''
    };
  },

  created() {
    this.activateTooltip = debounce(50, tooltip => {
      // console.log('debounce active tooltip ======');
      tooltip.doClose();
      tooltip.handleMouseEnter();
      on(tooltip.popperElm, 'mouseenter', () => {
        this.activateTooltip(tooltip);
      });
      on(tooltip.popperElm, 'mouseleave', () => {
        this.unactivateTooltip(tooltip);
      });
    });
    this.unactivateTooltip = debounce(50, tooltip => {
      // console.log('debounce unactive tooltip ======');
      tooltip.handleMouseLeave();
      off(tooltip.popperElm, 'mouseenter', () => {
        this.activateTooltip(tooltip);
      });
      off(tooltip.popperElm, 'mouseleave', () => {
        this.unactivateTooltip(tooltip);
      });
    });
  },
  methods: {
    getKeyOfRow(row, index) {
      const rowKey = this.table.rowKey;
      if (rowKey) {
        return getRowIdentity(row, rowKey);
      }
      return index;
    },

    isColumnHidden(index) {
      if (this.fixed === true || this.fixed === 'left') {
        return index >= this.leftFixedLeafCount;
      } else if (this.fixed === 'right') {
        return index < this.columnsCount - this.rightFixedLeafCount;
      } else {
        return (
          index < this.leftFixedLeafCount ||
          index >= this.columnsCount - this.rightFixedLeafCount
        );
      }
    },

    getSpan(row, column, rowIndex, columnIndex) {
      let rowspan = 1;
      let colspan = 1;
      const fn = this.table.spanMethod;
      if (typeof fn === 'function') {
        const result = fn({
          row,
          column,
          rowIndex,
          columnIndex
        });

        if (Array.isArray(result)) {
          rowspan = result[0];
          colspan = result[1];
        } else if (typeof result === 'object') {
          rowspan = result.rowspan;
          colspan = result.colspan;
        }
      }
      return { rowspan, colspan };
    },
    getRowStyle(row, rowIndex) {
      const rowStyle = this.table.rowStyle;
      if (typeof rowStyle === 'function') {
        return rowStyle.call(null, {
          row,
          rowIndex
        });
      }
      return rowStyle || null;
    },

    getRowClass(row, rowIndex) {
      const classes = ['yxt-table__row'];
      if (
        this.table.highlightCurrentRow &&
        row === this.store.states.currentRow
      ) {
        classes.push('current-row');
      }

      if (this.stripe && rowIndex % 2 === 1) {
        classes.push('yxt-table__row--striped');
      }
      const rowClassName = this.table.rowClassName;
      if (typeof rowClassName === 'string') {
        classes.push(rowClassName);
      } else if (typeof rowClassName === 'function') {
        classes.push(
          rowClassName.call(null, {
            row,
            rowIndex
          })
        );
      }

      if (this.store.states.expandRows.indexOf(row) > -1) {
        classes.push('expanded');
      }

      return classes;
    },

    getCellStyle(rowIndex, columnIndex, row, column) {
      const cellStyle = this.table.cellStyle;
      if (typeof cellStyle === 'function') {
        return cellStyle.call(null, {
          rowIndex,
          columnIndex,
          row,
          column
        });
      }
      return cellStyle;
    },

    getCellClass(rowIndex, columnIndex, row, column, rowSpan, borderRender = {top: true, bottom: true}) {
      const classes = [column.id, column.align, column.className];

      if (this.isColumnHidden(columnIndex)) {
        classes.push('is-hidden');
      }

      if (!borderRender.top) {
        classes.push('hide-top-border');
      }

      if (!borderRender.bottom) {
        classes.push('hide-bottom-border');
      }

      if (!this.table.border) {
        classes.push('is-dragable');
      }

      if (column.sortableFixedRight) {
        classes.push('is-sortable-fixed-right');
      }

      // 行合并单元格, 对合并的格子 js增加hover样式
      if (rowSpan > 1 &&
        this.hoverRow &&
        this.hoverRow > rowIndex &&
        this.hoverRow <= (rowIndex + rowSpan - 1)) {
        classes.push('row-hover');
      }

      const cellClassName = this.table.cellClassName;
      if (typeof cellClassName === 'string') {
        classes.push(cellClassName);
      } else if (typeof cellClassName === 'function') {
        classes.push(
          cellClassName.call(null, {
            rowIndex,
            columnIndex,
            row,
            column
          })
        );
      }

      return classes.join(' ');
    },

    getColspanRealWidth(columns, colspan, index) {
      if (colspan < 1) {
        return columns[index].realWidth;
      }
      const widthArr = columns
        .map(({ realWidth }) => realWidth)
        .slice(index, index + colspan);
      return widthArr.reduce((acc, width) => acc + width, -1);
    },

    handleCellMouseEnter(event, row) {
      const table = this.table;
      const cell = getCell(event);

      if (cell) {
        const column = getColumnByCell(table, cell);
        const hoverState = (table.hoverState = { cell, column, row });
        table.$emit(
          'cell-mouse-enter',
          hoverState.row,
          hoverState.column,
          hoverState.cell,
          event
        );
      }

      // 判断是否text-overflow, 如果是就显示tooltip
      const cellChild = event.target.querySelector('.cell');
      if (
        !(hasClass(cellChild, 'yxt-tooltip') && cellChild.childNodes.length)
      ) {
        return;
      }
      // use range width instead of scrollWidth to determine whether the text is overflowing
      // to address a potential FireFox bug: https://bugzilla.mozilla.org/show_bug.cgi?id=1074543#c3
      const range = document.createRange();
      range.setStart(cellChild, 0);
      range.setEnd(cellChild, cellChild.childNodes.length);
      const rangeWidth = range.getBoundingClientRect().width;
      const padding =
        (parseInt(getStyle(cellChild, 'paddingLeft'), 10) || 0) +
        (parseInt(getStyle(cellChild, 'paddingRight'), 10) || 0);
      if (
        (rangeWidth + padding > cellChild.offsetWidth ||
          cellChild.scrollWidth > cellChild.offsetWidth) &&
        this.$refs.tooltip
      ) {
        // 是否需要自定义呈现popover中的内容，主要用于呈现换行
        this.popoverCustom = hasClass(cellChild, 'yxt-popover-custom');
        this.popoverHtml = hasClass(cellChild, 'yxt-popover-html');
        const tooltip = this.$refs.tooltip;
        // TODO 会引起整个 Table 的重新渲染，需要优化
        // this.tooltipContent = cell.innerText || cell.textContent;
        if (
          this.popoverHtml &&
          Array.isArray(this.renderCellList) &&
          this.renderCellList.length
        ) {
          const cellHandler = this.renderCellList.find(
            d =>
              d.row === Number(cell.getAttribute('data-row')) &&
              d.column === Number(cell.getAttribute('data-column'))
          );
          if (cellHandler && typeof cellHandler.handler === 'function') {
            this.tooltipContent = cellHandler.handler();
            // 去除生成的单元格Handler生成的单元格特有样式
            if (this.tooltipContent && this.tooltipContent.data) {
              this.tooltipContent.data = {};
            }
          } else {
            this.tooltipContent = cell.innerText || cell.textContent;
          }
        } else if (this.popoverCustom) {
          this.tooltipContent = cell.textContent;
        } else {
          this.tooltipContent = cell.innerText || cell.textContent;
        }
        tooltip.referenceElm = cell;
        tooltip.$refs.popper && (tooltip.$refs.popper.style.display = 'none');
        tooltip.doClose();
        tooltip.doDestroy();
        tooltip.updateScrollbar();
        this.$nextTick(() => {
          this.activateTooltip(tooltip);
        });
        // tooltip.setExpectedState(true);
      }
    },

    handleCellMouseLeave(event) {
      const tooltip = this.$refs.tooltip;
      this.$nextTick(() => {
        if (tooltip) {
          this.unactivateTooltip(tooltip);
        }
      });
      const cell = getCell(event);
      if (!cell) return;

      const oldHoverState = this.table.hoverState || {};
      this.table.$emit(
        'cell-mouse-leave',
        oldHoverState.row,
        oldHoverState.column,
        oldHoverState.cell,
        event
      );
    },

    handleMouseEnter: debounce(30, function(index) {
      this.store.commit('setHoverRow', index);
    }),

    handleMouseLeave: debounce(30, function() {
      this.store.commit('setHoverRow', null);
    }),

    handleContextMenu(event, row) {
      this.handleEvent(event, row, 'contextmenu');
    },

    handleDoubleClick(event, row) {
      this.handleEvent(event, row, 'dblclick');
    },

    handleClick(event, row) {
      this.store.commit('setCurrentRow', row);
      this.handleEvent(event, row, 'click');
    },

    handleEvent(event, row, name) {
      const table = this.table;
      const cell = getCell(event);
      let column;
      if (cell) {
        column = getColumnByCell(table, cell);
        if (column) {
          table.$emit(`cell-${name}`, row, column, cell, event);
        }
      }
      table.$emit(`row-${name}`, row, column, event);
    },

    rowRender(row, $index, treeRowData) {
      const { treeIndent, columns, firstDefaultColumnIndex } = this;
      const columnsHidden = columns.map((column, index) =>
        this.isColumnHidden(index)
      );

      const $dataIndex = $index;
      const rowClasses = this.getRowClass(row, $index);
      let display = true;
      if (treeRowData) {
        rowClasses.push('yxt-table__row--level-yxt-' + treeRowData.level);
        display = treeRowData.display;
      }
      // 指令 v-show 会覆盖 row-style 中 display
      // 使用 :style 代替 v-show https://github.com/ElemeFE/element/issues/16995
      let displayStyle = display ? null : { display: 'none' };
      return (
        <tr
          style={[
            displayStyle,
            this.getRowStyle(row, $index),
            this.tableRowHeight
          ]}
          class={rowClasses}
          key={this.getKeyOfRow(row, $index)}
          on-dblclick={$event => this.handleDoubleClick($event, row)}
          on-click={$event => this.handleClick($event, row)}
          on-contextmenu={$event => this.handleContextMenu($event, row)}
          on-mouseenter={_ => this.handleMouseEnter($index)}
          on-mouseleave={this.handleMouseLeave}
        >
          {columns.map((column, cellIndex) => {
            let { rowspan, colspan } = this.getSpan(
              row,
              column,
              $index,
              cellIndex
            );
            let renderRowIndex = null; // 保存合并单元格渲染数据索引
            let renderRowSpan = null;
            let renderColumData = true;
            let renderBottomBorder = true; // 是否渲染底部边框
            let renderTopBorder = true; // 是否渲染顶部边框
            if (this.virtual) {
              renderRowSpan = row[`row-span-${cellIndex}`];
              renderRowIndex = row[`renderRow-${cellIndex}`];
            }
            const store = this.store;
            let renderRow = null;
            const { visibleTreeList } = store.states;
            if (this.virtual && renderRowSpan) {
              const { rowspan: currentRowSpan, colspan: currentColSpan } = row[`column-${cellIndex}`];
              const [startIndex, endIndex] = renderRowSpan; // 实际渲染合并数据的起始和终点索引
              const spanEndRow = currentRowSpan + renderRowIndex - 1; // 合并终点索引
              const _index = row._index;
              if (_index < startIndex && _index >= renderRowIndex) {
                // 当前行在合并范围内且索引在实际渲染索引之前，则将渲染数据置为空对象
                renderColumData = false;
                if ((this.renderList[0]._index === _index) || (_index === renderRowIndex)) {
                  // 渲染范围第一行在合并范围或者当前行即为合并起点
                  rowspan = startIndex - _index;
                  renderBottomBorder = false;
                }
              } else if ((_index > endIndex) && (_index <= spanEndRow)) {
                renderColumData = false;
                // 下方区域跨度
                if ((this.renderList[0]._index === _index) || (_index === endIndex + 1)) {
                  rowspan = spanEndRow - _index + 1;
                }
              } else {
                // 中间区域独立渲染
                if (_index === startIndex || (this.renderList[0]._index === _index && this.renderList[0]._index > startIndex && this.renderList[0]._index <= endIndex)) {
                  renderRow = visibleTreeList[renderRowIndex];
                  rowspan = endIndex - _index + 1;
                  renderBottomBorder = false;
                }
              }
              
              if (rowspan > 0) {
                if (colspan === 0) {
                  colspan = currentColSpan;
                }
              }

              if (!rowspan || !colspan) {
                if (this.canRenderTd($index, cellIndex)) {
                  return <td style={{ display: 'none' }} />;
                }
                return null;
              }
            } else if (!rowspan || !colspan) {
              if (this.canRenderTd($index, cellIndex)) {
                return <td style={{ display: 'none' }} />;
              }
              return null;
            }
            const columnData = { ...column };
            columnData.realWidth = this.getColspanRealWidth(
              columns,
              colspan,
              cellIndex
            );

            const data = {
              store: this.store,
              _self: this.context || this.table.$vnode.context,
              column: columnData,
              treeDataFirstColumn: cellIndex === firstDefaultColumnIndex && this.treeTable,
              $dataIndex: $dataIndex,
              row: renderRow || row,
              $index
            };
            if (cellIndex === firstDefaultColumnIndex && treeRowData) {
              data.treeNode = {
                indent: treeRowData.level * treeIndent,
                level: treeRowData.level
              };
              if (typeof treeRowData.expanded === 'boolean') {
                data.treeNode.expanded = treeRowData.expanded;
                // 表明是懒加载
                if ('loading' in treeRowData) {
                  data.treeNode.loading = treeRowData.loading;
                }
                if ('noLazyChildren' in treeRowData) {
                  data.treeNode.noLazyChildren = treeRowData.noLazyChildren;
                }
              }
            }
            const cellHandler = {
              row: $dataIndex,
              column: cellIndex,
              handler: () => {
                if (!renderColumData) return '';
                  return column.renderCell.call(
                    this._renderProxy,
                    this.$createElement,
                    data,
                    columnsHidden[cellIndex]
                  );
              }
            };
            if (data && data.column && data.column.popoverHtml) {
              if (this.renderCellList) {
                this.renderCellList.push(cellHandler);
              } else {
                this.renderCellList = [cellHandler];
              }
            }
            return (
              <td
                style={this.getCellStyle($index, cellIndex, row, column)}
                class={this.getCellClass($index, cellIndex, row, column, rowspan, {top: renderTopBorder, bottom: renderBottomBorder})}
                rowspan={rowspan}
                colspan={colspan}
                data-row={$dataIndex}
                data-column={cellIndex}
                on-mouseenter={$event => this.handleCellMouseEnter($event, row)}
                on-mouseleave={this.handleCellMouseLeave}
              >
                {cellHandler.handler()}
              </td>
            );
          })}
        </tr>
      );
    },

    wrappedRowRender(row, $index) {
      const store = this.store;
      const { isRowExpanded, assertRowKey } = store;
      const {
        treeData,
        lazyTreeNodeMap,
        childrenColumnName,
        rowKey
      } = store.states;
      // if (this.virtual) {
      //   console.log('treeData:', treeData);
      // }
      if (this.hasExpandColumn && isRowExpanded(row)) {
        const renderExpanded = this.table.renderExpanded;
        const tr = this.rowRender(row, $index);
        if (!renderExpanded) {
          console.error('[Element Error]renderExpanded is required.');
          return tr;
        }
        // 使用二维数组，避免修改 $index
        return [
          [
            tr,
            <tr key={'expanded-row__' + tr.key}>
              <td colspan={this.columnsCount} class={['yxt-table__expanded-cell', {
                'yxt-table__expanded-cell--nopadding': this.expandContentNoPadding
              }]}>
                {renderExpanded(this.$createElement, {
                  row,
                  $index,
                  store: this.store
                })}
              </td>
            </tr>
          ]
        ];
      } else if (Object.keys(treeData).length) {
        assertRowKey();
        // TreeTable 时，rowKey 必须由用户设定，不使用 getKeyOfRow 计算
        // 在调用 rowRender 函数时，仍然会计算 rowKey，不太好的操作
        const key = getRowIdentity(row, rowKey);
        if (!this.virtual) {
          let cur = treeData[key];
          let treeRowData = null;
          if (cur) {
            treeRowData = {
              expanded: cur.expanded,
              level: cur.level,
              display: true
            };
            if (typeof cur.lazy === 'boolean') {
              if (typeof cur.loaded === 'boolean' && cur.loaded) {
                treeRowData.noLazyChildren = !(
                  cur.children && cur.children.length
                );
              }
              treeRowData.loading = cur.loading;
            }
          }
          const tmp = [this.rowRender(row, $index, treeRowData)];
          // 渲染嵌套数据
          if (cur) {
            // currentRow 记录的是 index，所以还需主动增加 TreeTable 的 index
            let i = 0;
            const traverse = (children, parent) => {
              if (!(children && children.length && parent)) return;
              children.forEach(node => {
                // 父节点的 display 状态影响子节点的显示状态
                const innerTreeRowData = {
                  display: parent.display && parent.expanded,
                  level: parent.level + 1
                };
                const childKey = getRowIdentity(node, rowKey);
                if (childKey === undefined || childKey === null) {
                  throw new Error('for nested data item, row-key is required.');
                }
                cur = { ...treeData[childKey] };
                // 对于当前节点，分成有无子节点两种情况。
                // 如果包含子节点的，设置 expanded 属性。
                // 对于它子节点的 display 属性由它本身的 expanded 与 display 共同决定。
                if (cur) {
                  innerTreeRowData.expanded = cur.expanded;
                  // 懒加载的某些节点，level 未知
                  cur.level = cur.level || innerTreeRowData.level;
                  cur.display = !!(cur.expanded && innerTreeRowData.display);
                  if (typeof cur.lazy === 'boolean') {
                    if (typeof cur.loaded === 'boolean' && cur.loaded) {
                      innerTreeRowData.noLazyChildren = !(
                        cur.children && cur.children.length
                      );
                    }
                    innerTreeRowData.loading = cur.loading;
                  }
                }
                i++;
                tmp.push(this.rowRender(node, $index + i, innerTreeRowData));
                if (cur) {
                  const nodes =
                    lazyTreeNodeMap[childKey] || node[childrenColumnName];
                  traverse(nodes, cur);
                }
              });
            };
            // 对于 root 节点，display 一定为 true
            cur.display = true;
            const nodes = lazyTreeNodeMap[key] || row[childrenColumnName];
            traverse(nodes, cur);
          }
          return tmp;
        } else {
          return this.renderVirtualTree(row, $index);
        }
      } else {
        return this.rowRender(row, $index);
      }
    },
    renderVirtualTree(row, index) {
      const treeData = {
        level: row._level - 1,
        display: true,
        expanded: row._hasChild ? row._expanded : ''
      };
      return this.rowRender(row, index, treeData);
    },
    /* todo */
    // 是否有递归之外的方法来提升效率
    // 判断合并列时是否渲染display为none的td
    canRenderTd(rowIndex, columnIndex) {
      if (rowIndex === 0 || columnIndex > 0) return false;
      for (
        let previousIndex = rowIndex - 1;
        previousIndex >= 0;
        previousIndex--
      ) {
        const tdAbove = this.getSpan(
          this.data[previousIndex],
          this.columns[columnIndex],
          previousIndex,
          columnIndex
        );
        if (!tdAbove.colspan || !tdAbove.rowspan) continue;
        if (tdAbove.rowspan > rowIndex - previousIndex) return true;
        return false;
      }
    }
    // popoverUpdateScrollbar() {
    //   this.$refs.tooltip && this.$refs.tooltip.updateScrollbar();
    // }
  }
};
