var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      class: [
        _vm.isTextarea ? "yxt-textarea" : "yxt-input",
        _vm.inputSize ? "yxt-input--" + _vm.inputSize : "",
        {
          "is-ie": _vm.isIE,
          "is-ie-focus": _vm.isIE && _vm.focused,
          "is-disabled": _vm.inputDisabled,
          "is-disabled-with-value": _vm.inputDisabled && _vm.textLength > 0,
          "is-error": _vm.inputError,
          "is-exceed": _vm.inputExceed,
          "yxt-textarea--world-limit": _vm.isWordLimitVisible,
          "yxt-input-group": _vm.$slots.prepend || _vm.$slots.append,
          "yxt-input-group--append": _vm.$slots.append,
          "yxt-input-group--prepend": _vm.$slots.prepend,
          "yxt-input--prefix":
            _vm.$slots.prefix || _vm.prefixIcon || _vm.searchable,
          "yxt-input--suffix":
            _vm.$slots.suffix ||
            _vm.suffixIcon ||
            _vm.clearable ||
            _vm.showPassword,
        },
      ],
      attrs: { "data-id": _vm.$attrs["data-id"] },
      on: {
        mouseenter: function ($event) {
          _vm.hovering = true
        },
        mouseleave: function ($event) {
          _vm.hovering = false
        },
      },
    },
    [
      !_vm.isTextarea
        ? [
            _vm.$slots.prepend
              ? _c(
                  "div",
                  { staticClass: "yxt-input-group__prepend" },
                  [_vm._t("prepend")],
                  2
                )
              : _vm._e(),
            _c(
              "input",
              _vm._b(
                {
                  ref: "input",
                  staticClass: "yxt-input__inner",
                  class: [
                    { "yxt-input__hover": _vm.isHover },
                    { "yxt-input__inner--primary": _vm.pendType === "primary" },
                    // 'yxt-input__double-icon': showClear && searchable,
                    {
                      "yxt-input__wordlimit":
                        _vm.isWordLimitVisible && _vm.upperLimit < 100,
                    },
                    {
                      "yxt-input__wordlimit-big":
                        _vm.isWordLimitVisible && _vm.upperLimit >= 100,
                    },
                  ],
                  style: _vm.isWordLimitVisible
                    ? "padding-right:" + _vm.paddingRight + "px"
                    : "",
                  attrs: {
                    tabindex: _vm.tabindex,
                    type: _vm.showPassword
                      ? _vm.passwordVisible
                        ? "text"
                        : "password"
                      : _vm.type,
                    disabled: _vm.inputDisabled,
                    readonly: _vm.readonly,
                    autocomplete: _vm.autoComplete || _vm.autocomplete,
                    "aria-label": _vm.label,
                  },
                  on: {
                    compositionstart: _vm.handleCompositionStart,
                    compositionupdate: _vm.handleCompositionUpdate,
                    compositionend: _vm.handleCompositionEnd,
                    input: _vm.handleInput,
                    focus: _vm.handleFocus,
                    blur: _vm.handleBlur,
                    change: _vm.handleChange,
                    keyup: function ($event) {
                      if (
                        !("button" in $event) &&
                        _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                      ) {
                        return null
                      }
                      return _vm.search($event)
                    },
                  },
                },
                "input",
                _vm.attrsNoDataId,
                false
              )
            ),
            _vm.$slots.prefix || _vm.prefixIcon || _vm.searchable
              ? _c(
                  "span",
                  { staticClass: "yxt-input__prefix" },
                  [
                    _vm._t("prefix"),
                    _vm.prefixIcon
                      ? _c("i", {
                          staticClass: "yxt-input__icon",
                          class: _vm.prefixIcon,
                        })
                      : _vm._e(),
                    _vm.searchable
                      ? _c("i", {
                          staticClass: "yxt-input__icon hand yxt-icon-search",
                          on: {
                            click: _vm.search,
                            mouseenter: function ($event) {
                              $event.stopPropagation()
                              $event.preventDefault()
                              _vm.isHover = true
                            },
                            mouseleave: function ($event) {
                              _vm.isHover = false
                            },
                          },
                        })
                      : _vm._e(),
                  ],
                  2
                )
              : _vm._e(),
            _vm.getSuffixVisible()
              ? _c("span", { staticClass: "yxt-input__suffix" }, [
                  _c(
                    "span",
                    { staticClass: "yxt-input__suffix-inner" },
                    [
                      _vm.isWordLimitVisible
                        ? _c(
                            "span",
                            { staticClass: "yxt-input__count" },
                            [
                              _vm._t("textlimit"),
                              !_vm.$slots.textlimit
                                ? _c(
                                    "span",
                                    { staticClass: "yxt-input__count-inner" },
                                    [
                                      _c(
                                        "span",
                                        {
                                          staticClass:
                                            "yxt-input__count-innerleft",
                                          style: {
                                            color:
                                              _vm.textLength > 0
                                                ? "#757575"
                                                : "",
                                          },
                                        },
                                        [_vm._v(_vm._s(_vm.textLength))]
                                      ),
                                      _vm._v(
                                        "/" +
                                          _vm._s(_vm.upperLimit) +
                                          "\n          "
                                      ),
                                    ]
                                  )
                                : _vm._e(),
                              _vm.isWordLimitVisible && this.$slots.suffix
                                ? _c(
                                    "span",
                                    {
                                      staticClass: "yxt-input__wordLimitSplit",
                                    },
                                    [_c("em")]
                                  )
                                : _vm._e(),
                            ],
                            2
                          )
                        : _vm._e(),
                      _vm.showClear
                        ? _c(
                            "span",
                            {
                              staticClass: "yxt-input__clear-wrap",
                              on: {
                                mousedown: function ($event) {
                                  $event.preventDefault()
                                },
                                mouseenter: function ($event) {
                                  $event.stopPropagation()
                                  $event.preventDefault()
                                  _vm.isHover = true
                                },
                                mouseleave: function ($event) {
                                  _vm.isHover = false
                                },
                                click: _vm.clear,
                              },
                            },
                            [
                              _c("i", {
                                staticClass:
                                  "yxt-input__icon yxt-icon-close yxt-input__clear",
                              }),
                            ]
                          )
                        : _vm._e(),
                      !_vm.showClear ||
                      !_vm.showPwdVisible ||
                      !_vm.isWordLimitVisible
                        ? [
                            _vm._t("suffix"),
                            _vm.suffixIcon
                              ? _c("i", {
                                  staticClass: "yxt-input__icon",
                                  class: _vm.suffixIcon,
                                })
                              : _vm._e(),
                          ]
                        : _vm._e(),
                      _vm.showPwdVisible
                        ? _c(
                            "i",
                            {
                              staticClass: "yxt-input__icon yxt-input__pwd",
                              on: { click: _vm.handlePasswordVisible },
                            },
                            [
                              _c("yxt-svg", {
                                attrs: {
                                  width: "16px",
                                  height: "16px",
                                  "icon-class": _vm.viewIcon,
                                },
                              }),
                            ],
                            1
                          )
                        : _vm._e(),
                    ],
                    2
                  ),
                  _vm.validateState
                    ? _c("i", {
                        staticClass: "yxt-input__icon",
                        class: ["yxt-input__validateIcon", _vm.validateIcon],
                      })
                    : _vm._e(),
                ])
              : _vm._e(),
            _vm.$slots.append
              ? _c(
                  "div",
                  {
                    staticClass: "yxt-input-group__append",
                    class: ["yxt-input-group__append--" + _vm.pendType],
                  },
                  [_vm._t("append")],
                  2
                )
              : _vm._e(),
          ]
        : [
            _c(
              "textarea",
              _vm._b(
                {
                  ref: "textarea",
                  class: _vm.isIE
                    ? "yxt-textarea__inner-ie"
                    : "yxt-textarea__inner",
                  style: _vm.textareaStyle,
                  attrs: {
                    spellcheck: "false",
                    tabindex: _vm.tabindex,
                    disabled: _vm.inputDisabled,
                    readonly: _vm.readonly,
                    autocomplete: _vm.autoComplete || _vm.autocomplete,
                    "aria-label": _vm.label,
                  },
                  on: {
                    compositionstart: _vm.handleCompositionStart,
                    compositionupdate: _vm.handleCompositionUpdate,
                    compositionend: _vm.handleCompositionEnd,
                    input: _vm.handleInput,
                    focus: _vm.handleFocus,
                    blur: _vm.handleBlur,
                    change: _vm.handleChange,
                    keydown: _vm.handleKeyDown,
                  },
                },
                "textarea",
                _vm.attrsNoDataId,
                false
              )
            ),
            _vm.isWordLimitVisible
              ? _c(
                  "span",
                  { staticClass: "yxt-input__count" },
                  [
                    _vm._t("textlimit"),
                    !_vm.$slots.textlimit
                      ? _c("span", { staticClass: "yxt-input__count-inner" }, [
                          _c(
                            "span",
                            {
                              staticClass: "yxt-input__count-innerleft",
                              style: {
                                color: _vm.textLength > 0 ? "#757575" : "",
                              },
                            },
                            [_vm._v(_vm._s(_vm.textLength))]
                          ),
                          _vm._v("/" + _vm._s(_vm.upperLimit) + "\n      "),
                        ])
                      : _vm._e(),
                  ],
                  2
                )
              : _vm._e(),
          ],
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }