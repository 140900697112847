var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "yxt-block-header", class: _vm.wrapClass }, [
    _c("div", { staticClass: "yxt-block-content" }, [
      _c(
        "div",
        { staticClass: "yxt-block-title" },
        [
          _vm.$slots.title
            ? _vm._t("title", [_vm._v(_vm._s(_vm.title))])
            : [_vm._v(_vm._s(_vm.title))],
        ],
        2
      ),
    ]),
    _vm.$slots.footer
      ? _c(
          "div",
          { staticClass: "yxt-block-header__suffix" },
          [_vm._t("footer")],
          2
        )
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }