var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "li",
    {
      staticClass: "yxtf-dropdown-menu__item",
      class: {
        "is-disabled": _vm.disabled,
        "yxtf-dropdown-menu__item--divided": _vm.divided,
      },
      attrs: {
        "aria-disabled": _vm.disabled,
        tabindex: _vm.disabled ? null : -1,
      },
      on: { click: _vm.handleClick },
    },
    [_vm.icon ? _c("i", { class: _vm.icon }) : _vm._e(), _vm._t("default")],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }