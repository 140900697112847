<template>
  <yxt-input
    class="yxt-date-editor"
    :class="['yxt-date-editor--' + type, {'yxt-date-editor--placeholder': isPlaceholder}]"
    :readonly="!editable || readonly || type === 'dates' || type === 'week'"
    :disabled="pickerDisabled"
    :size="pickerSize"
    :name="name"
    v-bind="firstInputId"
    v-if="!ranged"
    v-clickoutside="handleClose"
    :placeholder="placeholder"
    @focus="handleFocus"
    @keydown.native="handleKeydown"
    @mouseenter.native="handleMouseEnter"
    @mouseleave.native="showClose = false"
    :value="displayValue"
    @input="value => userInput = value"
    @change="handleChange"  
    :validateEvent="false"
    ref="reference">
      <span class="yxt-date-time__suffix" slot="suffix">
      <yxt-svg class="hand" v-if="!showClose" width="16px" height="16px" @click.native="handleFocus" icon-class="time"></yxt-svg>
      <yxt-svg
          @click.native.stop="handleClickIcon"
          v-if="haveTrigger"
          icon-class="close"
          width="16px"
          height="16px"
          v-show="showClose"
          class="yxt-input__icon yxt-time__close hand"></yxt-svg>
    </span>
  </yxt-input>
  <div
    class="yxt-date-editor yxt-range-editor yxt-input__inner"
    :class="[
      'yxt-date-editor--' + type,
      pickerSize ? `yxt-range-editor--${ pickerSize }` : '',
      pickerDisabled ? 'is-disabled' : '',
      pickerVisible ? 'is-active' : '',
      isFocus ? 'is-focus' : ''
    ]"
    @keydown="handleKeydown"
    @mouseenter="handleMouseEnter"
    @mouseleave="showClose = false" 
    @click="handleFocus(true)"
    ref="reference"
    v-clickoutside="handleClose"
    v-else>
    <input
      autocomplete="off"
      :placeholder="startPlaceholder"
      :value="displayValue && displayValue[0]"
      :disabled="pickerDisabled"
      v-bind="firstInputId"
      :class="{'yxt-date-editor--placeholder': isPlaceholder && isStart}"
      :readonly="!editable || readonly"
      :name="name && name[0]"
      @input="handleStartInput"
      @change="handleStartChange"
      @focus="handleFocus(true)"
      @blur="handleBlur"
      @click.stop
      class="yxt-range-input">
    <slot name="range-separator">
      <span class="yxt-range-separator">{{ inputRangeSeparator }}</span>
    </slot>
    <input
      autocomplete="off"
      :class="{'yxt-date-editor--placeholder': isPlaceholder && !isStart}"
      :placeholder="endPlaceholder"
      :value="displayValue && displayValue[1]"
      :disabled="pickerDisabled"
      v-bind="secondInputId"
      :readonly="!editable || readonly"
      :name="name && name[1]"
      @input="handleEndInput"
      @change="handleEndChange"
      @focus="handleFocus()"
      @click.stop
      @blur="handleBlur"
      class="yxt-range-input">
    <span class="yxt-input__suffix">
      <span class="yxt-input__suffix-inner">
        <yxt-svg v-if="!showClose" width="16px" height="16px" icon-class="time"></yxt-svg>
        <yxt-svg
          @click.native.stop="handleClickIcon"
          v-if="haveTrigger"
          icon-class="close"
          width="16px"
          height="16px"
          v-show="showClose"
          class="yxt-input__icon yxt-time-range__close hand"></yxt-svg>
        <!-- <i
        @click="handleClickIcon"
        v-if="haveTrigger"
        v-show="showClose"
        :class="[showClose ? 'yxt-input__clear ' + clearIcon : '']"
        class="yxt-input__icon yxt-time-range__close">
      </i> -->
      </span>
    </span>
  </div>
</template>

<script>
import Vue from 'vue';
import Clickoutside from '@utils/utils/clickoutside';
import { formatDate, formatTime, parseDate, isDateObject, getWeekNumber } from '@utils/utils/date-util';
import Popper from '@utils/utils/vue-popper';
import Emitter from '@utils/mixins/emitter';
import ElInput from '@backstage/input';
import merge from '@utils/utils/merge';
import { t } from '@utils/locale';

const NewPopper = {
  props: {
    appendToBody: {...Popper.props.appendToBody, desc: '是否插入至 body 元素上'},
    offset: {...Popper.props.offset, desc: '出现位置的偏移量'},
    boundariesPadding: {...Popper.props.boundariesPadding, desc: '边界填充'},
    arrowOffset: {...Popper.props.arrowOffset, desc: '箭头偏移量'}
  },
  methods: Popper.methods,
  data() {
    return merge({ visibleArrow: false }, Popper.data);
  },
  beforeDestroy: Popper.beforeDestroy
};

const DEFAULT_FORMATS = {
  date: 'yyyy-MM-dd',
  month: 'yyyy-MM',
  quarter: 'yyyy-QQ',
  datetime: 'yyyy-MM-dd HH:mm:ss',
  time: 'HH:mm:ss',
  week: 'yyyywWW',
  timerange: 'HH:mm:ss',
  daterange: 'yyyy-MM-dd',
  monthrange: 'yyyy-MM',
  datetimerange: 'yyyy-MM-dd HH:mm:ss',
  year: 'yyyy'
};
const HAVE_TRIGGER_TYPES = [
  'date',
  'datetime',
  'time',
  'time-select',
  'time-select-range',
  'week',
  'month',
  'quarter',
  'year',
  'daterange',
  'monthrange',
  'timerange',
  'datetimerange',
  'dates'
];
const DATE_FORMATTER = function(value, format) {
  if (format === 'timestamp') return value.getTime();
  return formatDate(value, format);
};
const DATE_PARSER = function(text, format) {
  if (format === 'timestamp') return new Date(Number(text));
  return parseDate(text, format);
};
const RANGE_FORMATTER = function(value, format) {
  if (Array.isArray(value) && value.length === 2) {
    const start = value[0];
    const end = value[1];

    if (start && end) {
      return [DATE_FORMATTER(start, format), DATE_FORMATTER(end, format)];
    }

    if (start) {
      return [DATE_FORMATTER(start, format), ''];
    }

    if (end) {
      return ['', DATE_FORMATTER(end, format)];
    }
  }
  return '';
};

const TIME_FORMATTER = function(value) {
  return formatTime(value);
};

const TIME_RANGE_FORMATTER = function(value) {
  if (Array.isArray(value) && value.length === 2) {
    const start = value[0];
    const end = value[1];

    if (start && end) {
      return [formatTime(start), formatTime(end)];
    }

    if (start) {
      return [formatTime(start), ''];
    }

    if (end) {
      return ['', formatTime(end)];
    }
  }
  return '';
};

const TIME_RANGE_PARSER = function(array, format, separator) {
  if (!array) return [];
  if (!Array.isArray(array)) {
    array = array.split(separator);
  }
  if (array.length === 2) {
    const range1 = array[0];
    const range2 = array[1];
    return [formatTime(range1), formatTime(range2)];
  }
  return [];
};

const RANGE_PARSER = function(array, format, separator) {
  if (!array) return [];
  if (!Array.isArray(array)) {
    array = array.split(separator);
  }
  if (array.length === 2) {
    const range1 = array[0];
    const range2 = array[1];

    return [DATE_PARSER(range1, format), DATE_PARSER(range2, format)];
  }
  return [];
};
const TYPE_VALUE_RESOLVER_MAP = {
  default: {
    formatter(value) {
      if (!value) return '';
      return '' + value;
    },
    parser(text) {
      if (text === undefined || text === '') return null;
      return text;
    }
  },
  week: {
    formatter(value, format) {
      let week = getWeekNumber(value);
      let month = value.getMonth();
      const trueDate = new Date(value);
      if (week === 1 && month === 11) {
        trueDate.setHours(0, 0, 0, 0);
        trueDate.setDate(trueDate.getDate() + 3 - (trueDate.getDay() + 6) % 7);
      }
      let date = formatDate(trueDate, format);

      date = /WW/.test(date)
        ? date.replace(/WW/, week < 10 ? '0' + week : week)
        : date.replace(/W/, week);
      return date;
    },
    parser(text, format) {
      // parse as if a normal date
      return TYPE_VALUE_RESOLVER_MAP.date.parser(text, format);
    }
  },
  quarter: {
    formatter(value, format) {
      const year = value.getFullYear();
      const quarter = Math.floor(value.getMonth() / 3);
      return `${year}-Q${quarter + 1}`;
    },
    parser(text, format) {
      if (text && text.length === 7) {
        const [year, quarterStr] = text.split('-');
        if (/^\d{4}$/.test(year) && /^Q\d$/.test(quarterStr)) {
          const quarter = quarterStr.slice(1);
          return new Date(Number(year), Number((quarter - 1) * 3), 1);
        }
      }
      return null;
    }
  },
  date: {
    formatter: DATE_FORMATTER,
    parser: DATE_PARSER
  },
  datetime: {
    formatter: DATE_FORMATTER,
    parser: DATE_PARSER
  },
  daterange: {
    formatter: RANGE_FORMATTER,
    parser: RANGE_PARSER
  },
  monthrange: {
    formatter: RANGE_FORMATTER,
    parser: RANGE_PARSER
  },
  datetimerange: {
    formatter: RANGE_FORMATTER,
    parser: RANGE_PARSER
  },
  timerange: {
    formatter: RANGE_FORMATTER,
    parser: RANGE_PARSER
  },
  time: {
    formatter: DATE_FORMATTER,
    parser: DATE_PARSER
  },
  month: {
    formatter: DATE_FORMATTER,
    parser: DATE_PARSER
  },
  year: {
    formatter: DATE_FORMATTER,
    parser: DATE_PARSER
  },
  'time-select': {
    formatter: TIME_FORMATTER,
    parser(text) {
      if (text === undefined || text === '') return null;
      return TIME_FORMATTER(text);
    }
  },
  'time-select-range': {
    formatter: TIME_RANGE_FORMATTER,
    parser: TIME_RANGE_PARSER
  },
  number: {
    formatter(value) {
      if (!value) return '';
      return '' + value;
    },
    parser(text) {
      let result = Number(text);

      if (!isNaN(text)) {
        return result;
      } else {
        return null;
      }
    }
  },
  dates: {
    formatter(value, format) {
      return value.map(date => DATE_FORMATTER(date, format));
    },
    parser(value, format) {
      return (typeof value === 'string' ? value.split(', ') : value)
        .map(date => date instanceof Date ? date : DATE_PARSER(date, format));
    }
  }
};
const PLACEMENT_MAP = {
  left: 'bottom-start',
  center: 'bottom',
  right: 'bottom-end'
};

const parseAsFormatAndType = (value, customFormat, type, rangeSeparator = '-') => {
  if (!value) return null;
  const parser = (
    TYPE_VALUE_RESOLVER_MAP[type] ||
    TYPE_VALUE_RESOLVER_MAP['default']
  ).parser;
  const format = customFormat || DEFAULT_FORMATS[type];
  return parser(value, format, rangeSeparator);
};

const formatAsFormatAndType = (value, customFormat, type) => {
  if (!value) return null;
  const formatter = (
    TYPE_VALUE_RESOLVER_MAP[type] ||
    TYPE_VALUE_RESOLVER_MAP['default']
  ).formatter;
  const format = customFormat || DEFAULT_FORMATS[type];
  return formatter(value, format);
};

/*
 * Considers:
 *   1. Date object
 *   2. date string
 *   3. array of 1 or 2
 */
const valueEquals = function(a, b) {
  // considers Date object and string
  const dateEquals = function(a, b) {
    const aIsDate = a instanceof Date;
    const bIsDate = b instanceof Date;
    if (aIsDate && bIsDate) {
      return a.getTime() === b.getTime();
    }
    if (!aIsDate && !bIsDate) {
      return a === b;
    }
    return false;
  };

  const aIsArray = a instanceof Array;
  const bIsArray = b instanceof Array;
  if (aIsArray && bIsArray) {
    if (a.length !== b.length) {
      return false;
    }
    return a.every((item, index) => dateEquals(item, b[index]));
  }
  if (!aIsArray && !bIsArray) {
    return dateEquals(a, b);
  }
  return false;
};

const isString = function(val) {
  return typeof val === 'string' || val instanceof String;
};

const validator = function(val) {
  // either: String, Array of String, null / undefined
  return (
    val === null ||
    val === undefined ||
    isString(val) ||
    (Array.isArray(val) && val.length === 2 && val.every(isString))
  );
};

export default {
  mixins: [Emitter, NewPopper],

  inject: {
    yxtform: {
      default: ''
    },
    yxtformItem: {
      default: ''
    }
  },

  props: {
    showMomentBtn: {
      type: Boolean,
      desc: '是否显示此刻按钮(在type为datetime时生效)'
    },
    size: {
      type: String,
      desc: '尺寸'
    },
    format: {
      type: String,
      desc: '显示在输入框中的格式'
    },
    valueFormat: {
      type: String,
      desc: '绑定值的格式。不指定则绑定值为 Date 对象'
    },
    readonly: {
      type: Boolean,
      desc: '是否只读'
    },
    placeholder: {
      type: String,
      desc: '占位内容'
    },
    startPlaceholder: {
      type: String,
      default: () => (t('pc_comp_datepicker_startDate')),
      desc: '开始日期的占位内容'
    },
    endPlaceholder: {
      type: String,
      default: () => (t('pc_comp_datepicker_endDate')),
      desc: '结束日期的占位内容'
    },
    prefixIcon: {
      type: String,
      desc: '自定义头部图标的类名'
    },
    clearIcon: {
      type: String,
      default: 'yxt-icon-error',
      desc: '自定义清空图标的类名'
    },
    name: {
      default: '',
      desc: '原生name属性',
      validator
    },
    disabled: {
      type: Boolean,
      desc: '是否禁用'
    },
    clearable: {
      type: Boolean,
      default: true,
      desc: '显示清除按钮'
    },
    id: {
      default: '',
      validator
    },
    popperClass: {
      type: String,
      desc: '下拉框的类名(DateTimePicker)'
    },
    editable: {
      type: Boolean,
      default: true,
      desc: '文本框可输入'
    },
    align: {
      type: String,
      default: 'left',
      desc: '对齐方式'
    },
    value: {
      desc: '绑定值'
    },
    defaultValue: {
      desc: '选择器打开时默认显示的时间'
    },
    defaultTime: {
      desc: '选中日期后的默认具体时刻'
    },
    rangeSeparator: {
      default: '-',
      desc: '选择范围时的分隔符'
    },
    inputRangeSeparator: {
      default: '~',
      desc: '输入框中显示的分隔符'
    },
    pickerOptions: {
      desc: '当前时间日期选择器特有的选项'
    },
    unlinkPanels: {
      type: Boolean,
      desc: '在范围选择器里取消两个日期面板之间的联动'
    },
    validateEvent: {
      type: Boolean,
      default: true,
      desc: '输入时是否触发表单的校验'
    },
    isFixed: {
      type: Boolean,
      default: true,
      desc: '是否固定'
    }
  },

  components: { ElInput },

  directives: { Clickoutside },

  data() {
    return {
      pickerVisible: false,
      isStart: true,
      showClose: false,
      userInput: null,
      valueOnOpen: null, // value when picker opens, used to determine whether to emit change
      unwatchPickerOptions: null,
      isFocus: false,
      isPlaceholder: false,
      placeholderInput: ''
    };
  },

  watch: {
    pickerVisible(val) {
      if (this.readonly || this.pickerDisabled) return;
      if (val) {
        this.showPicker();
        this.valueOnOpen = Array.isArray(this.value) ? [...this.value] : this.value;
      } else {
        this.hidePicker();
        this.emitChange(this.value);
        this.userInput = null;
        if (this.validateEvent) {
          this.dispatch('YxtFormItem', 'yxt.form.blur');
        }
        // TODO 校验日期合法性
        this.$emit('blur', this);
        this.blur();
      }
    },
    parsedValue: {
      immediate: true,
      handler(val) {
        if (this.picker) {
          this.picker.value = val;
        }
      }
    },
    defaultValue(val) {
      // NOTE: should eventually move to jsx style picker + panel ?
      if (this.picker) {
        this.picker.defaultValue = val;
      }
    },
    value(val, oldVal) {
      if (!valueEquals(val, oldVal) && !this.pickerVisible && this.validateEvent) {
        this.dispatch('YxtFormItem', 'yxt.form.change', val);
      }
    }
  },

  computed: {
    ranged() {
      return this.type.indexOf('range') > -1;
    },

    reference() {
      const reference = this.$refs.reference;
      return reference.$el || reference;
    },

    refInput() {
      if (this.reference) {
        return [].slice.call(this.reference.querySelectorAll('input'));
      }
      return [];
    },

    valueIsEmpty() {
      const val = this.value;
      if (Array.isArray(val)) {
        for (let i = 0, len = val.length; i < len; i++) {
          if (val[i]) {
            return false;
          }
        }
      } else {
        if (val) {
          return false;
        }
      }
      return true;
    },

    triggerClass() {
      return this.prefixIcon || (this.type.indexOf('time') !== -1 ? 'yxt-icon-time' : 'yxt-icon-date');
    },

    selectionMode() {
      if (this.type === 'week') {
        return 'week';
      } else if (this.type === 'month') {
        return 'month';
      } else if (this.type === 'quarter') {
        return 'quarter';
      } else if (this.type === 'year') {
        return 'year';
      } else if (this.type === 'dates') {
        return 'dates';
      }

      return 'day';
    },

    haveTrigger() {
      if (typeof this.showTrigger !== 'undefined') {
        return this.showTrigger;
      }
      return HAVE_TRIGGER_TYPES.indexOf(this.type) !== -1;
    },

    displayValue() {
      const formattedValue = formatAsFormatAndType(this.parsedValue, this.format, this.type, this.rangeSeparator);
      const input = this.isPlaceholder ? this.formatToString(this.placeholderInput) : this.userInput;
      if (Array.isArray(input)) {
        return [
          input[0] || (!this.isStart && formattedValue && formattedValue[0]) || '',
          input[1] || (this.isStart && formattedValue && formattedValue[1]) || ''
        ];

      } else if (input !== null) {
        return input;
      } else if (formattedValue) {
        return this.type === 'dates'
          ? formattedValue.join(', ')
          : formattedValue;
      } else {
        return '';
      }
    },

    parsedValue() {
      if (!this.value) return this.value; // component value is not set
      if (this.type === 'time-select' || this.type === 'time-select-range') return this.value; // time-select does not require parsing, this might change in next major version

      if (this.type === 'quarter' && /^\d{4}-Q\d$/.test(this.value)) {
        return TYPE_VALUE_RESOLVER_MAP[this.type].parser(this.value);
      }

      const valueIsDateObject = isDateObject(this.value) || (Array.isArray(this.value) && this.value.every(d => {
        return isDateObject(d) || d === null || d === '';
      }));
      if (valueIsDateObject) {
        return this.value;
      }

      if (this.valueFormat) {
        return parseAsFormatAndType(this.value, this.valueFormat, this.type, this.rangeSeparator) || this.value;
      }

      // NOTE: deal with common but incorrect usage, should remove in next major version
      // user might provide string / timestamp without value-format, coerce them into date (or array of date)
      return Array.isArray(this.value) ? this.value.map(val => new Date(val)) : new Date(this.value);
    },

    _yxtformItemSize() {
      return (this.yxtformItem || {}).yxtformItemSize;
    },

    pickerSize() {
      return this.size || this._yxtformItemSize || (this.$ELEMENT || {}).size;
    },

    pickerDisabled() {
      return this.disabled || (this.yxtform || {}).disabled;
    },

    firstInputId() {
      const obj = {};
      let id;
      if (this.ranged) {
        id = this.id && this.id[0];
      } else {
        id = this.id;
      }
      if (id) obj.id = id;
      return obj;
    },

    secondInputId() {
      const obj = {};
      let id;
      if (this.ranged) {
        id = this.id && this.id[1];
      }
      if (id) obj.id = id;
      return obj;
    }
  },

  created() {
    // vue-popper
    this.popperOptions = {
      boundariesPadding: 0,
      gpuAcceleration: false
    };
    this.placement = PLACEMENT_MAP[this.align] || PLACEMENT_MAP.left;

    this.$on('fieldReset', this.handleFieldReset);
  },

  methods: {
    focus() {
      if (!this.ranged) {
        this.$refs.reference.focus();
      } else {
        this.handleFocus();
      }
    },

    blur() {
      this.refInput.forEach(input => input.blur());
    },

    // {parse, formatTo} Value deals maps component value with internal Date
    parseValue(value) {
      const isParsed = isDateObject(value) || (Array.isArray(value) && value.every(isDateObject));
      if (this.valueFormat && !isParsed) {
        return parseAsFormatAndType(value, this.valueFormat, this.type, this.rangeSeparator) || value;
      } else {
        return value;
      }
    },

    formatToValue(date) {
      const isFormattable = isDateObject(date) || (Array.isArray(date) && date.every(d => {
        return isDateObject(d) || d === null || d === '';
      }));
      if (this.valueFormat && isFormattable) {
        return formatAsFormatAndType(date, this.valueFormat, this.type, this.rangeSeparator);
      } else {
        return date;
      }
    },

    // {parse, formatTo} String deals with user input
    parseString(value) {
      const type = Array.isArray(value) ? this.type : this.type.replace('range', '');
      return parseAsFormatAndType(value, this.format, type);
    },

    formatToString(value) {
      const type = Array.isArray(value) ? this.type : this.type.replace('range', '');
      return formatAsFormatAndType(value, this.format, type);
    },

    handleMouseEnter() {
      if (this.readonly || this.pickerDisabled) return;
      if (!this.valueIsEmpty && this.clearable) {
        this.showClose = true;
      }
    },

    handleChange() {
      if (this.userInput) {
        let value = this.parseString(this.displayValue);
        if (this.picker && this.picker.getNear) {
          value = this.picker.getNear(value);
        }

        if (value) {
          this.picker.value = value;
          if (this.isValidValue(value)) {
            this.emitInput(value);
            this.userInput = null;
          }
        }
      }
      if (this.userInput === '') {
        this.emitInput(null);
        this.emitChange(null);
        this.userInput = null;
      }
    },

    handleStartInput(event) {
      if (this.userInput) {
        this.userInput = [event.target.value, this.userInput[1]];
      } else {
        this.userInput = [event.target.value, null];
      }
    },

    handleEndInput(event) {
      if (this.userInput) {
        this.userInput = [this.userInput[0], event.target.value];
      } else {
        this.userInput = [null, event.target.value];
      }
    },

    handleStartChange(event) {
      const value = this.parseString(this.userInput && this.userInput[0]);
      if (value) {
        this.userInput = [this.formatToString(value), this.displayValue[1]];
        const newValue = [value, this.picker.value && this.picker.value[1]];
        this.picker.value = newValue;
        if (this.isValidValue(newValue)) {
          this.emitInput(newValue);
          this.userInput = null;
        }
      }
    },

    handleEndChange(event) {
      const value = this.parseString(this.userInput && this.userInput[1]);
      if (value) {
        this.userInput = [this.displayValue[0], this.formatToString(value)];
        const newValue = [this.picker.value && this.picker.value[0], value];
        this.picker.value = newValue;
        if (this.isValidValue(newValue)) {
          this.emitInput(newValue);
          this.userInput = null;
        }
      }
    },

    handleClickIcon(event) {
      if (this.readonly || this.pickerDisabled) return;
      if (this.showClose) {
        this.valueOnOpen = this.value;
        event.stopPropagation();
        this.emitInput(null);
        this.emitChange(null);
        this.showClose = false;
        if (this.picker && typeof this.picker.handleClear === 'function') {
          this.picker.handleClear();
        }
      } else {
        this.pickerVisible = !this.pickerVisible;
      }
    },

    handleClose() {
      this.isPlaceholder = false;
      if (!this.pickerVisible) return;
      this.pickerVisible = false;

      if (this.type === 'timerange' || this.type === 'time-select' || this.type === 'time-select-range') {
        const oldValue = this.parseValue(this.valueOnOpen);
        this.emitInput(oldValue);
      }

      this.$emit('close');
    },

    handleFieldReset(initialValue) {
      this.userInput = initialValue === '' ? null : initialValue;
    },

    handleFocus(isStart) {
      const type = this.type;
      this.isFocus = true;
      this.isStart = !!isStart;
      if (this.ranged && this.picker) {
        this.picker.isStart = !!isStart;
        this.$nextTick(() => {
          this.picker.adjustSpinners && this.picker.adjustSpinners();
        });
      }

      if (HAVE_TRIGGER_TYPES.indexOf(type) !== -1 && !this.pickerVisible) {
        this.pickerVisible = true;
      }
      this.$emit('focus', this);
    },

    handleBlur() {
      this.isFocus = false;
    },

    handleKeydown(event) {
      const keyCode = event.keyCode;

      // ESC
      if (keyCode === 27) {
        this.pickerVisible = false;
        event.stopPropagation();
        return;
      }

      // Tab
      if (keyCode === 9) {
        if (!this.ranged) {
          this.handleChange();
          this.pickerVisible = this.picker.visible = false;
          this.blur();
          event.stopPropagation();
        } else {
          // user may change focus between two input
          setTimeout(() => {
            if (this.refInput.indexOf(document.activeElement) === -1) {
              this.pickerVisible = false;
              this.blur();
              event.stopPropagation();
            }
          }, 0);
        }
        return;
      }

      // Enter
      if (keyCode === 13) {
        if (this.userInput === '' || this.isValidValue(this.parseString(this.displayValue))) {
          this.handleChange();
          this.pickerVisible = this.picker.visible = false;
          this.blur();
        }
        event.stopPropagation();
        return;
      }

      // if user is typing, do not let picker handle key input
      if (this.userInput) {
        event.stopPropagation();
        return;
      }

      // delegate other keys to panel
      if (this.picker && this.picker.handleKeydown) {
        this.picker.handleKeydown(event);
      }
    },

    hidePicker() {
      if (this.picker) {
        this.picker.resetView && this.picker.resetView();
        this.pickerVisible = this.picker.visible = false;
        this.destroyPopper();
      }
    },

    showPicker() {
      if (this.$isServer) return;
      if (!this.picker) {
        this.mountPicker();
      }
      this.pickerVisible = this.picker.visible = true;
      if (this.ranged && this.picker) {
        this.picker.isStart = this.isStart;
      }

      this.updatePopper();

      this.picker.value = this.parsedValue;
      this.picker.resetView && this.picker.resetView();

      this.$nextTick(() => {
        this.picker.adjustSpinners && this.picker.adjustSpinners();
      });
    },

    mountPicker() {
      this.picker = new Vue(this.panel).$mount();
      this.picker.showMomentBtn = this.showMomentBtn || false;
      this.picker.defaultValue = this.defaultValue;
      this.picker.defaultTime = this.defaultTime;
      this.picker.popperClass = this.popperClass;
      this.popperElm = this.picker.$el;
      this.picker.width = this.reference.getBoundingClientRect().width;
      this.picker.showTime = this.type === 'datetime' || this.type === 'datetimerange';
      this.picker.selectionMode = this.selectionMode;
      this.picker.unlinkPanels = this.unlinkPanels;
      this.picker.arrowControl = this.arrowControl || this.timeArrowControl || false;
      this.$watch('format', (format) => {
        this.picker.format = format;
      });

      const updateOptions = () => {
        const options = this.pickerOptions;

        if (options && options.selectableRange) {
          let ranges = options.selectableRange;
          const parser = TYPE_VALUE_RESOLVER_MAP.datetimerange.parser;
          const format = DEFAULT_FORMATS.timerange;
          ranges = Array.isArray(ranges) ? ranges : [ranges];
          this.picker.selectableRange = ranges.map(range => parser(range, format, this.rangeSeparator));
        }

        for (const option in options) {
          if (options.hasOwnProperty(option) &&
              // 忽略 time-picker 的该配置项
              option !== 'selectableRange') {
            this.picker[option] = options[option];
          }
        }

        // main format must prevail over undocumented pickerOptions.format
        if (this.format) {
          this.picker.format = this.format;
        }
      };
      updateOptions();
      this.unwatchPickerOptions = this.$watch('pickerOptions', () => updateOptions(), { deep: true });
      this.$el.appendChild(this.picker.$el);
      this.picker.resetView && this.picker.resetView();

      this.picker.$on('dodestroy', this.doDestroy);
      this.picker.$on('pick', (date = '', visible = false) => {
        this.isPlaceholder = false;
        this.userInput = null;
        this.pickerVisible = this.picker.visible = visible;
        this.emitInput(date);
        this.picker.resetView && this.picker.resetView();
      });

      this.picker.$on('placeholder', (value) => {
        // 强制清除数据，触发渲染
        this.placeholderInput = null;
        if (!this.pickerVisible) {
          this.isPlaceholder = false;
          return;
        }
        if (!value) {
          this.isPlaceholder = false;
          return;
        }
        this.isPlaceholder = true;
        if (this.ranged) {
          this.placeholderInput = this.isStart ? [value, this.userInput ? this.userInput[1] : ''] : [this.userInput ? this.userInput[0] : '', value];
        } else {
          this.placeholderInput = value;
        }
      });

      this.picker.$on('select-range', (start, end, pos) => {
        if (this.refInput.length === 0) return;
        if (!pos || pos === 'min') {
          this.refInput[0].setSelectionRange(start, end);
          this.refInput[0].focus();
        } else if (pos === 'max') {
          this.refInput[1].setSelectionRange(start, end);
          this.refInput[1].focus();
        }
      });
    },

    unmountPicker() {
      if (this.picker) {
        this.picker.$destroy();
        this.picker.$off();
        if (typeof this.unwatchPickerOptions === 'function') {
          this.unwatchPickerOptions();
        }
        this.picker.$el.parentNode.removeChild(this.picker.$el);
      }
    },

    emitChange(val) {
      // determine user real change only
      if (!valueEquals(val, this.valueOnOpen)) {
        this.$emit('change', val);
        this.valueOnOpen = val;
        if (this.validateEvent) {
          this.dispatch('YxtFormItem', 'yxt.form.change', val);
        }
      }
    },

    emitInput(val) {
      const formatted = this.formatToValue(val);
      if (!valueEquals(this.value, formatted)) {
        this.$emit('input', formatted);
      }
    },

    isValidValue(value) {
      if (!this.picker) {
        this.mountPicker();
      }
      if (this.picker.isValidValue) {
        return value && this.picker.isValidValue(value);
      } else {
        return true;
      }
    }
  }
};
</script>
