var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "transition",
    {
      attrs: { name: "yxt-message-fade" },
      on: { "after-leave": _vm.handleAfterLeave },
    },
    [
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.visible,
              expression: "visible",
            },
          ],
          class: [
            "yxt-message",
            _vm.center ? "is-center" : "",
            _vm.showClose ? "is-closable" : "",
            _vm.showRepeal ? "is-repeal" : "",
            _vm.customClass,
          ],
          style: _vm.positionStyle,
          attrs: { role: "alert", isSingle: _vm.isSingle },
          on: { mouseenter: _vm.clearTimer, mouseleave: _vm.startTimer },
        },
        [
          _c(
            "div",
            { staticClass: "yxt-message__svg" },
            [
              _c("yxt-svg", {
                staticClass: "color-gray-6 hover-primary-6",
                attrs: {
                  "remote-url": "",
                  width: "16px",
                  height: "16px",
                  "icon-class": "message-" + _vm.type,
                },
              }),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "yxt-message__ctx" },
            [
              _vm._t("default", [
                !_vm.dangerouslyUseHTMLString
                  ? _c("p", { staticClass: "yxt-message__content" }, [
                      _vm._v(_vm._s(_vm.message)),
                    ])
                  : _c("p", {
                      staticClass: "yxt-message__content",
                      domProps: { innerHTML: _vm._s(_vm.message) },
                    }),
              ]),
              _vm.showClose
                ? _c(
                    "span",
                    {
                      staticClass: "yxt-message__closeBtn",
                      on: { click: _vm.close },
                    },
                    [_vm._v(_vm._s(_vm.t("pc_comp_message_iknow")))]
                  )
                : _vm._e(),
              _vm.showRepeal
                ? [
                    _c(
                      "span",
                      {
                        staticClass: "yxt-message__repeal",
                        on: { click: _vm.repeal },
                      },
                      [_vm._v(_vm._s(_vm.t("pc_comp_message_repeal")))]
                    ),
                    _c("i", {
                      staticClass: "yxt-message__closeIcon yxt-icon-close",
                      on: { click: _vm.close },
                    }),
                  ]
                : _vm._e(),
            ],
            2
          ),
        ]
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }