export default {
  data() {
    return {
      range: {
        start: 0,
        end: 0
      },
      keeps: 40,
      buffer: 10,
      rowHeight: 56,
      savedOffset: 0
    };
  },
  methods: {
    getPadFront() {
      return this.rowHeight * this.range.start;
    },
    getPadBehind() {
      const end = this.range.end;
      const lastIndex = this.getLastIndex();
      return (lastIndex - end) * this.rowHeight;
    },
    getEndByStart(start) {
      const lastIndex = this.getLastIndex();
      const end =
      start + this.keeps - 1 >= lastIndex
        ? lastIndex
        : start + this.keeps - 1;

      return end;
    },
    caculate(isFront, offset) {
      // 重新计算需要渲染的列表区间
      const overs = this.getScrollOvers(offset);
      let start = overs;
      if (isFront) {
        if (overs > this.range.start) {
          return;
        }
        start = Math.max(overs - this.buffer, 0);
      } else if (overs < this.range.start + this.buffer) {
        return;
      }
      this.checkRange(start, this.getEndByStart(start));
    },
    resetRange(start = 0) {
      if (!this.data || this.data.length === 0) {
        this.range = {
          start: 0,
          end: 0
        };
        return;
      }
      this.checkRange(start, this.getEndByStart(start));
    },
    checkRange(start, end) {
      const keeps = this.keeps;
      const total = this.data.length;
      if (total <= keeps) {
        start = 0;
        end = this.getLastIndex();
      } else if (end - start < keeps - 1) {
        start = end - keeps + 1;
      }
      if (this.range.start !== start || this.range.end !== end) {
        this.range = {
          start,
          end
        };
      }
    },
    getLastIndex() {
      return Math.max(this.data.length - 1, 0);
    },
    getScrollOvers(offset) {
      if (offset <= 0) return 0;
      return Math.floor(offset / this.rowHeight);
    }
  }
};
