import Vue from 'vue';

export function setAnimation(handler, time) {
  const start = +new Date();
  const requestAnimFrame = (function() {
    return window.requestAnimationFrame ||
            window.webkitRequestAnimationFrame ||
            window.mozRequestAnimationFrame ||
            function(callback) {
              window.setTimeout(callback, 1000 / 60);
            };
  })();
  handler();
  requestAnimFrame(() => loopAnimation(handler, time, start, requestAnimFrame));
}

function loopAnimation(handler, time, start, helper) {
  const t = +new Date();
  handler();
  if ((t - start) <= time) {
    helper(() => loopAnimation(handler, time, start, helper));
  }
}

//  获取元素距离文档顶部和左侧的距离
export function getPageOffset(node) {
  if (!node) return false;
  let result = {
    left: node.offsetLeft,
    top: node.offsetTop
  };

  while (node.offsetParent) {
    node = node.offsetParent;
    result.left += node.offsetLeft;
    result.top += node.offsetTop;
  }

  return result;
}

export default new Vue();
