var render = function () {
  var this$1 = this
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "show",
          rawName: "v-show",
          value: _vm.node.visible,
          expression: "node.visible",
        },
      ],
      ref: "node",
      staticClass: "yxt-tree-node",
      class: {
        "is-expanded": _vm.expanded,
        "is-current": _vm.node.isCurrent,
        "is-hidden": !_vm.node.visible,
        "is-focusable": !_vm.node.disabled,
        "is-checked": !_vm.node.disabled && _vm.node.checked,
      },
      attrs: {
        role: "treeitem",
        tabindex: "-1",
        "aria-expanded": _vm.expanded,
        "aria-disabled": _vm.node.disabled,
        "aria-checked": _vm.node.checked,
        draggable: _vm.tree.draggable,
      },
      on: {
        click: function ($event) {
          $event.stopPropagation()
          return _vm.handleClick($event)
        },
        contextmenu: function ($event) {
          return this$1.handleContextMenu($event)
        },
        dragstart: function ($event) {
          $event.stopPropagation()
          return _vm.handleDragStart($event)
        },
        dragover: function ($event) {
          $event.stopPropagation()
          return _vm.handleDragOver($event)
        },
        dragend: function ($event) {
          $event.stopPropagation()
          return _vm.handleDragEnd($event)
        },
        drop: function ($event) {
          $event.stopPropagation()
          return _vm.handleDrop($event)
        },
      },
    },
    [
      _c(
        "div",
        {
          staticClass: "yxt-tree-node__content",
          style: {
            "padding-left":
              _vm.node.level !== 1
                ? (_vm.node.level - 1) * _vm.tree.indent + "px"
                : "8px",
          },
        },
        [
          _c(
            "div",
            { staticStyle: { display: "flex", "align-items": "center" } },
            [
              _c("span", {
                directives: [
                  {
                    name: "loading",
                    rawName: "v-loading",
                    value: _vm.node.loading,
                    expression: "node.loading",
                  },
                ],
                class: [
                  {
                    "is-leaf": _vm.node.isLeaf,
                    expanded: !_vm.node.isLeaf && _vm.expanded,
                  },
                  "yxt-tree-node__expand-icon",
                  _vm.tree.iconClass
                    ? _vm.tree.iconClass
                    : "yxt-icon-caret-right",
                ],
                attrs: { "yxt-loading-size": "small" },
                on: {
                  click: function ($event) {
                    $event.stopPropagation()
                    return _vm.handleExpandIconClick($event)
                  },
                },
              }),
              _c(
                "div",
                { staticClass: "yxt-tree-node__labelGroup" },
                [
                  _vm.showCheckbox && !_vm.node.nocheck
                    ? _c("yxt-checkbox", {
                        attrs: {
                          indeterminate: _vm.node.indeterminate,
                          disabled: !!_vm.node.disabled,
                        },
                        on: { change: _vm.handleCheckChange },
                        nativeOn: {
                          click: function ($event) {
                            $event.stopPropagation()
                          },
                        },
                        model: {
                          value: _vm.node.checked,
                          callback: function ($$v) {
                            _vm.$set(_vm.node, "checked", $$v)
                          },
                          expression: "node.checked",
                        },
                      })
                    : _vm._e(),
                  _c("node-content", { attrs: { node: _vm.node } }),
                ],
                1
              ),
            ]
          ),
          _vm.draggable
            ? _c(
                "span",
                { staticClass: "yxt-tree-node__drag" },
                [
                  _c("yxt-svg", {
                    staticClass: "color-gray-6 hover-primary-6",
                    attrs: {
                      width: "20px",
                      height: "20px",
                      "icon-class": "sort",
                    },
                  }),
                ],
                1
              )
            : _vm._e(),
        ]
      ),
      _c("yxt-collapse-transition", [
        (!_vm.renderAfterExpand || _vm.childNodeRendered) && !_vm.virtual
          ? _c(
              "div",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.expanded,
                    expression: "expanded",
                  },
                ],
                staticClass: "yxt-tree-node__children",
                attrs: { role: "group", "aria-expanded": _vm.expanded },
              },
              _vm._l(_vm.node.childNodes, function (child) {
                return _c("yxt-tree-node", {
                  key: _vm.getNodeKey(child),
                  attrs: {
                    "render-content": _vm.renderContent,
                    virtual: _vm.virtual,
                    "render-after-expand": _vm.renderAfterExpand,
                    "show-checkbox": _vm.showCheckbox,
                    node: child,
                    draggable: _vm.draggable,
                  },
                  on: { "node-expand": _vm.handleChildNodeExpand },
                })
              }),
              1
            )
          : _vm._e(),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }