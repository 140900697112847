import axios from 'axios';
// import fetch from 'fetch';
import { mixColors } from '@adso-ts/mix-colors';
import colorString from 'color-string';
import { SepcialTheme } from './specialTheme.js';
// import fetchCss from 'fetch-css';
const ColorFactory = require('color');
const colorPalette = require('../sass-utils/colorPalette.js');

const level_range = 10;
let themeColorPrimary = '#436BFF';

function genID(length) {
  length = length || 4;
  const genNum = Number(Math.random().toString().substr(3, length) + Date.now()).toString(36);
  return 'var' + genNum;
}

function generateColor(color, level) {
  // const Color = Sass.types.Color;
  const color_rgb = colorString.get.rgb(color);

  if (color_rgb) {
    const t_color = ColorFactory.rgb(color_rgb[0], color_rgb[1], color_rgb[2])
      .alpha(color_rgb[3])
      .object();

    // console.log('=====', t_color);

    var g_color = ColorFactory(
      colorPalette(t_color, level).toRgbString()
    );

  }
  // console.log(g_color);
  // return '';
  return colorString.to.hex([
    g_color.red(),
    g_color.green(),
    g_color.blue(),
    g_color.alpha()
  ]);
}

function createColorObject(color) {
  // console.log('createColorObject: ', color);
  color = color || '#436BFF';
  color = color.toLowerCase();

  // 判断是否为特殊皮肤
  if (Object.keys(SepcialTheme).includes(color)) {
    let result = SepcialTheme[color];
    return result;
  }

  let result = {
    '--color-primary': color
  };

  for (let i = 0; i < level_range; i++) {
    const index = i + 1;
    result[`--color-primary-${index}`] = generateColor(color, index).toLowerCase();
  }

  result['--color-primary-6'] = color;
  const _color_primary = result['--color-primary-6'];

  for (let i = 0; i < level_range - 1; i++) {
    const index = i + 1;
    // console.log(_color_primary);
    // console.log((index * 10), ' - ffffff - ', _color_primary, ' : ', mixColors('#ffffff', _color_primary, (index * 10)));
    result[`--color-primary-light-${index}`] = mixColors('#ffffff', _color_primary, (index * 10));
  }

  result['--color-primary-white-5'] = mixColors(_color_primary, '#ffffff', 50);
  result['--color-primary-white-8'] = mixColors(_color_primary, '#ffffff', 80);
  result['--color-primary-white-10'] = mixColors(_color_primary, '#ffffff', 100);

  // $--slider-button-hover-color: mix($--color-primary, black, 97%) !default;
  result['--slider-button-hover-color'] = mixColors(_color_primary, '#000000', 97);

  // color: mix($--color-black, $--color-primary, $--button-active-shade-percent);
  result['--slider-button-active-color'] = mixColors('#000000', _color_primary, 10);

  return result;
}

function changeThemeVal(color, options) {
  // console.log('changeThemeVal: ', color);
  const studentFlag = options.studentFlag;
  const container = options.container;
  var themeStyleId = options.themeStyleId;
  const containerId = options.containerId;

  if (containerId) {
    themeStyleId += `-${containerId}`;
  }
  const colors = createColorObject(color);
  console.log('container: ', container);
  console.log('colors: ', colors);

  let varRes = '';
  for (const key in colors) {
    const color = colors[key];
    varRes += `${key}: ${color};\n\n`;
  }
  // varRes = `{\n\n${varRes}}`;

  var themeStyle = document.querySelector(`style[id="${themeStyleId}"]`);
  if (!themeStyle) {
    themeStyle = document.createElement('style');
    themeStyle.type = 'text/css';
    themeStyle.id = themeStyleId;
    let head = document.getElementsByTagName('head')[0];
    head.appendChild(themeStyle);
  }

  if (container) {
    varRes = `:root #${containerId}{\n\n${varRes}}`;
  } else {
    // if (options.noStudentFlag) {
    //   varRes = `:root body{\n\n${varRes}}`;
    // } else {
    //   varRes = `:root body[${studentFlag}]{\n\n${varRes}}`;
    // }
    varRes = `:root body[${studentFlag}]{\n\n${varRes}}`;
  }
  themeStyle.innerHTML = varRes;

  // console.log(themeStyle);
}

function getStyleWidthColor(color, cssText, options) {
  // console.log('getStyleWidthColor-cssText: ', cssText);
  const studentFlag = options.studentFlag;
  const container = options.container;
  const containerId = options.containerId;
  let result = '';
  const varReg = /var\(--color-primary\)|var\(--color-primary-(\d+)\)|var\(--color-primary-light-(\d+)\)|var\(--color-primary-white-(\d+)\)|var\(--slider-button-hover-color\)|var\(--slider-button-active-color\)/;
  let styles = '';
  try {
    styles = cssText.split('}');
  } catch (error) {
    return result;
  }
  // console.log('getStyleWidthColor-styles: ', styles);
  const colors = createColorObject(color);
  for (let i = 0; i < styles.length; i++) {
    const styleStr = styles[i] + '}';
    if (varReg.test(styleStr)) {
      let newCssText = styleStr;
      for (const key in colors) {
        const color = colors[key];
        // const colorReg = new RegExp(key, 'g');
        newCssText = newCssText.replace(new RegExp(`var\\(${key}\\)`, 'g'), color);
      }
      // const lineReg = new RegExp('\n\n', 'g');
      newCssText = newCssText.replace(new RegExp('\n', 'g'), '');

      let newCssSplit = newCssText.split('{');
      let newCssBody = '{' + newCssSplit[1];
      let newCssHeadList = newCssSplit[0].split(',');
      let newCssHeads = [];
      for (let index = 0; index < newCssHeadList.length; index++) {
        let head = newCssHeadList[index];

        if (container) {
          head = `#${containerId} ${head}`;
        } else {
          if (options.noStudentFlag) {
            head = `body ${head}`;
          } else {
            head = `body[${studentFlag}] ${head}`;
          }
          // newCssText = `body[${studentFlag}] ${newCssText}\n`;
        }
        newCssHeads.push(head);
      }

      // if (container) {
      //   newCssText = `#${containerId} ${newCssText}\n`;
      // } else {
      //   if (options.noStudentFlag) {
      //     newCssText = `body ${newCssText}\n`;
      //   } else {
      //     newCssText = `body[${studentFlag}] ${newCssText}\n`;
      //   }
      //   // newCssText = `body[${studentFlag}] ${newCssText}\n`;
      // }

      // result = result += newCssText;
      result = result + newCssHeads.join(', ') + newCssBody + '\n';
    }
  }
  return result;
}

async function changeThemeStyle(color, options) {
  // 业务组件和 基础组件  我们单独为大华出一份样式表，其他机构的样式表不做改动。  大华这份样式表用的色值：#ED1D24
  // 大华 host: dahuatech.com, localstorage 中的字段: orgId   具体值: a43eb6e2-2f9d-4b8c-99d2-b5d499a58ee5
  // 另一家机构: learning.cnegroup.com 也需要屏蔽换肤功能
  // 大华样式表名称: index.ie.css
  // if (typeof window !== 'undefined' && window.location && location.host.match('dahuatech.com')) {
  // if (location && location.host.match('dahuatech.com')) {
  if (location && location.host.match(/pro0830-phx-ali|dahuatech|learning.cnegroup.com/)) {
    return;
  }

  let varRes = '';
  // 通过document.styleSheets获取当前加载的样式文件的地址styleUrl（如有需要可以增加路径判断，只取必要的样式表）
  // fetch只为解释思路，实际操作应该使用XMLHttpRequest或者axios
  // document.styleSheets获取的元素可能为style节点，此时无法获取链接地址，需要通过innerHTML方法获取
  // console.log('changeThemeStyle ==================');
  const containerId = options.containerId || '';
  // const colors = createColorObject(color);
  // console.log(colors);
  const styleSheets = document.styleSheets;
  // const varReg = /var\(--color-primary\)|var\(--color-primary-(\d+)\)|var\(--color-primary-light-(\d+)\)|var\(--color-primary-white-(\d+)\)|var\(--slider-button-hover-color\)|var\(--slider-button-active-color\)/g;

  for (let index = 0; index < styleSheets.length; index++) {
    const styleSheet = styleSheets[index];
    // console.log(styleSheet);
    const href = styleSheet.href;
    const owerNode = styleSheet.ownerNode;
    if (href) {
      // console.log(index, ' ---+++ ', owerNode.innerHTML);
      // console.log(index, ' ---+++ ', styleSheet);
      // const [{css}] = await fetchCss([{url: href}]);
      let hrefStyle = '';
      try {
        let res = await axios.get(href, {
          headers: {
            'Content-Type': 'text/css'
          }
        });
        hrefStyle = res.data;
      } catch (err) {
        console.log('fetch css Error: ', err);
      }

      console.log('css href: ', href);
      let newHrefStyle = getStyleWidthColor(color, hrefStyle, options);
      varRes += newHrefStyle;

    } else {
      // console.log(index, ' ---=== ', owerNode);

      let newCssText = getStyleWidthColor(color, owerNode.innerHTML, options);
      varRes += newCssText;
    }

  }

  var themeStyleId = options.themeStyleId;
  if (containerId) {
    themeStyleId += `-${containerId}`;
  }
  var themeStyle = document.querySelector(`style[id="${themeStyleId}"]`);
  if (!themeStyle) {
    themeStyle = document.createElement('style');
    themeStyle.type = 'text/css';
    themeStyle.id = themeStyleId;
    let head = document.getElementsByTagName('head')[0];
    head.appendChild(themeStyle);
  }

  themeStyle.innerHTML = varRes;
  // themeStyle.innerHTML = `body[student] {--color-primary-1: ${color1}; .... --color-primary-10: ${color10}}`;
  // console.log(varRes);
}

function changeTheme(color, options) {
  // console.log('changeTheme----------++++++++++', color, options);
  // 准确的判断方法
  document.documentElement.style.setProperty('--yxt-theme-custom-color', 'orange');
  const isSupported = document.documentElement.style.getPropertyValue('--yxt-theme-custom-color') === 'orange';

  color = color || '#436bff';
  options = options || {};
  options = Object.assign({
    themeStyleId: 'theme-init',
    studentFlag: 'student',
    noStudentFlag: false,
    container: null,
    yxtInstall: false
  }, options);

  const container = options.container;
  // console.log('options', options);
  if (container) {
    // console.log('----------++++++++++', container);
    // options.container = document.body;
    if (container.id) {
      options.containerId = container.id;
    } else {
      options.containerId = genID();
      container.id = options.containerId;
    }
    // console.log('----------++++++++++', container, options);
    // options.container = document.querySelector('body[student]');
    // console.log('options.container: ', options.container, document.querySelector('body[student]'), document.body.attributes);
  } else {
    themeColorPrimary = color;
  }

  if (isSupported) {
    changeThemeVal(color, options);
  } else {
    if (options.yxtInstall) {
      changeThemeStyle('#436bff', {
        themeStyleId: 'theme-init-def',
        studentFlag: '',
        noStudentFlag: true,
        container: null
      });
    }

    changeThemeStyle(color, options);
  }
}

function getPrimaryColors() {
  let result = {
    // '--color-primary': themeColorPrimary
  };

  for (let i = 0; i < level_range; i++) {
    const index = i + 1;
    if (index === 6) {
      result['--color-primary-6'] = themeColorPrimary;
    } else {
      result[`--color-primary-${index}`] = generateColor(themeColorPrimary, index).toLowerCase();
    }
  }

  return result;
}

function getPrimaryColor() {
  return themeColorPrimary;
}

export default {
  changeTheme,
  getPrimaryColors,
  getPrimaryColor
};
// export default changeTheme;

// export default {
//   create,
//   colors,
//   fade
// };

