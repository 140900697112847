var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "yxt-collapse-item",
      class: {
        "is-active": _vm.isActive,
        "is-disabled": _vm.disabled,
        "yxt-collapse-item--card": _vm.type === "card",
      },
    },
    [
      _c(
        "div",
        {
          attrs: {
            role: "tab",
            "aria-expanded": _vm.isActive,
            "aria-controls": "yxt-collapse-content-" + _vm.id,
            "aria-describedby": "yxt-collapse-content-" + _vm.id,
          },
        },
        [
          _c(
            "div",
            {
              staticClass: "yxt-collapse-item__header",
              class: {
                focusing: _vm.focusing,
                "is-active": _vm.isActive,
              },
              attrs: {
                role: "button",
                id: "yxt-collapse-head-" + _vm.id,
                tabindex: _vm.disabled ? undefined : 0,
              },
              on: {
                click: _vm.handleHeaderClick,
                keyup: function ($event) {
                  if (
                    !("button" in $event) &&
                    _vm._k($event.keyCode, "space", 32, $event.key, [
                      " ",
                      "Spacebar",
                    ]) &&
                    _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                  ) {
                    return null
                  }
                  $event.stopPropagation()
                  return _vm.handleEnterClick($event)
                },
                focus: _vm.handleFocus,
                blur: function ($event) {
                  _vm.focusing = false
                },
              },
            },
            [
              _vm.showItemIcon && !_vm.iconType
                ? _c("yxt-svg", {
                    staticClass: "yxt-collapse-item__arrow",
                    class: { "is-active": _vm.isActive },
                    attrs: {
                      width: "16px",
                      height: "16px",
                      "icon-class": "common_top",
                    },
                  })
                : _vm.showItemIcon && _vm.iconType == "arrow"
                ? _c("i", {
                    staticClass:
                      "yxt-collapse-item__type-arrow yxt-icon-arrow-right",
                    class: { "is-active": _vm.isActive },
                  })
                : _vm._e(),
              _vm._t("title", [_vm._v(_vm._s(_vm.title))]),
            ],
            2
          ),
        ]
      ),
      _c("yxt-collapse-transition", [
        _c(
          "div",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.isActive,
                expression: "isActive",
              },
            ],
            staticClass: "yxt-collapse-item__wrap",
            attrs: {
              role: "tabpanel",
              "aria-hidden": !_vm.isActive,
              "aria-labelledby": "yxt-collapse-head-" + _vm.id,
              id: "yxt-collapse-content-" + _vm.id,
            },
          },
          [
            _c(
              "div",
              { staticClass: "yxt-collapse-item__content" },
              [_vm._t("default")],
              2
            ),
          ]
        ),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }