<template>
  <transition name="yxt-message-fade" @after-leave="handleAfterLeave">
    <div
      :class="[
        'yxt-message',
        center ? 'is-center' : '',
        showClose ? 'is-closable' : '',
        showRepeal ? 'is-repeal' : '',
        customClass
      ]"
      :style="positionStyle"
      v-show="visible"
      @mouseenter="clearTimer"
      @mouseleave="startTimer"
      role="alert"
      :isSingle="isSingle">
      <div class="yxt-message__svg">
        <yxt-svg remote-url='' class='color-gray-6 hover-primary-6' width='16px' height='16px' :icon-class="'message-'+type" />
      </div>
      <div class="yxt-message__ctx">
        <slot>
          <p v-if="!dangerouslyUseHTMLString" class="yxt-message__content">{{ message }}</p>
          <p v-else v-html="message" class="yxt-message__content"></p>
        </slot>
        <span v-if="showClose" @click="close" class="yxt-message__closeBtn">{{ t('pc_comp_message_iknow') }}</span>
        <template v-if="showRepeal">
          <span class="yxt-message__repeal" @click="repeal">{{ t('pc_comp_message_repeal') }}</span>
          <i class="yxt-message__closeIcon yxt-icon-close" @click="close"></i>
        </template>
      </div>
    </div>
  </transition>
</template>

<script type="text/babel">
  import Locale from '@utils/mixins/locale';
  export default {
    mixins: [Locale],
    data() {
      return {
        visible: false,
        message: '',
        duration: 3000,
        type: 'info',
        customClass: '',
        onClose: null,
        showClose: false,
        closed: false,
        verticalOffset: 20,
        timer: null,
        dangerouslyUseHTMLString: false,
        center: false,
        isSingle: false,
        showRepeal: false,
        onRepeal: null
      };
    },

    computed: {
      positionStyle() {
        return {
          'top': `${ this.verticalOffset }px`
        };
      }
    },

    watch: {
      closed(newVal) {
        if (newVal) {
          this.visible = false;
        }
      }
    },
    methods: {
      handleAfterLeave() {
        this.$destroy(true);
        this.$el.parentNode.removeChild(this.$el);
      },

      close() {
        this.closed = true;
        if (typeof this.onClose === 'function') {
          this.onClose(this);
        }
      },

      clearTimer() {
        clearTimeout(this.timer);
      },

      startTimer() {
        if (this.duration > 0) {
          this.timer = setTimeout(() => {
            if (!this.closed) {
              this.close();
            }
          }, this.duration);
        }
      },
      keydown(e) {
        if (e.keyCode === 27) { // esc关闭消息
          if (!this.closed) {
            this.close();
          }
        }
      },
      repeal() {
        if (typeof this.onRepeal === 'function') {
          this.close();
          this.onRepeal();
        }
      }
    },
    mounted() {
      this.startTimer();
      document.addEventListener('keydown', this.keydown);
    },
    beforeDestroy() {
      document.removeEventListener('keydown', this.keydown);
    }
  };
</script>
