var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("li", { staticClass: "yxt-timeline-item" }, [
    _c("div", {
      class: [
        "yxt-timeline-item__tail",
        !_vm.$slots.dot ? "yxt-timeline-item__tail--" + (_vm.size || "") : "",
        ,
        _vm.timeline.dashed ? "yxt-timeline-item__tail_dashed" : "",
      ],
      style: { borderColor: _vm.tailColor },
    }),
    !_vm.$slots.dot
      ? _c(
          "div",
          {
            class: _vm.icon
              ? [
                  "yxt-timeline-item__node_icon",
                  "yxt-timeline-item__node_icon--" + (_vm.size || ""),
                  "yxt-timeline-item__node_icon--" + (_vm.type || ""),
                ]
              : [
                  "yxt-timeline-item__node" + (_vm.isCurrent ? "_current" : ""),
                  "yxt-timeline-item__node" +
                    (_vm.isCurrent ? "_current" : "") +
                    "--" +
                    (_vm.size || ""),
                  "yxt-timeline-item__node" +
                    (_vm.isCurrent ? "_current" : "") +
                    "--" +
                    (_vm.type || ""),
                ],
            style: !_vm.icon
              ? {
                  borderColor: _vm.color,
                }
              : { color: _vm.color },
          },
          [
            _vm.icon
              ? _c("i", { class: [_vm.icon, "yxt-timeline-item__icon"] })
              : _vm._e(),
          ]
        )
      : _vm._e(),
    _vm.$slots.dot
      ? _c(
          "div",
          {
            staticClass: "yxt-timeline-item__dot",
            style: {
              width: _vm.dotWidth + "px",
              height: _vm.dotHeight + "px",
              left: -(_vm.dotWidth / 2 - 4) + "px",
            },
          },
          [_vm._t("dot")],
          2
        )
      : _vm._e(),
    _c("div", { staticClass: "yxt-timeline-item__wrapper" }, [
      !_vm.hideTimestamp && _vm.placement === "top"
        ? _c("div", { staticClass: "yxt-timeline-item__timestamp is-top" }, [
            _vm._v("\n      " + _vm._s(_vm.timestamp) + "\n    "),
          ])
        : _vm._e(),
      _c(
        "div",
        { staticClass: "yxt-timeline-item__content" },
        [_vm._t("default")],
        2
      ),
      !_vm.hideTimestamp && _vm.placement === "bottom"
        ? _c("div", { staticClass: "yxt-timeline-item__timestamp is-bottom" }, [
            _vm._v("\n      " + _vm._s(_vm.timestamp) + "\n    "),
          ])
        : _vm._e(),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }