var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.showPanel
    ? _c(
        "div",
        {
          staticClass: "yxt-table__title-custom-column",
          class: {
            "yxt-table__title-custom-column--popper": _vm.popper,
          },
          on: { click: _vm.stopEvent },
        },
        [
          _c("div", { staticClass: "yxt-table__custom-column-wrapper" }, [
            _c(
              "div",
              { staticClass: "yxt-table__custom-column-header" },
              [
                _c("div", [
                  _vm._v(_vm._s(_vm.t("pc_comp_table_columnCustomTip"))),
                ]),
                _c(
                  "yxt-checkbox",
                  {
                    attrs: { indeterminate: _vm.isIndeterminate },
                    on: { change: _vm.handleCheckAllChange },
                    model: {
                      value: _vm.checkAll,
                      callback: function ($$v) {
                        _vm.checkAll = $$v
                      },
                      expression: "checkAll",
                    },
                  },
                  [_vm._v(_vm._s(_vm.t("pc_comp_table_clearFilter")))]
                ),
              ],
              1
            ),
            _c("div", { staticClass: "yxt-table__title-check-panel" }, [
              _c(
                "div",
                [
                  _c(
                    "yxt-scrollbar",
                    { attrs: { wrapClass: "custom-column-scrollbar" } },
                    [
                      _c(
                        "yxt-checkbox-group",
                        {
                          ref: "customColumnList",
                          attrs: { direction: "row" },
                          on: { change: _vm.checkedColumnsChange },
                          model: {
                            value: _vm.checkedColumnsList,
                            callback: function ($$v) {
                              _vm.checkedColumnsList = $$v
                            },
                            expression: "checkedColumnsList",
                          },
                        },
                        _vm._l(_vm.columnsList, function (item, index) {
                          return _c(
                            "div",
                            {
                              key: item.label,
                              staticClass: "yxt-table__check-item",
                              attrs: { draggable: !item.disableDrag },
                              on: {
                                dragstart: function ($event) {
                                  $event.stopPropagation()
                                  return (function (event) {
                                    return _vm.handleDragStart(
                                      event,
                                      item,
                                      index
                                    )
                                  })($event)
                                },
                                dragover: function ($event) {
                                  $event.stopPropagation()
                                  return (function (event) {
                                    return _vm.handleDragOver(
                                      event,
                                      item,
                                      index
                                    )
                                  })($event)
                                },
                                dragleave: function ($event) {
                                  $event.stopPropagation()
                                  return (function (event) {
                                    return _vm.handleDragLeave(
                                      event,
                                      item,
                                      index
                                    )
                                  })($event)
                                },
                                dragend: function ($event) {
                                  $event.stopPropagation()
                                  return (function (event) {
                                    return _vm.handleDragEnd(event, item, index)
                                  })($event)
                                },
                                drop: function ($event) {
                                  $event.stopPropagation()
                                  return (function (event) {
                                    return _vm.handleDrop(event, item, index)
                                  })($event)
                                },
                                mouseenter: function (event) {
                                  return _vm.handleEnter(event, item, index)
                                },
                                mouseleave: function (event) {
                                  return _vm.handleLeave(event, item, index)
                                },
                              },
                            },
                            [
                              item.label
                                ? _c(
                                    "div",
                                    {
                                      staticClass: "yxt-table__check-item-cont",
                                      class: {
                                        "yxt-table__check-item-cont--draging":
                                          _vm.dragState.dragging,
                                      },
                                    },
                                    [
                                      _c(
                                        "yxt-checkbox",
                                        {
                                          staticClass:
                                            "table_yxt-checkbox__input",
                                          attrs: {
                                            disabled: item.disable
                                              ? "lock"
                                              : false,
                                            label: item.label,
                                          },
                                          on: { change: _vm.changeCheckedList },
                                        },
                                        [
                                          _c(
                                            "yxt-tooltip",
                                            {
                                              attrs: {
                                                effect: "dark",
                                                "open-filter": true,
                                                content: item.label,
                                                placement: "top",
                                              },
                                            },
                                            [
                                              _c("span", {
                                                domProps: {
                                                  textContent: _vm._s(
                                                    item.label
                                                  ),
                                                },
                                              }),
                                            ]
                                          ),
                                        ],
                                        1
                                      ),
                                      !item.disableDrag &&
                                      !_vm.dragState.dragging &&
                                      _vm.hoverIndex === index
                                        ? _c(
                                            "yxt-tooltip",
                                            {
                                              staticClass: "item",
                                              attrs: {
                                                "open-filter": true,
                                                effect: "dark",
                                                content: _vm.t(
                                                  "pc_comp_table_columnCustomDragTip"
                                                ),
                                                placement: "top",
                                              },
                                            },
                                            [
                                              _c(
                                                "span",
                                                {
                                                  staticClass:
                                                    "custom-column-icon-drag color-gray-6 hover-primary-6",
                                                  on: {
                                                    mousedown: function (
                                                      event
                                                    ) {
                                                      return _vm.handleCanDragStart(
                                                        event,
                                                        true
                                                      )
                                                    },
                                                    mouseup: function (event) {
                                                      return _vm.handleCanDragStart(
                                                        event,
                                                        false
                                                      )
                                                    },
                                                  },
                                                },
                                                [
                                                  _c("yxt-svg", {
                                                    attrs: {
                                                      width: "16px",
                                                      height: "16px",
                                                      "icon-class": "sort",
                                                    },
                                                  }),
                                                ],
                                                1
                                              ),
                                            ]
                                          )
                                        : _vm._e(),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                            ]
                          )
                        }),
                        0
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ]),
          ]),
        ]
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }