<template>
  <span>
    <transition
      :name="transition"
      @after-enter="handleAfterEnter"
      @after-leave="handleAfterLeave">
      <div
        class="yxtf-popover yxtf-popper"
        :class="[popperClass, paddingSmall && 'yxtf-popover--plain', hasIcon && 'yxtf-popover--icon', effect && 'is-ellipsis']"
        ref="popper"
        v-show="!disabled && showPopper && !hidePopover"
        :style="mainStyle"
        role="tooltip"
        :id="tooltipId"
        :aria-hidden="(disabled || !showPopper) ? 'true' : 'false'">
        <div class="yxtf-popover__title" v-if="title" v-text="title"></div>
        <yxt-scrollbar v-if="showScroll" ref="scrollbar" :warpClass="['custom-wrap']">
          <div :style="contentStyle">
            <slot><div>{{ content }}</div></slot>
          </div>
        </yxt-scrollbar>
        <slot v-else><div>{{ content }}</div></slot>
      </div>
    </transition>
    <slot name="reference"></slot>
  </span>
</template>
<script>
import Popper from '@utils/utils/vue-popper';
import { on, off, addClass, removeClass } from '@utils/utils/dom';
import { generateId } from '@utils/utils/util';

export default {
  name: 'YxtfPopover',

  mixins: [Popper],

  props: {
    trigger: {
      type: String,
      default: 'click',
      validator: value => ['click', 'focus', 'hover', 'manual'].indexOf(value) > -1
    },
    maxHeight: String | Number,
    maxWidth: {
      type: [String, Number],
      default: 542
    },
    showScroll: Boolean,
    openFilter: {
      type: [Function, Boolean],
      default: false
    },
    openDelay: {
      type: Number,
      default: 0
    },
    closeDelay: {
      type: Number,
      default: 200
    },
    title: String,
    disabled: Boolean,
    content: String,
    reference: {},
    popperClass: String,
    width: {
      default: ''
    },
    visibleArrow: {
      default: true
    },
    arrowOffset: {
      type: Number,
      default: 0
    },
    transition: {
      type: String,
      default: 'fade-in-linear'
    },
    tabindex: {
      type: Number,
      default: 0
    },
    paddingSmall: {
      type: Boolean,
      default: false
    },
    hasIcon: {
      type: Boolean,
      default: false
    },
    effect: {
      type: Boolean,
      default: false
    }
  },

  computed: {
    tooltipId() {
      return `yxt-popover-${generateId()}`;
    },
    contentStyle() {
      return 'margin-right:16px;' + (this.title ? `max-height:${this.maxHeight - 30}px;` : `max-height:${this.maxHeight - 30}px;`);
    },
    hidePopover() {
      return !this.title && !this.content && !this.$slots.default;
    },
    mainStyle() {
      var style = `width: ${this.width}px;`;
      this.showScroll && (style += 'padding-right: 0;');
      this.maxWidth && (style += `max-width: ${this.maxWidth}px;`);
      this.maxHeight && (style += `max-height: ${this.maxHeight}px;`);
      return style;
    }
  },
  watch: {
    showPopper(val) {
      if (this.disabled) {
        return;
      }
      val ? this.$emit('show') : this.$emit('hide');
    }
  },

  mounted() {
    let reference = this.referenceElm = this.reference || this.$refs.reference;
    const popper = this.popper || this.$refs.popper;

    if (!reference && this.$slots.reference && this.$slots.reference[0]) {
      reference = this.referenceElm = this.$slots.reference[0].elm;
    }
    // 可访问性
    if (reference) {
      addClass(reference, 'yxtf-popover__reference');
      reference.setAttribute('aria-describedby', this.tooltipId);
      reference.setAttribute('tabindex', this.tabindex); // tab序列
      popper.setAttribute('tabindex', 0);

      if (this.trigger !== 'click') {
        on(reference, 'focusin', () => {
          this.handleFocus();
          const instance = reference.__vue__;
          if (instance && typeof instance.focus === 'function') {
            instance.focus();
          }
        });
        on(popper, 'focusin', this.handleFocus);
        on(reference, 'focusout', this.handleBlur);
        on(popper, 'focusout', this.handleBlur);
      }
      on(reference, 'keydown', this.handleKeydown);
      on(reference, 'click', this.handleClick);
    }
    if (this.trigger === 'click') {
      on(reference, 'click', this.doToggle);
      on(document, 'click', this.handleDocumentClick);
    } else if (this.trigger === 'hover') {
      on(reference, 'mouseenter', this.handleMouseEnter);
      on(popper, 'mouseenter', this.handleMouseEnter);
      on(reference, 'mouseleave', this.handleMouseLeave);
      on(popper, 'mouseleave', this.handleMouseLeave);
    } else if (this.trigger === 'focus') {
      if (this.tabindex < 0) {
        console.warn('[Element Warn][Popover]a negative taindex means that the element cannot be focused by tab key');
      }
      if (reference.querySelector('input, textarea')) {
        on(reference, 'focusin', this.doShow);
        on(reference, 'focusout', this.doClose);
      } else {
        on(reference, 'mousedown', this.doShow);
        on(reference, 'mouseup', this.doClose);
      }
    }
  },

  beforeDestroy() {
    this.cleanup();
  },

  deactivated() {
    this.cleanup();
  },

  methods: {
    reloadContent() {
      // const popper = this.popper || this.$refs.popper;
      // this.updatePopper();

      this.$nextTick(this.updatePopper);
    },
    doToggle() {
      if (this.openFilter && this.isOverflow(this.referenceElm) && this.showPopper) return;
      this.showPopper = !this.showPopper;
    },
    doShow() {
      if (this.openFilter && this.isOverflow(this.referenceElm)) return;
      this.showPopper = true;
    },
    doClose() {
      // console.log('doClose -----+++');
      this.showPopper = false;
    },
    handleFocus() {
      addClass(this.referenceElm, 'focusing');
      if (this.trigger === 'click' || this.trigger === 'focus') this.doShow();
    },
    handleClick() {
      removeClass(this.referenceElm, 'focusing');
    },
    handleBlur() {
      removeClass(this.referenceElm, 'focusing');
      if (this.trigger === 'click' || this.trigger === 'focus') this.doClose();
    },
    handleMouseEnter() {
      // console.log('handleMouseEnter -----+++', this.openDelay, '===', this.tooltipId);
      clearTimeout(this._timer);
      if (this.openDelay) {
        this._timer = setTimeout(() => {
          this.doShow();
        }, this.openDelay);
      } else {
        this.doShow();
      }
    },
    handleKeydown(ev) {
      if (ev.keyCode === 27 && this.trigger !== 'manual') { // esc
        this.doClose();
      }
    },
    handleMouseLeave() {
      clearTimeout(this._timer);
      if (this.closeDelay) {
        // console.log('handleMouseLeave -----+++', this.closeDelay, '=== true', this.tooltipId);
        this._timer = setTimeout(() => {
          this.doClose();
        }, this.closeDelay);
      } else {
        // console.log('handleMouseLeave -----+++', this.closeDelay, '=== false', this.tooltipId);
        this.doClose();
      }
    },
    handleDocumentClick(e) {
      let reference = this.reference || this.$refs.reference;
      const popper = this.popper || this.$refs.popper;

      if (!reference && this.$slots.reference && this.$slots.reference[0]) {
        reference = this.referenceElm = this.$slots.reference[0].elm;
      }
      if (!this.$el ||
        !reference ||
        this.$el.contains(e.target) ||
        reference.contains(e.target) ||
        !popper ||
        popper.contains(e.target)) return;
      this.doClose();
    },
    handleAfterEnter() {
      this.$emit('after-enter');
    },
    handleAfterLeave() {
      this.$emit('after-leave');
      this.doDestroy();
    },
    cleanup() {
      if (this.openDelay || this.closeDelay) {
        clearTimeout(this._timer);
      }
    },
    isOverflow($el) {
      return $el.scrollWidth <= $el.clientWidth && $el.scrollHeight <= $el.clientHeight;
    }
  },

  destroyed() {
    const reference = this.reference;

    off(reference, 'click', this.doToggle);
    off(reference, 'mouseup', this.doClose);
    off(reference, 'mousedown', this.doShow);
    off(reference, 'focusin', this.doShow);
    off(reference, 'focusout', this.doClose);
    off(reference, 'mousedown', this.doShow);
    off(reference, 'mouseup', this.doClose);
    off(reference, 'mouseleave', this.handleMouseLeave);
    off(reference, 'mouseenter', this.handleMouseEnter);
    off(document, 'click', this.handleDocumentClick);
  }
};
</script>
