var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "transition",
    {
      attrs: { name: "yxt-zoom-in-top" },
      on: {
        "after-leave": function ($event) {
          _vm.$emit("dodestroy")
        },
      },
    },
    [
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.visible,
              expression: "visible",
            },
          ],
          staticClass: "yxt-time-panel yxt-popper",
          class: _vm.popperClass,
        },
        [
          _c(
            "div",
            {
              staticClass: "yxt-time-panel__content",
              class: { "has-seconds": _vm.showSeconds },
            },
            [
              _c("time-spinner", {
                ref: "spinner",
                attrs: {
                  "arrow-control": _vm.useArrow,
                  "show-seconds": _vm.showSeconds,
                  "am-pm-mode": _vm.amPmMode,
                  date: _vm.date,
                },
                on: {
                  change: _vm.handleChange,
                  "select-range": _vm.setSelectionRange,
                  placeholder: _vm.handlePlaceholder,
                },
              }),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "yxt-time-panel__footer" },
            [
              _c(
                "yxt-button",
                {
                  staticClass: "yxt-time-panel__btn cancel",
                  attrs: { type: "text2", size: "small" },
                  on: { click: _vm.handleCurrent },
                },
                [_vm._v(_vm._s(_vm.t("pc_comp_datepicker_now")))]
              ),
              _c(
                "yxt-button",
                {
                  staticClass: "yxt-time-panel__btn",
                  attrs: { type: "primary", size: "small" },
                  on: {
                    click: function ($event) {
                      _vm.handleConfirm()
                    },
                  },
                },
                [_vm._v(_vm._s(_vm.t("pc_comp_datepicker_confirm")))]
              ),
            ],
            1
          ),
        ]
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }