var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "ul",
    { staticClass: "yxt-pager", on: { click: _vm.onPagerClick } },
    [
      _vm.pageCount > 0
        ? _c(
            "li",
            {
              staticClass: "number",
              class: { active: _vm.currentPage === 1, disabled: _vm.disabled },
            },
            [_vm._v("1")]
          )
        : _vm._e(),
      _vm.showPrevMore
        ? _c(
            "li",
            {
              staticClass: "yxt-icon more btn-quickprev",
              class: [_vm.quickprevIconClass, { disabled: _vm.disabled }],
              on: {
                mouseenter: function ($event) {
                  _vm.onMouseenter("left")
                },
                mouseleave: function ($event) {
                  _vm.quickprevIconClass = ""
                },
              },
            },
            [
              !_vm.small && _vm.quickprevIconClass === ""
                ? _c("yxt-svg", {
                    attrs: {
                      width: "20px",
                      height: "28px",
                      "icon-class": "three-dot",
                    },
                  })
                : _vm._e(),
              _vm.small && _vm.quickprevIconClass === ""
                ? _c("yxt-svg", {
                    attrs: {
                      width: "20px",
                      height: "24px",
                      "icon-class": "three-dot",
                    },
                  })
                : _vm._e(),
            ],
            1
          )
        : _vm._e(),
      _vm._l(_vm.pagers, function (pager) {
        return _c(
          "li",
          {
            key: pager,
            staticClass: "number",
            class: {
              active: _vm.currentPage === pager,
              disabled: _vm.disabled,
            },
          },
          [_vm._v(_vm._s(pager))]
        )
      }),
      _vm.showNextMore
        ? _c(
            "li",
            {
              staticClass: "yxt-icon more btn-quicknext",
              class: [_vm.quicknextIconClass, { disabled: _vm.disabled }],
              on: {
                mouseenter: function ($event) {
                  _vm.onMouseenter("right")
                },
                mouseleave: function ($event) {
                  _vm.quicknextIconClass = ""
                },
              },
            },
            [
              !_vm.small && _vm.quicknextIconClass === ""
                ? _c("yxt-svg", {
                    attrs: {
                      width: "20px",
                      height: "26px",
                      "icon-class": "three-dot",
                    },
                  })
                : _vm._e(),
              _vm.small && _vm.quicknextIconClass === ""
                ? _c("yxt-svg", {
                    attrs: {
                      width: "20px",
                      height: "24px",
                      "icon-class": "three-dot",
                    },
                  })
                : _vm._e(),
            ],
            1
          )
        : _vm._e(),
      _vm.pageCount > 1
        ? _c(
            "li",
            {
              staticClass: "number",
              class: {
                active: _vm.currentPage === _vm.pageCount,
                disabled: _vm.disabled,
              },
            },
            [_vm._v(_vm._s(_vm.pageCount))]
          )
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }