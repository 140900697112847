<template>
  <transition
    name="yxt-zoom-in-top"
    @after-leave="$emit('dodestroy')">
    <div
      v-show="visible"
      class="yxt-time-range-picker yxt-picker-panel yxt-popper"
      :class="popperClass">
      <div class="yxt-time-range-picker__content">
        <time-spinner
            ref="minSpinner"
            v-show="isStart"
            :show-seconds="showSeconds"
            :am-pm-mode="amPmMode"
            @change="handleMinChange"
            :arrow-control="arrowControl"
            @select-range="setMinSelectionRange"
            @placeholder="handlePlaceholder"
            :date="minDate">
          </time-spinner>
          <time-spinner
            v-show="!isStart"
            ref="maxSpinner"
            :show-seconds="showSeconds"
            :am-pm-mode="amPmMode"
            @change="handleMaxChange"
            :arrow-control="arrowControl"
            @select-range="setMaxSelectionRange"
            @placeholder="handlePlaceholder"
            :date="maxDate">
          </time-spinner>
      </div>
      <div class="yxt-time-panel__footer">
        <yxt-button
          type="text2"
          size="small"
          :disabled="nowDisabled"
          @click="handleCurrent()">{{ t('pc_comp_datepicker_now') }}</yxt-button>
        <yxt-button
          type="primary"
          size="small"
          class="yxt-time-panel__btn confirm"
          @click="handleConfirm()"
          :disabled="btnDisabled">{{ t('pc_comp_datepicker_confirm') }}</yxt-button>
      </div>
    </div>
  </transition>
</template>

<script type="text/babel">
  import {
    parseDate,
    limitTimeRange,
    modifyDate,
    clearMilliseconds,
    timeWithinRange,
    isDate
  } from '@utils/utils/date-util';
  import Locale from '@utils/mixins/locale';
  import TimeSpinner from '../basic/time-spinner';

  const MIN_TIME = parseDate('00:00:00', 'HH:mm:ss');
  const MAX_TIME = parseDate('23:59:59', 'HH:mm:ss');

  const minTimeOfDay = function() {
    const d = new Date();
    return modifyDate(MIN_TIME, d.getFullYear(), d.getMonth(), d.getDate());
  };
  
  const maxTimeOfDay = function() {
    const d = new Date();
    return modifyDate(MAX_TIME, d.getFullYear(), d.getMonth(), d.getDate());
  };

  const formatTime = function(date) {
    if (!isDate(date)) return date;
    const d = new Date();
    d.setHours(date.getHours());
    d.setMinutes(date.getMinutes());
    d.setSeconds(date.getSeconds());

    return d;
  };

  // increase time by amount of milliseconds, but within the range of day
  // const advanceTime = function(date, amount) {
  //   return new Date(Math.min(date.getTime() + amount, maxTimeOfDay(date).getTime()));
  // };

  export default {
    mixins: [Locale],

    components: { TimeSpinner },

    computed: {
      showSeconds() {
        return (this.format || '').indexOf('ss') !== -1;
      },
      timeSpliceLen() {
        return this.showSeconds ? 3 : 2;
      },
      offset() {
        return this.showSeconds ? 11 : 8;
      },

      spinner() {
        return this.selectionRange[0] < this.offset ? this.$refs.minSpinner : this.$refs.maxSpinner;
      },

      btnDisabled() {
        if (this.isStart) {
          return this.compare(this.minDate, this.maxDate);
        } else {
          return this.compare(this.minDate, this.maxDate);
        }
      },

      nowDisabled() {
        if (this.isStart) {
          if (!isDate(this.maxDate)) return false;
          return this.compare(new Date(), this.maxDate);
        } else {
          if (!isDate(this.minDate)) return false;
          return this.compare(this.minDate, new Date());
        }
      },

      amPmMode() {
        if ((this.format || '').indexOf('A') !== -1) return 'A';
        if ((this.format || '').indexOf('a') !== -1) return 'a';
        return '';
      }
    },

    props: {
      isStart: {
        type: Boolean,
        default: true
      }
    },

    data() {
      return {
        popperClass: '',
        minDate: '',
        maxDate: '',
        value: [],
        oldValue: ['', ''],
        defaultValue: null,
        format: 'HH:mm:ss',
        visible: false,
        selectionRange: [0, 2],
        arrowControl: false,
        handleCount: 0
      };
    },

    watch: {
      value(value) {
        this.valueInit(value);
      },

      visible(val) {
        if (val) {
          this.oldValue = this.value;
          if (!this.isStart) {
            if (!isDate(this.minDate) && !isDate(this.maxDate)) {
              this.maxDate = new Date();
            } else if (!isDate(this.maxDate)) {
              this.maxDate = new Date();
            }
          } else {
            this.handleCount = isDate(this.minDate) ? this.timeSpliceLen + 1 : 0;
          }

          this.adjustRanges();
        }
      },

      minDate() {
        this.$nextTick(() => {
          this.adjustRanges();
        });
      },
  
      isStart: {
        immediate: true,
        handler(val) {
          // if (!val && !isDate(this.maxDate)) {
          //   this.maxDate = new Date();
          // }
          this.valueInit(this.value);

          if (this.visible) {
            if (val) {
              this.handleCount = isDate(this.minDate) ? this.timeSpliceLen + 1 : 0;
            }
            this.$nextTick(() => {
              this.$refs[val ? 'minSpinner' : 'maxSpinner'].emitSelectRange('hours');
              this.adjustRanges();
            });
          }

        }
      }
    },

    methods: {
      handleClear() {
        this.$emit('pick', null);
      },

      valueInit(value) {
        if (Array.isArray(value)) {
          this.minDate = isDate(value[0]) ? formatTime(value[0]) : '';
          this.maxDate = isDate(value[1]) ? formatTime(value[1]) : '';
        } else {
          if (Array.isArray(this.defaultValue)) {
            this.minDate = isDate(this.defaultValue[0]) ? formatTime(this.defaultValue[0]) : '';
            this.maxDate = isDate(this.defaultValue[1]) ? formatTime(this.defaultValue[1]) : '';
          } else if (this.defaultValue) {
            this.minDate = isDate(this.defaultValue) ? formatTime(this.defaultValue) : '';
            this.maxDate = '';
            // this.maxDate = advanceTime(new Date(this.defaultValue), 60 * 60 * 1000);
          } else {
            this.minDate = '';
            this.maxDate = '';
          }
        }
      },

      handlePlaceholder(type, value) {
        if (!value) {
          return this.$emit('placeholder', null);
        }
        const date = this.isStart ? new Date(this.minDate) : new Date(this.maxDate);
        if (type === 'time') {
          if (value[0]) {
            date.setHours(value[0]);
          }

          if (value[1]) {
            date.setMinutes(value[1]);
          }

          if (value[2]) {
            date.setSeconds(value[2]);
          }
        }

        this.$emit('placeholder', date);
      },

      handleCancel() {
        this.$emit('pick', this.oldValue);
      },

      handleCurrent() {
        this[this.isStart ? 'minDate' : 'maxDate'] = new Date();
        const minSelectableRange = this.$refs.minSpinner.selectableRange;
        const maxSelectableRange = this.$refs.maxSpinner.selectableRange;

        const minDate = this.isStart ? limitTimeRange(this.minDate, minSelectableRange, this.format) : this.minDate;
        const maxDate = !this.isStart ? limitTimeRange(this.maxDate, maxSelectableRange, this.format) : this.maxDate;

        this.$emit('pick', [minDate, maxDate], false);
      },

      handleMinChange(date) {
        if (isDate(date)) {
          this.minDate = clearMilliseconds(date);
        }
        this.handleCount++;
        this.handleChange();
      },

      handleMaxChange(date) {
        if (isDate(date)) {
          this.maxDate = clearMilliseconds(date);
        }
        this.handleChange();
      },

      compare(date1, date2) {
        if (date1 && date2) {
          if (date1.getHours() > date2.getHours()) {
            return true;
          } else if (date1.getHours() === date2.getHours()) {
            if (date1.getMinutes() > date2.getMinutes()) {
              return true;
            } else if (date1.getMinutes() === date2.getMinutes()) {
              return this.showSeconds ? date1.getSeconds() >= date2.getSeconds() : true;
            }
          }
        }
  
        return false;
      },

      handleChange() {
        if (this.isValidValue([this.minDate, this.maxDate])) {
          this.$refs.minSpinner.selectableRange = [[minTimeOfDay(), this.maxDate ? this.maxDate : maxTimeOfDay()]];
          this.$refs.maxSpinner.selectableRange = [[this.minDate ? this.minDate : minTimeOfDay(), maxTimeOfDay()]];
          if (this.isStart && this.handleCount <= this.timeSpliceLen) return;
          this.$emit('pick', [this.minDate, this.maxDate], true);
        }
      },

      setMinSelectionRange(start, end) {
        this.$emit('select-range', start, end, 'min');
        this.selectionRange = [start, end];
      },

      setMaxSelectionRange(start, end) {
        this.$emit('select-range', start, end, 'max');
        this.selectionRange = [start + this.offset, end + this.offset];
      },

      handleConfirm(visible = false) {
        const minSelectableRange = this.$refs.minSpinner.selectableRange;
        const maxSelectableRange = this.$refs.maxSpinner.selectableRange;

        this.minDate = limitTimeRange(this.minDate, minSelectableRange, this.format);
        this.maxDate = limitTimeRange(this.maxDate, maxSelectableRange, this.format);

        this.$emit('pick', [this.minDate, this.maxDate], visible);
      },

      adjustSpinners() {
        this.$refs.minSpinner.adjustSpinners();
        this.$refs.maxSpinner.adjustSpinners();
      },

      adjustRanges() {
        this.$refs.minSpinner && (this.$refs.minSpinner.selectableRange = [[minTimeOfDay(), this.maxDate ? this.maxDate : maxTimeOfDay()]]);
        this.$refs.maxSpinner && (this.$refs.maxSpinner.selectableRange = [[this.minDate ? this.minDate : minTimeOfDay(), maxTimeOfDay()]]);
      },

      changeSelectionRange(step) {
        const list = this.showSeconds ? [0, 3, 6, 11, 14, 17] : [0, 3, 8, 11];
        const mapping = ['hours', 'minutes'].concat(this.showSeconds ? ['seconds'] : []);
        const index = list.indexOf(this.selectionRange[0]);
        const next = (index + step + list.length) % list.length;
        const half = list.length / 2;
        if (next < half) {
          this.$refs.minSpinner.emitSelectRange(mapping[next]);
        } else {
          this.$refs.maxSpinner.emitSelectRange(mapping[next - half]);
        }
      },

      isValidValue(date) {
        return Array.isArray(date) &&
          timeWithinRange(this.minDate, this.$refs.minSpinner.selectableRange) &&
          timeWithinRange(this.maxDate, this.$refs.maxSpinner.selectableRange);
      },

      handleKeydown(event) {
        const keyCode = event.keyCode;
        const mapping = { 38: -1, 40: 1, 37: -1, 39: 1 };

        // Left or Right
        if (keyCode === 37 || keyCode === 39) {
          const step = mapping[keyCode];
          this.changeSelectionRange(step);
          event.preventDefault();
          return;
        }

        // Up or Down
        if (keyCode === 38 || keyCode === 40) {
          const step = mapping[keyCode];
          this.spinner.scrollDown(step);
          event.preventDefault();
          return;
        }
      }
    }
  };
</script>
