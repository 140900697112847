import Picker from '../timePicker';
import Panel from '../panel/time-select';
import RangePanel from '../panel/time-select-range';

export default {
  mixins: [Picker],

  name: 'YxtTimeSelect',

  componentName: 'YxtTimeSelect',

  props: {
    isRange: {
      type: Boolean,
      desc: '是否为时间范围选择，仅对<yxt-time-picker>有效'
    }
  },

  data() {
    return {
      type: ''
    };
  },

  created() {
    this.type = this.isRange ? 'time-select-range' : 'time-select';
    if (this.type !== 'time-select') {
      this.panel = RangePanel;
    } else {
      this.panel = Panel;
    }
  }
};
