var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "transition",
    {
      attrs: { name: "yxtf-message-fade" },
      on: { "after-leave": _vm.handleAfterLeave },
    },
    [
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.visible,
              expression: "visible",
            },
          ],
          class: [
            "yxtf-message",
            _vm.type && !_vm.iconClass ? "yxtf-message--" + _vm.type : "",
            _vm.center ? "is-center" : "",
            _vm.showClose ? "is-closable" : "",
            _vm.customClass,
          ],
          style: _vm.positionStyle,
          attrs: { role: "alert", isSingle: _vm.isSingle },
          on: { mouseenter: _vm.clearTimer, mouseleave: _vm.startTimer },
        },
        [
          _vm.iconClass
            ? _c("yxtf-svg", {
                staticStyle: { "margin-right": "10px" },
                attrs: {
                  width: "14px",
                  height: "14px",
                  "icon-class": _vm.iconClass,
                },
              })
            : _c("yxtf-svg", {
                staticStyle: { "margin-right": "10px" },
                attrs: {
                  width: "14px",
                  height: "14px",
                  "icon-class": _vm.typeIcon,
                },
              }),
          _vm._t("default", [
            !_vm.dangerouslyUseHTMLString
              ? _c("p", { staticClass: "yxtf-message__content" }, [
                  _vm._v(_vm._s(_vm.message)),
                ])
              : _c("p", {
                  staticClass: "yxtf-message__content",
                  domProps: { innerHTML: _vm._s(_vm.message) },
                }),
          ]),
          _vm.showClose
            ? _c(
                "span",
                {
                  staticClass: "yxtf-message__closeBtn",
                  on: { click: _vm.close },
                },
                [_vm._v(_vm._s(_vm.t("pc_comp_message_iknow")))]
              )
            : _vm._e(),
        ],
        2
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }