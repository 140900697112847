var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "label",
    {
      staticClass: "yxt-checkbox",
      class: [
        _vm.border && _vm.checkboxSize
          ? "yxt-checkbox--" + _vm.checkboxSize
          : "",
        { "is-disabled": _vm.isDisabled },
        { "is-alone": !(_vm.$slots.default || _vm.label) },
        { "is-disabled-lock": _vm.isDisabled && _vm.isDisabled === "lock" },
        { "is-bordered": _vm.border },
        { "is-checked": _vm.isChecked && _vm.changeTextColor },
        { "is-checked-alone": _vm.isChecked && !_vm.changeTextColor },
      ],
      attrs: { id: _vm.id },
    },
    [
      _c(
        "span",
        {
          staticClass: "yxt-checkbox__input",
          class: {
            "is-disabled": _vm.isDisabled,
            "is-disabled-lock": _vm.isDisabled && _vm.isDisabled === "lock",
            "is-checked": _vm.isChecked && _vm.changeTextColor,
            "is-checked-alone": _vm.isChecked && !_vm.changeTextColor,
            "is-indeterminate": _vm.indeterminate,
            "is-focus": _vm.focus,
          },
          attrs: {
            tabindex: _vm.indeterminate ? 0 : false,
            role: _vm.indeterminate ? "checkbox" : false,
            "aria-checked": _vm.indeterminate ? "mixed" : false,
          },
        },
        [
          _vm._m(0),
          _vm.trueLabel || _vm.falseLabel
            ? _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.model,
                    expression: "model",
                  },
                ],
                staticClass: "yxt-checkbox__original",
                attrs: {
                  type: "checkbox",
                  "aria-hidden": _vm.indeterminate ? "true" : "false",
                  name: _vm.name,
                  disabled: _vm.isDisabled,
                  "true-value": _vm.trueLabel,
                  "false-value": _vm.falseLabel,
                },
                domProps: {
                  checked: Array.isArray(_vm.model)
                    ? _vm._i(_vm.model, null) > -1
                    : _vm._q(_vm.model, _vm.trueLabel),
                },
                on: {
                  change: [
                    function ($event) {
                      var $$a = _vm.model,
                        $$el = $event.target,
                        $$c = $$el.checked ? _vm.trueLabel : _vm.falseLabel
                      if (Array.isArray($$a)) {
                        var $$v = null,
                          $$i = _vm._i($$a, $$v)
                        if ($$el.checked) {
                          $$i < 0 && (_vm.model = $$a.concat([$$v]))
                        } else {
                          $$i > -1 &&
                            (_vm.model = $$a
                              .slice(0, $$i)
                              .concat($$a.slice($$i + 1)))
                        }
                      } else {
                        _vm.model = $$c
                      }
                    },
                    _vm.handleChange,
                  ],
                  focus: function ($event) {
                    _vm.focus = true
                  },
                  blur: function ($event) {
                    _vm.focus = false
                  },
                },
              })
            : _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.model,
                    expression: "model",
                  },
                ],
                staticClass: "yxt-checkbox__original",
                attrs: {
                  type: "checkbox",
                  "aria-hidden": _vm.indeterminate ? "true" : "false",
                  disabled: _vm.isDisabled,
                  name: _vm.name,
                },
                domProps: {
                  value: _vm.label,
                  checked: Array.isArray(_vm.model)
                    ? _vm._i(_vm.model, _vm.label) > -1
                    : _vm.model,
                },
                on: {
                  change: [
                    function ($event) {
                      var $$a = _vm.model,
                        $$el = $event.target,
                        $$c = $$el.checked ? true : false
                      if (Array.isArray($$a)) {
                        var $$v = _vm.label,
                          $$i = _vm._i($$a, $$v)
                        if ($$el.checked) {
                          $$i < 0 && (_vm.model = $$a.concat([$$v]))
                        } else {
                          $$i > -1 &&
                            (_vm.model = $$a
                              .slice(0, $$i)
                              .concat($$a.slice($$i + 1)))
                        }
                      } else {
                        _vm.model = $$c
                      }
                    },
                    _vm.handleChange,
                  ],
                  focus: function ($event) {
                    _vm.focus = true
                  },
                  blur: function ($event) {
                    _vm.focus = false
                  },
                },
              }),
        ]
      ),
      _vm.$slots.default || _vm.label
        ? _c(
            "span",
            { staticClass: "yxt-checkbox__label" },
            [
              _vm._t("default"),
              !_vm.$slots.default ? [_vm._v(_vm._s(_vm.label))] : _vm._e(),
            ],
            2
          )
        : _vm._e(),
    ]
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("span", { staticClass: "yxt-checkbox__inner--box" }, [
      _c("span", { staticClass: "yxt-checkbox__inner" }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }