<script>
  export default {
    name: 'YxtTag',
    props: {
      text: {
        type: String,
        desc: '文本'
      },
      closable: {
        type: Boolean,
        desc: '是否可关闭'
      },
      type: {
        type: String,
        desc: '类型'
      },
      hit: {
        type: Boolean,
        desc: '是否有边框描边'
      },
      isStatusTag: {
        type: Boolean,
        desc: '设置状态标签'
      },
      disableTransitions: {
        type: Boolean,
        desc: '是否禁用动画'
      },
      color: {
        type: String,
        desc: '背景色'
      },
      size: {
        type: String,
        desc: '尺寸'
      },
      picked: {
        type: Boolean,
        desc: '是否选中，type为pick时生效'
      },
      pickType: {
        type: String,
        default: 'fill',
        validator(val) {
          return ['mark', 'fill'].indexOf(val) !== -1;
        },
        desc: '选中状态类型，type为pick时生效'
      },
      effect: {
        type: String,
        default: 'light',
        validator(val) {
          return ['dark', 'light', 'plain'].indexOf(val) !== -1;
        },
        desc: '主题'
      },
      themeMode: {
        type: String,
        default: '',
        desc: '暗黑模式'
      },
      coverType: {
        type: String,
        default: '',
        desc: '封面角标类型'
      }
    },
    methods: {
      handleClose(event) {
        event.stopPropagation();
        this.$emit('close', event);
      },
      handleClick(event) {
        if (this.type === 'pick') {
          const picked = !this.picked;
          this.$emit('click', picked);
        } else {
          this.$emit('click', event);
        }
      }
    },
    computed: {
      tagSize() {
        return this.size || (this.$ELEMENT || {}).size;
      }
    },
    render(h) {
      const { type, tagSize, coverType, hit, effect, closable, picked, pickType, themeMode } = this;
      const classes = [
        'yxt-tag',
        type ? `yxt-tag--${type}` : '',
        coverType ? `yxt-tag-cover--${coverType}` : '',
        tagSize ? `yxt-tag--${tagSize}` : '',
        effect ? `yxt-tag--${effect}` : '',
        hit && 'is-hit',
        themeMode === 'dark' ? 'yxt-tag--theme-dark' : '',
        closable ? 'yxt-tag--closable' : '',
        (picked && type === 'pick') ? (pickType === 'mark' ? 'yxt-tag--pick-mark' : 'yxt-tag--pick-fill') : ''
      ];
      const tagEl = (
        <span
          class={ classes }
          style={{ backgroundColor: this.color }}
          on-click={ this.handleClick }>
          <div class="yxt-tag__default">
            { this.$slots.default }
          </div>
          {
            this.closable && <i class="yxt-tag__close" on-click={ this.handleClose }><yxt-svg width='12px' height='12px' icon-class='close' /></i>
          }
          {
            (type === 'pick' && picked && pickType === 'mark') && <i class="yxt-tag--pick-mark-icon" on-click={ this.handleClose }><yxt-svg width='13px' height='13px' icon-class='pick' class="yxt-tag--primary-color"/></i>
          }
        </span>
      );
      // 是否为状态标签
      const statusClasses = [
        'yxt-tag-status',
        type ? `yxt-tag-status--${type}` : ''
      ];
      const statusTag = (
        <span
          class={ statusClasses }
          on-click={ this.handleClick }>
          {
            <i style={{ backgroundColor: this.color }} class="yxt-tag__point"></i>
          }
          { this.$slots.default }
        </span>
      );

      return this.isStatusTag ? statusTag : this.disableTransitions ? tagEl : <transition name="yxt-zoom-in-center">{ tagEl }</transition>;
    }
  };
</script>
