<template>
  <yxt-popover
    popper-class="yxt-popconfirm-box"
    v-bind="$attrs"
    v-model="visible"
    trigger="click"
  >
  <div class="yxt-popconfirm">
    <div class="yxt-popconfirm__main">
    <!-- <i
      v-if="!hideIcon"
      :class="icon"
      class="yxt-popconfirm__icon"
      :style="{color: iconColor}"
    ></i> -->
      <yxt-svg v-if="!hideIcon" :width="iconSize" :height="iconSize" class='yxt-popconfirm__icon' :icon-class="icon" />
      <div v-if="title" :class="{'yxt-popconfirm--title': content}">{{title}}</div>
    </div>
    <div class="yxt-popconfirm__content" v-if="content">{{content}}</div>
    <div class="yxt-popconfirm__action">
      <yxt-checkbox v-if="showTip" @change="change">不再提示</yxt-checkbox>
      <yxt-button 
        size="small" 
        :type="cancelButtonType" 
        @click="cancel"
      >
        {{ displayCancelButtonText }}
      </yxt-button>
      <yxt-button 
        size="small" 
        :type="confirmButtonType" 
        @click="confirm"
      >
        {{ displayConfirmButtonText }}
      </yxt-button>
    </div>
  </div>
  <slot name="reference" slot="reference"></slot>
</yxt-popover>
</template>

<script>
import YxtPopover from '@backstage/popover';
import YxtButton from '@backstage/button';
import YxtSvg from '@backstage/svg-icon';
import {t} from '@utils/locale';
export default {
  name: 'YxtPopconfirm',
  props: {
    value: {
      type: Boolean,
      default: false
    },
    title: {
      type: String
    },
    content: {
      type: String
    },
    confirmButtonText: {
      type: String
    },
    cancelButtonText: {
      type: String
    },
    confirmButtonType: {
      type: String,
      default: 'primary'
    },
    cancelButtonType: {
      type: String,
      default: ''
    },
    icon: {
      type: String,
      default: 'message-warning'
    },
    iconSize: {
      type: Number,
      default: 16
    },
    // iconColor: {
    //   type: String,
    //   default: '#f90'
    // },
    hideIcon: {
      type: Boolean,
      default: false
    },
    showTip: {
      type: Boolean,
      default: false
    }
  },
  components: {
    YxtPopover,
    YxtButton,
    YxtSvg
  },
  data() {
    return {
      visible: this.value
    };
  },
  computed: {
    displayConfirmButtonText() {
      return this.confirmButtonText || t('pc_comp_popconfirm_confirmButtonText');
    },
    displayCancelButtonText() {
      return this.cancelButtonText || t('pc_comp_popconfirm_cancelButtonText');
    }
  },
  watch: {
    value: {
      handler: function handler(val) {
        this.visible = val;
      }
    }
  },
  methods: {
    confirm() {
      this.visible = false;
      this.$emit('confirm');
    },
    cancel() {
      this.visible = false;
      this.$emit('cancel');
    },
    change(val) {
      this.$emit('change', val);
    }
  }
};
</script>