var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "yxt-transfer" },
    [
      _c(
        "transfer-panel",
        _vm._b(
          {
            ref: "leftPanel",
            attrs: {
              data: _vm.sourceData,
              title: _vm.titles[0] || _vm.t("pc_comp_transfer_titles.0"),
              "default-checked": _vm.leftDefaultChecked,
              placeholder:
                _vm.filterPlaceholder ||
                _vm.t("pc_comp_transfer_filterPlaceholder"),
            },
            on: { "checked-change": _vm.onSourceCheckedChange },
          },
          "transfer-panel",
          _vm.$props,
          false
        ),
        [_vm._t("left-footer")],
        2
      ),
      _c(
        "div",
        { staticClass: "yxt-transfer__buttons" },
        [
          _c(
            "yxt-button",
            {
              class: [
                "yxt-transfer__button",
                _vm.hasButtonTexts ? "is-with-texts" : "",
              ],
              attrs: {
                type: "primary",
                disabled: _vm.rightChecked.length === 0,
              },
              nativeOn: {
                click: function ($event) {
                  return _vm.addToLeft($event)
                },
              },
            },
            [
              _c("i", { staticClass: "yxt-icon-arrow-left" }),
              _vm.buttonTexts[0] !== undefined
                ? _c("span", [_vm._v(_vm._s(_vm.buttonTexts[0]))])
                : _vm._e(),
            ]
          ),
          _c(
            "yxt-button",
            {
              class: [
                "yxt-transfer__button",
                _vm.hasButtonTexts ? "is-with-texts" : "",
              ],
              attrs: {
                type: "primary",
                disabled: _vm.leftChecked.length === 0,
              },
              nativeOn: {
                click: function ($event) {
                  return _vm.addToRight($event)
                },
              },
            },
            [
              _vm.buttonTexts[1] !== undefined
                ? _c("span", [_vm._v(_vm._s(_vm.buttonTexts[1]))])
                : _vm._e(),
              _c("i", { staticClass: "yxt-icon-arrow-right" }),
            ]
          ),
        ],
        1
      ),
      _c(
        "transfer-panel",
        _vm._b(
          {
            ref: "rightPanel",
            attrs: {
              data: _vm.targetData,
              title: _vm.titles[1] || _vm.t("pc_comp_transfer_titles.1"),
              "default-checked": _vm.rightDefaultChecked,
              placeholder:
                _vm.filterPlaceholder ||
                _vm.t("pc_comp_transfer_filterPlaceholder"),
            },
            on: { "checked-change": _vm.onTargetCheckedChange },
          },
          "transfer-panel",
          _vm.$props,
          false
        ),
        [_vm._t("right-footer")],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }