<!--
 * @FileDescription: 
 * @Author: Sylpheed
 * @Date: 2021-10-15 18:40:36
 * @LastEditors: Sylpheed
 * @LastEditTime: 2021-11-01 18:45:00
-->
<template>
  <transition name="yxt-zoom-in-top" @after-leave="doDestroy">
    <div
      v-show="showPopper"
      class="yxt-autocomplete-suggestion yxt-popper"
      :class="{ 'is-loading': !parent.hideLoading && parent.loading }"
      :style="{ width: dropdownWidth }"
      role="region">
      <yxt-scrollbar
        tag="ul"
        wrap-class="yxt-autocomplete-suggestion__wrap"
        view-class="yxt-autocomplete-suggestion__list">
        <li v-if="!parent.hideLoading && parent.loading"><i class="yxt-icon-loading"></i></li>
        <slot v-else>
        </slot>
      </yxt-scrollbar>
    </div>
  </transition>
</template>
<script>
  import Popper from '@utils/utils/vue-popper';
  import Emitter from '@utils/mixins/emitter';
  import ElScrollbar from '@backstage/scrollbar';

  export default {
    components: { ElScrollbar },
    mixins: [Popper, Emitter],

    componentName: 'YxtAutocompleteSuggestions',

    data() {
      return {
        parent: this.$parent,
        dropdownWidth: ''
      };
    },

    props: {
      options: {
        default() {
          return {
            gpuAcceleration: false
          };
        },
        desc: '选项集合'
      },
      appendToBody: {
        type: Boolean,
        default: true,
        desc: '是否将下拉列表插入至body元素'
      },
      id: String
    },

    methods: {
      select(item) {
        this.dispatch('YxtAutocomplete', 'item-click', item);
      }
    },

    updated() {
      this.$nextTick(_ => {
        this.popperJS && this.updatePopper();
      });
    },

    mounted() {
      if (!this.appendToBody) {
        this.offset = [0, 0];
      }
      this.$parent.popperElm = this.popperElm = this.$el;
      this.referenceElm = this.$parent.$refs.input.$refs.input;
      this.referenceList = this.$el.querySelector('.yxt-autocomplete-suggestion__list');
      this.referenceList.setAttribute('role', 'listbox');
      this.referenceList.setAttribute('id', this.id);
    },

    created() {
      this.$on('visible', (val, inputWidth) => {
        this.dropdownWidth = inputWidth + 'px';
        this.showPopper = val;
      });
    }
  };
</script>
