var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      class: ["yxt-cascader-panel", _vm.border && "is-bordered"],
      on: { keydown: _vm.handleKeyDown },
    },
    _vm._l(_vm.menus, function (menu, index) {
      return _c("cascader-menu", {
        key: index,
        ref: "menu",
        refInFor: true,
        attrs: { index: index, nodes: menu },
      })
    }),
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }