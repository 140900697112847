<template>
  <main class="yxt-main">
    <slot></slot>
  </main>
</template>

<script>
  export default {
    name: 'YxtfMain',
    componentName: 'YxtfMain'
  };
</script>
