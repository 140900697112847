var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("svg", {
    class: [
      "yxtf-svg-icon",
      _vm.iconClass == "loading"
        ? "yxtf-circular"
        : _vm.spin
        ? "yxtf-spin"
        : "",
    ],
    style: _vm.style,
    attrs: { "aria-hidden": "true" },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }