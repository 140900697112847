var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "show",
          rawName: "v-show",
          value: _vm.ready,
          expression: "ready",
        },
      ],
      staticClass: "yxt-carousel__item",
      class: {
        "is-active": _vm.active,
        "yxt-carousel__item--card": _vm.getParentByName.type === "card",
        "is-in-stage": _vm.inStage,
        "is-hover": _vm.hover,
        "is-animating": _vm.animating,
      },
      style: _vm.itemStyle,
      on: { click: _vm.handleItemClick },
    },
    [
      _vm.getParentByName.type === "card"
        ? _c("div", {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: !_vm.active,
                expression: "!active",
              },
            ],
            staticClass: "yxt-carousel__mask",
          })
        : _vm._e(),
      _vm._t("default"),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }