<template>
  <header class="yxt-header" :style="{ height }">
    <slot></slot>
  </header>
</template>

<script>
  export default {
    name: 'YxtHeader',

    componentName: 'YxtHeader',

    props: {
      height: {
        type: String,
        default: '60px'
      }
    }
  };
</script>
