<template>
  <div
    class="yxtf-select"
    :class="[selectSize ? 'yxtf-select--' + selectSize : '', size ? `yxtf-select--{size}` : '', collapseTags ? 'yxtf-select--collapse' : '',  visible ? 'yxtf-select--visible' : '']"
    @click.stop="toggleMenu"
    :style='selectStyle'
    v-clickoutside="handleClose"
  >
    <div
      class="yxtf-select__tags"
      :class="{'yxtf-select__collapse_tags': collapseTags && !visible}"
      v-if="multiple"
      ref="tags"
      :style="{ width: '100%' }"
    >
      <!-- :style="{ 'max-width': inputWidth - 32 + 'px', width: '100%' }" -->
      <yxtf-scrollbar :class="['test2']" :wrap-style="[{'max-height': collapseTags ? '110px' : '113px', 'padding': '1px 32px 1px 0'}]">
        <span v-if="collapseTags && selected.length && !visible">
          <yxtf-tag
            :closable="!selectDisabled"
            :size="collapseTagSize"
            :hit="selected[0].hitState"
            type="info"
            @close="deleteTag($event, selected[0])"
            disable-transitions
          >
            <span class="yxtf-select__tags-text">
              <slot name='tag' :data='selected[0]'>
               {{
                  selected[0].currentLabel
                }}
            </slot>
            </span>
          </yxtf-tag>
          <yxtf-tag
            v-if="selected.length > 1"
            :closable="false"
            :size="collapseTagSize"
            type="info"
            disable-transitions
          >
            <span class="yxtf-select__tags-text">+ {{ selected.length - 1 }}</span>
          </yxtf-tag>
        </span>
        <transition-group @after-leave="resetInputHeight" v-if="!collapseTags || visible">
          <yxtf-tag
            v-for="item in limitSelectedTags"
            :key="getValueKey(item)"
            :closable="!selectDisabled"
            :size="collapseTagSize"
            :hit="item.hitState"
            type="info"
            @close="deleteTag($event, item)"
            disable-transitions
          >
            <span class="yxtf-select__tags-text">
              <slot name='tag' :data='item'>
                {{
                  item.currentLabel
                }}
              </slot>
            </span>
          </yxtf-tag>
           <yxtf-tag
            key="collapseTag"
            v-if="selected.length > collpaseLimit"
            :closable="false"
            :size="collapseTagSize"
            type="info"
            disable-transitions
          >
            <span class="yxtf-select__tags-text">+ {{ selected.length - limitSelectedTags.length }}</span>
          </yxtf-tag>
        </transition-group>
        <!-- {{selected.length}}-{{collapseTags}}-{{visible}} -->
        <input
          type="text"
          class="yxtf-select__input"
          :class="[selectSize ? `is-${selectSize}` : '', {'no-tags': !selected.length}]"
          :disabled="selectDisabled"
          :autocomplete="autoComplete || autocomplete"
          @focus="handleFocus"
          @blur="softFocus = false"
          @keyup="managePlaceholder"
          @keydown="resetInputState"
          @keydown.down.prevent="navigateOptions('next')"
          @keydown.up.prevent="navigateOptions('prev')"
          @keydown.enter.prevent="selectOption"
          @keydown.esc.stop.prevent="visible = false"
          @keydown.delete="deletePrevTag"
          @keydown.tab="visible = false"
          @compositionstart="handleComposition"
          @compositionupdate="handleComposition"
          @compositionend="handleComposition"
          v-model="query"
          @input="debouncedQueryChange"
          v-if="filterable"
          :style="{
            'flex-grow': '1',
            width: inputLength / (inputWidth - 32) + '%',
            'max-width': inputWidth - 42 + 'px'
          }"
          ref="input"
        />
      </yxtf-scrollbar>
    </div>
    <div class="yxtf-select--popper">
      <yxtf-input
        ref="reference"
        v-model="selectedLabel"
        type="text"
        :placeholder="currentPlaceholder"
        :name="name"
        :id="id"
        :autocomplete="autoComplete || autocomplete"
        :size="selectSize"
        :disabled="selectDisabled"
        :readonly="readonly"
        :validate-event="false"
        :class="{ 'is-focus': visible }"
        :tabindex="multiple && filterable ? '-1' : null"
        @focus="handleFocus"
        @blur="handleBlur"
        @keyup.native="debouncedOnInputChange"
        @keydown.native.down.stop.prevent="navigateOptions('next')"
        @keydown.native.up.stop.prevent="navigateOptions('prev')"
        @keydown.native.enter.prevent="selectOption"
        @keydown.native.esc.stop.prevent="visible = false"
        @keydown.native.tab="visible = false"
        @paste.native="debouncedOnInputChange"
        @mouseenter.native="inputHovering = true"
        @mouseleave.native="inputHovering = false"
      >
        <template slot="prefix" v-if="$slots.prefix">
          <slot name="prefix"></slot>
        </template>
        <template slot="suffix">
          <i
            v-show="!showClose"
            :class="[
              'yxtf-select__caret',
              'yxtf-input__icon',
              'yxtf-icon-' + iconClass
            ]"
          ></i>
          <i
            v-if="showClose"
            class="yxtf-select__caret yxt-input__icon yxtf-icon-circle-close"
            @click="handleClearClick"
          ></i>
        </template>
      </yxtf-input>
      <transition
        name="yxtf-zoom-in-top"
        @before-enter="handleMenuEnter"
        @after-leave="doDestroy"
      >
        <yxtf-select-menu
          ref="popper"
          :append-to-body="popperAppendToBody"
          :menuMaxWidth="menuMaxWidth"
          v-show="visible && emptyText !== false"
        >
          <div v-if="$slots['fixed-header']" class="yxtf-select-dropdown__prefix">
            <slot name="fixed-header"></slot>
          </div>
          <yxtf-scrollbar
            tag="ul"
            @click.stop.native='scrollFocus'
            wrap-class="yxtf-select-dropdown__wrap"
            :wrap-style='wrapStyle'
            :view-class="viewClass"
            ref="scrollbar"
            :class="{
              'is-empty': !allowCreate && query && filteredOptionsCount === 0
            }"
            v-show="options.length > 0 && !loading"
          >
            <yxtf-option :value="query" created v-if="showNewOption"></yxtf-option>
            <slot></slot>
          </yxtf-scrollbar>

          <template
            v-if="
              emptyText &&
                (!allowCreate || loading || (allowCreate && options.length === 0))
            "
          >
            <slot name="empty" v-if="$slots.empty"></slot>
            <p class="yxtf-select-dropdown__empty" v-else>{{ emptyText }}</p>
          </template>
          <div v-if="$slots['fixed-footer']" class="yxtf-select-dropdown__suffix">
            <slot name="fixed-footer"></slot>
          </div>
        </yxtf-select-menu>
      </transition>
    </div>
  </div>
</template>

<script type="text/babel">
import Emitter from '@utils/mixins/emitter';
import Focus from '@utils/mixins/focus';
import Locale from '@utils/mixins/locale';
import YxtfInput from '@foreground/input';
import YxtfSelectMenu from './select-dropdown.vue';
import YxtfOption from './option.vue';
import YxtfTag from '@foreground/tag';
import YxtfScrollbar from '@foreground/scrollbar';
import debounce from 'throttle-debounce/debounce';
import Clickoutside from '@utils/utils/clickoutside';
import {
  addResizeListener,
  removeResizeListener
} from '@utils/utils/resize-event';
import { t } from '@utils/locale';
import scrollIntoView from '@utils/utils/scroll-into-view';
import {
  getValueByPath,
  valueEquals,
  isIE,
  isEdge
} from '@utils/utils/util';
import NavigationMixin from './navigation-mixin';
import { isKorean } from '@utils/utils/shared';
import scrollbarWidth from '@utils/utils/scrollbar-width';
const ITEM_HEIGHT = 36; // 下拉列表元素高度
const SELECT_SPACE = 8;

export default {
  mixins: [Emitter, Locale, Focus('reference'), NavigationMixin],

  name: 'YxtfSelect',

  componentName: 'YxtfSelect',

  inject: {
    yxtform: {
      default: ''
    },

    yxtformItem: {
      default: ''
    }
  },

  provide() {
    return {
      select: this
    };
  },

  computed: {
    _yxtformItemSize() {
      return (this.yxtformItem || {}).yxtformItemSize;
    },
    viewClass() {
      let classNames = ['yxt-select-dropdown__list'];
      if (this.hasTop) {
        // 有固定头部，去掉列表上边距
        classNames.push('yxt-select-dropdown__list-top');
      }

      if (this.hasFooter) {
        // 有固定底部，去掉列表下边距
        classNames.push('yxt-select-dropdown__list-bottom');
      }
      return classNames.join(' ');
    },
    selectStyle() {
      return this.visible && this.collapseTags ? {
        width: `${this.$refs.reference.$el.offsetWidth}px`
      } : {};
    },
    readonly() {
      return (
        !this.filterable ||
        this.multiple ||
        (!isIE() && !isEdge() && !this.visible)
      );
    },
    gutter() {
      return scrollbarWidth();
    },
    hasTop() {
      return this.$slots['fixed-header'];
    },
    hasFooter() {
      return this.$slots['fixed-footer'];
    },
    wrapStyle() {
      let maxHeight = ITEM_HEIGHT * 5;
      if (!this.hasFooter) {
        maxHeight += SELECT_SPACE;
      }

      if (!this.hasFooter) {
        maxHeight += SELECT_SPACE;
      }
      if (this.gutter) {
        maxHeight += this.gutter;
      }
      return `max-height: ${maxHeight}px;`;
    },
    showClose() {
      let hasValue = this.multiple
        ? Array.isArray(this.value) && this.value.length > 0
        : this.value !== undefined && this.value !== null && this.value !== '';
      let criteria =
        this.clearable &&
        !this.selectDisabled &&
        this.inputHovering &&
        hasValue;
      return criteria;
    },

    iconClass() {
      return this.iconName
        ? `${this.iconName} is-custom`
        : this.remote && this.filterable
          ? ''
          : this.visible
            ? 'arrow-up is-reverse'
            : 'arrow-up';
    },

    debounce() {
      return this.remote ? 300 : 0;
    },

    emptyText() {
      if (this.loading) {
        return this.loadingText || this.t('pc_comp_select_loading');
      } else {
        if (this.remote && this.query === '' && this.options.length === 0) {
          return false;
        }
        if (
          this.filterable &&
          this.query &&
          this.options.length > 0 &&
          this.filteredOptionsCount === 0
        ) {
          return this.noMatchText || this.t('pc_comp_select_noMatch');
        }
        if (this.options.length === 0) {
          return this.noDataText || this.t('pc_comp_select_noData');
        }
      }
      return null;
    },

    showNewOption() {
      let hasExistingOption = this.options
        .filter(option => !option.created)
        .some(option => option.currentLabel === this.query);
      return (
        this.filterable &&
        this.allowCreate &&
        this.query !== '' &&
        !hasExistingOption
      );
    },

    selectSize() {
      return this.size || this._yxtformItemSize || (this.$ELEMENT || {}).size;
    },

    selectDisabled() {
      return this.disabled || (this.yxtform || {}).disabled;
    },

    collapseTagSize() {
      return ['small', 'mini'].indexOf(this.selectSize) > -1 ? 'mini' : 'small';
    },

    limitSelectedTags() {
      if (!this.multiple) return this.selected;
      return this.selected.slice(0, this.collpaseLimit);
    }
  },

  components: {
    YxtfInput,
    YxtfSelectMenu,
    YxtfOption,
    YxtfTag,
    YxtfScrollbar
  },

  directives: { Clickoutside },

  props: {
    collpaseLimit: {
      default: 50,
      type: Number
    },
    name: String,
    propLabel: {
      default: '',
      type: String
    },
    id: String,
    value: {
      required: true
    },
    autocomplete: {
      type: String,
      default: 'off'
    },
    /** @Deprecated in next major version */
    autoComplete: {
      type: String,
      validator(val) {
        process.env.NODE_ENV !== 'production' &&
          console.warn(
            "[Yxt Warn][Select]'auto-complete' property will be deprecated in next major version. please use 'autocomplete' instead."
          );
        return true;
      }
    },
    automaticDropdown: Boolean,
    size: String,
    disabled: Boolean,
    clearable: Boolean,
    filterable: Boolean,
    allowCreate: Boolean,
    loading: Boolean,
    popperClass: String,
    remote: Boolean,
    loadingText: String,
    noMatchText: String,
    noDataText: String,
    remoteMethod: Function,
    filterMethod: Function,
    multiple: Boolean,
    menuMaxWidth: {
      type: Number,
      default: 0
    },
    multipleLimit: {
      type: Number,
      default: 0
    },
    placeholder: {
      type: String,
      default() {
        return t('pc_comp_select_placeholder');
      }
    },
    defaultFirstOption: Boolean,
    reserveKeyword: Boolean,
    valueKey: {
      type: String,
      default: 'value'
    },
    collapseTags: Boolean,
    popperAppendToBody: {
      type: Boolean,
      default: true
    },
    iconName: String
  },

  data() {
    return {
      mouseEnterEnable: true,
      options: [],
      cachedOptions: [],
      createdLabel: null,
      createdSelected: false,
      selected: this.multiple ? [] : {},
      inputLength: 20,
      inputWidth: 0,
      initialInputHeight: 0,
      cachedPlaceHolder: '',
      optionsCount: 0,
      filteredOptionsCount: 0,
      visible: false,
      softFocus: false,
      selectedLabel: '',
      hoverIndex: -1,
      query: '',
      previousQuery: null,
      inputHovering: false,
      currentPlaceholder: '',
      menuVisibleOnFocus: false,
      isOnComposition: false,
      isSilentBlur: false
    };
  },

  watch: {
    selectDisabled() {
      this.$nextTick(() => {
        this.resetInputHeight();
      });
    },

    placeholder(val) {
      this.cachedPlaceHolder = this.currentPlaceholder = val;
    },

    value(val, oldVal) {
      if (this.multiple) {
        this.resetInputHeight();
        if (
          (val && val.length > 0) ||
          (this.$refs.input && this.query !== '')
        ) {
          this.currentPlaceholder = '';
        } else {
          this.currentPlaceholder = this.cachedPlaceHolder;
        }
        if (this.filterable && !this.reserveKeyword) {
          this.query = '';
          this.handleQueryChange(this.query);
        }
      }
      this.setSelected();
      if (this.filterable && !this.multiple) {
        this.inputLength = 20;
      }
      if (!valueEquals(val, oldVal)) {
        this.dispatch('YxtfFormItem', 'yxtf.form.change', val);
      }
    },

    visible(val) {
      this.resetHoverIndex(val);

      if (!val) {
        this.broadcast('YxtfSelectDropdown', 'destroyPopper');
        if (this.$refs.input) {
          this.$refs.input.blur();
        }
        this.query = '';
        this.previousQuery = null;
        this.selectedLabel = '';
        this.inputLength = 20;
        this.menuVisibleOnFocus = false;
        this.$nextTick(() => {
          if (
            this.$refs.input &&
            this.$refs.input.value === '' &&
            this.selected.length === 0
          ) {
            this.currentPlaceholder = this.cachedPlaceHolder;
          }
        });
        if (!this.multiple) {
          if (this.selected) {
            if (
              this.filterable &&
              this.allowCreate &&
              this.createdSelected &&
              this.createdLabel
            ) {
              this.selectedLabel = this.createdLabel;
            } else {
              this.selectedLabel = this.selected.currentLabel;
            }
            if (this.filterable) this.query = this.selectedLabel;
          }

          if (this.filterable) {
            this.currentPlaceholder = this.cachedPlaceHolder;
          }
        }
      } else {
        this.broadcast('YxtfSelectDropdown', 'updatePopper');
        if (this.filterable) {
          this.query = this.remote ? '' : this.selectedLabel;
          this.handleQueryChange(this.query);
          if (this.multiple) {
            this.$refs.input.focus();
          } else {
            if (!this.remote) {
              this.broadcast('YxtOption', 'queryChange', '');
              this.broadcast('YxtOptionGroup', 'queryChange');
            }

            if (this.selectedLabel) {
              this.currentPlaceholder = this.selectedLabel;
              this.selectedLabel = '';
            }
          }
        }
      }
      this.$emit('visible-change', val);
      this.$nextTick(() => {
        this.resetInputHeight();
        setTimeout(() => {
          this.broadcast('YxtfSelectDropdown', 'updatePopper');
        }, 1000);
      });
    },

    options() {
      if (this.$isServer) return;
      this.$nextTick(() => {
        this.broadcast('YxtfSelectDropdown', 'updatePopper');
      });
      if (this.multiple) {
        this.resetInputHeight();
      }
      let inputs = this.$el.querySelectorAll('input');
      if ([].indexOf.call(inputs, document.activeElement) === -1) {
        this.setSelected();
      }
      if (
        this.defaultFirstOption &&
        (this.filterable || this.remote) &&
        this.filteredOptionsCount
      ) {
        this.checkDefaultFirstOption();
      }
    }
  },

  methods: {
    scrollFocus() {
      if (this.filterable) {
        this.$refs.input.focus();
      } else {
        this.$refs.reference.focus();
      }
    },
    handleComposition(event) {
      const text = event.target.value;
      if (event.type === 'compositionend') {
        this.isOnComposition = false;
        this.$nextTick(_ => this.handleQueryChange(text));
      } else {
        const lastCharacter = text[text.length - 1] || '';
        this.isOnComposition = !isKorean(lastCharacter);
      }
    },
    handleQueryChange(val) {
      if (this.previousQuery === val || this.isOnComposition) return;
      if (
        this.previousQuery === null &&
        (typeof this.filterMethod === 'function' ||
          typeof this.remoteMethod === 'function')
      ) {
        this.previousQuery = val;
        return;
      }
      this.previousQuery = val;
      this.$nextTick(() => {
        if (this.visible) this.broadcast('YxtfSelectDropdown', 'updatePopper');
      });
      this.hoverIndex = -1;
      if (this.multiple && this.filterable) {
        this.$nextTick(() => {
          const length = this.$refs.input.value.length * 15 + 20;
          this.inputLength = this.collapseTags ? Math.min(50, length) : length;
          this.managePlaceholder();
          this.resetInputHeight();
        });
      }
      if (this.remote && typeof this.remoteMethod === 'function') {
        this.hoverIndex = -1;
        this.remoteMethod(val);
      } else if (typeof this.filterMethod === 'function') {
        this.filterMethod(val);
        this.broadcast('YxtOptionGroup', 'queryChange');
      } else {
        this.filteredOptionsCount = this.optionsCount;
        this.broadcast('YxtOption', 'queryChange', val);
        this.broadcast('YxtOptionGroup', 'queryChange');
      }
      if (
        this.defaultFirstOption &&
        (this.filterable || this.remote) &&
        this.filteredOptionsCount
      ) {
        this.checkDefaultFirstOption();
      }
    },

    scrollToOption(option) {
      const target =
        Array.isArray(option) && option[0] ? option[0].$el : option.$el;
      if (this.$refs.popper && target) {
        const menu = this.$refs.popper.$el.querySelector(
          '.yxtf-select-dropdown__wrap'
        );
        scrollIntoView(menu, target);
      }
      this.$refs.scrollbar && this.$refs.scrollbar.handleScroll();
    },

    handleMenuEnter() {
      this.$nextTick(() => this.scrollToOption(this.selected));
    },

    emitChange(val) {
      if (!valueEquals(this.value, val)) {
        this.$emit('change', val);
      }
    },

    getOption(value) {
      // console.log('getOption +++ value: ', value);
      let option;
      const prop = this.propLabel;
      const isObject =
        Object.prototype.toString.call(value).toLowerCase() ===
        '[object object]';
      const isNull =
        Object.prototype.toString.call(value).toLowerCase() === '[object null]';
      const isUndefined =
        Object.prototype.toString.call(value).toLowerCase() ===
        '[object undefined]';
      // console.log('getOption +++ isObject: ', isObject, ' - isNull: ', isNull, ' - isUndefined: ', isUndefined);

      for (let i = this.cachedOptions.length - 1; i >= 0; i--) {
        const cachedOption = this.cachedOptions[i];
        // console.log('getOption +++ cachedOption: ', cachedOption);
        const isEqual = isObject
          ? getValueByPath(cachedOption.value, this.valueKey) ===
            getValueByPath(value, this.valueKey)
          : cachedOption.value === value;

        // console.log('getOption +++ isEqual: ', isEqual);
        if (isEqual) {
          option = cachedOption;
          break;
        }
      }
      if (option) return option;
      const label =
        !isObject && !isNull && !isUndefined
          ? value
          : isObject
            ? value[prop]
            : '';

      let newOption = {
        value: value,
        currentLabel: label
      };
      if (this.multiple) {
        newOption.hitState = false;
      }

      return newOption;
    },

    setSelected() {
      if (!this.multiple) {
        let option = this.getOption(this.value);
        // console.log('setSelected === option.created: ', option.created);
        if (option.created) {
          this.createdLabel = option.currentLabel;
          this.createdSelected = true;
        } else {
          this.createdSelected = false;
        }
        this.selectedLabel = option.currentLabel;
        this.selected = option;
        if (this.filterable) this.query = this.selectedLabel;
        return;
      }

      let result = [];
      // console.log('setSelected === option.created: ', option.created);
      // option.created = true;
      if (Array.isArray(this.value)) {
        this.value.forEach(value => {
          result.push(this.getOption(value));
        });
      }
      this.selected = result;
      this.$nextTick(() => {
        this.resetInputHeight();
      });
    },

    handleFocus(event) {
      if (!this.softFocus) {
        if (this.automaticDropdown || this.filterable) {
          this.visible = true;
          if (this.filterable) {
            this.menuVisibleOnFocus = true;
          }
        }
        this.$emit('focus', event);
      } else {
        this.softFocus = false;
      }
    },

    blur() {
      this.visible = false;
      this.$refs.reference.blur();
    },

    handleBlur(event) {
      setTimeout(() => {
        if (this.isSilentBlur) {
          this.isSilentBlur = false;
        } else {
          this.$emit('blur', event);
        }
      }, 50);
      this.softFocus = false;
    },

    handleClearClick(event) {
      this.deleteSelected(event);
    },

    doDestroy() {
      this.$refs.popper && this.$refs.popper.doDestroy();
    },

    handleClose() {
      this.visible = false;
    },

    toggleLastOptionHitState(hit) {
      if (!Array.isArray(this.selected)) return;
      const option = this.selected[this.selected.length - 1];
      if (!option) return;

      if (hit === true || hit === false) {
        option.hitState = hit;
        return hit;
      }

      option.hitState = !option.hitState;
      return option.hitState;
    },

    deletePrevTag(e) {
      if (e.target.value.length <= 0 && !this.toggleLastOptionHitState()) {
        const value = this.value.slice();
        value.pop();
        this.$emit('input', value);
        this.emitChange(value);
      }
    },

    managePlaceholder() {
      if (this.currentPlaceholder !== '') {
        this.currentPlaceholder = this.$refs.input.value
          ? ''
          : this.cachedPlaceHolder;
      }
    },

    resetInputState(e) {
      if (e.keyCode !== 8) this.toggleLastOptionHitState(false);
      this.inputLength = this.$refs.input.value.length * 15 + 20;
      this.resetInputHeight();
    },

    resetInputHeight() {
      this.$nextTick(() => {
        if (!this.$refs.reference) return;
        let inputChildNodes = this.$refs.reference.$el.childNodes;
        let input = [].filter.call(
          inputChildNodes,
          item => item.tagName === 'INPUT'
        )[0];
        const tags = this.$refs.tags;
        const sizeInMap = this.initialInputHeight || 40;
        input.style.height =
          this.selected.length === 0
            ? sizeInMap + 'px'
            : Math.max(
              tags
                ? tags.clientHeight + (tags.clientHeight > sizeInMap ? 6 : 0)
                : 0,
              sizeInMap
            ) + 'px';

        if (this.visible && this.emptyText !== false) {
          this.broadcast('YxtfSelectDropdown', 'updatePopper');
        }
      });
    },

    resetHoverIndex(visible) {
      if (visible) {
        this.hoverIndex = -1;
        return false;
      }
      setTimeout(() => {
        if (!this.multiple) {
          this.hoverIndex = this.options.indexOf(this.selected);
        } else {
          if (this.selected.length > 0) {
            this.hoverIndex = Math.min.apply(
              null,
              this.selected.map(item => this.options.indexOf(item))
            );
          } else {
            this.hoverIndex = -1;
          }
        }
      }, 300);
    },

    handleOptionSelect(option, byClick) {
      if (this.multiple) {
        const value = (this.value || []).slice();
        const optionIndex = this.getValueIndex(value, option.value);
        if (optionIndex > -1) {
          value.splice(optionIndex, 1);
        } else if (
          this.multipleLimit <= 0 ||
          value.length < this.multipleLimit
        ) {
          value.push(option.value);
        }
        this.$emit('input', value);
        this.emitChange(value);
        if (option.created) {
          this.query = '';
          this.handleQueryChange('');
          this.inputLength = 20;
        }
        if (this.filterable) this.$refs.input.focus();
      } else {
        this.$emit('input', option.value);
        this.emitChange(option.value);
        this.visible = false;
      }
      this.isSilentBlur = byClick;
      this.setSoftFocus();
      if (this.visible) return;
      this.$nextTick(() => {
        this.scrollToOption(option);
      });
    },

    setSoftFocus() {
      this.softFocus = true;
      const input = this.$refs.input || this.$refs.reference;
      if (input) {
        input.focus();
      }
    },

    getValueIndex(arr = [], value) {
      const isObject =
        Object.prototype.toString.call(value).toLowerCase() ===
        '[object object]';
      if (!isObject) {
        return arr.indexOf(value);
      } else {
        const valueKey = this.valueKey;
        let index = -1;
        arr.some((item, i) => {
          if (
            getValueByPath(item, valueKey) === getValueByPath(value, valueKey)
          ) {
            index = i;
            return true;
          }
          return false;
        });
        return index;
      }
    },

    toggleMenu() {
      if (!this.selectDisabled) {
        if (this.menuVisibleOnFocus) {
          this.menuVisibleOnFocus = false;
        } else {
          this.visible = !this.visible;
        }
        if (this.visible) {
          (this.$refs.input || this.$refs.reference).focus();
        }
      }
    },

    selectOption() {
      if (!this.visible) {
        this.toggleMenu();
      } else {
        if (this.options[this.hoverIndex]) {
          this.handleOptionSelect(this.options[this.hoverIndex]);
        }
      }
    },

    deleteSelected(event) {
      event.stopPropagation();
      const value = this.multiple ? [] : '';
      this.$emit('input', value);
      this.emitChange(value);
      this.visible = false;
      this.$emit('clear');
    },

    deleteTag(event, tag) {
      let index = this.selected.indexOf(tag);
      if (index > -1 && !this.selectDisabled) {
        const value = this.value.slice();
        value.splice(index, 1);
        this.$emit('input', value);
        this.emitChange(value);
        this.$emit('remove-tag', tag.value);
      }
      event.stopPropagation();
    },

    onInputChange() {
      if (this.filterable && this.query !== this.selectedLabel) {
        this.query = this.selectedLabel;
        this.handleQueryChange(this.query);
      }
    },

    onOptionDestroy(index) {
      if (index > -1) {
        this.optionsCount--;
        this.filteredOptionsCount--;
        this.options.splice(index, 1);
      }
    },

    resetInputWidth() {
      this.inputWidth = this.$refs.reference.$el.getBoundingClientRect().width;
    },

    handleResize() {
      this.resetInputWidth();
      if (this.multiple) this.resetInputHeight();
    },

    checkDefaultFirstOption() {
      this.hoverIndex = -1;
      // highlight the created option
      let hasCreated = false;
      for (let i = this.options.length - 1; i >= 0; i--) {
        if (this.options[i].created) {
          hasCreated = true;
          this.hoverIndex = i;
          break;
        }
      }
      if (hasCreated) return;
      for (let i = 0; i !== this.options.length; ++i) {
        const option = this.options[i];
        if (this.query) {
          // highlight first options that passes the filter
          if (!option.disabled && !option.groupDisabled && option.visible) {
            this.hoverIndex = i;
            break;
          }
        } else {
          // highlight currently selected option
          if (option.itemSelected) {
            this.hoverIndex = i;
            break;
          }
        }
      }
    },

    getValueKey(item) {
      if (
        Object.prototype.toString.call(item.value).toLowerCase() !==
        '[object object]'
      ) {
        return item.value;
      } else {
        return getValueByPath(item.value, this.valueKey);
      }
    }
  },

  created() {
    this.cachedPlaceHolder = this.currentPlaceholder = this.placeholder;
    if (this.multiple && !Array.isArray(this.value)) {
      this.$emit('input', []);
    }
    if (!this.multiple && Array.isArray(this.value)) {
      this.$emit('input', '');
    }

    this.debouncedOnInputChange = debounce(this.debounce, () => {
      this.onInputChange();
    });

    this.debouncedQueryChange = debounce(this.debounce, e => {
      this.handleQueryChange(e.target.value);
    });

    this.$on('handleOptionClick', this.handleOptionSelect);
    this.$on('setSelected', this.setSelected);
  },

  mounted() {
    if (this.multiple && Array.isArray(this.value) && this.value.length > 0) {
      this.currentPlaceholder = '';
    }
    addResizeListener(this.$el, this.handleResize);

    const reference = this.$refs.reference;
    if (reference && reference.$el) {
      const sizeMap = {
        medium: 36,
        small: 32,
        mini: 28
      };
      const input = reference.$el.querySelector('input');
      this.initialInputHeight =
        input.offsetHeight || sizeMap[this.selectSize];
    }
    if (this.remote && this.multiple) {
      this.resetInputHeight();
    }
    this.$nextTick(() => {
      if (reference && reference.$el) {
        this.inputWidth = reference.$el.getBoundingClientRect().width;
      }
    });
    this.setSelected();
  },

  beforeDestroy() {
    if (this.$el && this.handleResize) {
      removeResizeListener(this.$el, this.handleResize);
    }
  }
};
</script>
