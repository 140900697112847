<template>
  <transition
    name="dialog-fade"
    @after-enter="afterEnter"
    @after-leave="afterLeave"
  >
    <div
      v-show="visible"
      class="yxtf-dialog__wrapper"
      @click.self="handleWrapperClick"
    >
      <div
        role="dialog"
        :key="key"
        aria-modal="true"
        :aria-label="title || 'dialog'"
        :class="[
          'yxtf-dialog',
          {
            'is-fullscreen': fullscreen,
            'yxtf-dialog--center': center,
            'yxtf-dialog--cutline': cutline,
            'yxtf-dialog--larger': paddingSize == 'larger',
            'yxtf-dialog--medium': paddingSize == 'medium',
            'yxtf-dialog--small': paddingSize == 'small',
            'yxtf-dialog--empty': paddingSize == 'empty',
          },
          customClass,
        ]"
        ref="dialog"
        :style="style"
      >
        <div
          class="yxtf-dialog__header"
          ref="dialogHeader"
          v-if="$slots.title || title"
        >
          <slot name="title">
            <span class="yxtf-dialog__title">{{ title }}</span>
          </slot>
        </div>
        <button
          type="button"
          class="yxtf-dialog__headerbtn"
          aria-label="Close"
          v-if="showClose"
          style="z-index:999"
          @click="handleClose"
        >
          <i class="yxtf-dialog__close yxtf-icon yxtf-icon-close"></i>
        </button>
          <div ref="dialogBody" class="yxtf-dialog__body" v-if="rendered">
            <!-- <yxtf-scrollbar :style="style" class="yxtf-dialog__body__scrollbar"> -->
            <!-- <yxtf-scrollbar fitHeight :wrapStyle="{'maxHeight':scrollHeight}" :style="style" class="yxtf-dialog__body__scrollbar"> -->
              <slot></slot>
            <!-- </yxtf-scrollbar> -->
          </div>
        <div class="yxtf-dialog__footer" v-if="$slots.footer">
          <slot name="footer"></slot>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
import Popup from '@utils/utils/popup';
import Migrating from '@utils/mixins/migrating';
import emitter from '@utils/mixins/emitter';
import YxtfScrollbar from '@foreground/scrollbar';

export default {
  name: 'YxtfDialog',
  components: { YxtfScrollbar },
  mixins: [Popup, emitter, Migrating],

  props: {
    paddingSize: {
      validator: function(val) {
        switch (val) {
          case 'larger':
          case 'medium':
          case 'small':
          case 'empty':
            return val;
          default:
            return 'default';
        }
      }
    },

    title: {
      type: String,
      default: ''
    },

    modal: {
      type: Boolean,
      default: true
    },

    modalAppendToBody: {
      type: Boolean,
      default: true
    },

    appendToBody: {
      type: Boolean,
      default: false
    },

    lockScroll: {
      type: Boolean,
      default: true
    },

    closeOnClickModal: {
      type: Boolean,
      default: true
    },

    closeOnPressEscape: {
      type: Boolean,
      default: true
    },

    showClose: {
      type: Boolean,
      default: true
    },

    width: String,
    scrollHeight: String,

    fullscreen: Boolean,

    customClass: {
      type: String,
      default: ''
    },

    top: {
      type: String,
      default: '15vh'
    },
    beforeClose: Function,
    center: {
      type: Boolean,
      default: false
    },

    destroyOnClose: Boolean,
    cutline: {
      type: Boolean,
      default: true
    }
  },

  data() {
    return {
      closed: false,
      key: 0
    };
  },

  watch: {
    visible(val) {
      if (val) {
        this.closed = false;
        this.$emit('open');
        this.$el.addEventListener('scroll', this.updatePopper);
        this.$nextTick(() => {
          this.$refs.dialog.scrollTop = 0;
        });
        if (this.appendToBody) {
          document.body.appendChild(this.$el);
        }
      } else {
        this.$el.removeEventListener('scroll', this.updatePopper);
        if (this.destroyOnClose) {
          this.rendered = false;
        }
        if (!this.closed) {
          this.$emit('close');
        }
      }
    }
  },

  computed: {
    style() {
      let style = {};
      if (!this.fullscreen) {
        // style.marginTop = this.top;
        if (this.width) {
          style.width = this.width;
        }
        // if (this.scrollHeight) {
        //   style.height = this.scrollHeight;
        //   style.maxHeight = this.scrollHeight;
        // }
      }
      return style;
    }
  },

  methods: {
    getMigratingConfig() {
      return {
        props: {
          size: 'size is removed.'
        }
      };
    },
    handleWrapperClick() {
      if (!this.closeOnClickModal) return;
      this.handleClose();
    },
    handleClose() {
      if (typeof this.beforeClose === 'function') {
        this.beforeClose(this.hide);
      } else {
        this.hide();
      }
    },
    hide(cancel) {
      if (cancel !== false) {
        this.$emit('update:visible', false);
        this.$emit('close');
        this.closed = true;
      }
    },
    updatePopper() {
      this.broadcast('YxtSelectDropdown', 'updatePopper');
      this.broadcast('YxtDropdownMenu', 'updatePopper');
    },
    afterEnter() {
      this.$emit('opened');
    },
    afterLeave() {
      this.$emit('closed');
    }
  },

  mounted() {
    if (this.visible) {
      this.rendered = true;
      this.open();
      // if (this.appendToBody) {
      //   document.body.appendChild(this.$el);
      // }
    }
  },

  destroyed() {
    // if appendToBody is true, remove DOM node after destroy
    if (this.appendToBody && this.$el && this.$el.parentNode) {
      this.$el.parentNode.removeChild(this.$el);
    }
  }
};
</script>
