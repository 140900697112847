<template>
  <component :is="tag" class="yxtf-infinite-list">
    <slot></slot>
    <div class="yxtf-infinite-list-loading" :class="{'is-page': isPage}" v-show="loading">
      <slot name="loading">
        <span class="yxt-infinite-loading-circle">
          <svg viewBox="0 0 16 16"><circle cx="8" cy="8" r="7" fill="none" class="path"></circle></svg>
        </span>
        <span class="color-gray-7 font-size-14">{{ loadingText }}</span>
      </slot>
    </div>
    <div class="yxtf-infinite-list-finished" :class="{'is-page': isPage}" v-show="showFinishText && finished">
      <slot name="finished">
        <div class="font-size-14 color-gray-7">{{ finishedText }}</div>
      </slot>
    </div>
    <div ref="placeholder"></div>
  </component>
</template>

<script>
import { t } from '@utils/locale';
import {
  getScrollContainer
} from '@utils/utils/dom';
function isHidden(element) {
  return (
    window.getComputedStyle(element).display === 'none' || element.offsetParent === null
  );
}
export default {
  name: 'YxtfInfiniteList',
  props: {
    tag: {
      type: String,
      default: 'div'
    },
    error: {
      type: Boolean,
      default: false
    },
    loadingText: {
      type: String,
      default: ()=> { return t('pc_comp_select_loading'); }
    },
    errorText: {
      type: String
    },
    loading: {
      type: Boolean
    },
    finished: {
      type: Boolean
    },
    finishedText: {
      type: String,
      default: ()=> { return t('pc_comp_infinite_content'); }
    },
    offset: {
      type: Number,
      default: 0
    },
    isPage: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      showFinishText: false
    };
  },
  watch: {
    loading: 'scollHandler',
    finished: 'scollHandler'
  },
  mounted() {
    this.scroller = getScrollContainer(this.$el, true);
    this.scroller.addEventListener('scroll', this.scollHandler);
    this.scollHandler();
  },
  methods: {
    scollHandler() {
      this.$nextTick(() => {
        const { $el: el, scroller, offset } = this;

        if (this.loading || this.finished || this.error) {
          const rectHeight = scroller.clientHeight || scroller.innerHeight;
          const scrollHeight = (scroller === window ? this.$el : scroller).scrollHeight;
          this.showFinishText = scrollHeight >= rectHeight || this.finished;
          return;
        }

        let scrollerRect;

        if (scroller.getBoundingClientRect) {
          scrollerRect = scroller.getBoundingClientRect();
        } else {
          scrollerRect = {
            top: 0,
            bottom: scroller.innerHeight
          };
        }

        const scrollerHeight = scrollerRect.bottom - scrollerRect.top;

        /* istanbul ignore next */
        if (!scrollerHeight || isHidden(el)) {
          return false;
        }

        const placeholderRect = this.$refs.placeholder.getBoundingClientRect();

        let isReachEdge = placeholderRect.bottom - scrollerRect.bottom <= offset;

        if (isReachEdge) {
          this.$emit('load');
        }
      });
    }
  }
};
</script>

<style lang="scss" scoped>

</style>