<template>
  <div class="tree-scroll-wrap" :style="wrapStyle">
    <yxt-scrollbar v-if="openScroll" ref="scrollbar">
      <yxt-treelist
        :virtual='virtual'
        :data="data"
        :emptyText="emptyText"
        :renderAfterExpand="renderAfterExpand"
        :nodeKey="nodeKey"
        :checkStrictly="checkStrictly"
        :defaultExpandAll="defaultExpandAll"
        :expandOnClickNode="expandOnClickNode"
        :checkOnClickNode="checkOnClickNode"
        :checkDescendants="checkDescendants"
        :autoExpandParent="autoExpandParent"
        :defaultCheckedKeys="defaultCheckedKeys"
        :defaultExpandedKeys="defaultExpandedKeys"
        :currentNodeKey="currentNodeKey"
        :renderContent="renderContent"
        :showCheckbox="showCheckbox"
        :draggable="draggable"
        :allowDrag="allowDrag"
        :allowDrop="allowDrop"
        :props="props"
        :lazy="lazy"
        :highlightCurrent="highlightCurrent"
        :load="load"
        :filterNodeMethod="filterNodeMethod"
        :accordion="accordion"
        :indent="indent"
        :iconClass="iconClass"
        ref="treelist"
        >
      </yxt-treelist>
    </yxt-scrollbar>
      <box  v-else>
          <yxt-treelist
            :virtual='virtual'
            :data="data"
            :emptyText="emptyText"
            :renderAfterExpand="renderAfterExpand"
            :nodeKey="nodeKey"
            :checkStrictly="checkStrictly"
            :defaultExpandAll="defaultExpandAll"
            :expandOnClickNode="expandOnClickNode"
            :checkOnClickNode="checkOnClickNode"
            :checkDescendants="checkDescendants"
            :autoExpandParent="autoExpandParent"
            :defaultCheckedKeys="defaultCheckedKeys"
            :defaultExpandedKeys="defaultExpandedKeys"
            :currentNodeKey="currentNodeKey"
            :renderContent="renderContent"
            :showCheckbox="showCheckbox"
            :draggable="draggable"
            :allowDrag="allowDrag"
            :allowDrop="allowDrop"
            :props="props"
            :lazy="lazy"
            :highlightCurrent="highlightCurrent"
            :load="load"
            :filterNodeMethod="filterNodeMethod"
            :accordion="accordion"
            :indent="indent"
            :iconClass="iconClass"
            ref="treelist"
            >
          </yxt-treelist>
        </box>
  </div>
</template>

<script>
import YxtTreelist from './tree.vue';
import box from './box.vue';
import YxtScrollbar from '@backstage/scrollbar';
import { t } from '@utils/locale';

export default {
  name: 'YxtTree',

  components: {
    YxtTreelist,
    box,
    YxtScrollbar
  },

  props: {
    data: {
      type: Array
    },
    virtual: {
      type: Boolean,
      default: false
    },
    emptyText: {
      type: String,
      default() {
        return t('pc_comp_tree_emptyText');
      }
    },
    renderAfterExpand: {
      type: Boolean,
      default: true
    },
    nodeKey: String,
    checkStrictly: Boolean,
    defaultExpandAll: Boolean,
    expandOnClickNode: {
      type: Boolean,
      default: true
    },
    checkOnClickNode: Boolean,
    checkDescendants: {
      type: Boolean,
      default: false
    },
    autoExpandParent: {
      type: Boolean,
      default: true
    },
    defaultCheckedKeys: Array,
    defaultExpandedKeys: Array,
    currentNodeKey: [String, Number],
    renderContent: Function,
    showCheckbox: {
      type: Boolean,
      default: false
    },
    draggable: {
      type: Boolean,
      default: false
    },
    allowDrag: Function,
    allowDrop: Function,
    props: {
      default() {
        return {
          children: 'children',
          label: 'label',
          disabled: 'disabled',
          lazyChildren: 'lazyChildren',
          nocheck: 'nocheck'
        };
      }
    },
    lazy: {
      type: Boolean,
      default: false
    },
    highlightCurrent: Boolean,
    load: Function,
    filterNodeMethod: Function,
    accordion: Boolean,
    indent: {
      type: Number,
      default: 28
    },
    iconClass: String,
    wrapWidth: {
      type: [String, Number],
      default: 320
    },
    openScroll: {
      type: Boolean,
      default: true
    }
  },

  computed: {
    wrapStyle() {
      const width = typeof this.wrapWidth === 'number' || this.wrapWidth.match(/\d$/) ? `${this.wrapWidth}px` : this.wrapWidth;
      return {
        width
      };
    }
  },

  methods: {
    filter(value) {
      this.$refs.treelist.filter(value);
    },

    setHugeData(data) {
      return this.$refs.treelist.setHugeData(data);
    },

    getCheckedNodes(leafOnly, includeHalfChecked) {
      return this.$refs.treelist.getCheckedNodes(leafOnly, includeHalfChecked);
    },

    getCheckedKeys(leafOnly) {
      return this.$refs.treelist.getCheckedKeys(leafOnly);
    },

    getCurrentNode() {
      return this.$refs.treelist.getCurrentNode();
    },

    getCurrentKey() {
      return this.$refs.treelist.getCurrentKey();
    },
    cleardefaultExpandedKeys() {
      return this.$refs.treelist.cleardefaultExpandedKeys();
    },

    setCheckedNodes(nodes, leafOnly) {
      this.$refs.treelist.setCheckedNodes(nodes, leafOnly);
    },

    setCheckedKeys(keys, leafOnly) {
      this.$refs.treelist.setCheckedKeys(keys, leafOnly);
    },

    setChecked(data, checked, deep) {
      this.$refs.treelist.setChecked(data, checked, deep);
    },

    getHalfCheckedNodes() {
      return this.$refs.treelist.getHalfCheckedNodes();
    },

    getHalfCheckedKeys() {
      return this.$refs.treelist.getHalfCheckedKeys();
    },

    setCurrentNode(node) {
      this.$refs.treelist.setCurrentNode(node);
    },

    setCurrentKey(key) {
      this.$refs.treelist.setCurrentKey(key);
    },

    getNode(data) {
      return this.$refs.treelist.getNode(data);
    },

    remove(data) {
      this.$refs.treelist.remove(data);
    },

    append(data, parentNode) {
      this.$refs.treelist.append(data, parentNode);
    },

    insertBefore(data, refNode) {
      this.$refs.treelist.insertBefore(data, refNode);
    },

    insertAfter(data, refNode) {
      this.$refs.treelist.insertAfter(data, refNode);
    },

    updateKeyChildren(key, data) {
      this.$refs.treelist.updateKeyChildren(key, data);
    }
  }
};
</script>
