<template>
  <transition name="yxt-alert-fade">
    <div
      class="yxt-alert"
      :class="[typeClass, center ? 'is-center' : '', 'is-' + effect,showIcon ? 'yxt-alert-svg' : '']"
      v-show="visible"
      :style="{backgroundColor:bgColor }"
      role="alert"
    >
      <div  :class="['yxt-alert__content',showIcon ? 'yxt-alert__svg' : '']">
        <yxt-svg v-if="showIcon" class='color-gray-6 hover-primary-6' width='16px' height='16px' :icon-class="iconName" />
        <span ref="title" class="yxt-alert__title" :class="[ isBoldTitle ]" v-if="title || $slots.title">
          <slot name="title">{{ title }}</slot>
        </span>
        <p class="yxt-alert__description" v-if="$slots.default && !description"><slot></slot></p>
        <p class="yxt-alert__description" v-if="description && !$slots.default">{{ description }}</p>
      </div>
      <i class="yxt-alert__closebtn" :class="{ 'is-customed': closeText !== '', 'yxt-icon-close': closeText === '' }" v-show="closable" @click="close()">{{closeText}}</i>
    </div>
  </transition>
</template>

<script type="text/babel">
  const TYPE_CLASSES_MAP = {
    'success': 'yxt-icon-success',
    'warning': 'yxt-icon-warning',
    'error': 'yxt-icon-error',
    'regular': 'yxt-icon-regular'
  };
  const TYPE_ICON_MAP = {
    'info': 'message-info',
    'success': 'message-success',
    'warning': 'message-warning',
    'error': 'message-error',
    'regular': 'message-info'
  };
  export default {
    name: 'YxtAlert',
    props: {
      title: {
        type: String,
        default: ''
      },
      description: {
        type: String,
        default: ''
      },
      type: {
        type: String,
        default: 'info'
      },
      closable: {
        type: Boolean,
        default: true
      },
      closeText: {
        type: String,
        default: ''
      },
      showIcon: Boolean,
      center: Boolean,
      effect: {
        type: String,
        default: 'light',
        validator: function(value) {
          return ['light', 'dark'].indexOf(value) !== -1;
        }
      },
      bgColor: {
        type: String,
        desc: '背景色'
      }
    },

    data() {
      return {
        visible: true
      };
    },
    methods: {
      close() {
        this.visible = false;
        this.$emit('close');
      }
    },
    computed: {
      typeClass() {
        return `yxt-alert--${ this.type }`;
      },

      iconClass() {
        return TYPE_CLASSES_MAP[this.type] || 'yxt-icon-info';
      },

      isBigIcon() {
        return this.description || this.$slots.default ? 'is-big' : '';
      },

      isBoldTitle() {
        return this.description || this.$slots.default ? 'is-bold' : '';
      },

      iconName() {
        return TYPE_ICON_MAP[this.type];
      }
    }
  };
</script>
