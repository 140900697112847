<template>
  <div class="yxt-tabs__active-bar" :class="`is-${ rootTabs.tabPosition }`" :style="barStyle"></div>
</template>
<script>
  import { arrayFind, getParentByName } from '@utils/utils/util';
  const parentName = 'TabNav';
  export default {
    name: 'TabBar',

    props: {
      tabs: Array,
      editable: Boolean,
      closable: Boolean,
      type: String
    },

    inject: ['rootTabs'],

    computed: {
      barStyle: {
        get() {
          let style = {};
          let offset = 0;
          let tabSize = 0;
          const sizeName = ['top', 'bottom'].indexOf(this.rootTabs.tabPosition) !== -1 ? 'width' : 'height';
          const sizeDir = sizeName === 'width' ? 'x' : 'y';
          const firstUpperCase = str => {
            return str.toLowerCase().replace(/( |^)[a-z]/g, (L) => L.toUpperCase());
          };
          this.tabs.every((tab, index) => {
            let $el = arrayFind(getParentByName(this, parentName).$refs.tabs || [], t => t.id.replace('tab-', '') === tab.paneName);
            if (!$el) { return false; }

            if (!tab.active) {
              offset += $el[`client${firstUpperCase(sizeName)}`];
              return true;
            } else {
              tabSize = $el[`client${firstUpperCase(sizeName)}`];
              const tabStyles = window.getComputedStyle($el);
              if (sizeName === 'width') {
                // if (this.type === 'special') {
                //   tabSize = $el.children[0].offsetWidth - 16;
                //   offset += (parseFloat(tabStyles.width) - $el.children[0].offsetWidth) / 2;
                // } else {
                let lft = 0;
                const wordSize = tabSize - parseFloat(tabStyles.paddingLeft) - parseFloat(tabStyles.paddingRight);
                tabSize = wordSize;
                if (wordSize < 32) {
                  lft = (wordSize - 16) / 2;
                } else {
                  lft = (wordSize - 24) / 2;
                }
                offset += parseFloat(tabStyles.paddingLeft) + lft;
                // }
              }
              return false;
            }
          });
          if (this.editable || this.closable) {
            if (sizeName === 'width') {
              tabSize = 24;
            } else {
              tabSize = tabSize < 36 ? 12 : tabSize;
            }
          } else if (!this.editable && sizeName !== 'height') {
            tabSize = 24;
          } else if (!this.editable && sizeName !== 'width') {
            tabSize = 14;
          }

          let transform = '';
          if (this.editable || this.closable) {
            offset = offset - 12; // 减去关闭按钮的宽度/2
            transform = `translate${firstUpperCase(sizeDir)}(${offset}px)`;
          } else {
            transform = `translate${firstUpperCase(sizeDir)}(${offset}px)`;
          }
          style[sizeName] = tabSize + 'px';
          style.transform = transform;
          style.msTransform = transform;
          style.webkitTransform = transform;

          return style;
        }
      }
    }
  };
</script>
