<template>
  <div class="yxt-form-item" :class="[{
      'yxt-form-item--feedback': yxtForm && yxtForm.statusIcon,
      'is-error': validateState === 'error',
      'is-validating': validateState === 'validating',
      'is-success': validateState === 'success',
      'is-required': isRequired || required,
      'is-no-asterisk': yxtForm && yxtForm.hideRequiredAsterisk
    },
    sizeClass ? 'yxt-form-item--' + sizeClass : ''
  ]">
    <label-wrap
      :is-auto-width="labelStyle && labelStyle.width === 'auto'"
      :update-all="form.labelWidth === 'auto'">
      <label :for="labelFor" class="yxt-form-item__label" :style="labelStyle" v-if="label || $slots.label">
        <span class="yxt-form-item__tooltip ml4" v-if="!isTopLabel">
          <yxt-tooltip :effect="tooltipEffect || form.tooltipEffect" :content="labelTooltip" placement="top-start" :offset="10" v-if="labelTooltip" :max-width="labelTooltipWidth">
            <yxt-svg remote-url='' class='color-gray-6 hover-primary-6' width='16px' height='16px'  style="cursor: pointer;" icon-class="question-cirlce-o" />
          </yxt-tooltip>
        </span>
        <span class="yxt-form-item__labelcontent">
          <slot name="label">
            <yxt-popover
              placement="top"
              trigger="hover"
              :effect="tooltipEffect || form.tooltipEffect"
              slot="reference"
              :open-filter="!isClamped"
              :content="label + form.labelSuffix">
              <vue-clamp class="yxt-form-clamp" @clampchange='clampchange' slot="reference" :maxLines='2'>
                {{label + form.labelSuffix}}
              </vue-clamp>
            </yxt-popover>
          </slot>
        </span>
        <span class="yxt-form-item__required" v-if="!(isTopLabel && !(isRequired || required))" ><i class="required-dot" v-if="isRequired || required"/></span>
        <span class="yxt-form-item__tooltip ml4" v-if="isTopLabel">
          <yxt-tooltip :effect="tooltipEffect || form.tooltipEffect" :content="labelTooltip" placement="top-start" :offset="10" v-if="labelTooltip" :max-width="labelTooltipWidth">
            <yxt-svg remote-url='' class='color-gray-6 hover-primary-6' width='16px' height='16px' style="cursor: pointer;"  icon-class="question-cirlce-o" />
          </yxt-tooltip>
        </span>
      </label>
    </label-wrap>
    <div class="yxt-form-item__content" :style="contentStyle">
      <slot></slot>
      <div class="yxt-form-item__description text-8c" v-if="$slots.description"><slot name="description"></slot></div>
      <div class="yxt-form-item__description text-8c" v-else-if="description">{{description}}</div>
      <transition name="yxt-zoom-in-top">
        <slot
          v-if="validateState === 'error' && showMessage && form.showMessage"
          name="error"
          :error="validateMessage">
          <div
            class="yxt-form-item__error"
            :class="{
              'yxt-form-item__error--inline': typeof inlineMessage === 'boolean'
                ? inlineMessage
                : (yxtForm && yxtForm.inlineMessage || false)
            }"
          >
            {{validateMessage}}
          </div>
        </slot>
      </transition>
    </div>
  </div>
</template>
<script>
  import AsyncValidator from 'async-validator';
  import emitter from '@utils/mixins/emitter';
  import objectAssign from '@utils/utils/merge';
  import { noop, getPropByPath } from '@utils/utils/util';
  import LabelWrap from './label-wrap';
  import YxtTooltip from '@backstage/tooltip';
  import VueClamp from 'vue-clamp';
  export default {
    name: 'YxtFormItem',

    componentName: 'YxtFormItem',

    mixins: [emitter],

    provide() {
      return {
        yxtFormItem: this
      };
    },

    inject: ['yxtForm'],

    props: {
      label: String,
      labelWidth: String,
      prop: String,
      required: {
        type: Boolean,
        default: undefined
      },
      rules: [Object, Array],
      error: String,
      validateStatus: String,
      for: String,
      inlineMessage: {
        type: [String, Boolean],
        default: ''
      },
      showMessage: {
        type: Boolean,
        default: true
      },
      size: String,
      labelTooltip: String,
      labelTooltipWidth: {
        type: Number,
        default: 440
      },
      description: String,
      tooltipEffect: {
        type: String,
        default: 'dark'
      }
    },
    components: {
      // use this component to calculate auto width
      LabelWrap,
      YxtTooltip,
      VueClamp
    },
    watch: {
      error: {
        immediate: true,
        handler(value) {
          this.validateMessage = value;
          this.validateState = value ? 'error' : '';
        }
      },
      validateStatus(value) {
        this.validateState = value;
      }
    },
    computed: {
      labelFor() {
        return this.for || this.prop;
      },
      labelStyle() {
        const ret = {};
        if (this.form.labelPosition === 'top') return ret;
        const labelWidth = this.labelWidth || this.form.labelWidth;
        if (labelWidth) {
          ret.width = labelWidth;
        }
        return ret;
      },
      isTopLabel() {
        return this.form.labelPosition === 'top';
      },
      contentStyle() {
        const ret = {};
        const label = this.label;
        if (this.form.labelPosition === 'top' || this.form.inline) return ret;
        if (!label && !this.labelWidth && this.isNested) return ret;
        const labelWidth = this.labelWidth || this.form.labelWidth;
        if (labelWidth === 'auto') {
          if (this.labelWidth === 'auto') {
            ret.marginLeft = this.computedLabelWidth;
          } else if (this.form.labelWidth === 'auto') {
            ret.marginLeft = this.yxtForm.autoLabelWidth;
          }
        } else {
          ret.marginLeft = labelWidth;
        }
        return ret;
      },
      form() {
        let parent = this.$parent;
        let parentName = parent.$options.componentName;
        while (parentName !== 'YxtForm') {
          if (parentName === 'YxtFormItem') {
            this.isNested = true;
          }
          parent = parent.$parent;
          parentName = parent.$options.componentName;
        }
        return parent;
      },
      fieldValue() {
        const model = this.form.model;
        if (!model || !this.prop) { return; }

        let path = this.prop;
        if (path.indexOf(':') !== -1) {
          path = path.replace(/:/, '.');
        }

        return getPropByPath(model, path, true).v;
      },
      isRequired() {
        let rules = this.getRules();
        let isRequired = false;

        if (rules && rules.length) {
          rules.every(rule => {
            if (rule.required) {
              isRequired = true;
              return false;
            }
            return true;
          });
        }
        return isRequired;
      },
      _formSize() {
        return this.yxtForm.size;
      },
      yxtFormItemSize() {
        return this.size || this._formSize;
      },
      sizeClass() {
        return this.yxtFormItemSize || (this.$ELEMENT || {}).size;
      }
    },
    data() {
      return {
        validateState: '',
        validateMessage: '',
        validateDisabled: false,
        validator: {},
        isNested: false,
        computedLabelWidth: '',
        isFieldChanged: false,
        isClamped: false
      };
    },
    methods: {
      clampchange(isClamped) {
        this.isClamped = isClamped;
      },
      validate(trigger, callback = noop) {
        this.validateDisabled = false;
        const rules = this.getFilteredRule(trigger);
        if ((!rules || rules.length === 0) && this.required === undefined) {
          callback();
          return true;
        }

        this.validateState = 'validating';

        const descriptor = {};
        if (rules && rules.length > 0) {
          rules.forEach(rule => {
            delete rule.trigger;
          });
        }
        descriptor[this.prop] = rules;

        const validator = new AsyncValidator(descriptor);
        const model = {};

        model[this.prop] = this.fieldValue;

        validator.validate(model, { firstFields: true }, (errors, invalidFields) => {
          this.validateState = !errors ? 'success' : 'error';
          this.validateMessage = errors ? errors[0].message : '';

          callback(this.validateMessage, invalidFields);
          this.yxtForm && this.yxtForm.$emit('validate', this.prop, !errors, this.validateMessage || null);
        });
      },
      clearValidate() {
        this.validateState = '';
        this.validateMessage = '';
        this.validateDisabled = false;
      },
      resetField() {
        this.validateState = '';
        this.validateMessage = '';
        this.isFieldChanged = false;

        let model = this.form.model;
        let value = this.fieldValue;
        let path = this.prop;
        if (path.indexOf(':') !== -1) {
          path = path.replace(/:/, '.');
        }

        let prop = getPropByPath(model, path, true);

        this.validateDisabled = true;
        if (Array.isArray(value)) {
          prop.o[prop.k] = [].concat(this.initialValue);
        } else {
          prop.o[prop.k] = this.initialValue;
        }

        // reset validateDisabled after onFieldChange triggered
        this.$nextTick(() => {
          this.validateDisabled = false;
        });

        this.broadcast('YxtTimeSelect', 'fieldReset', this.initialValue);
      },
      clearChangedStatus() {
        this.isFieldChanged = false;
      },
      getRules() {
        let formRules = this.form.rules;
        const selfRules = this.rules;
        const requiredRule = this.required !== undefined ? { required: !!this.required } : [];

        const prop = getPropByPath(formRules, this.prop || '');
        formRules = formRules ? (prop.o[this.prop || ''] || prop.v) : [];

        return [].concat(selfRules || formRules || []).concat(requiredRule);
      },
      getFilteredRule(trigger) {
        const rules = this.getRules();

        return rules.filter(rule => {
          if (!rule.trigger || trigger === '') return true;
          if (Array.isArray(rule.trigger)) {
            return rule.trigger.indexOf(trigger) > -1;
          } else {
            return rule.trigger === trigger;
          }
        }).map(rule => objectAssign({}, rule));
      },
      onFieldBlur() {
        this.validate('blur');
      },
      onFieldChange() {
        if (this.validateDisabled) {
          this.validateDisabled = false;
          return;
        }

        this.validate('change');
      },
      updateComputedLabelWidth(width) {
        this.computedLabelWidth = width ? `${width}px` : '';
      },
      addValidateEvents() {
        const rules = this.getRules();

        if (rules.length || this.required !== undefined) {
          this.$on('yxt.form.blur', this.onFieldBlur);
          this.$on('yxt.form.change', this.onFieldChange);
        }
      },
      removeValidateEvents() {
        this.$off('yxt.form.blur', this.onFieldBlur);
        this.$off('yxt.form.change', this.onFieldChange);
      },
      removeFieldChangedEvents() {
        this.$off('yxt.form.change', this.setFieldStatus);
      },
      addFieldChangedEvents() {
        this.$on('yxt.form.change', this.setFieldStatus);
      },
      setFieldStatus() {
        if (!this.isFieldChanged) {
          this.isFieldChanged = true;
        }
      }
    },
    mounted() {
      this.dispatch('YxtForm', 'yxt.form.addChildrenItems', [this]);
      if (this.form.enableDetectFieldsChange) {
        this.addFieldChangedEvents();
      }
      if (this.prop) {
        this.dispatch('YxtForm', 'yxt.form.addField', [this]);

        let initialValue = this.fieldValue;
        if (Array.isArray(initialValue)) {
          initialValue = [].concat(initialValue);
        }
        Object.defineProperty(this, 'initialValue', {
          value: initialValue
        });

        this.addValidateEvents();
      }
    },
    beforeDestroy() {
      this.dispatch('YxtForm', 'yxt.form.removeField', [this]);
      this.dispatch('YxtForm', 'yxt.form.removeChildrenItems', [this]);
    }
  };
</script>
