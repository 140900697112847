var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "yxtf-step",
      class: [
        !_vm.isSimple && !_vm.isBasic && "is-" + _vm.$parent.direction,
        _vm.isSimple && !_vm.isBasic && "is-simple",
        _vm.isBasic && !_vm.isSimple && "is-basic",
        _vm.isLast && !_vm.space && !_vm.isCenter && "is-flex",
        _vm.isCenter && !_vm.isVertical && !_vm.isSimple && "is-center",
      ],
      style: _vm.style,
    },
    [
      !_vm.isBasic
        ? _c(
            "div",
            {
              staticClass: "yxtf-step__head",
              class: "is-" + _vm.currentStatus,
            },
            [
              _c(
                "div",
                {
                  staticClass: "yxtf-step__line",
                  style: _vm.isLast
                    ? ""
                    : { marginRight: _vm.$parent.stepOffset + "px" },
                },
                [_c("i", { staticClass: "yxtf-step__line-inner" })]
              ),
              _c(
                "div",
                {
                  staticClass: "yxtf-step__icon",
                  class: "is-" + (_vm.icon ? "icon" : "text"),
                },
                [
                  _vm.currentStatus !== "success" &&
                  _vm.currentStatus !== "error"
                    ? _vm._t("icon", [
                        _vm.icon
                          ? _c("i", {
                              staticClass: "yxtf-step__icon-inner",
                              class: [_vm.icon],
                            })
                          : _vm._e(),
                        !_vm.icon && !_vm.isSimple
                          ? _c(
                              "div",
                              { staticClass: "yxtf-step__icon-inner" },
                              [_vm._v(_vm._s(_vm.index + 1))]
                            )
                          : _vm._e(),
                      ])
                    : _c("yxtf-svg", {
                        staticClass: "yxtf-step__icon-inner is-status",
                        attrs: {
                          "icon-class": [
                            _vm.currentStatus === "success"
                              ? "ok-square"
                              : "delete-1",
                          ],
                          width: "25px",
                          height: "25px",
                        },
                      }),
                ],
                2
              ),
            ]
          )
        : _vm._e(),
      _c("div", { staticClass: "yxtf-step__main" }, [
        _c(
          "div",
          {
            ref: "title",
            staticClass: "yxtf-step__title",
            class: ["is-" + _vm.currentStatus],
          },
          [
            _vm.currentStatus === "finish" && _vm.isBasic
              ? _c("i", { staticClass: "yxtf-icon-circle-check" })
              : _vm._e(),
            _vm._t("title", [_vm._v(_vm._s(_vm.title))]),
          ],
          2
        ),
        _vm.isSimple
          ? _c("div", { staticClass: "yxtf-step__arrow" })
          : _vm._e(),
        !_vm.isSimple && !_vm.isBasic
          ? _c(
              "div",
              {
                staticClass: "yxtf-step__description",
                class: ["is-" + _vm.currentStatus],
              },
              [_vm._t("description", [_vm._v(_vm._s(_vm.description))])],
              2
            )
          : _vm._e(),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }