var render = function () {
  var this$1 = this
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "show",
          rawName: "v-show",
          value: _vm.node.visible,
          expression: "node.visible",
        },
      ],
      ref: "node",
      staticClass: "yxtf-tree-node",
      class: {
        "is-expanded": _vm.expanded,
        "is-current": _vm.node.isCurrent,
        "is-hidden": !_vm.node.visible,
        "is-focusable": !_vm.node.disabled,
        "is-checked": !_vm.node.disabled && _vm.node.checked,
      },
      attrs: {
        role: "treeitem",
        tabindex: "-1",
        "aria-expanded": _vm.expanded,
        "aria-disabled": _vm.node.disabled,
        "aria-checked": _vm.node.checked,
        draggable: _vm.tree.draggable,
      },
      on: {
        click: function ($event) {
          $event.stopPropagation()
          return _vm.handleClick($event)
        },
        contextmenu: function ($event) {
          return this$1.handleContextMenu($event)
        },
        dragstart: function ($event) {
          $event.stopPropagation()
          return _vm.handleDragStart($event)
        },
        dragover: function ($event) {
          $event.stopPropagation()
          return _vm.handleDragOver($event)
        },
        dragend: function ($event) {
          $event.stopPropagation()
          return _vm.handleDragEnd($event)
        },
        drop: function ($event) {
          $event.stopPropagation()
          return _vm.handleDrop($event)
        },
      },
    },
    [
      _c(
        "div",
        {
          staticClass: "yxtf-tree-node__content",
          style: {
            "padding-left": (_vm.node.level - 1) * _vm.tree.indent + "px",
          },
        },
        [
          _c("span", {
            class: [
              {
                "is-leaf": _vm.node.isLeaf,
                expanded: !_vm.node.isLeaf && _vm.expanded,
              },
              "yxtf-tree-node__expand-icon",
              _vm.tree.iconClass ? _vm.tree.iconClass : "yxtf-icon-caret-right",
            ],
            on: {
              click: function ($event) {
                $event.stopPropagation()
                return _vm.handleExpandIconClick($event)
              },
            },
          }),
          _vm.showCheckbox && !_vm.node.nocheck
            ? _c("yxtf-checkbox", {
                attrs: {
                  indeterminate: _vm.node.indeterminate,
                  disabled: !!_vm.node.disabled,
                },
                on: { change: _vm.handleCheckChange },
                nativeOn: {
                  click: function ($event) {
                    $event.stopPropagation()
                  },
                },
                model: {
                  value: _vm.node.checked,
                  callback: function ($$v) {
                    _vm.$set(_vm.node, "checked", $$v)
                  },
                  expression: "node.checked",
                },
              })
            : _vm._e(),
          _vm.node.loading
            ? _c("span", {
                staticClass: "yxtf-tree-node__loading-icon yxtf-icon-loading",
              })
            : _vm._e(),
          _c("node-content", { attrs: { node: _vm.node } }),
        ],
        1
      ),
      _c("yxt-collapse-transition", [
        !_vm.renderAfterExpand || _vm.childNodeRendered
          ? _c(
              "div",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.expanded,
                    expression: "expanded",
                  },
                ],
                staticClass: "yxtf-tree-node__children",
                attrs: { role: "group", "aria-expanded": _vm.expanded },
              },
              _vm._l(_vm.node.childNodes, function (child) {
                return _c("yxtf-tree-node", {
                  key: _vm.getNodeKey(child),
                  attrs: {
                    "render-content": _vm.renderContent,
                    "render-after-expand": _vm.renderAfterExpand,
                    "show-checkbox": _vm.showCheckbox,
                    node: child,
                  },
                  on: { "node-expand": _vm.handleChildNodeExpand },
                })
              }),
              1
            )
          : _vm._e(),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }