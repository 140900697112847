// import { mixColors } from '@adso-ts/mix-colors';

// function colorLogFun() {

//   // --color-primary-0: red;
//   // --color-primary-1: #f0f6ff;
//   // --color-primary-2: #e6eeff;
//   // --color-primary-3: #bdd2ff;
//   // --color-primary-4: #94b2ff;
//   // --color-primary-5: #6b90ff;
//   // --color-primary-6: #436BFF;
//   // --color-primary-7: #2e4dd9;
//   // --color-primary-8: #1d33b3  ;
//   // --color-primary-9: #0f1e8c;
//   // --color-primary-10: #0a1266;

//   // // $--slider-button-hover-color: mix($--color-primary, black, 97%) !default;
//   // --slider-button-hover-color: #4168f7;

//   // // color: mix($--color-black, $--color-primary, $--button-active-shade-percent);
//   // --slider-button-active-color: #3c60e6;

//   // --color-primary-light-1: #567aff;
//   // --color-primary-light-2: #6989ff;
//   // --color-primary-light-3: #7b97ff;
//   // --color-primary-light-4: #8ea6ff;
//   // --color-primary-light-5: #a1b5ff;
//   // --color-primary-light-6: #b4c4ff;
//   // --color-primary-light-7: #c7d3ff;
//   // --color-primary-light-8: #d9e1ff;
//   // --color-primary-light-9: #ecf0ff;

// // $--slider-button-hover-color: mix($--color-primary, black, 97%) !default;
// // $--color-primary-light-1: mix($--color-white, $--color-primary, 10%) !default;

//   console.log('000-primary-97 ', mixColors('#000000', '#436bff', 97));
//   console.log('000-primary-10 ', mixColors('#000000', '#436bff', 10));

//   console.log('================');
//   let primaryColor = '#436bff';

//   console.log('10 - ffffff - primary : ', mixColors('#ffffff', primaryColor, 10));
//   console.log('20 - ffffff - primary : ', mixColors('#ffffff', primaryColor, 20));
//   console.log('30 - ffffff - primary : ', mixColors('#ffffff', primaryColor, 30));
//   console.log('40 - ffffff - primary : ', mixColors('#ffffff', primaryColor, 40));
//   console.log('50 - ffffff - primary : ', mixColors('#ffffff', primaryColor, 50));
//   console.log('60 - ffffff - primary : ', mixColors('#ffffff', primaryColor, 60));
//   console.log('70 - ffffff - primary : ', mixColors('#ffffff', primaryColor, 70));
//   console.log('80 - ffffff - primary : ', mixColors('#ffffff', primaryColor, 80));
//   console.log('90 - ffffff - primary : ', mixColors('#ffffff', primaryColor, 90));
//   console.log('100 - ffffff - primary : ', mixColors('#ffffff', primaryColor, 100));

//   console.log('================');

//   console.log('10 - primary - ffffff : ', mixColors(primaryColor, '#ffffff', 10));
//   console.log('20 - primary - ffffff : ', mixColors(primaryColor, '#ffffff', 20));
//   console.log('30 - primary - ffffff : ', mixColors(primaryColor, '#ffffff', 30));
//   console.log('40 - primary - ffffff : ', mixColors(primaryColor, '#ffffff', 40));
//   console.log('50 - primary - ffffff : ', mixColors(primaryColor, '#ffffff', 50));
//   console.log('60 - primary - ffffff : ', mixColors(primaryColor, '#ffffff', 60));
//   console.log('70 - primary - ffffff : ', mixColors(primaryColor, '#ffffff', 70));
//   console.log('80 - primary - ffffff : ', mixColors(primaryColor, '#ffffff', 80));
//   console.log('90 - primary - ffffff : ', mixColors(primaryColor, '#ffffff', 90));
//   console.log('100 - primary - ffffff : ', mixColors(primaryColor, '#ffffff', 100));

//   console.log('================');

//   console.log('97 - primary - 000000 ', mixColors('#436bff', '#000000', 97));
// }

const colorPalette = require('../../common/sass-utils/colorPalette');
const Tinycolor = require('../../common/sass-utils/tinyColor');

const level_range = 10;
const primary_color = '#436bff';

let colors = {};

function getArr(len) {
  let r = [];
  for (let i = 0; i < len; i++) {
    r[i] = '';
  }
  return r;
}

function createColorObject(list) {
  let result = { list };

  getArr(level_range).forEach((value, index) => {
    result[`color-primary-${index + 1}`] = list[index];
  });

  return result;
}

function create(color, isPrimary = true) {
  // colorLogFun();

  let c = getArr(level_range).map((...args) => {
    return args[1] === 5 ? color : colorPalette(color, args[1] + 1).toHexString();
  });

  // 是否是主色系的生成
  if (isPrimary) {
    colors = createColorObject(c);
    return colors;
  } else {
    return c;
  }
}

function fade(color, opacity) {
  if (typeof color === 'number') {
    color = Tinycolor(colors.list[color - 1]);
  } else {
    color = Tinycolor(color);
  }
  if (typeof opacity === 'number') {
    opacity = opacity;
  } else if (typeof opacity === 'string' && /%$/.test(opacity)) {
    let temp = opacity.replace(/(.*?)%$/, '$1');
    opacity = temp / 100;
  } else {
    throw new Error('透明度格式不正确');
  }
  return color.fade(opacity).toRgbString();
}

// 使用默认肤色初始化
create(primary_color);

export default {
  create,
  colors,
  fade
};

