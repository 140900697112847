<template>
  <yxt-row class="yxt-empty">
    <div>
      <slot v-if="type!=='text'&&type!=='custom'" name="image">
        <div class="yxt-empty__svg">
          <yxt-svg :icon-class=iconclass></yxt-svg>
        </div>
      </slot>
      <div class="color-gray-7">
        <slot name='text'>
          <div :class="['yxt-empty__text', type=='custom'&&'yxt-empty__title' ]">{{ fillText }}</div>
        </slot>
      </div>
      <slot></slot>
    </div>
  </yxt-row>
</template>

<script>
import { t } from '@utils/locale';
export default {
  name: 'YxtEmpty',
  props: {
    emptyText: {
      default: ''
    },
    permission: {
      type: Boolean,
      default: false
    },
    img: {
      type: String
    },
    type: {
      type: String,
      default: 'image'
    }
  },
  computed: {
    fillText() {
      return this.emptyText ? this.emptyText : this.permission || this.img === 'nopermission' ? t('pc_comp_no_auth') : t('pc_comp_table_emptyText');
    },
    iconclass() {
      const typeList = ['404', 'deleted', 'developing', 'empty', 'nodata', 'nodata-new', 'nopermission', 'nopermission-new', 'gobrowser', 'pagelost', 'servererror', 'versionerror'];
      if (this.permission) {
        return 'empty-nopermission';
      } else if (typeList.includes(this.img)) {
        return 'empty-' + this.img;
      } else {
        return 'empty-nodata';
      }
    }
  }
};
</script>

