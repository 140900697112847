<template>
  <li class="yxt-timeline-item">
    <div :style="{borderColor: tailColor}"
       :class="['yxt-timeline-item__tail',!$slots.dot ? `yxt-timeline-item__tail--${size || ''}`:'',,timeline.dashed ? `yxt-timeline-item__tail_dashed`:'']">
    </div>

    <div v-if="!$slots.dot"
      
      :class="icon ?
          [
        `yxt-timeline-item__node_icon`,
        `yxt-timeline-item__node_icon--${size || ''}`,
        `yxt-timeline-item__node_icon--${type || ''}`]
        :[
        `yxt-timeline-item__node${isCurrent ? '_current':''}`,
        `yxt-timeline-item__node${isCurrent ? '_current':''}--${size || ''}`,
        `yxt-timeline-item__node${isCurrent ? '_current':''}--${type || ''}`]
        "
      :style="!icon?{
        borderColor: color
      }:{ color: color}"
    >
      <i v-if="icon"
        :class="[icon,'yxt-timeline-item__icon']"
      ></i>
    </div>
    <div v-if="$slots.dot" class="yxt-timeline-item__dot" :style="{width:dotWidth+'px',height:dotHeight+'px',left:-(dotWidth/2-4) + 'px'}">
      <slot name="dot"></slot>
    </div>

    <div class="yxt-timeline-item__wrapper">
      <div v-if="!hideTimestamp && placement === 'top'"
        class="yxt-timeline-item__timestamp is-top">
        {{timestamp}}
      </div>

      <div class="yxt-timeline-item__content">
        <slot></slot>
      </div>

      <div v-if="!hideTimestamp && placement === 'bottom'"
        class="yxt-timeline-item__timestamp is-bottom">
        {{timestamp}}
      </div>
    </div>
  </li>
</template>

<script>
  export default {
    name: 'YxtTimelineItem',

    inject: ['timeline'],

    props: {
      timestamp: String,

      hideTimestamp: {
        type: Boolean,
        default: false
      },

      placement: {
        type: String,
        default: 'bottom'
      },

      type: String,

      color: String,

      size: {
        type: String,
        default: 'normal'
      },

      icon: String,

      isCurrent: {
        type: Boolean,
        default: false
      },

      tailColor: String,
      dotWidth: {
        type: Number,
        default: 0
      },
      dotHeight: {
        type: Number,
        default: 0
      }
    },
    methods: {
      setDotStyle() {
        if (!Number(this.dotWidth) || !Number(this.dotHeight)) {return;};
        const wrapperDom = this.$el.getElementsByClassName('yxt-timeline-item__wrapper')[0];
        const tailrDom = this.$el.getElementsByClassName('yxt-timeline-item__tail')[0];
        wrapperDom.style.paddingLeft = this.dotWidth / 2 + 'px';
        tailrDom.style.height = this.$el.clientHeight - this.dotHeight - 8 + 'px';
        tailrDom.style.top = this.dotHeight + 4 + 'px';
      }
    },
  
    mounted() {
      if (this.$slots.dot) {
        this.setDotStyle();
      }
    }
  };

</script>
