var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "yxt-calendar-mini", style: _vm.calendarStyle },
    [
      _vm.$slots.header
        ? _vm._t("header")
        : _c("div", { staticClass: "yxt-calendar-mini__header" }, [
            _c(
              "div",
              {
                staticClass: "yxt-calendar-mini__header-prev",
                on: {
                  click: function ($event) {
                    _vm.selectDate("prev-month")
                  },
                },
              },
              [_c("i", { staticClass: "yxt-icon-arrow-left" })]
            ),
            _c("div", { staticClass: "yxt-calendar-mini__header-title" }, [
              _c("span", [_vm._v(_vm._s(_vm.i18nDate))]),
            ]),
            _c(
              "div",
              {
                staticClass: "yxt-calendar-mini__header-next",
                on: {
                  click: function ($event) {
                    _vm.selectDate("next-month")
                  },
                },
              },
              [_c("i", { staticClass: "yxt-icon-arrow-right" })]
            ),
          ]),
      _vm.validatedRange.length === 0
        ? _c(
            "div",
            { key: "no-range", staticClass: "yxt-calendar-mini__body" },
            [
              _c("date-table", {
                attrs: {
                  date: _vm.date,
                  "selected-day": _vm.realSelectedDay,
                  "first-day-of-week": _vm.realFirstDayOfWeek,
                },
                on: { pick: _vm.pickDay },
              }),
            ],
            1
          )
        : _c(
            "div",
            { key: "has-range", staticClass: "yxt-calendar-mini__body" },
            _vm._l(_vm.validatedRange, function (range, index) {
              return _c("date-table", {
                key: index,
                attrs: {
                  date: range[0],
                  "selected-day": _vm.realSelectedDay,
                  range: range,
                  "hide-header": index !== 0,
                  "first-day-of-week": _vm.realFirstDayOfWeek,
                },
                on: { pick: _vm.pickDay },
              })
            }),
            1
          ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }