export default {
  // 新增
  'arrow-left': 'arrow-left',
  'f_biz-card-comment': 'f_biz-card-comment',
  'f_biz-card-customize': 'f_biz-card-customize',
  'f_biz-card-project': 'f_biz-card-project',
  'f_biz-card-survey': 'f_biz-card-survey',
  'f_biz-card-template': 'f_biz-card-template',
  'f_biz-card-vote': 'f_biz-card-vote',
  'f_browser': 'f_browser',
  'f_down-circle-o': 'f_down-circle-o',
  'f_empty-small': 'f_empty-small',
  'f_empty': 'f_empty',
  'f_exam-100': 'f_exam-100',
  'f_exam-clock': 'f_exam-clock',
  'f_exam-no': 'f_exam-no',
  'f_exam-pass': 'f_exam-pass',
  'f_exam-star': 'f_exam-star',
  'f_exam-task': 'f_exam-task',
  'f_exam-yes': 'f_exam-yes',
  'f_feedback-common': 'f_feedback-common',
  'f_feedback-success': 'f_feedback-success',
  'f_feedback-tip': 'f_feedback-tip',
  'f_feedback-warning': 'f_feedback-warning',
  'f_fullscreen-exit': 'f_fullscreen-exit',
  'f_fullscreen': 'f_fullscreen',
  'f_hide': 'f_hide',
  'f_home-fast-assess1': 'f_home-fast-assess1',
  'f_home-fast-assess2': 'f_home-fast-assess2',
  'f_home-fast-class1': 'f_home-fast-class1',
  'f_home-fast-class2': 'f_home-fast-class2',
  'f_home-fast-live1': 'f_home-fast-live1',
  'f_home-fast-live2': 'f_home-fast-live2',
  'f_home-fast-more1': 'f_home-fast-more1',
  'f_home-fast-more2': 'f_home-fast-more2',
  'f_home-fast-research1': 'f_home-fast-research1',
  'f_home-fast-research2': 'f_home-fast-research2',
  'f_home-fast-sign1': 'f_home-fast-sign1',
  'f_home-fast-sign2': 'f_home-fast-sign2',
  'f_home-fast-study1': 'f_home-fast-study1',
  'f_home-fast-study2': 'f_home-fast-study2',
  'f_home-fast-teach1': 'f_home-fast-teach1',
  'f_home-fast-teach2': 'f_home-fast-teach2',
  'f_home-fast-team1': 'f_home-fast-team1',
  'f_home-fast-team2': 'f_home-fast-team2',
  'f_home-fast-train1': 'f_home-fast-train1',
  'f_home-fast-train2': 'f_home-fast-train2',
  'f_home-fast-welfare1': 'f_home-fast-welfare1',
  'f_home-fast-welfare2': 'f_home-fast-welfare2',
  'f_home-feed-comunnity': 'f_home-feed-comunnity',
  'f_home-feed-course': 'f_home-feed-course',
  'f_home-feed-exam': 'f_home-feed-exam',
  'f_home-feed-live': 'f_home-feed-live',
  'f_home-feed-research': 'f_home-feed-research',
  'f_home-feed-train': 'f_home-feed-train',
  'f_kng-audio': 'f_kng-audio',
  'f_kng-excel': 'f_kng-excel',
  'f_kng-folder': 'f_kng-folder',
  'f_kng-html': 'f_kng-html',
  'f_kng-img': 'f_kng-img',
  'f_kng-kng': 'f_kng-kng',
  'f_kng-micro': 'f_kng-micro',
  'f_kng-pdf': 'f_kng-pdf',
  'f_kng-ppt': 'f_kng-ppt',
  'f_kng-scorm': 'f_kng-scorm',
  'f_kng-third': 'f_kng-third',
  'f_kng-video': 'f_kng-video',
  'f_kng-word': 'f_kng-word',
  'f_kng-zip': 'f_kng-zip',
  'f_night': 'f_night',
  'f_permission-small': 'f_permission-small',
  'f_permission': 'f_permission',
  'f_proj-activity': 'f_proj-activity',
  'f_proj-attend': 'f_proj-attend',
  'f_proj-audio': 'f_proj-audio',
  'f_proj-comment': 'f_proj-comment',
  'f_proj-course': 'f_proj-course',
  'f_proj-exam': 'f_proj-exam',
  'f_proj-face': 'f_proj-face',
  'f_proj-html': 'f_proj-html',
  'f_proj-live': 'f_proj-live',
  'f_proj-micro': 'f_proj-micro',
  'f_proj-other': 'f_proj-other',
  'f_proj-practice': 'f_proj-practice',
  'f_proj-question': 'f_proj-question',
  'f_proj-research': 'f_proj-research',
  'f_proj-scorm': 'f_proj-scorm',
  'f_proj-task': 'f_proj-task',
  'f_proj-video': 'f_proj-video',
  'f_proj-vote': 'f_proj-vote',
  'f_send-dingding': 'f_send-dingding',
  'f_send-letter': 'f_send-letter',
  'f_send-mail': 'f_send-mail',
  'f_send-mobile': 'f_send-mobile',
  'f_send-webchat': 'f_send-webchat',
  'f_send-xx': 'f_send-xx',
  'f_sun': 'f_sun',
  'f_up-circle-o': 'f_up-circle-o',
  'f_view': 'f_view',
  'f_voice': 'f_voice',
  'f_zoom-in-2': 'f_zoom-in-2',
  'f_zoom-in': 'f_zoom-in',
  'f_zoom-out-2': 'f_zoom-out-2',
  'f_zoom-out': 'f_zoom-out',
  // 旧的
  '@': '@',
  'account-security': 'account-security',
  'adoption': 'adoption',
  'all': 'all',
  'back-facial': 'back-facial',
  'back': 'back',
  'biz/default-state-phone': 'default-state-phone',
  'biz/default-state-small': 'default-state-small',
  'biz/default-state': 'default-state',
  'biz/mobile-result-fail': 'mobile-result-fail',
  'biz/mobile-result-success': 'mobile-result-success',
  'biz/point': 'point',
  'biz/result-fail-icon': 'result-fail-icon',
  'biz/result-success-icon': 'result-success-icon',
  'biz/result-success-mail': 'result-success-mail',
  'biz/result-success-phone': 'result-success-phone',
  'biz/tag-accept': 'tag-accept',
  'biz/tag-back': 'tag-back',
  'biz/tag-pass': 'tag-pass',
  'biz/tag-qualified': 'tag-qualified',
  'biz/tag-unpass': 'tag-unpass',
  'biz/tag-unqualified': 'tag-unqualified',
  'calendar': 'calendar',
  'classification': 'classification',
  'clock': 'clock',
  'closedimg': 'closedimg',
  'code': 'code',
  'comment': 'comment',
  'comments': 'comments',
  'copy': 'copy',
  'course': 'course',
  'delete-0': 'delete-0',
  'delete-1': 'delete-1',
  'delete-facial': 'delete-facial',
  'delete': 'delete',
  'department': 'department',
  'down-facial': 'down-facial',
  'down': 'down',
  'download': 'download',
  'edit-1': 'edit-1',
  'edit-pencil': 'edit-pencil',
  'edit': 'edit',
  'exam-analysis': 'exam-analysis',
  'examination-centre': 'examination-centre',
  'exchange': 'exchange',
  'export-testpaper': 'export-testpaper',
  'face-recognition': 'face-recognition',
  'favorite-facial': 'favorite-facial',
  'feedback': 'feedback',
  'file': 'file',
  'filter': 'filter',
  'forward-facial': 'forward-facial',
  'forward': 'forward',
  'hide': 'hide',
  'history': 'history',
  'homepage': 'homepage',
  'iconLeft': 'iconLeft',
  'icons/arrow-left': 'arrow-left',
  'icons/f_biz-card-comment': 'f_biz-card-comment',
  'icons/f_biz-card-customize': 'f_biz-card-customize',
  'icons/f_biz-card-project': 'f_biz-card-project',
  'icons/f_biz-card-survey': 'f_biz-card-survey',
  'icons/f_biz-card-template': 'f_biz-card-template',
  'icons/f_biz-card-vote': 'f_biz-card-vote',
  'icons/f_browser': 'f_browser',
  'icons/f_down-circle-o': 'f_down-circle-o',
  'icons/f_empty-small': 'f_empty-small',
  'icons/f_empty': 'f_empty',
  'icons/f_exam-100': 'f_exam-100',
  'icons/f_exam-clock': 'f_exam-clock',
  'icons/f_exam-no': 'f_exam-no',
  'icons/f_exam-pass': 'f_exam-pass',
  'icons/f_exam-star': 'f_exam-star',
  'icons/f_exam-task': 'f_exam-task',
  'icons/f_exam-yes': 'f_exam-yes',
  'icons/f_feedback-common': 'f_feedback-common',
  'icons/f_feedback-success': 'f_feedback-success',
  'icons/f_feedback-tip': 'f_feedback-tip',
  'icons/f_feedback-warning': 'f_feedback-warning',
  'icons/f_fullscreen-exit': 'f_fullscreen-exit',
  'icons/f_fullscreen': 'f_fullscreen',
  'icons/f_hide': 'f_hide',
  'icons/f_home-fast-assess1': 'f_home-fast-assess1',
  'icons/f_home-fast-assess2': 'f_home-fast-assess2',
  'icons/f_home-fast-class1': 'f_home-fast-class1',
  'icons/f_home-fast-class2': 'f_home-fast-class2',
  'icons/f_home-fast-live1': 'f_home-fast-live1',
  'icons/f_home-fast-live2': 'f_home-fast-live2',
  'icons/f_home-fast-more1': 'f_home-fast-more1',
  'icons/f_home-fast-more2': 'f_home-fast-more2',
  'icons/f_home-fast-research1': 'f_home-fast-research1',
  'icons/f_home-fast-research2': 'f_home-fast-research2',
  'icons/f_home-fast-sign1': 'f_home-fast-sign1',
  'icons/f_home-fast-sign2': 'f_home-fast-sign2',
  'icons/f_home-fast-study1': 'f_home-fast-study1',
  'icons/f_home-fast-study2': 'f_home-fast-study2',
  'icons/f_home-fast-teach1': 'f_home-fast-teach1',
  'icons/f_home-fast-teach2': 'f_home-fast-teach2',
  'icons/f_home-fast-team1': 'f_home-fast-team1',
  'icons/f_home-fast-team2': 'f_home-fast-team2',
  'icons/f_home-fast-train1': 'f_home-fast-train1',
  'icons/f_home-fast-train2': 'f_home-fast-train2',
  'icons/f_home-fast-welfare1': 'f_home-fast-welfare1',
  'icons/f_home-fast-welfare2': 'f_home-fast-welfare2',
  'icons/f_home-feed-comunnity': 'f_home-feed-comunnity',
  'icons/f_home-feed-course': 'f_home-feed-course',
  'icons/f_home-feed-exam': 'f_home-feed-exam',
  'icons/f_home-feed-live': 'f_home-feed-live',
  'icons/f_home-feed-research': 'f_home-feed-research',
  'icons/f_home-feed-train': 'f_home-feed-train',
  'icons/f_kng-audio': 'f_kng-audio',
  'icons/f_kng-excel': 'f_kng-excel',
  'icons/f_kng-folder': 'f_kng-folder',
  'icons/f_kng-html': 'f_kng-html',
  'icons/f_kng-img': 'f_kng-img',
  'icons/f_kng-kng': 'f_kng-kng',
  'icons/f_kng-other': 'f_proj-other',
  'icons/f_kng-micro': 'f_kng-micro',
  'icons/f_kng-pdf': 'f_kng-pdf',
  'icons/f_kng-ppt': 'f_kng-ppt',
  'icons/f_kng-scorm': 'f_kng-scorm',
  'icons/f_kng-third': 'f_kng-third',
  'icons/f_kng-video': 'f_kng-video',
  'icons/f_kng-word': 'f_kng-word',
  'icons/f_kng-zip': 'f_kng-zip',
  'icons/f_night': 'f_night',
  'icons/f_permission-small': 'f_permission-small',
  'icons/f_permission': 'f_permission',
  'icons/f_proj-activity': 'f_proj-activity',
  'icons/f_proj-attend': 'f_proj-attend',
  'icons/f_proj-audio': 'f_proj-audio',
  'icons/f_proj-comment': 'f_proj-comment',
  'icons/f_proj-course': 'f_proj-course',
  'icons/f_proj-exam': 'f_proj-exam',
  'icons/f_proj-face': 'f_proj-face',
  'icons/f_proj-html': 'f_proj-html',
  'icons/f_proj-live': 'f_proj-live',
  'icons/f_proj-micro': 'f_proj-micro',
  'icons/f_proj-other': 'f_proj-other',
  'icons/f_proj-practice': 'f_proj-practice',
  'icons/f_proj-question': 'f_proj-question',
  'icons/f_proj-research': 'f_proj-research',
  'icons/f_proj-scorm': 'f_proj-scorm',
  'icons/f_proj-task': 'f_proj-task',
  'icons/f_proj-video': 'f_proj-video',
  'icons/f_proj-vote': 'f_proj-vote',
  'icons/f_send-dingding': 'f_send-dingding',
  'icons/f_send-letter': 'f_send-letter',
  'icons/f_send-mail': 'f_send-mail',
  'icons/f_send-mobile': 'f_send-mobile',
  'icons/f_send-webchat': 'f_send-webchat',
  'icons/f_send-xx': 'f_send-xx',
  'icons/f_sun': 'f_sun',
  'icons/f_up-circle-o': 'f_up-circle-o',
  'icons/f_view': 'f_view',
  'icons/f_voice': 'f_voice',
  'icons/f_zoom-in-2': 'f_zoom-in-2',
  'icons/f_zoom-in': 'f_zoom-in',
  'icons/f_zoom-out-2': 'f_zoom-out-2',
  'icons/f_zoom-out': 'f_zoom-out',
  'im/im_read_all': 'im_read_all',
  'im/im-ann': 'im-ann',
  'im/im-cert': 'im-cert',
  'im/im-community': 'im-community',
  'im/im-develop': 'im-develop',
  'im/im-exam': 'im-exam',
  'im/im-points': 'im-points',
  'im/im-survey': 'im-survey',
  'im/im-system': 'im-system',
  'im/im-train': 'im-train',
  'im/m-cert': 'm-cert',
  'im/m-system': 'm-system',
  'image': 'image',
  'jf-wallet': 'jf-wallet',
  'jing': 'jing',
  'job': 'job',
  'knowledge-point': 'knowledge-point',
  'knowledge-sharing': 'knowledge-sharing',
  'language-set': 'language-set',
  'learning-achievement': 'learning-achievement',
  'left-facial': 'left-facial',
  'left': 'left',
  'level': 'level',
  'level/v0': 'v0',
  'level/v1': 'v1',
  'level/v2': 'v2',
  'level/v3': 'v3',
  'level/v4': 'v4',
  'level/v5': 'v5',
  'level/v6': 'v6',
  'level/v7': 'v7',
  'level/v8': 'v8',
  'level/v9': 'v9',
  'link': 'link',
  'live': 'live',
  'loading': 'loading-2',
  'lock-facial': 'lock-facial',
  'lock-off': 'lock-off',
  'lock-on': 'lock-on',
  'look-1': 'look-1',
  'look': 'look',
  'management': 'management',
  'mark-facial': 'mark-facial',
  'minus-0': 'minus-0',
  'minus-facial': 'minus-facial',
  'minus': 'minus',
  'mobile': 'mobile',
  'more': 'more',
  'my-certificate': 'my-certificate',
  'my-collection': 'my-collection',
  'my-medal': 'my-medal',
  'nav-msg': 'nav-msg',
  'nav-search': 'nav-search',
  'news-99': 'news-99',
  'news': 'news',
  'ok-facial': 'ok-facial',
  'ok-square': 'ok-square',
  'ok': 'ok',
  'order-center': 'order-center',
  'pack-up': 'pack-up',
  'paper-overview': 'paper-overview',
  'pass': 'pass',
  'personal-information': 'personal-information',
  'personal': 'personal',
  'phone': 'phone',
  'platform': 'platform',
  'plus-0': 'plus-0',
  'plus-facial': 'plus-facial',
  'plus': 'plus',
  'praise': 'praise',
  'preview-facial': 'preview-facial',
  'preview': 'preview',
  'project-center': 'project-center',
  'project': 'project',
  'prompt-0': 'prompt-0',
  'pull-up': 'pull-up',
  'Q-A': 'Q-A',
  'quit': 'quit',
  'rank': 'rank',
  'recommend': 'recommend',
  'reduction': 'reduction',
  'refresh': 'refresh',
  'registration-center': 'registration-center',
  'release': 'release',
  'research': 'research',
  'right-facial': 'right-facial',
  'right': 'right',
  'role': 'role',
  'search': 'search',
  'section-facial': 'section-facial',
  'self-learning': 'self-learning',
  'set': 'set',
  'Shape': 'Shape',
  'share': 'share',
  'sort-down': 'sort-down',
  'sort-facial': 'sort-facial',
  'sort-up': 'sort-up',
  'sort': 'sort',
  'spare-facial': 'spare-facial',
  'statistics': 'statistics',
  'study-file': 'study-file',
  'study-more': 'study-more',
  'study-notes': 'study-notes',
  'take-off': 'take-off',
  'template-facial': 'template-facial',
  'test-score': 'test-score',
  'test-time': 'test-time',
  'time': 'time',
  'tip-off': 'tip-off',
  'tip-on': 'tip-on',
  'triangle-right': 'triangle-right',
  'trim': 'trim',
  'turn-left': 'turn-left',
  'turn-right': 'turn-right',
  'up-facial': 'up-facial',
  'up': 'up',
  'user-group': 'user-group',
  'voice-ing': 'voice-ing',
  'voice-off': 'voice-off',
  'voice-on': 'voice-on',
  'workbench-center': 'workbench-center',
  'workbench-check': 'workbench-check',
  'workbench-review': 'workbench-review',
  'wrong-question': 'wrong-question',
  'zoom-in': 'zoom-in',
  'zoom-out': 'zoom-out'
};
