var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.iconList.length
    ? _c(
        "div",
        {
          staticClass: "yxt-side-bar-new",
          style: Object.assign({}, _vm.containerStyle, {
            height: 53 * _vm.iconList.length - 1 + "px",
          }),
        },
        [
          _c(
            "div",
            { staticClass: "yxt-side-bar-new__list" },
            _vm._l(_vm.iconList, function (item, index) {
              return _c(
                "div",
                { key: index, staticClass: "yxt-side-bar-new__list-item" },
                [
                  _c(
                    "yxt-popover",
                    {
                      attrs: {
                        placement: "left",
                        trigger: "hover",
                        "padding-small": false,
                      },
                    },
                    [
                      _c(
                        "div",
                        {
                          staticClass: "yxt-side-bar-new__list-info",
                          attrs: { slot: "reference" },
                          on: {
                            click: function ($event) {
                              _vm.changeDrawer(item, true)
                            },
                          },
                          slot: "reference",
                        },
                        [
                          _c("yxt-svg", {
                            attrs: {
                              width: "20px",
                              height: "20px",
                              "icon-class": item.iconClassName,
                            },
                          }),
                        ],
                        1
                      ),
                      _c("div", {
                        class: [
                          "yxt-side-bar-new__list-content",
                          item.popoverCenter ? "center" : "",
                        ],
                        domProps: { innerHTML: _vm._s(item.popoverSlot) },
                      }),
                    ]
                  ),
                  _c(
                    "div",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: item.isDrawerShow,
                          expression: "item.isDrawerShow",
                        },
                      ],
                      staticClass: "yxt-side-bar-new__drawer",
                      style: item.drawerStyle,
                    },
                    [
                      _c("div", {
                        attrs: { slot: "drawer-container" },
                        domProps: { innerHTML: _vm._s(item.drawerSlot) },
                        slot: "drawer-container",
                      }),
                    ]
                  ),
                ],
                1
              )
            }),
            0
          ),
        ]
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }