<template>
  <div class="yxt-block-header" :class="wrapClass">
    <div class="yxt-block-content">
      <div class="yxt-block-title">
        <slot name="title" v-if="$slots.title">{{ title }}</slot>
        <template v-else>{{ title }}</template>
      </div>
    </div>
    <div class="yxt-block-header__suffix" v-if="$slots.footer">
      <slot name="footer"></slot>
    </div>
  </div>
</template>

<script>
export default {
  name: 'YxtBlockhead',
  props: {
    title: String,
    type: String,
    size: String,
    cutline: {
      default: false,
      type: Boolean
    }
  },

  computed: {
    wrapClass() {
      let classNames = [];
      if (this.cutline) {
        classNames.push('yxt-block-header__cutline');
      }
      if (this.type === 'special') {
        classNames.push('yxt-block-header--special');
      }

      if (this.size) {
        classNames.push(`yxt-block-header--${this.size}`);
      }

      return classNames;
    }
  }
};
</script>


