<template>
  <article :class="className" :style='mainStyle' class='yxt-layout__main'>
    <slot></slot>
  </article>
</template>

<script>
import bus from './bus';
import scrollbarWidth from '@utils/utils/scrollbar-width';
import throttle from 'throttle-debounce/throttle';
import {
  addResizeListener,
  removeResizeListener
} from '@utils/utils/resize-event';

export default {
  name: 'YxtLayoutContent',
  props: {
    spaceX: {
      default: '24px',
      type: String
    },
    spaceY: {
      default: '24px',
      type: String
    },
    marginX: {
      default: '12px',
      type: String
    },
    marginY: {
      default: '12px',
      type: String
    },
    bg: {
      default: '#fff',
      type: String
    },
    size: {
      validator(v) {
        return ['large', 'medium', 'small', 'auto'].includes(v);
      },
      default: 'large',
      type: String
    }
  },
  data() {
    return {
      width: ''
    };
  },
  computed: {
    gutter() {
      return scrollbarWidth();
    },
    className() {
      return `yxt-layout__main--${this.size}`;
    },
    mainStyle() {
      return {
        padding: `${this.spaceY} ${this.spaceX}`,
        margin: `${this.marginY} ${this.marginX} 0 ${this.marginX}`,
        width: this.width,
        'background-color': this.bg
      };
    }
  },
  mounted() {
    this.throttledResizeHandler = throttle(100, this.resizeHandler);
    if (this.size !== 'small') { // small时不需要计算整体宽度
      this.resizeHandler();
      // 触发事件，如果容器宽度为最小时计算body滚动宽度，否则设置最外层布局的宽度为屏幕宽度
      window.addEventListener('resize', this.throttledResizeHandler);
      addResizeListener(this.$el, this.throttledResizeHandler);
      this.$on('content-change', this.throttledResizeHandler);
    }

  },
  destroyed() {
    window.removeEventListener('resize', this.throttledResizeHandler);
    removeResizeListener(this.$el, this.throttledResizeHandler);
    bus.$off();
  },
  methods: {
    resizeHandler() {
      this.$nextTick(() => {
        if (this.size === 'auto') return;
        const minWidth = parseInt(window.getComputedStyle(this.$el)['minWidth'], 10);
        const offsetWidth = this.$el.offsetWidth;
        const offset = document.body.scrollWidth - this.gutter - document.body.offsetWidth;
        let shouldCalc = offset > 0;
        if (offset > 0) {
          if (offsetWidth > minWidth) {
            this.width = `${minWidth}px`; // `${offsetWidth - offset}px`;
          }
        } else {
          this.width = '';
        }

        bus.$emit('calc-root', shouldCalc);
      });
    }
  }
};
</script>
<style lang="scss" scoped>

</style>