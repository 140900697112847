import { loadScript } from '@utils/utils/util';

export default {
  data() {
    return {
      range: {
        start: 0,
        end: 0
      },
      size: 32,
      // eslint-disable-next-line no-undef
      sizes: new Map(), // 动态尺寸时记录数据
      visibleKey: 'visible'
    };
  },
  methods: {
    toCurrentArray(tree) {
      // const imagesBaseUrl = (typeof window !== 'undefined' && window.feConfig && window.feConfig.common && window.feConfig.common.imagesBaseUrl) || 'https://images.yxt.com/';
      const staticBaseUrl = (typeof window !== 'undefined' && window.feConfig && window.feConfig.common && window.feConfig.common.staticBaseUrl) || 'https://stc.yxt.com/';
      const xeUtilUrl = `${staticBaseUrl}e510853c/0f849543/libs/xe-utils.min.js`;
      return loadScript(xeUtilUrl, () => {
        return !!window.XEUtils;
      }).then(_ => {
        const XEUtils = window.XEUtils;
        return XEUtils.toTreeArray(tree, {
          children: 'childNodes'
        });
      });
    },
    getScrollParent(element) {
      // 获取可滚动父元素
      let node = element;
      while (node && node.tagName !== 'BODY') {
        const overflow = getComputedStyle(node)['overflow-y'];
        if (overflow === 'auto' || overflow === 'scroll') {
          return node;
        }

        node = node.parentNode;
      }

      return document.documentElement;
    },
    saveSize(id, size) {
      this.sizes.set(id, size);
    },
    getIndexOffset(givenIndex) {
      if (!givenIndex) {
        return 0;
      }
      let offset = 0;
      let indexSize = 0;
      for (let index = 0; index < givenIndex; index++) {
        // 遍历计算给定index的offset
        indexSize = this.sizes.get(this.list[index][this.nodeKey]);
        offset =
          offset + (typeof indexSize === 'number' ? indexSize : this.size);
      }

      return offset;
    },
    resetRange(start = 0) {
      if (this.list.length === 0) {
        this.range = {
          start: 0,
          end: 0
        };
        return;
      }
      this.checkRange(start, this.getEndByStart(start));
    },
    /**
     * @param {Boolean} isFront 是否向前滚动
     */
    caculate(isFront, offset) {
      // 重新计算需要渲染的列表区间
      const overs = this.getScrollOvers(offset);
      let start = overs;
      if (isFront) {
        if (overs > this.range.start) {
          return;
        }
        start = Math.max(overs - this.buffer, 0);
      } else if (overs < this.range.start + this.buffer) {
        return;
      }
      this.checkRange(start, this.getEndByStart(start));
    },
    getScrollOvers(offset) {
      if (offset <= 0) return 0;
      if (!this.autoSize) {
        return Math.floor(offset / this.size);
      }

      let low = 0;
      let middle = 0;
      let middleOffset = 0;
      let high = this.getLastIndex() + 1;

      while (low <= high) {
        middle = low + Math.floor((high - low) / 2);
        middleOffset = this.getIndexOffset(middle);

        if (middleOffset === offset) {
          return middle;
        } else if (middleOffset < offset) {
          low = middle + 1;
        } else if (middleOffset > offset) {
          high = middle - 1;
        }
      }
      return low > 0 ? --low : 0;
    },
    getLastIndex() {
      return Math.max(this.list.length - 1, 0);
    },
    getEndByStart(start) {
      const lastIndex = this.getLastIndex();
      if (!this.isTree) {
        const end =
          start + this.keeps - 1 >= lastIndex
            ? lastIndex
            : start + this.keeps - 1;

        return end;
      }
      let count = 0;
      let end = lastIndex;
      for (let i = start; i <= lastIndex; i++) {
        if (this.list[i][this.visibleKey]) {
          count++;
        }

        if (count >= this.keeps) {
          end = i;
          break;
        }
      }
      return end;
    },
    getPadFront() {
      if (!this.autoSize) {
        return this.size * this.range.start;
      } else {
        return this.getIndexOffset(this.range.start);
      }
    },
    getPadBehind() {
      const end = this.range.end;
      const lastIndex = this.getLastIndex();
      if (!this.autoSize) {
        return (lastIndex - end) * this.size;
      } else {
        return this.getIndexOffset(lastIndex) - this.getIndexOffset(end);
      }
    },
    checkRange(start, end) {
      const keeps = this.keeps;
      const total = this.list.length;
      if (total <= keeps) {
        start = 0;
        end = this.getLastIndex();
      } else if (end - start < keeps - 1) {
        start = end - keeps + 1;
      }
      if (this.range.start !== start || this.range.end !== end) {
        this.range = {
          start,
          end
        };
      }
    }
  }
};
