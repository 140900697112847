import Vue from 'vue';
import { isString, isObject, isClient } from '@utils/utils/types';
import { cAF, rAF } from './raf';
const hasOwnProperty = Object.prototype.hasOwnProperty;
const scriptPromises = {};

export function noop() {};

export function hasOwn(obj, key) {
  return hasOwnProperty.call(obj, key);
};

function extend(to, _from) {
  for (let key in _from) {
    to[key] = _from[key];
  }
  return to;
};

export function toObject(arr) {
  var res = {};
  for (let i = 0; i < arr.length; i++) {
    if (arr[i]) {
      extend(res, arr[i]);
    }
  }
  return res;
};

export function cleanArray(actual) {
  const newArray = [];
  for (let i = 0; i < actual.length; i++) {
    if (actual[i]) {
      newArray.push(actual[i]);
    }
  }
  return newArray;
}

export function objToParam(json) {
  if (!json) return '';
  return cleanArray(Object.keys(json).map(key => {
    if (json[key] === undefined) {return '';};
    return encodeURIComponent(key) +
    '=' + encodeURIComponent(json[key]);
  })).join('&');
}

export const getValueByPath = function(object, prop) {
  prop = prop || '';
  const paths = prop.split('.');
  let current = object;
  let result = null;
  for (let i = 0, j = paths.length; i < j; i++) {
    const path = paths[i];
    if (!current) break;

    if (i === j - 1) {
      result = current[path];
      break;
    }
    current = current[path];
  }
  return result;
};

export function getPropByPath(obj, path, strict) {
  let tempObj = obj;
  path = path.replace(/\[(\w+)\]/g, '.$1');
  path = path.replace(/^\./, '');

  let keyArr = path.split('.');
  let i = 0;
  for (let len = keyArr.length; i < len - 1; ++i) {
    if (!tempObj && !strict) break;
    let key = keyArr[i];
    if (key in tempObj) {
      tempObj = tempObj[key];
    } else {
      if (strict) {
        throw new Error('please transfer a valid prop path to form item!');
      }
      break;
    }
  }
  return {
    o: tempObj,
    k: keyArr[i],
    v: tempObj ? tempObj[keyArr[i]] : null
  };
};

export const generateId = function() {
  return Math.floor(Math.random() * 10000);
};

export const valueEquals = (a, b) => {
  // see: https://stackoverflow.com/questions/3115982/how-to-check-if-two-arrays-are-equal-with-javascript
  if (a === b) return true;
  if (!(a instanceof Array)) return false;
  if (!(b instanceof Array)) return false;
  if (a.length !== b.length) return false;
  for (let i = 0; i !== a.length; ++i) {
    if (a[i] !== b[i]) return false;
  }
  return true;
};

export const escapeRegexpString = (value = '') => String(value).replace(/[|\\{}()[\]^$+*?.]/g, '\\$&');

// TODO: use native Array.find, Array.findIndex when IE support is dropped
export const arrayFindIndex = function(arr, pred) {
  for (let i = 0; i !== arr.length; ++i) {
    if (pred(arr[i])) {
      return i;
    }
  }
  return -1;
};

export const arrayFind = function(arr, pred) {
  const idx = arrayFindIndex(arr, pred);
  return idx !== -1 ? arr[idx] : undefined;
};

// coerce truthy value to array
export const coerceTruthyValueToArray = function(val) {
  if (Array.isArray(val)) {
    return val;
  } else if (val) {
    return [val];
  } else {
    return [];
  }
};

export const isIE = function() {
  return !Vue.prototype.$isServer && !isNaN(Number(document.documentMode));
};

export const isIEFun = () => {
  if (window.navigator.userAgent.indexOf('MSIE') >= 1) {
    return true;
  }
  return !!window.ActiveXObject || 'ActiveXObject' in window;
};

export const isEdge = function() {
  return !Vue.prototype.$isServer && navigator.userAgent.indexOf('Edge') > -1;
};

export const isFirefox = function() {
  return !Vue.prototype.$isServer && !!window.navigator.userAgent.match(/firefox/i);
};

export const autoprefixer = function(style) {
  if (typeof style !== 'object') return style;
  const rules = ['transform', 'transition', 'animation'];
  const prefixes = ['ms-', 'webkit-'];
  rules.forEach(rule => {
    const value = style[rule];
    if (rule && value) {
      prefixes.forEach(prefix => {
        style[prefix + rule] = value;
      });
    }
  });
  return style;
};

export const kebabCase = function(str) {
  const hyphenateRE = /([^-])([A-Z])/g;
  return str
    .replace(hyphenateRE, '$1-$2')
    .replace(hyphenateRE, '$1-$2')
    .toLowerCase();
};

export const capitalize = function(str) {
  if (!isString(str)) return str;
  return str.charAt(0).toUpperCase() + str.slice(1);
};

export const looseEqual = function(a, b) {
  const isObjectA = isObject(a);
  const isObjectB = isObject(b);
  if (isObjectA && isObjectB) {
    return JSON.stringify(a) === JSON.stringify(b);
  } else if (!isObjectA && !isObjectB) {
    return String(a) === String(b);
  } else {
    return false;
  }
};

export const arrayEquals = function(arrayA, arrayB) {
  arrayA = arrayA || [];
  arrayB = arrayB || [];

  if (arrayA.length !== arrayB.length) {
    return false;
  }

  for (let i = 0; i < arrayA.length; i++) {
    if (!looseEqual(arrayA[i], arrayB[i])) {
      return false;
    }
  }

  return true;
};

export const isEqual = function(value1, value2) {
  if (Array.isArray(value1) && Array.isArray(value2)) {
    return arrayEquals(value1, value2);
  }
  return looseEqual(value1, value2);
};

export const isEmpty = function(val) {
  // null or undefined
  if (val == null) return true;

  if (typeof val === 'boolean') return false;

  if (typeof val === 'number') return !val;

  if (val instanceof Error) return val.message === '';

  switch (Object.prototype.toString.call(val)) {
    // String or Array
    case '[object String]':
    case '[object Array]':
      return !val.length;

    // Map or Set or File
    case '[object File]':
    case '[object Map]':
    case '[object Set]': {
      return !val.size;
    }
    // Plain Object
    case '[object Object]': {
      return !Object.keys(val).length;
    }
  }

  return false;
};

export function rafThrottle(fn) {
  let locked = false;
  return function(...args) {
    if (locked) return;
    locked = true;
    window.requestAnimationFrame(_ => {
      fn.apply(this, args);
      locked = false;
    });
  };
}

export function objToArray(obj) {
  if (Array.isArray(obj)) {
    return obj;
  }
  return isEmpty(obj) ? [] : [obj];
}

export function loadScript(url, test) {
  if (test instanceof Function && test()) {
    // eslint-disable-next-line no-undef
    return Promise.resolve();
  }
  if (scriptPromises[url]) return scriptPromises[url];

  // eslint-disable-next-line no-undef
  scriptPromises[url] = new Promise((resolve, reject) => {
    const _script = document.createElement('script');
    _script.setAttribute('type', 'text/javascript');
    _script.setAttribute('src', url);
    document.getElementsByTagName('head')[0].appendChild(_script);
    if (_script.readyState) { // IE
      _script.onreadystatechange = function() {
        if (_script.readyState === 'loaded' || _script.readyState === 'complete') {
          _script.onreadystatechange = null;
          resolve();
        }
      };
    } else { // Others
      _script.onload = function() {
        resolve();
      };

      _script.onerror = function() {
        reject(new Error(`Failed to load ${url}`));
      };
    }

  });

  return scriptPromises[url];
}

/**
 * 深度拷贝
 * @param {*} obj;
 * @returns {*} copy;
 */
export function deepCopy(obj) {
  if (typeof obj !== 'object' || obj === null) {
    return obj;
  }

  let copy = Array.isArray(obj) ? [] : {};

  for (let key in obj) {
    if (obj.hasOwnProperty(key)) {
      copy[key] = deepCopy(obj[key]);
    }
  }

  return copy;
}

/**
 * 通过父组件名称获取组件实例
 * @param {*} that 上下文;
 * @param {*} parentName 组件名称;
 * @returns {*} vueComp ;
 */
export function getParentByName(that, parentName) {
  if (!that || !parentName) {
    return that || parentName || null;
  }

  if (that.$parent) {
    if (parentName === that.$parent.$options.name) {
      return that.$parent;
    } else {
      return getParentByName(that.$parent, parentName);
    }
  } else {
    return that;
  }
}

/**
 * 通过父组件名称获取组件实例
 * @param {*} vnode 上下文;
 * @param {*} parentName 组件名称;
 * @returns {*} vueComp ;
 */
export function getChildByName(vnode, childtName) {
  if (!vnode || !childtName) {
    return vnode || childtName || null;
  }

  if (vnode.componentOptions) {
    if (vnode.componentOptions.Ctor.options.name === childtName) {
      return vnode;
    } else {
      if (
        vnode.componentOptions.children &&
        vnode.componentOptions.children.length
      ) {
        return getChildByName(
          vnode.componentOptions.children[0],
          childtName
        );
      } else if (vnode.componentInstance.$children &&
               vnode.componentInstance.$children.length) {
        return getChildByName(vnode.componentInstance.$children[0].$vnode, childtName);
      } else {
        return vnode;
      }
    }
  } else {
    return vnode;
  }
}

/**
 *  获取组件实例
 * @param {*} target String;
 * @returns {*} vueComp ;
 */
export function getElement(target) {
  if (!isClient || (typeof target === 'string' && target === '')) {
    return null;
  }
  if (isString(target)) {
    try {
      return document.querySelector(target) || null;
    } catch (e) {
      return null;
    }
  }
  return target;
}

export function throttleByRaf(cb = (...args) => {}) {
  let timer = 0;

  const throttle = (...args) => {
    if (timer) {
      cAF(timer);
    }
    timer = rAF(() => {
      cb(...args);
      timer = 0;
    });
  };

  throttle.cancel = () => {
    cAF(timer);
    timer = 0;
  };

  return throttle;
}
