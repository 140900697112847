<template>
  <div class="yxtf-image" :style="wrapStyle">
    <slot v-if="loading" name="placeholder">
      <div class="yxtf-image__placeholder"></div>
    </slot>
    <slot v-else-if="error" name="error">
      <div class="yxtf-image__error">
        <div class="yxtf-image__error-content">
            <yxt-svg width="48" height="48" :remote-url='svgRemoteUrl' icon-class="img-failed" />
            <div class="mt12">
              {{ t('pc_comp_image_error') }}
            </div>
        </div>
      </div>
    </slot>
    <img
      v-else-if="currentRatio === 0"
      class="yxtf-image__inner"
      v-bind="$attrs"
      v-on="$listeners"
      @click="clickHandler"
      :src="webpSrc"
      :style="imageStyle"
      :class="{ 'yxtf-image__inner--center': alignCenter, 'yxtf-image__preview': preview }">
    <div v-else class="yxtf-image__ratio--container" :style="ratioStyle">
      <div class="yxtf-image__ratio--inner">
        <img
          class="yxtf-image__inner"
          v-bind="$attrs"
          v-on="$listeners"
          @click="clickHandler"
          :src="webpSrc"
          :style="imageStyle"
          :class="{ 'yxtf-image__inner--center': alignCenter, 'yxtf-image__preview': preview }">
      </div>
    </div>
    <image-viewer :z-index="zIndex" v-if="preview && showViewer" :on-close="closeViewer" :url-list="previewSrcList"/>
    <div class="yxtf-image__overlay" v-if="$slots.overlay">
      <slot name="overlay"></slot>
    </div>
  </div>
</template>

<script>
  import ImageViewer from './image-viewer';
  import Locale from '@utils/mixins/locale';
  import { on, off, getScrollContainer, isInContainer } from '@utils/utils/dom';
  import { isString, isHtmlElement } from '@utils/utils/types';
  import throttle from 'throttle-debounce/throttle';
  const staticBaseUrl = (typeof window !== 'undefined' && window.feConfig && window.feConfig.common && window.feConfig.common.staticBaseUrl) || 'https://stc.yxt.com/';
  const isSupportObjectFit = () => document.documentElement.style.objectFit !== undefined;

  const ObjectFit = {
    NONE: 'none',
    CONTAIN: 'contain',
    COVER: 'cover',
    FILL: 'fill',
    SCALE_DOWN: 'scale-down'
  };

  export default {
    name: 'YxtfImage',

    mixins: [Locale],
    inheritAttrs: false,

    components: {
      ImageViewer
    },

    props: {
      src: String,
      fit: String,
      lazy: Boolean,
      scrollContainer: {},
      previewSrcList: {
        type: Array,
        default: () => []
      },
      zIndex: {
        type: Number,
        default: 2000
      },
      ratio: {
        type: String,
        default: null
      }
    },

    data() {
      return {
        loading: true,
        error: false,
        show: !this.lazy,
        imageWidth: 0,
        imageHeight: 0,
        showViewer: false,
        webpSrc: '',
        svgRemoteUrl: `${staticBaseUrl}ufd/3f5568/common/pc_foreground/svg`
      };
    },

    computed: {
      imageStyle() {
        const { fit } = this;
        if (!this.$isServer && fit) {
          return isSupportObjectFit()
            ? { 'object-fit': fit }
            : this.getImageStyle(fit);
        }
        return {};
      },
      alignCenter() {
        return !this.$isServer && !isSupportObjectFit() && this.fit !== ObjectFit.FILL;
      },
      preview() {
        const { previewSrcList } = this;
        return Array.isArray(previewSrcList) && previewSrcList.length > 0;
      },
      currentRatio() {
        if (!this.ratio) return 0;
        const ratioArr = this.ratio.split('*');
        if (ratioArr.length !== 2) {
          console.error('未正确设置比例');
          return 0;
        }
  
        if (isNaN(Number(ratioArr[0])) || isNaN(Number(ratioArr[1]))) {
          console.error('比例只能为数字');
          return 0;
        }

        const [width, height] = ratioArr.map(Number);
        if (height === 0) {
          console.error('宽度设置不能为0');
          return 0;
        }
        return height / width;
      },
      ratioStyle() {
        if (!this.currentRatio) return {};
        return {
          'padding-top': `${this.currentRatio * 100}%`
        };
      },
      wrapStyle() {
        if (!this.currentRatio) return {};
        return {
          'display': 'block'
        };
      }
    },

    watch: {
      src(val) {
        this.show && this.loadImage();
      },
      show(val) {
        val && this.loadImage();
      }
    },

    mounted() {
      if (this.lazy) {
        this.addLazyLoadListener();
      } else {
        this.loadImage();
      }
    },

    beforeDestroy() {
      this.lazy && this.removeLazyLoadListener();
    },

    methods: {
      loadImage() {
        if (this.$isServer) return;

        // reset status
        this.loading = true;
        this.error = false;

        const img = new Image();
        img.onload = e => this.handleLoad(e, img);
        img.onerror = this.handleError.bind(this);

        // bind html attrs
        // so it can behave consistently
        Object.keys(this.$attrs)
          .forEach((key) => {
            const value = this.$attrs[key];
            img.setAttribute(key, value);
          });

        if (this.src) {
          this.webpSrc = this.src;
          img.src = this.src;
        } else {
          img.src = this.src;
        }
      },
      handleLoad(e, img) {
        this.imageWidth = img.width;
        this.imageHeight = img.height;
        this.loading = false;
      },
      handleError(e) {
        this.loading = false;
        this.error = true;
        this.$emit('error', e);
      },
      handleLazyLoad() {
        if (isInContainer(this.$el, this._scrollContainer)) {
          this.show = true;
          this.removeLazyLoadListener();
        }
      },
      addLazyLoadListener() {
        if (this.$isServer) return;

        const { scrollContainer } = this;
        let _scrollContainer = null;

        if (isHtmlElement(scrollContainer)) {
          _scrollContainer = scrollContainer;
        } else if (isString(scrollContainer)) {
          _scrollContainer = document.querySelector(scrollContainer);
        } else {
          _scrollContainer = getScrollContainer(this.$el);
        }

        if (_scrollContainer) {
          this._scrollContainer = _scrollContainer;
          this._lazyLoadHandler = throttle(200, this.handleLazyLoad);
          on(_scrollContainer, 'scroll', this._lazyLoadHandler);
          this.handleLazyLoad();
        }
      },
      removeLazyLoadListener() {
        const { _scrollContainer, _lazyLoadHandler } = this;

        if (this.$isServer || !_scrollContainer || !_lazyLoadHandler) return;

        off(_scrollContainer, 'scroll', _lazyLoadHandler);
        this._scrollContainer = null;
        this._lazyLoadHandler = null;
      },
      /**
       * simulate object-fit behavior to compatible with IE11 and other browsers which not support object-fit
       */
      getImageStyle(fit) {
        const { imageWidth, imageHeight } = this;
        const {
          clientWidth: containerWidth,
          clientHeight: containerHeight
        } = this.$el;

        if (!imageWidth || !imageHeight || !containerWidth || !containerHeight) return {};

        const vertical = imageWidth / imageHeight < 1;

        if (fit === ObjectFit.SCALE_DOWN) {
          const isSmaller = imageWidth < containerWidth && imageHeight < containerHeight;
          fit = isSmaller ? ObjectFit.NONE : ObjectFit.CONTAIN;
        }

        switch (fit) {
          case ObjectFit.NONE:
            return { width: 'auto', height: 'auto' };
          case ObjectFit.CONTAIN:
            return vertical ? { width: 'auto' } : { height: 'auto' };
          case ObjectFit.COVER:
            return vertical ? { height: 'auto' } : { width: 'auto' };
          default:
            return {};
        }
      },
      clickHandler() {
        this.showViewer = true;
      },
      closeViewer() {
        this.showViewer = false;
      }
    }
  };
</script>
