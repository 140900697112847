var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("span", { staticClass: "yxt-breadcrumb__item" }, [
    _c(
      "span",
      {
        ref: "link",
        class: ["yxt-breadcrumb__inner", _vm.to || _vm.isLink ? "is-link" : ""],
        attrs: { role: "link" },
      },
      [_vm._t("default")],
      2
    ),
    _vm.separatorClass
      ? _c("i", {
          staticClass: "yxt-breadcrumb__separator",
          class: _vm.separatorClass,
        })
      : _c(
          "span",
          {
            staticClass: "yxt-breadcrumb__separator",
            attrs: { role: "presentation" },
          },
          [_vm._v(_vm._s(_vm.separator))]
        ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }