var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "transition",
    {
      attrs: { name: "yxtf-zoom-in-top" },
      on: {
        "before-enter": _vm.handleMenuEnter,
        "after-leave": function ($event) {
          _vm.$emit("dodestroy")
        },
      },
    },
    [
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.visible,
              expression: "visible",
            },
          ],
          ref: "popper",
          staticClass: "yxtf-picker-panel time-select yxtf-popper",
          class: _vm.popperClass,
          style: { width: _vm.width + "px" },
        },
        [
          _c(
            "yxtf-scrollbar",
            {
              attrs: {
                noresize: "",
                "wrap-class": "yxtf-picker-panel__content",
              },
            },
            _vm._l(_vm.items, function (item) {
              return _c(
                "div",
                {
                  key: item.value,
                  staticClass: "time-select-item",
                  class: {
                    selected: _vm.value === item.value,
                    disabled: item.disabled,
                    default: item.value === _vm.defaultValue,
                  },
                  attrs: { disabled: item.disabled },
                  on: {
                    click: function ($event) {
                      _vm.handleClick(item)
                    },
                  },
                },
                [_vm._v(_vm._s(item.value))]
              )
            }),
            0
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }