import Picker from '../picker';
import DatePanel from '../panel/date';
import DateRangePanel from '../panel/date-range';
import DateTimeRangePanel from '../panel/date-time-range';
import MonthRangePanel from '../panel/month-range';
import YearRangePanel from '../panel/year-range';

const getPanel = function(type) {
  if (type === 'daterange') {
    return DateRangePanel;
  } else if (type === 'monthrange') {
    return MonthRangePanel;
  } else if (type === 'datetimerange') {
    return DateTimeRangePanel;
  } else if (type === 'yearrange') {
    return YearRangePanel;
  }
  return DatePanel;
};

export default {
  mixins: [Picker],

  name: 'YxtDatePicker',

  props: {
    type: {
      type: String,
      default: 'date',
      desc: '日期显示类型'
    },
    timeArrowControl: {
      type: Boolean,
      desc: '是否使用箭头进行时间选择，只有包含时间选择的组件有效'
    }
  },

  watch: {
    type(type) {
      if (this.picker) {
        this.unmountPicker();
        this.panel = getPanel(type);
        this.mountPicker();
      } else {
        this.panel = getPanel(type);
      }
    }
  },

  created() {
    this.panel = getPanel(this.type);
  }
};
