var render = function (_h, _vm) {
  var _c = _vm._c
  return _c(
    "div",
    _vm._g(
      _vm._b(
        {
          class: [
            _vm.data.staticClass,
            "yxtf-divider",
            "yxtf-divider--" + _vm.props.direction,
          ],
        },
        "div",
        _vm.data.attrs,
        false
      ),
      _vm.listeners
    ),
    [
      _vm.slots().default && _vm.props.direction !== "vertical"
        ? _c(
            "div",
            {
              class: ["yxtf-divider__text", "is-" + _vm.props.contentPosition],
            },
            [_vm._t("default")],
            2
          )
        : _vm._e(),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }