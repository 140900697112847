<script>
import TabNav from './tab-nav';
import { getChildByName } from '@utils/utils/util';

export default {
  name: 'YxtTabs',

  components: {
    TabNav
  },

  props: {
    type: String,
    drawerTop: {
      type: Boolean,
      default: false
    },
    activeName: String,
    closable: Boolean,
    addable: Boolean,
    value: {},
    editable: Boolean,
    tabPosition: {
      type: String,
      default: 'top'
    },
    paddingLeft: {
      type: Number,
      default: 24
    },
    beforeLeave: Function,
    stretch: Boolean,
    heightSize: {
      type: String,
      default: 'large'
    },
    allowNest: {// 允许子组件嵌套 （支持appmaker 内插槽内插入自定义子组件无法找到目标组件的问题 ）
      type: Boolean,
      default: false
    }
  },

  provide() {
    return {
      rootTabs: this
    };
  },

  data() {
    return {
      currentName: this.value || this.activeName,
      panes: []
    };
  },

  watch: {
    activeName(value) {
      this.setCurrentName(value);
    },
    value(value) {
      this.setCurrentName(value);
    },
    currentName(value) {
      if (this.$refs.nav) {
        this.$nextTick(() => {
          this.$refs.nav.$nextTick(_ => {
            this.$refs.nav.scrollToActiveTab();
          });
        });
      }
    }
  },

  methods: {
    calcPaneInstances(isForceUpdate = false) {
      if (this.$slots.default) {
        let panes = [];
        if (!this.allowNest) {
          const paneSlots = this.$slots.default.filter(vnode => vnode.tag &&
          vnode.componentOptions && vnode.componentOptions.Ctor.options.name === 'YxtTabPane');
          panes = paneSlots.map(({ componentInstance }) => componentInstance);
        } else {
          const paneSlots = this.$slots.default.filter(vnode => vnode.tag && vnode.componentOptions);
          const newPaneSlots = [];
          paneSlots.forEach(vnode => {
            newPaneSlots.push(getChildByName(vnode, 'YxtTabPane'));
          });
          panes = newPaneSlots.map(({ componentInstance }) => componentInstance);
        }

        // update indeed
        const panesChanged = !(panes.length === this.panes.length && panes.every((pane, index) => pane === this.panes[index]));
        if (isForceUpdate || panesChanged) {
          this.panes = panes;
        }
      } else if (this.panes.length !== 0) {
        this.panes = [];
      }
    },
    handleTabClick(tab, tabName, event) {
      if (tab.disabled) return;
      this.setCurrentName(tabName);
      this.$emit('tab-click', tab, event);
    },
    handleTabRemove(pane, ev) {
      if (pane.disabled) return;
      ev.stopPropagation();
      this.$emit('edit', pane.name, 'remove');
      this.$emit('tab-remove', pane.name);
    },
    handleTabAdd() {
      this.$emit('edit', null, 'add');
      this.$emit('tab-add');
    },
    setCurrentName(value) {
      const changeCurrentName = () => {
        this.currentName = value;
        this.$emit('input', value);
      };
      if (this.currentName !== value && this.beforeLeave) {
        const before = this.beforeLeave(value, this.currentName);
        if (before && before.then) {
          before
            .then(() => {
              changeCurrentName();
              this.$refs.nav && this.$refs.nav.removeFocus();
            }, () => {
              // https://github.com/ElemeFE/element/pull/14816
              // ignore promise rejection in `before-leave` hook
            });
        } else if (before !== false) {
          changeCurrentName();
        }
      } else {
        changeCurrentName();
      }
    }
  },

  render(h) {
    let {
      type,
      paddingLeft,
      handleTabClick,
      handleTabRemove,
      handleTabAdd,
      currentName,
      panes,
      editable,
      closable,
      addable,
      tabPosition,
      stretch,
      heightSize
    } = this;

    const newButton = editable || addable
      ? (
        <span
          class="yxt-tabs__new-tab"
          on-click={handleTabAdd}
          tabindex="0"
          on-keydown={(ev) => { if (ev.keyCode === 13) { handleTabAdd(); } }}
        >
          <yxt-svg icon-class='plus-circle-o' width='16px' height='16px'></yxt-svg>
          添加分类
        </span>
      )
      : null;

    const navData = {
      props: {
        currentName,
        onTabClick: handleTabClick,
        onTabRemove: handleTabRemove,
        editable,
        closable,
        type,
        paddingLeft,
        panes,
        stretch,
        heightSize
      },
      ref: 'nav'
    };
    const header = (
      <div class={['yxt-tabs__header', `is-${tabPosition}`, this.drawerTop ? 'yxt-tabs__drawer-top' : '']}>
        {newButton}
        <tab-nav {...navData}>
          <template slot="extra">
            { this.$slots.extra}
          </template>
        </tab-nav>
      </div>
    );
    const panels = (
      <div class="yxt-tabs__content">
        {this.$slots.default}
      </div>
    );
    const shadow = (
      <div style="height: 0;width: 0;position: absolute;">
        <svg height="0" xmlns="http://www.w3.org/2000/svg">
          <filter id="drop-shadow">
            <feGaussianBlur in="SourceAlpha" stdDeviation="3" />
            <feOffset dx="0" dy="0" result="offsetblur" />
            <feFlood flood-color="rgba(138,138,138,0.05)" />
            <feComposite in2="offsetblur" operator="in" />
            <feMerge>
              <feMergeNode />
              <feMergeNode in="SourceGraphic" />
            </feMerge>
          </filter>
        </svg>
      </div>
    );
    return (
      <div class={{
        'yxt-tabs': true,
        'yxt-tabs--card': type === 'card',
        'yxt-tabs--text': type === 'text',
        'yxt-tabs--wrap': type === 'wrap',
        [`yxt-tabs--${tabPosition}`]: true,
        'yxt-tabs--border-card': type === 'border-card',
        'yxt-tabs--special': type === 'special',
        'yxt-tabs--simple': type === 'simple',
        'yxt-tabs--ant': type === 'ant'
      }}>
        {(type === 'card' || type === 'wrap') && shadow}
        {tabPosition !== 'bottom' ? [header, panels] : [panels, header]}
      </div>
    );
  },

  created() {
    if (!this.currentName) {
      this.setCurrentName('0');
    }

    this.$on('tab-nav-update', this.calcPaneInstances.bind(null, true));
  },

  mounted() {
    this.calcPaneInstances();
  },

  updated() {
    this.calcPaneInstances();
  }
};
</script>
