var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "yxt-upload-dragger",
      class: {
        "is-dragover": _vm.dragover,
      },
      on: {
        drop: function ($event) {
          $event.preventDefault()
          return _vm.onDrop($event)
        },
        dragover: function ($event) {
          $event.preventDefault()
          return _vm.onDragover($event)
        },
        dragleave: function ($event) {
          $event.preventDefault()
          _vm.dragover = false
        },
      },
    },
    [_vm._t("default")],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }