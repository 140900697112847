var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("li", { staticClass: "yxt-menu-item-group" }, [
    _c(
      "div",
      {
        staticClass: "yxt-menu-item-group__title",
        style: { paddingLeft: _vm.levelPadding + "px" },
      },
      [!_vm.$slots.title ? [_vm._v(_vm._s(_vm.title))] : _vm._t("title")],
      2
    ),
    _c("ul", [_vm._t("default")], 2),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }