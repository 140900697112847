import Vue from 'vue';
import { hasClass, addClass, removeClass, on, off } from '@utils/utils/dom';
import ElCheckbox from '@foreground/checkbox';
import FilterPanel from './filter-panel.vue';
import LayoutObserver from './layout-observer';
import { mapStates } from './store/helper';
import debounce from 'throttle-debounce/debounce';
import { getThTextCell } from './util';
import { isFirefox } from '@utils/utils/util';
import Locale from '@utils/mixins/locale';

const getAllColumns = (columns) => {
  const result = [];
  columns.forEach((column) => {
    if (column.children) {
      result.push(column);
      result.push.apply(result, getAllColumns(column.children));
    } else {
      result.push(column);
    }
  });
  return result;
};

const convertToRows = (originColumns) => {
  let maxLevel = 1;
  const traverse = (column, parent) => {
    if (parent) {
      column.level = parent.level + 1;
      if (maxLevel < column.level) {
        maxLevel = column.level;
      }
    }
    if (column.children) {
      let colSpan = 0;
      column.children.forEach((subColumn) => {
        traverse(subColumn, column);
        colSpan += subColumn.colSpan;
      });
      column.colSpan = colSpan;
    } else {
      column.colSpan = 1;
    }
  };

  originColumns.forEach((column) => {
    column.level = 1;
    traverse(column);
  });

  const rows = [];
  for (let i = 0; i < maxLevel; i++) {
    rows.push([]);
  }

  const allColumns = getAllColumns(originColumns);

  allColumns.forEach((column) => {
    if (!column.children) {
      column.rowSpan = maxLevel - column.level + 1;
    } else {
      column.rowSpan = 1;
    }
    rows[column.level - 1].push(column);
  });

  return rows;
};

export default {
  name: 'YxtfTableHeader',

  mixins: [LayoutObserver, Locale],

  render(h) {
    const originColumns = this.store.states.originColumns;
    const columnRows = convertToRows(originColumns, this.columns);
    // 是否拥有多级表头
    const isGroup = columnRows.length > 1;
    if (isGroup) this.$parent.isGroup = true;
    const fixedButton = this.store.states.customColumnProps && this.store.states.customColumnProps.fixedButton && this.fixed;
    return (
      <table
        class="yxtf-table__header"
        cellspacing="0"
        cellpadding="0"
        border="0">
        <colgroup>
          {
            this.columns.map(column => <col name={ column.id } key={column.id} />)
          }
          {
            this.hasGutter ? <col name="gutter" /> : ''
          }
        </colgroup>
        <thead class={ [{ 'is-group': isGroup, 'has-gutter': this.hasGutter }] }>
          { fixedButton && <div class="custom-column-fixed-button">
            <div on-click={ ($event) => this.toggleCustomPanelShow($event) } class="custom-column-btn"><i class="yxtf-icon-setting"></i></div>
          </div> }
          {
            this._l(columnRows, (columns, rowIndex) =>
              <tr
                style={ this.getHeaderRowStyle(rowIndex) }
                class={ this.getHeaderRowClass(rowIndex) }
              >
                {
                  columns.map((column, cellIndex) => (<th
                    colspan={ column.colSpan }
                    rowspan={ column.rowSpan }
                    on-mousemove={ ($event) => this.handleMouseMove($event, column) }
                    on-mouseout={ this.handleMouseOut }
                    on-mousedown={ ($event) => this.handleMouseDown($event, column) }
                    on-click={ ($event) => this.handleHeaderClick($event, column) }
                    on-contextmenu={ ($event) => this.handleHeaderContextMenu($event, column) }
                    style={ this.getHeaderCellStyle(rowIndex, cellIndex, columns, column) }
                    class={ this.getHeaderCellClass(rowIndex, cellIndex, columns, column) }
                    on-mouseenter={$event => this.handleCellMouseEnter($event, column)}
                    on-mouseleave={$event => this.handleCellMouseLeave($event)}
                    key={ column.id }>
                    <div
                      class={ [
                        column.type === 'default' ? 'cell'
                          : column.type === 'selection' && 'cell-selection' || column.type === 'custom-column' && 'cell-custom-column',
                        column.filteredValue && column.filteredValue.length > 0 ? 'highlight' : '',
                        column.labelClassName,
                        column.paddingClass ? column.paddingClass : '',
                        columns.length - 1 === cellIndex && 'last-custom'
                      ] }
                      style={ [column.realWidth ? {width: `${column.realWidth}px`} : null] }
                    >
                      {/* {
                        column.renderHeader
                          ? column.renderHeader.call(this._renderProxy, h, { column, $index: cellIndex, store: this.store, _self: this.$parent.$vnode.context })
                          : column.label
                      } */}
                      <span class={[
                        'cell-cont',
                        column.sortable ? 'width-sort' : ''
                      ]}>
                        {
                          column.renderHeader
                            ? column.renderHeader.call(this._renderProxy, h, { column, $index: cellIndex, store: this.store, _self: this.$parent.$vnode.context })
                            : column.label
                        }
                      </span>
                      {
                        column.type === 'custom-column' && (
                          <div on-click={ ($event) => this.toggleCustomPanelShow($event) } class="custom-column-btn"><i class="yxtf-icon-setting"></i></div>
                        )
                      }
                      {
                        column.sortable ? (<span
                          class={ ['caret-wrapper', { 'fixed-right': column.sortableFixedRight }] }
                          on-click={ ($event) => this.handleSortClick($event, column) }>
                          <i class="sort-caret ascending"
                            on-click={ ($event) => this.handleSortClick($event, column, 'asc') }>
                          </i>
                          <i class="sort-caret descending"
                            on-click={ ($event) => this.handleSortClick($event, column, 'desc') }>
                          </i>
                        </span>) : ''
                      }
                      {
                        column.filterable ? (<span
                          class="yxtf-table__column-filter-trigger"
                          on-click={ ($event) => this.handleFilterClick($event, column) }>
                          <i class={ ['yxtf-icon-arrow-down', column.filterOpened ? 'yxtf-icon-arrow-up' : ''] }></i>
                        </span>) : ''
                      }
                    </div>
                    {
                      (column.dragable || (!isGroup && !this.border && Boolean(column.label))) ? (
                        <yxt-tooltip class="item" effect="dark" content={this.t('pc_comp_table_columnDragTip')} placement="top">
                          123<span class="column-drager"></span>
                        </yxt-tooltip>) : ''
                    }
                  </th>))
                }
                {
                  this.hasGutter ? <th class="gutter"></th> : ''
                }
              </tr>
            )
          }
        </thead>
        <yxt-popover
          show-scroll
          max-width={300}
          max-height={200}
          placement="top"
          ref="tooltip"
          popper-class="yxt-table__popper"
        >{ this.tooltipContent }</yxt-popover>
      </table>
    );
  },

  props: {
    fixed: String,
    store: {
      required: true
    },
    tooltipEffect: {
      type: String,
      default: 'dark'
    },
    border: Boolean,
    defaultSort: {
      type: Object,
      default() {
        return {
          prop: '',
          order: ''
        };
      }
    }
  },

  components: {
    ElCheckbox
  },

  computed: {
    table() {
      return this.$parent;
    },

    hasGutter() {
      return !this.fixed && this.tableLayout.gutterWidth;
    },

    ...mapStates({
      columns: 'columns',
      isAllSelected: 'isAllSelected',
      leftFixedLeafCount: 'fixedLeafColumnsLength',
      rightFixedLeafCount: 'rightFixedLeafColumnsLength',
      columnsCount: states => states.columns.length,
      leftFixedCount: states => states.fixedColumns.length,
      rightFixedCount: states => states.rightFixedColumns.length
    })
  },

  created() {
    this.filterPanels = {};
    this.activateTooltip = debounce(50, (tooltip) => {
      tooltip.doClose();
      tooltip.handleMouseEnter();
      on(tooltip.popperElm, 'mouseenter', () => { this.activateTooltip(tooltip); });
      on(tooltip.popperElm, 'mouseleave', () => { this.unactivateTooltip(tooltip);});
    });
    this.unactivateTooltip = debounce(50, (tooltip) => {
      tooltip.handleMouseLeave();
      off(tooltip.popperElm, 'mouseenter', () => { this.activateTooltip(tooltip); });
      off(tooltip.popperElm, 'mouseleave', () => { this.unactivateTooltip(tooltip);});
    });
  },

  mounted() {
    // nextTick 是有必要的 https://github.com/ElemeFE/element/pull/11311
    this.$nextTick(() => {
      const { prop, order } = this.defaultSort;
      const init = true;
      this.store.commit('sort', { prop, order, init });
    });
  },

  beforeDestroy() {
    const panels = this.filterPanels;
    for (let prop in panels) {
      if (panels.hasOwnProperty(prop) && panels[prop]) {
        panels[prop].$destroy(true);
      }
    }
    // 取消 点击body点击关闭面板的事件监听
    document.body.removeEventListener('click', this.hideCustomPanelShow);
  },

  methods: {
    handleCellMouseEnter(event, column) {
      const tooltip = this.$refs.tooltip;
      const reference = getThTextCell(event);
      tooltip.referenceElm = reference;
      tooltip.doClose();
      tooltip.doDestroy();
      this.tooltipContent = tooltip.referenceElm.textContent;

      let activeTooltipFlag = reference.scrollWidth > reference.offsetWidth;
      if (isFirefox && column.type === 'selection') {
        activeTooltipFlag = reference.scrollWidth > reference.offsetWidth + 4;
      }

      if (activeTooltipFlag) {
        tooltip.$refs.popper && (tooltip.$refs.popper.style.display = 'none');
        tooltip.updateScrollbar();
        this.$nextTick(() => {
          this.activateTooltip(tooltip);
        });
      }
    },
    handleCellMouseLeave(event) {
      const tooltip = this.$refs.tooltip;
      this.$nextTick(() => {
        if (tooltip) {
          this.unactivateTooltip(tooltip);
        }
      });
    },
    isCellHidden(index, columns) {
      let start = 0;
      for (let i = 0; i < index; i++) {
        start += columns[i].colSpan;
      }
      const after = start + columns[index].colSpan - 1;
      if (this.fixed === true || this.fixed === 'left') {
        return after >= this.leftFixedLeafCount;
      } else if (this.fixed === 'right') {
        return start < this.columnsCount - this.rightFixedLeafCount;
      } else {
        return (after < this.leftFixedLeafCount) || (start >= this.columnsCount - this.rightFixedLeafCount);
      }
    },

    getHeaderRowStyle(rowIndex) {
      const headerRowStyle = this.table.headerRowStyle;
      if (typeof headerRowStyle === 'function') {
        return headerRowStyle.call(null, { rowIndex });
      }
      return headerRowStyle;
    },

    getHeaderRowClass(rowIndex) {
      const classes = [];

      const headerRowClassName = this.table.headerRowClassName;
      if (typeof headerRowClassName === 'string') {
        classes.push(headerRowClassName);
      } else if (typeof headerRowClassName === 'function') {
        classes.push(headerRowClassName.call(null, { rowIndex }));
      }

      return classes.join(' ');
    },

    getHeaderCellStyle(rowIndex, columnIndex, row, column) {
      const headerCellStyle = this.table.headerCellStyle;
      if (typeof headerCellStyle === 'function') {
        return headerCellStyle.call(null, {
          rowIndex,
          columnIndex,
          row,
          column
        });
      }
      return headerCellStyle;
    },

    getHeaderCellClass(rowIndex, columnIndex, row, column) {
      const classes = [column.id, column.headerAlign, column.className, column.labelClassName];
      const isDragable = column.dragable || (!this.$parent.isGroup && Boolean(column.label));
      if (column.order === 'asc') {
        classes.push('ascending');
      } else if (column.order === 'desc') {
        classes.push('descending');
      }

      if (rowIndex === 0 && this.isCellHidden(columnIndex, row)) {
        classes.push('is-hidden');
      }

      if (!column.children) {
        classes.push('is-leaf');
      }

      if (column.sortable) {
        classes.push('is-sortable');
      }

      if (isDragable) {
        classes.push('is-dragable');
      }

      const headerCellClassName = this.table.headerCellClassName;
      if (typeof headerCellClassName === 'string') {
        classes.push(headerCellClassName);
      } else if (typeof headerCellClassName === 'function') {
        classes.push(headerCellClassName.call(null, {
          rowIndex,
          columnIndex,
          row,
          column
        }));
      }

      return classes.join(' ');
    },

    toggleAllSelection(event) {
      event.stopPropagation();
      if (this.store.states.data && this.store.states.data.length === 0) {
        return;
      }
      this.store.commit('toggleAllSelection');
    },

    handleFilterClick(event, column) {
      event.stopPropagation();
      const target = event.target;
      let cell = target.tagName === 'TH' ? target : target.parentNode;
      if (hasClass(cell, 'noclick')) return;
      cell = cell.querySelector('.yxtf-table__column-filter-trigger') || cell;
      const table = this.$parent;

      let filterPanel = this.filterPanels[column.id];

      if (filterPanel && column.filterOpened) {
        filterPanel.showPopper = false;
        return;
      }

      if (!filterPanel) {
        filterPanel = new Vue(FilterPanel);
        this.filterPanels[column.id] = filterPanel;
        if (column.filterPlacement) {
          filterPanel.placement = column.filterPlacement;
        }
        filterPanel.table = table;
        filterPanel.cell = cell;
        filterPanel.column = column;
        !this.$isServer && filterPanel.$mount(document.createElement('div'));
      }

      setTimeout(() => {
        filterPanel.showPopper = true;
      }, 16);
    },

    handleHeaderClick(event, column) {
      if (!column.filters && column.sortable) {
        this.handleSortClick(event, column);
      } else if (column.filterable && !column.sortable) {
        this.handleFilterClick(event, column);
      }

      this.$parent.$emit('header-click', column, event);
    },

    handleHeaderContextMenu(event, column) {
      this.$parent.$emit('header-contextmenu', column, event);
    },

    handleMouseDown(event, column) {
      if (this.$isServer) return;
      if (column.children && column.children.length > 0) return;
      const isDragable = column.dragable || (!this.$parent.isGroup && Boolean(column.label));
      /* istanbul ignore if */
      if (this.draggingColumn && (this.border || isDragable)) {
        this.dragging = true;

        this.$parent.resizeProxyVisible = true;

        const table = this.$parent;
        const tableEl = table.$el;
        const tableLeft = tableEl.getBoundingClientRect().left;
        const columnEl = this.$el.querySelector(`th.${column.id}`);
        const columnRect = columnEl.getBoundingClientRect();
        const minLeft = columnRect.left - tableLeft + 32 +
          (column.sortable ? 16 : 0) +
          (isDragable ? 16 : 0);
        const headerHight = this.$el.getBoundingClientRect().height;

        addClass(columnEl, 'noclick');

        this.dragState = {
          startMouseLeft: event.clientX,
          startLeft: columnRect.right - tableLeft,
          startColumnLeft: columnRect.left - tableLeft,
          tableLeft
        };

        const resizeProxy = table.$refs.resizeProxy;
        resizeProxy.style.left = this.dragState.startLeft + 'px';
        resizeProxy.style.top = headerHight + 'px';

        document.onselectstart = function() { return false; };
        document.ondragstart = function() { return false; };

        const handleMouseMove = (event) => {
          const deltaLeft = event.clientX - this.dragState.startMouseLeft;
          const proxyLeft = this.dragState.startLeft + deltaLeft;

          resizeProxy.style.left = Math.max(minLeft, proxyLeft) + 'px';
        };

        const handleMouseUp = () => {
          if (this.dragging) {
            const {
              startColumnLeft,
              startLeft
            } = this.dragState;
            const finalLeft = parseInt(resizeProxy.style.left, 10);
            const columnWidth = finalLeft - startColumnLeft;
            column.width = column.realWidth = columnWidth;
            table.$emit('header-dragend', column.width, startLeft - startColumnLeft, column, event);

            this.store.scheduleLayout();

            document.body.style.cursor = '';
            this.dragging = false;
            // this.draggingColumn = null;
            this.dragState = {};

            table.resizeProxyVisible = false;
          }

          document.removeEventListener('mousemove', handleMouseMove);
          document.removeEventListener('mouseup', handleMouseUp);
          document.onselectstart = null;
          document.ondragstart = null;

          setTimeout(function() {
            removeClass(columnEl, 'noclick');
          }, 0);
        };

        document.addEventListener('mousemove', handleMouseMove);
        document.addEventListener('mouseup', handleMouseUp);
      }
    },

    handleMouseMove(event, column) {
      if (column.children && column.children.length > 0) return;
      let target = event.target;
      while (target && target.tagName !== 'TH') {
        target = target.parentNode;
      }

      if (!column || !column.resizable) return;
      const isDragable = column.dragable || (!this.$parent.isGroup && Boolean(column.label));
      if (!this.dragging && (this.border || isDragable)) {
        let rect = target.getBoundingClientRect();

        const bodyStyle = document.body.style;
        const isDragger = event.target.classList.contains('column-drager');
        if (isDragger || rect.width > 12 && rect.right - event.pageX < 8) {
          bodyStyle.cursor = 'col-resize';
          if (hasClass(target, 'is-sortable')) {
            target.style.cursor = 'col-resize';
          }
          this.draggingColumn = column;
        } else if (!this.dragging) {
          bodyStyle.cursor = '';
          if (hasClass(target, 'is-sortable')) {
            target.style.cursor = 'pointer';
          }
          this.draggingColumn = null;
        }
      }
    },

    handleMouseOut() {
      if (this.$isServer) return;
      document.body.style.cursor = '';
    },

    toggleOrder({ order, sortOrders }) {
      if (order === '') return sortOrders[0];
      const index = sortOrders.indexOf(order || null);
      return sortOrders[index > sortOrders.length - 2 ? 0 : index + 1];
    },

    handleSortClick(event, column, givenOrder) {
      event.stopPropagation();
      let order = column.order === givenOrder
        ? null
        : (givenOrder || this.toggleOrder(column));

      let target = event.target;
      while (target && target.tagName !== 'TH') {
        target = target.parentNode;
      }

      if (target && target.tagName === 'TH') {
        if (hasClass(target, 'noclick')) {
          removeClass(target, 'noclick');
          return;
        }
      }

      if (!column.sortable) return;

      const states = this.store.states;
      let sortProp = states.sortProp;
      let sortOrder;
      const sortingColumn = states.sortingColumn;

      if (sortingColumn !== column || (sortingColumn === column && sortingColumn.order === null)) {
        if (sortingColumn) {
          sortingColumn.order = null;
        }
        states.sortingColumn = column;
        sortProp = column.property;
      }

      if (!order) {
        sortOrder = column.order = null;
      } else {
        sortOrder = column.order = order;
      }

      states.sortProp = sortProp;
      states.sortOrder = sortOrder;

      this.store.commit('changeSortCondition');
    },

    toggleCustomPanelShow(event) {
      // document.body.click();
      this.store.toggleCustomColumnPanelShow();
      event.stopPropagation();
      if (this.store.states.showCustomColumnPanel) {
        // 添加 点击body点击关闭面板的事件监听
        document.body.addEventListener('click', this.hideCustomPanelShow);
      } else {
        // 取消 点击body点击关闭面板的事件监听
        document.body.removeEventListener('click', this.hideCustomPanelShow);
      }
    },
    hideCustomPanelShow() {
      if (this.store.states.showCustomColumnPanel) {
        this.store.toggleCustomColumnPanelShow();
      }
    }
  },
  data() {
    return {
      draggingColumn: null,
      dragging: false,
      dragState: {},
      tooltipContent: ''
    };
  }
};
