var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("span", { staticClass: "yxt-guide" }, [
    _c(
      "span",
      {
        attrs: { id: "yxtGuide" },
        on: {
          click: function ($event) {
            $event.preventDefault()
            $event.stopPropagation()
            return _vm.guide($event)
          },
        },
      },
      [_vm._t("default")],
      2
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }