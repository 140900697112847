var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "tree-scroll-wrap", style: _vm.wrapStyle },
    [
      _vm.openScroll
        ? _c(
            "yxt-scrollbar",
            { ref: "scrollbar" },
            [
              _c("yxt-treelist", {
                ref: "treelist",
                attrs: {
                  virtual: _vm.virtual,
                  data: _vm.data,
                  emptyText: _vm.emptyText,
                  renderAfterExpand: _vm.renderAfterExpand,
                  nodeKey: _vm.nodeKey,
                  checkStrictly: _vm.checkStrictly,
                  defaultExpandAll: _vm.defaultExpandAll,
                  expandOnClickNode: _vm.expandOnClickNode,
                  checkOnClickNode: _vm.checkOnClickNode,
                  checkDescendants: _vm.checkDescendants,
                  autoExpandParent: _vm.autoExpandParent,
                  defaultCheckedKeys: _vm.defaultCheckedKeys,
                  defaultExpandedKeys: _vm.defaultExpandedKeys,
                  currentNodeKey: _vm.currentNodeKey,
                  renderContent: _vm.renderContent,
                  showCheckbox: _vm.showCheckbox,
                  draggable: _vm.draggable,
                  allowDrag: _vm.allowDrag,
                  allowDrop: _vm.allowDrop,
                  props: _vm.props,
                  lazy: _vm.lazy,
                  highlightCurrent: _vm.highlightCurrent,
                  load: _vm.load,
                  filterNodeMethod: _vm.filterNodeMethod,
                  accordion: _vm.accordion,
                  indent: _vm.indent,
                  iconClass: _vm.iconClass,
                },
              }),
            ],
            1
          )
        : _c(
            "box",
            [
              _c("yxt-treelist", {
                ref: "treelist",
                attrs: {
                  virtual: _vm.virtual,
                  data: _vm.data,
                  emptyText: _vm.emptyText,
                  renderAfterExpand: _vm.renderAfterExpand,
                  nodeKey: _vm.nodeKey,
                  checkStrictly: _vm.checkStrictly,
                  defaultExpandAll: _vm.defaultExpandAll,
                  expandOnClickNode: _vm.expandOnClickNode,
                  checkOnClickNode: _vm.checkOnClickNode,
                  checkDescendants: _vm.checkDescendants,
                  autoExpandParent: _vm.autoExpandParent,
                  defaultCheckedKeys: _vm.defaultCheckedKeys,
                  defaultExpandedKeys: _vm.defaultExpandedKeys,
                  currentNodeKey: _vm.currentNodeKey,
                  renderContent: _vm.renderContent,
                  showCheckbox: _vm.showCheckbox,
                  draggable: _vm.draggable,
                  allowDrag: _vm.allowDrag,
                  allowDrop: _vm.allowDrop,
                  props: _vm.props,
                  lazy: _vm.lazy,
                  highlightCurrent: _vm.highlightCurrent,
                  load: _vm.load,
                  filterNodeMethod: _vm.filterNodeMethod,
                  accordion: _vm.accordion,
                  indent: _vm.indent,
                  iconClass: _vm.iconClass,
                },
              }),
            ],
            1
          ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }