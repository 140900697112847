var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "label",
    {
      staticClass: "yxtf-checkbox-button",
      class: [
        _vm.size ? "yxtf-checkbox-button--" + _vm.size : "",
        { "is-disabled": _vm.isDisabled },
        { "is-checked": _vm.isChecked },
        { "is-focus": _vm.focus },
      ],
      attrs: {
        role: "checkbox",
        "aria-checked": _vm.isChecked,
        "aria-disabled": _vm.isDisabled,
      },
    },
    [
      _vm.trueLabel || _vm.falseLabel
        ? _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.model,
                expression: "model",
              },
            ],
            staticClass: "yxtf-checkbox-button__original",
            attrs: {
              type: "checkbox",
              name: _vm.name,
              disabled: _vm.isDisabled,
              "true-value": _vm.trueLabel,
              "false-value": _vm.falseLabel,
            },
            domProps: {
              checked: Array.isArray(_vm.model)
                ? _vm._i(_vm.model, null) > -1
                : _vm._q(_vm.model, _vm.trueLabel),
            },
            on: {
              change: [
                function ($event) {
                  var $$a = _vm.model,
                    $$el = $event.target,
                    $$c = $$el.checked ? _vm.trueLabel : _vm.falseLabel
                  if (Array.isArray($$a)) {
                    var $$v = null,
                      $$i = _vm._i($$a, $$v)
                    if ($$el.checked) {
                      $$i < 0 && (_vm.model = $$a.concat([$$v]))
                    } else {
                      $$i > -1 &&
                        (_vm.model = $$a
                          .slice(0, $$i)
                          .concat($$a.slice($$i + 1)))
                    }
                  } else {
                    _vm.model = $$c
                  }
                },
                _vm.handleChange,
              ],
              focus: function ($event) {
                _vm.focus = true
              },
              blur: function ($event) {
                _vm.focus = false
              },
            },
          })
        : _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.model,
                expression: "model",
              },
            ],
            staticClass: "yxtf-checkbox-button__original",
            attrs: {
              type: "checkbox",
              name: _vm.name,
              disabled: _vm.isDisabled,
            },
            domProps: {
              value: _vm.label,
              checked: Array.isArray(_vm.model)
                ? _vm._i(_vm.model, _vm.label) > -1
                : _vm.model,
            },
            on: {
              change: [
                function ($event) {
                  var $$a = _vm.model,
                    $$el = $event.target,
                    $$c = $$el.checked ? true : false
                  if (Array.isArray($$a)) {
                    var $$v = _vm.label,
                      $$i = _vm._i($$a, $$v)
                    if ($$el.checked) {
                      $$i < 0 && (_vm.model = $$a.concat([$$v]))
                    } else {
                      $$i > -1 &&
                        (_vm.model = $$a
                          .slice(0, $$i)
                          .concat($$a.slice($$i + 1)))
                    }
                  } else {
                    _vm.model = $$c
                  }
                },
                _vm.handleChange,
              ],
              focus: function ($event) {
                _vm.focus = true
              },
              blur: function ($event) {
                _vm.focus = false
              },
            },
          }),
      _vm.$slots.default || _vm.label
        ? _c(
            "span",
            {
              staticClass: "yxtf-checkbox-button__inner",
              style: _vm.isChecked ? _vm.activeStyle : null,
            },
            [_vm._t("default", [_vm._v(_vm._s(_vm.label))])],
            2
          )
        : _vm._e(),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }