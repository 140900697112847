<template>
  <div class="yxt-avatar-group">
    <template v-for="(item, idx) in list">
      <yxt-avatar v-bind="item" v-on="$listeners" :key="idx" :class="{'yxt-avatar-group--avatar': idx !== 0}"></yxt-avatar>
    </template>
  </div>
</template>

<script>
import avatar from '../../avatar';

export default {
  name: 'YxtAvatarGroup',
  components: { avatar },
  props: {
    dataList: {
      type: Array,
      default: () => []
    },
    max: String | Number,
    total: Number
  },
  computed: {
    list() {
      const list = this.dataList.map(item => {
        return {
          ...this.$attrs,
          ...item
        };
      });
      let arr = this.max ? list.splice(0, Number(this.max)) : list;
      if (this.total) {
        const totalAva = {
          username: `+${this.total}`,
          ...this.$attrs,
          isSplit: false
        };
        arr.push(totalAva);
      }
      return arr;
    }
  }
};
</script>
