<script>
  export default {
    name: 'YxtTimeline',

    props: {
      reverse: {
        type: Boolean,
        default: false
      },
      /** 虚线 */
      dashed: {
        type: Boolean,
        default: false
      }
  
    },

    provide() {
      return {
        timeline: this
      };
    },

    render() {
      const reverse = this.reverse;
      const classes = {
        'yxt-timeline': true,
        'is-reverse': reverse
      };
      let slots = this.$slots.default || [];
      if (reverse) {
        slots = slots.reverse();
      }
      return (<ul class={ classes }>
        { slots }
      </ul>);
    }
  };
</script>
