var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "ul",
    {
      directives: [
        {
          name: "show",
          rawName: "v-show",
          value: _vm.visible,
          expression: "visible",
        },
      ],
      staticClass: "yxt-select-group__wrap",
    },
    [
      _c("li", { staticClass: "yxt-select-group__title" }, [
        _vm._v(_vm._s(_vm.label)),
      ]),
      _c("li", [
        _c("ul", { staticClass: "yxt-select-group" }, [_vm._t("default")], 2),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }