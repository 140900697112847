var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return !_vm.ranged
    ? _c(
        "yxt-input",
        _vm._b(
          {
            directives: [
              {
                name: "clickoutside",
                rawName: "v-clickoutside",
                value: _vm.handleClose,
                expression: "handleClose",
              },
            ],
            ref: "reference",
            staticClass: "yxt-date-editor",
            class: [
              "yxt-date-editor--" + _vm.type,
              { "yxt-date-editor--placeholder": _vm.isPlaceholder },
            ],
            attrs: {
              readonly:
                !_vm.editable ||
                _vm.readonly ||
                _vm.type === "dates" ||
                _vm.type === "week",
              disabled: _vm.pickerDisabled,
              size: _vm.pickerSize,
              name: _vm.name,
              placeholder: _vm.placeholder,
              value: _vm.displayValue,
              validateEvent: false,
            },
            on: {
              focus: _vm.handleFocus,
              input: _vm.handleInput,
              change: _vm.handleChange,
            },
            nativeOn: {
              keydown: function ($event) {
                return _vm.handleKeydown($event)
              },
              mouseenter: function ($event) {
                return _vm.handleMouseEnter($event)
              },
              mouseleave: function ($event) {
                _vm.showClose = false
              },
            },
          },
          "yxt-input",
          _vm.firstInputId,
          false
        ),
        [
          _c(
            "span",
            {
              staticClass: "yxt-date__suffix",
              attrs: { slot: "suffix" },
              slot: "suffix",
            },
            [
              _c("i", {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: !_vm.showClose,
                    expression: "!showClose",
                  },
                ],
                staticClass: "yxt-input__icon",
                class: _vm.triggerClass,
                on: { click: _vm.handleFocus },
              }),
              _vm.haveTrigger
                ? _c("yxt-svg", {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.showClose,
                        expression: "showClose",
                      },
                    ],
                    staticClass: "yxt-input__icon yxt-time__close hand",
                    attrs: {
                      "icon-class": "close",
                      width: "16px",
                      height: "16px",
                    },
                    nativeOn: {
                      click: function ($event) {
                        $event.stopPropagation()
                        return _vm.handleClickIcon($event)
                      },
                    },
                  })
                : _vm._e(),
            ],
            1
          ),
        ]
      )
    : _c(
        "div",
        {
          directives: [
            {
              name: "clickoutside",
              rawName: "v-clickoutside",
              value: _vm.handleClose,
              expression: "handleClose",
            },
          ],
          ref: "reference",
          staticClass: "yxt-date-editor yxt-range-editor yxt-input__inner",
          class: [
            "yxt-date-editor--" + _vm.type,
            _vm.pickerSize ? "yxt-range-editor--" + _vm.pickerSize : "",
            _vm.pickerDisabled ? "is-disabled" : "",
            _vm.pickerVisible ? "is-active" : "",
            _vm.isFocus ? "is-focus" : "",
          ],
          on: {
            click: function ($event) {
              _vm.handleFocus(true)
            },
            mouseenter: _vm.handleMouseEnter,
            mouseleave: function ($event) {
              _vm.showClose = false
            },
            keydown: _vm.handleKeydown,
          },
        },
        [
          _c(
            "input",
            _vm._b(
              {
                staticClass: "yxt-range-input",
                class: {
                  "yxt-date-editor--placeholder":
                    _vm.isPlaceholder && _vm.isStart,
                  "is-current": _vm.isStart,
                },
                attrs: {
                  autocomplete: "off",
                  placeholder: _vm.startPlaceholder,
                  disabled: _vm.pickerDisabled,
                  readonly: !_vm.editable || _vm.readonly,
                  name: _vm.name && _vm.name[0],
                },
                domProps: { value: _vm.displayValue && _vm.displayValue[0] },
                on: {
                  input: _vm.handleStartInput,
                  change: _vm.handleStartChange,
                  focus: function ($event) {
                    _vm.handleFocus(true)
                  },
                  click: function ($event) {
                    $event.stopPropagation()
                  },
                  blur: _vm.handleBlur,
                },
              },
              "input",
              _vm.firstInputId,
              false
            )
          ),
          _vm._t("range-separator", [
            _c("span", { staticClass: "yxt-range-separator" }, [_vm._v("~")]),
          ]),
          _c(
            "input",
            _vm._b(
              {
                staticClass: "yxt-range-input",
                class: {
                  "yxt-date-editor--placeholder":
                    _vm.isPlaceholder && !_vm.isStart,
                  "is-current": !_vm.isStart,
                },
                attrs: {
                  autocomplete: "off",
                  placeholder: _vm.endPlaceholder,
                  disabled: _vm.pickerDisabled,
                  readonly: !_vm.editable || _vm.readonly,
                  name: _vm.name && _vm.name[1],
                },
                domProps: { value: _vm.displayValue && _vm.displayValue[1] },
                on: {
                  input: _vm.handleEndInput,
                  change: _vm.handleEndChange,
                  focus: function ($event) {
                    _vm.handleFocus()
                  },
                  click: function ($event) {
                    $event.stopPropagation()
                  },
                  blur: _vm.handleBlur,
                },
              },
              "input",
              _vm.secondInputId,
              false
            )
          ),
          _c("span", { staticClass: "yxt-input__suffix" }, [
            _c(
              "span",
              { staticClass: "yxt-input__suffix-inner" },
              [
                _c("i", {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: !_vm.showClose,
                      expression: "!showClose",
                    },
                  ],
                  class: [
                    "yxt-input__icon",
                    "yxt-range__icon",
                    _vm.triggerClass,
                  ],
                }),
                _c("yxt-svg", {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.showClose,
                      expression: "showClose",
                    },
                  ],
                  staticClass: "yxt-input__icon yxt-time__close hand",
                  attrs: {
                    "icon-class": "close",
                    width: "16px",
                    height: "16px",
                  },
                  nativeOn: {
                    click: function ($event) {
                      $event.stopPropagation()
                      return _vm.handleClickIcon($event)
                    },
                  },
                }),
              ],
              1
            ),
          ]),
        ],
        2
      )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }