var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      class: [
        "yxtf-checkbox-group",
        { "yxtf-checkbox-group--row": this.direction === "row" },
      ],
      attrs: { role: "group", "aria-label": "checkbox-group" },
    },
    [_vm._t("default")],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }