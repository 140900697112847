<template>
  <yxt-sticky :animated='animated' ref="sticky" :keep-left='keepLeft' resizable @change="changeFixed" :offset="offset" v-if="fixed">
    <header
      :style="headerStyle"
      :class="headerClass"
      class="yxt-layout__header"
    >
      <slot></slot>
    </header>
  </yxt-sticky>
  <header
    :style="headerStyle"
    :class="headerClass"
    v-else
    class="yxt-layout__header"
  >
    <slot></slot>
  </header>
</template>
<script>
import bus, { setAnimation } from './bus';
export default {
  name: 'YxtLayoutHeader',
  props: {
    headerClass: {
      type: String,
      default: ''
    },
    headerStyle: {
      default: '',
      type: String
    },
    fixed: {
      type: Boolean,
      default: false
    },
    keepLeft: {
      default: true,
      type: Boolean
    },
    animated: {
      default: false,
      type: Boolean
    },
    offset: {
      type: Number,
      default: 0
    }
  },
  mounted() {
    bus.$on('changeSize', (time) => {
      if (this.$refs.sticky) {
        setAnimation(() => {
          this.$refs.sticky.place();
        }, time || 400);
      }
    });
  },
  methods: {
    changeFixed(isFixed) {
      this.$emit('change', isFixed);
    }
  },
  destroyed() {
    bus.$off();
  }
};
</script>
