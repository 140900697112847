<script>
  import TabBar from './tab-bar';
  import TabNavMore from './tab-nav-more';
  import { addResizeListener, removeResizeListener } from '@utils/utils/resize-event';
  import throttle from 'throttle-debounce/throttle';

  function noop() {}
  const firstUpperCase = str => {
    return str.toLowerCase().replace(/( |^)[a-z]/g, (L) => L.toUpperCase());
  };
  const THROTTLE_TIME = 300; // 设置节流常量，单位ms

  export default {
    name: 'TabNav',

    components: {
      TabBar,
      TabNavMore
    },

    inject: ['rootTabs'],

    props: {
      panes: Array,
      currentName: String,
      editable: Boolean,
      closable: Boolean,
      onTabClick: {
        type: Function,
        default: noop
      },
      onTabRemove: {
        type: Function,
        default: noop
      },
      type: String,
      paddingLeft: Number,
      stretch: Boolean
    },

    data() {
      return {
        scrollable: false,
        navOffset: 0,
        isFocus: false,
        focusable: true,
        showShadowRight: false,
        lockTransition: false,
        lastWheel: null
      };
    },

    computed: {
      navWrapStyle() {
        let res = {};
        if (!this.type || this.type === 'border-card') {
          if (this.paddingLeft && !isNaN(this.paddingLeft)) {
            res['padding-left'] = `${this.paddingLeft}px !important`;
          } else {
            res['padding-left'] = '24px !important';
          }
        }

        return res;
      },
      navStyle() {
        const dir = ['top', 'bottom'].indexOf(this.rootTabs.tabPosition) !== -1 ? 'X' : 'Y';
        const obj = {
          transform: `translate${dir}(-${this.navOffset}px)`
        };
        if (this.lockTransition) {
          obj.transition = 'none';
        }
        return obj;
      },
      sizeName() {
        return ['top', 'bottom'].indexOf(this.rootTabs.tabPosition) !== -1 ? 'width' : 'height';
      }
    },

    watch: {
      navOffset() {
        this.calcShowShadowRigth();
      }
    },

    methods: {
      scrollPrev() {
        const containerSize = this.$refs.navScroll[`offset${firstUpperCase(this.sizeName)}`];
        const currentOffset = this.navOffset;

        if (!currentOffset) return;

        let newOffset = currentOffset > containerSize
          ? currentOffset - containerSize
          : 0;

        this.navOffset = newOffset;
      },
      scrollNext() {
        const navSize = this.$refs.nav[`offset${firstUpperCase(this.sizeName)}`];
        const containerSize = this.$refs.navScroll[`offset${firstUpperCase(this.sizeName)}`];
        const currentOffset = this.navOffset;

        if (navSize - currentOffset <= containerSize) return;

        let newOffset = navSize - currentOffset > containerSize * 2
          ? currentOffset + containerSize
          : (navSize - containerSize);

        this.navOffset = newOffset;
      },
      scrollToActiveTab() {
        if (!this.scrollable) return;
        const nav = this.$refs.nav;
        const activeTab = this.$el.querySelector('.is-active');
        if (!activeTab) return;
        const navScroll = this.$refs.navScroll;
        const isHorizontal = ['top', 'bottom'].indexOf(this.rootTabs.tabPosition) !== -1;
        const activeTabBounding = activeTab.getBoundingClientRect();
        const navScrollBounding = navScroll.getBoundingClientRect();
        const maxOffset = isHorizontal
          ? nav.offsetWidth - navScrollBounding.width
          : nav.offsetHeight - navScrollBounding.height;
        const currentOffset = this.navOffset;
        let newOffset = currentOffset;

        if (isHorizontal) {
          if (activeTabBounding.left < navScrollBounding.left) {
            newOffset = currentOffset - (navScrollBounding.left - activeTabBounding.left);
          }
          if (activeTabBounding.right > navScrollBounding.right) {
            newOffset = currentOffset + activeTabBounding.right - navScrollBounding.right;
          }
        } else {
          if (activeTabBounding.top < navScrollBounding.top) {
            newOffset = currentOffset - (navScrollBounding.top - activeTabBounding.top);
          }
          if (activeTabBounding.bottom > navScrollBounding.bottom) {
            newOffset = currentOffset + (activeTabBounding.bottom - navScrollBounding.bottom);
          }
        }
        newOffset = Math.max(newOffset, 0);
        this.navOffset = Math.min(newOffset, maxOffset);
      },
      update() {
        if (!this.$refs.nav) return;
        const sizeName = this.sizeName;
        const navSize = this.$refs.nav && this.$refs.nav[`offset${firstUpperCase(sizeName)}`];
        const containerSize = this.$refs.navScroll && this.$refs.navScroll[`offset${firstUpperCase(sizeName)}`];
        const currentOffset = this.navOffset;

        // contanerSize加上最右边的pandding-right距离
        if ((containerSize + 32) < navSize) {
          const currentOffset = this.navOffset;
          this.scrollable = this.scrollable || {};
          this.scrollable.prev = currentOffset;
          this.scrollable.next = currentOffset + containerSize < navSize;
          if (navSize - currentOffset < containerSize) {
            this.navOffset = navSize - containerSize;
          }
          this.$refs.tabNavMore && this.$refs.tabNavMore.calcList(containerSize, currentOffset);
        } else {
          this.scrollable = false;
          if (currentOffset > 0) {
            this.navOffset = 0;
          }
        }
        this.calcShowShadowRigth();
      },
      changeTab(e) {
        const keyCode = e.keyCode;
        let nextIndex;
        let currentIndex, tabList;
        if ([37, 38, 39, 40].indexOf(keyCode) !== -1) { // 左右上下键更换tab
          tabList = e.currentTarget.querySelectorAll('[role=tab]');
          currentIndex = Array.prototype.indexOf.call(tabList, e.target);
        } else {
          return;
        }
        if (keyCode === 37 || keyCode === 38) { // left
          if (currentIndex === 0) { // first
            nextIndex = tabList.length - 1;
          } else {
            nextIndex = currentIndex - 1;
          }
        } else { // right
          if (currentIndex < tabList.length - 1) { // not last
            nextIndex = currentIndex + 1;
          } else {
            nextIndex = 0;
          }
        }
        tabList[nextIndex].focus(); // 改变焦点元素
        tabList[nextIndex].click(); // 选中下一个tab
        this.setFocus();
      },
      setFocus() {
        if (this.focusable) {
          this.isFocus = true;
        }
      },
      removeFocus() {
        this.isFocus = false;
      },
      visibilityChangeHandler() {
        const visibility = document.visibilityState;
        if (visibility === 'hidden') {
          this.focusable = false;
        } else if (visibility === 'visible') {
          setTimeout(() => {
            this.focusable = true;
          }, 50);
        }
      },
      windowBlurHandler() {
        this.focusable = false;
      },
      windowFocusHandler() {
        setTimeout(() => {
          this.focusable = true;
        }, 50);
      },
      // ant风格支持滚轮滑动
      navWheelHandler(e) {
        if (!this.scrollable) return;

        const { deltaMode, deltaX, deltaY } = e;
        let num = deltaX || deltaY;
        // firefox下deltaMode单位是行
        if (deltaMode > 0) {
          num = num * 33.3333;
        }

        e.preventDefault();

        this.lockTransition = true;
        this.lastWheel = Date.now();

        this.navOffset += num;
        if (this.navOffset < 0) {
          this.navOffset = 0;
        }

        setTimeout(() => {
          if (Date.now() - this.lastWheel > 100) {
            this.lockTransition = false;
          }
        }, 100);
      },
      calcShowShadowRigth() {
        const navSize = this.$refs.nav && this.$refs.nav[`offset${firstUpperCase(this.sizeName)}`];
        const containerSize = this.$refs.navScroll && this.$refs.navScroll[`offset${firstUpperCase(this.sizeName)}`];
        const currentOffset = this.navOffset;

        this.showShadowRight = ((navSize - currentOffset - 32) > containerSize);
      }
    },

    updated() {
      this.update();
    },

    render(h) {
      const {
        type,
        panes,
        editable,
        closable,
        stretch,
        onTabClick,
        onTabRemove,
        navStyle,
        navWrapStyle,
        scrollable,
        changeTab,
        setFocus,
        removeFocus,
        navOffset,
        showShadowRight
      } = this;
      const scrollBtn = null;

      const extraDom = this.$slots.extra ? <div class="yxt-tabs__nav-right" >
        {scrollable && <tab-nav-more ref="tabNavMore" havExtra={true} tabs={panes} />}
        {this.$slots.extra}
      </div> : scrollable ? <tab-nav-more ref="tabNavMore" tabs={panes} /> : '';
      const tabs = this._l(panes, (pane, index) => {
        let tabName = pane.name || pane.index || index;
        const closable = pane.isClosable || editable;

        pane.index = `${index}`;

        const btnClose = closable
          ? <span class="yxt-icon-close" on-click={(ev) => { onTabRemove(pane, ev); }}></span>
          : null;

        const tabLabelContent = pane.$slots.label || pane.label;
        const showTooltip = pane.showTooltip;
        const tabindex = pane.active ? 0 : -1;
        const maxWidthStyle = pane.$slots.label ? 'max-width:none' : `max-width:${pane.spanMaxWidth}px`;
        return (
          <div
            class={{
              'yxt-tabs__item': true,
              [`is-${ this.rootTabs.tabPosition }`]: true,
              'is-active': pane.active,
              'is-disabled': pane.disabled,
              'is-closable': closable,
              'fix_tabs_item': (closable && type === 'card'),
              'is-focus': this.isFocus
            }}
            id={`tab-${tabName}`}
            key={`tab-${tabName}`}
            aria-controls={`pane-${tabName}`}
            role="tab"
            aria-selected={ pane.active }
            ref="tabs"
            tabindex={tabindex}
            refInFor
            on-focus={ ()=> { setFocus(); }}
            on-blur ={ ()=> { removeFocus(); }}
            on-click={(ev) => { removeFocus(); onTabClick(pane, tabName, ev); }}
            on-keydown={(ev) => { if (closable && (ev.keyCode === 46 || ev.keyCode === 8)) { onTabRemove(pane, ev);} }}
          >
            {
              showTooltip
                ? <yxt-tooltip class="item" effect="dark" content={pane.$slots.label ? '' : tabLabelContent} placement="top">
                  {
                    pane.$slots.label ? <div slot="content">{tabLabelContent} </div> : ''
                  }
                  <span class='yxt-tabs__item-label' style={maxWidthStyle}>{tabLabelContent}</span>
                </yxt-tooltip>
                : <span class='yxt-tabs__item-label' style={maxWidthStyle}>{tabLabelContent}</span>
            }
            {btnClose}
          </div>
        );
      });
      return (
        <div style={this.$slots.extra && {'display': 'flex' } }>
          <div class={[
            'yxt-tabs__nav-wrap',
            this.$slots.extra && 'yxt-tabs__nav-wrap-extra',
            scrollable ? 'is-scrollable' : '',
            `is-${ this.rootTabs.tabPosition }`,
            navOffset !== 0 ? 'show-shadow-left' : '',
            showShadowRight ? 'show-shadow-right' : ''
          ]}
          style={navWrapStyle}
          >
            {scrollBtn}
            <div class={['yxt-tabs__nav-scroll']} ref="navScroll">
              <div
                class={['yxt-tabs__nav', `yxt-tabs__${ this.rootTabs.heightSize }`, `is-${ this.rootTabs.tabPosition }`, (stretch && ['top', 'bottom'].indexOf(this.rootTabs.tabPosition) !== -1) || type === 'special' ? 'is-stretch' : '']}
                ref="nav"
                style={navStyle}
                role="tablist"
                on-keydown={ changeTab }
              >
                {type !== 'card' && <tab-bar tabs={panes} editable={editable} closable={ closable} type={type}></tab-bar>}
                {tabs}
              </div>
            </div>
          </div>
          {extraDom}
        </div>
      );
    },

    mounted() {
      this.throttleUpdate = throttle(THROTTLE_TIME, this.update);
      addResizeListener(this.$el, this.throttleUpdate);
      document.addEventListener('visibilitychange', this.visibilityChangeHandler);
      window.addEventListener('blur', this.windowBlurHandler);
      window.addEventListener('focus', this.windowFocusHandler);
      setTimeout(() => {
        this.scrollToActiveTab();
      }, 0);
      this.$refs.navScroll.addEventListener('wheel', this.navWheelHandler);
      this.$nextTick(() => {
        this.calcShowShadowRigth();
      });
    },

    beforeDestroy() {
      if (this.$el && this.update) removeResizeListener(this.$el, this.throttleUpdate);
      document.removeEventListener('visibilitychange', this.visibilityChangeHandler);
      window.removeEventListener('blur', this.windowBlurHandler);
      window.removeEventListener('focus', this.windowFocusHandler);
      this.$refs.navScroll.removeEventListener('wheel', this.navWheelHandler);
    }
  };
</script>
