<template>
  <label
    class="yxt-radio-button"
    :class="[
      size ? 'yxt-radio-button--' + size : '',
      type ? 'yxt-radio-button--' + type : '',
      { 'is-active': value === label },
      { 'is-disabled': isDisabled },
      { 'is-focus': focus }
    ]"
    role="radio"
    :aria-checked="value === label"
    :aria-disabled="isDisabled"
    :tabindex="tabIndex"
    @keydown.space.stop.prevent="value = isDisabled ? value : label"
  >
    <input
      class="yxt-radio-button__orig-radio"
      :value="label"
      type="radio"
      v-model="value"
      :name="name"
      @change="handleChange"
      :disabled="isDisabled"
      tabindex="-1"
      @focus="focus = true"
      @blur="focus = false"
    >
    <span
      class="yxt-radio-button__inner"
      :style="value === label ? activeStyle : null"
      @keydown.stop>
      <slot></slot>
      <template v-if="!$slots.default">{{label}}</template>
    </span>
  </label>
</template>
<script>
  import Emitter from '@utils/mixins/emitter';

  export default {
    name: 'YxtRadioButton',

    mixins: [Emitter],

    inject: {
      yxtform: {
        default: ''
      },
      yxtformItem: {
        default: ''
      }
    },

    props: {
      label: {
        desc: 'Radio的value'
      },
      disabled: {
        type: Boolean,
        desc: '是否禁用'
      },
      name: {
        type: String,
        desc: '原生name属性'
      }
    },
    data() {
      return {
        focus: false
      };
    },
    computed: {
      value: {
        get() {
          return this._radioGroup.value;
        },
        set(value) {
          this._radioGroup.$emit('input', value);
        }
      },
      _radioGroup() {
        let parent = this.$parent;
        while (parent) {
          if (parent.$options.componentName !== 'YxtRadioGroup') {
            parent = parent.$parent;
          } else {
            return parent;
          }
        }
        return false;
      },
      activeStyle() {
        return {
          backgroundColor: this._radioGroup.fill || '',
          borderColor: this._radioGroup.fill || '',
          boxShadow: this._radioGroup.fill ? `-1px 0 0 0 ${this._radioGroup.fill}` : '',
          color: this._radioGroup.textColor || ''
        };
      },
      _yxtformItemSize() {
        return (this.yxtformItem || {}).yxtformItemSize;
      },
      size() {
        return this._radioGroup.radioGroupSize || this._yxtformItemSize || (this.$ELEMENT || {}).size;
      },
      type() {
        return this._radioGroup.radioGroupType;
      },
      isDisabled() {
        return this.disabled || this._radioGroup.disabled || (this.yxtform || {}).disabled;
      },
      tabIndex() {
        return (this.isDisabled || (this._radioGroup && this.value !== this.label)) ? -1 : 0;
      }
    },

    methods: {
      handleChange() {
        this.$nextTick(() => {
          this.dispatch('YxtRadioGroup', 'handleChange', this.value);
        });
      }
    }
  };
</script>
