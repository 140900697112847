<script>
import Clickoutside from '@utils/utils/clickoutside';
import Emitter from '@utils/mixins/emitter';
import Migrating from '@utils/mixins/migrating';
import YxtButton from '@backstage/button';
import YxtButtonGroup from '@backstage/button-group';
import { generateId } from '@utils/utils/util';

export default {
  name: 'YxtDropdown',

  componentName: 'YxtDropdown',

  mixins: [Emitter, Migrating],

  directives: { Clickoutside },

  components: {
    YxtButton,
    YxtButtonGroup
  },

  provide() {
    return {
      dropdown: this
    };
  },

  props: {
    trigger: {
      type: String,
      default: 'hover'
    },
    // type: String,
    // size: {
    //   type: String,
    //   default: ''
    // },
    customElm: {
      type: Boolean,
      default: false
    },
    hideOnClick: {
      type: Boolean,
      default: true
    },
    placement: {
      type: String,
      default: 'bottom-start'
    },
    visibleArrow: {
      default: true
    },
    showTimeout: {
      type: Number,
      default: 150
    },
    hideTimeout: {
      type: Number,
      default: 150
    },
    tabindex: {
      type: Number,
      default: 0
    },
    disabled: {
      type: Boolean,
      default: false
    },
    loading: {
      type: Boolean,
      default: false
    }
  },

  data() {
    return {
      timeout: null,
      visible: false,
      triggerElm: null,
      menuItems: null,
      menuItemsArray: null,
      dropdownElm: null,
      focusing: false,
      listId: `dropdown-menu-${generateId()}`
    };
  },

  // computed: {
  //   dropdownSize() {
  //     return this.size || (this.$ELEMENT || {}).size;
  //   }
  // },

  mounted() {
    this.$on('menu-item-click', this.handleMenuItemClick);
    this.loading && this.broadcast('YxtDropdownMenu', 'loading', this.loading);
  },

  watch: {
    visible(val) {
      this.broadcast('YxtDropdownMenu', 'visible', val);
      this.$emit('visible-change', val);
    },
    loading(val) {
      this.broadcast('YxtDropdownMenu', 'loading', val);
    },
    focusing(val) {
      const selfDefine = this.$el.querySelector('.yxt-dropdown-selfdefine');
      if (selfDefine) {
        const type = typeof selfDefine.className;
        if (type !== 'string') {
          return false;
        }
        // 自定义
        if (val) {
          selfDefine.className += ' focusing';
        } else {
          selfDefine.className = selfDefine.className.replace('focusing', '');
        }
      }
    }
  },

  methods: {
    getMigratingConfig() {
      return {
        props: {
          'menu-align': 'menu-align is renamed to placement.'
        }
      };
    },
    show() {
      if (this.disabled) return;
      clearTimeout(this.timeout);
      this.timeout = setTimeout(
        () => {
          this.visible = true;
        },
        this.trigger === 'click' ? 0 : this.showTimeout
      );
    },
    hide() {
      if (this.disabled) return;
      this.removeTabindex();
      if (this.tabindex >= 0) {
        this.resetTabindex(this.triggerElm);
      }
      clearTimeout(this.timeout);
      this.timeout = setTimeout(
        () => {
          this.visible = false;
        },
        this.trigger === 'click' ? 0 : this.hideTimeout
      );
    },
    handleClick() {
      if (this.disabled) return;
      if (this.visible) {
        this.hide();
      } else {
        this.show();
      }
    },
    handleTriggerKeyDown(ev) {
      const keyCode = ev.keyCode;
      if ([38, 40].indexOf(keyCode) > -1) {
        // up/down
        this.removeTabindex();
        this.resetTabindex(this.menuItems[0]);
        this.menuItems[0].focus();
        ev.preventDefault();
        ev.stopPropagation();
      } else if (keyCode === 13) {
        // space enter选中
        this.handleClick();
      } else if ([9, 27].indexOf(keyCode) > -1) {
        // tab || esc
        this.hide();
      }
    },
    handleItemKeyDown(ev) {
      const keyCode = ev.keyCode;
      const target = ev.target;
      const currentIndex = this.menuItemsArray.indexOf(target);
      const max = this.menuItemsArray.length - 1;
      let nextIndex;
      if ([38, 40].indexOf(keyCode) > -1) {
        // up/down
        if (keyCode === 38) {
          // up
          nextIndex = currentIndex !== 0 ? currentIndex - 1 : 0;
        } else {
          // down
          nextIndex = currentIndex < max ? currentIndex + 1 : max;
        }
        this.removeTabindex();
        this.resetTabindex(this.menuItems[nextIndex]);
        this.menuItems[nextIndex].focus();
        ev.preventDefault();
        ev.stopPropagation();
      } else if (keyCode === 13) {
        // enter选中
        this.triggerElmFocus();
        target.click();
        if (this.hideOnClick) {
          // click关闭
          this.visible = false;
        }
      } else if ([9, 27].indexOf(keyCode) > -1) {
        // tab // esc
        this.hide();
        this.triggerElmFocus();
      }
    },
    resetTabindex(ele) {
      // 下次tab时组件聚焦元素
      this.removeTabindex();
      ele.setAttribute('tabindex', '0'); // 下次期望的聚焦元素
    },
    removeTabindex() {
      this.triggerElm.setAttribute('tabindex', '-1');
      this.menuItemsArray.forEach((item) => {
        item.setAttribute('tabindex', '-1');
      });
    },
    initAria() {
      this.dropdownElm.setAttribute('id', this.listId);
      this.triggerElm.setAttribute('aria-haspopup', 'list');
      this.triggerElm.setAttribute('aria-controls', this.listId);

      if (this.customElm) {
        // 自定义
        this.triggerElm.setAttribute('role', 'button');
        this.triggerElm.setAttribute('tabindex', this.tabindex);
        this.triggerElm.setAttribute(
          'class',
          (this.triggerElm.getAttribute('class') || '') +
            ' yxt-dropdown-selfdefine'
        ); // 控制
      }
    },
    initEvent() {
      let {
        trigger,
        show,
        hide,
        handleClick,
        customElm,
        handleTriggerKeyDown,
        handleItemKeyDown
      } = this;
      this.triggerElm = !customElm
        ? this.$refs.trigger
        : this.$slots.default[0].elm;

      let dropdownElm = this.dropdownElm;

      this.triggerElm.addEventListener('keydown', handleTriggerKeyDown); // triggerElm keydown
      dropdownElm.addEventListener('keydown', handleItemKeyDown, true); // item keydown
      // 控制自定义元素的样式
      if (customElm) {
        this.triggerElm.addEventListener('focus', () => {
          this.focusing = true;
        });
        this.triggerElm.addEventListener('blur', () => {
          this.focusing = false;
        });
        this.triggerElm.addEventListener('click', () => {
          this.focusing = false;
        });
      }
      if (trigger === 'hover') {
        this.triggerElm.addEventListener('mouseenter', show);
        this.triggerElm.addEventListener('mouseleave', hide);
        dropdownElm.addEventListener('mouseenter', show);
        dropdownElm.addEventListener('mouseleave', hide);
      } else if (trigger === 'click') {
        this.triggerElm.addEventListener('click', handleClick);
      }
    },
    handleMenuItemClick(command, instance) {
      if (this.hideOnClick) {
        this.visible = false;
      }
      this.$emit('command', command, instance);
    },
    triggerElmFocus() {
      this.triggerElm.focus && this.triggerElm.focus();
    },
    initDomOperation() {
      this.dropdownElm = this.popperElm;
      this.menuItems = this.dropdownElm.querySelectorAll("[tabindex='-1']");
      this.menuItemsArray = [].slice.call(this.menuItems);

      this.initEvent();
      this.initAria();
    }
  },

  render(h) {
    // let { hide, splitButton, type, dropdownSize } = this;
    let { hide, customElm, disabled } = this;

    // let classArrow = this.visible ? 'yxt-dropdown__icon yxt-icon-arrow-up' : 'yxt-dropdown__icon yxt-icon-arrow-down';
    let classArrow = this.visible
      ? 'yxt-icon--right yxt-icon-arrow-up'
      : 'yxt-icon--right yxt-icon-arrow-down';
    let classLink = this.disabled ? 'yxt-dropdown-link yxt-dropdown-link-disable' : 'yxt-dropdown-link';
    // const handleMainButtonClick = (event) => {
    //   this.$emit('click', event);
    //   hide();
    // };

    let text =
      !customElm && this.$slots.default[0].text
        ? (text = this.$slots.default[0].text.replace(/^\s*|\s*$/g, ''))
        : this.$slots.default;

    let triggerElm = customElm ? (
      this.$slots.default
    ) : (
      // : (<yxtf-button-group>
      //   <yxtf-button type={type} size={dropdownSize} nativeOn-click={handleMainButtonClick}>
      //     {this.$slots.default}
      //   </yxtf-button>
      //   <yxtf-button ref="trigger" type={type} size={dropdownSize} class="yxt-dropdown__caret-button">
      //     <i class={classArrow}></i>
      //   </yxtf-button>
      // </yxtf-button-group>);
      <span class={classLink} disabled={disabled} ref='trigger'>
        {text}
        <i class={classArrow} />
      </span>
    );

    if (customElm) {
      let vNode = null;
      if (triggerElm && triggerElm.length) {
        vNode = triggerElm[0];
      }
      const vnodeData = vNode.data || {};
      let { attrs = {} } = vnodeData;
      if (disabled && !attrs.disabled) {
        attrs.disabled = true;
        vnodeData.attrs = attrs;
        vNode.data = vnodeData;
      }
    }

    const menuElm = disabled ? null : this.$slots.dropdown;

    return (
      <div class='yxt-dropdown' v-clickoutside={hide} aria-disabled={disabled}>
        {triggerElm}
        {menuElm}
      </div>
    );
  }
};
</script>
