var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      class: [
        _vm.isTextarea ? "yxtf-textarea" : "yxtf-input",
        _vm.inputSize ? "yxtf-input--" + _vm.inputSize : "",
        {
          "is-ie": _vm.isIE,
          "is-ie-focus": _vm.isIE && _vm.focused,
          "is-search": _vm.searchable,
          "is-limit": _vm.isWordLimitVisible,
          "is-limit--big": _vm.isWordLimitVisible && _vm.upperLimit >= 100,
          "is-disabled": _vm.inputDisabled,
          "is-error": _vm.inputError,
          "is-exceed": _vm.inputExceed,
          "yxtf-input-group": _vm.$slots.prepend || _vm.$slots.append,
          "yxtf-input-group--append": _vm.$slots.append,
          "yxtf-input-group--prepend": _vm.$slots.prepend,
          "yxtf-input--prefix": _vm.$slots.prefix || _vm.prefixIcon,
          "yxtf-input--suffix":
            _vm.$slots.suffix ||
            _vm.suffixIcon ||
            _vm.clearable ||
            _vm.showPassword,
        },
      ],
      on: {
        mouseenter: function ($event) {
          _vm.hovering = true
        },
        mouseleave: function ($event) {
          _vm.hovering = false
        },
      },
    },
    [
      !_vm.isTextarea
        ? [
            _vm.$slots.prepend
              ? _c(
                  "div",
                  { staticClass: "yxtf-input-group__prepend" },
                  [_vm._t("prepend")],
                  2
                )
              : _vm._e(),
            _c(
              "input",
              _vm._b(
                {
                  ref: "input",
                  staticClass: "yxtf-input__inner",
                  class: [
                    "yxtf-input__inner--" + _vm.styleType,
                    {
                      "yxtf-input__inner--primary": _vm.pendType === "primary",
                    },
                    {
                      "yxtf-input__inner--radusfull": _vm.radusType === "full",
                    },
                  ],
                  attrs: {
                    tabindex: _vm.tabindex,
                    type: _vm.showPassword
                      ? _vm.passwordVisible
                        ? "text"
                        : "password"
                      : _vm.type,
                    disabled: _vm.inputDisabled,
                    readonly: _vm.readonly,
                    autocomplete: _vm.autoComplete || _vm.autocomplete,
                    "aria-label": _vm.label,
                  },
                  on: {
                    compositionstart: _vm.handleCompositionStart,
                    compositionupdate: _vm.handleCompositionUpdate,
                    compositionend: _vm.handleCompositionEnd,
                    input: _vm.handleInput,
                    focus: _vm.handleFocus,
                    blur: _vm.handleBlur,
                    change: _vm.handleChange,
                    keyup: function ($event) {
                      if (
                        !("button" in $event) &&
                        _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                      ) {
                        return null
                      }
                      return _vm.search($event)
                    },
                  },
                },
                "input",
                _vm.$attrs,
                false
              )
            ),
            _vm.$slots.prefix || _vm.prefixIcon
              ? _c(
                  "span",
                  { staticClass: "yxtf-input__prefix" },
                  [
                    _vm._t("prefix"),
                    _vm.prefixIcon
                      ? _c("i", {
                          staticClass: "yxtf-input__icon",
                          class: _vm.prefixIcon,
                        })
                      : _vm._e(),
                  ],
                  2
                )
              : _vm._e(),
            _c(
              "span",
              {
                staticClass: "yxtf-input__suffix",
                class: { "yxtf-input__suffix--inputed": this.value },
              },
              [
                _c(
                  "span",
                  { staticClass: "yxtf-input__suffix-inner" },
                  [
                    _vm.showClear
                      ? _c("i", {
                          staticClass:
                            "yxtf-input__icon yxtf-icon-error yxtf-input__clear",
                          on: {
                            mousedown: function ($event) {
                              $event.preventDefault()
                            },
                            click: _vm.clear,
                          },
                        })
                      : _vm._e(),
                    !_vm.showClear ||
                    !_vm.showPwdVisible ||
                    !_vm.isWordLimitVisible
                      ? [
                          _vm._t("suffix"),
                          _vm.suffixIcon
                            ? _c("i", {
                                staticClass: "yxtf-input__icon",
                                class: _vm.suffixIcon,
                              })
                            : _vm._e(),
                          _vm.searchable
                            ? _c(
                                "div",
                                {
                                  staticClass:
                                    "yxtf-input__search hover-primary-6",
                                  on: { click: _vm.search },
                                },
                                [
                                  _c("yxt-svg", {
                                    attrs: {
                                      slot: "suffix",
                                      "icon-class": "search",
                                    },
                                    slot: "suffix",
                                  }),
                                ],
                                1
                              )
                            : _vm._e(),
                        ]
                      : _vm._e(),
                    _vm.showPwdVisible
                      ? _c(
                          "i",
                          {
                            staticClass:
                              "yxt-input__icon yxt-input__pwd yxt-input__clear",
                            on: { click: _vm.handlePasswordVisible },
                          },
                          [
                            _c("yxt-svg", {
                              attrs: {
                                width: "16px",
                                height: "16px",
                                "icon-class": _vm.viewIcon,
                              },
                            }),
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm.isWordLimitVisible
                      ? _c("span", { staticClass: "yxtf-input__count" }, [
                          _c(
                            "span",
                            { staticClass: "yxtf-input__count-inner" },
                            [
                              _vm._t(
                                "textlimit",
                                [
                                  _vm._v(_vm._s(_vm.textLength)),
                                  _c(
                                    "span",
                                    { staticClass: "yxtf-input__count-limit" },
                                    [_vm._v("/" + _vm._s(_vm.upperLimit))]
                                  ),
                                ],
                                {
                                  textLength: _vm.textLength,
                                  upperLimit: _vm.upperLimit,
                                }
                              ),
                            ],
                            2
                          ),
                        ])
                      : _vm._e(),
                  ],
                  2
                ),
                _vm.validateState
                  ? _c("i", {
                      staticClass: "yxtf-input__icon",
                      class: ["yxtf-input__validateIcon", _vm.validateIcon],
                    })
                  : _vm._e(),
              ]
            ),
            _vm.$slots.append
              ? _c(
                  "div",
                  {
                    staticClass: "yxtf-input-group__append",
                    class: [
                      "yxtf-input-group__append--" + _vm.pendType,
                      "yxtf-input-group__append--" + _vm.inputSize,
                    ],
                  },
                  [
                    _c(
                      "div",
                      { staticClass: "yxtf-input-group__append--inner" },
                      [_vm._t("append")],
                      2
                    ),
                  ]
                )
              : _vm._e(),
          ]
        : [
            _c(
              "textarea",
              _vm._b(
                {
                  ref: "textarea",
                  class: _vm.isIE
                    ? "yxtf-textarea__inner-ie"
                    : "yxtf-textarea__inner",
                  style: _vm.textareaStyle,
                  attrs: {
                    spellcheck: "false",
                    tabindex: _vm.tabindex,
                    disabled: _vm.inputDisabled,
                    readonly: _vm.readonly,
                    autocomplete: _vm.autoComplete || _vm.autocomplete,
                    "aria-label": _vm.label,
                  },
                  on: {
                    compositionstart: _vm.handleCompositionStart,
                    compositionupdate: _vm.handleCompositionUpdate,
                    compositionend: _vm.handleCompositionEnd,
                    input: _vm.handleInput,
                    focus: _vm.handleFocus,
                    blur: _vm.handleBlur,
                    change: _vm.handleChange,
                    keydown: _vm.handleKeyDown,
                  },
                },
                "textarea",
                _vm.$attrs,
                false
              )
            ),
            _vm.isWordLimitVisible
              ? _c(
                  "div",
                  { staticClass: "yxtf-input__count" },
                  [
                    _vm._t(
                      "textlimit",
                      [
                        _vm._v(_vm._s(_vm.textLength)),
                        _c("span", { staticClass: "yxtf-input__limit" }, [
                          _vm._v("/" + _vm._s(_vm.upperLimit)),
                        ]),
                      ],
                      { textLength: _vm.textLength, upperLimit: _vm.upperLimit }
                    ),
                  ],
                  2
                )
              : _vm._e(),
          ],
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }