var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.root
    ? _c("div", { staticClass: "yxt-layout__root", style: _vm.layoutStyle }, [
        _c(
          "div",
          {
            staticClass: "yxt-layout",
            class: _vm.layoutClass,
            style: _vm.layoutStyle,
          },
          [_vm._t("default")],
          2
        ),
      ])
    : _c(
        "div",
        {
          staticClass: "yxt-layout",
          class: _vm.layoutClass,
          style: _vm.layoutStyle,
        },
        [_vm._t("default")],
        2
      )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }