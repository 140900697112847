var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "span",
    [
      _c(
        "transition",
        {
          attrs: { name: _vm.transition },
          on: {
            "after-enter": _vm.handleAfterEnter,
            "after-leave": _vm.handleAfterLeave,
          },
        },
        [
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: !_vm.disabled && _vm.showPopper && !_vm.hidePopover,
                  expression: "!disabled && showPopper && !hidePopover",
                },
              ],
              ref: "popper",
              staticClass: "yxtf-popover yxtf-popper",
              class: [
                _vm.popperClass,
                _vm.paddingSmall && "yxtf-popover--plain",
                _vm.hasIcon && "yxtf-popover--icon",
                _vm.effect && "is-ellipsis",
              ],
              style: _vm.mainStyle,
              attrs: {
                role: "tooltip",
                id: _vm.tooltipId,
                "aria-hidden":
                  _vm.disabled || !_vm.showPopper ? "true" : "false",
              },
            },
            [
              _vm.title
                ? _c("div", {
                    staticClass: "yxtf-popover__title",
                    domProps: { textContent: _vm._s(_vm.title) },
                  })
                : _vm._e(),
              _vm.showScroll
                ? _c(
                    "yxt-scrollbar",
                    { ref: "scrollbar", attrs: { warpClass: ["custom-wrap"] } },
                    [
                      _c(
                        "div",
                        { style: _vm.contentStyle },
                        [
                          _vm._t("default", [
                            _c("div", [_vm._v(_vm._s(_vm.content))]),
                          ]),
                        ],
                        2
                      ),
                    ]
                  )
                : _vm._t("default", [_c("div", [_vm._v(_vm._s(_vm.content))])]),
            ],
            2
          ),
        ]
      ),
      _vm._t("reference"),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }