<template>
  <div
    class="yxt-tab-pane"
    v-if="(!lazy || loaded) || active"
    v-show="active"
    role="tabpanel"
    :aria-hidden="!active"
    :id="`pane-${paneName}`"
    :aria-labelledby="`tab-${paneName}`"
  >
    <slot></slot>
  </div>
</template>
<script>
  import { getParentByName } from '@utils/utils/util';
  const parentName = 'YxtTabs';
  export default {
    name: 'YxtTabPane',

    componentName: 'YxtTabPane',
    props: {
      label: String,
      labelContent: Function,
      name: String,
      maxWidth: {
        type: Number,
        default: 192
      },
      closable: Boolean,
      disabled: Boolean,
      lazy: Boolean,
      showTooltip: {
        type: Boolean,
        default: false
      }
    },

    data() {
      return {
        index: null,
        loaded: false
      };
    },

    computed: {
      isClosable() {
        return this.closable || getParentByName(this, parentName).closable;
      },
      active() {
        const active = getParentByName(this, parentName).currentName === (this.name || this.index);
        if (active) {
          this.loaded = true;
        }
        return active;
      },
      paneName() {
        return this.name || this.index;
      },
      spanMaxWidth() {
        return this.maxWidth - 48;
      }
    },

    updated() {
      getParentByName(this, parentName).$emit('tab-nav-update');
    }
  };
</script>
