var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      ref: "button",
      staticClass: "yxt-slider__button-wrapper",
      class: { hover: _vm.hovering, dragging: _vm.dragging },
      style: _vm.wrapperStyle,
      attrs: { tabindex: "0" },
      on: {
        mouseenter: _vm.handleMouseEnter,
        mouseleave: _vm.handleMouseLeave,
        mousedown: _vm.onButtonDown,
        touchstart: _vm.onButtonDown,
        focus: _vm.handleMouseEnter,
        blur: _vm.handleMouseLeave,
        keydown: [
          function ($event) {
            if (
              !("button" in $event) &&
              _vm._k($event.keyCode, "left", 37, $event.key, [
                "Left",
                "ArrowLeft",
              ])
            ) {
              return null
            }
            if ("button" in $event && $event.button !== 0) {
              return null
            }
            return _vm.onLeftKeyDown($event)
          },
          function ($event) {
            if (
              !("button" in $event) &&
              _vm._k($event.keyCode, "right", 39, $event.key, [
                "Right",
                "ArrowRight",
              ])
            ) {
              return null
            }
            if ("button" in $event && $event.button !== 2) {
              return null
            }
            return _vm.onRightKeyDown($event)
          },
          function ($event) {
            if (
              !("button" in $event) &&
              _vm._k($event.keyCode, "down", 40, $event.key, [
                "Down",
                "ArrowDown",
              ])
            ) {
              return null
            }
            $event.preventDefault()
            return _vm.onLeftKeyDown($event)
          },
          function ($event) {
            if (
              !("button" in $event) &&
              _vm._k($event.keyCode, "up", 38, $event.key, ["Up", "ArrowUp"])
            ) {
              return null
            }
            $event.preventDefault()
            return _vm.onRightKeyDown($event)
          },
        ],
      },
    },
    [
      _c(
        "yxt-tooltip",
        {
          ref: "tooltip",
          attrs: {
            placement: "top",
            "popper-class": _vm.tooltipClass,
            disabled: !_vm.showTooltip,
          },
        },
        [
          _c("span", { attrs: { slot: "content" }, slot: "content" }, [
            _vm._v(_vm._s(_vm.formatValue)),
          ]),
          _c("div", {
            staticClass: "yxt-slider__button",
            class: { hover: _vm.hovering, dragging: _vm.dragging },
          }),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }