var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("yxt-row", { staticClass: "yxt-empty" }, [
    _c(
      "div",
      [
        _vm.type !== "text" && _vm.type !== "custom"
          ? _vm._t("image", [
              _c(
                "div",
                { staticClass: "yxt-empty__svg" },
                [_c("yxt-svg", { attrs: { "icon-class": _vm.iconclass } })],
                1
              ),
            ])
          : _vm._e(),
        _c(
          "div",
          { staticClass: "color-gray-7" },
          [
            _vm._t("text", [
              _c(
                "div",
                {
                  class: [
                    "yxt-empty__text",
                    _vm.type == "custom" && "yxt-empty__title",
                  ],
                },
                [_vm._v(_vm._s(_vm.fillText))]
              ),
            ]),
          ],
          2
        ),
        _vm._t("default"),
      ],
      2
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }