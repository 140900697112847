<template>
  <i :class="'yxt-icon-' + name"></i>
</template>

<script>
  export default {
    name: 'YxtfIcon',

    props: {
      name: String
    }
  };
</script>
