var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "transition",
    {
      attrs: { name: "yxt-zoom-in-top" },
      on: { "after-leave": _vm.doDestroy },
    },
    [
      _vm.scrollable
        ? _c(
            "ul",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.showPopper,
                  expression: "showPopper",
                },
              ],
              staticClass: "yxt-dropdown-menu",
              class: [_vm.size && "yxt-dropdown-menu--" + _vm.size],
            },
            [
              _c(
                "div",
                {
                  directives: [
                    {
                      name: "loading",
                      rawName: "v-loading",
                      value: _vm.loading,
                      expression: "loading",
                    },
                  ],
                  staticClass: "yxt-popper",
                  class: [
                    _vm.$slots.custom && "is-custom",
                    _vm.loading &&
                      !Object.keys(this.$slots).length &&
                      "is-loading",
                    _vm.$slots["fixed-footer"] && "is-suffix",
                    _vm.$slots["fixed-header"] && "is-prefix",
                  ],
                  style: _vm.wrapStyle,
                  attrs: { "yxt-loading-size": "small" },
                },
                [
                  _vm.$slots["fixed-header"]
                    ? _c(
                        "div",
                        { staticClass: "yxt-dropdown-menu__prefix" },
                        [_vm._t("fixed-header")],
                        2
                      )
                    : _vm._e(),
                  _c(
                    "yxt-scrollbar",
                    {
                      ref: "bar",
                      staticClass: "yxt-dropdown-menu__wrap",
                      attrs: { "fit-height": true },
                    },
                    [
                      _vm._t("default"),
                      !Object.keys(this.$slots).length && !_vm.loading
                        ? _c(
                            "div",
                            { staticClass: "yxt-dropdown-menu__no_data" },
                            [
                              _vm._v(
                                "\n          " +
                                  _vm._s(_vm.t("pc_comp_not_available")) +
                                  "\n        "
                              ),
                            ]
                          )
                        : _vm._e(),
                    ],
                    2
                  ),
                  _vm.$slots.custom
                    ? _c(
                        "div",
                        { staticClass: "yxt-dropdown-custom" },
                        [_vm._t("custom")],
                        2
                      )
                    : _vm._e(),
                  _vm.$slots["fixed-footer"]
                    ? _c(
                        "div",
                        { staticClass: "yxt-dropdown-menu__suffix" },
                        [_vm._t("fixed-footer")],
                        2
                      )
                    : _vm._e(),
                ],
                1
              ),
            ]
          )
        : _c(
            "ul",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.showPopper,
                  expression: "showPopper",
                },
              ],
              staticClass: "yxt-dropdown-menu yxt-popper",
              class: [_vm.size && "yxt-dropdown-menu--" + _vm.size],
            },
            [_vm._t("default")],
            2
          ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }