var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("transition", { attrs: { name: "yxt-fade-in" } }, [
    _vm.visible
      ? _c(
          "div",
          {
            staticClass: "yxt-backtop",
            style: {
              right: _vm.styleRight,
              bottom: _vm.styleBottom,
            },
            on: {
              click: function ($event) {
                $event.stopPropagation()
                return _vm.handleClick($event)
              },
            },
          },
          [
            _vm._t("default", [
              _c("yxt-icon", { attrs: { name: "caret-top" } }),
            ]),
          ],
          2
        )
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }