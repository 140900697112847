var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "li",
    {
      directives: [
        {
          name: "show",
          rawName: "v-show",
          value: _vm.visible,
          expression: "visible",
        },
      ],
      staticClass: "yxt-select-dropdown__item",
      class: [
        _vm.itemSelectedClass,
        {
          selected: _vm.itemSelected,
          hover: _vm.isHover,
          "is-disabled": _vm.disabled || _vm.groupDisabled || _vm.limitReached,
        },
      ],
      on: {
        mouseenter: _vm.hoverItem,
        mouseout: _vm.mouseoutItem,
        mousemove: _vm.enableMouseEnter,
        click: function ($event) {
          $event.stopPropagation()
          return _vm.selectOptionClick($event)
        },
      },
    },
    [
      _vm.select.multipleStyle === "checkbox"
        ? _c("yxt-checkbox", {
            class: ["selected-multiple-checkbox"],
            attrs: { disabled: _vm.itemDisabled },
            nativeOn: {
              click: function ($event) {
                $event.preventDefault()
              },
            },
            model: {
              value: _vm.itemSelected,
              callback: function ($$v) {
                _vm.itemSelected = $$v
              },
              expression: "itemSelected",
            },
          })
        : _vm._e(),
      _vm._t("default", [_c("span", [_vm._v(_vm._s(_vm.currentLabel))])]),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }