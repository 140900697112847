<template>
  <transition name="yxt-zoom-in-top" @after-leave="$emit('dodestroy')">
    <div
      v-show="visible"
      class="yxt-picker-panel yxt-date-range-picker yxt-popper"
      :class="[{
        'has-sidebar': $slots.sidebar || shortcuts,
      }, popperClass]">
      <div class="yxt-picker-panel__body-wrapper">
        <slot name="sidebar" class="yxt-picker-panel__sidebar"></slot>
        <div class="yxt-picker-panel__sidebar" v-if="shortcuts">
          <button
            type="button"
            class="yxt-picker-panel__shortcut"
            v-for="(shortcut, key) in shortcuts"
            :key="key"
            @click="handleShortcutClick(shortcut)">{{shortcut.text}}</button>
        </div>
        <div class="yxt-picker-panel__body">
          <div class="yxt-picker-panel__content yxt-date-range-picker__content" >
            <div class="yxt-date-range-picker__header">
              <button
                type="button"
                @click="prevYear"
                class="yxt-picker-panel__icon-btn yxt-icon-d-arrow-left yxt-date-picker__left-side-btn"></button>
              <button
                type="button"
                @click="prevMonth"
                class="yxt-picker-panel__icon-btn yxt-icon-arrow-left yxt-date-picker__left-sub-btn"></button>
              <button
                type="button"
                @click="nextYear"
                class="yxt-picker-panel__icon-btn yxt-icon-d-arrow-right yxt-date-picker__right-side-btn"></button>
              <button
                type="button"
                @click="nextMonth"
                class="yxt-picker-panel__icon-btn yxt-icon-arrow-right yxt-date-picker__right-sub-btn"></button>
              <div>{{ label }}</div>
            </div>
            <date-table
              selection-mode="time-range"
              v-show="isStart"
              @dateHover="handleHover"
              :date="leftDate"
              :is-hover="isHover"
              :value="minDate"
              :default-value="defaultValue"
              :min-date="minDate"
              :max-date="maxDate"
              :disabled-date="disabledDate"
              :cell-class-name="cellClassName"
              @placeholder="handlePlaceholder"
              :first-day-of-week="firstDayOfWeek"
              @pick="handleRangePick">
            </date-table>
            <date-table
              selection-mode="time-range"
              v-show="!isStart"
              @dateHover="handleHover"
              @placeholder="handlePlaceholder"
              :is-hover="isHover"
              :date="rightDate"
              :value="maxDate"
              :default-value="defaultValue"
              :min-date="minDate"
              :max-date="maxDate"
              :disabled-date="disabledDate"
              :cell-class-name="cellClassName"
              :first-day-of-week="firstDayOfWeek"
              @pick="handleRangePick">
            </date-table>
          </div>
          <div class="yxt-date-picker__time-content">
            <div class="yxt-date-picker__time-header">{{ time | time }}</div>
            <div>
              <time-spinner
                @placeholder="handlePlaceholder"
                ref="spinner"
                :date="time"
                @change="handleTimePick"
                :show-seconds="false" />
            </div>
          </div>
        </div>
      </div>
      <div class="yxt-picker-panel__footer  yxt-date-picker-panel__footer">
        <yxt-button
          size="small"
          type="text2"
          class="yxt-picker-panel__link-btn"
          @click="handleCurrent">
          {{ t('pc_comp_datepicker_now') }}
        </yxt-button>
        <yxt-button
          type="primary"
          size="small"
          :disabled="confirmDisabled"
          class="yxt-picker-panel__link-btn"
          @click="handleConfirm">
          {{ t('pc_comp_datepicker_confirm') }}
        </yxt-button>
      </div>
    </div>
  </transition>
</template>

<script type="text/babel">
  import {
    formatDate,
    // parseDate,
    isDate,
    // modifyDate,
    modifyTime,
    // modifyWithTimeString,
    prevYear,
    nextYear,
    prevMonth,
    nextMonth,
    nextDate,
    extractDateFormat,
    extractTimeFormat
  } from '@utils/utils/date-util';
  import Clickoutside from '@utils/utils/clickoutside';
  import Locale from '@utils/mixins/locale';
  import TimePicker from './time';
  import DateTable from '../basic/date-table';
  import ElInput from '@backstage/input';
  import ElButton from '@backstage/button';
  import TimeSpinner from '../basic/time-spinner';

  const calcDefaultValue = (defaultValue) => {
    if (Array.isArray(defaultValue)) {
      return [new Date(defaultValue[0]), new Date(defaultValue[1])];
    } else if (defaultValue) {
      return [new Date(defaultValue), nextDate(new Date(defaultValue), 1)];
    } else {
      return [new Date(), nextDate(new Date(), 1)];
    }
  };

  export default {
    mixins: [Locale],

    directives: { Clickoutside },

    computed: {
      btnDisabled() {
        return !(this.minDate && this.maxDate && !this.selecting && this.isValidValue([this.minDate, this.maxDate]));
      },

      confirmDisabled() {
        if (this.isStart && !isDate(this.minDate)) {
          return true;
        } else if (!this.isStart && !isDate(this.maxDate)) {
          return true;
        }
        return false;
      },

      label() {
        const date = this.isStart ? this.leftDate : this.rightDate;
        return date.getFullYear() + ' ' + this.t('pc_comp_datepicker_year') + ' ' + this.t(`pc_comp_datepicker_month${ date.getMonth() + 1 }`);
      },

      leftYear() {
        return this.leftDate.getFullYear();
      },

      leftMonth() {
        return this.leftDate.getMonth();
      },

      leftMonthDate() {
        return this.leftDate.getDate();
      },

      rightYear() {
        return this.rightDate.getFullYear();
      },

      rightMonth() {
        return this.rightDate.getMonth();
      },

      rightMonthDate() {
        return this.rightDate.getDate();
      },

      minVisibleDate() {
        if (this.dateUserInput.min !== null) return this.dateUserInput.min;
        if (this.minDate) return formatDate(this.minDate, this.dateFormat);
        return '';
      },

      maxVisibleDate() {
        if (this.dateUserInput.max !== null) return this.dateUserInput.max;
        if (this.maxDate || this.minDate) return formatDate(this.maxDate || this.minDate, this.dateFormat);
        return '';
      },

      minVisibleTime() {
        if (this.timeUserInput.min !== null) return this.timeUserInput.min;
        if (this.minDate) return formatDate(this.minDate, this.timeFormat);
        return '';
      },

      maxVisibleTime() {
        if (this.timeUserInput.max !== null) return this.timeUserInput.max;
        if (this.maxDate || this.minDate) return formatDate(this.maxDate || this.minDate, this.timeFormat);
        return '';
      },

      timeFormat() {
        if (this.format) {
          return extractTimeFormat(this.format);
        } else {
          return 'HH:mm:ss';
        }
      },

      dateFormat() {
        if (this.format) {
          return extractDateFormat(this.format);
        } else {
          return 'yyyy-MM-dd';
        }
      },

      enableMonthArrow() {
        const nextMonth = (this.leftMonth + 1) % 12;
        const yearOffset = this.leftMonth + 1 >= 12 ? 1 : 0;
        return this.unlinkPanels && new Date(this.leftYear + yearOffset, nextMonth) < new Date(this.rightYear, this.rightMonth);
      },

      enableYearArrow() {
        return this.unlinkPanels && this.rightYear * 12 + this.rightMonth - (this.leftYear * 12 + this.leftMonth + 1) >= 12;
      }
    },

    data() {
      return {
        popperClass: '',
        value: [],
        isStart: true, // 是否选择开始时间
        time: new Date(),
        defaultValue: null,
        defaultTime: null,
        isHover: false,
        minDate: '',
        maxDate: '',
        leftDate: new Date(),
        rightDate: new Date(),
        rangeState: {
          endDate: null,
          selecting: false,
          row: null,
          column: null
        },
        shortcuts: '',
        visible: '',
        disabledDate: '',
        cellClassName: '',
        firstDayOfWeek: 7,
        minTimePickerVisible: false,
        maxTimePickerVisible: false,
        format: '',
        arrowControl: false,
        unlinkPanels: false,
        dateUserInput: {
          min: null,
          max: null
        },
        timeUserInput: {
          min: null,
          max: null
        }
      };
    },

    filters: {
      time(v) {
        if (!v) return '';
        return `${String(v.getHours()).padLeft(2, '0')}:${String(v.getMinutes()).padLeft(2, '0')}`;
      }
    },

    watch: {
      minDate(val) {
        if (this.isStart) {
          this.time = isDate(val) ? new Date(val) : '';
        }
      },

      maxDate(val) {
        if (!this.isStart) {
          this.time = isDate(val) ? new Date(val) : '';
        }
      },

      isStart: {
        immediate: true,
        handler(v) {
          if (v) {
            this.time = isDate(this.minDate) ? new Date(this.minDate) : '';
          } else {
            this.time = isDate(this.maxDate) ? new Date(this.maxDate) : '';
          }

          if (this.visible) {
            this.$nextTick(() => {
              this.$refs.spinner.adjustSpinners();
            });
          }

          this.$forceUpdate();
        }
      },

      value(newVal) {
        if (!newVal) {
          this.minDate = null;
          this.maxDate = null;
        } else if (Array.isArray(newVal)) {
          this.minDate = isDate(newVal[0]) ? new Date(newVal[0]) : null;
          this.maxDate = isDate(newVal[1]) ? new Date(newVal[1]) : null;
          if (this.minDate) {
            this.leftDate = this.minDate;
            if (this.unlinkPanels && this.maxDate) {
              const minDateYear = this.minDate.getFullYear();
              const minDateMonth = this.minDate.getMonth();
              const maxDateYear = this.maxDate.getFullYear();
              const maxDateMonth = this.maxDate.getMonth();
              this.rightDate = minDateYear === maxDateYear && minDateMonth === maxDateMonth
                ? nextMonth(this.maxDate)
                : this.maxDate;
            } else {
              this.rightDate = this.maxDate || new Date();
            }
          } else {
            this.leftDate = calcDefaultValue(this.defaultValue)[0];
            this.rightDate = this.maxDate || new Date();
          }
        }
      },

      defaultValue(val) {
        if (!Array.isArray(this.value)) {
          const [left, right] = calcDefaultValue(val);
          this.leftDate = left;
          this.rightDate = val && val[1] && this.unlinkPanels
            ? right
            : nextMonth(this.leftDate);
        }
      }
    },

    methods: {
      handleHover(isHover, date) {
        this.isHover = isHover;
      },
      handleCurrent() {
        if (this.isStart) {
          this.minDate = new Date();
        } else {
          this.maxDate = new Date();
        }
        this.judgmentSizeValue();
        this.handleConfirm();
      },
      // 判断大小值
      judgmentSizeValue() {
        if (isDate(this.minDate) && isDate(this.maxDate)) {
          if (this.minDate.getTime() > this.maxDate.getTime()) {
            const temp = this.minDate;
            this.minDate = this.maxDate;
            this.maxDate = temp;
          }
        }
      },
      handleClear() {
        this.minDate = null;
        this.maxDate = null;
        this.leftDate = calcDefaultValue(this.defaultValue)[0];
        this.rightDate = new Date(this.leftDate);
        this.$emit('pick', null);
      },

      handlePlaceholder(type, value) {
        if (value === null) {
          this.$emit('placeholder', null);
        } else {
          let date = new Date();
          if (this.isStart && this.minDate) {
            date = new Date(this.minDate);
          } else if (!this.isStart && this.maxDate) {
            date = new Date(this.maxDate);
          }
          if (type === 'time') {
            if (value[0] !== null) {
              date.setHours(value[0]);
            } else {
              date.setMinutes(value[1]);
            }
            this.$emit('placeholder', date);
          } else {
            date = new Date(value);
            if (this.time) {
              date.setHours(this.time.getHours());
              date.setMinutes(this.time.getMinutes());
            } else {
              // 如果存在最大最小时间中的一个，在this.time为null 的情况下，他们的小时分钟要一致
              if (!this.isStart && this.minDate) {
                date.setHours(this.minDate.getHours());
                date.setMinutes(this.minDate.getMinutes());
              } else if (this.isStart && this.maxDate) {
                date.setHours(this.maxDate.getHours());
                date.setMinutes(this.maxDate.getMinutes());
              }
            }
            this.$emit('placeholder', date);
          }
        }

      },

      handleTimePick(time) {
        if (!isDate(time)) return;
        this.time = time;
        this.handleRangePick(time, true);
      },

      // handleChangeRange(val) {
      //   this.minDate = val.minDate;
      //   this.maxDate = val.maxDate;
      //   this.rangeState = val.rangeState;
      // },

      handleRangePick(val, isTime = false) {
        if (!isTime) {
          // 恢复时分
          if (!isDate(this.time)) {
            this.time = new Date();
            this.$nextTick(() => {
              this.$refs.spinner.adjustSpinners();
            });
          }
          val.setHours(this.time.getHours());
          val.setMinutes(this.time.getMinutes());
          // 如果存在最大最小时间中的一个，在this.time为null 的情况下，他们的小时分钟要一致
          if (this.isStart && this.maxDate) {
            val.setHours(this.maxDate.getHours());
            val.setMinutes(this.maxDate.getMinutes());
          } else if (!this.isStart && this.minDate) {
            val.setHours(this.minDate.getHours());
            val.setMinutes(this.minDate.getMinutes());
          }
        }
        this[this.isStart ? 'minDate' : 'maxDate'] = val;
        if (isDate(this.minDate) && isDate(this.maxDate)) {
          if (this.minDate.getTime() > this.maxDate.getTime()) {
            const temp = this.minDate;
            this.minDate = this.maxDate;
            this.maxDate = temp;
          }
        }
        this.handleConfirm(false);
      },

      handleShortcutClick(shortcut) {
        if (shortcut.onClick) {
          shortcut.onClick(this);
        }
      },

      handleMinTimePick(value) {
        this.minDate = this.minDate || new Date();
        if (value) {
          this.minDate = modifyTime(this.minDate, value.getHours(), value.getMinutes(), value.getSeconds());
        }

        if (!this.maxDate || this.maxDate && this.maxDate.getTime() < this.minDate.getTime()) {
          this.maxDate = new Date(this.minDate);
        }
      },

      handleMinTimeClose() {
        this.minTimePickerVisible = false;
      },

      handleMaxTimePick(value) {
        if (this.maxDate && value) {
          this.maxDate = modifyTime(this.maxDate, value.getHours(), value.getMinutes(), value.getSeconds());
        }

        if (this.maxDate && this.minDate && this.minDate.getTime() > this.maxDate.getTime()) {
          this.minDate = new Date(this.maxDate);
        }
      },

      prevYear() {
        if (this.isStart) {
          this.leftDate = prevYear(this.leftDate);
        } else {
          this.rightDate = prevYear(this.rightDate);
        }
      },

      prevMonth() {
        if (this.isStart) {
          this.leftDate = prevMonth(this.leftDate);
        } else {
          this.rightDate = prevMonth(this.rightDate);
        }
      },

      nextYear() {
        if (this.isStart) {
          this.leftDate = nextYear(this.leftDate);
        } else {
          this.rightDate = nextYear(this.rightDate);
        }
      },

      nextMonth() {
        if (this.isStart) {
          this.leftDate = nextMonth(this.leftDate);
        } else {
          this.rightDate = nextMonth(this.rightDate);
        }
      },

      handleConfirm(btnTriggered = true) {
        if (btnTriggered) {
          // bug：修复input输入时间再点击确定按钮时，时间大小值展示异常的问题
          this.judgmentSizeValue();
        }
        this.$emit('pick', [this.minDate, this.maxDate], false, btnTriggered);
      },

      isValidValue(value) {
        return Array.isArray(value) &&
          value && value[0] && value[1] &&
          isDate(value[0]) && isDate(value[1]) &&
          value[0].getTime() <= value[1].getTime() && (
          typeof this.disabledDate === 'function'
            ? !this.disabledDate(value[0]) && !this.disabledDate(value[1])
            : true
        );
      },

      resetView() {
        // NOTE: this is a hack to reset {min, max}Date on picker open.
        // TODO: correct way of doing so is to refactor {min, max}Date to be dependent on value and internal selection state
        //       an alternative would be resetView whenever picker becomes visible, should also investigate date-panel's resetView
        if (this.minDate && this.maxDate == null) this.rangeState.selecting = false;
        this.minDate = this.value && isDate(this.value[0]) ? new Date(this.value[0]) : null;
        this.maxDate = this.value && isDate(this.value[1]) ? new Date(this.value[1]) : null;
      }
    },

    components: { TimePicker, DateTable, ElInput, ElButton, TimeSpinner }
  };
</script>
