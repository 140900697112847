<template>
  <div class="yxt-anchor__item">
    <a class="yxt-anchor__link" ref="linkRef" :class="{'is-active': isActive}" :href="href" @click="handleClick">
      <slot>{{ title }}</slot>
    </a>
      <div
      v-if="$slots['sub-link'] && direction === 'vertical'"
      class="yxt-anchor__list"
    >
      <slot name="sub-link" />
    </div>
  </div>
</template>

<script>
import { getParentByName } from '@utils/utils/util';
const ParentName = 'YxtAnchor';
export default {
  name: 'YxtAnchorLink',
  props: {
    title: {
      type: String,
      default: ''
    },
    href: {
      type: String,
      default: ''
    }
  },
  computed: {
    parent() {
      return getParentByName(this, ParentName);
    },
    isActive() {
      return this.parent.currentAnchor === this.href;
    }
  },
  created() {
    this.parent.updateItems();
  },
  unmounted() {
    this.parent.updateItems();
  },
  methods: {
    handleClick(e) {
      return this.parent.handleClick(e, this.href);
    }
  }
};
</script>
