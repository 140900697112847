var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "form",
    {
      staticClass: "yxt-form",
      class: [
        _vm.labelPosition ? "yxt-form--label-" + _vm.labelPosition : "",
        {
          "yxt-form--inline": _vm.inline,
          "yxt-form--norequire": !_vm.isNeedRequire,
          "yxt-form--notooltip": !_vm.isNeedTooltip,
        },
      ],
    },
    [_vm._t("default")],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }