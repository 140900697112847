var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.fixed
    ? _c(
        "yxt-sticky",
        {
          ref: "sticky",
          attrs: {
            animated: _vm.animated,
            "keep-left": _vm.keepLeft,
            resizable: "",
            offset: _vm.offset,
          },
          on: { change: _vm.changeFixed },
        },
        [
          _c(
            "header",
            {
              staticClass: "yxt-layout__header",
              class: _vm.headerClass,
              style: _vm.headerStyle,
            },
            [_vm._t("default")],
            2
          ),
        ]
      )
    : _c(
        "header",
        {
          staticClass: "yxt-layout__header",
          class: _vm.headerClass,
          style: _vm.headerStyle,
        },
        [_vm._t("default")],
        2
      )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }