var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "yxt-avatar-group" },
    [
      _vm._l(_vm.list, function (item, idx) {
        return [
          _c(
            "yxt-avatar",
            _vm._g(
              _vm._b(
                { key: idx, class: { "yxt-avatar-group--avatar": idx !== 0 } },
                "yxt-avatar",
                item,
                false
              ),
              _vm.$listeners
            )
          ),
        ]
      }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }