<template>
  <div
    class="yxt-autocomplete"
    v-clickoutside="close"
    aria-haspopup="listbox"
    role="combobox"
    :aria-expanded="suggestionVisible"
    :aria-owns="id"
  >
    <yxt-input
      ref="input"
      v-bind="[$props, $attrs]"
      @input="handleChange"
      @focus="handleFocus"
      @blur="handleBlur"
      @clear="handleClear"
      @keydown.up.native.prevent="highlight(highlightedIndex - 1)"
      @keydown.down.native.prevent="highlight(highlightedIndex + 1)"
      @keydown.enter.native="handleKeyEnter"
      @keydown.native.tab="close"
    >
      <template slot="prepend" v-if="$slots.prepend">
        <slot name="prepend"></slot>
      </template>
      <template slot="append" v-if="$slots.append">
        <slot name="append"></slot>
      </template>
      <template slot="prefix" v-if="$slots.prefix">
        <slot name="prefix"></slot>
      </template>
      <template slot="suffix" v-if="$slots.suffix">
        <slot name="suffix"></slot>
      </template>
    </yxt-input>
    <yxt-autocomplete-suggestions
      :class="[popperClass ? popperClass : '']"
      :popper-options="popperOptions"
      :append-to-body="popperAppendToBody"
      ref="suggestions"
      :placement="placement"
      :id="id">
      <li
        v-for="(item, index) in suggestions"
        :key="index"
        :class="{'highlighted': highlightedIndex === index}"
        @click="select(item)"
        :id="`${id}-item-${index}`"
        role="option"
        :aria-selected="highlightedIndex === index"
      >
        <slot :item="item">
          {{ item[valueKey] }}
        </slot>
      </li>
    </yxt-autocomplete-suggestions>
  </div>
</template>
<script>
  import debounce from 'throttle-debounce/debounce';
  import YxtInput from '@backstage/input';
  import Clickoutside from '@utils/utils/clickoutside';
  import YxtAutocompleteSuggestions from './autocomplete-suggestions.vue';
  import Emitter from '@utils/mixins/emitter';
  import Migrating from '@utils/mixins/migrating';
  import { generateId } from '@utils/utils/util';
  import Focus from '@utils/mixins/focus';

  export default {
    name: 'YxtAutocomplete',

    mixins: [Emitter, Focus('input'), Migrating],

    inheritAttrs: false,

    componentName: 'YxtAutocomplete',

    components: {
      YxtInput,
      YxtAutocompleteSuggestions
    },

    directives: { Clickoutside },

    props: {
      valueKey: {
        type: String,
        default: 'value',
        desc: '输入建议对象中用于显示的键名'
      },
      popperClass: {
        type: String,
        desc: '下拉列表的类名'
      },
      popperOptions: {
        type: Object,
        desc: 'popper配置项'
      },
      placeholder: {
        type: String,
        desc: '占位文本'
      },
      clearable: {
        type: Boolean,
        default: false,
        desc: '是否可清除'
      },
      disabled: {
        type: Boolean,
        desc: '是否禁用'
      },
      name: {
        type: String,
        desc: '原生name属性'
      },
      size: {
        type: String,
        desc: '尺寸'
      },
      value: {
        type: String,
        desc: '绑定值'
      },
      maxlength: {
        type: Number,
        desc: '最大输入长度'
      },
      minlength: {
        type: Number,
        desc: '最小输入长度'
      },
      autofocus: {
        type: Boolean,
        desc: '自动获取焦点'
      },
      fetchSuggestions: {
        type: Function,
        desc: '返回输入建议的方法，仅当你的输入建议数据 resolve 时，通过调用 callback(data:[]) 来返回它'
      },
      triggerOnFocus: {
        type: Boolean,
        default: true,
        desc: '是否在输入框 focus 时显示建议列表'
      },
      selectWhenUnmatched: {
        type: Boolean,
        default: false,
        desc: '在输入没有任何匹配建议的情况下，按下回车是否触发 select 事件'
      },
      suffixIcon: {
        type: String,
        desc: '尾部图标'
      },
      prefixIcon: {
        type: String,
        desc: '头部图标'
      },
      label: {
        type: String,
        desc: '关联的label文字'
      },
      debounce: {
        type: Number,
        default: 300,
        desc: '获取输入建议的去抖延时'
      },
      placement: {
        type: String,
        default: 'bottom-start',
        desc: '菜单弹出位置'
      },
      hideLoading: {
        type: Boolean,
        desc: '是否隐藏远程加载图标'
      },
      popperAppendToBody: {
        type: Boolean,
        default: true,
        desc: '是否将下拉列表插入至body元素'
      },
      highlightFirstItem: {
        type: Boolean,
        default: false,
        desc: '是否默认突出显示远程搜索建议中的第一项'
      },
      customItem: {
        type: String,
        desc: 'customItem(器用)'
      }
    },
    data() {
      return {
        activated: false,
        suggestions: [],
        loading: false,
        highlightedIndex: -1,
        suggestionDisabled: false
      };
    },
    computed: {
      suggestionVisible() {
        const suggestions = this.suggestions;
        let isValidData = Array.isArray(suggestions) && suggestions.length > 0;
        return (isValidData || this.loading) && this.activated;
      },
      id() {
        return `yxt-autocomplete-${generateId()}`;
      }
    },
    watch: {
      suggestionVisible(val) {
        let $input = this.getInput();
        if ($input) {
          this.broadcast('YxtAutocompleteSuggestions', 'visible', [val, $input.offsetWidth]);
        }
      }
    },
    methods: {
      getMigratingConfig() {
        return {
          props: {
            'custom-item': 'custom-item is removed, use scoped slot instead.',
            'props': 'props is removed, use value-key instead.'
          }
        };
      },
      getData(queryString) {
        if (this.suggestionDisabled) {
          return;
        }
        this.loading = true;
        this.fetchSuggestions(queryString, (suggestions) => {
          this.loading = false;
          if (this.suggestionDisabled) {
            return;
          }
          if (Array.isArray(suggestions)) {
            this.suggestions = suggestions;
            this.highlightedIndex = this.highlightFirstItem ? 0 : -1;
          } else {
            console.error('[Element Error][Autocomplete]autocomplete suggestions must be an array');
          }
        });
      },
      handleChange(value) {
        this.$emit('input', value);
        this.suggestionDisabled = false;
        if (!this.triggerOnFocus && !value) {
          this.suggestionDisabled = true;
          this.suggestions = [];
          return;
        }
        this.debouncedGetData(value);
      },
      handleFocus(event) {
        this.activated = true;
        this.$emit('focus', event);
        if (this.triggerOnFocus) {
          this.debouncedGetData(this.value);
        }
      },
      handleBlur(event) {
        this.$emit('blur', event);
      },
      handleClear() {
        this.activated = false;
        this.$emit('clear');
      },
      close(e) {
        this.activated = false;
      },
      handleKeyEnter(e) {
        if (this.suggestionVisible && this.highlightedIndex >= 0 && this.highlightedIndex < this.suggestions.length) {
          e.preventDefault();
          this.select(this.suggestions[this.highlightedIndex]);
        } else if (this.selectWhenUnmatched) {
          this.$emit('select', {value: this.value});
          this.$nextTick(_ => {
            this.suggestions = [];
            this.highlightedIndex = -1;
          });
        }
      },
      select(item) {
        this.$emit('input', item[this.valueKey]);
        this.$emit('select', item);
        this.$nextTick(_ => {
          this.suggestions = [];
          this.highlightedIndex = -1;
        });
      },
      highlight(index) {
        if (!this.suggestionVisible || this.loading) { return; }
        if (index < 0) {
          this.highlightedIndex = -1;
          return;
        }
        if (index >= this.suggestions.length) {
          index = this.suggestions.length - 1;
        }
        const suggestion = this.$refs.suggestions.$el.querySelector('.yxt-autocomplete-suggestion__wrap');
        const suggestionList = suggestion.querySelectorAll('.yxt-autocomplete-suggestion__list li');

        let highlightItem = suggestionList[index];
        let scrollTop = suggestion.scrollTop;
        let offsetTop = highlightItem.offsetTop;

        if (offsetTop + highlightItem.scrollHeight > (scrollTop + suggestion.clientHeight)) {
          suggestion.scrollTop += highlightItem.scrollHeight;
        }
        if (offsetTop < scrollTop) {
          suggestion.scrollTop -= highlightItem.scrollHeight;
        }
        this.highlightedIndex = index;
        let $input = this.getInput();
        $input.setAttribute('aria-activedescendant', `${this.id}-item-${this.highlightedIndex}`);
      },
      getInput() {
        return this.$refs.input.getInput();
      }
    },
    mounted() {
      this.debouncedGetData = debounce(this.debounce, this.getData);
      this.$on('item-click', item => {
        this.select(item);
      });
      let $input = this.getInput();
      $input.setAttribute('role', 'textbox');
      $input.setAttribute('aria-autocomplete', 'list');
      $input.setAttribute('aria-controls', 'id');
      $input.setAttribute('aria-activedescendant', `${this.id}-item-${this.highlightedIndex}`);
    },
    beforeDestroy() {
      this.$refs.suggestions.$destroy();
    }
  };
</script>
