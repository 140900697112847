var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "yxt-collapse",
      class: [_vm.type ? "yxt-collapse--" + _vm.type : ""],
      attrs: { role: "tablist", "aria-multiselectable": "true" },
    },
    [_vm._t("default")],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }