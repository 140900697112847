<template>
  <li
    class="yxtf-dropdown-menu__item"
    :class="{
      'is-disabled': disabled,
      'yxtf-dropdown-menu__item--divided': divided
    }"
    @click="handleClick"
    :aria-disabled="disabled"
    :tabindex="disabled ? null : -1"
  >
    <i :class="icon" v-if="icon"></i>
    <slot></slot>
  </li>
</template>
<script>
  import Emitter from '@utils/mixins/emitter';

  export default {
    name: 'YxtfDropdownItem',

    mixins: [Emitter],

    props: {
      command: {},
      disabled: Boolean,
      divided: Boolean,
      icon: String
    },

    methods: {
      handleClick(e) {
        this.dispatch('YxtfDropdown', 'menu-item-click', [this.command, this]);
      }
    }
  };
</script>
