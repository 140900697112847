<template>
  <div class='yxt-layout__body'>
    <slot></slot>
  </div>
</template>

<script>
  export default {
    name: 'YxtLayoutMain'
  };
</script>
