var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "yxt-calendar" }, [
    _c("div", { staticClass: "yxt-calendar__header" }, [
      _c("div", { staticClass: "yxt-calendar__title" }, [
        _vm._v("\n      " + _vm._s(_vm.i18nDate) + "\n    "),
      ]),
      _vm.validatedRange.length === 0
        ? _c(
            "div",
            { staticClass: "yxt-calendar__button-group" },
            [
              _c(
                "yxt-button-group",
                [
                  _c(
                    "yxt-button",
                    {
                      attrs: { type: "plain", size: "mini" },
                      on: {
                        click: function ($event) {
                          _vm.selectDate("prev-month")
                        },
                      },
                    },
                    [
                      _vm._v(
                        "\n          " +
                          _vm._s(_vm.t("pc_comp_datepicker_prevMonth")) +
                          "\n        "
                      ),
                    ]
                  ),
                  _c(
                    "yxt-button",
                    {
                      attrs: { type: "plain", size: "mini" },
                      on: {
                        click: function ($event) {
                          _vm.selectDate("today")
                        },
                      },
                    },
                    [
                      _vm._v(
                        "\n          " +
                          _vm._s(_vm.t("pc_comp_datepicker_today")) +
                          "\n        "
                      ),
                    ]
                  ),
                  _c(
                    "yxt-button",
                    {
                      attrs: { type: "plain", size: "mini" },
                      on: {
                        click: function ($event) {
                          _vm.selectDate("next-month")
                        },
                      },
                    },
                    [
                      _vm._v(
                        "\n          " +
                          _vm._s(_vm.t("pc_comp_datepicker_nextMonth")) +
                          "\n        "
                      ),
                    ]
                  ),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
    ]),
    _vm.validatedRange.length === 0
      ? _c(
          "div",
          { key: "no-range", staticClass: "yxt-calendar__body" },
          [
            _c("date-table", {
              attrs: {
                date: _vm.date,
                "selected-day": _vm.realSelectedDay,
                "first-day-of-week": _vm.realFirstDayOfWeek,
              },
              on: { pick: _vm.pickDay },
            }),
          ],
          1
        )
      : _c(
          "div",
          { key: "has-range", staticClass: "yxt-calendar__body" },
          _vm._l(_vm.validatedRange, function (range, index) {
            return _c("date-table", {
              key: index,
              attrs: {
                date: range[0],
                "selected-day": _vm.realSelectedDay,
                range: range,
                "hide-header": index !== 0,
                "first-day-of-week": _vm.realFirstDayOfWeek,
              },
              on: { pick: _vm.pickDay },
            })
          }),
          1
        ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }