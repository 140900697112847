<template>
  <transition name="yxtf-zoom-in-top" @after-leave="doDestroy">
    <div
      v-show="showPopper"
      class="yxtf-autocomplete-suggestion yxtf-popper"
      :class="{ 'is-loading': !parent.hideLoading && parent.loading }"
      :style="{ width: dropdownWidth }"
      role="region">
      <yxtf-scrollbar
        tag="ul"
        wrap-class="yxtf-autocomplete-suggestion__wrap"
        view-class="yxtf-autocomplete-suggestion__list">
        <li v-if="!parent.hideLoading && parent.loading"><i class="yxtf-icon-loading"></i></li>
        <slot v-else>
        </slot>
      </yxtf-scrollbar>
    </div>
  </transition>
</template>
<script>
  import Popper from '@utils/utils/vue-popper';
  import Emitter from '@utils/mixins/emitter';
  import ElScrollbar from '@foreground/scrollbar';

  export default {
    components: { ElScrollbar },
    mixins: [Popper, Emitter],

    componentName: 'YxtfAutocompleteSuggestions',

    data() {
      return {
        parent: this.$parent,
        dropdownWidth: ''
      };
    },

    props: {
      options: {
        default() {
          return {
            gpuAcceleration: false
          };
        }
      },
      appendToBody: {
        type: Boolean,
        default: true
      },
      id: String
    },

    methods: {
      select(item) {
        this.dispatch('YxtfAutocomplete', 'item-click', item);
      }
    },

    updated() {
      this.$nextTick(_ => {
        this.popperJS && this.updatePopper();
      });
    },

    mounted() {
      if (!this.appendToBody) {
        this.offset = [0, 0];
      }
      this.$parent.popperElm = this.popperElm = this.$el;
      this.referenceElm = this.$parent.$refs.input.$refs.input;
      this.referenceList = this.$el.querySelector('.yxtf-autocomplete-suggestion__list');
      this.referenceList.setAttribute('role', 'listbox');
      this.referenceList.setAttribute('id', this.id);
    },

    created() {
      this.$on('visible', (val, inputWidth) => {
        this.dropdownWidth = inputWidth + 'px';
        this.showPopper = val;
      });
    }
  };
</script>
