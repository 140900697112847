var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "yxt-steps",
      class: [
        !(_vm.simple || _vm.type === "simple") &&
          !(_vm.basic || _vm.type === "basic") &&
          _vm.type !== "navigation" &&
          "yxt-steps--" + _vm.direction,
        (_vm.simple || _vm.type == "simple") &&
          !(_vm.basic || _vm.type == "basic") &&
          "yxt-steps--simple",
        (_vm.basic || _vm.type == "basic") &&
          !(_vm.simple || _vm.type == "simple") &&
          "yxt-steps--basic",
        _vm.type == "navigation" && "yxt-steps--navigation",
      ],
    },
    [_vm._t("default")],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }