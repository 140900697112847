<template>
  <transition
    name="dialog-fade"
    @after-enter="afterEnter"
    @after-leave="afterLeave">
    <div
      v-show="visible"
      class="yxt-dialog__wrapper"
      :class="{'is-fullscreen': fullscreen, 'is-custom-header': customHeader}"
      @click.self="handleWrapperClick">
      <div
        role="dialog"
        :key="key"
        aria-modal="true"
        :aria-label="title || 'dialog'"
        :class="['yxt-dialog', { 'is-fullscreen': fullscreen, 'yxt-dialog--center': center, 'yxt-dialog--cutline': cutline,
            'yxt-dialog--larger': paddingSize == 'larger',
            'yxt-dialog--medium': paddingSize == 'medium',
            'yxt-dialog--small': paddingSize == 'small',
            'yxt-dialog--empty': paddingSize == 'empty', 'no-footer': !$slots.footer }, customClass]"
        ref="dialog"
        :style="style">
        <div class="yxt-dialog__header" v-if="$slots.title || title">
          <span class="yxt-dialog__back" @click="backHandler" v-if="showBack">
            <yxt-svg width="20px" height="20px" icon-class="arrow_left"></yxt-svg>
          </span>
          <span class="yxt-dialog__title" v-if="$slots.title || title">
            <slot name="title">
              {{ title }}
            </slot>
          </span>
          <yxt-tooltip :effect="tooltipEffect " :content="labelTooltip" placement="bottom"  v-if="labelTooltip" :max-width="labelTooltipWidth">
            <yxt-svg remote-url='' class='color-gray-6 hover-primary-6' width='20px' style="margin-left: 4px; margin-right: 4px; cursor: pointer;" height='20px' icon-class="question-cirlce-o" />
          </yxt-tooltip>
          <span v-if="describe" class="yxt-dialog--describe">{{ describe }}</span>
        </div>
        <div class="yxt-dialog__header" v-if="fullscreen && showHeader">
          <span class="yxt-dialog__back" @click="backHandler" v-if="showBack">
              <yxt-svg width="20px" height="20px" icon-class="arrow_left"></yxt-svg>
          </span>
          <div class="yxt-dialog__header-content">
            <div class="yxt-dialog__header-left">
              <div v-if="$slots.mainTitle" class="yxt-dialog__main-title">
                <slot name="mainTitle"></slot>
              </div>
             <div v-if="$slots.subTitle" class="yxt-dialog__sub-title">
              <slot name="subTitle"></slot>
             </div>
            </div>
            <div class="yxt-dialog__header-right" v-if="$slots.headerRight">
              <slot name="headerRight">
              </slot>
            </div>

          </div>
        </div>
        <button
          type="button"
          class="yxt-dialog__headerbtn"
          aria-label="Close"
          v-if="showClose"
          @click="handleClose">
          <yxt-svg width="20px" height="20px" class="yxt-dialog__close" icon-class="close" />
          <!-- <i class="yxt-dialog__close yxt-icon yxt-icon-close"></i> -->
        </button>
        <yxt-scrollbar v-if="rendered" ref="scrollbar" :fit-height="true">
          <div class="yxt-dialog__body">
              <slot></slot>
          </div>
        </yxt-scrollbar>
        <div class="yxt-dialog__footer" :class="{'footer-cutline': footerCutline}" v-if="$slots.footer">
          <div class="yxt-dialog__footer-prefix" v-if="$slots.prefixFooter">
            <slot name="prefixFooter"></slot>
          </div>
          <slot name="footer"></slot>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
  import Popup from '@utils/utils/popup';
  import Migrating from '@utils/mixins/migrating';
  import emitter from '@utils/mixins/emitter';
  import throttle from 'throttle-debounce/throttle';

  export default {
    name: 'YxtDialog',

    mixins: [Popup, emitter, Migrating],

    props: {
      paddingSize: {
        validator: function(val) {
          switch (val) {
            case 'larger':
            case 'medium':
            case 'small':
            case 'empty':
              return val;
            default:
              return 'default';
          }
        }
      },
      title: {
        type: String,
        default: ''
      },

      customHeader: {
        type: Boolean,
        default: false
      },

      modal: {
        type: Boolean,
        default: true
      },

      modalAppendToBody: {
        type: Boolean,
        default: true
      },

      appendToBody: {
        type: Boolean,
        default: false
      },

      lockScroll: {
        type: Boolean,
        default: true
      },

      showHeader: {
        type: Boolean,
        default: false
      },

      closeOnClickModal: {
        type: Boolean,
        default: true
      },

      closeOnPressEscape: {
        type: Boolean,
        default: true
      },

      showClose: {
        type: Boolean,
        default: true
      },

      width: String,

      fullscreen: Boolean,
      showBack: Boolean,

      customClass: {
        type: String,
        default: ''
      },

      top: {
        type: String,
        default: '48px'
      },
      beforeClose: Function,
      center: {
        type: Boolean,
        default: false
      },

      destroyOnClose: Boolean,
      cutline: {
        type: Boolean,
        default: true
      },
      footerCutline: {
        type: Boolean,
        default: false
      },
      labelTooltip: String,
      labelTooltipWidth: {
        type: Number,
        default: 440
      },
      description: String,
      describe: { // 描述
        type: String,
        default: ''
      },
      tooltipEffect: {
        type: String,
        default: 'dark'
      }
    },

    data() {
      return {
        closed: false,
        key: 0
      };
    },

    watch: {
      visible(val) {
        if (val) {
          this.closed = false;
          this.$emit('open');
          this.$el.addEventListener('scroll', this.updatePopper);
          if (this.fullscreen) {
            window.addEventListener('keydown', this.escEvent);
          }
          this.$nextTick(() => {
            this.$refs.dialog.scrollTop = 0;
          });
          if (this.appendToBody) {
            document.body.appendChild(this.$el);
          }
        } else {
          this.$el.removeEventListener('scroll', this.updatePopper);
          if (this.fullscreen) {
            window.removeEventListener('keydown', this.escEvent);
          }
          if (!this.closed) this.$emit('close');
          if (this.destroyOnClose) {
            this.$nextTick(() => {
              setTimeout(() => {
                this.key++;
              }, 300);
            });
          }
        }
      }
    },

    computed: {
      style() {
        let style = {};
        if (!this.fullscreen) {
          // style.marginTop = this.top;
          if (this.width) {
            style.width = this.width;
          }
        }
        return style;
      }
    },

    methods: {
      getMigratingConfig() {
        return {
          props: {
            'size': 'size is removed.'
          }
        };
      },
      escEvent(event) {
        if (!this.closeOnPressEscape) {
          return;
        }
        if (event.keyCode === 27) {
          this.handleClose();
        }
      },
      backHandler() {
        this.$emit('back');
      },
      handleWrapperClick() {
        if (!this.closeOnClickModal) return;
        this.handleClose();
      },
      handleClose() {
        if (typeof this.beforeClose === 'function') {
          this.beforeClose(this.hide);
        } else {
          this.hide();
        }
      },
      hide(cancel) {
        if (cancel !== false) {
          this.$emit('update:visible', false);
          this.$emit('close');
          this.closed = true;
        }
      },
      updatePopper() {
        this.broadcast('YxtSelectDropdown', 'updatePopper');
        this.broadcast('YxtDropdownMenu', 'updatePopper');
      },
      afterEnter() {
        this.$emit('opened');
      },
      afterLeave() {
        this.$emit('closed');
      },
      updateScroll() {
        if (this.$refs.scrollbar) {
          this.$refs.scrollbar.update();
        }
      }
    },

    mounted() {
      if (this.visible) {
        this.rendered = true;
        this.open();
        if (this.appendToBody) {
          document.body.appendChild(this.$el);
        }
      }

      this.throttleUpdate = throttle(100, true, () => this.updateScroll());

      window.addEventListener('resize', this.throttleUpdate);
    },

    destroyed() {
      // if appendToBody is true, remove DOM node after destroy21
      if (this.appendToBody && this.$el && this.$el.parentNode) {
        this.$el.parentNode.removeChild(this.$el);
      }

      window.removeEventListener('resize', this.throttleUpdate);
    }
  };
</script>
