var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "table",
    { staticClass: "yxtf-year-table", on: { click: _vm.handleYearTableClick } },
    [
      _c("tbody", [
        _c("tr", [
          _c(
            "td",
            {
              staticClass: "available",
              class: _vm.getCellStyle(_vm.startYear + 0),
            },
            [_c("a", { staticClass: "cell" }, [_vm._v(_vm._s(_vm.startYear))])]
          ),
          _c(
            "td",
            {
              staticClass: "available",
              class: _vm.getCellStyle(_vm.startYear + 1),
            },
            [
              _c("a", { staticClass: "cell" }, [
                _vm._v(_vm._s(_vm.startYear + 1)),
              ]),
            ]
          ),
          _c(
            "td",
            {
              staticClass: "available",
              class: _vm.getCellStyle(_vm.startYear + 2),
            },
            [
              _c("a", { staticClass: "cell" }, [
                _vm._v(_vm._s(_vm.startYear + 2)),
              ]),
            ]
          ),
          _c(
            "td",
            {
              staticClass: "available",
              class: _vm.getCellStyle(_vm.startYear + 3),
            },
            [
              _c("a", { staticClass: "cell" }, [
                _vm._v(_vm._s(_vm.startYear + 3)),
              ]),
            ]
          ),
        ]),
        _c("tr", [
          _c(
            "td",
            {
              staticClass: "available",
              class: _vm.getCellStyle(_vm.startYear + 4),
            },
            [
              _c("a", { staticClass: "cell" }, [
                _vm._v(_vm._s(_vm.startYear + 4)),
              ]),
            ]
          ),
          _c(
            "td",
            {
              staticClass: "available",
              class: _vm.getCellStyle(_vm.startYear + 5),
            },
            [
              _c("a", { staticClass: "cell" }, [
                _vm._v(_vm._s(_vm.startYear + 5)),
              ]),
            ]
          ),
          _c(
            "td",
            {
              staticClass: "available",
              class: _vm.getCellStyle(_vm.startYear + 6),
            },
            [
              _c("a", { staticClass: "cell" }, [
                _vm._v(_vm._s(_vm.startYear + 6)),
              ]),
            ]
          ),
          _c(
            "td",
            {
              staticClass: "available",
              class: _vm.getCellStyle(_vm.startYear + 7),
            },
            [
              _c("a", { staticClass: "cell" }, [
                _vm._v(_vm._s(_vm.startYear + 7)),
              ]),
            ]
          ),
        ]),
        _c("tr", [
          _c(
            "td",
            {
              staticClass: "available",
              class: _vm.getCellStyle(_vm.startYear + 8),
            },
            [
              _c("a", { staticClass: "cell" }, [
                _vm._v(_vm._s(_vm.startYear + 8)),
              ]),
            ]
          ),
          _c(
            "td",
            {
              staticClass: "available",
              class: _vm.getCellStyle(_vm.startYear + 9),
            },
            [
              _c("a", { staticClass: "cell" }, [
                _vm._v(_vm._s(_vm.startYear + 9)),
              ]),
            ]
          ),
          _c("td"),
          _c("td"),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }