var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.showPanel
    ? _c(
        "div",
        {
          staticClass: "yxtf-table__title-custom-column",
          on: { click: _vm.stopEvent },
        },
        [
          _c("div", { staticClass: "yxtf-table__custom-column-wrapper" }, [
            _c(
              "div",
              { staticClass: "yxtf-table__custom-column-header" },
              [
                _c(
                  "yxtf-checkbox",
                  {
                    attrs: { indeterminate: _vm.isIndeterminate },
                    on: { change: _vm.handleCheckAllChange },
                    model: {
                      value: _vm.checkAll,
                      callback: function ($$v) {
                        _vm.checkAll = $$v
                      },
                      expression: "checkAll",
                    },
                  },
                  [_vm._v(_vm._s(_vm.t("pc_comp_table_clearFilter")))]
                ),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "yxtf-table__title-check-panel" },
              [
                _c(
                  "yxtf-scrollbar",
                  { attrs: { wrapClass: "custom-column-scrollbar" } },
                  [
                    _c(
                      "yxtf-checkbox-group",
                      {
                        attrs: { direction: "row" },
                        on: { change: _vm.checkedColumnsChange },
                        model: {
                          value: _vm.checkedColumnsList,
                          callback: function ($$v) {
                            _vm.checkedColumnsList = $$v
                          },
                          expression: "checkedColumnsList",
                        },
                      },
                      _vm._l(_vm.columnsList, function (item) {
                        return _c(
                          "div",
                          {
                            key: item.label,
                            staticClass: "yxtf-table__check-item",
                          },
                          [
                            item.label
                              ? _c(
                                  "div",
                                  {
                                    staticClass: "yxtf-table__check-item-cont",
                                  },
                                  [
                                    _c(
                                      "yxtf-checkbox",
                                      {
                                        attrs: {
                                          disabled: item.disable
                                            ? "lock"
                                            : false,
                                          label: item.label,
                                        },
                                      },
                                      [
                                        _c("span", {
                                          domProps: {
                                            textContent: _vm._s(item.label),
                                          },
                                        }),
                                        item.disable
                                          ? _c("yxtf-svg", {
                                              staticClass:
                                                "color-gray-6 custom-column-icon-lock",
                                              attrs: {
                                                width: "16px",
                                                height: "16px",
                                                "icon-class": "lock-on",
                                              },
                                            })
                                          : _vm._e(),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                )
                              : _vm._e(),
                          ]
                        )
                      }),
                      0
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "yxtf-table__custom-column-footer" },
              [
                _c(
                  "yxtf-button",
                  {
                    staticClass: "custom-btn-cancel",
                    attrs: { plain: "" },
                    on: { click: _vm.hidePanel },
                  },
                  [_vm._v(_vm._s(_vm.t("pc_comp_table_cancel")))]
                ),
                _c(
                  "yxtf-button",
                  {
                    staticClass: "custom-btn-confirm",
                    attrs: { type: "primary" },
                    on: { click: _vm.changeCheckedList },
                  },
                  [_vm._v(_vm._s(_vm.t("pc_comp_table_confirm")))]
                ),
              ],
              1
            ),
          ]),
        ]
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }