var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "yxt-sticky", class: _vm.wrapClass, style: _vm.style },
    [
      _c(
        "div",
        {
          ref: "container",
          staticClass: "yxt-sticky__inner",
          class: [
            _vm.stickyClass,
            { "is-fixed": _vm.fixed, "yxt-sticky--animate": _vm.animated },
          ],
          style: _vm.stickyStyle,
        },
        [_vm._t("default")],
        2
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }