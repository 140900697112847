<!--
 * @FileDescription: 
 * @Author: 张显华
 * @Date: 2020-11-02 15:51:11
 * @LastEditors: 张显华
 * @LastEditTime: 2020-11-03 21:19:07
-->
<template>
  <div
    class="yxtf-select-dropdown yxtf-popper"
    :class="[{ 'is-multiple': $parent.multiple }, popperClass]"
    :style="{ minWidth: minWidth , maxWidth: maxWidth }">
    <slot></slot>
  </div>
</template>

<script type="text/babel">
  import Popper from '@utils/utils/vue-popper';

  export default {
    name: 'YxtfSelectDropdown',

    componentName: 'YxtfSelectDropdown',

    mixins: [Popper],

    props: {
      placement: {
        default: 'bottom-start'
      },

      boundariesPadding: {
        default: 0
      },

      popperOptions: {
        default() {
          return {
            gpuAcceleration: false
          };
        }
      },

      visibleArrow: {
        default: true
      },

      appendToBody: {
        type: Boolean,
        default: true
      },
      menuMaxWidth: Number
    },

    data() {
      return {
        minWidth: ''
      };
    },

    computed: {
      popperClass() {
        return this.$parent.popperClass;
      },
      maxWidth() {
        return this.menuMaxWidth === 0 ? '' : this.menuMaxWidth + 'px';
      }
    },
    watch: {
      '$parent.inputWidth'() {
        this.minWidth = this.$parent.$el.getBoundingClientRect().width + 'px';
      }
    },

    mounted() {
      this.referenceElm = this.$parent.$refs.reference.$el;
      this.$parent.popperElm = this.popperElm = this.$el;
      this.$on('updatePopper', () => {
        if (this.$parent.visible) this.updatePopper();
      });
      this.$on('destroyPopper', this.destroyPopper);
    }
  };
</script>
