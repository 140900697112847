var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "transition",
    {
      attrs: { name: "dialog-fade" },
      on: { "after-enter": _vm.afterEnter, "after-leave": _vm.afterLeave },
    },
    [
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.visible,
              expression: "visible",
            },
          ],
          staticClass: "yxt-dialog__wrapper",
          class: {
            "is-fullscreen": _vm.fullscreen,
            "is-custom-header": _vm.customHeader,
          },
          on: {
            click: function ($event) {
              if ($event.target !== $event.currentTarget) {
                return null
              }
              return _vm.handleWrapperClick($event)
            },
          },
        },
        [
          _c(
            "div",
            {
              key: _vm.key,
              ref: "dialog",
              class: [
                "yxt-dialog",
                {
                  "is-fullscreen": _vm.fullscreen,
                  "yxt-dialog--center": _vm.center,
                  "yxt-dialog--cutline": _vm.cutline,
                  "yxt-dialog--larger": _vm.paddingSize == "larger",
                  "yxt-dialog--medium": _vm.paddingSize == "medium",
                  "yxt-dialog--small": _vm.paddingSize == "small",
                  "yxt-dialog--empty": _vm.paddingSize == "empty",
                  "no-footer": !_vm.$slots.footer,
                },
                _vm.customClass,
              ],
              style: _vm.style,
              attrs: {
                role: "dialog",
                "aria-modal": "true",
                "aria-label": _vm.title || "dialog",
              },
            },
            [
              _vm.$slots.title || _vm.title
                ? _c(
                    "div",
                    { staticClass: "yxt-dialog__header" },
                    [
                      _vm.showBack
                        ? _c(
                            "span",
                            {
                              staticClass: "yxt-dialog__back",
                              on: { click: _vm.backHandler },
                            },
                            [
                              _c("yxt-svg", {
                                attrs: {
                                  width: "20px",
                                  height: "20px",
                                  "icon-class": "arrow_left",
                                },
                              }),
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm.$slots.title || _vm.title
                        ? _c(
                            "span",
                            { staticClass: "yxt-dialog__title" },
                            [
                              _vm._t("title", [
                                _vm._v(
                                  "\n            " +
                                    _vm._s(_vm.title) +
                                    "\n          "
                                ),
                              ]),
                            ],
                            2
                          )
                        : _vm._e(),
                      _vm.labelTooltip
                        ? _c(
                            "yxt-tooltip",
                            {
                              attrs: {
                                effect: _vm.tooltipEffect,
                                content: _vm.labelTooltip,
                                placement: "bottom",
                                "max-width": _vm.labelTooltipWidth,
                              },
                            },
                            [
                              _c("yxt-svg", {
                                staticClass: "color-gray-6 hover-primary-6",
                                staticStyle: {
                                  "margin-left": "4px",
                                  "margin-right": "4px",
                                  cursor: "pointer",
                                },
                                attrs: {
                                  "remote-url": "",
                                  width: "20px",
                                  height: "20px",
                                  "icon-class": "question-cirlce-o",
                                },
                              }),
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm.describe
                        ? _c("span", { staticClass: "yxt-dialog--describe" }, [
                            _vm._v(_vm._s(_vm.describe)),
                          ])
                        : _vm._e(),
                    ],
                    1
                  )
                : _vm._e(),
              _vm.fullscreen && _vm.showHeader
                ? _c("div", { staticClass: "yxt-dialog__header" }, [
                    _vm.showBack
                      ? _c(
                          "span",
                          {
                            staticClass: "yxt-dialog__back",
                            on: { click: _vm.backHandler },
                          },
                          [
                            _c("yxt-svg", {
                              attrs: {
                                width: "20px",
                                height: "20px",
                                "icon-class": "arrow_left",
                              },
                            }),
                          ],
                          1
                        )
                      : _vm._e(),
                    _c("div", { staticClass: "yxt-dialog__header-content" }, [
                      _c("div", { staticClass: "yxt-dialog__header-left" }, [
                        _vm.$slots.mainTitle
                          ? _c(
                              "div",
                              { staticClass: "yxt-dialog__main-title" },
                              [_vm._t("mainTitle")],
                              2
                            )
                          : _vm._e(),
                        _vm.$slots.subTitle
                          ? _c(
                              "div",
                              { staticClass: "yxt-dialog__sub-title" },
                              [_vm._t("subTitle")],
                              2
                            )
                          : _vm._e(),
                      ]),
                      _vm.$slots.headerRight
                        ? _c(
                            "div",
                            { staticClass: "yxt-dialog__header-right" },
                            [_vm._t("headerRight")],
                            2
                          )
                        : _vm._e(),
                    ]),
                  ])
                : _vm._e(),
              _vm.showClose
                ? _c(
                    "button",
                    {
                      staticClass: "yxt-dialog__headerbtn",
                      attrs: { type: "button", "aria-label": "Close" },
                      on: { click: _vm.handleClose },
                    },
                    [
                      _c("yxt-svg", {
                        staticClass: "yxt-dialog__close",
                        attrs: {
                          width: "20px",
                          height: "20px",
                          "icon-class": "close",
                        },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
              _vm.rendered
                ? _c(
                    "yxt-scrollbar",
                    { ref: "scrollbar", attrs: { "fit-height": true } },
                    [
                      _c(
                        "div",
                        { staticClass: "yxt-dialog__body" },
                        [_vm._t("default")],
                        2
                      ),
                    ]
                  )
                : _vm._e(),
              _vm.$slots.footer
                ? _c(
                    "div",
                    {
                      staticClass: "yxt-dialog__footer",
                      class: { "footer-cutline": _vm.footerCutline },
                    },
                    [
                      _vm.$slots.prefixFooter
                        ? _c(
                            "div",
                            { staticClass: "yxt-dialog__footer-prefix" },
                            [_vm._t("prefixFooter")],
                            2
                          )
                        : _vm._e(),
                      _vm._t("footer"),
                    ],
                    2
                  )
                : _vm._e(),
            ],
            1
          ),
        ]
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }