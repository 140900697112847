var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("transition", { attrs: { name: "msgbox-fade" } }, [
    _c(
      "div",
      {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.visible,
            expression: "visible",
          },
        ],
        staticClass: "yxt-message-box__wrapper",
        attrs: {
          tabindex: "-1",
          role: "dialog",
          "aria-modal": "true",
          "aria-label": _vm.title || "dialog",
        },
        on: {
          click: function ($event) {
            if ($event.target !== $event.currentTarget) {
              return null
            }
            return _vm.handleWrapperClick($event)
          },
        },
      },
      [
        _c(
          "div",
          {
            staticClass: "yxt-message-box",
            class: [
              "yxt-message-box__" + _vm.type,
              _vm.customClass,
              _vm.center && "yxt-message-box--center",
            ],
          },
          [
            _vm.title !== null
              ? _c("div", { staticClass: "yxt-message-box__header" }, [
                  _c(
                    "div",
                    { staticClass: "yxt-message-box__title" },
                    [
                      (_vm.icon && _vm.center) || _vm.icon
                        ? _c("yxt-svg", {
                            staticClass: "v-top",
                            class: ["yxt-message-box__status", _vm.icon],
                            attrs: {
                              width: "20px",
                              height: "20px",
                              "icon-class": "message-" + _vm.iconType,
                            },
                          })
                        : _vm._e(),
                      _c("span", [_vm._v(_vm._s(_vm.title))]),
                    ],
                    1
                  ),
                  _vm.showClose
                    ? _c(
                        "button",
                        {
                          staticClass: "yxt-message-box__headerbtn",
                          attrs: { type: "button", "aria-label": "Close" },
                          on: {
                            click: function ($event) {
                              _vm.handleAction(
                                _vm.distinguishCancelAndClose
                                  ? "close"
                                  : "cancel"
                              )
                            },
                            keydown: function ($event) {
                              if (
                                !("button" in $event) &&
                                _vm._k(
                                  $event.keyCode,
                                  "enter",
                                  13,
                                  $event.key,
                                  "Enter"
                                )
                              ) {
                                return null
                              }
                              _vm.handleAction(
                                _vm.distinguishCancelAndClose
                                  ? "close"
                                  : "cancel"
                              )
                            },
                          },
                        },
                        [
                          _c("i", {
                            staticClass:
                              "yxt-message-box__close yxt-icon-close",
                          }),
                        ]
                      )
                    : _vm._e(),
                ])
              : _vm._e(),
            _c(
              "div",
              {
                staticClass: "yxt-message-box__content",
                class: { "is-checkable": _vm.checkable },
              },
              [
                _c("div", { staticClass: "yxt-message-box__container" }, [
                  _vm.message !== ""
                    ? _c(
                        "div",
                        { staticClass: "yxt-message-box__message" },
                        [
                          _vm._t("default", [
                            !_vm.dangerouslyUseHTMLString
                              ? _c("p", [_vm._v(_vm._s(_vm.message))])
                              : _c("p", {
                                  domProps: { innerHTML: _vm._s(_vm.message) },
                                }),
                          ]),
                        ],
                        2
                      )
                    : _vm._e(),
                ]),
                _c(
                  "div",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.showInput,
                        expression: "showInput",
                      },
                    ],
                    staticClass: "yxt-message-box__input",
                  },
                  [
                    _c("yxt-input", {
                      ref: "input",
                      attrs: {
                        type: _vm.inputType,
                        placeholder: _vm.inputPlaceholder,
                      },
                      nativeOn: {
                        keydown: function ($event) {
                          if (
                            !("button" in $event) &&
                            _vm._k(
                              $event.keyCode,
                              "enter",
                              13,
                              $event.key,
                              "Enter"
                            )
                          ) {
                            return null
                          }
                          return _vm.handleInputEnter($event)
                        },
                      },
                      model: {
                        value: _vm.inputValue,
                        callback: function ($$v) {
                          _vm.inputValue = $$v
                        },
                        expression: "inputValue",
                      },
                    }),
                    _c(
                      "div",
                      {
                        staticClass: "yxt-message-box__errormsg",
                        style: {
                          visibility: !!_vm.editorErrorMessage
                            ? "visible"
                            : "hidden",
                        },
                      },
                      [_vm._v(_vm._s(_vm.editorErrorMessage))]
                    ),
                  ],
                  1
                ),
              ]
            ),
            _c(
              "div",
              { staticClass: "yxt-message-box__btns" },
              [
                _vm.showCancelButton
                  ? _c(
                      "yxt-button",
                      _vm._b(
                        {
                          staticClass: "yxt-message-box__btn",
                          class: [_vm.cancelButtonClasses],
                          attrs: {
                            loading: _vm.cancelButtonLoading,
                            round: _vm.roundButton,
                          },
                          on: {
                            keydown: function ($event) {
                              if (
                                !("button" in $event) &&
                                _vm._k(
                                  $event.keyCode,
                                  "enter",
                                  13,
                                  $event.key,
                                  "Enter"
                                )
                              ) {
                                return null
                              }
                              _vm.handleAction("cancel")
                            },
                          },
                          nativeOn: {
                            click: function ($event) {
                              _vm.handleAction("cancel")
                            },
                          },
                        },
                        "yxt-button",
                        _vm.cancelButtonProps,
                        false
                      ),
                      [
                        _vm._v(
                          "\n            " +
                            _vm._s(
                              _vm.cancelButtonText ||
                                _vm.t("pc_comp_messagebox_cancel")
                            ) +
                            "\n          "
                        ),
                      ]
                    )
                  : _vm._e(),
                _c(
                  "yxt-button",
                  _vm._b(
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.showConfirmButton,
                          expression: "showConfirmButton",
                        },
                      ],
                      ref: "confirm",
                      staticClass: "yxt-message-box__btn",
                      class: [_vm.confirmButtonClasses],
                      attrs: {
                        loading: _vm.confirmButtonLoading,
                        round: _vm.roundButton,
                      },
                      on: {
                        keydown: function ($event) {
                          if (
                            !("button" in $event) &&
                            _vm._k(
                              $event.keyCode,
                              "enter",
                              13,
                              $event.key,
                              "Enter"
                            )
                          ) {
                            return null
                          }
                          _vm.handleAction("confirm")
                        },
                      },
                      nativeOn: {
                        click: function ($event) {
                          _vm.handleAction("confirm")
                        },
                      },
                    },
                    "yxt-button",
                    _vm.confirmButtonProps,
                    false
                  ),
                  [
                    _vm._v(
                      "\n            " +
                        _vm._s(
                          _vm.confirmButtonText ||
                            _vm.t("pc_comp_messagebox_confirm")
                        ) +
                        "\n          "
                    ),
                  ]
                ),
              ],
              1
            ),
          ]
        ),
      ]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }