<template>
  <yxtf-row class="yxt-empty">
    <div>
      <slot name="image">
        <yxtf-svg style='display: block;margin:auto;' 
          :width="permission ? '714px' : browser ? '550px' : '300px'" 
          :height="permission ? '422px' : browser ? '401px' : '160px'"
          :icon-class="permission ? 'f_permission' : browser ? 'f_browser' : 'icons/f_empty'"  
          v-if="size !== 'small' && size !== 'medium'"/>
        <yxtf-svg style='display: block;margin:auto;' 
          :width="permission ? '714px' : browser ? '550px' : '260px'"  
          :height="permission ? '422px' : browser ? '401px' : '139px'"
          :icon-class="permission ? 'f_permission' : browser ? 'f_browser' : 'f_empty'" 
          v-if="size === 'medium'"/>
        <yxtf-svg style='display: block;margin:auto;' 
          :width="permission ? '714px' : browser ? '550px' : '160px'"  
          :height="permission ? '422px' : browser ? '401px' : '86px'"
          :icon-class="permission ? 'f_permission-small' : browser ? 'f_browser' : 'f_empty-small'" 
          v-if="size === 'small'"/>
      </slot>
      <div class="color-gray-9 mt16">
        <slot name='text' v-if="fillText !== ''">
          <div :class="[size !== 'small' && size !== 'medium'? 'standard-size-20' : 
          size === 'medium' ? 'standard-size-18' :'standard-size-16']" class="mb12">{{ fillText }}</div>
        </slot>
        <slot name='text' v-if="emptySubtext !== ''">
          <div class='color-gray-7 mb24'>{{ emptySubtext }}</div>
        </slot>
        <slot></slot>
      </div>
    </div>
  </yxtf-row>
</template>
<script>
import yxtfRow from '../../row';
import yxtfSvg from '../../svg-icon';
import { t } from '@utils/locale';
export default {
  components: {
    yxtfRow,
    yxtfSvg
  },
  name: 'YxtfEmpty',
  props: {
    emptyText: {
      default: ''
    },
    emptySubtext: {
      default: ''
    },
    permission: {
      type: Boolean,
      default: false
    },
    browser: {
      type: Boolean,
      default: false
    },
    size: {
      type: String
    }
  },
  data() {
    return {
    };
  },
  computed: {
    fillText() {
      return this.emptyText ? this.emptyText : this.permission ? t('pc_comp_no_auth') : this.browser ? t('pc_comp_in_browser') : t('pc_comp_tree_emptyText');
    }
  }
};
</script>

<style lang="scss" scoped>
</style>