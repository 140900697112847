var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("span", { staticClass: "yxt-spinner" }, [
    _c(
      "svg",
      {
        staticClass: "yxt-spinner-inner",
        style: { width: _vm.radius / 2 + "px", height: _vm.radius / 2 + "px" },
        attrs: { viewBox: "0 0 50 50" },
      },
      [
        _c("circle", {
          staticClass: "path",
          attrs: {
            cx: "25",
            cy: "25",
            r: "20",
            fill: "none",
            stroke: _vm.strokeColor,
            "stroke-width": _vm.strokeWidth,
          },
        }),
      ]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }