var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("yxtf-row", { staticClass: "yxt-empty" }, [
    _c(
      "div",
      [
        _vm._t("image", [
          _vm.size !== "small" && _vm.size !== "medium"
            ? _c("yxtf-svg", {
                staticStyle: { display: "block", margin: "auto" },
                attrs: {
                  width: _vm.permission
                    ? "714px"
                    : _vm.browser
                    ? "550px"
                    : "300px",
                  height: _vm.permission
                    ? "422px"
                    : _vm.browser
                    ? "401px"
                    : "160px",
                  "icon-class": _vm.permission
                    ? "f_permission"
                    : _vm.browser
                    ? "f_browser"
                    : "icons/f_empty",
                },
              })
            : _vm._e(),
          _vm.size === "medium"
            ? _c("yxtf-svg", {
                staticStyle: { display: "block", margin: "auto" },
                attrs: {
                  width: _vm.permission
                    ? "714px"
                    : _vm.browser
                    ? "550px"
                    : "260px",
                  height: _vm.permission
                    ? "422px"
                    : _vm.browser
                    ? "401px"
                    : "139px",
                  "icon-class": _vm.permission
                    ? "f_permission"
                    : _vm.browser
                    ? "f_browser"
                    : "f_empty",
                },
              })
            : _vm._e(),
          _vm.size === "small"
            ? _c("yxtf-svg", {
                staticStyle: { display: "block", margin: "auto" },
                attrs: {
                  width: _vm.permission
                    ? "714px"
                    : _vm.browser
                    ? "550px"
                    : "160px",
                  height: _vm.permission
                    ? "422px"
                    : _vm.browser
                    ? "401px"
                    : "86px",
                  "icon-class": _vm.permission
                    ? "f_permission-small"
                    : _vm.browser
                    ? "f_browser"
                    : "f_empty-small",
                },
              })
            : _vm._e(),
        ]),
        _c(
          "div",
          { staticClass: "color-gray-9 mt16" },
          [
            _vm.fillText !== ""
              ? _vm._t("text", [
                  _c(
                    "div",
                    {
                      staticClass: "mb12",
                      class: [
                        _vm.size !== "small" && _vm.size !== "medium"
                          ? "standard-size-20"
                          : _vm.size === "medium"
                          ? "standard-size-18"
                          : "standard-size-16",
                      ],
                    },
                    [_vm._v(_vm._s(_vm.fillText))]
                  ),
                ])
              : _vm._e(),
            _vm.emptySubtext !== ""
              ? _vm._t("text", [
                  _c("div", { staticClass: "color-gray-7 mb24" }, [
                    _vm._v(_vm._s(_vm.emptySubtext)),
                  ]),
                ])
              : _vm._e(),
            _vm._t("default"),
          ],
          2
        ),
      ],
      2
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }