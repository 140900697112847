var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "table",
    {
      staticClass: "yxtf-date-table",
      class: { "is-week-mode": _vm.selectionMode === "week" },
      attrs: { cellspacing: "0", cellpadding: "0" },
      on: { click: _vm.handleClick, mousemove: _vm.handleMouseMove },
    },
    [
      _c(
        "tbody",
        [
          _c(
            "tr",
            [
              _vm.showWeekNumber
                ? _c("th", [_vm._v(_vm._s(_vm.t("pc_comp_datepicker_week")))])
                : _vm._e(),
              _vm._l(_vm.WEEKS, function (week, key) {
                return _c("th", { key: key }, [
                  _vm._v(_vm._s(_vm.t("pc_comp_datepicker_weeks" + week))),
                ])
              }),
            ],
            2
          ),
          _vm._l(_vm.rows, function (row, key) {
            return _c(
              "tr",
              {
                key: key,
                staticClass: "yxtf-date-table__row",
                class: { current: _vm.isWeekActive(row[1]) },
              },
              _vm._l(row, function (cell, key) {
                return _c("td", { key: key, class: _vm.getCellClasses(cell) }, [
                  _c("div", [
                    _c("span", [
                      _vm._v("\n          " + _vm._s(cell.text) + "\n        "),
                    ]),
                    _c("i", { class: { circle: cell.status } }),
                  ]),
                ])
              }),
              0
            )
          }),
        ],
        2
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }