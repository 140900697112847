<template>
  <div class="yxt-tabs__nav-more" :class="[havExtra && 'yxt-tabs__nav-more-extra']" @mouseover="handleover" @mouseout="handleout">
    <span class="yxt-tabs__nav-more-shadow"></span>
    <yxt-svg
      icon-class="three-dot"
      width="16px"
      height="16px"
    ></yxt-svg>
    <transition name="fade-in-linear">
      <div class="yxt-tabs__nav-popover" v-show="show">
        <scrollbar>
          <ul>
            <li
              v-for="(pane, index) in list"
              :key="index"
              @click="clickMoreLi(pane, pane.name || pane.index || index, $event)"
            >
              <template-slot v-if="pane.$slots.label"  :data="pane.$slots.label"></template-slot>
              <template v-else>
                {{pane.label}}
              </template>
            </li>
          </ul>
        </scrollbar>
      </div>
    </transition>
  </div>
</template>

<script>
import { arrayFind, getParentByName} from '@utils/utils/util';
import Scrollbar from '../../scrollbar';
import TemplateSlot from './templateSlot.vue';
const parentName = 'TabNav';
export default {
  name: 'TabNavMore',
  components: { Scrollbar, TemplateSlot },
  props: {
    tabs: Array,
    havExtra: false
  },
  inject: ['rootTabs'],
  data() {
    return {
      show: false,
      list: []
    };
  },
  methods: {
    handleover(e) {
      const env = window.event || e;
      var s = env.fromElement || env.relatedTarget;
      if (document.all) {
        if (!this.contains(s)) {
          this.show = true;
        }
      } else {
        const _this = document.querySelector('.yxt-tabs__nav-popover');
        var reg = _this.compareDocumentPosition(s);
        if (!(reg === 20 || reg === 0)) {
          this.show = true;
        }
      }
    },
    handleout(e) {
      const env = window.event || e;
      var s = env.toElement || env.relatedTarget;
      if (document.all) {
        if (!this.contains(s)) {
          this.show = false;
        }
      } else {
        const _this = document.querySelector('.yxt-tabs__nav-popover');
        var reg = _this.compareDocumentPosition(s);
        if (!(reg === 20 || reg === 0)) {
          this.show = false;
        }
      }
    },
    // 计算可视区之外的tab列表
    calcList(containerSize, currentOffset) {
      let offset = 0;
      const sizeName = ['top', 'bottom'].indexOf(this.rootTabs.tabPosition) !== -1 ? 'width' : 'height';
      const firstUpperCase = str => {
        return str.toLowerCase().replace(/( |^)[a-z]/g, (L) => L.toUpperCase());
      };
      const list = [];
      this.tabs.every((tab, index) => {
        let $el = arrayFind(getParentByName(this, parentName).$refs.tabs || [], t => t.id.replace('tab-', '') === tab.paneName);
        if (!$el) { return false; }

        if (offset < currentOffset - (index === 0 ? 32 : 20)) {
          list.push(tab);
        }
        offset += $el[`client${firstUpperCase(sizeName)}`];
        if (offset > containerSize + currentOffset + (index === this.tabs.length - 1 ? 32 : 20)) {
          list.push(tab);
        }
        return true;
      });
      this.list = list;
    },
    clickMoreLi(pane, tabName, ev) {
      getParentByName(this, parentName).onTabClick(pane, tabName, ev);
      this.show = false;
    }
  }
};
</script>