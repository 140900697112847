<template>
  <div class="yxt-steps"
    :class="[
    (!(simple || type === 'simple')) && (!(basic || type === 'basic')) && (type !== 'navigation') && 'yxt-steps--' + direction,
    (simple || type == 'simple') && !(basic || type == 'basic') && 'yxt-steps--simple',
    (basic || type == 'basic') && !(simple || type == 'simple') && 'yxt-steps--basic', type == 'navigation' && 'yxt-steps--navigation']">
    <slot></slot>
  </div>
</template>

<script>
import Migrating from '@utils/mixins/migrating';

export default {
  name: 'YxtSteps',

  mixins: [Migrating],

  props: {
    space: [Number, String],
    active: Number,
    direction: {
      type: String,
      default: 'horizontal'
    },
    alignCenter: Boolean,
    simple: Boolean,
    type: {
      type: String,
      default: 'default'// simple,basic,navigation
    },
    size: {
      type: String,
      default: '' // medium,large
    },
    basic: Boolean,
    operate: Boolean,
    alloperate: Boolean,
    finishStatus: {
      type: String,
      default: 'finish'
    },
    processStatus: {
      type: String,
      default: 'process'
    },
    beforeClickStep: Function
  },

  data() {
    return {
      steps: [],
      stepOffset: 0,
      internalActive: this.active
    };
  },

  methods: {
    getMigratingConfig() {
      return {
        props: {
          'center': 'center is removed.'
        }
      };
    }
  },

  watch: {
    active(newVal, oldVal) {
      this.internalActive = newVal;
      this.$emit('change', newVal, oldVal);
    },
    internalActive(newVal, oldVal) {
      this.$emit('active-change', newVal, oldVal);
    },
    steps(steps) {
      steps.forEach((child, index) => {
        child.index = index;
      });
    }
  }
};
</script>
