<template>
  <div
    class="yxt-portrait"
    :style="style"
    :class="classes"
    @click="onClick"
  >
    <span class="yxt-portrait__content" v-if="((!imgUrl && imgDefault === 'chars') || isLoadErr)">
      {{ last }}
    </span>
    <YxtImg @error="onImgLoadError" :src="getFilteredImg(this.imgUrl)" fit="cover" v-else></YxtImg>
  </div>
</template>
<script>
import YxtImg from '../../image';
export default {
  name: 'YxtAvatar',
  components: {
    YxtImg
  },
  props: {
    imgDefault: { // chars: '字符头像'，anonymity: '匿名头像', group: '用户组'
      type: String,
      default: 'chars'
    },
    shape: { // 头像形状
      type: String,
      default: 'circle'
    },
    size: { // 头像尺寸
      type: String,
      default: 'medium'
    },
    isBorder: Boolean,
    backgroundColor: {
      type: String,
      default: '#436dff'
    },
    color: {
      type: String,
      default: '#fff'
    },
    imgUrl: {
      type: String,
      default: ''
    },
    username: {
      type: String,
      default: ''
    },
    targetHref: {
      type: String,
      default: ''
    },
    fontSize: {
      type: String,
      default: ''
    },
    bgType: { // blue: 蓝，green: 绿 orange: 橙色  purple: 紫色  gray: 灰色
      type: String,
      default: 'blue'
    },
    isSplit: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      isLoadErr: false,
      sizeList: ['great', 'size_100', 'size_48', 'big', 'large', 'medium', 'small', 'tiny', 'micro']
    };
  },
  computed: {
    last() {
      let text = this.username ? this.username.trim() : '';
      if (!text || typeof text !== 'string') {
        return null;
      } else {
        if (this.isSplit) {
          // const chPattern = new RegExp('[\u4E00-\u9FA5]+$'); // 中文
          const enPattern = new RegExp('[A-Za-z]+$'); // 英文
          if (enPattern.test(text)) {
            let nameArr = text.split(' ');
            let firstCode = '';
            nameArr.forEach(name => {
              firstCode += name.substr(0, 1);
            });
            return firstCode.toUpperCase();
          } else {
            return text.substr(text.length - 2, 2);
          }
        } else {
          return text;
        }
      }
    },
    style() {
      let style = {};

      const hasCustomSize = !this.sizeList.includes(this.size);
      if (hasCustomSize) {
        style.width = this.size;
        style.height = this.size;
        style.lineHeight = this.size;
        style.fontSize = this.fontSize ? this.fontSize : `${parseFloat(this.size) * 0.4}px`;
      }
      return style;
    },
    classes() {
      const size = this.sizeList.includes(this.size) ? this.size : 'medium';
      const shape = ['circle', 'square'].includes(this.shape) ? this.shape : 'circle';
      const borderClassName = this.isBorder ? `yxt-portrait--border-${this.getBorderWidth()}` : '';
      const background = this.imgDefault === 'chars' && (this.imgUrl === '' || this.imgUrl === null || this.imgUrl === undefined) ? `yxt-portrait--bg-${this.bgType}` : '';
      const classes = [`yxt-portrait--${size}`, `yxt-portrait--${shape}-${this.getBorderRadius()}`, `${borderClassName}`, `${background}`];
      return classes;
    }
  },
  methods: {
    getBorderRadius() {
      // 圆角大小：120px＞头像尺寸≥80px,圆角为6px；80px＞头像尺寸≥40px，圆角为4px；40px≥头像尺寸，圆角为2px
      const small = ['small', 'tiny', 'micro'];
      const medium = ['medium', 'large', 'size_48'];
      const large = ['big', 'great', 'size_100'];
      if (small.includes(this.size)) {
        return 'small';
      } else if (medium.includes(this.size)) {
        return 'medium';
      } else if (large.includes(this.size)) {
        return 'large';
      } else {
        return 'medium';
      }
    },
    getBorderWidth() {
      // 描边尺寸：描边尺寸：头像＞80时，建议使用3px；80≥头像＞60px时，建议使用2px的描边；头像＜60px时，建议使用1px的描边
      const small = ['medium', 'small', 'tiny', 'micro', 'size_48'];
      const medium = ['big', 'large'];
      const large = ['great', 'size_100'];
      if (small.includes(this.size)) {
        return 'small';
      } else if (medium.includes(this.size)) {
        return 'medium';
      } else if (large.includes(this.size)) {
        return 'large';
      } else {
        return 'medium';
      }
    },
    getFilteredImg(url) {
      if (url) {
        if (!!~url.indexOf('picobd') && !~url.indexOf('.gif')) {
          return `${url}?x-bce-process=image/resize,w_400/auto-orient,o_1`;
        } else {
          return url;
        }
      } else {
        const staticBaseUrl = (typeof window !== 'undefined' && window.feConfig && window.feConfig.common && window.feConfig.common.staticBaseUrl) || 'https://stc.yxt.com';
        if (this.imgDefault === 'anonymity') {
          return `${staticBaseUrl}/ufd/3f5568/common/pc_backstage/img/anonymity-default.png`;
        } else if (this.imgDefault === 'group') {
          return `${staticBaseUrl}/ufd/3f5568/common/pc_backstage/img/group-default.png`;
        } else {
          return '';
        }
      }
    },
    onClick(e) {
      if (this.targetHref) {
        window.location.href = this.targetHref;
        return;
      }
      this.$emit('click', e);
    },
    onImgLoadError() {
      this.isLoadErr = true;
    }
  },
  mounted() {
  }
};
</script>
