<template>
  <transition name="yxt-zoom-in-top" @after-enter="handleEnter" @after-leave="handleLeave">
    <div
      v-show="visible"
      class="yxt-picker-panel yxt-date-picker yxt-popper"
      :class="[{
        'has-sidebar': $slots.sidebar || shortcuts,
        'no-footer': !((footerVisible && currentView === 'date') || showTimePinker || currentView === 'date')
      }, popperClass]">
      <div class="yxt-picker-panel__body-wrapper">
        <slot name="sidebar" class="yxt-picker-panel__sidebar"></slot>
        <div class="yxt-picker-panel__sidebar" v-if="shortcuts">
          <button
            type="button"
            class="yxt-picker-panel__shortcut"
            v-for="(shortcut, key) in shortcuts"
            :key="key"
            @click="handleShortcutClick(shortcut)">{{ shortcut.text }}</button>
        </div>
        <div class="yxt-picker-panel__body">
          <div>
            <div
            class="yxt-date-picker__header"
            :class="{ 'yxt-date-picker__header--bordered': currentView === 'year' || currentView === 'month' || currentView === 'quarter' }"
            v-show="currentView !== 'time'">
            <button
              type="button"
              @click="prevYear"
              :aria-label="t(`pc_comp_datepicker_prevYear`)"
              class="yxt-picker-panel__icon-btn yxt-date-picker__prev-btn yxt-icon-d-arrow-left yxt-date-picker__left-side-btn">
            </button>
            <button
              type="button"
              @click="prevMonth"
              v-show="currentView === 'date'"
              :aria-label="t(`pc_comp_datepicker_prevMonth`)"
              class="yxt-picker-panel__icon-btn yxt-date-picker__prev-btn yxt-icon-arrow-left yxt-date-picker__left-sub-btn">
            </button>
            <div class="yxt-date-picker__header-label-area">
              <span
              @click="showYearPicker"
              role="button"
              class="yxt-date-picker__header-label">{{ yearLabel }}</span>
              <span
                @click="showMonthPicker"
                v-show="currentView === 'date'"
                role="button"
                class="yxt-date-picker__header-label"
                :class="{ active: currentView === 'month' }">{{t(`pc_comp_datepicker_month${ month + 1 }`)}}</span>
            </div>
            <button
              type="button"
              @click="nextMonth"
              v-show="currentView === 'date'"
              :aria-label="t(`pc_comp_datepicker_nextMonth`)"
              class="yxt-picker-panel__icon-btn yxt-date-picker__next-btn yxt-icon-arrow-right yxt-date-picker__right-sub-btn">
            </button>
            <button
              type="button"
              @click="nextYear"
              :aria-label="t(`pc_comp_datepicker_nextYear`)"
              class="yxt-picker-panel__icon-btn yxt-date-picker__next-btn yxt-icon-d-arrow-right yxt-date-picker__right-side-btn">
            </button>
          </div>
          <div class="yxt-picker-panel__content" :class="'yxt-picker-panel__' + currentView + '-content'">
            <date-table
              v-show="currentView === 'date'"
              @pick="handleDatePick"
              @placeholder="handlePlaceholder"
              :selection-mode="selectionMode"
              :first-day-of-week="firstDayOfWeek"
              :value="value"
              :default-value="defaultValue ? new Date(defaultValue) : null"
              :date="date"
              :cell-class-name="cellClassName"
              :disabled-date="disabledDate">
            </date-table>
            <year-table
              v-show="currentView === 'year'"
              @pick="handleYearPick"
              @placeholder="handlePlaceholder"
              :value="value"
              :default-value="defaultValue ? new Date(defaultValue) : null"
              :date="date"
              :disabled-date="disabledDate">
            </year-table>
            <month-table
              v-show="currentView === 'month'"
              @pick="handleMonthPick"
              @placeholder="handlePlaceholder"
              :value="value"
              :default-value="defaultValue ? new Date(defaultValue) : null"
              :date="date"
              :disabled-date="disabledDate">
            </month-table>
            <quarter-table
              v-show="currentView === 'quarter'"
              @pick="handleQuarterPick"
              :value="value"
              @placeholder="handlePlaceholder"
              :default-value="defaultValue ? new Date(defaultValue) : null"
              :date="date"
              :disabled-date="disabledDate">
            </quarter-table>
          </div>
          </div>
          <div class="yxt-date-picker__time-content" v-if="showTimePinker">
            <div class="yxt-date-picker__time-header">{{ date | time }}</div>
            <div>
              <time-spinner
                v-if="showTime"
                @placeholder="handlePlaceholder"
                ref="spinner"
                :date="date"
                @change="handleTimePick"
                :show-seconds="showSeconds" />
              <time-select
                v-if="showTimeSelect"
                :start="start"
                :end="end"
                :step="step"
                :value="value"
                @pick="handleTimePick"
              @placeholder="handlePlaceholder"
               />
            </div>
          </div>
        </div>
      </div>
      <div
        class="yxt-picker-panel__footer yxt-date-picker-panel__footer"
        v-show="((footerVisible && currentView === 'date') || showTimePinker || currentView ==='date')">
        <yxt-button
          size="small"
          type="text2"
          class="yxt-picker-panel__link-btn"
          v-show="selectionMode !== 'dates'"
          :class="{'mr8': currentView === 'date'}"
          @click="changeToNow">
          {{ currentView === 'date' ? t('pc_comp_datepicker_today') : t('pc_comp_datepicker_now') }}
        </yxt-button>
        <yxt-button
          type="primary"
          size="small"
          v-show="currentView !=='date' || selectionMode === 'dates'"
          class="yxt-picker-panel__link-btn"
          @click="confirm">
          {{ t('pc_comp_datepicker_confirm') }}
        </yxt-button>
      </div>
    </div>
  </transition>
</template>

<script type="text/babel">
  import {
    formatDate,
    parseDate,
    getWeekNumber,
    isDate,
    modifyDate,
    modifyTime,
    modifyWithTimeString,
    clearMilliseconds,
    clearTime,
    prevYear,
    nextYear,
    prevMonth,
    nextMonth,
    changeYearMonthAndClampDate,
    extractDateFormat,
    extractTimeFormat,
    timeWithinRange
  } from '@utils/utils/date-util';
  import Clickoutside from '@utils/utils/clickoutside';
  import Locale from '@utils/mixins/locale';
  import ElInput from '@backstage/input';
  import ElButton from '@backstage/button';
  import TimePicker from './time';
  import YearTable from '../basic/year-table';
  import MonthTable from '../basic/month-table';
  import DateTable from '../basic/date-table';
  import QuarterTable from '../basic/quarter-table';
  import TimeSpinner from '../basic/time-spinner';
  import TimeSelect from '../basic/time-select';

  export default {
    mixins: [Locale],

    directives: { Clickoutside },

    watch: {
      value(val) {
        if (this.selectionMode === 'dates' && this.value) return;
        if (isDate(val)) {
          this.date = new Date(val);
        } else {
          this.date = this.getDefaultValue();
        }
      },
      defaultValue(val) {
        if (!isDate(this.value)) {
          this.date = val ? new Date(val) : new Date();
        }
      },

      visible(val) {
        if (val) {
          this.showTime && this.$nextTick(() => {
            this.$refs['spinner'].adjustSpinners();
          });
        }
      },

      selectionMode(newVal) {
        if (newVal === 'month') {
          /* istanbul ignore next */
          if (this.currentView !== 'year' || this.currentView !== 'month') {
            this.currentView = 'month';
          }
        } else if (newVal === 'dates') {
          this.currentView = 'date';
        } else if (newVal === 'quarter') {
          if (this.currentView !== 'year') {
            this.currentView = 'quarter';
          }
        }
      }
    },

    methods: {
      proxyTimePickerDataProperties() {
        const format = timeFormat => {this.$refs.timepicker.format = timeFormat;};
        const value = value => {this.$refs.timepicker.value = value;};
        const date = date => {this.$refs.timepicker.date = date;};
        const selectableRange = selectableRange => {this.$refs.timepicker.selectableRange = selectableRange;};

        this.$watch('value', value);
        this.$watch('date', date);
        this.$watch('selectableRange', selectableRange);

        format(this.timeFormat);
        value(this.value);
        date(this.date);
        selectableRange(this.selectableRange);
      },

      handlePlaceholder(type, value) {
        let date = new Date(this.date);
        if (value === null) {
          date = null;
          this.isPlaceholder = false;
        } else {
          this.isPlaceholder = true;
          if (type === 'day') {
            date.setFullYear(value.getFullYear());
            date.setMonth(value.getMonth());
            date.setDate(value.getDate());
          } else if (type === 'time') {
            if (value[0] !== null) {
              date.setHours(value[0]);
            } else {
              date.setMinutes(value[1]);
            }
          } else if (type === 'year') {
            date.setFullYear(value);
          } else if (type === 'quarter') {
            date = new Date(value);
          }
        }
  
        this.$emit('placeholder', date);
      },

      handleTimeChange(date) {
        if (!date) return;
        this.date = new Date(this.date.getFullYear(), this.date.getMonth(), this.date.getDate(), date.getHours(), date.getMinutes());
      },

      handleClear() {
        this.date = this.getDefaultValue();
        this.$emit('pick', null);
      },

      emit(value, ...args) {
        if (!value) {
          this.$emit('pick', value, ...args);
        } else if (Array.isArray(value)) {
          const dates = value.map(date => this.showTime ? clearMilliseconds(date) : clearTime(date));
          this.$emit('pick', dates, ...args);
        } else {
          this.$emit('pick', this.showTime ? clearMilliseconds(value) : clearTime(value), ...args);
        }
        this.userInputDate = null;
        this.userInputTime = null;
      },

      // resetDate() {
      //   this.date = new Date(this.date);
      // },

      showMonthPicker() {
        this.currentView = 'month';
      },
  
      showYearPicker() {
        this.currentView = 'year';
      },

      // XXX: 没用到
      // handleLabelClick() {
      //   if (this.currentView === 'date') {
      //     this.showMonthPicker();
      //   } else if (this.currentView === 'month') {
      //     this.showYearPicker();
      //   }
      // },

      prevMonth() {
        // console.log('prevMonth 1 ======', this.date);
        this.date = prevMonth(this.date);
        // console.log('prevMonth 2 ======', this.date);
      },

      nextMonth() {
        this.date = nextMonth(this.date);
      },

      prevYear() {
        if (this.currentView === 'year') {
          this.date = prevYear(this.date, 10);
        } else {
          this.date = prevYear(this.date);
        }
      },

      nextYear() {
        if (this.currentView === 'year') {
          this.date = nextYear(this.date, 10);
        } else {
          this.date = nextYear(this.date);
        }
      },

      handleShortcutClick(shortcut) {
        if (shortcut.onClick) {
          shortcut.onClick(this);
        }
      },

      handleTimePick(value) {
        if (isDate(value)) {
          const newDate = this.value
            ? modifyTime(this.value, value.getHours(), value.getMinutes(), value.getSeconds())
            : modifyWithTimeString(this.getDefaultValue(), this.defaultTime);
          this.date = newDate;
          this.emit(this.date, true);
        } else {
          this.emit(value, true);
        }
      },

      handleMonthPick(month) {
        if (this.selectionMode === 'month') {
          this.date = modifyDate(this.date, this.year, month, 1);
          this.emit(this.date);
        } else {
          this.date = changeYearMonthAndClampDate(this.date, this.year, month);
          // TODO: should emit intermediate value ??
          // this.emit(this.date);
          this.currentView = 'date';
        }
      },

      handleQuarterPick(quarter) {
        this.date = modifyDate(this.date, this.year, quarter * 3, 1);
        this.emit(this.date);
      },

      handleDatePick(value) {
        if (this.selectionMode === 'day') {
          let newDate = this.value
            ? modifyDate(this.value, value.getFullYear(), value.getMonth(), value.getDate())
            : modifyWithTimeString(value, this.defaultTime);
          // change default time while out of selectableRange
          if (!this.checkDateWithinRange(newDate)) {
            newDate = modifyDate(this.selectableRange[0][0], value.getFullYear(), value.getMonth(), value.getDate());
          }
          this.date = newDate;
          this.emit(this.date, this.showTime);
        } else if (this.selectionMode === 'week') {
          this.emit(value.date);
        } else if (this.selectionMode === 'dates') {
          this.emit(value, true); // set false to keep panel open
        }
      },

      handleYearPick(year) {
        if (this.selectionMode === 'year') {
          this.date = modifyDate(this.date, year, 0, 1);
          this.emit(this.date);
        } else if (this.selectionMode === 'quarter') {
          this.date = changeYearMonthAndClampDate(this.date, year, this.quarter * 3);
          this.currentView = 'quarter';
        } else {
          this.date = changeYearMonthAndClampDate(this.date, year, this.month);
          // TODO: should emit intermediate value ??
          // this.emit(this.date, true);
          this.currentView = 'month';
        }
      },

      changeToNow() {
        // NOTE: not a permanent solution
        //       consider disable "now" button in the future
        if ((!this.disabledDate || !this.disabledDate(new Date())) && this.checkDateWithinRange(new Date())) {
          this.date = new Date();
          this.emit(this.date);
        }
      },

      confirm() {
        if (this.selectionMode === 'dates') {
          this.emit(this.value);
        } else {
          // value were emitted in handle{Date,Time}Pick, nothing to update here
          // deal with the scenario where: user opens the picker, then confirm without doing anything
          const value = this.value
            ? this.value
            : modifyWithTimeString(this.getDefaultValue(), this.defaultTime);
          this.date = new Date(value); // refresh date
          this.emit(value);
        }
      },

      resetView() {
        if (this.selectionMode === 'month') {
          this.currentView = 'month';
        } else if (this.selectionMode === 'quarter') {
          this.currentView = 'quarter';
        } else if (this.selectionMode === 'year') {
          this.currentView = 'year';
        } else {
          this.currentView = 'date';
        }
      },

      handleEnter() {
        // document.body.addEventListener('keydown', this.handleKeydown);
      },

      handleLeave() {
        this.$emit('dodestroy');
        // document.body.removeEventListener('keydown', this.handleKeydown);
      },

      handleKeydown(event) {
        const keyCode = event.keyCode;
        const list = [38, 40, 37, 39];
        if (this.visible && !this.timePickerVisible) {
          if (list.indexOf(keyCode) !== -1) {
            this.handleKeyControl(keyCode);
            event.stopPropagation();
            event.preventDefault();
          }
          if (keyCode === 13 && this.userInputDate === null && this.userInputTime === null) { // Enter
            this.emit(this.date, false);
          }
        }
      },

      handleKeyControl(keyCode) {
        const mapping = {
          'year': {
            38: -4, 40: 4, 37: -1, 39: 1, offset: (date, step) => date.setFullYear(date.getFullYear() + step)
          },
          'month': {
            38: -4, 40: 4, 37: -1, 39: 1, offset: (date, step) => date.setMonth(date.getMonth() + step)
          },
          'quarter': {
            38: -4, 40: 4, 37: -1, 39: 1, offset: (date, step) => date.setMonth(date.getMonth() + step * 3)
          },
          'week': {
            38: -1, 40: 1, 37: -1, 39: 1, offset: (date, step) => date.setDate(date.getDate() + step * 7)
          },
          'day': {
            38: -7, 40: 7, 37: -1, 39: 1, offset: (date, step) => date.setDate(date.getDate() + step)
          }
        };
        const mode = this.selectionMode;
        const year = 3.1536e10;
        const now = this.date.getTime();
        const newDate = new Date(this.date.getTime());
        while (Math.abs(now - newDate.getTime()) <= year) {
          const map = mapping[mode];
          map.offset(newDate, map[keyCode]);
          if (typeof this.disabledDate === 'function' && this.disabledDate(newDate)) {
            continue;
          }
          this.date = newDate;
          this.$emit('pick', newDate, true);
          break;
        }
      },

      handleVisibleTimeChange(value) {
        const time = parseDate(value, this.timeFormat);
        if (time && this.checkDateWithinRange(time)) {
          this.date = modifyDate(time, this.year, this.month, this.monthDate);
          this.userInputTime = null;
          this.$refs.timepicker.value = this.date;
          this.timePickerVisible = false;
          this.emit(this.date, true);
        }
      },

      // handleVisibleDateChange(value) {
      //   const date = parseDate(value, this.dateFormat);
      //   if (date) {
      //     if (typeof this.disabledDate === 'function' && this.disabledDate(date)) {
      //       return;
      //     }
      //     this.date = modifyTime(date, this.date.getHours(), this.date.getMinutes(), this.date.getSeconds());
      //     this.userInputDate = null;
      //     this.resetView();
      //     this.emit(this.date, true);
      //   }
      // },

      isValidValue(value) {
        return value && !isNaN(value) && (
          typeof this.disabledDate === 'function'
            ? !this.disabledDate(value)
            : true
        ) && this.checkDateWithinRange(value);
      },

      getDefaultValue() {
        // if default-value is set, return it
        // otherwise, return now (the moment this method gets called)
        return this.defaultValue ? new Date(this.defaultValue) : new Date();
      },

      checkDateWithinRange(date) {
        return this.selectableRange.length > 0
          ? timeWithinRange(date, this.selectableRange, this.format || 'HH:mm:ss')
          : true;
      }
    },

    components: {
      TimePicker, YearTable, MonthTable, QuarterTable, DateTable, ElInput, ElButton, TimeSpinner, TimeSelect
    },

    data() {
      return {
        popperClass: '',
        date: new Date(),
        value: '',
        type: '', // 当前类型
        defaultValue: null, // use getDefaultValue() for time computation
        defaultTime: null,
        showSeconds: false,
        showTime: false,
        showTimeSelect: false,
        selectionMode: 'day',
        shortcuts: '',
        visible: false,
        currentView: 'date',
        disabledDate: '',
        cellClassName: '',
        selectableRange: [],
        firstDayOfWeek: 7,
        showWeekNumber: false,
        timePickerVisible: false,
        format: '',
        arrowControl: false,
        userInputDate: null,
        userInputTime: null,
        showMomentBtn: false,
        isPlaceholder: false,
        start: '7:00',
        end: '21:00',
        step: '00:15'
      };
    },

    filters: {
      time(v) {
        if (!v) return '';
        return `${String(v.getHours()).padLeft(2, '0')}:${String(v.getMinutes()).padLeft(2, '0')}`;
      }
    },

    computed: {
      year() {
        return this.date.getFullYear();
      },

      month() {
        // console.log('computed month: ', this.date, this.date.getMonth());
        return this.date.getMonth();
      },

      quarter() {
        return Math.floor(this.date.getMonth() / 3);
      },

      week() {
        return getWeekNumber(this.date);
      },

      monthDate() {
        return this.date.getDate();
      },

      footerVisible() {
        return this.showTime || this.showTimeSelect || this.selectionMode === 'dates';
      },

      visibleDate() {
        if (this.userInputDate !== null) {
          return this.userInputDate;
        } else {
          return formatDate(this.value || this.defaultValue, this.dateFormat);
        }
      },

      yearLabel() {
        const yearTranslation = this.t('pc_comp_datepicker_year');
        if (this.currentView === 'year') {
          const startYear = Math.floor(this.year / 10) * 10;
          if (yearTranslation) {
            return startYear + ' ' + yearTranslation + ' - ' + (startYear + 11) + ' ' + yearTranslation;
          }
          return startYear + ' - ' + (startYear + 11);
        }
        return this.year + ' ' + yearTranslation;
      },

      timeFormat() {
        if (this.format) {
          return extractTimeFormat(this.format);
        } else {
          return 'HH:mm:ss';
        }
      },

      dateFormat() {
        if (this.format) {
          return extractDateFormat(this.format);
        } else {
          return 'yyyy-MM-dd';
        }
      },
      showTimePinker() {
        // 当type为datetime时，且当前视图为年或月时，不显示时间选择器
        if (this.type === 'datetime' && (this.currentView === 'year' || this.currentView === 'month')) {
          return false;
        }
        return this.showTime || this.showTimeSelect;
      }
    }
  };
</script>
