<template>
  <label
    class="yxtf-radio"
    :class="[
      border && radioSize ? 'yxtf-radio--' + radioSize : '',
      { 'is-disabled': isDisabled },
      { 'is-disabled-lock': isDisabled && isDisabled === 'lock' },
      { 'is-focus': focus },
      { 'is-bordered': border },
      { 'is-checked': model === label && changeTextColor },
      { 'is-checked-alone': model === label && !changeTextColor }
    ]"
    role="radio"
    :aria-checked="model === label"
    :aria-disabled="isDisabled"
    :tabindex="tabIndex"
    @keydown.space.stop.prevent="model = isDisabled ? model : label"
  >
    <span class="yxtf-radio__input"
      :class="{
        'is-disabled': isDisabled,
        'is-disabled-lock': isDisabled && isDisabled === 'lock' ,
        'is-checked': model === label && changeTextColor,
        'is-checked-alone': model === label && !changeTextColor
      }"
    >
      <span class="yxtf-radio__inner"></span>
      <input
        ref="radio"
        class="yxtf-radio__original"
        :value="label"
        type="radio"
        aria-hidden="true"
        v-model="model"
        @focus="focus = true"
        @blur="focus = false"
        @change="handleChange"
        :name="name"
        :disabled="isDisabled"
        tabindex="-1"
      >
    </span>
    <span class="yxtf-radio__label" @keydown.stop>
      <slot></slot>
      <template v-if="!$slots.default">{{label}}</template>
    </span>
  </label>
</template>
<script>
  import Emitter from '@utils/mixins/emitter';

  export default {
    name: 'YxtfRadio',

    mixins: [Emitter],

    inject: {
      yxtform: {
        default: ''
      },

      yxtformItem: {
        default: ''
      }
    },

    componentName: 'YxtfRadio',

    props: {
      value: {},
      label: {},
      disabled: {
        type: [Boolean, String],
        default: false
      },
      name: String,
      border: Boolean,
      size: String,
      changeTextColor: {
        type: [Number, Boolean],
        default: false
      }
    },

    data() {
      return {
        focus: false
      };
    },
    computed: {
      isGroup() {
        let parent = this.$parent;
        while (parent) {
          if (parent.$options.componentName !== 'YxtfRadioGroup') {
            parent = parent.$parent;
          } else {
            this._radioGroup = parent;
            return true;
          }
        }
        return false;
      },
      model: {
        get() {
          return this.isGroup ? this._radioGroup.value : this.value;
        },
        set(val) {
          if (this.isGroup) {
            this.dispatch('YxtfRadioGroup', 'input', [val]);
          } else {
            this.$emit('input', val);
          }
          this.$refs.radio && (this.$refs.radio.checked = this.model === this.label);
        }
      },
      _yxtformItemSize() {
        return (this.yxtformItem || {}).yxtformItemSize;
      },
      radioSize() {
        const temRadioSize = this.size || this._yxtformItemSize || (this.$ELEMENT || {}).size;
        return this.isGroup
          ? this._radioGroup.radioGroupSize || temRadioSize
          : temRadioSize;
      },
      isDisabled() {
        return this.isGroup
          ? this._radioGroup.disabled || this.disabled || (this.yxtform || {}).disabled
          : this.disabled || (this.yxtform || {}).disabled;
      },
      tabIndex() {
        return (this.isDisabled || (this.isGroup && this.model !== this.label)) ? -1 : 0;
      }
    },

    methods: {
      handleChange() {
        this.$nextTick(() => {
          this.$emit('change', this.model);
          this.isGroup && this.dispatch('YxtfRadioGroup', 'handleChange', this.model);
        });
      }
    }
  };
</script>
