<template>
  <div class="yxtf-table__title-custom-column" v-if="showPanel" @click="stopEvent">
    <div class="yxtf-table__custom-column-wrapper">
      <div class="yxtf-table__custom-column-header">
        <yxtf-checkbox
          :indeterminate="isIndeterminate"
          v-model="checkAll"
          @change="handleCheckAllChange"
          >{{t('pc_comp_table_clearFilter')}}</yxtf-checkbox>
      </div>
      <div class="yxtf-table__title-check-panel">
        <yxtf-scrollbar wrapClass="custom-column-scrollbar">
          <yxtf-checkbox-group
            v-model="checkedColumnsList"
            direction="row"
            @change="checkedColumnsChange">
            <div class="yxtf-table__check-item"
              v-for="item in columnsList"
              :key="item.label">
              <div class="yxtf-table__check-item-cont" v-if="item.label">
                <yxtf-checkbox
                  :disabled="item.disable ? 'lock' : false"
                  :label="item.label">
                  <span v-text="item.label"></span>
                  <yxtf-svg v-if="item.disable" class='color-gray-6 custom-column-icon-lock' width="16px" height="16px" icon-class="lock-on"/>
                </yxtf-checkbox>
              </div>
            </div>
          </yxtf-checkbox-group>
        </yxtf-scrollbar>
      </div>
      <div class="yxtf-table__custom-column-footer">
        <yxtf-button class="custom-btn-cancel" plain @click="hidePanel">{{t('pc_comp_table_cancel')}}</yxtf-button>
        <yxtf-button class="custom-btn-confirm" type="primary" @click="changeCheckedList">{{t('pc_comp_table_confirm')}}</yxtf-button>
      </div>
    </div>
  </div>
</template>

<script>
import Locale from '@utils/mixins/locale';

export default {
  mixins: [Locale],
  computed: {
    owner() {
      let parent = this.$parent;
      while (parent && !parent.tableId) {
        parent = parent.$parent;
      }
      return parent;
    },
    showPanel() {
      let parent = this.$parent;
      while (parent && !parent.tableId) {
        parent = parent.$parent;
      }
      return parent.store.states.showCustomColumnPanel;
    }
  },
  watch: {
    showPanel: {
      handler(val) {
        if (!val) {
          this.reset();
        } else {
          this.getCustomColumnList();
        }
      },
      deep: true,
      immediate: true
    }
  },
  mounted() {
    // 添加 点击body点击关闭面板的事件监听
    document.body.addEventListener('click', this.hideCustomPanelShow);
  },
  methods: {
    stopEvent(event) {
      event.stopPropagation();
    },
    // 初始化的时候获取自定义列列表
    getCustomColumnList() {
      let customColumns = {};
      this.columnsList = [];
      this.disabledList = [];
      this.checkedColumnsList = [];
      if (this.owner.customColumnProps && !this.owner.customColumnProps.showDiasbledAttributes) {
        Object.keys(this.owner.customColumns).forEach(key => {
          if (this.owner.customColumns[key].disable) {
            this.disabledList.push(this.owner.customColumns[key].label);
          } else {
            customColumns[key] = this.owner.customColumns[key];
          }
        });
      } else {
        customColumns = this.owner.customColumns;
      }

      for (let key in customColumns) {
        this.columnsList.push(customColumns[key]);
        if (!customColumns[key].hide) {
          this.checkedColumnsList.push(customColumns[key].label);
        }
      }
      if (this.columnsList.length === this.checkedColumnsList.length) {
        this.checkAll = true;
        this.isIndeterminate = false;
      }
    },
    // 选中列改变reload table
    checkedColumnsChange(value) {
      let checkedCount = value.length;
      this.checkAll = checkedCount === this.columnsList.length;
      this.isIndeterminate =
        checkedCount > 0 && checkedCount < this.columnsList.length;
    },
    // 处理全选状态改变
    handleCheckAllChange(val) {
      let checked = [];
      if (val) {
        this.columnsList.map((item) => {
          checked.push(item.label);
        });
      } else {
        this.columnsList.map((item) => {
          item.disable && checked.push(item.label);
        });
      }
      this.checkedColumnsList = checked;
      if (!checked.length) {
        this.checkAll = false;
      }
      this.isIndeterminate = false;
    },
    // 隐藏选择面板
    hidePanel() {
      this.owner.store.toggleCustomColumnPanelShow();
      this.reset();
    },
    // 更新选中的列到table上
    changeCheckedList() {
      this.owner.store.updateColumnsChecked(this.checkedColumnsList.concat(this.disabledList));
      this.owner.reload();
      this.hidePanel();
    },
    hideCustomPanelShow() {
      if (this.owner.store.states.showCustomColumnPanel) {
        this.hidePanel();
      }
    },
    reset() {
      this.data = {
        // 展示的列信息
        columnsList: [],
        // 自定义列显示的列表
        checkedColumnsList: [],
        // 全选状态
        checkAll: false,
        // 表示 checkbox 的不确定状态，用于实现全选的效果
        isIndeterminate: true,
        // 被禁用项的列表
        disabledList: []
      };
    }
  },
  data() {
    return {
      // 展示的列信息
      columnsList: [],
      // 自定义列显示的列表
      checkedColumnsList: [],
      // 全选状态
      checkAll: false,
      // 表示 checkbox 的不确定状态，用于实现全选的效果
      isIndeterminate: true,
      // 被禁用项的列表
      disabledList: []
    };
  },
  beforeDestroy() {
    // 取消 点击body点击关闭面板的事件监听
    document.body.removeEventListener('click', this.hideCustomPanelShow);
  }
};

</script>
