<script>
export default {
  name: 'TemplateSlot',
  props: {
    data: {
      type: Array
    }
  },
  render(h) {
    const {data} = this;
    return (
      <div>{data}</div>
    );
  }
};
</script>
