<template>
  <transition
    name="yxt-drawer-fade"
    @after-enter="afterEnter"
    @after-leave="afterLeave"
  >
    <div class="yxt-dialog__wrapper" role="presentation" v-show="visible">
      <div
        class="yxt-drawer__container"
        :class="visible && 'yxt-drawer__open'"
        @mousedown.self="handleWrapperClick"
        role="document"
        tabindex="-1"
      >
        <div
          aria-modal="true"
          aria-labelledby="yxt-drawer__title"
          class="yxt-drawer"
          :class="[direction, customClass]"
          :style="isHorizontal ? `width: ${size}` : `height: ${size}`"
          ref="drawer"
          role="presentation"
        >
          <header v-if="title || $slots.title" class="yxt-drawer__header" :class="{'yxt-drawer__noborder': !headerBorder}" id="yxt-drawer__title">
            <slot name="title">
              <button v-if="isBack" type="button" class="yxt-drawer__back-btn" @click="closeDrawer">
                <yxt-svg icon-class='arrow_left' class="color-gray-8 hover-bg-gay-3 hand" width='20px' height='20px'></yxt-svg>
              </button>
              <span role="heading" class="yxt-drawer--title">{{ title }}</span>
              <yxt-tooltip :effect="tooltipEffect " :content="labelTooltip" placement="bottom" v-if="labelTooltip" :max-width="labelTooltipWidth">
                <yxt-svg remote-url='' class='color-gray-6 hover-primary-6' width='20px' style="margin-left: -4px; margin-right: 4px; cursor: pointer;" height='20px' icon-class="question-cirlce-o" />
              </yxt-tooltip>
              <span v-if="describe" class="yxt-drawer--describe">{{ describe }}</span>
            </slot>
          </header>
          <button 
              :aria-label="`close ${title || 'drawer'}`"
              class="yxt-drawer__close-btn"
              type="button"
              v-if="showClose"
              @click="closeDrawer"
            >
            <yxt-svg icon-class='common_close' class="color-gray-8 hover-bg-gay-3 hand" width='24px' height='24px'></yxt-svg>
          </button>
          <scrollbar :fit-height='true' v-if='hasScroll'>
            <section class="yxt-drawer__body" v-if="rendered">
              <slot></slot>
            </section>
          </scrollbar>
           <section class="yxt-drawer__body" v-if="rendered && !hasScroll">
              <slot></slot>
            </section>
          <footer class="yxt-drawer_footer" v-if="$slots.footer" v-show="rendered">
            <slot name="footer"> </slot>
          </footer>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
import Popup from '@utils/utils/popup';
import Migrating from '@utils/mixins/migrating';
import MessageBox from '@backstage/message-box';
import emitter from '@utils/mixins/emitter';
import scrollbar from '../../scrollbar/index';
import { t } from '@utils/locale';
import Locale from '@utils/mixins/locale';

export default {
  name: 'YxtDrawer',
  components: {
    scrollbar
  },
  mixins: [Popup, emitter, Migrating, Locale],
  props: {
    appendToBody: {
      type: Boolean,
      default: true
    },
    modalAppendToBody: {
      type: Boolean,
      default: true
    },
    hasScroll: {
      default: false,
      type: Boolean
    },
    headerBorder: {
      default: true,
      type: Boolean
    },
    beforeClose: {
      type: Function
    },
    customClass: {
      type: String,
      default: ''
    },
    destroyOnClose: {
      type: Boolean,
      default: false
    },
    modal: {
      type: Boolean,
      default: true
    },
    direction: {
      type: String,
      default: 'rtl',
      validator(val) {
        return ['ltr', 'rtl', 'ttb', 'btt'].indexOf(val) !== -1;
      }
    },
    showClose: {
      type: Boolean,
      default: true
    },
    size: {
      type: String,
      default: '30%'
    },
    title: {
      type: String,
      default: ''
    },
    describe: { // 描述
      type: String,
      default: ''
    },
    isBack: Boolean, // 是否有返回按钮
    visible: {
      type: Boolean
    },
    wrapperClosable: {
      type: Boolean,
      default: true
    },
    closeOnPressEscape: {
      type: Boolean,
      default: false
    },
    MessageBoxDetail: {
      type: Object,
      default: () => {
        return {
          title: t('pc_comp_drawer_waive'),
          message: t('pc_comp_drawer_cancel_describe'),
          confirmButtonText: t('pc_comp_drawer_confirm'),
          cancelButtonText: t('pc_comp_drawer_cancel'),
          type: 'warning'
        };
      }
    },
    labelTooltip: String,
    labelTooltipWidth: {
      type: Number,
      default: 440
    },
    description: String,
    tooltipEffect: {
      type: String,
      default: 'dark'
    }
  },
  computed: {
    isHorizontal() {
      return this.direction === 'rtl' || this.direction === 'ltr';
    }
  },
  data() {
    return {
      closed: false
    };
  },
  watch: {
    visible: {
      handler(val) {
        if (val) {
          this.closed = false;
          this.$emit('open');
          if (this.appendToBody) {
            this.$nextTick(()=>{ // 此处使用这个可以等节点渲染后再获取节点
              document.body.appendChild(this.$el);
            });
          }
        } else {
          if (!this.closed) {
            if (this.destroyOnClose) {
              this.rendered = false;
            }
            this.$emit('close');
          }
        }
      },
      immediate: true
    }
  },
  methods: {
    afterEnter() {
      this.$emit('opened');
    },
    afterLeave() {
      this.$emit('closed');
    },
    defaultHide() {
      MessageBox.confirm(this.MessageBoxDetail.message || t('pc_comp_drawer_waive'), this.MessageBoxDetail.title || t('pc_comp_drawer_cancel_describe'), {
        confirmButtonText: this.MessageBoxDetail.confirmButtonText || t('pc_comp_drawer_confirm'),
        cancelButtonText: this.MessageBoxDetail.cancelButtonText || t('pc_comp_drawer_cancel'),
        type: this.MessageBoxDetail.type || 'warning'
      }).then(() => {
        this.hide(false);
      }).catch(() => {});
    },
    hide(cancel) {
      // if (cancel === true) {
      //   this.defaultHide(this.hide);
      // } else if (cancel !== false) {
      //   this.$emit('update:visible', false);
      //   this.$emit('close');
      //   if (this.destroyOnClose === true) {
      //     this.rendered = false;
      //   }
      //   this.closed = true;
      // }
      if (cancel) {
        this.defaultHide(this.hide);
      } else {
        this.$emit('update:visible', false);
        this.$emit('close');
        if (this.destroyOnClose === true) {
          this.rendered = false;
        }
        this.closed = true;
      }
    },
    handleWrapperClick() {
      if (this.wrapperClosable) {
        this.closeDrawer();
      }
    },
    closeDrawer() {
      if (this.beforeClose === undefined) {
        this.defaultHide();
      } else if (typeof this.beforeClose === 'function') {
        this.beforeClose(this.hide);
      } else {
        this.hide();
      }
    },
    handleClose() {
      this.closeDrawer();
    },
    // 动态设置高度调整
    _setHeight() {
      var vh = window.innerHeight * 0.01;
      document.documentElement.style.setProperty('--vh', vh + 'px');
    }
  },
  created() {
    window.addEventListener('resize', this._setHeight);
  },
  mounted() {
    if (this.visible) {
      this.rendered = true;
      this.open();
    }
  },
  beforeDestroy() {
    window.removeEventListener('resize', this._setHeight);
  },
  destroyed() {
    // if appendToBody is true, remove DOM node after destroy
    if (this.appendToBody && this.$el && this.$el.parentNode) {
      this.$el.parentNode.removeChild(this.$el);
    }
  }
};
</script>
