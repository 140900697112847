<template>
  <div class="yxt-color-predefine">
    <div class="yxt-color-predefine__colors" style="justify-content: space-between;">
      <div class="yxt-color-predefine__color-selector"
          style="margin: 0 3px 8px 3px;box-shadow: none;"
          v-for="(item, index) in rgbaColors"
          :class="{selected: item.selected, 'is-alpha': item._alpha < 100}"
          :key="colors[index]"
          @click="handleSelect(index)">
        <div class="default_color_block" :style="{'background-color': item.value, 'border-radius': '2px'}">
        </div>
      </div>
      <div v-for="i in  8 - ((rgbaColors.length) % 8)" :key="i" style="width: 20px;margin: 0 3px 8px 3px;"></div>
      <!-- <div class="yxt-color-predefine__color-selector plusbtn" :class="{ 'plusbtn-disable': colorExist }" @click="creatColor">
        <i class="yxt-icon-plus"></i>
      </div> -->
    </div>
  </div>
</template>

<script>
  import Color from '../color';

  export default {
    props: {
      colors: { type: Array, required: true },
      color: { required: true }
    },
    data() {
      return {
        rgbaColors: this.parseColors(this.colors, this.color),
        clickTimes: 0
      };
    },
    computed: {
      colorExist() {
        const colExist = this.colors.some((i) => {
          const ci = new Color();
          ci.enableAlpha = true;
          ci.format = 'rgba';
          ci.fromString(i);

          return JSON.stringify(ci.toRgba()) === JSON.stringify(this.color.toRgba());
        });
        return colExist;
      }
    },
    methods: {
      handleSelect(index) {
        // console.log('handleSelect -------');
        // 单击模拟双击操作
        this.clickTimes++;
        // 当点击次数为2
        if (this.clickTimes === 2) {
          // 记得清零
          this.clickTimes = 0;
          // 触发双击事件...
          this.$emit('removePredefineIndex', index);
        }

        let _this = this;
        setTimeout(function() {
          // 当点击次数为1
          if (_this.clickTimes === 1) {
            // 单击清零
            _this.clickTimes = 0;
            // 触发单击事件...
            _this.color.fromString(_this.colors[index]);
          }
        }, 200);
        // this.color.fromString(this.colors[index]);
      },
      parseColors(colors, color) {
        // console.log('parseColors ===== colors: ', colors, ' - color: ', color);
        return colors.map(value => {
          // console.log('parseColors ===== colors map value: ', value);
          const c = new Color();
          c.enableAlpha = true;
          c.format = 'rgba';
  
          c.fromString(value);
          c.selected = c.value === color.value;
          c.selected = JSON.stringify(c.toRgba()) === JSON.stringify(color.toRgba());
          // console.log('parseColors ===== colors map c.value: ', c.value, '---color.value: ', color.value);
          // console.log('parseColors ===== colors map c.value: ', c.value, '---c.selected: ', c.value === color.value);
          return c;
        });
      },
      creatColor() {
        if (this.colorExist) {
          return;
        }
        this.$emit('creatColor', this.color);
      }
    },
    watch: {
      '$parent.currentColor'(val) {
        const color = new Color();
        color.fromString(val);

        this.rgbaColors.forEach(item => {
          item.selected = color.compare(item);
        });
      },
      colors(newVal) {
        console.log('predefine colors watch =====');
        this.rgbaColors = this.parseColors(newVal, this.color);
      },
      color(newVal) {
        // console.log('predefine color watch =====');
        this.rgbaColors = this.parseColors(this.colors, newVal);
      }
    }
  };
</script>