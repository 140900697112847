<template>
  <div class="yxt-table__title-custom-column" :class="{
    'yxt-table__title-custom-column--popper': popper
  }" v-if="showPanel"
    @click="stopEvent">
    <div class="yxt-table__custom-column-wrapper"
      >
      <div class="yxt-table__custom-column-header">
        <div>{{t('pc_comp_table_columnCustomTip')}}</div>
        <yxt-checkbox
          :indeterminate="isIndeterminate"
          v-model="checkAll"
          @change="handleCheckAllChange"
          >{{t('pc_comp_table_clearFilter')}}</yxt-checkbox>
      </div>
      <div class="yxt-table__title-check-panel">
        <div><yxt-scrollbar wrapClass="custom-column-scrollbar">
          <yxt-checkbox-group
            v-model="checkedColumnsList"
            direction="row"
            ref="customColumnList"
            @change="checkedColumnsChange">
            <div class="yxt-table__check-item"
              v-for="(item,index) in columnsList"
              :draggable="!item.disableDrag"
              @dragstart.stop="(event) => handleDragStart(event, item, index)"
              @dragover.stop="(event) => handleDragOver(event, item, index)"
              @dragleave.stop="(event) => handleDragLeave(event, item, index)"
              @dragend.stop="(event) => handleDragEnd(event, item, index)"
              @drop.stop="(event) => handleDrop(event, item, index)"
              @mouseenter="(event) => handleEnter(event, item, index)"
              @mouseleave="(event) => handleLeave(event, item, index)"
              :key="item.label">
              <div v-if="item.label"
                class="yxt-table__check-item-cont"
                :class="{
                  'yxt-table__check-item-cont--draging': dragState.dragging
                }">
                <yxt-checkbox
                  :disabled="item.disable ? 'lock' : false"
                  :label="item.label"
                  class="table_yxt-checkbox__input"
                  @change="changeCheckedList">
                  <yxt-tooltip effect="dark" :open-filter="true" :content="item.label" placement="top">
                    <span v-text="item.label"></span>
                  </yxt-tooltip>
                </yxt-checkbox>
                <yxt-tooltip v-if="!item.disableDrag && !dragState.dragging && hoverIndex === index" :open-filter="true" class="item" effect="dark" :content="t('pc_comp_table_columnCustomDragTip')" placement="top">
                  <span class="custom-column-icon-drag color-gray-6 hover-primary-6"
                    @mousedown="(event) => handleCanDragStart(event, true)"
                    @mouseup="(event) => handleCanDragStart(event, false)"
                    >
                    <yxt-svg width="16px" height="16px" icon-class="sort"/>
                  </span>
                </yxt-tooltip>
              </div>
            </div>
          </yxt-checkbox-group>
        </yxt-scrollbar></div>
      </div>
      <!-- <div class="yxt-table__custom-column-footer">
        <yxt-button class="custom-btn-cancel" plain @click="hidePanel">{{t('pc_comp_table_cancel')}}</yxt-button>
        <yxt-button class="custom-btn-confirm" type="primary" @click="changeCheckedList">{{t('pc_comp_table_confirm')}}</yxt-button>
      </div> -->
    </div>
  </div>
</template>

<script>
import Locale from '@utils/mixins/locale';
import { addClass, removeClass } from '@utils/utils/dom';

export default {
  mixins: [Locale],
  data() {
    return {
      // 展示的列信息
      columnsList: [],
      // 自定义列显示的列表
      checkedColumnsList: [],
      columnNums: 0,
      // 全选状态
      checkAll: false,
      // 表示 checkbox 的不确定状态，用于实现全选的效果
      isIndeterminate: true,
      // 被禁用项的列表
      disabledList: [],
      dragState: {
        draggingNode: null,
        dropNode: null,
        draggingIndex: 0,
        dragging: false
      },
      hoverIndex: null,
      shortcutInfo: {
        orders: [],
        undone: false // 撤回了
      }
    };
  },
  props: {
    popper: {
      type: Boolean,
      default: false
    },
    show: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    owner() {
      let parent = this.$parent;
      while (parent && !parent.tableId) {
        parent = parent.$parent;
      }
      return parent;
    },
    showPanel() {
      if (this.show) {
        return true;
      }
      // console.log('show panel =====', this.checkedColumnsList);
      // // this.checkedColumnsChange(this.checkedColumnsList);
      let parent = this.$parent;
      while (parent && !parent.tableId) {
        parent = parent.$parent;
      }

      return parent.store.states.showCustomColumnPanel;
    },
    columnsOderMap() {
      let parent = this.$parent;
      while (parent && !parent.tableId) {
        parent = parent.$parent;
      }
      return parent.store.states.columnsOderMap;
    },
    cacheId() {
      let parent = this.$parent;
      while (parent && !parent.tableId) {
        parent = parent.$parent;
      }
      return parent.customColumnProps.cacheId;
    }
  },
  watch: {
    showPanel: {
      handler(val) {
        // console.log('watch show Panel =====', val);
        if (!val) {
          this.reset();
          document.removeEventListener('keydown', this.shortcut);
        } else {
          if (this.cacheId) {
            // 获取缓存
            const haveCache = this.getCacheObj();
            this.getCustomColumnList(haveCache);
            if (!haveCache) {
              this.setCacheObj();
            }
          } else {
            this.getCustomColumnList();
          }
          document.addEventListener('keydown', this.shortcut);
        }
      },
      deep: true,
      immediate: true
    }
  },
  mounted() {
    // 添加 点击body点击关闭面板的事件监听
    document.body.addEventListener('click', this.hideCustomPanelShow);
  },
  methods: {
    stopEvent(event) {
      event.stopPropagation();
    },
    setCacheObj() {
      if (!this.cacheId) return false;
      const cacheObj = {
        columnsOderMap: this.columnsOderMap,
        checkedColumnsList: this.checkedColumnsList
      };
      localStorage.setItem(`yxt-table-${this.cacheId}`, JSON.stringify(cacheObj));
    },
    getCacheObj() {
      if (!this.cacheId) return false;
      const customCacheStr = localStorage.getItem(`yxt-table-${this.cacheId}`);
      if (!customCacheStr) return false;
      const cacheObj = JSON.parse(customCacheStr);
      if (!cacheObj) return false;
      let parent = this.$parent;
      while (parent && !parent.tableId) {
        parent = parent.$parent;
      }
      parent.store.states.columnsOderMap = cacheObj.columnsOderMap; // 排序
      this.checkedColumnsList = cacheObj.checkedColumnsList; // 勾选
      return true;
    },
    // 初始化的时候获取自定义列列表
    getCustomColumnList(haveCache = false) {
      // console.log('getCustomColumnList +++++++++++');
      let customColumns = [];
      this.columnsList = [];
      this.disabledList = [];
      (!haveCache) && (this.checkedColumnsList = []);

      const orderedList = [];
      // 处理删除的
      for (let key in this.owner.customColumns) {
        const column = this.owner.customColumns[key];
        if (column.deleted) {
          for (let key2 in this.owner.customColumns) {
            const c = this.owner.customColumns[key2];
            if (c.index > column.index && !column.reseted && !column.deleted) {
              c.index = c.index - 1; // 删除的元素后的序号归位
            }
          }
        }
      }

      // 处理排序
      let index = 0;
      for (let key in this.owner.customColumns) {
        const column = this.owner.customColumns[key];
        column.reseted = true;
        if (!column.deleted) {
          let newIndex = column.index;
          if (this.columnsOderMap[newIndex] !== undefined) newIndex = this.columnsOderMap[newIndex];
          column.originIndex = newIndex;
          orderedList[newIndex] = column;

          index++;
        }
      }
      this.columnNums = index;
      // console.log(orderedList);
      // console.log(this.columnsOderMap);
      // console.log(this.owner.customColumns);
      // console.log(Object.values(this.owner.customColumns).map((c) => c.label));
      // console.log(orderedList.map((c) => c.label));

      // 处理禁用隐藏
      if (this.owner.customColumnProps && !this.owner.customColumnProps.showDiasbledAttributes) {
        orderedList.forEach((column, index) => {
          if (column.disable) {
            this.disabledList.push(column.label);
          } else {
            customColumns.push(column);
          }
        });
      } else {
        customColumns = orderedList;
      }

      customColumns.forEach((column, index) => {
        // label 为空的不显示
        if (!column.label && column.type === 'default') {
          return;
        }
        this.columnsList.push(column);
        if (!column.hide && !haveCache) {
          this.checkedColumnsList.push(column.label);
        }
      });

      let checkedCount = this.checkedColumnsList.length;
      this.isIndeterminate =
        checkedCount > 0 && checkedCount < this.columnsList.length;
      if (this.columnsList.length === this.checkedColumnsList.length) {
        this.checkAll = true;
        this.isIndeterminate = false;
      }

      this.owner.fixedColumns && this.owner.fixedColumns.forEach((oc) => {
        const cl = this.columnsList.find(c => oc.label === c.label);
        if (cl) cl.disableDrag = true;
      });
      this.owner.rightFixedColumns && this.owner.rightFixedColumns.forEach((oc) => {
        const cl = this.columnsList.find(c => oc.label === c.label);
        if (cl) cl.disableDrag = true;
      });
    },
    // 选中列改变reload table
    checkedColumnsChange(value) {
      // console.log('checkedColumnsChange +++++++++++', value);
      let checkedCount = value.length;
      this.checkAll = checkedCount === this.columnsList.length;
      this.isIndeterminate =
        checkedCount > 0 && checkedCount < this.columnsList.length;
    },
    // 处理全选状态改变
    handleCheckAllChange(val) {
      // console.log('handleCheckAllChange +++++++++++', val);
      let checked = [];
      if (val) {
        this.columnsList.map((item) => {
          checked.push(item.label);
        });
      } else {
        this.columnsList.map((item) => {
          item.disable && checked.push(item.label);
        });
      }
      this.checkedColumnsList = checked;
      if (!checked.length) {
        this.checkAll = false;
      }
      this.isIndeterminate = false;
      this.changeCheckedList();
    },
    // 隐藏选择面板
    hidePanel() {
      this.owner.store.toggleCustomColumnPanelShow();
      this.reset();
    },
    // 更新选中的列到table上
    changeCheckedList() {
      this.owner.store.updateColumnsChecked(this.checkedColumnsList.concat(this.disabledList));
      this.owner.reload();
      // 设置缓存
      this.setCacheObj();
      // this.hidePanel();
    },
    hideCustomPanelShow() {
      if (this.owner.store.states.showCustomColumnPanel) {
        this.hidePanel();
      }
    },
    reset() {
      // console.log('reset =====');
      this.data = {
        // 展示的列信息
        columnsList: [],
        // 自定义列显示的列表
        checkedColumnsList: [],
        // 全选状态
        checkAll: false,
        // 表示 checkbox 的不确定状态，用于实现全选的效果
        isIndeterminate: true,
        // 被禁用项的列表
        disabledList: []
      };
    },
    getColunmEL(index) {
      return this.$refs.customColumnList && this.$refs.customColumnList.$el && this.$refs.customColumnList.$el.children[index];
    },
    handleCanDragStart(event, isStart) {
      this.dragState.canDrag = isStart;
    },
    handleDragStart(event, column, index) {
      if (!this.dragState.canDrag) {
        event.preventDefault();
        return;
      }

      event.dataTransfer.effectAllowed = 'move';
      // wrap in try catch to address IE's error when first param is 'text/plain'
      try {
        // setData is required for draggable to work in FireFox
        // the content has to be '' so dragging a node out of the tree won't open a new tab in FireFox
        event.dataTransfer.setData('text/plain', '');
      } catch (e) { }
      // document.body.style.cursor = 'grabbing';

      const originIndex = column.originIndex;
      this.dragState.dragging = true;
      this.dragState.draggingNode = column;
      this.dragState.draggingIndex = originIndex;
      this.dragState.draggingShowIndex = index;
    },
    handleDragOver(event, column, index) {
      if (column.disableDrag) {
        document.body.style.cursor = 'no-drop';
        return;
      }
      document.body.style.cursor = 'grabbing';
      this.setDragRow(true, index);
      event.preventDefault();
    },
    handleDragLeave(event, column, index) {
      this.setDragRow(false, index);
    },
    handleDrop(event, column, index) {
      const originIndex = column.originIndex;
      this.setDragRow(false, index);
      if (this.dragState.draggingIndex !== originIndex) {
        this.moveColumn(this.dragState.draggingIndex, originIndex, column);
        // 记录历史
        this.shortcutInfo.orders = [this.dragState.draggingIndex, originIndex, column];
        this.shortcutInfo.redone = false;
      }
    },
    handleDragEnd(event, column, index) {
      this.dragState.canDrag = false;
      this.dragState.dragging = false;
      document.body.style.cursor = '';
      this.hoverIndex = null;
    },
    setDragRow(on, index) {
      if (this.dragState.draggingShowIndex === index) return index;
      if (this.dragState.draggingShowIndex > index && index > 0) index--;
      on ? addClass(this.getColunmEL(index), 'col-drag-over') : removeClass(this.getColunmEL(index), 'col-drag-over');
      return index;
    },
    moveColumn(oldIndex, newIndex, column) {
      this.owner.store.changeColumnOrder(oldIndex, newIndex, column, this.columnNums);
      this.owner.reload();
      this.getCustomColumnList();
      // 设置缓存
      this.setCacheObj();
    },
    shortcut(e) {
      if (!this.showPanel) return;
      if (e.keyCode === 90 && (e.ctrlKey || e.metaKey)) {
        if (e.shiftKey) {
          this.redo();
        } else {
          this.undo();
        }
      }
    },
    redo() {
      const orders = this.shortcutInfo.orders;
      if (this.shortcutInfo.undone && orders.length > 0) {
        this.moveColumn(orders[0], orders[1], orders[2]);
        this.shortcutInfo.undone = false;
      }
    },
    undo() {
      const orders = this.shortcutInfo.orders;
      if (!this.shortcutInfo.undone) {
        this.moveColumn(orders[1], orders[0], orders[2]);
        this.shortcutInfo.undone = true;
      }
    },
    handleEnter(event, column, index) {
      this.hoverIndex = index;
    },
    handleLeave(event, column, index) {
      this.hoverIndex = null;
    }
  },
  beforeDestroy() {
    // 取消 点击body点击关闭面板的事件监听
    document.body.removeEventListener('click', this.hideCustomPanelShow);
  }
};

</script>
