<template>
  <transition name="yxt-zoom-in-top" @after-leave="$emit('dodestroy')">
    <div
      v-show="visible"
      class="yxt-picker-panel yxt-date-range-picker yxt-popper no-footer"
      :class="[{
        'has-sidebar': $slots.sidebar || shortcuts
      }, popperClass]">
      <div class="yxt-picker-panel__body-wrapper">
        <slot name="sidebar" class="yxt-picker-panel__sidebar"></slot>
        <div class="yxt-picker-panel__sidebar" v-if="shortcuts">
          <button
            type="button"
            class="yxt-picker-panel__shortcut"
            v-for="(shortcut, key) in shortcuts"
            :key="key"
            @click="handleShortcutClick(shortcut)">{{shortcut.text}}</button>
        </div>
        <div class="yxt-picker-panel__body">
          <div class="yxt-picker-panel__content yxt-date-range-picker__content is-left">
            <div class="yxt-date-range-picker__header">
              <button
                type="button"
                @click="leftPrevYear"
                class="yxt-picker-panel__icon-btn yxt-icon-d-arrow-left yxt-date-picker__left-side-btn"></button>
              <button
                type="button"
                v-if="unlinkPanels"
                @click="leftNextYear"
                :disabled="!enableYearArrow"
                :class="{ 'is-disabled': !enableYearArrow }"
                class="yxt-picker-panel__icon-btn yxt-icon-d-arrow-right yxt-date-picker__right-side-btn"></button>
              <div>{{ leftLabel }}</div>
            </div>
            <year-table
              selection-mode="range"
              :date="leftDate"
              :default-value="defaultValue"
              :min-date="minDate"
              :max-date="maxDate"
              :range-state="rangeState"
              :disabled-date="disabledDate"
              @changerange="handleChangeRange"
              @pick="handleRangePick">
            </year-table>
          </div>
          <div class="yxt-picker-panel__content yxt-date-range-picker__content is-right">
            <div class="yxt-date-range-picker__header">
              <button
                type="button"
                v-if="unlinkPanels"
                @click="rightPrevYear"
                :disabled="!enableYearArrow"
                :class="{ 'is-disabled': !enableYearArrow }"
                class="yxt-picker-panel__icon-btn yxt-icon-d-arrow-left yxt-date-picker__left-side-btn"></button>
              <button
                type="button"
                @click="rightNextYear"
                class="yxt-picker-panel__icon-btn yxt-icon-d-arrow-right yxt-date-picker__right-side-btn"></button>
              <div>{{ rightLabel }}</div>
            </div>
            <year-table
              selection-mode="range"
              :date="rightDate"
              :default-value="defaultValue"
              :min-date="minDate"
              :max-date="maxDate"
              :range-state="rangeState"
              :disabled-date="disabledDate"
              @changerange="handleChangeRange"
              @pick="handleRangePick">
            </year-table>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script type="text/babel">
  import {
    isDate,
    modifyWithTimeString,
    nextMonth
  } from '@utils/utils/date-util';
  import Clickoutside from '@utils/utils/clickoutside';
  import Locale from '@utils/mixins/locale';
  import YearTable from '../basic/year-table';
  import ElInput from '@backstage/input';
  import ElButton from '@backstage/button';

  const calcDefaultValue = (defaultValue) => {
    if (Array.isArray(defaultValue)) {
      return [new Date(defaultValue[0]), new Date(defaultValue[1])];
    } else if (defaultValue) {
      return [new Date(defaultValue), nextMonth(new Date(defaultValue))];
    } else {
      return [new Date(), nextMonth(new Date())];
    }
  };

  const rightYear = function(date) {
    if (typeof date === 'number') {
      return date + 12;
    }
    if (date instanceof Date) {
      return date.getFullYear() + 12;
    }

    return new Date(date).getFullYear() + 12;
  };

  const leftYear = function(year) {
    return year - 12;
  };

  export default {
    mixins: [Locale],

    directives: { Clickoutside },

    computed: {
      btnDisabled() {
        return !(this.minDate && this.maxDate && !this.selecting && this.isValidValue([this.minDate, this.maxDate]));
      },

      leftLabel() {
        const yearTranslation = this.t('pc_comp_datepicker_year');
        const startYear = Math.floor(new Date(String(this.leftDate)).getFullYear() / 10) * 10;
        return startYear + ' ' + yearTranslation + ' - ' + (startYear + 11) + ' ' + yearTranslation;
      },

      rightLabel() {
        const yearTranslation = this.t('pc_comp_datepicker_year');
        const startYear = Math.floor(new Date(String(this.rightDate)).getFullYear() / 10) * 10;
        return startYear + ' ' + yearTranslation + ' - ' + (startYear + 11) + ' ' + yearTranslation;
      },

      leftYear() {
        return new Date(String(this.leftDate)).getFullYear();
      },

      rightYear() {
        return new Date(String(this.rightDate)).getFullYear() === new Date(String(this.leftDate)).getFullYear() ? new Date(String(this.leftDate)).getFullYear() + 1 : new Date(String(this.rightDate)).getFullYear();
      },

      enableYearArrow() {
        return true; // this.unlinkPanels && this.rightYear > this.leftYear + 1;
      }
    },

    data() {
      return {
        popperClass: '',
        value: [],
        defaultValue: null,
        defaultTime: null,
        minDate: '',
        maxDate: '',
        leftDate: new Date(),
        rightDate: rightYear(new Date()),
        rangeState: {
          endDate: null,
          selecting: false,
          row: null,
          column: null
        },
        shortcuts: '',
        visible: '',
        disabledDate: '',
        format: '',
        arrowControl: false,
        unlinkPanels: false
      };
    },

    watch: {
      value(newVal) {
        if (!newVal) {
          this.minDate = null;
          this.maxDate = null;
        } else if (Array.isArray(newVal)) {
          this.minDate = isDate(newVal[0]) ? newVal[0] : null;
          this.maxDate = isDate(newVal[1]) ? newVal[1] : null;
          if (this.minDate) {
            this.leftDate = this.minDate;
            this.rightDate = rightYear(this.leftDate);
          } else {
            this.leftDate = calcDefaultValue(this.defaultValue)[0];
            this.rightDate = rightYear(this.leftDate);
          }
        }
      },

      defaultValue(val) {
        if (!Array.isArray(this.value)) {
          const [left] = calcDefaultValue(val);
          this.leftDate = left;
          this.rightDate = rightYear(this.leftDate);
        }
      }
    },

    methods: {
      handleClear() {
        this.minDate = null;
        this.maxDate = null;
        this.leftDate = calcDefaultValue(this.defaultValue)[0];
        this.rightDate = rightYear(this.leftDate);
        this.$emit('pick', null);
      },
  
      handleChangeRange(val) {
        this.minDate = val.minDate;
        this.maxDate = val.maxDate;
        this.rangeState = val.rangeState;
      },

      handleRangePick(val, close = true) {
        const defaultTime = this.defaultTime || [];
        const minDate = modifyWithTimeString(val.minDate, defaultTime[0]);
        const maxDate = modifyWithTimeString(val.maxDate, defaultTime[1]);
        if (this.maxDate === maxDate && this.minDate === minDate) {
          return;
        }
        this.onPick && this.onPick(val);
        this.maxDate = maxDate;
        this.minDate = minDate;

        // workaround for https://github.com/ElemeFE/element/issues/7539, should remove this block when we don't have to care about Chromium 55 - 57
        setTimeout(() => {
          this.maxDate = maxDate;
          this.minDate = minDate;
        }, 10);
        if (!close) return;
        this.handleConfirm();
      },

      handleShortcutClick(shortcut) {
        if (shortcut.onClick) {
          shortcut.onClick(this);
        }
      },

      // leftPrev*, rightNext* need to take care of `unlinkPanels`
      leftPrevYear() {
        this.leftDate = this.leftDate - 10;
        this.rightDate = rightYear(this.leftDate);
      },

      rightNextYear() {
        this.leftDate = rightYear(this.leftDate);
        this.rightDate = rightYear(this.leftDate);
      },

      // leftNext*, rightPrev* are called when `unlinkPanels` is true
      leftNextYear() {
        this.leftDate = rightYear(this.leftDate);
      },

      rightPrevYear() {
        this.rightDate = leftYear(this.rightDate);
      },

      handleConfirm(visible = false) {
        if (this.isValidValue([this.minDate, this.maxDate])) {
          this.$emit('pick', [this.minDate, this.maxDate], visible);
        }
      },

      isValidValue(value) {
        return Array.isArray(value) &&
          value && value[0] && value[1] &&
          isDate(value[0]) && isDate(value[1]) && (
          typeof this.disabledDate === 'function'
            ? !this.disabledDate(value[0]) && !this.disabledDate(value[1])
            : true
        );
      },

      resetView() {
        // NOTE: this is a hack to reset {min, max}Date on picker open.
        // TODO: correct way of doing so is to refactor {min, max}Date to be dependent on value and internal selection state
        //       an alternative would be resetView whenever picker becomes visible, should also investigate date-panel's resetView
        this.minDate = this.value && isDate(this.value[0]) ? this.value[0] : null;
        this.maxDate = this.value && isDate(this.value[0]) ? this.value[1] : null;
      }
    },

    components: { YearTable, ElInput, ElButton }
  };
</script>
