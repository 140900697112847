<template>
  <li class="yxt-dropdown-group">
    <div v-if='title' class="yxt-dropdown-group-title">{{ title }}</div>
    <ul>
      <slot></slot>
    </ul>
  </li>
</template>
<script>
export default {
  name: 'YxtDropdownGroup',
  props: {
    title: {
      default: '',
      type: String
    }
  },

  methods: {

  }
};
</script>
