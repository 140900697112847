import Vue from 'vue';
import { isWindow } from './types';
import { easeInOutCubic } from './easings';
import { cAF, rAF } from './raf';

export default function scrollIntoView(container, selected) {
  if (Vue.prototype.$isServer) return;

  if (!selected) {
    container.scrollTop = 0;
    return;
  }

  const offsetParents = [];
  let pointer = selected.offsetParent;
  while (pointer && container !== pointer && container.contains(pointer)) {
    offsetParents.push(pointer);
    pointer = pointer.offsetParent;
  }
  const top = selected.offsetTop + offsetParents.reduce((prev, curr) => (prev + curr.offsetTop), 0);
  const bottom = top + selected.offsetHeight;
  const viewRectTop = container.scrollTop;
  const viewRectBottom = viewRectTop + container.clientHeight;

  if (top < viewRectTop) {
    container.scrollTop = top;
  } else if (bottom > viewRectBottom) {
    container.scrollTop = bottom - container.clientHeight;
  }
}

export function animateScrollTo(
  container,
  from,
  to,
  duration,
  callback = ()=>{}
) {
  const startTime = Date.now();

  let handle;
  const scroll = () => {
    const timestamp = Date.now();
    const time = timestamp - startTime;
    const nextScrollTop = easeInOutCubic(
      time > duration ? duration : time,
      from,
      to,
      duration
    );
    if (isWindow(container)) {
      container.scrollTo(window.pageXOffset, nextScrollTop);
    } else {
      container.scrollTop = nextScrollTop;
    }
    if (time < duration) {
      handle = rAF(scroll);
    } else if (typeof callback === 'function') {
      callback();
    }
  };

  scroll();

  return () => {
    handle && cAF(handle);
  };
}

export function getScrollElement(target, container) {
  if (isWindow(container)) {
    return target.ownerDocument.documentElement;
  }
  return container;
};

export const getScrollTop = (container) => {
  if (isWindow(container)) {
    return window.scrollY;
  }
  return container.scrollTop;
};
