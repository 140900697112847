var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "yxt-portrait",
      class: _vm.classes,
      style: _vm.style,
      on: { click: _vm.onClick },
    },
    [
      (!_vm.imgUrl && _vm.imgDefault === "chars") || _vm.isLoadErr
        ? _c("span", { staticClass: "yxt-portrait__content" }, [
            _vm._v("\n    " + _vm._s(_vm.last) + "\n  "),
          ])
        : _c("YxtImg", {
            attrs: { src: _vm.getFilteredImg(this.imgUrl), fit: "cover" },
            on: { error: _vm.onImgLoadError },
          }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }