import { walkTreeNode, getRowIdentity } from '../util';

export default {
  data() {
    return {
      states: {
        // defaultExpandAll 存在于 expand.js 中，这里不重复添加
        // 在展开行中，expandRowKeys 会被转化成 expandRows，expandRowKeys 这个属性只是记录了 TreeTable 行的展开
        // TODO: 拆分为独立的 TreeTable，统一用法
        expandRowKeys: [],
        treeList: [], // 筛选后数据
        originList: [], // 全量数据
        visibleTreeList: [], // 需要渲染的数据局
        treeData: {},
        indent: 16,
        lazy: false,
        lazyTreeNodeMap: {},
        lazyColumnIdentifier: 'hasChildren',
        childrenColumnName: 'children'
      }
    };
  },

  computed: {
    // 嵌入型的数据，watch 无法是检测到变化 https://github.com/ElemeFE/element/issues/14998
    // TODO: 使用 computed 解决该问题，是否会造成性能问题？
    // @return { id: { level, children } }
    normalizedData() {
      if (!this.states.rowKey) return {};
      const data = this.states.data || [];
      return this.normalize(data);
    },
    // @return { id: { children } }
    // 针对懒加载的情形，不处理嵌套数据
    normalizedLazyNode() {
      const { rowKey, lazyTreeNodeMap, lazyColumnIdentifier } = this.states;
      const keys = Object.keys(lazyTreeNodeMap);
      const res = {};
      if (!keys.length) return res;
      keys.forEach(key => {
        if (lazyTreeNodeMap[key].length) {
          const item = { children: [] };
          lazyTreeNodeMap[key].forEach(row => {
            const currentRowKey = getRowIdentity(row, rowKey);
            item.children.push(currentRowKey);
            if (row[lazyColumnIdentifier] && !res[currentRowKey]) {
              res[currentRowKey] = { children: [] };
            }
          });
          res[key] = item;
        }
      });
      return res;
    }
  },

  watch: {
    normalizedData: 'updateTreeData',
    normalizedLazyNode: 'updateTreeData'
  },

  methods: {
    normalize(data) {
      const {
        childrenColumnName,
        lazyColumnIdentifier,
        rowKey,
        lazy
      } = this.states;
      const res = {};
      walkTreeNode(
        data,
        (parent, children, level) => {
          const parentId = getRowIdentity(parent, rowKey);
          if (Array.isArray(children)) {
            res[parentId] = {
              children: children.map(row => getRowIdentity(row, rowKey)),
              level
            };
          } else if (lazy) {
            // 当 children 不存在且 lazy 为 true，该节点即为懒加载的节点
            res[parentId] = {
              children: [],
              lazy: true,
              level
            };
          }
        },
        childrenColumnName,
        lazyColumnIdentifier
      );
      return res;
    },

    /**
     * 将树形结构转换为列表结构
     * @param {Array} tree
     * @param {*} res
     * @returns
     */
    flatTree(tree, res = []) {
      if (!tree) return [];
      const { childrenColumnName } = this.states;
      for (let i = 0; i < tree.length; i++) {
        const item = tree[i];
        res.push(item);
        if (item[childrenColumnName]) {
          this.flatTree(item[childrenColumnName], res);
        }
      }
      return res;
    },

    toTreeArray(tree) {
      this.setLevels(tree);

      this.states.originList = this.flatTree(tree);
      this.states.treeList = [].concat(this.states.originList);

      this.getVisibleTreeList();
      return [].concat(this.states.visibleTreeList);
    },

    /**
     * 设置树层级
     * @param {Array} tree
     * @param {Number} level
     * @returns
     */
    setLevels(tree, level = 1) {
      if (!tree) return [];
      const { childrenColumnName, rowKey } = this.states;
      for (let i = 0; i < tree.length; i++) {
        const item = tree[i];
        // 配置时候为同级中的头尾元素
        if (i === 0) {
          item._first = true;
        } else if (i === tree.length - 1) {
          item._last = true;
        }

        if (i + 1 < tree.length) {
          item._nextId = tree[i + 1][rowKey];
        }

        if (i - 1 >= 0) {
          item._prevId = tree[i - 1][rowKey];
        }
        item._level = level;
        if (item[childrenColumnName] && item[childrenColumnName].length > 0) {
          item._hasChild = true;
        }
        if (item[childrenColumnName]) {
          this.setLevels(item[childrenColumnName], level + 1);
        }
      }
    },

    getVisibleTreeList(cols) {
      const { rowKey } = this.states;
      const expandKeys = this.states.expandKeys;
      const treeList = this.states.treeList;
      const visibleTreeList = [].concat(treeList);
      for (let i = 0; i < visibleTreeList.length;) {
        const item = visibleTreeList[i];
        const status = expandKeys.includes(item[rowKey]);
        item._expanded = status;
        if (item._level === 1) {
          item._display = true;
        }
        const endIndex = this.setExpandedStatus(
          visibleTreeList,
          item._level,
          i,
          status
        );
        if (endIndex > i && !status) {
          i = endIndex + 1;
        } else {
          i++;
        }
      }
      let list = visibleTreeList.filter(
        item => item._display !== false
      ).map((item, index) => {
        item._index = index;
        return item;
      });

      list = this.getSpanData(list, cols);

      this.states.visibleTreeList = list;
    },
    // 计算数据span间的关系
    getSpanData(list, cols) {
      // 获取数据中
      const fn = this.table.spanMethod;
      const { columns: statesColumns } = this.states;
      const columns = cols || statesColumns;
      if (typeof fn === 'function') {
        const currentMerged = new Array(columns.length).fill({});
        let renderRowIndex = new Array(columns.length).fill(null);
        let renderSpan = new Array(columns.length).fill(null);
        for (let i = 0; i < list.length; i++) {
          const row = list[i];
          columns.forEach((column, columnIndex) => {
            const { rowspan, colspan } = this.getSpan(row, column, columnIndex, i, fn);
            if (rowspan > 5) {
              // 当前为纵向合并单元格，则需要处理
              currentMerged[columnIndex] = {rowspan, colspan};
              if (rowspan % 2 === 0) {
                // 跨度为偶数，取中间两行
                renderSpan[columnIndex] = [i + rowspan / 2 - 1, i + rowspan / 2];
              } else {
                // 跨度为奇数，取中间三行
                renderSpan[columnIndex] = [i + Math.floor(rowspan / 2) - 1, i + Math.floor(rowspan / 2) + 1];
              }
              row[`row-span-${columnIndex}`] = renderSpan[columnIndex];
              row[`column-${columnIndex}`] = currentMerged[columnIndex];
              renderRowIndex[columnIndex] = row._index;
              row[`renderRow-${columnIndex}`] = renderRowIndex[columnIndex];
            } else if (rowspan === 0) {
              // 当前为被合并的纵向单元格，记录初始合并的行
              row[`column-${columnIndex}`] = currentMerged[columnIndex];
              row[`renderRow-${columnIndex}`] = renderRowIndex[columnIndex];
              renderSpan[columnIndex] && (row[`row-span-${columnIndex}`] = renderSpan[columnIndex]);
            } else {
              currentMerged[columnIndex] = null;
              row[`row-span-${columnIndex}`] = null;
              renderRowIndex[columnIndex] = null;
              renderSpan[columnIndex] = null;
              row[`column-${columnIndex}`] = null;
              // row[`mergeId-${columnIndex}`] = null;
              row[`renderRow-${columnIndex}`] = null;
            }
          });
        }
      }

      return list;
    },

    getSpan(row, column, columnIndex, rowIndex, fn) {
      let rowspan = 1;
      let colspan = 1;
      const result = fn({
        row,
        column,
        rowIndex,
        columnIndex
      });
      if (Array.isArray(result)) {
        rowspan = result[0];
        colspan = result[1];
      } else if (typeof result === 'object') {
        rowspan = result.rowspan;
        colspan = result.colspan;
      }
      return {
        colspan,
        rowspan
      };
    },
    setExpandedStatus(list, level, index, status) {
      if (list.length <= index + 1) return;
      let i = index + 1;
      let endIndex = index;
      while (i < list.length && list[i]._level > level) {
        if (!status) {
          // 标记节点为隐藏
          list[i]._display = false;
          endIndex = i;
        } else {
          const tmp = list[i];
          if (tmp._level === level + 1) {
            // 展开第一级子元素
            tmp._display = true;
          }
          this.setExpandedStatus(
            list,
            tmp._level,
            i,
            tmp._expanded && tmp._display
          );
        }
        i++;
      }

      return endIndex;
    },

    /**
     * 按关键字搜索数据，并保留层级关系
     * @param {*} prop
     * @param {*} keyword
     */
    filterTreeList(prop, keyword) {
      const keyValue = keyword ? keyword.trim() : '';
      if (!keyValue) {
        this.states.treeList = [].concat(this.states.originList);
      } else {
        const result = [];
        for (let i = 0; i < this.states.originList.length; i++) {
          const item = this.states.originList[i];
          if (this.isMatched(i, prop, item._level, keyValue)) {
            item._display = true;
            this.toggleItemExpansion(item, true);
            result.push(item);
          }
        }
        this.states.treeList = result;
      }
      this.getVisibleTreeList();
    },

    isMatched(index, prop, level, keyword) {
      let flag = false;
      for (let i = index; i < this.states.originList.length; i++) {
        const item = this.states.originList[i];
        if (item._level <= level && index !== i) {
          break;
        }
        if (item[prop].indexOf(keyword) !== -1) {
          flag = true;
          break;
        }
      }

      return flag;
    },

    updateTreeData() {
      const nested = this.normalizedData;
      const normalizedLazyNode = this.normalizedLazyNode;
      const keys = Object.keys(nested);
      const newTreeData = {};
      if (keys.length) {
        const {
          treeData: oldTreeData,
          defaultExpandAll,
          expandRowKeys,
          lazy
        } = this.states;
        const rootLazyRowKeys = [];
        const getExpanded = (oldValue, key) => {
          const included =
            defaultExpandAll ||
            (expandRowKeys && expandRowKeys.indexOf(key) !== -1);
          return !!((oldValue && oldValue.expanded) || included);
        };
        // 合并 expanded 与 display，确保数据刷新后，状态不变
        keys.forEach(key => {
          const oldValue = oldTreeData[key];
          const newValue = { ...nested[key] };
          newValue.expanded = getExpanded(oldValue, key);
          if (newValue.lazy) {
            const { loaded = false, loading = false } = oldValue || {};
            newValue.loaded = !!loaded;
            newValue.loading = !!loading;
            rootLazyRowKeys.push(key);
          }
          newTreeData[key] = newValue;
        });
        // 根据懒加载数据更新 treeData
        const lazyKeys = Object.keys(normalizedLazyNode);
        if (lazy && lazyKeys.length && rootLazyRowKeys.length) {
          lazyKeys.forEach(key => {
            const oldValue = oldTreeData[key];
            const lazyNodeChildren = normalizedLazyNode[key].children;
            if (rootLazyRowKeys.indexOf(key) !== -1) {
              // 懒加载的 root 节点，更新一下原有的数据，原来的 children 一定是空数组
              if (newTreeData[key].children.length !== 0) {
                throw new Error('[ElTable]children must be an empty array.');
              }
              newTreeData[key].children = lazyNodeChildren;
            } else {
              const { loaded = false, loading = false } = oldValue || {};
              newTreeData[key] = {
                lazy: true,
                loaded: !!loaded,
                loading: !!loading,
                expanded: getExpanded(oldValue, key),
                children: lazyNodeChildren,
                level: ''
              };
            }
          });
        }
      }
      this.states.treeData = newTreeData;
      this.updateTableScrollY();
    },

    updateTreeExpandKeys(value) {
      this.states.expandRowKeys = value;
      this.updateTreeData();
    },

    toggleTreeExpansion(row, expanded) {
      this.assertRowKey();
      const oldExpanded = row._expanded;
      expanded = typeof expanded === 'undefined' ? !oldExpanded : expanded;
      if (oldExpanded !== expanded) {
        this.toggleRowExpansion(row);
        this.getVisibleTreeList();
      }
      this.updateTableScrollY();
    },

    toggleItemExpansion(item, expanded) {
      this.assertRowKey();
      const oldExpanded = item._expanded;
      expanded = typeof expanded === 'undefined' ? !oldExpanded : expanded;
      if (oldExpanded !== expanded) {
        this.toggleRowExpansion(item);
      }
    },

    loadOrToggle(row) {
      this.assertRowKey();
      // const { treeData, rowKey } = this.states;
      // const id = getRowIdentity(row, rowKey);
      // const data = treeData[id];
      // if (lazy && data && 'loaded' in data && !data.loaded) {
      //   this.loadData(row, id, data);
      // } else {
      //   this.toggleTreeExpansion(row);
      // }
      this.toggleTreeExpansion(row);
    },

    loadData(row, key, treeNode) {
      const { load } = this.table;
      const { lazyTreeNodeMap, treeData } = this.states;
      if (load && !treeData[key].loaded) {
        treeData[key].loading = true;
        load(row, treeNode, data => {
          if (!Array.isArray(data)) {
            throw new Error('[ElTable] data must be an array');
          }
          treeData[key].loading = false;
          treeData[key].loaded = true;
          treeData[key].expanded = true;
          if (data.length) {
            this.$set(lazyTreeNodeMap, key, data);
          }
          this.table.$emit('expand-change', row, true);
        });
      }
    }
  }
};
