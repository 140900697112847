var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "label",
    {
      staticClass: "yxt-radio-card",
      class: [
        { "is-active": _vm.value === _vm.label },
        { "is-disabled": _vm.isDisabled },
        { "is-adisabled": _vm.isDisabled && _vm.value === _vm.label },
      ],
      attrs: {
        role: "radio",
        "aria-checked": _vm.value === _vm.label,
        "aria-disabled": _vm.isDisabled,
        tabindex: _vm.tabIndex,
      },
      on: {
        keydown: function ($event) {
          if (
            !("button" in $event) &&
            _vm._k($event.keyCode, "space", 32, $event.key, [" ", "Spacebar"])
          ) {
            return null
          }
          $event.stopPropagation()
          $event.preventDefault()
          _vm.value = _vm.isDisabled ? _vm.value : _vm.label
        },
      },
    },
    [
      _c("input", {
        directives: [
          {
            name: "model",
            rawName: "v-model",
            value: _vm.value,
            expression: "value",
          },
        ],
        staticClass: "yxt-radio-card__orig-radio",
        attrs: {
          type: "radio",
          name: _vm.name,
          disabled: _vm.isDisabled,
          tabindex: "-1",
        },
        domProps: { value: _vm.label, checked: _vm._q(_vm.value, _vm.label) },
        on: {
          change: [
            function ($event) {
              _vm.value = _vm.label
            },
            _vm.handleChange,
          ],
          focus: function ($event) {
            _vm.focus = true
          },
          blur: function ($event) {
            _vm.focus = false
          },
        },
      }),
      _c("div", { staticClass: "yxt-radio-card__content" }, [
        _vm.$slots.avatar || _vm.avatar
          ? _c(
              "div",
              { staticClass: "yxt-radio-card__avatar" },
              [_vm._t("avatar", [_vm._v(_vm._s(_vm.avatar))])],
              2
            )
          : _vm._e(),
        _c("div", { staticClass: "yxt-radio-card__detail" }, [
          _vm.title || _vm.$slots.extra || _vm.extra
            ? _c(
                "div",
                { staticClass: "yxt-radio-card__header" },
                [
                  _vm.showTitleTooltip
                    ? _c(
                        "yxt-tooltip",
                        {
                          attrs: {
                            "open-filter": true,
                            content: _vm.title,
                            placement: "top",
                          },
                        },
                        [
                          _c("span", { staticClass: "yxt-radio-card__title" }, [
                            _vm._v(_vm._s(_vm.title)),
                          ]),
                        ]
                      )
                    : _c("span", { staticClass: "yxt-radio-card__title" }, [
                        _vm._v(_vm._s(_vm.title)),
                      ]),
                  _vm.$slots.extra
                    ? [
                        _vm._t("extra", [
                          _c(
                            "span",
                            { staticClass: "yxt-radio-card__extraIcon" },
                            [_vm._v(_vm._s(_vm.extra))]
                          ),
                        ]),
                      ]
                    : _vm.extra
                    ? [
                        _c("span", { staticClass: "yxt-radio-card__extra" }, [
                          _vm._v(_vm._s(_vm.extra)),
                        ]),
                      ]
                    : _vm._e(),
                ],
                2
              )
            : _vm._e(),
          _vm.description
            ? _c("span", { staticClass: "yxt-radio-card__description" }, [
                _vm._v(_vm._s(_vm.description)),
              ])
            : _vm._e(),
        ]),
      ]),
      _c(
        "span",
        {
          staticClass: "yxt-radio-card__iconCorner",
          class: [
            { "is-active": _vm.value === _vm.label },
            { "is-disabled": _vm.isDisabled },
            { "is-adisabled": _vm.isDisabled && _vm.value === _vm.label },
          ],
        },
        [
          _c(
            "svg",
            {
              staticClass: "yxt-svg-icon ml4 hand",
              staticStyle: {
                display: "inline-block",
                width: "16px",
                height: "16px",
              },
              attrs: {
                "aria-hidden": "true",
                xmlns: "http://www.w3.org/2000/svg",
                width: "16",
                height: "16",
                viewBox: "0 0 16 16",
              },
            },
            [
              _c(
                "g",
                { attrs: { fill: "currentColor", "fill-rule": "evenodd" } },
                [
                  _c("path", {
                    attrs: {
                      d: "M16 0v13a3 3 0 0 1-3 3H0L16 0z",
                      fill: "currentColor",
                      "fill-rule": "evenodd",
                    },
                  }),
                  _c("path", {
                    attrs: {
                      fill: "#FFF",
                      "fill-rule": "nonzero",
                      d: "M9.695 12.868l3.587-4.017.613.557-4.138 4.634-2.76-2.506.552-.618z",
                    },
                  }),
                ]
              ),
            ]
          ),
        ]
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }