var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "yxt-color-predefine" }, [
    _c(
      "div",
      {
        staticClass: "yxt-color-predefine__colors",
        staticStyle: { "justify-content": "space-between" },
      },
      [
        _vm._l(_vm.rgbaColors, function (item, index) {
          return _c(
            "div",
            {
              key: _vm.colors[index],
              staticClass: "yxt-color-predefine__color-selector",
              class: { selected: item.selected, "is-alpha": item._alpha < 100 },
              staticStyle: { margin: "0 3px 8px 3px", "box-shadow": "none" },
              on: {
                click: function ($event) {
                  _vm.handleSelect(index)
                },
              },
            },
            [
              _c("div", {
                staticClass: "default_color_block",
                style: {
                  "background-color": item.value,
                  "border-radius": "2px",
                },
              }),
            ]
          )
        }),
        _vm._l(8 - (_vm.rgbaColors.length % 8), function (i) {
          return _c("div", {
            key: i,
            staticStyle: { width: "20px", margin: "0 3px 8px 3px" },
          })
        }),
      ],
      2
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }