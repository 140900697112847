<!--
 * @FileDescription: 
 * @Author: Sylpheed
 * @Date: 2021-11-22 14:54:04
 * @LastEditors: Sylpheed
 * @LastEditTime: 2021-11-22 14:54:24
-->
<template>
<!-- 兼容ie -->
  <div v-if='root' :style="layoutStyle" class="yxt-layout__root">
    <div :style="layoutStyle" :class="layoutClass" class='yxt-layout'>
      <slot></slot>
    </div>
  </div>
  <div v-else :style="layoutStyle" :class="layoutClass" class='yxt-layout'>
    <slot></slot>
  </div>
</template>

<script>
import bus from './bus';
export default {
  name: 'YxtLayout',
  props: {
    autoFit: {
      default: false,
      type: Boolean
    },
    root: {
      default: false,
      type: Boolean
    }
  },
  data() {
    return {
      rootWidth: '100%'
    };
  },
  computed: {
    layoutClass() {
      let classList = [];
      if (this.autoFit) {
        classList.push('yxt-layout--auto'); // 宽度自适应
      }
      return classList.join(' ');
    },
    layoutStyle() {
      return this.root ? {
        width: this.rootWidth
      } : {};
    }
  },
  mounted() {
    if (this.root) {
      // document.querySelector('html').style.backgroundColor = window.getComputedStyle(this.$el)['background-color'];
      bus.$on('calc-root', this.updateWidth);
    }

    // bus.$on('changeSize', () => {
    //   this.updateWidth(true);
    // });
  },
  destroyed() {
    bus.$off();
  },
  methods: {
    updateWidth() {
      this.rootWidth = '100%';
      // if (this.autoFit) return;
      if (document.body.scrollWidth === document.body.clientWidth) return;

      let w = document.body.scrollWidth;
      this.rootWidth = `${w}px`;
      console.log(w);
      this.$nextTick(() => {
        let wt = document.body.scrollWidth;
        console.log('width', wt);
        this.rootWidth = `${wt}px`;
      });
    }
  }
};
</script>

