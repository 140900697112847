<template>
  <yxtf-popover
    v-bind="$attrs"
    v-model="visible"
    trigger="click"
    popper-class="yxt-popconfirm__offset"
  >
  <div class="yxtf-popconfirm">
    <div class="yxtf-popconfirm__main">
    <i
      v-if="!hideIcon"
      :class="icon"
      class="yxtf-popconfirm__icon"
      :style="{color: iconColor}"
    ></i>
      <div v-if="title">{{title}}</div>
      
    </div>
    <div class="yxtf-popconfirm__content" v-if="content">{{content}}</div>
    <div class="yxtf-popconfirm__action">
      <yxtf-checkbox v-if="showTip" @change="change">不再提示</yxtf-checkbox>
      <yxtf-button 
        size="small" 
        :type="cancelButtonType" 
        @click="cancel"
      >
        {{ displayCancelButtonText }}
      </yxtf-button>
      <yxtf-button 
        size="small" 
        :type="confirmButtonType" 
        @click="confirm"
      >
        {{ displayConfirmButtonText }}
      </yxtf-button>
    </div>
  </div>
  <slot name="reference" slot="reference"></slot>
</yxtf-popover>
</template>

<script>
import YxtfPopover from '@foreground/popover';
import YxtfButton from '@foreground/button';
import {t} from '@utils/locale';
export default {
  name: 'YxtfPopconfirm',
  props: {
    title: {
      type: String
    },
    content: {
      type: String
    },
    confirmButtonText: {
      type: String
    },
    cancelButtonText: {
      type: String
    },
    confirmButtonType: {
      type: String,
      default: 'primary'
    },
    cancelButtonType: {
      type: String,
      default: ''
    },
    icon: {
      type: String,
      default: 'yxtf-icon-question'
    },
    iconColor: {
      type: String,
      default: '#f90'
    },
    hideIcon: {
      type: Boolean,
      default: false
    },
    showTip: {
      type: Boolean,
      default: false
    }
  },
  components: {
    YxtfPopover,
    YxtfButton
  },
  data() {
    return {
      visible: false
    };
  },
  computed: {
    displayConfirmButtonText() {
      return this.confirmButtonText || t('pc_comp_popconfirm_confirmButtonText');
    },
    displayCancelButtonText() {
      return this.cancelButtonText || t('pc_comp_popconfirm_cancelButtonText');
    }
  },
  methods: {
    confirm() {
      this.visible = false;
      this.$emit('confirm');
    },
    cancel() {
      this.visible = false;
      this.$emit('cancel');
    },
    change(val) {
      this.$emit('change', val);
    }
  }
};
</script>