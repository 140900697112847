<template>
  <aside class="yxt-aside" :style="{ width }">
    <slot></slot>
  </aside>
</template>

<script>
  export default {
    name: 'YxtfAside',

    componentName: 'YxtfAside',

    props: {
      width: {
        type: String,
        default: '300px'
      }
    }
  };
</script>
