<script>
  export default {
    name: 'YxtfTag',
    props: {
      text: String,
      closable: Boolean,
      type: String,
      coverType: String,
      hit: Boolean,
      disableTransitions: Boolean,
      color: String,
      size: String,
      showIcon: {
        // effect为'select'时，此值为true: 表示tag被选中，否则为未选中
        type: Boolean,
        default: false
      },
      selected: {
        // effect为'selectNormal'时，此值为true: 表示tag被选中，否则为未选中
        type: Boolean,
        default: false
      },
      disable: {
        type: Boolean,
        default: false
      },
      effect: {
        type: String,
        default: 'light',
        validator(val) {
          return ['special', 'point', 'select', 'selectNormal', 'dark', 'light', 'plain', 'free'].indexOf(val) !== -1;
        }
      }
    },
    methods: {
      handleClose(event) {
        event.stopPropagation();
        this.$emit('close', event);
      },
      handleClick(event) {
        this.$emit('click', event);
      }
    },
    computed: {
      tagSize() {
        return this.size || (this.$ELEMENT || {}).size;
      }
    },
    render(h) {
      const { type, coverType, tagSize, hit, effect, closable, showIcon, selected, disable } = this;
      const classes = [
        'yxtf-tag',
        type ? `yxtf-tag--${type}` : '',
        coverType ? `yxtf-tag-cover--${coverType}` : '',
        tagSize ? `yxtf-tag--${tagSize}` : '',
        closable && 'yxtf-tag--closable',
        effect ? `yxtf-tag--${effect}` : '',
        hit && 'is-hit',
        selected && 'yxtf-tag-selected'
      ];
      const tagEl = (
        disable
          ? <span class="yxtf-disable" >{ this.$slots.default }</span>
          : <span
            class={ classes }
            style={{ backgroundColor: this.color }}
            on-click={ this.handleClick }>
            { this.$slots.default }
            {
              closable && effect !== 'special' && <i class="yxtf-tag__close yxtf-icon-close" on-click={ this.handleClose }></i>
            }
            {
              closable && effect === 'special' && <i class="yxtf-tag__close yxtf-icon-error" on-click={ this.handleClose }></i>
            }
            {
              showIcon && effect === 'select' && <svg class="yxtf-icon_tag_svg" width="14px" height="14px" viewBox="0 0 14 14" version="1.1">
                <path d="M7,7 L14,0 L14,11 C14,12.6568542 12.6568542,14 11,14 L0,14 L0,14 L7,7 Z" id="矩形"></path>
                <path d="M11.5479462,6.8857352 C11.7132407,6.66452869 12.0265618,6.6192031 12.2477683,6.78449766 C12.4443963,6.93142616 12.5020566,7.19531384 12.3963509,7.40750202 L12.3490058,7.48431976 L9.29384238,11.5729118 C9.14402244,11.7734093 8.87336959,11.8288407 8.6600019,11.7159572 L8.58302215,11.6656908 L6.43489719,9.96563601 C6.21836222,9.79426735 6.1817477,9.47980932 6.35311636,9.26327436 C6.50544406,9.07079883 6.77083003,9.02048267 6.98000352,9.13203523 L7.05547802,9.18149353 L8.8,10.562 L11.5479462,6.8857352 Z" id="路径-5" fill="#FFFFFF"></path>
              </svg>
            }
          </span>
      );

      return this.disableTransitions ? tagEl : <transition name="yxtf-zoom-in-center">{ tagEl }</transition>;
    }
  };
</script>
