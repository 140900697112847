var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "yxtf-form-item",
      class: [
        {
          "yxtf-form-item--feedback": _vm.yxtfForm && _vm.yxtfForm.statusIcon,
          "is-error": _vm.validateState === "error",
          "is-validating": _vm.validateState === "validating",
          "is-success": _vm.validateState === "success",
          "is-required": _vm.isRequired || _vm.required,
          "is-no-asterisk": _vm.yxtfForm && _vm.yxtfForm.hideRequiredAsterisk,
        },
        _vm.sizeClass ? "yxtf-form-item--" + _vm.sizeClass : "",
      ],
    },
    [
      _c(
        "label-wrap",
        {
          attrs: {
            "is-auto-width": _vm.labelStyle && _vm.labelStyle.width === "auto",
            "update-all": _vm.form.labelWidth === "auto",
          },
        },
        [
          _vm.label || _vm.$slots.label
            ? _c(
                "label",
                {
                  staticClass: "yxtf-form-item__label",
                  style: _vm.labelStyle,
                  attrs: { for: _vm.labelFor },
                },
                [
                  _vm.labelTooltip
                    ? _c(
                        "span",
                        { staticClass: "yxtf-form-item__tooltip" },
                        [
                          _c(
                            "yxtf-tooltip",
                            {
                              attrs: {
                                "popper-class": "yxtf-form-tooltip",
                                effect: "ellipsis",
                                content: _vm.labelTooltip,
                                placement: "bottom-start",
                                "visible-arrow": false,
                              },
                            },
                            [
                              _c("yxt-svg", {
                                staticClass: "color-gray-6 hover-primary-6",
                                attrs: {
                                  "remote-url": "",
                                  width: "16px",
                                  height: "16px",
                                  "icon-class": "info-cirlce-o",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  _c(
                    "span",
                    { staticClass: "yxtf-form-item__labelcontent" },
                    [
                      _vm._t("label", [
                        _c(
                          "yxt-popover",
                          {
                            attrs: {
                              slot: "reference",
                              placement: "top",
                              trigger: "hover",
                              "open-filter": !_vm.isClamped,
                              content: _vm.label + _vm.form.labelSuffix,
                            },
                            slot: "reference",
                          },
                          [
                            _c(
                              "vue-clamp",
                              {
                                staticClass: "yxt-form-clamp",
                                attrs: { slot: "reference", maxLines: 2 },
                                on: { clampchange: _vm.clampchange },
                                slot: "reference",
                              },
                              [
                                _vm._v(
                                  "\n              " +
                                    _vm._s(_vm.label + _vm.form.labelSuffix) +
                                    "\n            "
                                ),
                              ]
                            ),
                          ],
                          1
                        ),
                      ]),
                    ],
                    2
                  ),
                  _c("span", { staticClass: "yxtf-form-item__required" }, [
                    _vm.isRequired || _vm.required
                      ? _c("i", { staticClass: "required-dot" })
                      : _vm._e(),
                  ]),
                ]
              )
            : _vm._e(),
        ]
      ),
      _c(
        "div",
        { staticClass: "yxtf-form-item__content", style: _vm.contentStyle },
        [
          _vm._t("default"),
          _vm.$slots.description
            ? _c(
                "div",
                { staticClass: "yxtf-form-item__description" },
                [_vm._t("description")],
                2
              )
            : _vm.description
            ? _c("div", { staticClass: "yxtf-form-item__description" }, [
                _vm._v(_vm._s(_vm.description)),
              ])
            : _vm._e(),
          _c(
            "transition",
            { attrs: { name: "yxtf-zoom-in-top" } },
            [
              _vm.validateState === "error" &&
              _vm.showMessage &&
              _vm.form.showMessage
                ? _vm._t(
                    "error",
                    [
                      _c(
                        "div",
                        {
                          staticClass: "yxtf-form-item__error",
                          class: {
                            "yxtf-form-item__error--inline":
                              typeof _vm.inlineMessage === "boolean"
                                ? _vm.inlineMessage
                                : (_vm.yxtfForm &&
                                    _vm.yxtfForm.inlineMessage) ||
                                  false,
                          },
                        },
                        [
                          _vm._v(
                            "\n          " +
                              _vm._s(_vm.validateMessage) +
                              "\n        "
                          ),
                        ]
                      ),
                    ],
                    { error: _vm.validateMessage }
                  )
                : _vm._e(),
            ],
            2
          ),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }