<template>
  <div
    class="yxt-page-header"
    :class="[
      true
        ? { 'yxt-page-header--pure yxt-page-header--pure_line': pure }
        : { 'yxt-page-header--pure': pure },
      size ? 'yxt-page-header--' + size : ''
    ]"
  >
    <div class="yxt-page-header__top" :class="[describe &&  'yxt-page-header__top_desc']">
      <div class="yxt-page-header__left" @click="iconHot || $emit('back')">
        <div v-show="showIcon" class="yxt-page-header__icon"  @click="!iconHot ||$emit('back')">
          <yxt-svg
          width="24px"
          height="24px"
          icon-class="arrow-left"
          class="color-gray-9 yxt-page-header__back"
        />
        </div>
        <div
          v-if="!describe"
          class="yxt-page-header__title yxt-weight-6 color-gray-10"
          :style="iconHot?'cursor: default':''"
        >
          <slot name="title">{{ title }}</slot>
        </div>
         <div v-else>
            <div
              class="yxt-page-header__title yxt-weight-6 color-gray-10"
              :style="iconHot?'cursor: default':''"
            >
              <slot name="title">{{ title }}</slot>
            </div>
            <span class="yxt-page-header--describe">{{ describe }}</span>
         </div>
      </div>
      <div class="yxt-page-header__subtitle">
        <slot name="subtitle">{{ subtitle }}</slot>
      </div>
      <div class="yxt-page-right">
        <slot name="title-right"></slot>
      </div>
    </div>
    <div class="yxt-page-header__content" v-if="!pure && $slots.content">
      <slot name="content"></slot>
    </div>
  </div>
</template>

<script>
import YxtSvg from '../../svg-icon';
export default {
  name: 'YxtPageHeader',
  components: {
    YxtSvg
  },
  props: {
    title: String,
    size: String,
    subtitle: String,
    iconHot: {
      type: Boolean,
      default: false
    },
    pure: {
      type: Boolean,
      default: true
    },
    checkIcon: {
      type: Boolean,
      default: false
    },
    describe: { // 描述
      type: String,
      default: ''
    },
    showIcon: {
      type: Boolean,
      default: true
    }
  }
};
</script>
