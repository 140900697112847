var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "yxt-step",
      class: [
        !_vm.isSimple &&
          !_vm.isBasic &&
          !_vm.isNavigation &&
          "is-" + _vm.$parent.direction,
        _vm.isSimple && !_vm.isBasic && !_vm.isNavigation && "is-simple",
        _vm.isBasic && !_vm.isSimple && !_vm.isNavigation && "is-basic",
        _vm.isNavigation && !_vm.isSimple && !_vm.isBasic && "is-navigation",
        _vm.isLast && !_vm.space && !_vm.isCenter && "is-flex",
        _vm.isCenter && !_vm.isVertical && !_vm.isSimple && "is-center",
        _vm.size && _vm.$parent.direction === "horizontal" && "is-" + _vm.size,
      ],
      style: _vm.style,
    },
    [
      !_vm.isBasic
        ? _c(
            "div",
            {
              staticClass: "yxt-step__head",
              class: ["is-" + _vm.currentStatus, "yxt-step__" + _vm.cursorType],
              on: { click: _vm.onStepClick },
            },
            [
              !_vm.isNavigation
                ? _c(
                    "div",
                    {
                      staticClass: "yxt-step__line",
                      style: _vm.isLast
                        ? ""
                        : { marginRight: _vm.$parent.stepOffset + "px" },
                    },
                    [
                      _c("i", {
                        staticClass: "yxt-step__line-inner",
                        style: _vm.lineStyle,
                      }),
                    ]
                  )
                : _vm._e(),
              _c(
                "div",
                {
                  staticClass: "yxt-step__icon",
                  class: [
                    "is-" + (_vm.icon ? "icon" : "text"),
                    _vm.isNavigation &&
                      _vm.currentStatus == "process" &&
                      "is-text-process",
                  ],
                },
                [
                  _vm.currentStatus !== "success" &&
                  _vm.currentStatus !== "error"
                    ? _vm._t("icon", [
                        _vm.icon
                          ? _c("i", {
                              staticClass: "yxt-step__icon-inner",
                              class: [_vm.icon],
                            })
                          : _vm._e(),
                        !_vm.icon && !_vm.isSimple
                          ? _c("div", { staticClass: "yxt-step__icon-inner" }, [
                              _vm._v(_vm._s(_vm.index + 1)),
                            ])
                          : _vm._e(),
                      ])
                    : _c("i", {
                        staticClass: "yxt-step__icon-inner is-status",
                        class: [
                          "yxt-icon-" +
                            (_vm.currentStatus === "success"
                              ? "check"
                              : "close"),
                        ],
                      }),
                ],
                2
              ),
            ]
          )
        : _vm._e(),
      _c("div", { staticClass: "yxt-step__main" }, [
        _c(
          "div",
          {
            ref: "title",
            staticClass: "yxt-step__title",
            class: [
              "is-" + _vm.currentStatus,
              "yxt-step__" + _vm.cursorType,
              _vm.hoverClass,
            ],
            on: { click: _vm.onStepClick },
          },
          [
            _vm.currentStatus === "finish" && _vm.isBasic
              ? _c("i", { staticClass: "yxt-icon-circle-check" })
              : _vm._e(),
            _vm._t("title", [_vm._v(_vm._s(_vm.title))]),
          ],
          2
        ),
        _vm.isSimple ? _c("div", { staticClass: "yxt-step__arrow" }) : _vm._e(),
        _vm.isNavigation
          ? _c("div", { staticClass: "yxt-step__navigation-arrow" })
          : _vm._e(),
        !_vm.isSimple && !_vm.isBasic && !_vm.isNavigation
          ? _c(
              "div",
              {
                staticClass: "yxt-step__description",
                class: ["is-" + _vm.currentStatus],
              },
              [_vm._t("description", [_vm._v(_vm._s(_vm.description))])],
              2
            )
          : _vm._e(),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }