export default {
  // 新增
  'arrow-down': 'b_arrow-down',
  'arrow-left': 'b_arrow-left',
  'arrow-right': 'b_arrow-right',
  'arrow-up': 'b_arrow-up',
  'double-left': 'b_double-left',
  'double-right': 'b_double-right',
  'edit-summary': 'b_edit-summary',
  empty: 'b_empty',
  'empty-exception': 'empty-exception',
  'empty-404': 'empty-404',
  'empty-empty': 'empty-empty',
  'empty-pagelost': 'empty-pagelost',
  'empty-deleted': 'empty-deleted',
  'empty-nodata': 'empty-nodata',
  'empty-nodata-new': 'empty-nodata-new-1',
  'empty-nopermission-new': 'empty-nopermission-new-2',
  'empty-gobrowser': 'empty-gobrowser-1',
  'empty-servererror': 'empty-servererror',
  'empty-developing': 'empty-developing',
  'empty-nopermission': 'empty-nopermission',
  'empty-versionerror': 'empty-versionerror',
  export: 'b_export',
  f_hide: 'b_f_hide',
  f_view: 'b_f_view',
  level: 'b_level',
  'like-star-s': 'b_like-star-s',
  'like-star': 'b_like-star',
  'message-auth': 'b_message-auth',
  'message-error': 'b_message-error',
  'message-info': 'b_message-info',
  'message-success': 'b_message-success',
  'message-warning': 'b_message-warning',
  message: 'b_message',
  new: 'b_new',
  nopermission: 'b_nopermission',
  'pack-up': 'b_pack-up',
  'paper-summary': 'b_paper-summary',
  pick: 'b_pick',
  poweroff: 'b_poweroff',
  'question-cirlce-o': 'b_question-cirlce-o',
  require: 'b_require',
  'search-2': 'b_search-2',
  'set-2': 'b_set-2',
  set: 'b_set',
  stage: 'b_stage',
  upload: 'b_upload',
  upload2: 'b_upload_new',
  // 同名
  'alarm-clock': 'b_alarm-clock',
  all: 'b_all',
  bars: 'b_bars',
  calendar: 'b_calendar',
  caret: 'b_caret',
  'check-circle-o': 'b_check-circle-o',
  'check-circle': 'b_check-circle',
  check: 'b_check',
  'close-circle-o': 'b_close-circle-o',
  'close-circle': 'b_close-circle',
  close: 'b_close',
  code: 'b_code',
  collect: 'b_collect',
  comment: 'b_comment',
  crop: 'b_crop',
  delete: 'b_delete',
  dept: 'b_dept',
  document: 'b_document',
  edit: 'b_edit',
  filter: 'b_filter',
  hide: 'b_hide',
  home: 'b_home',
  'info-cirlce-o': 'b_info-cirlce-o',
  language: 'b_language',
  left: 'b_left',
  link: 'b_link',
  lock: 'b_lock',
  manage: 'b_manage',
  'microphone-off': 'b_microphone-off',
  microphone: 'b_microphone',
  'minus-circle-o': 'b_minus-circle-o',
  'minus-circle': 'b_minus-circle',
  minus: 'b_minus',
  mobile: 'b_mobile',
  'nav-all': 'b_nav-all',
  'nav-exam': 'b_nav-exam',
  'nav-manage': 'b_nav-manage',
  'nav-set': 'b_nav-set',
  'nav-train': 'b_nav-train',
  'page-header-back-arrow': 'b_page-header-back-arrow',
  'paper-preview': 'b_paper-preview',
  'person-center': 'b_person-center',
  phone: 'b_phone',
  'plus-circle-o': 'b_plus-circle-o',
  'plus-circle': 'b_plus-circle',
  plus: 'b_plus',
  'pos-grade': 'b_pos-grade',
  position: 'b_position',
  rank: 'b_rank',
  right: 'b_right',
  role: 'b_role',
  search: 'b_search',
  share: 'b_share',
  statistics: 'b_statistics',
  swap: 'b_swap',
  sync: 'b_sync',
  tag: 'b_tag',
  time: 'b_time',
  top: 'b_top',
  unlock: 'b_unlock',
  usergroup: 'b_usergroup',
  view: 'b_view',
  'zoom-in': 'b_zoom-in',
  'zoom-out': 'b_zoom-out',
  // 旧的
  address: 'address',
  'common_alarm-clock': 'alarm-clock',
  common_all: 'all',
  arrow_down: 'arrow_down',
  arrow_left: 'arrow_left',
  arrow_right: 'arrow_right',
  arrow_up: 'arrow_up',
  backup: 'backup',
  common_bars: 'bars',
  'biz/default-state-phone': 'default-state-phone',
  'biz/default-state-small': 'default-state-small',
  'biz/default-state': 'default-state',
  'biz/mobile-result-fail': 'mobile-result-fail',
  'biz/mobile-result-success': 'mobile-result-success',
  'biz/point': 'point',
  'biz/result-success-icon': 'result-success-icon',
  'biz/result-success-mail': 'result-success-mail',
  'biz/result-success-phone': 'result-success-phone',
  'biz/tag-accept': 'tag-accept',
  'biz/tag-pass': 'tag-pass',
  'biz/tag-qualified': 'tag-qualified',
  'biz/tag-unpass': 'tag-unpass',
  'biz/tag-unqualified': 'tag-unqualified',
  common_calendar: 'calendar',
  common_caret: 'caret',
  'common_check-circle-o': 'check-circle-o',
  'common_check-circle': 'check-circle',
  common_check: 'check',
  'common_close-circle-o': 'close-circle-o',
  'common_close-circle': 'close-circle',
  common_close: 'close',
  common_code: 'code',
  common_collect: 'collect',
  column: 'column',
  common_comment: 'comment',
  copy: 'copy',
  course: 'course',
  common_crop: 'crop',
  common_delete: 'delete',
  common_dept: 'dept',
  common_document: 'document',
  doubleright: 'doubleright',
  down: 'down',
  'download-center': 'download-center',
  edit_summary: 'edit_summary',
  common_edit: 'edit',
  edit1: 'edit1',
  'exam-summary': 'exam-summary',
  'export-exam': 'export-exam',
  'face-reg': 'face-reg',
  common_filter: 'filter',
  forward: 'forward',
  goback: 'goback',
  common_hide: 'hide',
  common_home: 'home',
  'icons/alarm-clock': 'b_alarm-clock',
  'icons/all': 'b_all',
  'icons/arrow-down': 'b_arrow-down',
  'icons/arrow-left': 'b_arrow-left',
  'icons/arrow-right': 'b_arrow-right',
  'icons/arrow-up': 'b_arrow-up',
  'icons/bars': 'b_bars',
  'icons/calendar': 'b_calendar',
  'icons/caret': 'b_caret',
  'icons/check-circle-o': 'b_check-circle-o',
  'icons/check-circle': 'b_check-circle',
  'icons/check': 'b_check',
  'icons/close-circle-o': 'b_close-circle-o',
  'icons/close-circle': 'b_close-circle',
  'icons/close': 'b_close',
  'icons/code': 'b_code',
  'icons/collect': 'b_collect',
  'icons/comment': 'b_comment',
  'icons/crop': 'b_crop',
  'icons/delete': 'b_delete',
  'icons/dept': 'b_dept',
  'icons/document': 'b_document',
  'icons/double-left': 'b_double-left',
  'icons/double-right': 'b_double-right',
  'icons/edit-summary': 'b_edit-summary',
  'icons/edit': 'b_edit',
  'icons/empty': 'b_empty',
  'icons/export': 'b_export',
  'icons/f_hide': 'b_f_hide',
  'icons/f_view': 'b_f_view',
  'icons/filter': 'b_filter',
  'icons/hide': 'b_hide',
  'icons/home': 'b_home',
  'icons/info-cirlce-o': 'b_info-cirlce-o',
  'icons/language': 'b_language',
  'icons/left': 'b_left',
  'icons/level': 'b_level',
  'icons/like-star-s': 'b_like-star-s',
  'icons/like-star': 'b_like-star',
  'icons/link': 'b_link',
  'icons/lock': 'b_lock',
  'icons/manage': 'b_manage',
  'icons/message-auth': 'b_message-auth',
  'icons/message-error': 'b_message-error',
  'icons/message-info': 'b_message-info',
  'icons/message-success': 'b_message-success',
  'icons/message-warning': 'b_message-warning',
  'icons/message': 'b_message',
  'icons/microphone-off': 'b_microphone-off',
  'icons/microphone': 'b_microphone',
  'icons/minus-circle-o': 'b_minus-circle-o',
  'icons/minus-circle': 'b_minus-circle',
  'icons/minus': 'b_minus',
  'icons/mobile': 'b_mobile',
  'icons/nav-all': 'b_nav-all',
  'icons/nav-exam': 'b_nav-exam',
  'icons/nav-manage': 'b_nav-manage',
  'icons/nav-set': 'b_nav-set',
  'icons/nav-train': 'b_nav-train',
  'icons/new': 'b_new',
  'icons/nopermission': 'b_nopermission',
  'icons/pack-up': 'b_pack-up',
  'icons/page-header-back-arrow': 'b_page-header-back-arrow',
  'icons/paper-preview': 'b_paper-preview',
  'icons/paper-summary': 'b_paper-summary',
  'icons/person-center': 'b_person-center',
  'icons/phone': 'b_phone',
  'icons/pick': 'b_pick',
  'icons/plus-circle-o': 'b_plus-circle-o',
  'icons/plus-circle': 'b_plus-circle',
  'icons/plus': 'b_plus',
  'icons/pos-grade': 'b_pos-grade',
  'icons/position': 'b_position',
  'icons/poweroff': 'b_poweroff',
  'icons/question-cirlce-o': 'b_question-cirlce-o',
  'icons/rank': 'b_rank',
  'icons/require': 'b_require',
  'icons/right': 'b_right',
  'icons/role': 'b_role',
  'icons/search-2': 'b_search-2',
  'icons/search': 'b_search',
  'icons/set-2': 'b_set-2',
  'icons/set': 'b_set',
  'icons/share': 'b_share',
  'icons/stage': 'b_stage',
  'icons/statistics': 'b_statistics',
  'icons/swap': 'b_swap',
  'icons/sync': 'b_sync',
  'icons/tag': 'b_tag',
  'icons/time': 'b_time',
  'icons/top': 'b_top',
  'icons/unlock': 'b_unlock',
  'icons/upload': 'b_upload',
  'icons/usergroup': 'b_usergroup',
  'icons/view': 'b_view',
  'icons/zoom-in': 'b_zoom-in',
  'icons/zoom-out': 'b_zoom-out',
  'common_info-cirlce-o': 'info-cirlce-o',
  'kng-point': 'kng-point',
  common_language: 'language',
  ldoubleleft: 'ldoubleleft',
  'left-rotate': 'left-rotate',
  common_left: 'left',
  common_link: 'link',
  common_lock: 'lock',
  'lower-shelf': 'lower-shelf',
  common_manage: 'manage',
  'common_microphone-off': 'microphone-off',
  common_microphone: 'microphone',
  'common_minus-circle-o': 'minus-circle-o',
  'common_minus-circle': 'minus-circle',
  common_minus: 'minus',
  common_mobile: 'mobile',
  'common_nav-all': 'nav-all',
  'common_nav-exam': 'nav-exam',
  'common_nav-manage': 'nav-manage',
  'common_nav-set': 'nav-set',
  'common_nav-train': 'nav-train',
  packup: 'packup',
  'common_page-header-back-arrow': 'page-header-back-arrow',
  'common_paper-preview': 'paper-preview',
  'common_person-center': 'person-center',
  common_phone: 'phone',
  'common_plus-circle-o': 'plus-circle-o',
  'common_plus-circle': 'plus-circle',
  common_plus: 'plus',
  'common_pos-grade': 'pos-grade',
  common_position: 'position',
  preview: 'preview',
  project: 'project',
  publish: 'publish',
  quit: 'quit',
  common_rank: 'rank',
  restore: 'restore',
  'right-rotate': 'right-rotate',
  common_right: 'right',
  common_role: 'role',
  schedule: 'schedule',
  common_search: 'search',
  secrecy: 'secrecy',
  setting: 'setting',
  common_share: 'share',
  sort: 'sort',
  common_statistics: 'statistics',
  'study-center': 'study-center',
  common_swap: 'swap',
  common_sync: 'sync',
  common_tag: 'tag',
  template: 'template',
  'three-dot': 'three-dot',
  common_time: 'time',
  'tip-offs': 'tip-offs',
  common_top: 'top',
  common_unlock: 'unlock',
  common_usergroup: 'usergroup',
  common_view: 'view',
  'common_zoom-in': 'zoom-in',
  'common_zoom-out': 'zoom-out',
  hotline: 'hotline',
  'tree-open-1': 'tree-open-1',
  'tree-close-1': 'tree-close-1',

  'icons/f_kng-video': 'f_kng-video',
  'icons/f_kng-img': 'f_kng-img',
  'icons/f_kng-audio': 'f_kng-audio',
  'icons/f_kng-micro': 'f_kng-micro',
  'icons/f_kng-zip': 'f_kng-zip',
  'icons/f_kng-excel': 'f_kng-excel',
  'icons/f_kng-ppt': 'f_kng-ppt',
  'icons/f_kng-pdf': 'f_kng-pdf',
  'icons/f_kng-scorm': 'f_kng-scorm',
  'icons/f_kng-html': 'f_kng-html',
  'icons/f_kng-word': 'f_kng-word',
  'icons/f_kng-third': 'f_kng-third',
  'icons/f_kng-other': 'f_proj-other',
  'icons/f_kng-folder': 'f_kng-folder',
  'icons/f_kng-kng': 'f_kng-kng',

  'icons/f_proj-live': 'f_proj-live',
  'icons/f_proj-micro': 'f_proj-micro',
  'icons/f_proj-scorm': 'f_proj-scorm',
  'icons/f_proj-html': 'f_proj-html',
  'icons/f_proj-video': 'f_proj-video',
  'icons/f_proj-audio': 'f_proj-audio',
  'icons/f_proj-exam': 'f_proj-exam',
  'icons/f_proj-activity': 'f_proj-activity',
  'icons/f_proj-task': 'f_proj-task',
  'icons/f_proj-course': 'f_proj-course',
  'icons/f_proj-face': 'f_proj-face',
  'icons/f_proj-practice': 'f_proj-practice',
  'icons/f_proj-research': 'f_proj-research',
  'icons/f_proj-comment': 'f_proj-comment',
  'icons/f_proj-vote': 'f_proj-vote',
  'icons/f_proj-attend': 'f_proj-attend',
  'icons/f_proj-other': 'f_proj-other',
  'icons/f_proj-question': 'f_proj-question',
  'icons/f_proj-questionnaire': 'f_proj-questionnaire',
  'icons/f_proj-courseware.svg': 'f_proj-courseware'
};
