<script>
  import Emitter from '@utils/mixins/emitter';

  export default {
    name: 'YxtfCheckboxGroup',

    componentName: 'YxtfCheckboxGroup',

    mixins: [Emitter],

    inject: {
      yxtformItem: {
        default: ''
      }
    },

    props: {
      value: {},
      disabled: Boolean,
      min: Number,
      max: Number,
      size: String,
      fill: String,
      textColor: String,
      direction: {
        type: String,
        default: 'column'
      }
    },

    computed: {
      _yxtformItemSize() {
        return (this.yxtformItem || {}).yxtformItemSize;
      },
      checkboxGroupSize() {
        return this.size || this._yxtformItemSize || (this.$ELEMENT || {}).size;
      }
    },

    watch: {
      value(value) {
        this.dispatch('YxtfFormItem', 'yxtf.form.change', [value]);
      }
    }
  };
</script>

<template>
  <div :class="['yxtf-checkbox-group', { 'yxtf-checkbox-group--row': this.direction === 'row' }]" role="group" aria-label="checkbox-group">
    <slot></slot>
  </div>
</template>
