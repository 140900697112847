var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "yxt-badge-text" },
    [
      _vm._t("default"),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: !_vm.hidden,
              expression: "!hidden",
            },
          ],
          class: [
            "yxt-badge-text__content",
            "yxt-badge-text__content--" + _vm.type,
          ],
        },
        [
          _c("span", { staticClass: "scale-font" }, [
            _vm._v(_vm._s(_vm.content)),
          ]),
          _vm.isShowPlus
            ? _c("yxt-svg", {
                staticClass: "plus-svg",
                attrs: {
                  width: "5px",
                  "remote-url": _vm.url,
                  "icon-class": "badge-plus",
                },
              })
            : _vm._e(),
        ],
        1
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }