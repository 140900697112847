var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "yxtf-popover",
    _vm._b(
      {
        attrs: { trigger: "click", "popper-class": "yxt-popconfirm__offset" },
        model: {
          value: _vm.visible,
          callback: function ($$v) {
            _vm.visible = $$v
          },
          expression: "visible",
        },
      },
      "yxtf-popover",
      _vm.$attrs,
      false
    ),
    [
      _c("div", { staticClass: "yxtf-popconfirm" }, [
        _c("div", { staticClass: "yxtf-popconfirm__main" }, [
          !_vm.hideIcon
            ? _c("i", {
                staticClass: "yxtf-popconfirm__icon",
                class: _vm.icon,
                style: { color: _vm.iconColor },
              })
            : _vm._e(),
          _vm.title ? _c("div", [_vm._v(_vm._s(_vm.title))]) : _vm._e(),
        ]),
        _vm.content
          ? _c("div", { staticClass: "yxtf-popconfirm__content" }, [
              _vm._v(_vm._s(_vm.content)),
            ])
          : _vm._e(),
        _c(
          "div",
          { staticClass: "yxtf-popconfirm__action" },
          [
            _vm.showTip
              ? _c("yxtf-checkbox", { on: { change: _vm.change } }, [
                  _vm._v("不再提示"),
                ])
              : _vm._e(),
            _c(
              "yxtf-button",
              {
                attrs: { size: "small", type: _vm.cancelButtonType },
                on: { click: _vm.cancel },
              },
              [
                _vm._v(
                  "\n        " +
                    _vm._s(_vm.displayCancelButtonText) +
                    "\n      "
                ),
              ]
            ),
            _c(
              "yxtf-button",
              {
                attrs: { size: "small", type: _vm.confirmButtonType },
                on: { click: _vm.confirm },
              },
              [
                _vm._v(
                  "\n        " +
                    _vm._s(_vm.displayConfirmButtonText) +
                    "\n      "
                ),
              ]
            ),
          ],
          1
        ),
      ]),
      _vm._t("reference", null, { slot: "reference" }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }