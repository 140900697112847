var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "yxt-rate",
      attrs: {
        role: "slider",
        "aria-valuenow": _vm.currentValue,
        "aria-valuetext": _vm.text,
        "aria-valuemin": "0",
        "aria-valuemax": _vm.max,
        tabindex: "0",
      },
      on: { keydown: _vm.handleKey },
    },
    [
      _vm._l(_vm.max, function (item, key) {
        return _c(
          "span",
          {
            key: key,
            staticClass: "yxt-rate__item",
            style: { cursor: _vm.rateDisabled ? "auto" : "pointer" },
            on: {
              mousemove: function ($event) {
                _vm.setCurrentValue(item, $event)
              },
              mouseleave: _vm.resetCurrentValue,
              click: function ($event) {
                _vm.selectValue(item)
              },
            },
          },
          [
            _c(
              "i",
              {
                staticClass: "yxt-rate__icon",
                class: [
                  _vm.classes[item - 1],
                  { hover: _vm.hoverIndex === item },
                ],
                style: _vm.getIconStyle(item),
              },
              [
                _vm.showDecimalIcon(item)
                  ? _c("i", {
                      staticClass: "yxt-rate__decimal",
                      class: _vm.decimalIconClass,
                      style: _vm.decimalStyle,
                    })
                  : _vm._e(),
              ]
            ),
          ]
        )
      }),
      _vm.showText || _vm.showScore
        ? _c(
            "span",
            { staticClass: "yxt-rate__text", style: { color: _vm.textColor } },
            [_vm._v(_vm._s(_vm.text))]
          )
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }