<template>
  <div class="tree-scroll-wrap" :style="{ width: wrapWidth + 'px' }">
    <yxtf-scrollbar>
      <yxtf-treelist
        :data="data"
        :emptyText="emptyText"
        :renderAfterExpand="renderAfterExpand"
        :nodeKey="nodeKey"
        :checkStrictly="checkStrictly"
        :defaultExpandAll="defaultExpandAll"
        :expandOnClickNode="expandOnClickNode"
        :checkOnClickNode="checkOnClickNode"
        :checkDescendants="checkDescendants"
        :autoExpandParent="autoExpandParent"
        :defaultCheckedKeys="defaultCheckedKeys"
        :defaultExpandedKeys="defaultExpandedKeys"
        :currentNodeKey="currentNodeKey"
        :renderContent="renderContent"
        :showCheckbox="showCheckbox"
        :draggable="draggable"
        :allowDrag="allowDrag"
        :allowDrop="allowDrop"
        :props="props"
        :lazy="lazy"
        :highlightCurrent="highlightCurrent"
        :load="load"
        :filterNodeMethod="filterNodeMethod"
        :accordion="accordion"
        :indent="indent"
        :iconClass="iconClass"
        ref="treelist"
        >
      </yxtf-treelist>
    </yxtf-scrollbar>
  </div>
</template>

<script>
import YxtfTreelist from './tree.vue';
import YxtfScrollbar from '@foreground/scrollbar';
import { t } from '@utils/locale';

export default {
  name: 'YxtfTree',

  components: {
    YxtfTreelist,
    YxtfScrollbar
  },

  props: {
    data: {
      type: Array
    },
    emptyText: {
      type: String,
      default() {
        return t('pc_comp_tree_emptyText');
      }
    },
    renderAfterExpand: {
      type: Boolean,
      default: true
    },
    nodeKey: String,
    checkStrictly: Boolean,
    defaultExpandAll: Boolean,
    expandOnClickNode: {
      type: Boolean,
      default: true
    },
    checkOnClickNode: Boolean,
    checkDescendants: {
      type: Boolean,
      default: false
    },
    autoExpandParent: {
      type: Boolean,
      default: true
    },
    defaultCheckedKeys: Array,
    defaultExpandedKeys: Array,
    currentNodeKey: [String, Number],
    renderContent: Function,
    showCheckbox: {
      type: Boolean,
      default: false
    },
    draggable: {
      type: Boolean,
      default: false
    },
    allowDrag: Function,
    allowDrop: Function,
    props: {
      default() {
        return {
          children: 'children',
          label: 'label',
          disabled: 'disabled',
          nocheck: 'nocheck'
        };
      }
    },
    lazy: {
      type: Boolean,
      default: false
    },
    highlightCurrent: Boolean,
    load: Function,
    filterNodeMethod: Function,
    accordion: Boolean,
    indent: {
      type: Number,
      default: 18
    },
    iconClass: String,
    wrapWidth: {
      type: [String, Number],
      default: 320
    }
  },

  methods: {
    filter(value) {
      this.$refs.treelist.filter(value);
    },

    getCheckedNodes(leafOnly, includeHalfChecked) {
      return this.$refs.treelist.getCheckedNodes(leafOnly, includeHalfChecked);
    },

    getCheckedKeys(leafOnly) {
      return this.$refs.treelist.getCheckedKeys(leafOnly);
    },

    getCurrentNode() {
      return this.$refs.treelist.getCurrentNode();
    },

    getCurrentKey() {
      return this.$refs.treelist.getCurrentKey();
    },

    setCheckedNodes(nodes, leafOnly) {
      this.$refs.treelist.setCheckedNodes(nodes, leafOnly);
    },

    setCheckedKeys(keys, leafOnly) {
      this.$refs.treelist.setCheckedKeys(keys, leafOnly);
    },

    setChecked(data, checked, deep) {
      this.$refs.treelist.setChecked(data, checked, deep);
    },

    getHalfCheckedNodes() {
      return this.$refs.treelist.getHalfCheckedNodes();
    },

    getHalfCheckedKeys() {
      return this.$refs.treelist.getHalfCheckedKeys();
    },

    setCurrentNode(node) {
      this.$refs.treelist.setCurrentNode(node);
    },

    setCurrentKey(key) {
      this.$refs.treelist.setCurrentKey(key);
    },

    getNode(data) {
      return this.$refs.treelist.getNode(data);
    },

    remove(data) {
      this.$refs.treelist.remove(data);
    },

    append(data, parentNode) {
      this.$refs.treelist.append(data, parentNode);
    },

    insertBefore(data, refNode) {
      this.$refs.treelist.insertBefore(data, refNode);
    },

    insertAfter(data, refNode) {
      this.$refs.treelist.insertAfter(data, refNode);
    },

    updateKeyChildren(key, data) {
      this.$refs.treelist.updateKeyChildren(key, data);
    }
  }
};
</script>
