var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      class: [
        "yxt-input-number",
        _vm.inputNumberSize ? "yxt-input-number--" + _vm.inputNumberSize : "",
        { "is-disabled": _vm.inputNumberDisabled },
        { "is-without-controls": !_vm.controls },
        { "is-controls-right": _vm.controlsAtRight },
        _vm.currency && _vm.currency.length > 0 ? "currency-input-style" : "",
      ],
      style: _vm.widthCustom ? "width:" + _vm.widthCustom : "",
      on: {
        dragstart: _vm.handleDragStart,
        mouseover: function ($event) {
          _vm.controlsFocus = true
        },
        mouseleave: function ($event) {
          _vm.controlsFocus = false
        },
      },
    },
    [
      _c(
        "span",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.controlsFocus && _vm.controls,
              expression: "controlsFocus && controls",
            },
            {
              name: "repeat-click",
              rawName: "v-repeat-click",
              value: _vm.decrease,
              expression: "decrease",
            },
          ],
          staticClass: "yxt-input-number__decrease",
          class: { "is-disabled": _vm.minDisabled || _vm.inputNumberDisabled },
          attrs: { role: "button" },
          on: {
            keydown: function ($event) {
              if (
                !("button" in $event) &&
                _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
              ) {
                return null
              }
              return _vm.decrease($event)
            },
            click: _vm.handleStopPropagation,
          },
        },
        [
          _c("i", {
            class: "yxt-icon-" + (_vm.controlsAtRight ? "arrow-down" : "minus"),
          }),
        ]
      ),
      _c(
        "span",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.controlsFocus && _vm.controls,
              expression: "controlsFocus && controls",
            },
            {
              name: "repeat-click",
              rawName: "v-repeat-click",
              value: _vm.increase,
              expression: "increase",
            },
          ],
          staticClass: "yxt-input-number__increase",
          class: { "is-disabled": _vm.maxDisabled || _vm.inputNumberDisabled },
          attrs: { role: "button" },
          on: {
            keydown: function ($event) {
              if (
                !("button" in $event) &&
                _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
              ) {
                return null
              }
              return _vm.increase($event)
            },
            click: _vm.handleStopPropagation,
          },
        },
        [
          _c("i", {
            class: "yxt-icon-" + (_vm.controlsAtRight ? "arrow-up" : "plus"),
          }),
        ]
      ),
      _c(
        "yxt-input",
        {
          ref: "input",
          class: _vm.currency && _vm.currency.length > 0 ? "currencyIcon" : "",
          attrs: {
            value: _vm.displayValue,
            placeholder: _vm.placeholder,
            disabled: _vm.inputNumberDisabled,
            size: _vm.inputNumberSize,
            max: _vm.max,
            min: _vm.min,
            name: _vm.name,
            label: _vm.label,
          },
          on: {
            blur: _vm.handleBlur,
            focus: _vm.handleFocus,
            input: _vm.handleInput,
            change: _vm.handleInputChange,
          },
          nativeOn: {
            keydown: [
              function ($event) {
                if (
                  !("button" in $event) &&
                  _vm._k($event.keyCode, "up", 38, $event.key, [
                    "Up",
                    "ArrowUp",
                  ])
                ) {
                  return null
                }
                $event.preventDefault()
                return _vm.increase($event)
              },
              function ($event) {
                if (
                  !("button" in $event) &&
                  _vm._k($event.keyCode, "down", 40, $event.key, [
                    "Down",
                    "ArrowDown",
                  ])
                ) {
                  return null
                }
                $event.preventDefault()
                return _vm.decrease($event)
              },
            ],
          },
        },
        [
          _vm.currency && _vm.currency.length > 0
            ? _c(
                "div",
                {
                  staticClass: "currency-num-style",
                  attrs: { slot: "prefix" },
                  slot: "prefix",
                },
                [_vm._v(_vm._s(_vm.currency))]
              )
            : _vm._e(),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }