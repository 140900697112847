var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "transition-group",
    {
      class: [
        "yxt-upload-list",
        "yxt-upload-list--" + _vm.listType,
        { "is-disabled": _vm.disabled },
      ],
      attrs: { tag: "ul", name: "yxt-list" },
    },
    _vm._l(_vm.files, function (file) {
      return _c(
        "li",
        {
          key: file.uid,
          class: [
            "yxt-upload-list__item",
            "is-" + file.status,
            _vm.focusing ? "focusing" : "",
          ],
          attrs: { tabindex: "0" },
          on: {
            keydown: function ($event) {
              if (
                !("button" in $event) &&
                _vm._k($event.keyCode, "delete", [8, 46], $event.key, [
                  "Backspace",
                  "Delete",
                  "Del",
                ])
              ) {
                return null
              }
              !_vm.disabled && _vm.$emit("remove", file)
            },
            focus: function ($event) {
              _vm.focusing = true
            },
            blur: function ($event) {
              _vm.focusing = false
            },
            click: function ($event) {
              _vm.focusing = false
            },
          },
        },
        [
          _vm._t(
            "default",
            [
              file.status !== "uploading" &&
              ["picture-card", "picture"].indexOf(_vm.listType) > -1
                ? _c("img", {
                    staticClass: "yxt-upload-list__item-thumbnail",
                    attrs: { src: file.url, alt: "" },
                  })
                : _vm._e(),
              _c(
                "a",
                {
                  staticClass: "yxt-upload-list__item-name",
                  on: {
                    click: function ($event) {
                      _vm.handleClick(file)
                    },
                  },
                },
                [
                  _c("i", { staticClass: "yxt-icon-document" }),
                  _vm._v(_vm._s(file.name) + "\n      "),
                ]
              ),
              _c(
                "label",
                { staticClass: "yxt-upload-list__item-status-label" },
                [
                  _c("i", {
                    class: {
                      "yxt-icon-upload-success": true,
                      "yxt-icon-circle-check": _vm.listType === "text",
                      "yxt-icon-check":
                        ["picture-card", "picture"].indexOf(_vm.listType) > -1,
                    },
                  }),
                ]
              ),
              !_vm.disabled
                ? _c("i", {
                    staticClass: "yxt-icon-close",
                    on: {
                      click: function ($event) {
                        _vm.$emit("remove", file)
                      },
                    },
                  })
                : _vm._e(),
              !_vm.disabled
                ? _c("i", { staticClass: "yxt-icon-close-tip" }, [
                    _vm._v(_vm._s(_vm.t("pc_comp_upload_deleteTip"))),
                  ])
                : _vm._e(),
              file.status === "uploading"
                ? _c("yxt-progress", {
                    attrs: {
                      type: _vm.listType === "picture-card" ? "circle" : "line",
                      "stroke-width": _vm.listType === "picture-card" ? 6 : 2,
                      percentage: _vm.parsePercentage(file.percentage),
                    },
                  })
                : _vm._e(),
              _vm.listType === "picture-card"
                ? _c("span", { staticClass: "yxt-upload-list__item-actions" }, [
                    _vm.handlePreview && _vm.listType === "picture-card"
                      ? _c(
                          "span",
                          {
                            staticClass: "yxt-upload-list__item-preview",
                            on: {
                              click: function ($event) {
                                _vm.handlePreview(file)
                              },
                            },
                          },
                          [_c("i", { staticClass: "yxt-icon-zoom-in" })]
                        )
                      : _vm._e(),
                    !_vm.disabled
                      ? _c(
                          "span",
                          {
                            staticClass: "yxt-upload-list__item-delete",
                            on: {
                              click: function ($event) {
                                _vm.$emit("remove", file)
                              },
                            },
                          },
                          [_c("i", { staticClass: "yxt-icon-delete" })]
                        )
                      : _vm._e(),
                  ])
                : _vm._e(),
            ],
            { file: file }
          ),
        ],
        2
      )
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }