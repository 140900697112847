<template>
  <span :style='fitStyle' class="yxtf-action-icon" :class='light ? `yxtf-action-icon__${type}-light` : `yxtf-action-icon__${type}`'>
    <i ref='icon' @click='clickHandler' :style='style'></i>
  </span>
</template>

<script>
const praiseJson = require('./praise.json');
const collectionJson = require('./collection.json');
import { loadScript } from '@utils/utils/util';

const jsonMap = {
  praise: praiseJson,
  collection: collectionJson
};
export default {
  name: 'YxtfActionIcon',
  props: {
    size: {
      type: String,
      default: '30px'
    },
    fitSize: {
      type: String,
      default: null
    },
    actived: {
      type: Boolean,
      default: false
    },
    type: {
      type: String,
      default: 'praise',
      validator(v) {
        return ['collection', 'praise'].includes(v);
      }
    },
    beforeChange: {
      type: Function,
      default: () => {
        return true;
      }
    },
    readonly: {
      type: Boolean,
      default: false
    },
    light: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      isActive: this.actived
    };
  },
  computed: {
    style() {
      return {
        width: this.size,
        height: this.size
      };
    },
    fitStyle() {
      return this.fitSize ? {
        width: this.fitSize,
        height: this.fitSize
      } : {
        width: this.size,
        height: this.size
      };
    }
  },
  watch: {
    actived(v) {
      if (this.isActive !== v) {
        this.isActive = v;
        this.setState(v);
      }
    }
  },
  mounted() {
    // 加载cdn上的js文件
    // const imagesBaseUrl = (typeof window !== 'undefined' && window.feConfig && window.feConfig.common && window.feConfig.common.imagesBaseUrl) || 'https://images.yxt.com/';
    const staticBaseUrl = (typeof window !== 'undefined' && window.feConfig && window.feConfig.common && window.feConfig.common.staticBaseUrl) || 'https://stc.yxt.com/';
    const lottieUrl = `${staticBaseUrl}dd8f4226/015a32b9/lottie.min.js`;
    loadScript(lottieUrl, () => {
      return !!window.lottie;
    }).then(_ => {
      const lottie = window.lottie;

      this.lottie = lottie.loadAnimation({
        container: this.$refs.icon,
        renderer: 'svg',
        loop: false,
        autoplay: false,
        animationData: jsonMap[this.type]
      });

      if (this.actived) {
        this.lottie.goToAndStop(1000);
      }
    });
  },

  methods: {
    play() {
      if (this.lottie) {
        this.lottie.play();
      }
    },
    stop() {
      if (this.lottie) {
        this.lottie.stop();
      }
    },
    setState(v) {
      if (this.lottie) {
        this.lottie.goToAndStop(v ? 1000 : 0);
      }
    },
    changeEvent() {
      if (!this.lottie) return;
      if (this.isActive) {
        this.lottie.goToAndStop(0);
      } else {
        this.play();
      }
      this.$emit('change', !this.isActive);
      this.isActive = !this.isActive;
    },
    clickHandler() {
      if (!this.lottie || this.readonly) return;

      let isNext = this.beforeChange(this.isActive);
      if (isNext && isNext.then) {
        isNext.then(this.changeEvent);
      } else {
        this.changeEvent();
      }
    }
  }
};
</script>

<style lang="scss" scoped>

</style>