<template>
  <div class="yxt-badge">
    <slot></slot>
    <transition name="yxt-zoom-in-center">
      <sup
        v-show="!hidden && (content || content === 0 || isDot || isText)"
        class="yxt-badge__content"
        :style="positionStyle"
        :class="[
          'yxt-badge__content--' + type,
          {
            'is-fixed': $slots.default,
            'is-dot': isDot,
            'is-text': isText
          }
        ]">
        <span class="scale-font">{{ content }}</span>
        <yxt-svg v-if="isShowPlus" width="5px" :remote-url="url" icon-class="badge-plus" class="plus-svg" />
      </sup>
    </transition>
  </div>
</template>

<script>
export default {
  name: 'YxtBadge',

  props: {
    value: [String, Number],
    max: Number,
    isDot: Boolean,
    isText: Boolean,
    hidden: Boolean,
    type: {
      type: String,
      validator(val) {
        return ['primary', 'success', 'warning', 'info', 'danger'].indexOf(val) > -1;
      }
    },
    right: String,
    top: String
  },

  computed: {
    content() {
      if (this.isDot) return;

      const value = this.value;
      const max = this.max;

      if (typeof value === 'number' && typeof max === 'number') {
        return max < value ? max : value;
      }

      return value;
    },
    isShowPlus() {
      const value = this.value;
      const max = this.max;
      if (typeof value === 'number' && typeof max === 'number') {
        return max < value;
      } else {
        return false;
      }
    },
    positionStyle() {
      if (this.isDot) {
        return { 'top': this.top ? this.top : '2px', 'right': this.right ? this.right : '6px' };
      } else if (this.isText) {
        return { 'top': this.top ? this.top : '0', 'right': this.right ? this.right : '0' };
      } else {
        return { 'top': this.top ? this.top : '0', 'right': this.right ? this.right : '8px' };
      }
    },
    url() {
      const staticBaseUrl = (typeof window !== 'undefined' && window.feConfig && window.feConfig.common && window.feConfig.common.staticBaseUrl) || 'https://stc.yxt.com/';
      return `${staticBaseUrl}ufd/3f5568/common/pc_backstage/font`;
    }
  }
};
</script>
