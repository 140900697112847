var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "table",
    {
      staticClass: "yxt-year-table",
      attrs: { cellspacing: "0", cellpadding: "0" },
      on: { mousemove: _vm.handleMouseMove, click: _vm.handleYearTableClick },
    },
    [
      _c(
        "tbody",
        _vm._l(_vm.rows, function (row, key) {
          return _c(
            "tr",
            { key: key },
            _vm._l(row, function (cell, key) {
              return _c(
                "td",
                {
                  key: key,
                  class: _vm.getCellStyle(cell),
                  on: {
                    mouseenter: function ($event) {
                      _vm.handleMouseEnter(cell)
                    },
                    mouseleave: _vm.handleMouseLeave,
                  },
                },
                [
                  _c("div", [
                    _c("a", { staticClass: "cell" }, [
                      _vm._v(_vm._s(cell.text)),
                    ]),
                  ]),
                ]
              )
            }),
            0
          )
        }),
        0
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }