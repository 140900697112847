<template>
  <footer class="yxt-footer" :style="{ height }">
    <slot></slot>
  </footer>
</template>

<script>
  export default {
    name: 'YxtFooter',

    componentName: 'YxtFooter',

    props: {
      height: {
        type: String,
        default: '60px'
      }
    }
  };
</script>
