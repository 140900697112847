var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "clickoutside",
          rawName: "v-clickoutside",
          value: _vm.handleClose,
          expression: "handleClose",
        },
      ],
      ref: "select",
      staticClass: "yxt-select",
      class: [
        _vm.selectSize ? "yxt-select--" + _vm.selectSize : "",
        {
          "yxt-select-noBorder": _vm.noBorder,
        },
      ],
      on: {
        click: function ($event) {
          $event.stopPropagation()
          return _vm.toggleMenu($event)
        },
      },
    },
    [
      _vm.multiple
        ? _c(
            "div",
            {
              ref: "tags",
              staticClass: "yxt-select__tags",
              class: {
                "yxt-select__collapse_tags": _vm.collapseTags && !_vm.statics,
                "yxt-select__collapse_auto_tags":
                  _vm.statics && _vm.collapseTagsAutoVisible,
              },
              style: {
                "max-width": _vm.inputWidth - _vm.prependWidth - 36 + "px",
                width: "100%",
                paddingLeft:
                  _vm.prependWidth <= 0
                    ? ["small", "mini"].indexOf(_vm.selectSize) > -1
                      ? "4px"
                      : "8px"
                    : _vm.prependWidth - 8 + "px",
              },
            },
            [
              _vm.collapseTags && _vm.selected.length && !_vm.statics
                ? _c(
                    "span",
                    {
                      staticClass: "sel111",
                      style: { "max-width": _vm.filterable ? "95%" : "100%" },
                    },
                    [
                      _c(
                        "yxt-tag",
                        {
                          attrs: {
                            closable: !_vm.selectDisabled,
                            size: _vm.collapseTagSize,
                            hit: _vm.selected[0].hitState,
                            type: "info",
                            "disable-transitions": "",
                          },
                          on: {
                            close: function ($event) {
                              _vm.deleteTag($event, _vm.selected[0])
                            },
                          },
                        },
                        [
                          _c(
                            "span",
                            { staticClass: "yxt-select__tags-text" },
                            [
                              _vm._t(
                                "tag",
                                [
                                  _vm._v(
                                    "\n             " +
                                      _vm._s(_vm.selected[0].currentLabel) +
                                      "\n          "
                                  ),
                                ],
                                { data: _vm.selected[0] }
                              ),
                            ],
                            2
                          ),
                        ]
                      ),
                      _vm.selected.length > 1
                        ? _c(
                            "yxt-tag",
                            {
                              attrs: {
                                closable: false,
                                size: _vm.collapseTagSize,
                                type: "info",
                                "disable-transitions": "",
                              },
                            },
                            [
                              _c(
                                "span",
                                { staticClass: "yxt-select__tags-text" },
                                [_vm._v("+ " + _vm._s(_vm.selected.length - 1))]
                              ),
                            ]
                          )
                        : _vm._e(),
                    ],
                    1
                  )
                : _vm._e(),
              _vm.visibleSelected.length
                ? _c(
                    "span",
                    {
                      ref: "visibleTags",
                      staticClass: "ref_visibleTags",
                      style: { "max-width": _vm.filterable ? "95%" : "100%" },
                    },
                    [
                      _vm._l(_vm.visibleSelected, function (item) {
                        return _c(
                          "yxt-tag",
                          {
                            key: _vm.getValueKey(item),
                            attrs: {
                              closable: !_vm.selectDisabled,
                              size: _vm.collapseTagSize,
                              hit: item.hitState,
                              type: "info",
                              "disable-transitions": "",
                            },
                            on: {
                              close: function ($event) {
                                _vm.deleteTag($event, item)
                              },
                            },
                          },
                          [
                            _c(
                              "span",
                              { staticClass: "yxt-select__tags-text" },
                              [
                                _vm._t(
                                  "tag",
                                  [
                                    _vm._v(
                                      "\n            " +
                                        _vm._s(item.currentLabel) +
                                        "\n          "
                                    ),
                                  ],
                                  { data: item }
                                ),
                              ],
                              2
                            ),
                          ]
                        )
                      }),
                      _vm.collapseTagsAutoVisible
                        ? _c(
                            "yxt-tag",
                            {
                              attrs: {
                                closable: false,
                                size: _vm.collapseTagSize,
                                type: "info",
                                "disable-transitions": "",
                              },
                            },
                            [
                              _c(
                                "span",
                                {
                                  ref: "collapseTagsAutoNum",
                                  staticClass:
                                    "yxt-select__tags-text yxt-select__tags-text-num",
                                },
                                [
                                  _vm._v(
                                    "+ " +
                                      _vm._s(
                                        _vm.selected.length -
                                          _vm.visibleSelected.length
                                      )
                                  ),
                                ]
                              ),
                            ]
                          )
                        : _vm._e(),
                    ],
                    2
                  )
                : _vm._e(),
              !_vm.collapseTags || (_vm.statics && !_vm.visibleSelected.length)
                ? _c(
                    "transition-group",
                    {
                      staticClass: "sel333",
                      style: { "max-width": _vm.filterable ? "95%" : "100%" },
                      on: { "after-leave": _vm.resetInputHeight },
                    },
                    [
                      _vm._l(_vm.selected, function (item, index) {
                        return [
                          index < 200
                            ? _c(
                                "yxt-tag",
                                {
                                  key: _vm.getValueKey(item),
                                  attrs: {
                                    closable: !_vm.selectDisabled,
                                    size: _vm.collapseTagSize,
                                    hit: item.hitState,
                                    type: "info",
                                    "disable-transitions": "",
                                  },
                                  on: {
                                    close: function ($event) {
                                      _vm.deleteTag($event, item)
                                    },
                                  },
                                },
                                [
                                  _c(
                                    "span",
                                    { staticClass: "yxt-select__tags-text" },
                                    [
                                      _vm._t(
                                        "tag",
                                        [
                                          _vm._v(
                                            "\n                " +
                                              _vm._s(item.currentLabel) +
                                              "\n              "
                                          ),
                                        ],
                                        { data: item }
                                      ),
                                    ],
                                    2
                                  ),
                                ]
                              )
                            : _vm._e(),
                        ]
                      }),
                      _vm.selected && _vm.selected.length > 200
                        ? _c(
                            "yxt-tag",
                            {
                              key: _vm.getValueKey({
                                value:
                                  "key-" + Math.random().toString(36).substr(2),
                              }),
                              attrs: {
                                closable: false,
                                size: _vm.collapseTagSize,
                                type: "info",
                                "disable-transitions": "",
                              },
                            },
                            [
                              _c(
                                "span",
                                { staticClass: "yxt-select__tags-text" },
                                [
                                  _vm._v(
                                    "+ " + _vm._s(_vm.selected.length - 200)
                                  ),
                                ]
                              ),
                            ]
                          )
                        : _vm._e(),
                    ],
                    2
                  )
                : _vm._e(),
              _vm.filterable
                ? _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.query,
                        expression: "query",
                      },
                    ],
                    ref: "input",
                    staticClass: "yxt-select__input input_sel111",
                    class: [_vm.selectSize ? "is-" + _vm.selectSize : ""],
                    style: {
                      "flex-grow": "1",
                      width: _vm.inputLength / (_vm.inputWidth - 32) + "%",
                      "max-width": _vm.inputWidth - 42 + "px",
                    },
                    attrs: {
                      type: "text",
                      disabled: _vm.selectDisabled,
                      autocomplete: _vm.autoComplete || _vm.autocomplete,
                    },
                    domProps: { value: _vm.query },
                    on: {
                      focus: _vm.handleFocus,
                      blur: function ($event) {
                        _vm.softFocus = false
                      },
                      keyup: _vm.managePlaceholder,
                      keydown: [
                        _vm.resetInputState,
                        function ($event) {
                          if (
                            !("button" in $event) &&
                            _vm._k($event.keyCode, "down", 40, $event.key, [
                              "Down",
                              "ArrowDown",
                            ])
                          ) {
                            return null
                          }
                          $event.preventDefault()
                          _vm.navigateOptions("next")
                        },
                        function ($event) {
                          if (
                            !("button" in $event) &&
                            _vm._k($event.keyCode, "up", 38, $event.key, [
                              "Up",
                              "ArrowUp",
                            ])
                          ) {
                            return null
                          }
                          $event.preventDefault()
                          _vm.navigateOptions("prev")
                        },
                        function ($event) {
                          if (
                            !("button" in $event) &&
                            _vm._k(
                              $event.keyCode,
                              "enter",
                              13,
                              $event.key,
                              "Enter"
                            )
                          ) {
                            return null
                          }
                          $event.preventDefault()
                          return _vm.selectOption($event)
                        },
                        function ($event) {
                          if (
                            !("button" in $event) &&
                            _vm._k($event.keyCode, "esc", 27, $event.key, [
                              "Esc",
                              "Escape",
                            ])
                          ) {
                            return null
                          }
                          $event.stopPropagation()
                          $event.preventDefault()
                          _vm.visible = false
                        },
                        function ($event) {
                          if (
                            !("button" in $event) &&
                            _vm._k(
                              $event.keyCode,
                              "delete",
                              [8, 46],
                              $event.key,
                              ["Backspace", "Delete", "Del"]
                            )
                          ) {
                            return null
                          }
                          return _vm.deletePrevTag($event)
                        },
                        function ($event) {
                          if (
                            !("button" in $event) &&
                            _vm._k($event.keyCode, "tab", 9, $event.key, "Tab")
                          ) {
                            return null
                          }
                          _vm.visible = false
                        },
                      ],
                      compositionstart: _vm.handleComposition,
                      compositionupdate: _vm.handleComposition,
                      compositionend: _vm.handleComposition,
                      input: [
                        function ($event) {
                          if ($event.target.composing) {
                            return
                          }
                          _vm.query = $event.target.value
                        },
                        _vm.debouncedQueryChange,
                      ],
                    },
                  })
                : _vm._e(),
            ],
            1
          )
        : _vm._e(),
      _c(
        "yxt-input",
        {
          ref: "reference",
          staticClass: "input_sel222",
          class: { "is-focus": _vm.visible },
          attrs: {
            type: "text",
            placeholder: _vm.currentPlaceholder,
            name: _vm.name,
            id: _vm.id,
            autocomplete: _vm.autoComplete || _vm.autocomplete,
            size: _vm.selectSize,
            disabled: _vm.selectDisabled,
            readonly: _vm.readonly,
            "validate-event": false,
            tabindex: _vm.multiple && _vm.filterable ? "-1" : null,
          },
          on: { focus: _vm.handleFocus, blur: _vm.handleBlur },
          nativeOn: {
            keyup: function ($event) {
              return _vm.debouncedOnInputChange($event)
            },
            keydown: [
              function ($event) {
                if (
                  !("button" in $event) &&
                  _vm._k($event.keyCode, "down", 40, $event.key, [
                    "Down",
                    "ArrowDown",
                  ])
                ) {
                  return null
                }
                $event.stopPropagation()
                $event.preventDefault()
                _vm.navigateOptions("next")
              },
              function ($event) {
                if (
                  !("button" in $event) &&
                  _vm._k($event.keyCode, "up", 38, $event.key, [
                    "Up",
                    "ArrowUp",
                  ])
                ) {
                  return null
                }
                $event.stopPropagation()
                $event.preventDefault()
                _vm.navigateOptions("prev")
              },
              function ($event) {
                if (
                  !("button" in $event) &&
                  _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                ) {
                  return null
                }
                $event.preventDefault()
                return _vm.selectOption($event)
              },
              function ($event) {
                if (
                  !("button" in $event) &&
                  _vm._k($event.keyCode, "esc", 27, $event.key, [
                    "Esc",
                    "Escape",
                  ])
                ) {
                  return null
                }
                $event.stopPropagation()
                $event.preventDefault()
                _vm.visible = false
              },
              function ($event) {
                if (
                  !("button" in $event) &&
                  _vm._k($event.keyCode, "tab", 9, $event.key, "Tab")
                ) {
                  return null
                }
                _vm.visible = false
              },
            ],
            paste: function ($event) {
              return _vm.debouncedOnInputChange($event)
            },
            mouseenter: function ($event) {
              _vm.inputHovering = true
            },
            mouseleave: function ($event) {
              _vm.inputHovering = false
            },
          },
          model: {
            value: _vm.selectedLabel,
            callback: function ($$v) {
              _vm.selectedLabel = $$v
            },
            expression: "selectedLabel",
          },
        },
        [
          _vm.$slots.prepend
            ? _c(
                "template",
                { ref: "prepend", slot: "prepend" },
                [_vm._t("prepend")],
                2
              )
            : _vm._e(),
          _vm.$slots.prefix
            ? _c("template", { slot: "prefix" }, [_vm._t("prefix")], 2)
            : _vm._e(),
          _c("template", { slot: "suffix" }, [
            _c("i", {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: !_vm.showClose,
                  expression: "!showClose",
                },
              ],
              class: [
                "yxt-select__caret",
                "yxt-input__icon",
                _vm.selectDisabled ? "yxt-select__icon-disabled" : "",
                "yxt-icon-" + _vm.iconClass,
              ],
            }),
            _vm.showClose
              ? _c("i", {
                  class: [
                    "yxt-select__caret",
                    "yxt-input__icon",
                    _vm.selectDisabled ? "yxt-select__icon-disabled" : "",
                    "yxt-icon-close",
                  ],
                  on: { click: _vm.handleClearClick },
                })
              : _vm._e(),
          ]),
        ],
        2
      ),
      _c(
        "transition",
        {
          attrs: { name: "yxt-zoom-in-top" },
          on: {
            "before-enter": _vm.handleMenuEnter,
            "after-leave": _vm.doDestroy,
          },
        },
        [
          _c(
            "yxt-select-menu",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value:
                    !_vm.hiddenOption && _vm.visible && _vm.emptyText !== false,
                  expression: "!hiddenOption && visible && emptyText !== false",
                },
              ],
              ref: "popper",
              attrs: { "append-to-body": _vm.popperAppendToBody },
              on: { leaveDropdown: _vm.handleLeaveDropdown },
            },
            [
              _vm.$slots["fixed-header"]
                ? _c(
                    "div",
                    { staticClass: "yxt-select-dropdown__prefix-wrap" },
                    [
                      _c(
                        "div",
                        { staticClass: "yxt-select-dropdown__prefix" },
                        [_vm._t("fixed-header")],
                        2
                      ),
                    ]
                  )
                : _vm._e(),
              _c(
                "div",
                {
                  staticClass: "yxt-select-dropdown__layout",
                  style: _vm.dropdownLayoutStyle,
                },
                [
                  _vm.$slots["fixed-left"]
                    ? _c(
                        "div",
                        {
                          staticClass: "yxt-select-dropdown__layout-left",
                          style: _vm.dropdownLayoutLeftStyle,
                        },
                        [_vm._t("fixed-left")],
                        2
                      )
                    : _vm._e(),
                  _vm.$slots["fixed-right"]
                    ? _c(
                        "div",
                        {
                          staticClass: "yxt-select-dropdown__layout-right",
                          style: _vm.dropdownLayoutRightStyle,
                        },
                        [_vm._t("fixed-right")],
                        2
                      )
                    : _vm._e(),
                  _c(
                    "yxt-scrollbar",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.options.length > 0 && !_vm.loading,
                          expression: "options.length > 0 && !loading",
                        },
                      ],
                      ref: "scrollbar",
                      class: {
                        "is-empty":
                          !_vm.allowCreate &&
                          _vm.query &&
                          _vm.filteredOptionsCount === 0,
                      },
                      attrs: {
                        "wrap-style": _vm.wrapStyle,
                        tag: "ul",
                        "wrap-class": "yxt-select-dropdown__wrap",
                        "view-class": _vm.viewClass,
                      },
                      nativeOn: {
                        click: function ($event) {
                          $event.stopPropagation()
                          return _vm.scrollFocus($event)
                        },
                      },
                    },
                    [
                      _vm.showNewOption
                        ? _c("yxt-option", {
                            attrs: { value: _vm.query, created: "" },
                          })
                        : _vm._e(),
                      _vm._t("default"),
                    ],
                    2
                  ),
                ],
                1
              ),
              _vm.loading
                ? [
                    _c("div", { staticClass: "yxt-select-dropdown__loading" }, [
                      _c("div", {
                        directives: [
                          {
                            name: "loading",
                            rawName: "v-loading",
                            value: _vm.loading,
                            expression: "loading",
                          },
                        ],
                        attrs: {
                          "yxt-loading-size": "small",
                          "yxt-loading-text": _vm.loadingCompText,
                        },
                      }),
                    ]),
                  ]
                : _vm.emptyText &&
                  (!_vm.allowCreate ||
                    _vm.loading ||
                    (_vm.allowCreate && _vm.options.length === 0))
                ? [
                    _vm.$slots.empty
                      ? _vm._t("empty")
                      : !_vm.loading
                      ? _c("p", { staticClass: "yxt-select-dropdown__empty" }, [
                          _vm._v(_vm._s(_vm.emptyText)),
                        ])
                      : _vm._e(),
                  ]
                : _vm._e(),
              _vm.$slots["fixed-footer"]
                ? _c(
                    "div",
                    { staticClass: "yxt-select-dropdown__suffix-wrap" },
                    [
                      _c(
                        "div",
                        { staticClass: "yxt-select-dropdown__suffix" },
                        [_vm._t("fixed-footer")],
                        2
                      ),
                    ]
                  )
                : _vm._e(),
            ],
            2
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }