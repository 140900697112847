/* Automatically generated by 'scripts/bin/build-entry.js' */

import 'babel-polyfill';
import Pagination from './packages/backstage/src/pagination/index.js';
import Dialog from './packages/backstage/src/dialog/index.js';
import Autocomplete from './packages/backstage/src/autocomplete/index.js';
import Dropdown from './packages/backstage/src/dropdown/index.js';
import DropdownMenu from './packages/backstage/src/dropdown-menu/index.js';
import DropdownItem from './packages/backstage/src/dropdown-item/index.js';
import DropdownGroup from './packages/backstage/src/dropdown-group/index.js';
import Menu from './packages/backstage/src/menu/index.js';
import Submenu from './packages/backstage/src/submenu/index.js';
import MenuItem from './packages/backstage/src/menu-item/index.js';
import MenuItemGroup from './packages/backstage/src/menu-item-group/index.js';
import Input from './packages/backstage/src/input/index.js';
import InputNumber from './packages/backstage/src/input-number/index.js';
import Radio from './packages/backstage/src/radio/index.js';
import RadioGroup from './packages/backstage/src/radio-group/index.js';
import RadioButton from './packages/backstage/src/radio-button/index.js';
import RadioCard from './packages/backstage/src/radio-card/index.js';
import Checkbox from './packages/backstage/src/checkbox/index.js';
import CheckboxButton from './packages/backstage/src/checkbox-button/index.js';
import CheckboxGroup from './packages/backstage/src/checkbox-group/index.js';
import Switch from './packages/backstage/src/switch/index.js';
import Select from './packages/backstage/src/select/index.js';
import Option from './packages/backstage/src/option/index.js';
import OptionGroup from './packages/backstage/src/option-group/index.js';
import Button from './packages/backstage/src/button/index.js';
import ButtonGroup from './packages/backstage/src/button-group/index.js';
import Table from './packages/backstage/src/table/index.js';
import TableColumn from './packages/backstage/src/table-column/index.js';
import DatePicker from './packages/backstage/src/date-picker/index.js';
import TimeSelect from './packages/backstage/src/time-select/index.js';
import TimePicker from './packages/backstage/src/time-picker/index.js';
import Popover from './packages/backstage/src/popover/index.js';
import Popconfirm from './packages/backstage/src/popconfirm/index.js';
import Tooltip from './packages/backstage/src/tooltip/index.js';
import MessageBox from './packages/backstage/src/message-box/index.js';
import Breadcrumb from './packages/backstage/src/breadcrumb/index.js';
import BreadcrumbItem from './packages/backstage/src/breadcrumb-item/index.js';
import Form from './packages/backstage/src/form/index.js';
import FormItem from './packages/backstage/src/form-item/index.js';
import Tabs from './packages/backstage/src/tabs/index.js';
import TabPane from './packages/backstage/src/tab-pane/index.js';
import Tag from './packages/backstage/src/tag/index.js';
import Tree from './packages/backstage/src/tree/index.js';
import TreeNative from './packages/backstage/src/tree-native/index.js';
import Alert from './packages/backstage/src/alert/index.js';
import Notification from './packages/backstage/src/notification/index.js';
import Slider from './packages/backstage/src/slider/index.js';
import Loading from './packages/backstage/src/loading/index.js';
import Icon from './packages/backstage/src/icon/index.js';
import Row from './packages/backstage/src/row/index.js';
import Col from './packages/backstage/src/col/index.js';
import Upload from './packages/backstage/src/upload/index.js';
import Progress from './packages/backstage/src/progress/index.js';
import Spinner from './packages/backstage/src/spinner/index.js';
import Message from './packages/backstage/src/message/index.js';
import Badge from './packages/backstage/src/badge/index.js';
import Card from './packages/backstage/src/card/index.js';
import Rate from './packages/backstage/src/rate/index.js';
import Steps from './packages/backstage/src/steps/index.js';
import Step from './packages/backstage/src/step/index.js';
import Carousel from './packages/backstage/src/carousel/index.js';
import Scrollbar from './packages/backstage/src/scrollbar/index.js';
import CarouselItem from './packages/backstage/src/carousel-item/index.js';
import Collapse from './packages/backstage/src/collapse/index.js';
import CollapseItem from './packages/backstage/src/collapse-item/index.js';
import Cascader from './packages/backstage/src/cascader/index.js';
import ColorPicker from './packages/backstage/src/color-picker/index.js';
import Transfer from './packages/backstage/src/transfer/index.js';
import Container from './packages/backstage/src/container/index.js';
import Header from './packages/backstage/src/header/index.js';
import Aside from './packages/backstage/src/aside/index.js';
import Main from './packages/backstage/src/main/index.js';
import Footer from './packages/backstage/src/footer/index.js';
import Timeline from './packages/backstage/src/timeline/index.js';
import TimelineItem from './packages/backstage/src/timeline-item/index.js';
import Link from './packages/backstage/src/link/index.js';
import Divider from './packages/backstage/src/divider/index.js';
import Image from './packages/backstage/src/image/index.js';
import Calendar from './packages/backstage/src/calendar/index.js';
import CalendarMini from './packages/backstage/src/calendar-mini/index.js';
import Backtop from './packages/backstage/src/backtop/index.js';
import InfiniteScroll from './packages/backstage/src/infinite-scroll/index.js';
import PageHeader from './packages/backstage/src/page-header/index.js';
import CascaderPanel from './packages/backstage/src/cascader-panel/index.js';
import Avatar from './packages/backstage/src/avatar/index.js';
import Drawer from './packages/backstage/src/drawer/index.js';
import SvgIcon from './packages/backstage/src/svg-icon/index.js';
import Empty from './packages/backstage/src/empty/index.js';
import Sticky from './packages/backstage/src/sticky/index.js';
import Layout from './packages/backstage/src/layout/index.js';
import SideBar from './packages/backstage/src/side-bar/index.js';
import Content from './packages/backstage/src/content/index.js';
import NavHeader from './packages/backstage/src/nav-header/index.js';
import LayoutMain from './packages/backstage/src/layout-main/index.js';
import LayoutSide from './packages/backstage/src/layout-side/index.js';
import LayoutHeader from './packages/backstage/src/layout-header/index.js';
import LayoutContent from './packages/backstage/src/layout-content/index.js';
import Blockhead from './packages/backstage/src/blockhead/index.js';
import Guide from './packages/backstage/src/guide/index.js';
import BadgeText from './packages/backstage/src/badge-text/index.js';
import AvatarGroup from './packages/backstage/src/avatar-group/index.js';
import SideBarNew from './packages/backstage/src/side-bar-new/index.js';
import Text from './packages/backstage/src/text/index.js';
import Anchor from './packages/backstage/src/anchor/index.js';
import AnchorLink from './packages/backstage/src/anchor-link/index.js';
import FrontPagination from './packages/foreground/src/pagination/index.js';
import FrontDialog from './packages/foreground/src/dialog/index.js';
import FrontAutocomplete from './packages/foreground/src/autocomplete/index.js';
import FrontDropdown from './packages/foreground/src/dropdown/index.js';
import FrontDropdownMenu from './packages/foreground/src/dropdown-menu/index.js';
import FrontDropdownItem from './packages/foreground/src/dropdown-item/index.js';
import FrontMenu from './packages/foreground/src/menu/index.js';
import FrontSubmenu from './packages/foreground/src/submenu/index.js';
import FrontMenuItem from './packages/foreground/src/menu-item/index.js';
import FrontMenuItemGroup from './packages/foreground/src/menu-item-group/index.js';
import FrontInput from './packages/foreground/src/input/index.js';
import FrontInputNumber from './packages/foreground/src/input-number/index.js';
import FrontRadio from './packages/foreground/src/radio/index.js';
import FrontRadioGroup from './packages/foreground/src/radio-group/index.js';
import FrontRadioButton from './packages/foreground/src/radio-button/index.js';
import FrontCheckbox from './packages/foreground/src/checkbox/index.js';
import FrontCheckboxButton from './packages/foreground/src/checkbox-button/index.js';
import FrontCheckboxGroup from './packages/foreground/src/checkbox-group/index.js';
import FrontSwitch from './packages/foreground/src/switch/index.js';
import FrontSelect from './packages/foreground/src/select/index.js';
import FrontOption from './packages/foreground/src/option/index.js';
import FrontOptionGroup from './packages/foreground/src/option-group/index.js';
import FrontButton from './packages/foreground/src/button/index.js';
import FrontButtonGroup from './packages/foreground/src/button-group/index.js';
import FrontTable from './packages/foreground/src/table/index.js';
import FrontTableColumn from './packages/foreground/src/table-column/index.js';
import FrontDatePicker from './packages/foreground/src/date-picker/index.js';
import FrontTimeSelect from './packages/foreground/src/time-select/index.js';
import FrontTimePicker from './packages/foreground/src/time-picker/index.js';
import FrontPopover from './packages/foreground/src/popover/index.js';
import FrontTooltip from './packages/foreground/src/tooltip/index.js';
import FrontMessageBox from './packages/foreground/src/message-box/index.js';
import FrontBreadcrumb from './packages/foreground/src/breadcrumb/index.js';
import FrontBreadcrumbItem from './packages/foreground/src/breadcrumb-item/index.js';
import FrontForm from './packages/foreground/src/form/index.js';
import FrontFormItem from './packages/foreground/src/form-item/index.js';
import FrontTabs from './packages/foreground/src/tabs/index.js';
import FrontTabPane from './packages/foreground/src/tab-pane/index.js';
import FrontTag from './packages/foreground/src/tag/index.js';
import FrontTree from './packages/foreground/src/tree/index.js';
import FrontTreeNative from './packages/foreground/src/tree-native/index.js';
import FrontNotification from './packages/foreground/src/notification/index.js';
import FrontIcon from './packages/foreground/src/icon/index.js';
import FrontRow from './packages/foreground/src/row/index.js';
import FrontCol from './packages/foreground/src/col/index.js';
import FrontUpload from './packages/foreground/src/upload/index.js';
import FrontProgress from './packages/foreground/src/progress/index.js';
import FrontSpinner from './packages/foreground/src/spinner/index.js';
import FrontMessage from './packages/foreground/src/message/index.js';
import FrontBadge from './packages/foreground/src/badge/index.js';
import FrontCard from './packages/foreground/src/card/index.js';
import FrontRate from './packages/foreground/src/rate/index.js';
import FrontSteps from './packages/foreground/src/steps/index.js';
import FrontStep from './packages/foreground/src/step/index.js';
import FrontCarousel from './packages/foreground/src/carousel/index.js';
import FrontScrollbar from './packages/foreground/src/scrollbar/index.js';
import FrontCarouselItem from './packages/foreground/src/carousel-item/index.js';
import FrontContainer from './packages/foreground/src/container/index.js';
import FrontHeader from './packages/foreground/src/header/index.js';
import FrontAside from './packages/foreground/src/aside/index.js';
import FrontMain from './packages/foreground/src/main/index.js';
import FrontFooter from './packages/foreground/src/footer/index.js';
import FrontLink from './packages/foreground/src/link/index.js';
import FrontDivider from './packages/foreground/src/divider/index.js';
import FrontImage from './packages/foreground/src/image/index.js';
import FrontCalendar from './packages/foreground/src/calendar/index.js';
import FrontCalendarMini from './packages/foreground/src/calendar-mini/index.js';
import FrontInfiniteScroll from './packages/foreground/src/infinite-scroll/index.js';
import FrontInfiniteList from './packages/foreground/src/infinite-list/index.js';
import FrontPageHeader from './packages/foreground/src/page-header/index.js';
import FrontCascaderPanel from './packages/foreground/src/cascader-panel/index.js';
import FrontPortrait from './packages/foreground/src/portrait/index.js';
import FrontSvgIcon from './packages/foreground/src/svg-icon/index.js';
import FrontEmpty from './packages/foreground/src/empty/index.js';
import FrontLoading from './packages/foreground/src/loading/index.js';
import FrontActionIcon from './packages/foreground/src/action-icon/index.js';
import FrontPopconfirm from './packages/foreground/src/popconfirm/index.js';
import locale from '@utils/locale';
import CollapseTransition from '@utils/transitions/collapse-transition';
import ColorCreator from '@utils/theme';
import Theme from '@utils/theme/setting.js';
import afterLeave from '@utils/utils/after-leave.js';
import ariaDialog from '@utils/utils/aria-dialog.js';
import ariaUtils from '@utils/utils/aria-utils.js';
import Clickoutside from '@utils/utils/clickoutside.js';
import * as dom from '@utils/utils/dom.js';
import merge from '@utils/utils/merge.js';
import popper from '@utils/utils/popper.js';
import { addResizeListener, removeResizeListener } from '@utils/utils/resize-event.js';
import scrollIntoView from '@utils/utils/scroll-into-view.js';
import scrollbarWidth from '@utils/utils/scrollbar-width.js';
import vuePopper from '@utils/utils/vue-popper.js';
import PopupManager from '@utils/utils/popup/popup-manager';
import { isIEFun } from '@utils/utils/util';
const clickoutside = Clickoutside;

const components = [
  Pagination,
  Dialog,
  Autocomplete,
  Dropdown,
  DropdownMenu,
  DropdownItem,
  DropdownGroup,
  Menu,
  Submenu,
  MenuItem,
  MenuItemGroup,
  Input,
  InputNumber,
  Radio,
  RadioGroup,
  RadioButton,
  RadioCard,
  Checkbox,
  CheckboxButton,
  CheckboxGroup,
  Switch,
  Select,
  Option,
  OptionGroup,
  Button,
  ButtonGroup,
  Table,
  TableColumn,
  DatePicker,
  TimeSelect,
  TimePicker,
  Popover,
  Popconfirm,
  Tooltip,
  Breadcrumb,
  BreadcrumbItem,
  Form,
  FormItem,
  Tabs,
  TabPane,
  Tag,
  Tree,
  TreeNative,
  Alert,
  Slider,
  Icon,
  Row,
  Col,
  Upload,
  Progress,
  Spinner,
  Badge,
  Card,
  Rate,
  Steps,
  Step,
  Carousel,
  Scrollbar,
  CarouselItem,
  Collapse,
  CollapseItem,
  Cascader,
  ColorPicker,
  Transfer,
  Container,
  Header,
  Aside,
  Main,
  Footer,
  Timeline,
  TimelineItem,
  Link,
  Divider,
  Image,
  Calendar,
  CalendarMini,
  Backtop,
  PageHeader,
  CascaderPanel,
  Avatar,
  Drawer,
  SvgIcon,
  Empty,
  Sticky,
  Layout,
  SideBar,
  Content,
  NavHeader,
  LayoutMain,
  LayoutSide,
  LayoutHeader,
  LayoutContent,
  Blockhead,
  Guide,
  BadgeText,
  AvatarGroup,
  SideBarNew,
  Text,
  Anchor,
  AnchorLink,
  FrontPagination,
  FrontDialog,
  FrontAutocomplete,
  FrontDropdown,
  FrontDropdownMenu,
  FrontDropdownItem,
  FrontMenu,
  FrontSubmenu,
  FrontMenuItem,
  FrontMenuItemGroup,
  FrontInput,
  FrontInputNumber,
  FrontRadio,
  FrontRadioGroup,
  FrontRadioButton,
  FrontCheckbox,
  FrontCheckboxButton,
  FrontCheckboxGroup,
  FrontSwitch,
  FrontSelect,
  FrontOption,
  FrontOptionGroup,
  FrontButton,
  FrontButtonGroup,
  FrontTable,
  FrontTableColumn,
  FrontDatePicker,
  FrontTimeSelect,
  FrontTimePicker,
  FrontPopover,
  FrontTooltip,
  FrontBreadcrumb,
  FrontBreadcrumbItem,
  FrontForm,
  FrontFormItem,
  FrontTabs,
  FrontTabPane,
  FrontTag,
  FrontTree,
  FrontTreeNative,
  FrontIcon,
  FrontRow,
  FrontCol,
  FrontUpload,
  FrontProgress,
  FrontSpinner,
  FrontBadge,
  FrontCard,
  FrontRate,
  FrontSteps,
  FrontStep,
  FrontCarousel,
  FrontScrollbar,
  FrontCarouselItem,
  FrontContainer,
  FrontHeader,
  FrontAside,
  FrontMain,
  FrontFooter,
  FrontLink,
  FrontDivider,
  FrontImage,
  FrontCalendar,
  FrontCalendarMini,
  FrontInfiniteScroll,
  FrontInfiniteList,
  FrontPageHeader,
  FrontCascaderPanel,
  FrontPortrait,
  FrontSvgIcon,
  FrontEmpty,
  FrontActionIcon,
  FrontPopconfirm,
  CollapseTransition
];

const logVersion = function() {
  const {log} = console;
  log(' %cYXT-PC 2.6.6', 'color: #fff; border-radius: 3px; padding: 3px 7px;background: linear-gradient(315deg, #ff512f 0%, #f09819 74%)');
};

const install = function(Vue, opts = {}) {
  !isIEFun() && logVersion();
  locale.use(opts.locale);
  locale.i18n(opts.i18n);

  components.forEach(component => {
    Vue.component(component.name, component);
  });

  Vue.prototype.$ELEMENT = {
    size: opts.size || '',
    zIndex: opts.zIndex || 2000
  };
  Vue.prototype.$msgbox = MessageBox;
  Vue.prototype.$alert = MessageBox.alert;
  Vue.prototype.$confirm = MessageBox.confirm;
  Vue.prototype.$prompt = MessageBox.prompt;
  Vue.prototype.$notify = Notification;
  Vue.prototype.$message = Message;
  Vue.use(InfiniteScroll);
  Vue.use(Loading.directive);
  Vue.prototype.$loading = Loading.service;
  // Vue.prototype.$fmsgbox = FrontMessageBox;
  // Vue.prototype.$falert = FrontMessageBox.alert;
  // Vue.prototype.$fconfirm = FrontMessageBox.confirm;
  // Vue.prototype.$fprompt = FrontMessageBox.prompt;
  Vue.prototype.$fnotify = FrontNotification;
  Vue.prototype.$fmessage = FrontMessage;
  Vue.use(FrontLoading.directive);
  Vue.prototype.$floading = FrontLoading.service;
  Vue.prototype.$colorCreator = ColorCreator;
  Vue.prototype.$changeTheme = Theme.changeTheme;
  Vue.prototype.$getPrimaryColors = Theme.getPrimaryColors;
  Vue.prototype.$getPrimaryColor = Theme.getPrimaryColor;

  Theme.changeTheme(opts.theme || '#436bff', { yxtInstall: true });
};

/* istanbul ignore if */
if (typeof window !== 'undefined' && window.Vue) {
  install(window.Vue);
}

export default {
  version: '2.6.6',
  locale: locale.use,
  i18n: locale.i18n,
  install,
  CollapseTransition,
  Pagination,
  Dialog,
  Autocomplete,
  Dropdown,
  DropdownMenu,
  DropdownItem,
  DropdownGroup,
  Menu,
  Submenu,
  MenuItem,
  MenuItemGroup,
  Input,
  InputNumber,
  Radio,
  RadioGroup,
  RadioButton,
  RadioCard,
  Checkbox,
  CheckboxButton,
  CheckboxGroup,
  Switch,
  Select,
  Option,
  OptionGroup,
  Button,
  ButtonGroup,
  Table,
  TableColumn,
  DatePicker,
  TimeSelect,
  TimePicker,
  Popover,
  Popconfirm,
  Tooltip,
  MessageBox,
  Breadcrumb,
  BreadcrumbItem,
  Form,
  FormItem,
  Tabs,
  TabPane,
  Tag,
  Tree,
  TreeNative,
  Alert,
  Notification,
  Slider,
  Loading,
  Icon,
  Row,
  Col,
  Upload,
  Progress,
  Spinner,
  Message,
  Badge,
  Card,
  Rate,
  Steps,
  Step,
  Carousel,
  Scrollbar,
  CarouselItem,
  Collapse,
  CollapseItem,
  Cascader,
  ColorPicker,
  Transfer,
  Container,
  Header,
  Aside,
  Main,
  Footer,
  Timeline,
  TimelineItem,
  Link,
  Divider,
  Image,
  Calendar,
  CalendarMini,
  Backtop,
  InfiniteScroll,
  PageHeader,
  CascaderPanel,
  Avatar,
  Drawer,
  SvgIcon,
  Empty,
  Sticky,
  Layout,
  SideBar,
  Content,
  NavHeader,
  LayoutMain,
  LayoutSide,
  LayoutHeader,
  LayoutContent,
  Blockhead,
  Guide,
  BadgeText,
  AvatarGroup,
  SideBarNew,
  Text,
  Anchor,
  AnchorLink,
  FrontPagination,
  FrontDialog,
  FrontAutocomplete,
  FrontDropdown,
  FrontDropdownMenu,
  FrontDropdownItem,
  FrontMenu,
  FrontSubmenu,
  FrontMenuItem,
  FrontMenuItemGroup,
  FrontInput,
  FrontInputNumber,
  FrontRadio,
  FrontRadioGroup,
  FrontRadioButton,
  FrontCheckbox,
  FrontCheckboxButton,
  FrontCheckboxGroup,
  FrontSwitch,
  FrontSelect,
  FrontOption,
  FrontOptionGroup,
  FrontButton,
  FrontButtonGroup,
  FrontTable,
  FrontTableColumn,
  FrontDatePicker,
  FrontTimeSelect,
  FrontTimePicker,
  FrontPopover,
  FrontTooltip,
  FrontMessageBox,
  FrontBreadcrumb,
  FrontBreadcrumbItem,
  FrontForm,
  FrontFormItem,
  FrontTabs,
  FrontTabPane,
  FrontTag,
  FrontTree,
  FrontTreeNative,
  FrontNotification,
  FrontIcon,
  FrontRow,
  FrontCol,
  FrontUpload,
  FrontProgress,
  FrontSpinner,
  FrontMessage,
  FrontBadge,
  FrontCard,
  FrontRate,
  FrontSteps,
  FrontStep,
  FrontCarousel,
  FrontScrollbar,
  FrontCarouselItem,
  FrontContainer,
  FrontHeader,
  FrontAside,
  FrontMain,
  FrontFooter,
  FrontLink,
  FrontDivider,
  FrontImage,
  FrontCalendar,
  FrontCalendarMini,
  FrontInfiniteScroll,
  FrontInfiniteList,
  FrontPageHeader,
  FrontCascaderPanel,
  FrontPortrait,
  FrontSvgIcon,
  FrontEmpty,
  FrontLoading,
  FrontActionIcon,
  FrontPopconfirm,
  afterLeave,
  ariaDialog,
  ariaUtils,
  Clickoutside,
  clickoutside,
  dom,
  merge,
  popper,
  addResizeListener,
  removeResizeListener,
  scrollIntoView,
  scrollbarWidth,
  vuePopper,
  PopupManager,
  changeTheme: Theme.changeTheme
};
